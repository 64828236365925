import { Card, Form } from 'react-bootstrap';
import ApplicantDetails from '../ApplicantDetails';
import { useState } from 'react';
import PlotDetails from '../../../Migration/pages/PlotDetails';
import EnterpriseDetails from '../EnterpriseDetails';
import {
    ALLOTMENT_CORRESPONDENCE,
    APPLICANT_DETAILS_I,
    APPLICANT_DETAILS_II,
    CHECKLIST_TABLE,
    DECLARATION_DETAILS,
    ENTERPRISE_DETAILS,
    PROJECT_DETAILS,
    PROMOTER_DETAILS,
    paymentColumns
} from '../Allotment.const';
import Declaration from '../Declaration';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import ScoringMatrixComponent from '../ScoringMatrixComponent';
import { useFormikContext } from 'formik';
import AllotedPlotDetails from './AllotedPlotDetails';
import Editor from '../../../../../components/ContentEditor';
import GenericCommonTextField from '../../../../../components/moduleCommonFormik/GenericCommonTextField.component';
import { useTranslation } from 'react-i18next';
import { CONSTANT_DATA_FIELD } from '../../../../../utils/constants';
import { WorkflowPaymentDetails } from '../../WorkflowPaymentDetails';

export const AllotmentWorkflowCorrespondence = ({
    dropdownData,
    dropdownDataPlot,
    setDropdownDataPlot,
    ownershipDetails,
    setOwnershipDetails,
    isChecked,
    handleCheckboxChange,
    nicDetails,
    setNicDetails,
    controllingOwnership,
    checkListRowData,
    vacantPlotData,
    rowData,
    setRowData,
    applicationNumber,
    status,
    content,
    setContent,
    paymentRowData,
    disabled,
    designation,
    auctionType,
    fileStoreId,
    functionReturnError,
    readOnly,
    handleSamePlotCheck,
    isSamePlot,
    setIsSamePlot,
    transactionDetails,
    openSection,
    setOpenSection,
    isViewOnly
}: any) => {
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userDesignationGM = getUserDetails.designation === 'GM';

    const [showOwnershipModal, setShowOwnershipModal] = useState(false);

    const { values, setFieldValue } = useFormikContext<any>();
    const { t } = useTranslation();

    const ALLOTMENT_CORRESPONDENCE_SHOW =
        userDesignationGM && values.applicationSubType === 'IDC' && status === 'PENDING'
            ? [
                  ...ALLOTMENT_CORRESPONDENCE,
                  {
                      title: 'F - Scoring Matrix',
                      field: 'scoringMatrix'
                  }
              ]
            : (values.applicationSubType !== 'IDC' && status === 'PENDING') || (values.applicationSubType === 'IDC' && status === 'SCRUTINY_DONE')
            ? [
                  ...ALLOTMENT_CORRESPONDENCE,
                  {
                      title: 'F - Provisional Allotment Notes',
                      field: 'provisionalAllotmentNotes'
                  }
              ]
            : status === 'REJECTION_PENDING'
            ? [
                  ...ALLOTMENT_CORRESPONDENCE,
                  {
                      title: 'F - Rejection Order',
                      field: 'provisionalAllotmentNotes'
                  }
              ]
            : ALLOTMENT_CORRESPONDENCE;

    const promotersDetails = PROMOTER_DETAILS?.map((val: any) => ({ ...val, formData: val?.formData?.map((item: any) => ({ ...item, isDisabled: true })) }));

    const highestBidRate = [
        {
            title: ' Highest Bid Rate (INR/Sq.m.)',
            titleCode: 'HIGHEST_BID_RATE',
            field: 'highestBidRate',
            type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
            isRequired: true,
            isDisabled: isViewOnly ? isViewOnly : designation === 'AM' && auctionType ? false : true,
            showInr: true,
            class: 'col-sm-6'
        }
    ];

    const enterpriseDetails = ENTERPRISE_DETAILS?.map((val: any) => ({ ...val, formData: val.formData?.map((item: any) => ({ ...item, isDisabled: true })) }));

    const declarationDetails = DECLARATION_DETAILS?.map((val: any) => ({ ...val, formData: val.formData?.map((item: any) => ({ ...item, isDisabled: true })) }));

    const projectDetails =
        designation === 'AM' && auctionType && !isViewOnly
            ? PROJECT_DETAILS?.map((val: any) => ({
                  ...val,
                  formData: val.formData?.map((item: any) => {
                      if (item.field === 'emdAmount' || item.field === 'emdAmountSupportingDocument') {
                          return { ...item, isRequired: true };
                      }
                      return { ...item, isDisabled: true };
                  })
              }))
            : PROJECT_DETAILS?.map((val: any) => ({
                  ...val,
                  formData: val.formData?.map((item: any) => ({ ...item, isDisabled: true }))
              }));

    const renderDeclarationDetails = () => {
        const declarationList = [
            '(i) the information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed herein.',
            '(ii) it has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy or liquidation proceeding.',
            '(iii) The provision allotment order or the lease dead,as the case may be,shall be liable to be cancelled if there is any misdeclaration or misrepresentation of the information submitted under or along with this application.'
        ];
        return (
            <Declaration
                isChecked={isChecked}
                hideCard={true}
                declarationList={declarationList}
                handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                fileStoreId={fileStoreId}
                isDisabled={true}
            ></Declaration>
        );
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Card.Body>
                        <Form.Group>
                            <div className='col-lg-12 my-1 px-4 py-4'>
                                <Editor setContent={setContent} content={content} readOnly={readOnly} />
                            </div>
                        </Form.Group>
                    </Card.Body>
                </div>
            </>
        );
    };
    console.log('Payment Row Data', paymentRowData);

    const renderPaymentDetails = () => {
        return (
            <>
                <div className='px-4 py-4'>
                    <WorkflowPaymentDetails transactionDetails={transactionDetails} paymentColumns={paymentColumns} paymentRowData={paymentRowData} />
                </div>
            </>
        );
    };

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    // const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const numericValue = e.target.value.replace(/[^0-9.-]/g, '');
    //     e.target.value = numericValue;
    //     setFieldValue('highestBidRate', numericValue);
    // };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                Condition/Check list
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {ALLOTMENT_CORRESPONDENCE_SHOW.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A - Applicant Details' && openSection === 'applicantDetails' && (
                                    <ApplicantDetails
                                        controllingOwnershipResponse={controllingOwnership}
                                        dropdownOptions={dropdownData}
                                        setOwnershipDetails={setOwnershipDetails}
                                        ownershipDetails={ownershipDetails}
                                        isDisabled={true}
                                        showOwnershipModal={showOwnershipModal}
                                        setShowOwnershipModal={setShowOwnershipModal}
                                        applicantDetails1={APPLICANT_DETAILS_I}
                                        applicantDetails2={APPLICANT_DETAILS_II}
                                        promoterDetails={promotersDetails}
                                        scrollNotRequired={true}
                                        isWorkflow={true}
                                    />
                                )}
                                {val.title === 'B - Plot Details' && openSection === 'plotDetails' && (
                                    <>
                                        <Card.Body>
                                            <div className='typeof-plot-details ps-2 fs-5 mt-4 mb-2'>Applied Plot</div>
                                            <PlotDetails dropdownData={dropdownDataPlot} isFieldDisable={true} setDropdownData={setDropdownDataPlot} hideCard={true} />
                                            {auctionType && (
                                                <div className='row d-flex m-0'>
                                                    <div className='col-sm-6 py-3'>
                                                        {highestBidRate?.map((val: any) => (
                                                            <GenericCommonTextField {...val} />
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {/* (
                                                <div className='row d-flex m-0'>
                                                    <div className='py-3 col-sm-6'>
                                                        <div style={{ width: '100%' }}>
                                                            <div className='d-flex'>
                                                                <label
                                                                    htmlFor={'highestBidRate'}
                                                                    className='pb-2 file-form-label'
                                                                    style={{ width: 'fit-content' }}
                                                                    title={'Highest Bid Rate (INR/Sq.m.)'}
                                                                >
                                                                    Highest Bid Rate (INR/Sq.m.)
                                                                </label>
                                                                <span className='mandatory-indicator'>*</span>
                                                            </div>
                                                            <Field
                                                                name={'highestBidRate'}
                                                                type={'text'}
                                                                id={'highestBidRate'}
                                                                className={` form-control was-validated ${_.get(touched, 'highestBidRate') && _.get(errors, 'highestBidRate') && 'is-invalid'}  ${
                                                                    _.get(values, 'highestBidRate') && !_.get(errors, 'highestBidRate') && 'is-valid'
                                                                } `}
                                                                disabled={designation === 'AM' && auctionType ? false : true}
                                                                onChange={(e: any) => {
                                                                    handleOnChange(e);
                                                                }}
                                                                value={_.get(values, 'highestBidRate')}
                                                            />
                                                            <ErrorMessage name={'highestBidRate'}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) */}

                                            {values.applicationSubType === 'IDC' && ((status === 'PENDING' && designation === 'GM') || status === 'SCRUTINY_DONE') ? (
                                                <>
                                                    <div className='ps-2 py-2 d-flex justify-content-between'>
                                                        <div className='typeof-plot-details ps-2 fs-5 my-2'>Allotted Plot</div>
                                                        {userDesignationGM && values.applicationSubType === 'IDC' && status === 'PENDING' && (
                                                            <label className='d-flex align-items-center'>
                                                                <input type='checkbox' id={`samePlot`} name={`samePlot`} checked={isSamePlot} onChange={handleSamePlotCheck} className='me-2' />
                                                                {t('SAME_AS_ABOVE', 'Same as above')}
                                                            </label>
                                                        )}
                                                    </div>
                                                    <AllotedPlotDetails
                                                        plotData={vacantPlotData}
                                                        type={'allotedPlot'}
                                                        isFieldDisable={status === 'SCRUTINY_DONE' || isViewOnly}
                                                        setIsSamePlot={setIsSamePlot}
                                                    />
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Card.Body>
                                    </>
                                )}
                                {val.title === 'C - Enterprise Details' && openSection === 'enterpriseDetails' && (
                                    <EnterpriseDetails
                                        enterpriseDetails={enterpriseDetails}
                                        enterpriseProjectDetails={projectDetails}
                                        declarationDetails={declarationDetails}
                                        dropdownOptions={dropdownData}
                                        nicDetails={nicDetails}
                                        setNicDetails={setNicDetails}
                                        isDisabled={true}
                                    ></EnterpriseDetails>
                                )}
                                {val.title === 'D - Self Declaration' && openSection === 'selfDeclaration' && renderDeclarationDetails()}
                                {val.title === 'E - Payment Details' && openSection === 'paymentDetails' && renderPaymentDetails()}
                                {val.title === 'F - Scoring Matrix' && openSection === 'scoringMatrix' && (
                                    <ScoringMatrixComponent rowData={rowData} setRowData={setRowData} applicationNumber={applicationNumber} functionReturnError={functionReturnError} />
                                )}
                                {val.title === 'F - Provisional Allotment Notes' && openSection === 'provisionalAllotmentNotes' && renderNoteDetails()}
                                {val.title === 'F - Rejection Order' && openSection === 'provisionalAllotmentNotes' && renderNoteDetails()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};
