import { useEffect, useState } from 'react';
import { Card, CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSearchParams } from 'react-router-dom';
import './Advertisement.scss';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import Editor from '../../../../../components/ContentEditor';
import { ADVERTISEMENT_COLUMNS1, ADVERTISEMENT_COLUMNS_GM, ADVERTISEMENT_COLUMNS_NOT_GM, ADVERTISEMENT_DETAILS } from './Advertisement.const';
import GenericEmailIDNotification from '../../../../../components/moduleCommonFormik/GenericEmailIDNotification.component';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { POST, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { VACANTPLOT_TABLE_COLUMNS } from '../../ApplyForPlot/AdvertisementTable.const';
import { getUniqueArray } from '../../../../../utils/utils';

interface ICorrespondenceProps {
    checkListData?: any;
    noting?: any;
    rowData?: any;
    content?: any;
    setContent?: any;
    formValues?: any;
    advDate?: Date;
    advClosureDate?: Date;
    setRowData?: any;
    onChangeAction: any;
    setSelectedvacantPlots: any;
    selectedVacantPlots: any;
    setVacantPlotRowData: any;
    vacantPlotRowData: any;
    fetchAdvertisementData: any;
    updateAdvertisement: any;
    setShowSaveModal: (e: any) => void;
    showSaveModal: boolean;
    designation?: string;
    nextValidAction?: any;
    isDisabled?: boolean;
    isViewOnly?: boolean;
}

const AdvWorkflow = (props: ICorrespondenceProps) => {
    const {
        rowData,
        setRowData,
        content,
        setContent,
        onChangeAction,
        setSelectedvacantPlots,
        selectedVacantPlots,
        setVacantPlotRowData,
        vacantPlotRowData,
        fetchAdvertisementData,
        updateAdvertisement,
        showSaveModal,
        setShowSaveModal,
        designation,
        nextValidAction,
        isDisabled,
        isViewOnly
    } = props;

    const [openSection, setOpenSection] = useState<string>('isAdvDetails');
    const { t } = useTranslation();
    const { values, setValues } = useFormikContext<any>();
    const [addButton, setAddButton] = useState(false);
    const [addPlotBtn, setaddPlotBtn] = useState(false);
    const [searchParams] = useSearchParams();

    const handleEmailValues = (emails: any) => {
        setValues((prev: any) => ({ ...prev, emails: emails }));
    };

    const onDeleteRow = (data: any) => {
        const updatedSelected = rowData.filter((e: any) => e.uuid !== data.uuid);
        setRowData(updatedSelected);
        setShowSaveModal(true);
    };

    const onSectionChange = (currentSection: string) => {
        if (openSection !== currentSection && currentSection === 'isNoteDetails' && showSaveModal && designation === 'DGM') {
            setAddButton(true);
        }

        if (openSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const handleSavePlots = () => {
        const updatedSelectedPlots = getUniqueArray([...rowData, ...selectedVacantPlots], 'uuid');
        setRowData(updatedSelectedPlots);
        setShowSaveModal(true);
        setaddPlotBtn(false);
    };

    const addPlotModal = () => {
        let newVacantData: any = [...vacantPlotRowData];
        newVacantData = newVacantData.map((row: any) => {
            if (rowData.find((data: any) => data.uuid === row.uuid)) {
                return { ...row, isChecked: true };
            } else {
                const { isChecked, ...rest } = row; // Destructure to remove isChecked
                return rest;
            }
        });
        setVacantPlotRowData(newVacantData);
        setaddPlotBtn(true);
    };

    const onCheckHandler = (checkedItems: any[]) => {
        const newData = vacantPlotRowData.filter((item: any) => checkedItems.includes(item.uuid));
        setSelectedvacantPlots(newData);
        console.log('data', newData);
    };

    const MODIFIED_ADVERTISEMENT_DETAILS = isViewOnly
        ? ADVERTISEMENT_DETAILS.map(val => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, isDisabled: true })) }))
        : ADVERTISEMENT_DETAILS.map(val => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, isDisabled: designation !== 'DGM' })) }));

    const renderAdvDetails = () => {
        return (
            <>
                {MODIFIED_ADVERTISEMENT_DETAILS.map(val => (
                    <GenericFormCard {...val} onChangeAction={onChangeAction} />
                ))}
            </>
        );
    };

    const renderPlotDetails = () => {
        return (
            <div className='pt-4'>
                <GenericActionTable
                    columns={isViewOnly ? ADVERTISEMENT_COLUMNS_NOT_GM : designation === 'DGM' ? ADVERTISEMENT_COLUMNS_GM : ADVERTISEMENT_COLUMNS_NOT_GM}
                    rowData={rowData}
                    onDeleteRow={(e: any) => onDeleteRow(e)}
                    hideSearchBar={true}
                    hideExportBtn={true}
                    disable={isDisabled}
                ></GenericActionTable>
                <br />
                {designation === 'DGM' && !isViewOnly ? (
                    <div
                        className='col-12 mt-1 p-2 text-center cursor-pointer'
                        style={{ border: 'dashed 2px #45852e80', borderRadius: '8px', backgroundColor: '#ecf2ea' }}
                        onClick={() => addPlotModal()}
                    >
                        <button style={{ fontWeight: 600, fontSize: '16px', lineHeight: '25.6px', border: '0px', background: 'none', color: '#000000B2' }} className='button add' type='button'>
                            + {t('ADD PLOTS', 'Add Plots')}
                        </button>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 pt-4'>
                            <Editor setContent={setContent} content={content} readOnly={isViewOnly ? isViewOnly : !nextValidAction?.isUpdatable} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    const renderNotificationDetails = () => {
        return (
            <>
                <div>
                    <GenericEmailIDNotification isEditable={designation === 'DGM' && !isViewOnly} emailIdsData={values.emails} getEmailIds={handleEmailValues} />
                </div>
            </>
        );
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <CardBody>
                    <div className='row'>
                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='advDetailsCheckbox' name='advDetailsForm' checked={openSection === 'isAdvDetails'} onChange={() => onSectionChange('isAdvDetails')} />
                            <label
                                htmlFor='advDetailsCheckbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('A_ADVERTISEMENT_TIMELINE', ' A - Advertisement Timeline')}
                            </label>
                            {openSection === 'isAdvDetails' && renderAdvDetails()}
                        </div>
                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='plotDetailsCheckbox' name='plotDetailsForm' checked={openSection === 'isPlotDetails'} onChange={() => onSectionChange('isPlotDetails')} />
                            <label
                                htmlFor='plotDetailsCheckbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('B_PLOTS', ' B - Plots')}
                            </label>

                            {openSection === 'isPlotDetails' && renderPlotDetails()}
                        </div>

                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='noteDetailsCheckbox' name='noteDetailsForm' checked={openSection === 'isNoteDetails'} onChange={() => onSectionChange('isNoteDetails')} />
                            <label
                                htmlFor='noteDetailsCheckbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('C_ADVERTISEMENT_NOTES', 'C - Advertisement Notes')}
                            </label>

                            {openSection === 'isNoteDetails' && renderNoteDetails()}
                        </div>

                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input
                                type='checkbox'
                                id='notificationDetailsCheckbox'
                                name='notificationDetailsForm'
                                checked={openSection === 'isNotificationDetails'}
                                onChange={() => onSectionChange('isNotificationDetails')}
                            />

                            <label
                                htmlFor='notificationDetailsCheckbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('D_NOTIFICATION_DETAILS', 'D - Notification Details')}
                            </label>

                            {openSection === 'isNotificationDetails' && renderNotificationDetails()}
                        </div>
                    </div>
                </CardBody>
            </Card>
            {nextValidAction?.isUpdatable ? (
                <GenericPopupModal
                    title='Save Confirmation'
                    size='md'
                    primaryAction={() => {
                        setAddButton(false);
                        updateAdvertisement();
                    }}
                    secondaryAction={() => {
                        setAddButton(false);
                        fetchAdvertisementData();
                    }}
                    isVisible={addButton}
                    children={
                        <div>
                            <div className='mx-4 my-3'>
                                <div className='mt-4'>
                                    <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                        {t('SAVE_CHANGES', 'Looks like you have some unsaved changes. Would you like to save them before proceeding with the new data?')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    primaryBtnTitle='Yes'
                    secondaryBtnTitle='No'
                    onClose={() => setAddButton(false)}
                />
            ) : (
                <> </>
            )}

            {
                <GenericPopupModal
                    title='Add Plot for Advertisement'
                    subTitle='Select the Plots to be added in the Advertisement'
                    size='lg'
                    primaryAction={handleSavePlots}
                    secondaryAction={() => {}}
                    children={
                        <div className='employee-creation-container bg-light m-auto  row'>
                            <div className='col-sm-12'>
                                <div className='d-flex justify-content-start'>
                                    <h4>
                                        <br />
                                        <span style={{ color: '#3A7425', paddingLeft: '4px' }}>
                                            <label>{t('VACANT_PLOTS', 'List of Vacant Plots')}</label>
                                        </span>
                                    </h4>
                                </div>
                                <div className='employee-creation-container m-auto px-1 row'>
                                    <GenericActionTable
                                        checkType={`multiCheck`}
                                        columns={VACANTPLOT_TABLE_COLUMNS}
                                        rowData={vacantPlotRowData}
                                        onCheckHandler={onCheckHandler}
                                        hideSearchBar={false}
                                        hideExportBtn={true}
                                        searchBarPlaceholder={'Search by Plot No., Estate Name...'}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    isVisible={addPlotBtn}
                    primaryBtnTitle='Add'
                    secondaryBtnTitle=''
                    isSecondaryBtn={false}
                    onClose={() => setaddPlotBtn(false)}
                />
            }
        </>
    );
};
export default AdvWorkflow;
