import React, { useEffect, useState } from 'react';
import { RENEWAL_CONSTRUCTION_LICENSE_FORM, RENEWAL_CONSTRUCTION_LICENSE_FORM_STEPS } from './renewalOfConstructionLicense.const';
import HeaderComponent from '../../components/header/Header.component';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { convertToINR, fetchLeaseDurationDoc, getOptions, paymentPayload } from '../modules/EstateManagement/EstateManagement.controller';
import { BPArequestInfo, POST, POST2, devUrl, endpoints, estateEndpoints, getNextValidAction, requestInfo } from '../../services/modulesServices/userManagement.api';
import { check, cross, user, warning } from '../../utils/Logo';
import { getNavigateTo } from '../../utils/utils';
import { RazorpayOptions } from '../modules/UserManagement/types';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { Button, Card, CardBody } from 'react-bootstrap';
import Loading from '../../components/portalComponents/Loading.component';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { Formik, FormikProvider, useFormik } from 'formik';
import PaymentDetails from '../modules/EstateManagement/Allotment/PaymentDetails';
import { CorrespondenceWrapper, NotingWrapper } from '../../components/Workflow/NotingWrapper';
import { Clearance_Form_Initial_Data } from '../scrutinyFlowScreen/ScrutinyFormPage.module';
import GenericFooterComponent from '../../components/moduleCommonFormik/GenericFooterStepper.component';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { RenewalCLFormJson, getCLList } from './renewalOfConstructionLicense.controller';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import { Image } from 'react-bootstrap';
import { download } from '../../utils/Logo';
import { esignForDocuments } from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.controller';
import { CHECKLIST_TABLE_TC, paymentColumnsForTC } from '../modules/EstateManagement/Allotment/Allotment.const';
import { CONSTANT_DATA_FIELD } from '../../utils/constants';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';

const M_RenewalOfConstructionLicense = () => {
    const initialValues = Clearance_Form_Initial_Data;
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visited, setVisited] = useState(false);
    const [transferorDetails, setTransferorDetails] = useState<any>({});
    const [transferDetails, setTransferDetails] = useState<any>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [waterConnectionData, setWaterConnectionData] = useState<any>([]);
    const [rowData, setRowData] = useState(null);
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;

    const getUser = JSON.parse(localStorage.getItem('user') || '{}');
    let navigate = useNavigate();
    const stepsDetails = RENEWAL_CONSTRUCTION_LICENSE_FORM_STEPS;
    const [paymentData, setPaymentData] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [openSection, setOpenSection] = useState<string>('CONSTRUCTION_LICENSE');
    const [actionsData, setActionsData] = useState<any>(null);
    const [searchParams] = useSearchParams();
    const location: any = useLocation();
    const [comment, setcomment] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const applicationNumber = urlParams.get('applicationNumber') || '';
    const [allotmentDetails, setAllotmentDetails] = useState<any>(null);
    const [plotDetails, setPlotDetails] = useState<any>(null);
    const [allCLData, setCLData] = useState<any>([]);
    const [previewData, setPreviewData] = useState<any>(null);
    const [eSignFlag, setESignFlag] = useState<boolean>(false);
    const [activeStepper, setActiveStepper] = useState<string>('CONSTRUCTION_LICENSE');
    const [allchecked, setAllChecked] = React.useState<any>([]);
    const stepperIndex = urlParams.get('stepper') || '';
    const signedFileStoreId = urlParams.get('signedFileStoreId') || '';
    const [BPAData, setBPAData] = useState<any>(null);
    const [paymentRowData, setPaymentRowData] = useState<any>(null);
    const [checklistTableData, setChecklistTableData] = useState();
    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');
    // let designation = user ? String(JSON.parse(user)?.designation?.code) : '';

    const formik: any = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            isSpecialProject: false
        },
        onSubmit: value => {}
        // validationSchema: TRANSFER_VALIDATION[`page${activeIndex}`]
    });
    const { values, setValues, errors, setFieldTouched } = formik;

    const handleStepClick = (index: number) => {
        const stepperLength = stepsDetails.length;
        if (index > activeIndex) {
            try {
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    useEffect(() => {
        getActiveStepper();
    }, [activeIndex]);

    const getActiveStepper = () => {
        stepsDetails.forEach(val => {
            if (val?.index === activeIndex) {
                setActiveStepper(val?.code);
            }
        });
    };

    useEffect(() => {
        getPaymentData(previewData?.applicationNo);
    }, []);

    const getPaymentData = async (appId = null) => {
        if (!applicationNumber && !appId) return;
        try {
            const payload = { RequestInfo: BPArequestInfo() };
            TechnicalServices.searchPaymentDetails(appId || applicationNumber, payload)
                .then(res => {
                    if (res?.data) {
                        setPaymentRowData(res?.data);
                    } else {
                        throw new Error('No data found');
                    }
                })
                .catch(error => {
                    let errorMessage = 'Failed to retrieve payment data';
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        errorMessage += `: ${error.response.data.message || error.response.statusText}`;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage += ': No response received from server';
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage += `: ${error.message}`;
                    }
                    setModalDetails({ show: true, title: 'Error!', para: errorMessage, img: cross, type: 'error', reUpload: 'OK' });
                });
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Unexpected error occurred', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (!!stepperIndex && stepperIndex === '2' && previewData && !previewData?.lesseeDigitalSignFlage) {
                setActiveIndex(2);
                setActiveStepper('SELF_DECLARATION');
                setAllChecked([{}, {}, {}]);
                submitForm('eSign', signedFileStoreId);
                setTimeout(() => {
                    setAllChecked([{}, {}, {}]);
                }, 1000);
            }
        }, 1000);
    }, [previewData]);

    useEffect(() => {
        if (applicationNumber) {
            getActionsData();
        }
    }, [applicationNumber]);

    const searchBPA = (val: any) => {
        setIsLoading(true);
        if (!!val) {
            TechnicalServices.getBpaSearchForRefNo(val, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    // handle the response
                    setIsLoading(false);
                    if (!response?.data?.BPA.length) {
                        setPreviewData(null);
                        setBPAData(null);
                        return;
                    }
                    setPreviewData(response?.data?.BPA[0]);
                    setBPAData(response?.data?.BPA[0]);

                    if (val) {
                        const filteredData = response?.data?.BPA[0];
                        values.constructionLicenseNo = filteredData?.refCLApplication;
                        values.phase = filteredData?.additionalDetails?.phase;
                        values.plotNumber = filteredData?.additionalDetails?.plotNo;
                        values.plotType = filteredData?.additionalDetails?.propertyType;
                        values.nameOfIndustrialEstate = filteredData?.additionalDetails?.estate;
                        const fileDate = new Date(filteredData?.validUpTo || new Date());
                        const day = fileDate.getDate(),
                            month = fileDate.getMonth() + 1,
                            year = fileDate.getFullYear();

                        const formattedDate = `${day}/${month}/${year}`;
                        values.expirationDate = formattedDate;
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        searchBPA(applicationNumber);
        getCheckListData();
    }, [applicationNumber]);

    const getCheckListData = () => {
        if (!applicationNumber) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.bpaCheckList(payload, applicationNumber, 'WF_BPA_CL')
            .then(res => {
                const checklistDataWithIndex = res?.data?.checklistValidationDetails?.map((item: any, index: any) => ({
                    ...item,
                    index: index + 1
                }));
                setChecklistTableData(checklistDataWithIndex);
            })
            .catch(err => console.log(err));
    };

    const submitForm = async (action?: any, storeId?: any) => {
        if (!values?.notes) {
            setModalDetails({ show: true, title: 'Error!', para: t('TC_MSG_CHECK_NOTING', 'Please add the noting and proceed'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setIsLoading(true);

        const payload: any = RenewalCLFormJson(values, previewData, !!previewData?.accountId);

        payload.BPA.notes = {
            id: null, // Assuming it should have an ID, set as null or any specific value if needed
            content: values?.notes || '',
            notificationDetails: null // Assuming no specific notification details
        };

        payload.BPA.workflow = {
            action: action,
            assignes: null,
            notes: values?.notes,
            varificationDocuments: null
        };

        TechnicalServices.createTechnicalClearance(payload)
            .then((response: any) => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Success!', para: 'Application approved successfully', img: check, type: 'success', reUpload: 'OK' });

                setPreviewData(response?.data?.BPA[0]);
                setBPAData(response?.data?.BPA[0]);
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const esignSelf = async (fileStoreId: string) => {
        const redirectURL = `/goa-idc/obpas/renewal/ConstructionLicense?${searchParams.toString()}&applicationNumber=${BPAData?.applicationNo || applicationNumber}&note=${
            values.notes
        }&docsUuid=${fileStoreId}&stepper=2`;
        let url = window.location.origin + redirectURL;
        url = btoa(url);
        // navigate(`/goa-idc/emudra-esign?fileStoreId=${fileStoreId}&callbackUrl=${url}`);
        // esignForDocuments(fileStoreId, redirectURL);
        if (values.esignOption === 'EKYC') {
                    const eKYCInitiate =
                        devUrl +
                        endpoints.kycInitiate +
                        `?fileStoreId=${fileStoreId}&accessToken=${requestInfo().authToken}&userType=${requestInfo().userType}${
                            requestInfo().departmentCode ? `&departmentCode=${requestInfo().departmentCode}` : ''
                        }&callBackUrl=${url}`;
        
                    window.open(eKYCInitiate, '_self');
                } else {
                    navigate(`/goa-idc/emudra-esign?fileStoreId=${fileStoreId}&callbackUrl=${url}`);
                }
    };

    const signDocument = async () => {
        if (activeStepper === 'SELF_DECLARATION' && allchecked.length !== 3) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            const payload = { RequestInfo: BPArequestInfo() };
            TechnicalServices.digitalSignDocument(previewData?.applicationNo, payload)
                .then(res => {
                    console.log(res, 'res');
                    if (res?.data) {
                        esignSelf(res?.data?.fileStoreId);
                        // submitForm('eSign', res?.data?.fileStoreId);
                    }
                })
                .catch((e: any) => {
                    let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                });

            // setESignedDocumentData(data?.data);
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            //Fetch Transfer Lessee Details
            try {
                const { data } = getUser.uuid && (await POST(endpoints.searchExistingUser + `?userUuid=${getUser.uuid}`, { RequestInfo: requestInfo() }));
                const transferor = data?.enterpriseEntity[0];
                if (transferor) {
                    setTransferorDetails(transferor);
                    const { entityName, phoneNumber, email } = transferor;
                    const postalAddress = transferor?.addresses?.find((address: any) => address.addressType === 'PERMANENT') || {};
                    const lesseeDataObj = { name: entityName, email, phoneNumber, ...postalAddress };
                    setValues({ ...values, ...lesseeDataObj });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let { data: plotData } = await POST(endpoints.entityRefDetails + `?entityIds=${transferorDetails.uuid}&types=LESSEE`, { RequestInfo: requestInfo() });
                if (plotData?.entityRefDetails?.length) {
                    let plotIds = plotData?.entityRefDetails?.map((item: any) => item.plotId);
                    plotIds = plotIds.join(',');

                    if (!plotIds) return;
                    let { data } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

                    let plotDataArray = data?.plots?.map((plot: any) => ({
                        estateName: plot?.estateDetails?.estateName,
                        plotType: plot?.plotType,
                        phase: plot?.phase,
                        plotNo: plot?.plotNo,
                        plotAreaSqm: plot?.plotAreaSqm,
                        uuid: plot?.uuid,
                        id: plot?.uuid
                    }));
                    setRowData(plotDataArray);
                    // setPlotData(plotDataArray);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (transferorDetails.uuid) fetchData();
    }, [transferorDetails]);

    useEffect(() => {
        const fetchData = async () => {
            const detailsData = await fetchLeaseDurationDoc(values.plotUuid, transferorDetails.uuid);

            setValues((prevState: any) => ({
                ...prevState,
                ...detailsData
            }));
        };

        values.plotUuid && transferorDetails && fetchData();
    }, [values.plotUuid, transferorDetails]);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleModalClose = (type: string) => {
        setModalDetails({ show: false });
        if (type === 'success') {
            navigate('/goa-idc/dashboard');
        }
    };

    const RenewalConstruactionLicense = () => {
        const renewalConstructionLicense = RENEWAL_CONSTRUCTION_LICENSE_FORM;
        renewalConstructionLicense[0].formData.map((val: any) => {
            if (val?.field === 'constructionLicenseNo') {
                val.type = CONSTANT_DATA_FIELD.TEXT_FIELD;
                val.options = getCLList(allCLData);
                val.isDisabled = true;
            }
        });
        return (
            <div>
                <>
                    {renewalConstructionLicense?.map(val => (
                        <GenericFormCard {...val} />
                    ))}
                </>
            </div>
        );
    };

    const onSectionChange = (currentSection: string) => {
        setOpenSection(currentSection);
    };

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const renderDeclarationDetails = () => {
        return (
            <>
                {/* <Card className='card_border my-2'>
                    <Card.Body> */}
                <div className='row ps-2 py-2'>
                    <div className='formTitle m-0'>
                        {t('RCL_CARD_HEADER_SELF_DECLARATION', 'Self Declaration')}
                        {/* <span className='text-danger'>*</span> */}
                    </div>
                </div>
                <div className='row d-flex m-0'>
                    <p className='' style={{ fontWeight: 600 }}>
                        The Lessee does hereby affirm and declare that:
                    </p>

                    <label className='mb-3 checkbox-container'>
                        {!!previewData?.lesseeDigitalSignFlage ? (
                            <input type='checkbox' id='myCheckbox' className='me-2' checked={previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                        ) : (
                            <input type='checkbox' id='myCheckbox' value='1' name='step_1' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                        )}
                        <span className={`${previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                        <span style={{ fontWeight: 600 }}>
                            (i) The information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed herein.
                        </span>
                    </label>

                    <label className='mb-3 checkbox-container'>
                        {!!previewData?.lesseeDigitalSignFlage ? (
                            <input type='checkbox' id='myCheckbox' className='me-2' checked={previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                        ) : (
                            <input type='checkbox' id='myCheckbox' value='2' name='step_2' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                        )}
                        <span className={`${previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                        <span style={{ fontWeight: 600 }}>
                            (ii) It has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy, insolvency or liquidation proceedings.
                        </span>
                    </label>

                    <label className='mb-3 checkbox-container'>
                        {!!previewData?.lesseeDigitalSignFlage ? (
                            <input type='checkbox' id='myCheckbox' className='me-2' checked={previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                        ) : (
                            <input type='checkbox' id='myCheckbox' value='3' name='step_3' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                        )}
                        <span className={`${previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                        <span style={{ fontWeight: 600 }}>
                            (iii) The renewal of the aforementioned construction license shall be subject to all rules and regulations applicable to the original building plan. In the event of
                            non-compliance with these requirements, the construction license shall be deemed null and void.
                        </span>
                    </label>

                    {/* <label className='mb-3 checkbox-container'>
                        {!!previewData?.lesseeDigitalSignFlage ? (
                            <input type='checkbox' id='myCheckbox' className='me-2' checked={previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                        ) : (
                            <input type='checkbox' id='myCheckbox' value='4' name='step_4' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                        )}
                        <span className={`${previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                        <span style={{ fontWeight: 600 }}>(iv) the Lessee agrees to pay annual Sub-Lease Fee to Corporation as defined under the Regulations; and</span>
                    </label>

                    <label className='mb-3 checkbox-container'>
                        {!!previewData?.lesseeDigitalSignFlage ? (
                            <input type='checkbox' id='myCheckbox' className='me-2' checked={previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                        ) : (
                            <input type='checkbox' id='myCheckbox' value='5' name='step_5' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                        )}
                        <span className={`${previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                        <span style={{ fontWeight: 600 }}>(v) it is not in breach of any term and condition of the Lease Deed.</span>
                    </label> */}
                </div>
                {!!previewData?.lesseeDigitalSignFlage ? (
                    <>
                        <div className='ps-2 pt-2 font-14 font-inter fw-bold m-0'>Digitally Signed</div>
                        <div className='ps-2 pb-2 font-14 font-inter'>{}</div>
                        <div onClick={() => downloadApplicationDocument()} className='ps-2 d-flex align-items-center'>
                            <Image src={download} height={14} />
                            <div className='ps-2 font-14' style={{ cursor: 'pointer', color: '#619050', textDecoration: 'underline' }}>
                                Download Application
                            </div>
                        </div>
                    </>
                ) : (
                    <div className='mt-3 col-7 '>
                        <p className='leaseDeed_Self_Declaration_Signature common_style_lease_paragraph mb-1 ms-1'>{`${t(
                            'NAME_&_DIGITAL_SIGNATURE',
                            'Name & Digital Signature of the Transferor/authorized person of the Transferor'
                        )}`}</p>
                        <GenericButtons text={'Sign the Document'} handleClick={signDocument} ClassName='px-4 ' />
                    </div>
                )}
                {/* </Card.Body>
                </Card> */}
            </>
        );
    };

    const onActionClick = (action: string) => {
        submitForm(action);
    };

    const onLastSubmit = async (action: string) => {
        if (!values?.notes) {
            setModalDetails({ show: true, title: 'Error!', para: t('TC_MSG_CHECK_NOTING', 'Please add the noting and proceed'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setIsLoading(true);
        const payload: any = {
            RequestInfo: BPArequestInfo(),
            waterApplicationRequest: {
                applicationDetails: waterConnectionData
            },
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: 'WATER_CONNECTION',
                    businessId: waterConnectionData?.applicationNo,
                    moduleName: 'Water Connection',
                    action: action,
                    comment: values?.notes
                }
            ]
        };

        const apiEndPoint = endpoints.waterConnectionInitiate;

        try {
            const { data } = await POST(apiEndPoint, payload);
            console.log(data);
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Success!', para: 'Application updated successfully', img: check, type: 'success', reUpload: 'OK' });
        } catch (error: any) {
            setIsLoading(false);
            let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getActionsData = () => {
        if (!!applicationNumber) {
            setIsLoading(true);

            getNextValidAction(applicationNumber)
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        console.log(response?.data);
                        setActionsData(response?.data);
                    }

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    const renderActionBtn = () => (
        <>
            {actionsData?.nextValidAction.map((val: any) => {
                // eslint-disable-next-line array-callback-return
                if (val?.action === 'Submit') return;
                return (
                    <>
                        <Button
                            variant='disabled outline-success new_btn btn btn-light me-3 font-karla-med p-10 fs-6'
                            style={{ borderColor: '#45852e', color: '#FFF', backgroundColor: '#45852e', width: 'max-content', borderRadius: 8 }}
                            onClick={() => onActionClick(val?.action)}
                        >
                            {/* {val?.action.replace(/_/g, ' ')} */}
                            {t(val?.action, 'Localisation Not Working')}
                        </Button>
                    </>
                );
            })}
        </>
    );

    function handleChange(e: any) {
        if (e.target.checked) {
            setAllChecked([...allchecked, e?.target?.value]);
        } else {
            setAllChecked(allchecked.filter((item: any) => item !== e.target.value));
        }
    }

    const downloadApplicationDocument = () => {
        // const fileStoreId = props?.previewData?.digitalSignedDoc || props?.bpaData?.digitalSignedDoc;
        // const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${fileStoreId}`;
        // window.open(downloadFileUrl);
    };

    const renderPayment = () => {
        const SANCTION_DETAILS = {
            txnId: paymentRowData?.transactionNumber || '',
            auditDetails: { createdTime: paymentRowData?.transactionDate || '' },
            txnStatus: 'SUCCESS',
            additionalDetails: { paymentReceiptDocRefId: paymentRowData?.receiptId || '' }
        };

        // Function to format numbers with commas
        const formatNumberWithCommas = (number: any) => {
            return number.toLocaleString('en-IN');
        };

        // Format the BPAPaymentDetails array
        const formattedPaymentDetails = (paymentRowData?.BPAPaymentDetails || []).map(
            (
                detail: {
                    principalAmount: any;
                    cgstPrincipal: any;
                    sgstPrincipal: any;
                    interestAmount: any;
                    penaltyAmount: any;
                    cgstPenalty: any;
                    sgstPenalty: any;
                    totalDueAmount: any;
                    paidAmount: any;
                },
                index: number
            ) => ({
                ...detail,
                index: index + 1,
                principalAmount: formatNumberWithCommas(detail.principalAmount),
                cgstPrincipal: formatNumberWithCommas(detail.cgstPrincipal),
                sgstPrincipal: formatNumberWithCommas(detail.sgstPrincipal),
                interestAmount: formatNumberWithCommas(detail.interestAmount),
                penaltyAmount: formatNumberWithCommas(detail.penaltyAmount),
                cgstPenalty: formatNumberWithCommas(detail.cgstPenalty),
                sgstPenalty: formatNumberWithCommas(detail.sgstPenalty),
                totalDueAmount: formatNumberWithCommas(detail.totalDueAmount),
                paidAmount: formatNumberWithCommas(detail.paidAmount)
            })
        );
        return (
            <>
                <div className='my-2'>
                    <PaymentDetails
                        paymentData={formattedPaymentDetails}
                        title={t('TC_CARD_HEADER_PAYMENT_BREAKDOWN', 'Payment Breakdown')}
                        selectedGateway={selectedGateway}
                        setSelectedGateway={setSelectedGateway}
                        isPaid={paymentRowData?.isPaymentRecived}
                        columns={paymentColumnsForTC}
                        gatewayData={gatewayData}
                        transactionDetails={SANCTION_DETAILS}
                        totalLabel={'totalDueAmount'}
                        isCardDisable={true}
                        isPaymentOption={false}
                        hideTitle={true}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
            {/* <StatusAlertPopup para={bodydata} img={cross} handleClose={handleClose} handleSubmit={handleStatusSubmit} show={showStatus} title={''} /> */}
            <div className='applicationPage m-auto px-5 py-4'>
                <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                    <GenericHeader
                        // subText={`Permit No. : ${values?.refApplication || applicationNumber || 'NA'}`}
                        text={t('', 'Renewal Construction License')}
                        additionalClass='sub-text-style'
                        // onActionClick={() => navigateToDashboard()}
                        bottomSubText={`File : ${values?.name}, Type : ${t('', 'Renewal Construction License')}, No :${applicationNumber || 'NA'}`}
                    />
                    <Formik initialValues={initialValues} onSubmit={value => {}}>
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('', 'Renewal Construction License')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : ${t('', 'Renewal Construction License')}, No : ${applicationNumber || 'NA'}`}
                                    />

                                    <NotingWrapper
                                        text={comment}
                                        // disable={!values.isUpdatable}
                                        nextValidAction={actionsData}
                                        disable={isViewOnly ? isViewOnly : !actionsData?.nextValidAction?.length}
                                    />
                                    {/* <p className='noting-heading my-1'>{t('OC_EMP_NOTING_HEADING', 'Noting')}</p>

                                    <NotingComponent history={historyDetails} text={comment} handleOnFileUpload={handleOnFileUpload} /> */}
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper
                                    applicationNumber={applicationNumber}
                                    //  designation={designation}
                                    nextValidAction={{}}
                                />

                                <Card className='checklist-outer mb-4 mx-3'>
                                    <CardBody>
                                        <div className='row'>
                                            <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3    align-items-center pb-2 '>
                                                <input type='checkbox' id='Primary Assignment' name='assignments' defaultChecked={true} />
                                                <label htmlFor='Primary Assignment' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold  '>
                                                    {t('CL_EMP_CONDITION_CHECKLIST_HEADING', 'Condition/Check list')}
                                                </label>
                                                <div className='form_fields px-2'>
                                                    <GenericActionTable columns={CHECKLIST_TABLE_TC} hideSearchBar={true} hideFooter={true} rowData={checklistTableData} />
                                                </div>
                                            </div>
                                            <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                <input
                                                    type='checkbox'
                                                    id='CONSTRUCTION_LICENSE_Checkbox'
                                                    name='CONSTRUCTION_LICENSE'
                                                    checked={openSection === 'CONSTRUCTION_LICENSE'}
                                                    onChange={() => onSectionChange('CONSTRUCTION_LICENSE')}
                                                />
                                                <label
                                                    htmlFor='CONSTRUCTION_LICENSE_Checkbox'
                                                    className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                >
                                                    {t('', 'A - Construction License')}
                                                </label>
                                                {openSection === 'CONSTRUCTION_LICENSE' && RenewalConstruactionLicense()}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                <input
                                                    type='checkbox'
                                                    id='SELF_DECLARATION_Checkbox'
                                                    name='SELF_DECLARATION'
                                                    checked={openSection === 'SELF_DECLARATION'}
                                                    onChange={() => onSectionChange('SELF_DECLARATION')}
                                                />
                                                <label
                                                    htmlFor='SELF_DECLARATION_Checkbox'
                                                    className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                >
                                                    {t('', 'B - Self Declaration')}
                                                </label>
                                                {openSection === 'SELF_DECLARATION' && renderDeclarationDetails()}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                <input type='checkbox' id='PAYMENT_Checkbox' name='PAYMENT' checked={openSection === 'PAYMENT'} onChange={() => onSectionChange('PAYMENT')} />
                                                <label
                                                    htmlFor='PAYMENT_Checkbox'
                                                    className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                >
                                                    {t('', 'C - Payment Details')}
                                                </label>
                                                {openSection === 'PAYMENT' && renderPayment()}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </FormikProvider>
                    </Formik>
                    {!isViewOnly ? (
                        <GenericFooterComponent
                            onClickNext={() => {}}
                            onSubmit={() => {}}
                            onCancel={() => navigateToDashboard()}
                            onClickPrev={() => {}}
                            stepperLength={0}
                            activeIndex={activeIndex}
                            children={renderActionBtn()}
                            isSaveBtn={false}
                            isSubmitBtn={false}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};
export default M_RenewalOfConstructionLicense;
