import React, { useEffect, useState } from 'react';
import './OrderCompletionFormPage.scss';
import { Button, Card, CardBody, Image } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Formik, FormikProvider, useFormik } from 'formik';
import { Clearance_Form_Initial_Data, VALIDATION_ORDER_COMPLETION_FORM, stepsDetailsForOrderCompletion, stepsDetailsForOrderCompletionforFI } from './OrderCompletionFormPage.module';
import Loading from '../../components/portalComponents/Loading.component';
import { check, close, cross, warning } from '../../utils/Logo';
import { FIELD_INSPECTION_DETAILS, QUESTIONNAIRE_DOCUMENT_COLUMN, QUESTIONNAIRE_DOCUMENT_COLUMN_DEFAULT } from './OrderCompletionFormPage.const';
import HeaderComponent from '../../components/header/Header.component';
import { extractFileUrl, getNavigateTo } from '../../utils/utils';
import OrderCompletionFormSubPage from './OrderCompletionFormSubPage.wrapper';
import GenericPopupModal from '../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import GenericFooterComponent from '../../components/moduleCommonFormik/GenericFooterStepper.component';
import SideCard from '../../components/moduleCommonFormik/SideCardGenericComponent';
import { BPArequestInfo, POST, devUrl, endpoints, getUserDataOnRefresh, requestInfo } from '../../services/modulesServices/userManagement.api';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import { useTranslation } from 'react-i18next';
import { esignForDocuments, getDocsData, getFileurl } from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.controller';
import { OrderCompletionFormJson, createInspectionFormJson } from './OrderCompletionFormPage.controller';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { APPLICATION_TYPE, COMPLETION_ORDER, CONSTANT_DATA_FIELD, TENANT_ID } from '../../utils/constants';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import { ThreeDots } from 'react-loader-spinner';
import attachmentIcon from '../../assets/logos/attachment.svg';
import axios from 'axios';
import { QuestionnaireTable } from './QuestionnaireTable';

export default function OrderCompletionFormPage() {
    const { t, i18n } = useTranslation();
    const initialValues = Clearance_Form_Initial_Data;
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [activeStepper, setActiveStepper] = useState<string>('LESSEE_DETAITLS');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingForDoc, setIsLoadingForDoc] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showStatus, setShowStatus] = useState<boolean>(false);
    const [showProcess, setShowProcess] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [bodydata, setBodydata] = useState<string>('');
    const [employeeData, setEmployeeData] = useState(null);
    const [previewData, setPreviewData] = useState<any>(null);
    const [appStatus, setAppStatus] = useState<any>(false);
    const [isCancelBtn, setCancelBtn] = useState<any>(false);
    const [isShowActionPopup, setIsShowActionPopup] = useState<boolean>(false);
    const [bpaData, setBpaData] = useState<any>(null);
    const [selectedAction, setSelectedAction] = useState<any>('');
    const [allchecked, setAllChecked] = React.useState<any>([]);
    const [editorContent, setEditorContent] = useState('');
    const [formValues, setFormValues] = useState([{ emailId: '' }]);
    var userinfo = window.localStorage.getItem('user');
    const user_uuid = !!userinfo && JSON.parse(userinfo).uuid;
    const [payVisible, setPayVisible] = useState<boolean>(false);
    const [allBPADetails, setAllBPADetails] = useState<any>(null);
    const [isLesseeEmpty, setIsLesseeEmpty] = useState<boolean>(false);
    const [selectedBPA, setIsSelectedBPA] = useState<string>('');
    const [allEdcrDetails, setAllEdcrDetails] = useState<any>(null);
    const urlParams = new URLSearchParams(window.location.search);
    const [actionsData, setActionsData] = useState<any>(null);
    const [fieldInspection, setFieldInspectionData] = useState<any>(null);
    const applicationNumber = decodeURIComponent(urlParams.get('applicationNumber') || '');
    const [modalDetails, setModalDetails] = useState<any>({});
    const [eSignFlag, setESignFlag] = useState<boolean>(false);
    const [isScheduleActive, setIsScheduleActive] = useState<boolean>(false);
    const [questionnaireData, setQuestionnaireData] = useState<any>([]);
    let pendingStatus = 'APPL_STATUS_OC_SUBMISSION_PENDING';
    const stepperIndex = urlParams.get('stepper') || '';
    const signedFileStoreId = urlParams.get('signedFileStoreId') || '';
    const [searchParams] = useSearchParams();
    const [questionnaireDetails, setquestionnaireDetails] = useState<any>([]);
    // let questionnaireDetails = questionnaireData;
    let stepperDetails = !!applicationNumber && previewData?.status !== pendingStatus ? stepsDetailsForOrderCompletionforFI : stepsDetailsForOrderCompletion;
    stepperDetails.forEach((val: any) => {
        if (previewData?.applicationType !== 'CONSTRUCTION_LICENCE' && !!previewData && previewData?.status !== 'APPL_STATUS_OC_SUBMISSION_PENDING') val.isVisited = true;
        if (val?.index < 3) val.isVisited = true;
        if ((val?.code === 'DECLARATION' || val?.code === 'DOCUMENTS') && !!previewData?.lesseeDigitalSignFlage) val.isVisited = true;
    });
    const formik = useFormik<any>({
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: initialValues,
        onSubmit: value => {},
        validationSchema: VALIDATION_ORDER_COMPLETION_FORM[`step_${activeIndex}`],
        validateOnMount: true
    });
    const { values, resetForm, setFieldValue } = formik;

    const handleClose = () => {
        setShowPopup(false);
        setShowStatus(false);
        setIsShowActionPopup(false);
    };

    const handleSubmit = () => {
        setShowPopup(false);
        setShowStatus(false);
        if (activeStepper === 'DECLARATION') navigateToDashboard();
    };

    const handleStatusSubmit = () => {
        setShowStatus(false);
        navigateToDashboard();
    };

    useEffect(() => {
        setquestionnaireDetails(questionnaireData);
    }, [questionnaireData]);

    useEffect(() => {
        if (!!allBPADetails && allBPADetails.length === 0) {
            setModalDetails({ show: true, title: 'Warning!', para: t('OC_NO_EDCR_SHARED', 'No Data Available'), img: warning, type: 'warning', reUpload: 'OK' });
        }
    }, [allBPADetails]);

    const onChangeNextIndex = async (key: number) => {
        if (activeStepper === 'OCCUPANCY_DETAILS' && values.occupancyType === 'PART_OCCUPANCY') {
            try {
                await VALIDATION_ORDER_COMPLETION_FORM[`step_${activeIndex}`].validate(values, { abortEarly: false });
            } catch (e) {
                setModalDetails({
                    show: true,
                    title: 'Error!',
                    para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'),
                    img: cross,
                    type: 'error',
                    reUpload: 'OK'
                });
                return;
            }
        }
        setCancelBtn(false);
        const checkWithoutValidation = ['DOCUMENTS'];
        const stepperLength = stepperDetails.length;
        if (activeIndex < stepperLength) {
            if (!!appStatus) {
                setActiveIndex(activeIndex + 1);
                return;
            }

            // Check if the active stepper is OCCUPANCY_DETAILS
            if (activeStepper === 'OCCUPANCY_DETAILS') {
                // Get the values of Type of Occupancy and Occupancy Percent from formik values
                const typeOfOccupancy = values?.occupancyType;
                const occupancyPercent = values?.occupancy;

                // Check if both Type of Occupancy and Occupancy Percent are not filled
                if (!typeOfOccupancy && !occupancyPercent) {
                    setModalDetails({
                        show: true,
                        title: 'Error!',
                        para: t('OC_VALIDATION_PERCENT_FIELD', 'Please select the Occupancy type before proceeding'),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    return;
                }

                // Check if Type of Occupancy is not filled
                if (!typeOfOccupancy) {
                    setModalDetails({ show: true, title: 'Error!', para: t('OC_VALIDATION_OCTYPE_FIELD', 'Please select Type of Occupancy!'), img: cross, type: 'error', reUpload: 'OK' });

                    return;
                }

                submitForm();
                return;
            }

            if (activeStepper === 'DECLARATION' && !previewData?.lesseeDigitalSignFlage) {
                submitForm();
                return;
            }

            // NEED to rectify
            try {
                if (checkWithoutValidation.includes(activeStepper)) await VALIDATION_ORDER_COMPLETION_FORM[`step_${activeIndex}`].validate(values, { abortEarly: false });
                if (activeStepper === 'LESSEE_DETAITLS' && !!previewData) {
                    setActiveIndex(activeIndex + 1);
                    return;
                }
                if (activeStepper === 'DOCUMENTS' && !appStatus) {
                    submitForm();
                    return;
                }
                if (activeStepper === 'INSPECTION_DETAILS' && editorContent.trim() === '') {
                    setModalDetails({ show: true, title: 'Error!', para: 'Add a note to continue.', img: cross, type: 'error', reUpload: 'OK' });
                    return;
                } else {
                    return setActiveIndex(activeIndex + 1);
                }
            } catch (e) {
                console.log(JSON.stringify(e));
                setModalDetails({
                    show: true,
                    title: 'Error!',
                    para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'),
                    img: cross,
                    type: 'error',
                    reUpload: 'OK'
                });
            }
        }
    };

    const getActionsData = () => {
        if (!!applicationNumber) {
            setIsLoading(true);

            TechnicalServices.getBpaActions(applicationNumber, {
                RequestInfo: BPArequestInfo()
            })
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        setActionsData(response?.data);
                        response?.data?.nextValidAction?.forEach((val: any) => {
                            if (val?.action === 'Pay') {
                                setSelectedAction(val?.action);
                                setPayVisible(true);
                                return;
                            }
                        });
                    }

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    const getTechnicalClearanceData = () => {
        const payload = { RequestInfo: BPArequestInfo() };
        TechnicalServices.applyConstructionLicence(payload)
            .then(res => {
                if (res?.data) {
                    setAllBPADetails(res?.data?.ConstructionLicense || []);
                }
            })
            .catch(err => {
                setIsLesseeEmpty(true);
                setModalDetails({ show: true, title: 'Error!', para: err?.response?.data?.Errors[0]?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const searchBPA = (val: any) => {
        setIsLoading(true);
        if (!!val) {
            TechnicalServices.getBpaSearchForRefNo(val, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    // handle the response
                    setIsLoading(false);
                    if (!response?.data?.BPA.length) {
                        setBodydata('No Application Found');
                        setShowStatus(true);
                        setPreviewData(null);
                        return;
                    }
                    setPreviewData(response?.data?.BPA[0]);
                    setFormData(response?.data?.BPA[0]);
                    fetchInitialData(response?.data?.BPA[0]);
                    getActionsData();
                    setAppStatus(response?.data?.BPA[0]?.applicationType !== 'CONSTRUCTION_LICENCE' ? response?.data?.BPA[0]?.status !== pendingStatus : false);
                    // toast('Data Fetched Successfully!', {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'success'
                    // });
                })
                .catch(error => {
                    setShowStatus(true);
                    setBodydata('No Application Found');
                    setIsLoading(false);
                });
        } else {
            setModalDetails({ show: true, title: 'Error!', para: 'Something Went Wrong!', img: cross, type: 'error', reUpload: 'OK' });
            // toast('Something Went Wrong!!', {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'error'
            // });
            setIsLoading(false);
        }
    };

    const getQuestionnaires = (val: any) => {
        setIsLoading(true);
        if (!!val) {
            TechnicalServices.searchQuestionnaire(val, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    setIsLoading(false);
                    setQuestionnaireData(response?.data?.backQuestionnaires);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    const updateFieldInspection = (action?: string) => {
        if (action === 'reschedule') {
            setIsScheduleActive(true);
            return;
        }

        setIsLoading(true);

        const payload: any = createInspectionFormJson(values, fieldInspection, !!fieldInspection?.id);
        if (action === 'accept') {
            payload.FieldInspection.lesseeAcepted = true;
        }

        if (action === 'submit') {
            if (!values?.inspDate && !values?.inspTime) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please Fill required data', img: cross, type: 'error', reUpload: 'OK' });
                setIsLoading(false);
                return;
            }
            payload.FieldInspection.requestForRescheduleData = {
                scheduledDate: new Date(values?.inspDate).getTime(),
                scheduledTime: `${values?.inspTime}:00`,
                comment: values?.comment,
                requestDate: new Date(values?.toDate).getTime()
            };
            payload.FieldInspection.requestForReschedule = true;
        }

        TechnicalServices.updateFieldInspection(payload, !!fieldInspection?.id)
            .then((response: any) => {
                setIsLoading(false);

                // toast(`Field Inspection ${!!fieldInspection?.id ? 'Saved' : 'Created'} Successfully!`, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'success'
                // });

                setModalDetails({
                    show: true,
                    title: 'Success!',
                    para: t('POPUP_MSG_LESSEE_INSPECTION_ACCEPT', 'Inspection schedule has been accepted'),
                    img: check,
                    type: 'success',
                    reUpload: 'OK',
                    finalSubmit: true
                });

                setIsShowActionPopup(false);
                searchFieldInspection();
                // if (!!action && action !== "report")  submitForm(action);
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
                // toast(error?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'error'
                // });
            });
    };

    const searchFieldInspection = () => {
        values.FIELD_INSP_SITEPLAN = [];
        values.FIELD_INSP_SITEPLAN = [];
        setIsLoading(true);
        if (!!applicationNumber) {
            TechnicalServices.searchFieldInspectionData(applicationNumber, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    setIsLoading(false);
                    const preData = response?.data?.fieldInspections[0];
                    const insp = preData?.inspectionCheckListData;
                    setFieldInspectionData(response?.data?.fieldInspections[0]);
                    values.FIELD_INSP_SITEPLAN = getFileurl(preData?.documents, 'FIELD_INSP_SITEPLAN') || [];
                    values.FIELD_INSP_SITEPLAN_OTHER = getFileurl(preData?.documents, 'FIELD_INSP_SITEPLAN_OTHER') || [];
                    values.planapprovedNo = insp?.planapprovedNo;
                    values.planapprovedDate = new Date(insp?.planapprovedDate).getUTCDate();
                    values.clvalidation = insp?.clvalidation;
                    values.notvalidreason = insp?.notvalidreason;
                    values.appliedforoccupancy = insp?.appliedforoccupancy;
                    values.clearedthedump = insp?.clearedthedump;
                    values.sscertificatesubmission = insp?.sscertificatesubmission;
                    values.ccsubmission = insp?.ccsubmission;
                    values.otherremark = insp?.otherremark;
                })
                .catch(error => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepperDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const renderFieldInspectionForm = () => {
        return (
            <>
                {FIELD_INSPECTION_DETAILS.map(val => (
                    <Card.Body>
                        <GenericFormCard {...val} />
                    </Card.Body>
                ))}
            </>
        );
    };

    const handleStepClick = async (index: number) => {
        if (!!appStatus) {
            setActiveIndex(index);
            return;
        }

        // Check if the active stepper is OCCUPANCY_DETAILS
        if (activeStepper === 'OCCUPANCY_DETAILS') {
            // Get the values of Type of Occupancy and Occupancy Percent from formik values
            const typeOfOccupancy = values?.occupancyType;
            const occupancyPercent = values?.occupancy;

            // Check if both Type of Occupancy and Occupancy Percent are not filled
            if (!typeOfOccupancy && !occupancyPercent) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please select the Occupancy type before proceeding', img: cross, type: 'error', reUpload: 'OK' });
                // toast('Please fill Type of Occupancy and Occupancy Percent fields!', {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
                return;
            }

            // Check if Type of Occupancy is not filled
            if (!typeOfOccupancy) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please select Type of Occupancy!', img: cross, type: 'error', reUpload: 'OK' });
                // toast('Please select Type of Occupancy!', {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
                return;
            }
        }

        try {
            // await VALIDATION_CLEARANCE_FORM[`step_${activeIndex}`].validate(values, { abortEarly: false });
            setActiveIndex(index);
            window.scrollTo(0, 0);
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'warning'
            // });
        }
    };

    const fetchInitialData = (preData: any) => {
        values.name = preData?.lesseeDetails?.name;
        values.gender = preData?.lesseeDetails?.gender;
        values.phone = preData?.lesseeDetails?.phoneNo;
        values.panNo = preData?.lesseeDetails?.pan;
        values.email = preData?.lesseeDetails?.email;
        values.addressLine1 = preData?.lesseeDetails?.address?.addressLine1 || '';
        values.addressLine2 = preData?.lesseeDetails?.address?.addressLine2 || '';
        values.country = preData?.lesseeDetails?.address?.country;
        values.state = preData?.lesseeDetails?.address?.state;
        values.district = preData?.lesseeDetails?.address?.district;
        values.pincode = preData?.lesseeDetails?.address?.pincode;
        values.id = preData?.id;
        values.appId = preData?.applicationNo;
        values.archName = preData?.archName;
        values.archId = preData?.archRegId;
        values.serviceType = preData?.serviceType || 'NEW_CONSTRUCTION';
        values.licenseeType = '';
        values.CouncilArcNo = '';
        values.buildingPlan = [];
        values.token = '';
        values.cert = '';
        values.pin = '';
        values.documents = preData?.documents;
        values.forwardTo = preData?.lesseeDetails?.name;
        values.comments = '';
        values.notes = '';
        values.additionalDetails = preData?.additionalDetails;
        values.TECHNICAL_CLEARANCE_ORDER = getFileurl(preData?.documents, 'TECHNICAL_CLEARANCE_ORDER');
        values.CONSTRUCTION_LICENSE_ORDER = getFileurl(preData?.documents, 'CONSTRUCTION_LICENSE_ORDER');
        values.COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER = getFileurl(preData?.documents, 'COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER');
        values.REVISED_PLANS_INCORPORATING_DEVIATIONS_MADE_DURING_THE_EXECUTION = getFileurl(preData?.documents, 'REVISED_PLANS_INCORPORATING_DEVIATIONS_MADE_DURING_THE_EXECUTION');
        values.STRUCTURAL_STABILITY_CERTIFICATE = getFileurl(preData?.documents, 'STRUCTURAL_STABILITY_CERTIFICATE');
        values.isEditableFile = preData?.editable;
        values.FIRE_NOC = getFileurl(preData?.documents, 'FIRE_NOC');
        values.FACTORIES_AND_BOILERS_NOC = getFileurl(preData?.documents, 'FACTORIES_AND_BOILERS_NOC');
        values.isUpdatable = true;
        values.edcrNumber = preData?.edcrNumber;
        values.plotNo = preData?.additionalDetails?.plotNo;
        values.estate = preData?.additionalDetails?.estate;
        values.phase = preData?.additionalDetails?.phase;
        values.propertyType = preData?.additionalDetails?.propertyType;
        values.plotArea = preData?.additionalDetails?.plotArea;
        values.blocks = preData?.partOCDetails?.blocks || [{ floors: [{ floorType: '', floorNumber: '', builtUpArea: '' }] }];
        values.far = preData?.partOCDetails?.far;
        values.coverageArea = preData?.partOCDetails?.coverageArea;
        values.coveragePercent = preData?.partOCDetails?.coveragePercent;
        values.refApplication = previewData?.applicationType === 'OCCUPANCY_CERTIFICATE' ? preData?.refApplication : preData?.applicationNo;
        if (!!preData?.ocPercentage) {
            values.percentage = preData?.ocPercentage;
            values.occupancy = preData?.ocPercentage;
            values.occupancyType = preData?.ocPercentage === 100 ? 'FULL_OCCUPANCY' : 'PART_OCCUPANCY';
        }
    };

    const submitForm = async (action?: string, storeId?: any) => {
        try {
            if (activeStepper === 'OCCUPANCY_DETAILS' && values.occupancyType === 'PART_OCCUPANCY')
                await VALIDATION_ORDER_COMPLETION_FORM[`step_${activeIndex}`].validate(values, { abortEarly: false });
        } catch (e) {
            setModalDetails({
                show: true,
                title: 'Error!',
                para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            return;
        }

        if (!!previewData?.lesseeDigitalSignFlage) allchecked.length = 3;
        if (!!appStatus && !action) {
            navigateToDashboard();
            return;
        }

        if (activeStepper === 'DECLARATION' && allchecked.length !== 3 && !appStatus) {
            setModalDetails({ show: true, title: 'Declaration', para: t('CHECK_DECLARATION_PROCEED', 'Please check all declarations and proceed'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeStepper === 'DECLARATION' && !previewData?.lesseeDigitalSignFlage && action !== 'eSign') {
            setModalDetails({ show: true, title: 'E-Sign Pending', para: 'Please sign the document', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        setIsLoading(true);
        values.appId = applicationNumber;
        values.id = previewData?.applicationType === 'OCCUPANCY_CERTIFICATE' ? previewData?.id : '';
        const allDocs = [
            ...values?.CONSTRUCTION_LICENSE_ORDER,
            ...values?.TECHNICAL_CLEARANCE_ORDER,
            ...values?.COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER,
            ...values?.REVISED_PLANS_INCORPORATING_DEVIATIONS_MADE_DURING_THE_EXECUTION,
            ...values?.STRUCTURAL_STABILITY_CERTIFICATE
        ];
        const uniqueData = allDocs.filter((item, index, self) => index === self.findIndex(t => t.fileStoreId === item.fileStoreId));

        values.documents = uniqueData;

        const payload: any = OrderCompletionFormJson(values, previewData, !!previewData?.accountId);
        payload.BPA.applicationType = 'OCCUPANCY_CERTIFICATE';
        if (!!applicationNumber) payload.BPA.businessService = 'WF_BPA_OC';
        payload.BPA.ocPercentage = +values.occupancy;
        payload.BPA.documents = getDocsData(uniqueData, values?.appId);
        payload.BPA.id = previewData?.applicationType === 'OCCUPANCY_CERTIFICATE' ? previewData?.id : '';
        payload.BPA.applicationNo = applicationNumber || (previewData?.applicationType === 'OCCUPANCY_CERTIFICATE' ? previewData?.applicationNo : '');
        payload.BPA.refApplication = values?.refApplication || '';
        payload.BPA.status = previewData?.status;
        if (values.occupancyType === 'PART_OCCUPANCY') {
            payload.BPA.partOCDetails = {
                far: values?.far,
                coverageArea: values?.coverageArea,
                coveragePercent: values?.coveragePercent,
                blocks: values?.blocks
            };
        } else {
            payload.BPA.partOCDetails = null;
        }
        payload.BPA.workflow = null;
        if (activeStepper === 'DECLARATION' && action !== 'eSign')
            payload.BPA.workflow = {
                action: 'ACT_OC_SUBMIT',
                assignes: null,
                notes: values?.notes,
                varificationDocuments: null
            };
        if ((!!selectedAction || !!action) && action !== 'eSign')
            payload.BPA.workflow = {
                action: selectedAction || action,
                assignes: null,
                notes: values?.notes,
                varificationDocuments: null
            };

        payload.BPA.notes = {
            id: null, // Assuming it should have an ID, set as null or any specific value if needed
            content: values?.notes || '',
            notificationDetails: null // Assuming no specific notification details
        };

        if (action === 'eSign') {
            payload.BPA.lesseeDigitalSignFlage = true;
            payload.BPA.digitalSignedDoc = storeId;
        }

        TechnicalServices.createTechnicalClearance(payload)
            .then((response: any) => {
                setIsLoading(false);

                setPreviewData(response?.data?.BPA[0]);
                searchBPA(response?.data?.BPA[0]?.applicationNo);
                setFormData(response?.data?.BPA[0]);
                fetchInitialData(response?.data?.BPA[0]);
                // }
                if (action === 'eSign') {
                    setESignFlag(true);
                    return;
                }
                if (activeStepper === 'DOCUMENTS' || activeStepper === 'OCCUPANCY_DETAILS') setActiveIndex(activeIndex + 1);
                if (activeStepper === 'DECLARATION') setShowPopup(true);
            })
            .catch(err => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: err?.response?.data?.Errors[0]?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const onActionClick = (action: string) => {
        setFieldValue('comments', '');
        setSelectedAction(action);
        setIsShowActionPopup(true);
    };

    const handleModalClose = (type: string, final?: boolean) => {
        setModalDetails({ show: false });
        if (type === 'warning') {
            navigateToDashboard();
        }
        if (!!final) navigateToDashboard();
    };

    const initiateNoc = () => {
        setIsLoading(true);
        let source;
        if (formData?.applicationType === 'OCCUPANCY_CERTIFICATE') {
            source = COMPLETION_ORDER;
        }
        let payload: any = {
            RequestInfo: requestInfo(),
            Noc: {
                tenantId: TENANT_ID,
                applicationType: APPLICATION_TYPE,
                source: source,
                sourceRefId: formData?.applicationNo
            }
        };

        try {
            POST(endpoints.createNoc, payload)
                .then(res => {
                    setModalDetails({ show: true, title: 'Success!', para: 'Action successful', img: check, type: 'success', reUpload: 'OK' });
                    // toast('Action successful', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
                    navigate(`/goa-idc/noc?applicationNumber=${res.data.applicationNo}&tenantId=${TENANT_ID}`);
                    setIsLoading(false);
                })
                .catch(e => {
                    setModalDetails({ show: true, title: 'Error!', para: 'Action unsuccessful', img: cross, type: 'error', reUpload: 'OK' });
                    // toast('Action unsuccessful', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'error' });
                    setIsLoading(false);
                });
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('Comments are required'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('Comments are required'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'warning'
            // });
            setIsLoading(false);
        }
        console.log('Initiate NOC', payload);
    };

    const getActiveStepper = () => {
        stepperDetails.forEach(val => {
            if (val?.index === activeIndex) {
                setActiveStepper(val?.code);
            }
        });
    };

    const handelVisible = () => {
        setPayVisible(false);
    };

    const handleContentChange = (content: string) => {
        setEditorContent(content);
    };

    useEffect(() => {
        getActiveStepper();
        values.isEditableFile = true;
    }, [activeIndex]);

    const handleScroll = () => {
        if (window.scrollY > 110) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const checkRole = async () => {
        try {
            const { data } = await getUserDataOnRefresh();
            if (data) {
                if (data.type === 'EMPLOYEE') {
                    navigate(`/goa-idc/obpas/e/occupancyCertificate?applicationNumber=${applicationNumber}&tenantId=ga`);
                }
            }
        } catch (error) {}
    };

    const updateQuestionnaire = () => {
        questionnaireDetails.forEach((val: any) => {
            console.log(values[`ans_${val?.id}`]);
            val['answer'] = values[`ans_${val?.id}`];
        });
        console.log(questionnaireDetails);
        const remainingQuestionCount = questionnaireDetails.filter((val: any) => !val?.answer || val?.answer === null);
        if (remainingQuestionCount.length > 0) {
            setModalDetails({
                show: true,
                title: 'Error!',
                para: t('POPUP_MSG_QUESTIONNAIRE_ERROR', 'Please respond to the queries raised by the Field Manager in the Questionnaire section'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            return;
        }
        questionnaireDetails.forEach((val: any) => {
            val.lesseeReplyStatus = true;
        });

        let payload: any = {
            RequestInfo: BPArequestInfo(),
            backQuestionnaires: questionnaireDetails
        };

        TechnicalServices.manageQuestionnaire(payload, true)
            .then((response: any) => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Success', para: 'report submitted successfully', img: cross, type: 'success', reUpload: 'OK' });
                navigateToDashboard();
                // submitForm(selectedAction);
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const renderActionforSchedule = () => {
        return (
            <>
                {!isScheduleActive && !fieldInspection?.requestForReschedule && (
                    <GenericButtons text='Reschedule' variant='secondary' disabled={!!fieldInspection?.lesseeAcepted} handleClick={() => updateFieldInspection('reschedule')} />
                )}
                {!isScheduleActive && !fieldInspection?.requestForReschedule && (
                    <GenericButtons disabled={!!fieldInspection?.lesseeAcepted} variant='primary' text='Accept' handleClick={() => updateFieldInspection('accept')} />
                )}
                {!!isScheduleActive && !fieldInspection?.requestForReschedule && <GenericButtons type='primary' text='Submit' handleClick={() => updateFieldInspection('submit')} />}
            </>
        );
    };

    const renderActionforQuestion = () => {
        if (!!questionnaireData.length && !!previewData?.lesseeReplyPendding) {
            return (
                <>
                    <GenericButtons type='' variant='' text='Close' handleClick={() => navigateToDashboard()} />
                    <GenericButtons type='primary' variant='primary' text='Submit' handleClick={() => updateQuestionnaire()} />
                </>
            );
        } else {
            return <GenericButtons type='' variant='' text='Close' handleClick={() => navigateToDashboard()} />;
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (!!stepperIndex && stepperIndex === '4' && previewData && !previewData?.lesseeDigitalSignFlage) {
                setActiveIndex(4);
                setActiveStepper('DECLARATION');
                setAllChecked([{}, {}, {}]);
                submitForm('eSign', signedFileStoreId);
                setTimeout(() => {
                    setAllChecked([{}, {}, {}]);
                }, 1000);
            }
        }, 1500);
    }, [previewData]);

    const signDocument = async () => {
        // if (eSignedDocumentData) {

        if (activeStepper === 'DECLARATION' && allchecked.length !== 3) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            const payload = { RequestInfo: BPArequestInfo() };
            TechnicalServices.digitalSignDocument(previewData?.applicationNo, payload)
                .then(res => {
                    if (res?.data) {
                        esignSelf(res?.data?.fileStoreId);
                        // submitForm('eSign', res?.data?.fileStoreId);
                    }
                })
                .catch((e: any) => {
                    let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                });

            // setESignedDocumentData(data?.data);
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const esignSelf = async (fileStoreId: string) => {
        const redirectURL = `/goa-idc/obpas/occupancyCertificate?${searchParams.toString()}&applicationNumber=${values?.appId || applicationNumber}&note=${
            values.notes
        }&docsUuid=${fileStoreId}&stepper=4`;
        let url = window.location.origin + redirectURL;
        url = btoa(url);
        // navigate(`/goa-idc/emudra-esign?fileStoreId=${fileStoreId}&callbackUrl=${url}`);
        // esignForDocuments(fileStoreId, redirectURL);
        if (values.esignOption === 'EKYC') {
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${fileStoreId}&accessToken=${requestInfo().authToken}&userType=${requestInfo().userType}${
                    requestInfo().departmentCode ? `&departmentCode=${requestInfo().departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${fileStoreId}&callbackUrl=${url}`);
        }
    };

    useEffect(() => {
        searchFieldInspection();
        getQuestionnaires(applicationNumber);
    }, []);

    useEffect(() => {
        checkRole();
        if (!!applicationNumber) {
            setIsSelectedBPA(applicationNumber);
            return;
        }
        getTechnicalClearanceData();
    }, []);

    useEffect(() => {
        if (selectedBPA) searchBPA(selectedBPA);
    }, [selectedBPA]);

    useEffect(() => {
        if (!!fieldInspection && !fieldInspection?.lesseeAcepted) {
            setActiveIndex(5);
            setActiveStepper('INSPECTION_DETAILS');
        }
    }, [fieldInspection]);

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const removeFile = (id: any, current_id: any) => {
        setIsLoadingForDoc(true);
        let myallDcosArray_New = questionnaireDetails;

        myallDcosArray_New.forEach((val: any) => {
            if (val.id === current_id) {
                const filteredData = val?.documents?.filter((val: any) => val.rowId !== id);
                val.documents = filteredData;
            }
        });
        setquestionnaireDetails(myallDcosArray_New);
        setIsLoadingForDoc(false);
        setFieldValue('Docs', myallDcosArray_New);
        renderQuestionnaireDetails();
    };

    const renderQuestionnaireDetails = () => {
        const downloadFile = (id: any) => {
            if (!id) return;
            axios
                .get(devUrl + `/filestore/v1/files/url?tenantId=ga&fileStoreIds=${id}`)
                .then((response: any) => {
                    window.open(response?.data?.fileStoreIds[0]?.url);
                })
                .catch(error => {});
        };

        const handleOnChange = async (e: any, current_id: any) => {
            let myallDcosArray = questionnaireDetails;
            const file: any = e.target.files && e.target.files[0];
            try {
                if (!file) {
                    console.error('No file selected.');
                    return;
                }
                setIsLoadingForDoc(true);
                const response: any = await axios.post(
                    devUrl + '/filestore/v1/files',
                    {
                        file: file,
                        tenantId: 'ga',
                        tag: file.name,
                        module: 'hrms-upload'
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
                const fileResponseUrl = await axios.get(devUrl + '/filestore/v1/files/url', {
                    params: {
                        tenantId: 'ga',
                        fileStoreIds: response.data.files[0].fileStoreId
                    }
                });
                const fileData = {
                    documentName: fileResponseUrl?.data?.fileStoreIds[0].tag,
                    fileSize: fileResponseUrl?.data?.fileStoreIds[0].fileSize,
                    referenceId: fileResponseUrl?.data?.fileStoreIds[0].id,
                    documentUrl: extractFileUrl(fileResponseUrl.data.fileStoreIds[0].url),
                    createdDate: fileResponseUrl?.data?.fileStoreIds[0].uploadedDate,
                    rowId: fileResponseUrl?.data?.fileStoreIds[0].id,
                    fileStoreId: fileResponseUrl?.data?.fileStoreIds[0].id
                };
                setIsLoadingForDoc(false);
                // props.setQuestionnaireData(
                myallDcosArray.forEach((val: any) => {
                    if (val.id === current_id) val.documents.push(fileData);
                });
                setquestionnaireDetails(myallDcosArray);
                // );
            } catch (e) {
                setIsLoadingForDoc(false);
            }
        };
        return (
            <>
                {!!questionnaireDetails &&
                    questionnaireDetails.map((val: any, ind: number) => {
                        values[`que_${val?.id}`] = val?.question;
                        if (!!val?.answer) values[`ans_${val?.id}`] = val?.answer;
                        const myArray = {
                            formData: [
                                {
                                    title: 'Question',
                                    field: `que_${val?.id}`,
                                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                                    isRequired: true,
                                    isDisabled: true,
                                    titleCode: `OC_LBL_ENTERPRISE_NAMEs ${ind + 1}`,
                                    class: 'me-2'
                                },
                                {
                                    title: 'Answer',
                                    field: `ans_${val?.id}`,
                                    type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                                    isRequired: true,
                                    titleCode: 'Your Feedback',
                                    class: 'me-2',
                                    isDisabled: !!val?.lesseeReplyStatus
                                }
                            ]
                        };

                        return (
                            <>
                                <Card key={ind}>
                                    <CardBody>
                                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 my-2'>
                                            <input type='checkbox' id={`${val?.id}_${ind}`} name={`${val?.id}_${ind}`} defaultChecked={false} />
                                            <label htmlFor={`${val?.id}_${ind}`} className='d-flex collapsible_box_label align-items-center justify-content-between text-black  fw-bold  '>
                                                {t('', `Correction Report ${ind + 1}`)}
                                            </label>
                                            <div className='form_fields px-2'>
                                                <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 '>
                                                    <GenericFormCard {...myArray} key={ind} />
                                                </div>
                                                <div className='mt-3'>
                                                    {!val?.lesseeReplyStatus && (
                                                        <div className='d-flex justify-content-center align-self-center mb-4'>
                                                            {isLoadingForDoc ? (
                                                                <ThreeDots
                                                                    visible={true}
                                                                    height='80'
                                                                    width='80'
                                                                    color='#45852e'
                                                                    radius='9'
                                                                    ariaLabel='three-dots-loading'
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=''
                                                                />
                                                            ) : (
                                                                // <div className='col-12 note-attachfile text-center my-1 p-2'>
                                                                //     <Card>
                                                                //         <CardBody>
                                                                //             <Image src={attachmentIcon} alt='Attach Document Icon' /> <span className='cursor-pointer'>Upload Document</span>
                                                                //             <input className='uploadBox upload-document cursor-pointer' type='file' onChange={e => handleOnChange(e, val?.id)} />
                                                                //         </CardBody>
                                                                //     </Card>
                                                                // </div>
                                                                <div className='col-11 note-attachfile text-center my-1 p-2 mt-2  mb-2 justify-content-center align-items-center noc_add_fields'>
                                                                    <Image src={attachmentIcon} alt='Attach Document Icon' /> <span className='cursor-pointer'>{t('', 'Upload Document')}</span>
                                                                    <input className='uploadBox upload-document cursor-pointer' type='file' onChange={e => handleOnChange(e, val?.id)} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {!!val?.documents.length && (
                                                        <QuestionnaireTable
                                                            key={ind}
                                                            column={!!val?.lesseeReplyStatus ? QUESTIONNAIRE_DOCUMENT_COLUMN_DEFAULT : QUESTIONNAIRE_DOCUMENT_COLUMN}
                                                            tableRowData={val?.documents || []}
                                                            additionalMethod={() => {}}
                                                            deleteOwnershipDetails={id => removeFile(id, val?.id)}
                                                            updateOwnershipDataStatus={(data: any) => downloadFile(data?.rowId ?? data?.fileStoreId)}
                                                            isDisabled={false}
                                                            hideSearchBar
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </>
                        );
                    })}
                {!questionnaireDetails.length && (
                    <Card>
                        <CardBody>
                            <p className='text-center fw-bold pt-2'>No Data Available</p>
                        </CardBody>
                    </Card>
                )}
            </>
        );
    };

    const checkSubmitBtn = () => {
        if (previewData?.lesseeReplyPendding && activeIndex === stepperDetails.length) return false;
        return !(!!fieldInspection && !fieldInspection?.lesseeAcepted && activeStepper === 'INSPECTION_DETAILS');
    };
    // console.log(formik);

    const successMessageOCSubmitted = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} Submitted successfully`;

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            <StatusAlertPopup title='' para={bodydata} img={close} handleClose={handleClose} handleSubmit={handleStatusSubmit} show={showStatus} />
            <div className={`applicationPage m-auto px-5 py-4 ${activeStepper === 'OCCUPANCY_DETAILS' || 'QUESTIONNAIRE' ? 'h-100' : 'vh-100'}`}>
                <div className='col-sm-12'>
                    <GenericHeader
                        text={t('OC_HEADER_APPLICATION_FOR_OCCUPANCY_CERTIFICATE', 'Application for Completion Order & Occupancy Certificate')}
                        additionalClass='sub-text-style'
                        bottomSubText={`Type : ${t('OC_HEADER_TYPE', 'Completion Order & Occupancy Certificate')}, No : ${values?.refApplication || applicationNumber || 'NA'}`}
                    />
                </div>
                <div className='row d-flex my-4'>
                    <div className='col-lg-3 align-self-start stickyCard-section'>
                        <div className='stickyCard' style={{ top: '110px' }}>
                            <GenericHeader
                                text={t('OC_SUBHEADER_APPLICATION_FOR_OCCUPANCY_CERTIFICATE', 'Application for Completion Order & Occupancy Certificate')}
                                sideHeader={true}
                                additionalClass='sub-text-style'
                                bottomSubText={`Type : ${t('OC_SUBHEADER_TYPE', 'Completion Order & Occupancy Certificate')}, No : ${values?.refApplication || applicationNumber || 'NA'}`}
                            />
                            <Card className='card_border p-3 mb-4'>
                                <Card.Body>
                                    <Card.Text className=' d-flex flex-column row-gap-3  '>
                                        <SideCard indexType='alphabets' steps={stepperDetails} handleStepClick={handleStepClick} activeIndex={activeIndex} />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <div className='col-lg-9 me-auto mb-5'>
                        <Formik initialValues={initialValues} onSubmit={value => {}} validationSchema={VALIDATION_ORDER_COMPLETION_FORM}>
                            <FormikProvider value={formik}>
                                <Form>
                                    <OrderCompletionFormSubPage
                                        index={activeIndex}
                                        activeStepper={activeStepper}
                                        setActiveIndex={setActiveIndex}
                                        employeeList={employeeData}
                                        setEditorContent={setEditorContent}
                                        editorContent={editorContent}
                                        formData={formData}
                                        previewData={previewData}
                                        appStatus={appStatus}
                                        showProcess={showProcess}
                                        allchecked={allchecked}
                                        setAllChecked={setAllChecked}
                                        bpaData={bpaData}
                                        isCancelBtn={isCancelBtn}
                                        setShowProcess={setShowProcess}
                                        setFormValues={setFormValues}
                                        formValues={formValues}
                                        searchBPA={(value: any) => searchBPA(value)}
                                        allBPADetails={allBPADetails}
                                        allEdcrDetails={allEdcrDetails}
                                        setIsSelectedBPA={setIsSelectedBPA}
                                        applicationNumber={applicationNumber}
                                        fieldInspection={fieldInspection}
                                        onActionClick={updateFieldInspection}
                                        signDocument={signDocument}
                                        eSignFlag={eSignFlag}
                                        isScheduleActive={isScheduleActive}
                                        setIsScheduleActive={setIsScheduleActive}
                                        questionnaireData={questionnaireData}
                                        setQuestionnaireData={setQuestionnaireData}
                                        renderQuestionnaireDetails={renderQuestionnaireDetails}
                                        questionnaireDetails={questionnaireDetails}
                                    />
                                    <GenericPopupModal
                                        title=''
                                        subTitle={`Reschedule Field Inspection`}
                                        isVisible={isShowActionPopup}
                                        primaryBtnTitle={'Reschedule'}
                                        secondaryAction={handleClose}
                                        primaryAction={() => {
                                            updateFieldInspection('submit');
                                        }}
                                        secondaryBtnTitle='Close'
                                        children={renderFieldInspectionForm()}
                                    />
                                </Form>
                            </FormikProvider>
                        </Formik>
                    </div>
                </div>
            </div>
            {
                // !appStatus && !(activeIndex === stepperDetails.length)
                <GenericFooterComponent
                    isSaveBtn={false}
                    onClickNext={onChangeNextIndex}
                    onClickSave={onChangeNextIndex}
                    onSubmit={() => submitForm()}
                    onCancel={() => navigateToDashboard()}
                    onClickPrev={onChangePrevIndex}
                    stepperLength={stepperDetails.length}
                    activeIndex={activeIndex}
                    isSubmitBtn={checkSubmitBtn()}
                    secondaryBtnLabel={
                        previewData?.status === 'APPL_STATUS_OC_SUBMISSION_PENDING' && activeStepper === 'DOCUMENTS'
                            ? t('SAVE_AND_NEXT', 'Save & Next')
                            : !previewData?.lesseeDigitalSignFlage && activeStepper === 'DECLARATION'
                            ? t('SUBMIT', 'Submit')
                            : !!appStatus && activeIndex === stepperDetails.length
                            ? 'Close'
                            : !appStatus && activeIndex === stepperDetails.length
                            ? 'Submit'
                            : !!appStatus
                            ? 'Next'
                            : 'Next'
                    }
                    children={
                        !!fieldInspection && !fieldInspection?.lesseeAcepted && activeStepper === 'INSPECTION_DETAILS' ? (
                            renderActionforSchedule()
                        ) : !!questionnaireData.length && !!previewData?.lesseeReplyPendding && activeStepper === 'QUESTIONNAIRE' ? (
                            renderActionforQuestion()
                        ) : (
                            <></>
                        )
                    }
                />
            }
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.finalSubmit)}
            />

            <StatusAlertPopup
                img={check}
                title={'Success!'}
                // para={'Application Submitted Successfully'}
                para={successMessageOCSubmitted}
                fontsize={'fontsize'}
                // children={
                //     <div>
                //         <span className='fw-bold font-14'>Your Application Number</span>: <b className='font-15 text-success'>{previewData?.applicationNo}</b>
                //     </div>
                // }
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                show={showPopup}
            />
        </>
    );
}
