import { toast } from 'react-toastify';
import { fetchDropdownData, devUrl } from '../../../services/modulesServices/userManagement.api';
import {
    ALLOTMENT,
    CGST_SGST,
    CORRESPONDENCE,
    ENTERPRISE,
    EXTENSION,
    HOUSE_TAX,
    LAND_PREMIUM,
    LAND_PREMIUM_INTEREST,
    LEASE_RENT,
    PERMANENT,
    SUBLEASE_FEE,
    TENANT_ID,
    TRANSFER,
    WATER_CHARGES
} from '../../../utils/constants';
import { DocumentDetails, dropdownOptionsInterface, formOptionsDTO } from '../../../components/moduleCommonFormik/GenericFormCard.constants';
import { getDropdownSelectedObject } from '../UserManagement/UserMangement.controller';
import { IEntityDocumentDetails } from '../UserManagement/types';
import { DOCUMENT_TYPE } from './Migration.const';
import axios from 'axios';
import { changeDateFormat, changeDateFormatToddMMyyyy, changeDateToYYYYMMDD, convertDateToDDmmYYYY, extractFileUrl, getCorrectDateFormate } from '../../../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { getEnterpriseData, getInitPlotData } from '../EstateManagement/Allotment/Allotment.controller';
import { entityDocumentData } from '../EstateManagement/EstateManagement.controller';

export const setEntityDocumentDetails: any = (data: IEntityDocumentDetails) => {
    if (data) {
        const { documentUrl, documentSize, uploadedDate, ...doc } = data;
        return doc;
    }
};

export const getEntityDocumentDetails = async (data: any) => {
    if (data) {
        try {
            const res = await axios.get(devUrl + '/filestore/v1/files/url', { params: { tenantId: 'ga', fileStoreIds: data.docReferenceId } });
            const docs = res.data.fileStoreIds.map((item: IEntityDocumentDetails, index: number) => ({
                documentName: res?.data?.fileStoreIds[index].tag,
                documentType: data.documentType,
                referenceType: data.referenceType,
                docReferenceId: data.docReferenceId,
                documentUrl: extractFileUrl(res.data.fileStoreIds[index].url),
                documentSize: res?.data?.fileStoreIds[index].fileSize,
                uploadedDate: res?.data?.fileStoreIds[0].uploadedDate
            }));

            return docs[0];
        } catch (e) {
            toast('Failed to fetch the document, Please try again after some time', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar',
                type: 'error'
            });
            return null;
        }
    }
};

export const setObpasEntityDocumentDetails = (data: any) => {
    if (data) {
        const { documentUrl, documentSize, uploadedDate, docReferenceId, ...doc } = data;
        return { fileStoreId: docReferenceId, ...doc };
    }
};

export const getSubLeasePayload = (currentPayload: any, ind: number, subleaseNicDetails: any, subLeaseDetails: any) => {
    const subLeaseEntityDetails = currentPayload.subLeaseEntityDetails;

    const currentObject =
        subLeaseDetails && subLeaseDetails?.length ? subLeaseDetails?.find((val: any) => val?.subLeaseEntityDetails?.panNumber === currentPayload?.subLeaseEntityDetails?.panNumber) : {};

    const subLeaseEntityAddress = [
        {
            addressType: 'CORRESPONDENCE',
            address1: subLeaseEntityDetails.permanantAddress.peraddress1,
            address2: subLeaseEntityDetails.permanantAddress.peraddress2,
            pinCode: subLeaseEntityDetails.permanantAddress.perpincode,
            district: subLeaseEntityDetails.permanantAddress.perdistrict,
            state: subLeaseEntityDetails.permanantAddress.perstate,
            country: subLeaseEntityDetails.permanantAddress.percountry
        },
        { ...subLeaseEntityDetails.address, pinCode: subLeaseEntityDetails.address?.pincode, addressType: 'PERMANENT' }
    ];

    const allDocuments = subLeaseEntityDetails?.documents && Object.values(subLeaseEntityDetails?.documents)?.map((document: any) => setEntityDocumentDetails(document));

    const subLeaseEnterpriseDetails = {
        ...currentPayload.subLeaseEnterpriseDetails,
        gstApplicable: currentPayload?.subLeaseEnterpriseDetails?.applicantGst,
        gstNumber: currentPayload?.subLeaseEnterpriseDetails?.applicantGstno,
        enterpriseSubCategories: currentPayload.subLeaseEnterpriseDetails.subCategory?.map((val: any) => val.code),
        estimatedWaterRequirement: currentPayload.subLeaseEnterpriseDetails.waterRequirement.code,
        estimatedPowerRequirement: currentPayload.subLeaseEnterpriseDetails.powerRequirement.code,
        pollutionCategory: currentPayload.subLeaseEnterpriseDetails.pollutionCategory.code,
        enterpriseCategory: currentPayload.subLeaseEnterpriseDetails.enterpriseCategory.code,
        estimatedNumberOfPersonsToBeEmployed: currentPayload.subLeaseEnterpriseDetails.employement.code,
        nicEnterpriseMappingRequests: subleaseNicDetails[ind].map((e: any) => {
            return { nicMstId: e.nicMstId, proposedActivity: e.proposedActivity };
        })
    };
    const enterpriseDocument = (subLeaseEnterpriseDetails?.documents && Object.values(subLeaseEnterpriseDetails?.documents)?.map((document: any) => setEntityDocumentDetails(document))) || [];

    const { address, permanantAddress, phoneNumber, ...otherDetails } = subLeaseEntityDetails;

    const subleaseWaterPayment = currentPayload?.subLeaseWaterDetails?.map((item: any, index: number) =>
        currentPayload.subLeaseWaterDetails[index].subleaseWaterFeeDue === true
            ? {
                  paymentType: WATER_CHARGES,
                  dueDate: item.waterChargesDueDate,
                  principalAmount: item.waterChargesPrincipalAmount,
                  penaltyAmount: item.waterChargesPenaltyAmount,
                  cgstPenalty: item.cgstOnWaterChargesPenaltyAmount,
                  sgstPenalty: item.sgstOnWaterChargesPenaltyAmount,
                  totalDueAmount: (
                      +(item.waterChargesPrincipalAmount || 0) +
                      +(item.waterChargesPenaltyAmount || 0) +
                      +(item.cgstOnWaterChargesPenaltyAmount || 0) +
                      +(item.sgstOnWaterChargesPenaltyAmount || 0)
                  )
                      .toFixed(2)
                      .toString(),
                  arrears: {
                      principalAmount: item.waterChargesArrearPrincipalAmount || 0,
                      penaltyAmount: item.waterChargesArrearPenaltyAmount || 0,
                      cgstPenalty: item.cgstOnWaterChargesArrearPenaltyAmount || 0,
                      sgstPenalty: item.sgstOnWaterChargesArrearPenaltyAmount || 0,
                      totalDueAmount: (
                          +(item.waterChargesArrearPrincipalAmount || 0) +
                          +(item.waterChargesArrearPenaltyAmount || 0) +
                          +(item.cgstOnWaterChargesArrearPenaltyAmount || 0) +
                          +(item.sgstOnWaterChargesArrearPenaltyAmount || 0)
                      )
                          .toFixed(2)
                          .toString()
                  },
                  currentMeterReading: item.waterChargesLastMeterReading,
                  currentMeterReadingDate: convertDateToDDmmYYYY(item.waterChargeslastMeterReadingDate),
                  meterNumber: item.meterNo
              }
            : {
                  paymentType: WATER_CHARGES,
                  existingPaymentAmount: item?.waterChargesExistingAmount,
                  paymentDate: item?.waterChargesLastPaymentDate || '',
                  dueDate: item?.waterChargesNextDueDate,
                  invoiceRefId: item?.waterChargesPaymentReceipt?.docReferenceId || '',
                  currentMeterReading: item.waterChargesLastMeterReading,
                  currentMeterReadingDate: convertDateToDDmmYYYY(item.waterChargeslastMeterReadingDate),
                  meterNumber: item.meterNo
              }
    );

    console.log('waterPay', subleaseWaterPayment);

    const paymentDetails: any = [
        {
            paymentType: SUBLEASE_FEE,
            principalAmount: currentPayload.paymentDetails.principalAmount || 0,
            cgstPrincipal: currentPayload.paymentDetails.cgstPrincipalAmount || 0,
            sgstPrincipal: currentPayload.paymentDetails.sgstPrincipalAmount || 0,
            penaltyAmount: currentPayload.paymentDetails.penaltyAmount || 0,
            cgstPenalty: currentPayload.paymentDetails.cgstPenaltyAmount || 0,
            sgstPenalty: currentPayload.paymentDetails.sgstPenaltyAmount || 0,
            totalDueAmount:
                (
                    +(currentPayload.paymentDetails.principalAmount || 0) +
                    +(currentPayload.paymentDetails.cgstPrincipalAmount || 0) +
                    +(currentPayload.paymentDetails.sgstPrincipalAmount || 0) +
                    +(currentPayload.paymentDetails.penaltyAmount || 0) +
                    +(currentPayload.paymentDetails.cgstPenaltyAmount || 0) +
                    +(currentPayload.paymentDetails.sgstPenaltyAmount || 0)
                )
                    .toFixed(2)
                    .toString() || null,
            dueDate: currentPayload.paymentDetails.dueDate || currentPayload.paymentDetails.nextDueDate,
            existingPaymentAmount: currentPayload.paymentDetails.existingSubLeaseFee || null,
            invoiceRefId: currentPayload.paymentDetails?.uploadReceipt?.docReferenceId || null,
            paymentDate: currentPayload.paymentDetails.lastPaymentDate || null,
            arrears: {
                principalAmount: currentPayload.paymentDetails.arrearPrincipalAmount || 0,
                cgstPrincipal: currentPayload.paymentDetails.cgstArrearPrincipalAmount || 0,
                sgstPrincipal: currentPayload.paymentDetails.sgstArrearPrincipalAmount || 0,
                penaltyAmount: currentPayload.paymentDetails.arrearPenaltyAmount || 0,
                cgstPenalty: currentPayload.paymentDetails.cgstArrearPenaltyAmount || 0,
                sgstPenalty: currentPayload.paymentDetails.sgstArrearPenaltyAmount || 0,
                totalDueAmount:
                    (
                        +(currentPayload.paymentDetails.arrearPrincipalAmount || 0) +
                        +(currentPayload.paymentDetails.cgstArrearPrincipalAmount || 0) +
                        +(currentPayload.paymentDetails.sgstArrearPrincipalAmount || 0) +
                        +(currentPayload.paymentDetails.arrearPenaltyAmount || 0) +
                        +(currentPayload.paymentDetails.cgstArrearPenaltyAmount || 0) +
                        +(currentPayload.paymentDetails.sgstArrearPenaltyAmount || 0)
                    )
                        .toFixed(2)
                        .toString() || null
            }
        },
        ...(currentPayload.subleaseWater ? subleaseWaterPayment : [])
    ].filter(Boolean);

    const getSubleaseWaterConnectionDetails = currentPayload?.subLeaseWaterDetails?.map((item: any) => ({
        applicationDetails: {
            waterType: item.waterType?.code,
            pipeDiaMm: item.connectionPipeDia.code,
            monthlyWaterConsumptionM3: item.monthlyConsumption,
            waterConnectionCharge: null,
            securityDeposit: item.securityDeposit,
            adjustedSecurityDeposit: null,
            cgst: null,
            sgst: null,
            totalWaterConnectionCharge: null
        },
        connectionDate: convertDateToDDmmYYYY(item.connectionDate),
        meterNo: item.meterNo,
        waterType: item.waterType?.code
    }));

    const newPayload = {
        ...(currentObject ? currentObject : {}),
        subLeaseEntityDetails: {
            ...otherDetails,
            tenantId: 'ga',
            phoneNumber: subLeaseEntityDetails.phoneNumber?.length > 0 ? subLeaseEntityDetails.phoneNumber : null,
            addresses: subLeaseEntityAddress,
            category: subLeaseEntityDetails.category?.code,
            authUsers: [
                {
                    ...subLeaseEntityDetails.user,
                    documents: [setEntityDocumentDetails(subLeaseEntityDetails?.documents?.authorizedPersonPowerOfAttorneyDocument) || null].filter(Boolean),
                    tenantId: 'ga',
                    type: 'ENTERPRISE'
                }
            ],
            miscDetails: {
                isUdhyamRegistered: currentPayload.subLeaseOtherDetails.udhyamRegisteredEntity,
                udyamNumber: currentPayload?.subLeaseOtherDetails?.udyamNo || null
                // udyamRegistrationCertificate: currentPayload.udyamRegistrationCertificate ? setEntityDocumentDetails(currentPayload.udyamRegistrationCertificate) : null
            },
            documents: allDocuments.filter(Boolean),
            gstNumber: subLeaseEntityDetails.gstNumber ? subLeaseEntityDetails.gstNumber : undefined,
            uuid: currentObject?.subLeaseEntityDetails?.uuid ? currentObject?.subLeaseEntityDetails?.uuid : otherDetails.uuid ? otherDetails.uuid : undefined
        },
        subLeaseEnterpriseDetails: {
            ...subLeaseEnterpriseDetails,
            documents: enterpriseDocument.filter(Boolean),
            subCategory: subLeaseEnterpriseDetails.subCategory?.code
        },
        subLeaseTermStartDate: currentPayload.subLeaseStartDate, //new Date(currentPayload.subLeaseStartDate).getTime(),
        subLeaseTermEndDate: currentPayload.subLeaseEndDate, //new Date(currentPayload.subLeaseEndDate).getTime(),
        area: currentPayload.subLeaseArea,
        isSpecialProject: currentPayload.subLeaseSpecialProject?.code,
        // documents: currentPayload?.documents && Object.values(currentPayload?.documents)?.map((document: any) => setEntityDocumentDetails(document)),
        // paymentDetails: paymentDetails.map((val: any) => Object.values(val).every(subItem => subItem)).filter(Boolean),
        paymentDetails: paymentDetails.filter(Boolean),
        subleaseOrderDocReferenceId: currentPayload?.subLeaseOrder?.docReferenceId,
        nocForSpecialProjectDocRefId: currentPayload?.documents?.inPrincipleApproval?.docReferenceId,
        waterConnectionMigrationDetailsList: currentPayload.subleaseWater ? getSubleaseWaterConnectionDetails : undefined
    };

    return newPayload;
};

export const paymentDetailsPayload = (values: any) => {
    let installmentPayload: any = [];
    installmentPayload = installmentCalculation(values);

    let dueDate = new Date(values.nextInstallmentDate);
    dueDate.setFullYear(dueDate.getFullYear() - 1);

    const paymentPayload =
        values.landPremiumDue === true
            ? [
                  {
                      totalLandPremiumAmount: values?.totalLandPremiumAmount,
                      existingPaymentAmount: values?.totalPaidLandPremiumPrincipalAmount,
                      dueDate: changeDateFormat(dueDate),
                      paymentType: LAND_PREMIUM,
                      paymentDate: changeDateFormat(dueDate)
                  },
                  ...installmentPayload
              ]
            : [
                  {
                      paymentType: LAND_PREMIUM,
                      paidAmount: values.totalLandPremiumAmount,
                      paymentDate: values?.landPremiumLastPaymentdate || '',
                      invoiceRefId: values?.landPremiumPaymentReceipt?.docReferenceId || ''
                  }
              ];

    const leaseRentPayload: any =
        values.leaseRentPaymentStatus === true
            ? {
                  paymentType: LEASE_RENT,
                  dueDate: values.leaseRentDueDate,
                  principalAmount: values.leaseRentPrincipalAmount,
                  cgstPrincipal: values.cgstOnLeaseRentPrincipleAmount,
                  sgstPrincipal: values.sgstOnLeaseRentPrincipleAmount,
                  penaltyAmount: values.leaseRentPenaltyAmount,
                  cgstPenalty: values.cgstOnLeaseRentPenaltyAmount,
                  sgstPenalty: values.sgstOnLeaseRentPenaltyAmount,
                  totalDueAmount: (
                      +(values.leaseRentPrincipalAmount || 0) +
                      +(values.cgstOnLeaseRentPrincipleAmount || 0) +
                      +(values.sgstOnLeaseRentPrincipleAmount || 0) +
                      +(values.leaseRentPenaltyAmount || 0) +
                      +(values.cgstOnLeaseRentPenaltyAmount || 0) +
                      +(values.sgstOnLeaseRentPenaltyAmount || 0)
                  )
                      .toFixed(2)
                      .toString(),
                  arrears: {
                      principalAmount: values.leaseRentArrearPrincipalAmount || 0,
                      cgstPrincipal: values.cgstOnLeaseRentArrearPrincipalAmount || 0,
                      sgstPrincipal: values.sgstOnLeaseRentArrearPrincipalAmount || 0,
                      penaltyAmount: values.leaseRentArrearPenaltyAmount || 0,
                      cgstPenalty: values.cgstOnLeaseRentArrearPenaltyAmount || 0,
                      sgstPenalty: values.sgstOnLeaseRentArrearPenaltyAmount || 0,
                      totalDueAmount: (
                          +(values.leaseRentArrearPrincipalAmount || 0) +
                          +(values.cgstOnLeaseRentArrearPrincipalAmount || 0) +
                          +(values.sgstOnLeaseRentArrearPrincipalAmount || 0) +
                          +(values.leaseRentArrearPenaltyAmount || 0) +
                          +(values.cgstOnLeaseRentArrearPenaltyAmount || 0) +
                          +(values.sgstOnLeaseRentArrearPenaltyAmount || 0)
                      )
                          .toFixed(2)
                          .toString()
                  },
                  incrementDate: values.leaseRentIncrementDate
              }
            : {
                  paymentType: LEASE_RENT,
                  incrementDate: values.leaseRentIncrementDate,
                  existingPaymentAmount: values.existingLeaseRentAmount,
                  paymentDate: values.leaseRentLastPaymentDate,
                  dueDate: values?.LeaseRentNextDueDate || '',
                  invoiceRefId: values?.leaseRentPaymentReceipt?.docReferenceId || ''
              };
    paymentPayload.push(leaseRentPayload);

    const houseTaxPayload: any =
        values.houseTaxPaymentStatus === true
            ? {
                  paymentType: HOUSE_TAX,
                  dueDate: values.houseTaxDueDate,
                  principalAmount: values.houseTaxPrincipalAmount,
                  penaltyAmount: values.houseTaxPenaltyAmount,
                  cgstPenalty: values.cgstOnHouseTaxPenaltyAmount,
                  sgstPenalty: values.sgstOnHouseTaxPenaltyAmount,
                  totalDueAmount: (
                      +(values.houseTaxPrincipalAmount || 0) +
                      +(values.houseTaxPenaltyAmount || 0) +
                      +(values.cgstOnHouseTaxPenaltyAmount || 0) +
                      +(values.sgstOnHouseTaxPenaltyAmount || 0)
                  )
                      .toFixed(2)
                      .toString(),
                  arrears: {
                      principalAmount: values.houseTaxArrearPrincipalAmount || 0,
                      penaltyAmount: values.houseTaxArrearPenaltyAmount || 0,
                      cgstPenalty: values.cgstOnHouseTaxArrearPenaltyAmount || 0,
                      sgstPenalty: values.sgstOnHouseTaxArrearPenaltyAmount || 0,
                      totalDueAmount: (
                          +(values.houseTaxArrearPrincipalAmount || 0) +
                          +(values.houseTaxArrearPenaltyAmount || 0) +
                          +(values.cgstOnHouseTaxArrearPenaltyAmount || 0) +
                          +(values.sgstOnHouseTaxArrearPenaltyAmount || 0)
                      )
                          .toFixed(2)
                          .toString()
                  }
              }
            : {
                  paymentType: HOUSE_TAX,
                  existingPaymentAmount: values?.existingHouseTaxAmount,
                  paymentDate: values?.houseTaxLastPaymentDate || '',
                  dueDate: values?.houseTaxNextDueDate,
                  invoiceRefId: values?.houseTaxPaymentReceipt?.docReferenceId || ''
              };
    values?.houseTaxApplicable && paymentPayload.push(houseTaxPayload);

    const waterChargesPayload: any =
        values.migrationWaterCharge &&
        values?.migrationWaterCharge?.map((value: any) =>
            values.waterChargesPaymentStatus === true
                ? {
                      paymentType: WATER_CHARGES,
                      meterNumber: value.waterChargeMeterNo?.code,
                      currentMeterReading: value.waterChargesLastMeterReading,
                      currentMeterReadingDate: convertDateToDDmmYYYY(value.waterChargeslastMeterReadingDate),
                      dueDate: value.waterChargesDueDate,
                      principalAmount: value.waterChargesPrincipalAmount,
                      penaltyAmount: value.waterChargesPenaltyAmount,
                      cgstPenalty: value.cgstOnWaterChargesPenaltyAmount,
                      sgstPenalty: value.sgstOnWaterChargesPenaltyAmount,
                      totalDueAmount: (
                          +(value.waterChargesPrincipalAmount || 0) +
                          +(value.waterChargesPenaltyAmount || 0) +
                          +(value.cgstOnWaterChargesPenaltyAmount || 0) +
                          +(value.sgstOnWaterChargesPenaltyAmount || 0)
                      )
                          .toFixed(2)
                          .toString(),
                      arrears: {
                          principalAmount: value.waterChargesArrearPrincipalAmount || 0,
                          penaltyAmount: value.waterChargesArrearPenaltyAmount || 0,
                          cgstPenalty: value.cgstOnWaterChargesArrearPenaltyAmount || 0,
                          sgstPenalty: value.sgstOnWaterChargesArrearPenaltyAmount || 0,
                          totalDueAmount: (
                              +(value.waterChargesArrearPrincipalAmount || 0) +
                              +(value.waterChargesArrearPenaltyAmount || 0) +
                              +(value.cgstOnWaterChargesArrearPenaltyAmount || 0) +
                              +(value.sgstOnWaterChargesArrearPenaltyAmount || 0)
                          )
                              .toFixed(2)
                              .toString()
                      }
                  }
                : {
                      paymentType: WATER_CHARGES,
                      meterNumber: value.waterChargeMeterNo?.code,
                      currentMeterReading: value.waterChargesLastMeterReading,
                      currentMeterReadingDate: convertDateToDDmmYYYY(value.waterChargeslastMeterReadingDate),
                      existingPaymentAmount: value?.waterChargesExistingAmount,
                      paymentDate: value?.waterChargesLastPaymentDate || '',
                      dueDate: value?.waterChargesNextDueDate,
                      invoiceRefId: value?.waterChargesPaymentReceipt?.docReferenceId || ''
                  }
        );

    values?.waterConnection && paymentPayload.push(...waterChargesPayload);
    return paymentPayload;
};

const getSubLeaseFeePayload = (data: any, existingSubleaseEntityDetailsResponse: any) => {
    existingSubleaseEntityDetailsResponse.forEach((item: any) => {
        const sameEntityName = data.find((val: any) => item.entityName === val.entityName);
        if (sameEntityName) {
            item.paymentDetails = [
                {
                    paymentType: SUBLEASE_FEE,
                    principalAmount: sameEntityName.principalAmount || null,
                    cgstPrincipal: sameEntityName.cgstPrincipalAmount || null,
                    sgstPrincipal: sameEntityName.sgstPrincipalAmount || null,
                    penaltyAmount: sameEntityName.penaltyAmount || null,
                    cgstPenalty: sameEntityName.cgstPenaltyAmount || null,
                    sgstPenalty: sameEntityName.sgstPenaltyAmount || null,
                    totalDueAmount:
                        (
                            +(sameEntityName.principalAmount || 0) +
                            +(sameEntityName.cgstPrincipalAmount || 0) +
                            +(sameEntityName.sgstPrincipalAmount || 0) +
                            +(sameEntityName.penaltyAmount || 0) +
                            +(sameEntityName.cgstPenaltyAmount || 0) +
                            +(sameEntityName.sgstPenaltyAmount || 0)
                        )
                            .toFixed(2)
                            .toString() || null,
                    dueDate: sameEntityName.dueDate || sameEntityName.nextDueDate,
                    existingPaymentAmount: sameEntityName.existingSubLeaseFee || null,
                    invoiceRefId: sameEntityName?.uploadReceipt?.docReferenceId || null,
                    paymentDate: sameEntityName.lastPaymentDate || null,
                    arrears: {
                        principalAmount: sameEntityName.arrearPrincipalAmount || null,
                        cgstPrincipal: sameEntityName.cgstArrearPrincipalAmount || null,
                        sgstPrincipal: sameEntityName.sgstArrearPrincipalAmount || null,
                        penaltyAmount: sameEntityName.arrearPenaltyAmount || null,
                        cgstPenalty: sameEntityName.cgstArrearPenaltyAmount || null,
                        sgstPenalty: sameEntityName.sgstArrearPenaltyAmount || null,
                        totalDueAmount:
                            (
                                +(sameEntityName.arrearPrincipalAmount || 0) +
                                +(sameEntityName.cgstArrearPrincipalAmount || 0) +
                                +(sameEntityName.sgstArrearPrincipalAmount || 0) +
                                +(sameEntityName.arrearPenaltyAmount || 0) +
                                +(sameEntityName.cgstArrearPenaltyAmount || 0) +
                                +(sameEntityName.sgstArrearPenaltyAmount || 0)
                            )
                                .toFixed(2)
                                .toString() || null
                    }
                }
            ];
        }
    });

    return existingSubleaseEntityDetailsResponse;
};

export const migrationPayload = (
    values: any,
    controllingOwnershipResponse: any,
    activeIndex: number,
    searchedData: any,
    existingSubleaseEntityDetailsResponse: any,
    nicDetails: any,
    subleaseNicDetails: any,
    response: any,
    editMigrationResponse: any,
    isUpdate: boolean
) => {
    const { applicantDetails, enterpriseDetails, obpasDetails, otherDetails, paymentDetails, plotDetails, subLeaseDetails, ...rest } = editMigrationResponse;

    const tcMap = new Map(obpasDetails?.technicalClearance.map((item: any) => [item.orderNo, item]));

    const clMap = new Map(obpasDetails?.constructionLicense?.map((item: any) => [item.orderNo, item]));

    const clocMap = new Map(obpasDetails?.CLOC?.map((item: any) => [item.orderNo, item]));

    const buildingPlanPayload = {
        technicalClearance: values?.technicalClearance?.map((techClearance: any, _: number) => ({
            ...techClearance,
            validUpto: techClearance.validUpto && new Date(techClearance.validUpto).getTime(),
            orderDate: new Date(techClearance.orderDate).getTime(),
            serviceType: techClearance.serviceType ? techClearance.serviceType?.map((type: any, _: number) => type.code).join(',') : '',
            documents: techClearance?.documents && Object.values(techClearance?.documents)?.map(setObpasEntityDocumentDetails).filter(Boolean)
        })),
        constructionLicense: values?.constructionLicense?.map((constLicense: any, _: number) => ({
            ...constLicense,
            validUpto: new Date(constLicense?.validUpto).getTime(),
            technicalClearanceOrderNumber: constLicense.technicalClearanceOrderNumber?.code,
            estimatedConstructionCost: +constLicense?.estimatedConstructionCost,
            industryClassification: constLicense?.industryClassification?.code,
            orderDate: new Date(constLicense.orderDate).getTime(),
            documents: constLicense?.documents && Object.values(constLicense?.documents)?.map(setObpasEntityDocumentDetails).filter(Boolean)
        })),
        CLOC: values?.cooc?.map((coocData: any, _: number) => ({
            ...coocData,
            validUpto: coocData?.validUpto && new Date(coocData?.validUpto).getTime(),
            constructionLicenseOrderNumber: coocData.constructionLicenseOrderNumber?.code,
            orderDate: new Date(coocData.orderDate).getTime(),
            documents: coocData?.documents && Object.values(coocData?.documents).map(setObpasEntityDocumentDetails).filter(Boolean)
        }))
    };

    const subLeaseDetailsData = activeIndex === 7 ? values?.subLeaseDetailsdata?.map((data: any, ind: any) => getSubLeasePayload(data, ind, subleaseNicDetails, subLeaseDetails)) : null;

    const existingSubleaseEntityDetails = activeIndex === 4 && values.isSamePlotApplicationExist && getSubLeaseFeePayload(values?.subLeaseFeePayment, existingSubleaseEntityDetailsResponse);

    const mortgageMigrationRequestPayload = values?.mortgageMigrationRequests.map((val: any) => ({
        ...val,
        nocDocReferenceId: val.nocDocReferenceId?.docReferenceId || null,
        mortgageType: val.mortgageType?.code
    }));

    const migrationWaterConnectionPayload =
        values.migrationWaterConnection &&
        values?.migrationWaterConnection?.map((value: any) => ({
            applicationDetails: {
                waterType: value?.waterType?.code,
                pipeDiaMm: value?.connectionPipeDia?.code,
                monthlyWaterConsumptionM3: value?.monthlyConsumption,
                waterConnectionCharge: null,
                securityDeposit: value?.securityDeposit,
                adjustedSecurityDeposit: null,
                cgst: null,
                sgst: null,
                totalWaterConnectionCharge: null
            },
            connectionDate: convertDateToDDmmYYYY(value.connectionDate),
            meterNo: value?.meterNo,
            waterType: value?.waterType?.code
        }));
    const enterpriseSubCategoryList = values?.subCategory && values?.subCategory?.map((val: any) => val.code);

    const estateMigrationData = {
        ...rest,
        uuid: isUpdate ? rest?.uuid : values?.estateMigrationDataUuid || '',
        migrationApplicationNumber: isUpdate ? rest?.migrationApplicationNumber : values?.migrationApplicationNumber || '',
        entityUserUuid: isUpdate ? applicantDetails.user.uuid : values?.entityUserUuid || '',
        entityId: isUpdate ? rest?.entityId : values?.entityUuid || '',
        entityName: isUpdate ? rest?.entityName : values.applicantName,
        isEnterpriseDetailsUpdate: activeIndex === 3 && !values.isSamePlotApplicationExist,
        estateApplicationId: values.estateApplicationId || '',
        entityRefDetailsId: values.entityRefDetailsId || '',
        plotId: isUpdate ? rest?.plotId : values.plotNo?.code,
        plotNo: values.plotNo?.value,
        isApplicantDetailsUpdate: activeIndex === 1 && true,
        applicantDetails: {
            ...(applicantDetails ? applicantDetails : {}),
            ...searchedData,
            tenantId: TENANT_ID,
            entityName: values.applicantName,
            category: values?.category?.code,
            email: values.emailId,
            phoneNumber: values.phoneNumber || null,
            panNumber: values.applicantPanNo,
            addresses: [
                {
                    ...applicantDetails?.addresses?.[0],
                    ...searchedData?.addresses?.[0],
                    addressType: PERMANENT,
                    address1: values.address1,
                    address2: values.address2 || null,
                    pinCode: values.pincode,
                    district: values.district,
                    city: null,
                    state: values.state,
                    country: values.country
                },
                {
                    ...applicantDetails?.addresses?.[1],
                    ...searchedData?.addresses?.[1],
                    addressType: CORRESPONDENCE,
                    address1: values.peraddress1,
                    address2: values.peraddress2 || null,
                    pinCode: values.perpincode,
                    district: values.perdistrict,
                    city: null,
                    state: values.perstate,
                    country: values.percountry
                }
            ],
            authUsers: [
                {
                    ...(applicantDetails?.authUsers && applicantDetails?.authUsers[0]),
                    ...(searchedData?.authUsers && searchedData?.authUsers[0]),
                    firstName: values.firstName,
                    lastName: values.lastName,
                    primaryMobileNo: values.pmobileNumber,
                    alternateMobileNo: values.alternateMobileNumber || null,
                    email: values.authorizedEmailId,
                    tenantId: TENANT_ID,
                    type: ENTERPRISE,
                    documents: [setEntityDocumentDetails(values?.letter) || null].filter(Boolean)
                }
            ],
            owners: controllingOwnershipResponse,
            entityBankDetail: {
                ...applicantDetails?.entityBankDetail,
                ...searchedData?.entityBankDetail,
                accountNumber: values.accountNumber,
                ifscCode: values.ifscCode,
                bankName: values.bankName,
                branchAddress: values.branchAddress
            },
            miscDetails: {
                ...applicantDetails?.miscDetails,
                ...searchedData?.miscDetails,
                isUdhyamRegistered: values.udhyam,
                isDpiitCertified: values.isStartup,
                udyamNumber: values.udyamNo,
                experience: values?.experienceCategory?.code,
                // isWomenEntreprenueur: values.womenEntrepreneur,
                // isLocalPerson: values.localPerson,
                isExperienced: values.priorExperience
            },
            documents: [
                setEntityDocumentDetails(values?.entityProprietorshipFirm) || null,
                setEntityDocumentDetails(values?.entityProprietorIdentity) || null,
                setEntityDocumentDetails(values?.trustDeed) || null,
                setEntityDocumentDetails(values?.partnershipDeed) || null,
                setEntityDocumentDetails(values?.certificateOfRegistrationFirms) || null,
                setEntityDocumentDetails(values?.certificateOfIncorporation) || null,
                setEntityDocumentDetails(values?.certificateOfRegistrationSocieties) || null,
                setEntityDocumentDetails(values?.copyRelevantNotification) || null,
                setEntityDocumentDetails(values?.applicantPancard) || null,
                setEntityDocumentDetails(values?.authorizedPersonPancard) || null,
                setEntityDocumentDetails(values?.letter) || null,
                setEntityDocumentDetails(values?.RegCertificate) || null,
                setEntityDocumentDetails(values?.DPIITCertificate) || null,
                // setEntityDocumentDetails(values?.womenEntreprenuershipDocument) || null,
                setEntityDocumentDetails(values?.experienceCertificate) || null
            ].filter(Boolean),
            user: isUpdate ? applicantDetails?.user : searchedData?.user
        },
        enterpriseDetails:
            activeIndex >= 3 && !values.isSamePlotApplicationExist
                ? {
                      ...(enterpriseDetails ? enterpriseDetails : {}),
                      enterpriseCategory: values?.plotType?.code || '',
                      enterpriseSubCategories: enterpriseSubCategoryList,
                      //   nationalIndustrialCode: values?.enterpriseClassificationCode || '',
                      specialProject: values?.specialProject,
                      //   subCategoryDetails: values?.subCategoryDetails || '',
                      pollutionCategory: values?.pollutionCategory?.code || '',
                      estimatedWaterRequirement: values?.waterRequirement?.code || '',
                      estimatedPowerRequirement: values?.powerRequirement?.code || '',
                      estimatedNumberOfPersonsToBeEmployed: values?.employement?.code || '',
                      documents: [
                          setEntityDocumentDetails(values?.enterpriseSpecialProjectCase) || null,
                          setEntityDocumentDetails(values?.enterpriseProjectReport) || null,
                          values.applicantGst ? setEntityDocumentDetails(values?.applicantGstdocument) || null : null,
                          !values?.applicantGst ? setEntityDocumentDetails(values?.entitygstUndertaking) || null : null
                      ].filter(Boolean),
                      uuid: values.enterpriseDetailsUuid,
                      proposedActivity: values.subCategoryDetails,
                      gstApplicable: values.applicantGst,
                      gstNumber: values.applicantGst ? values?.applicantGstno || null : undefined,
                      nicEnterpriseMappingRequests: nicDetails.map((e: any) => {
                          return { nicMstId: e.nicMstId, proposedActivity: e.proposedActivity };
                      }),
                      projectType: values.projectType?.code
                  }
                : enterpriseDetails
                ? { ...enterpriseDetails }
                : null,
        paymentDetails:
            activeIndex >= 6 || (activeIndex >= 3 && values.isSamePlotApplicationExist)
                ? {
                      ...(paymentDetails ? paymentDetails : {}),
                      isPaymentDetailsUpdate: (activeIndex === 6 || (activeIndex === 3 && values.isSamePlotApplicationExist)) && true,
                      lastMeterReading: values.waterChargesLastMeterReading,
                      pendencyReportDocReferenceId: values?.pendencyReport?.docReferenceId,
                      payments: paymentDetailsPayload(values),
                      uuid: values.paymentDetailsUuid
                  }
                : paymentDetails
                ? { ...paymentDetails }
                : null,

        isObpasDetailsUpdate: activeIndex === 5,
        // obpasDetails:
        //     activeIndex >= 5
        //         ? {
        //               ...response.obpasDetails,
        //               id: isUpdate ? obpasDetails?.id : values.obpasDetailsUuid || null,
        //               technicalClearance: buildingPlanPayload.technicalClearance
        //                   .flatMap((item: any) =>
        //                       Object.values(item).every(value => !value)
        //                           ? null
        //                           : obpasDetails?.technicalClearance?.length
        //                           ? obpasDetails?.technicalClearance
        //                                 ?.map((val: any) => {
        //                                     if (val.orderNo === item.orderNo) {
        //                                         return { ...val, ...item };
        //                                     }
        //                                 })
        //                                 .filter(Boolean)
        //                           : response?.obpasDetails?.technicalClearance?.length
        //                           ? response?.obpasDetails?.technicalClearance
        //                                 ?.map((val: any) => {
        //                                     if (val.orderNo === item.orderNo) {
        //                                         return { ...val, ...item };
        //                                     }
        //                                 })
        //                                 .filter(Boolean)
        //                           : item
        //                   )
        //                   .filter(Boolean),

        //               constructionLicense: buildingPlanPayload.constructionLicense
        //                   .flatMap((item: any) =>
        //                       Object.values(item).every(value => !value)
        //                           ? null
        //                           : obpasDetails?.constructionLicense?.length
        //                           ? obpasDetails?.constructionLicense
        //                                 ?.map((val: any) => {
        //                                     if (val.orderNo === item.orderNo) {
        //                                         return { ...val, ...item };
        //                                     }
        //                                 })
        //                                 .filter(Boolean)
        //                           : response?.obpasDetails?.constructionLicense?.length
        //                           ? response?.obpasDetails?.constructionLicense
        //                                 ?.map((val: any) => {
        //                                     if (val.orderNo === item.orderNo) {
        //                                         return { ...val, ...item };
        //                                     }
        //                                 })
        //                                 .filter(Boolean)
        //                           : item
        //                   )
        //                   .filter(Boolean),
        //               CLOC: buildingPlanPayload.CLOC.flatMap((item: any) =>
        //                   Object.values(item).every(value => !value)
        //                       ? null
        //                       : obpasDetails?.CLOC?.length
        //                       ? obpasDetails?.CLOC?.map((val: any) => {
        //                             if (val?.orderNo === item.orderNo) {
        //                                 return { ...val, ...item };
        //                             }
        //                         }).filter(Boolean)
        //                       : response?.obpasDetails?.CLOC?.length
        //                       ? response?.obpasDetails?.CLOC?.map((val: any) => {
        //                             if (val?.orderNo === item.orderNo) {
        //                                 return { ...val, ...item };
        //                             }
        //                         }).filter(Boolean)
        //                       : item
        //               ).filter(Boolean)
        //           }
        //         : obpasDetails
        //         ? { ...obpasDetails }
        //         : null,

        obpasDetails:
            activeIndex >= 5
                ? {
                      //   ...response.obpasDetails,
                      id: values.obpasDetailsUuid || null,
                      technicalClearance: buildingPlanPayload.technicalClearance.map((item: any) => (Object.values(item).every(value => !value) ? null : item)).filter(Boolean),
                      constructionLicense: buildingPlanPayload.constructionLicense.map((item: any) => (Object.values(item).every(value => !value) ? null : item)).filter(Boolean),
                      CLOC: buildingPlanPayload.CLOC.map((item: any) => (Object.values(item).every(value => !value) ? null : item)).filter(Boolean)
                  }
                : obpasDetails
                ? { ...obpasDetails }
                : null,

        isOtherDetailsUpdate: activeIndex === 4 && !values.isSamePlotApplicationExist,
        otherDetails:
            activeIndex >= 4 && !values.isSamePlotApplicationExist
                ? {
                      extensionMigrationRequest:
                          values?.isExtension === true
                              ? {
                                    allotmentRequest: {
                                        ...response.otherDetails?.extensionMigrationRequest?.allotmentRequest,
                                        allotmentId: values?.extensionOrderNumber || '',
                                        allotmentDate: values?.extensionDate || '',
                                        allotmentType: EXTENSION,
                                        allotmentSubType: null,
                                        documents: values?.extensionOrder?.documentName
                                            ? [
                                                  {
                                                      documentName: values?.extensionOrder?.documentName,
                                                      documentType: values?.extensionOrder?.documentType,
                                                      docReferenceId: values?.extensionOrder?.docReferenceId,
                                                      docReferenceType: values?.extensionOrder?.referenceType
                                                  }
                                              ]
                                            : [],
                                        plotRate: null,
                                        uuid: values.allotmentUuid
                                    },
                                    isDeedExecuted: values?.isDeedOfExtensionExecuted || null,
                                    isDeedRegistered: values?.isDeedOfExtensionRegistered || null,
                                    deedMigrationRequest: {
                                        ...response.otherDetails?.extensionMigrationRequest?.deedMigrationRequest,
                                        registeredDeedDocRefId: values?.registeredDeedOfExtension?.docReferenceId || '',
                                        executedDeedDocRefId: values?.executedDeedOfExtension?.docReferenceId || '',
                                        termStartDate: values?.leaseTermStartDateExtension || '',
                                        deedRegistrationDate: values?.deedOfExtensionRegistrationDate || '',
                                        termEndDate: values?.leaseTermEndDateExtension || '',
                                        applicationNumber: values.deedAppNo,
                                        deedExecutionDate: values?.deedOfExtensionExecutionDate || ''
                                    },
                                    otherDocument: values?.otherDocumentExtension?.documentName
                                        ? {
                                              documentName: values?.otherDocumentExtension?.documentName,
                                              docReferenceId: values?.otherDocumentExtension?.docReferenceId
                                          }
                                        : null
                                }
                              : null,
                      allotmentMigrationRequest:
                          values?.plotActionType?.code === ALLOTMENT
                              ? {
                                    allotmentRequest: {
                                        ...response.otherDetails?.allotmentMigrationRequest?.allotmentRequest,
                                        allotmentId: values?.provisionalAllotmentOrderNumber || '',
                                        allotmentDate: values?.allotmentDate || '',
                                        allotmentType: ALLOTMENT,
                                        allotmentSubType: values?.allotmentType?.code || '',
                                        documents: values?.allotmentOrder?.documentName
                                            ? [
                                                  {
                                                      documentName: values?.allotmentOrder?.documentName,
                                                      documentType: values?.allotmentOrder?.documentType,
                                                      docReferenceId: values?.allotmentOrder?.docReferenceId,
                                                      docReferenceType: values?.allotmentOrder?.referenceType
                                                  }
                                              ]
                                            : [],
                                        plotRate: null,
                                        uuid: values.allotmentUuid
                                    },
                                    isDeedExecuted: values?.isLeaseDeedExecuted || null,
                                    isDeedRegistered: values?.isLeaseDeedRegistered || null,
                                    deedMigrationRequest: {
                                        ...response.otherDetails?.allotmentMigrationRequest?.deedMigrationRequest,
                                        registeredDeedDocRefId: values?.registeredLeaseDeed?.docReferenceId || '',
                                        executedDeedDocRefId: values?.executedLeaseDeed?.docReferenceId || '',
                                        termStartDate: values?.leaseTermStartDate || '',
                                        deedRegistrationDate: values?.leaseDeedRegistrationDate || '',
                                        termEndDate: values?.leaseTermEndDate || '',
                                        applicationNumber: values.deedAppNo,
                                        deedExecutionDate: values?.leaseDeedExecutionDate || ''
                                    },
                                    otherDocument: values?.otherDocumentAllotment?.documentName
                                        ? {
                                              documentName: values?.otherDocumentAllotment?.documentName,
                                              docReferenceId: values?.otherDocumentAllotment?.docReferenceId
                                          }
                                        : null
                                }
                              : null,
                      transferMigrationRequest:
                          values?.plotActionType?.code === TRANSFER
                              ? {
                                    allotmentRequest: {
                                        ...response.otherDetails?.transferMigrationRequest?.allotmentRequest,
                                        allotmentId: values?.transferOrderNumber || '',
                                        allotmentDate: values?.transferDate || '',
                                        allotmentType: TRANSFER,
                                        allotmentSubType: null,
                                        documents: values?.transferOrder?.documentName
                                            ? [
                                                  {
                                                      documentName: values?.transferOrder?.documentName,
                                                      documentType: values?.transferOrder?.documentType,
                                                      docReferenceId: values?.transferOrder?.docReferenceId,
                                                      docReferenceType: values?.transferOrder?.referenceType
                                                  }
                                              ]
                                            : [],

                                        plotRate: null,
                                        uuid: values.allotmentUuid
                                    },
                                    isDeedExecuted: values?.isTripartiteDeedExecuted || null,
                                    isDeedRegistered: values?.isTripartiteDeedRegistered || null,
                                    deedMigrationRequest: {
                                        ...response.otherDetails?.transferMigrationRequest?.deedMigrationRequest,
                                        registeredDeedDocRefId: values?.registeredTripartiteDeed?.docReferenceId || '',
                                        executedDeedDocRefId: values?.executedTripartiteDeed?.docReferenceId || '',
                                        termStartDate: values?.leaseTermStartDateTransfer || '',
                                        deedRegistrationDate: values?.tripartiteDeedRegistrationDate || '',
                                        termEndDate: values?.leaseTermEndDateTransfer || '',
                                        applicationNumber: values.deedAppNo,
                                        deedExecutionDate: values?.tripartiteDeedExecutionDate || ''
                                    },
                                    leaseDeedMigrationRequestForTransfer: {
                                        ...response.otherDetails?.transferMigrationRequest?.leaseDeedMigrationRequestForTransfer,
                                        termStartDate: values.originalLeasetermStartDate,
                                        applicationNumber: values.transferDeedAppNo
                                    },
                                    otherDocument: values?.otherDocumentTransfer?.documentName
                                        ? {
                                              documentName: values?.otherDocumentTransfer?.documentName,
                                              docReferenceId: values?.otherDocumentTransfer?.docReferenceId
                                          }
                                        : null
                                }
                              : null,
                      //   mortgageMigrationRequests: values.mortgageStatus ? mortgageMigrationRequestPayload : null,

                      mortgageMigrationRequests: values.mortgageStatus
                          ? response.otherDetails?.mortgageMigrationRequests
                              ? mortgageMigrationRequestPayload?.map((val: any) => {
                                    const currentObject = response?.otherDetails?.mortgageMigrationRequests?.find((item: any) => item?.nocOrderNumber === val?.nocOrderNumber);
                                    if (currentObject) {
                                        return { ...currentObject, ...val };
                                    } else {
                                        return { ...val };
                                    }
                                })
                              : mortgageMigrationRequestPayload
                          : null,

                      //   waterConnectionMigrationDetailsList: values.waterConnection
                      //       ? response.otherDetails?.waterConnectionMigrationDetailsList
                      //           ? response.otherDetails?.waterConnectionMigrationDetailsList
                      //           : migrationWaterConnectionPayload
                      //       : null,

                      waterConnectionMigrationDetailsList: values.waterConnection
                          ? response.otherDetails?.waterConnectionMigrationDetailsList
                              ? migrationWaterConnectionPayload
                                    ?.map((val: any) => {
                                        const currentObject = response?.otherDetails?.waterConnectionMigrationDetailsList?.find((item: any) => item.meterNo === val?.meterNo);
                                        if (currentObject) {
                                            return { ...currentObject, ...val };
                                        } else {
                                            return { ...val };
                                        }
                                    })
                                    .filter(Boolean)
                              : migrationWaterConnectionPayload
                          : null,

                      isSublease: values?.sublease,
                      uuid: values.otherDetailsUuid
                  }
                : otherDetails
                ? { ...otherDetails }
                : null,
        isExistingSubleasePaymentsUpdate: activeIndex === 4 && values.isSamePlotApplicationExist ? true : false,
        isSamePlotApplicationExist: values?.isSamePlotApplicationExist,
        existingSubleaseEntityDetails: activeIndex === 4 && values.isSamePlotApplicationExist ? existingSubleaseEntityDetails : existingSubleaseEntityDetailsResponse,
        isSubLeaseDetailsUpdate: activeIndex === 7,
        subLeaseDetails: activeIndex >= 7 ? subLeaseDetailsData : subLeaseDetails ? [...subLeaseDetails] : null
    };

    console.log(mortgageMigrationRequestPayload, estateMigrationData?.otherDetails?.mortgageMigrationRequests);

    return estateMigrationData;
};

export const dropdownDataOptions = async () => {
    const dropdownPayload = {
        RequestInfo: {},
        MdmsCriteria: {
            tenantId: 'ga',
            moduleDetails: [
                {
                    moduleName: 'common-masters',
                    masterDetails: [
                        {
                            name: 'GenderType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'EntityCategory',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'QueryDivision',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'QueryType',
                            filter: '[?(@.active == true)]'
                        }
                    ]
                },
                {
                    moduleName: 'Estate',
                    masterDetails: [
                        {
                            name: 'EmploymentCount',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'EnterpriseProjectType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'EnterpriseSubcategory',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'FinanceMeans',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'PollutionCategory',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'PowerRequirement',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'ProjectExpansionType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'WaterRequirement',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'OrderType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'EnterpriseProjectType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'Experience',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'SpecialTransferType',
                            filter: '[?(@.active == true)]'
                        }
                    ]
                },
                {
                    moduleName: 'BPA',
                    masterDetails: [
                        {
                            name: 'ServiceType',
                            filter: '[?(@.active == true)]'
                        }
                    ]
                },
                {
                    moduleName: 'ws-services-masters',
                    masterDetails: [
                        {
                            name: 'WaterType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'PipeDiameter',
                            filter: '[?(@.active == true)]'
                        }
                    ]
                }
                // {
                //     moduleName: 'common-masters',
                //     masterDetails: [
                //         {
                //             name: 'QueryDivision',
                //             filter: '[?(@.active == true)]'
                //         },
                //         {
                //             name: 'QueryType',
                //             filter: '[?(@.active == true)]'
                //         }
                //     ]
                // }
            ]
        }
    };
    try {
        const res = await fetchDropdownData(dropdownPayload);
        const updateServiceType = res['BPA']['ServiceType'].map((item: formOptionsDTO, _: number) => ({ code: item?.code, name: item.title }));
        const updatePipeConnection = res['ws-services-masters']['PipeDiameter'].map((item: any, _: number) => ({ code: item?.size, name: item.code }));
        {
            const dropdownOptions = {
                gender: res['common-masters']['GenderType'],
                category: res['common-masters']['EntityCategory'],
                ownershipGender: res['common-masters']['GenderType'],
                queryType: res['common-masters']['QueryType'],
                division: res['common-masters']['QueryDivision'],
                waterType: res['ws-services-masters']['WaterType'],
                connectionPipeDia: updatePipeConnection,
                ownershipHighestQualification: [
                    { name: 'Post Graduate', code: 'POST_GRADUATE' },
                    { name: 'Graduate or Diploma', code: 'GRADUATE_OR_DIPLOMA' }
                ],
                authorizedPersonGender: res['common-masters']['GenderType'],
                builtUpArea: [
                    { name: 'Yes', code: true },
                    { name: 'No', code: false }
                ],
                outstandingDues: [
                    { name: 'Yes', code: true },
                    { name: 'No', code: false }
                ],
                disqualifiedEntity: [
                    { name: 'Yes', code: true },
                    { name: 'No', code: false }
                ],
                industrialToCommercial: [
                    { name: 'Yes', code: true },
                    { name: 'No', code: false }
                ],
                subLeaseSpecialProject: [
                    { name: 'Yes', code: true },
                    { name: 'No', code: false }
                ],
                enterpriseCategory: [
                    { name: 'Industrial', code: 'Industrial' },
                    { name: 'Commercial', code: 'Commercial' }
                ],
                subCategory: res['Estate']['EnterpriseSubcategory'],
                specialProject: [
                    { name: 'Yes', code: 'true' },
                    { name: 'No', code: 'false' }
                ],
                pollutionCategory: res['Estate']['PollutionCategory'],
                waterRequirement: res['Estate']['WaterRequirement'],
                orderType: res['Estate']['OrderType'],
                powerRequirement: res['Estate']['PowerRequirement'],
                employement: res['Estate']['EmploymentCount'],
                meansOfFinance: res['Estate']['FinanceMeans'],
                declaration: [
                    { name: 'Yes', code: true },
                    { name: 'No', code: false }
                ],
                priorExperience: [
                    { name: 'Yes', code: true },
                    { name: 'No', code: false }
                ],
                installmentNo: [
                    { name: '1', code: '1' },
                    { name: '2', code: '2' },
                    { name: '3', code: '3' },
                    { name: '4', code: '4' },
                    { name: '5', code: '5' },
                    { name: '6', code: '6' },
                    { name: '7', code: '7' },
                    { name: '8', code: '8' },
                    { name: '9', code: '9' },
                    { name: '10', code: '10' }
                ],
                serviceType: updateServiceType,
                mortgageType: [
                    { name: 'First charge', code: 'FIRST_CHARGE' },
                    { name: 'Second charge', code: 'SECOND_CHARGE' }
                ],
                plotActionType: [
                    { name: 'Allotment', code: 'ALLOTMENT' },
                    { name: 'Transfer', code: 'TRANSFER' }
                ],
                allotmentType: [
                    { name: 'Allotment by Goa-IDC', code: 'IDC' },
                    { name: 'Allotment by Goa-IPB', code: 'IPB' },
                    { name: 'Allotment by Auction', code: 'AUCTION' }
                ],
                projectType: res['Estate']['EnterpriseProjectType'],
                experienceCategory: res['Estate']['Experience'],
                specialTransferType: res['Estate']['SpecialTransferType'],
                industryClassification: [
                    { name: 'Small', code: 'SMALL' },
                    { name: 'Medium', code: 'MEDIUM' },
                    { name: 'Large', code: 'LARGE' }
                ]
            };
            return dropdownOptions;
        }
    } catch (err) {
        toast('Error while fetching dropdown error', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo-bar',
            type: 'error'
        });
    }
};

export const entityDocumentDataMigration = async (res: any, docs: any) => {
    const formatedEntityDocuments: any = [];
    const refIDs = docs?.map((docItem: any, _: number) => docItem.docReferenceId || docItem.fileStoreId);
    if (refIDs && refIDs.length) {
        const { data } = await axios.get(devUrl + '/filestore/v1/files/url', { params: { tenantId: 'ga', fileStoreIds: refIDs?.join() } });

        res.documents?.map((doc: any, index: number) => {
            data.fileStoreIds.map((baseDoc: DocumentDetails) => {
                if (baseDoc.id === doc.docReferenceId || doc.fileStoreId) {
                    formatedEntityDocuments.push({ ...doc, ...baseDoc, documentUrl: baseDoc.url });
                }
            });
        });
    }

    return formatedEntityDocuments;
};

export const getMigrationData = async (res: any, dropdownOptions: dropdownOptionsInterface) => {
    const documentData: any = await entityDocumentDataMigration(res, res?.documents);

    const permanentAddress = res?.addresses?.find((val: any) => val?.addressType === 'PERMANENT');
    const correspondenceAddress = res?.addresses?.find((val: any) => val?.addressType === 'CORRESPONDENCE');

    const initialData: any = {};
    initialData['applicantName'] = res?.entityName;
    initialData['category'] = getDropdownSelectedObject(res?.category, dropdownOptions['category'] || []);
    initialData['emailId'] = res?.email;
    initialData['phoneNumber'] = res?.phoneNumber;
    initialData['applicantPanNo'] = res?.panNumber;
    // initialData['applicantGst'] = res?.gstApplicable;
    // initialData['applicantGstno'] = res?.gstNumber;
    initialData['address1'] = permanentAddress?.address1;
    initialData['address2'] = permanentAddress?.address2;
    initialData['pincode'] = permanentAddress?.pinCode;
    initialData['district'] = permanentAddress?.district;
    initialData['state'] = permanentAddress?.state;
    initialData['country'] = permanentAddress?.country;
    initialData['peraddress1'] = correspondenceAddress?.address1;
    initialData['peraddress2'] = correspondenceAddress?.address2;
    initialData['perpincode'] = correspondenceAddress?.pinCode;
    initialData['perdistrict'] = correspondenceAddress?.district;
    initialData['perstate'] = correspondenceAddress?.state;
    initialData['percountry'] = correspondenceAddress?.country;
    initialData['authorizedPersonFirstnamesalutation'] = res?.authUsers[0]?.salutation;
    initialData['firstName'] = res?.authUsers[0]?.firstName;
    initialData['lastName'] = res?.authUsers[0]?.lastName;
    initialData['AuthorizedPersonGender'] = res?.authUsers[0]?.gender;
    initialData['pmobileNumber'] = res?.authUsers[0]?.primaryMobileNo;
    initialData['alternateMobileNumber'] = res?.authUsers[0]?.alternateMobileNo;
    initialData['authorizedEmailId'] = res?.authUsers[0]?.email;
    // initialData['applicantGstdocument'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GST && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['applicantPancard'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.PAN && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['entityProprietorshipFirm'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['entityProprietorIdentity'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['trustDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['partnershipDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['certificateOfRegistrationFirms'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['certificateOfIncorporation'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['certificateOfRegistrationSocieties'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['copyRelevantNotification'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['letter'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY && doc.referenceType === DOCUMENT_TYPE.AUTHUSER);

    initialData['RegCertificate'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION && doc.referenceType === DOCUMENT_TYPE.OTHER);
    initialData['DPIITCertificate'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.DPIIT && doc.referenceType === DOCUMENT_TYPE.OTHER);
    initialData['womenEntreprenuershipDocument'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.WOMENENTREPRENUEUR && doc.referenceType === DOCUMENT_TYPE.OTHER);
    initialData['experienceCertificate'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.EXPERIENCED && doc.referenceType === DOCUMENT_TYPE.OTHER);

    initialData['accountNumber'] = res?.entityBankDetail?.accountNumber;
    initialData['ifscCode'] = res?.entityBankDetail?.ifscCode;
    initialData['bankName'] = res?.entityBankDetail?.bankName;
    initialData['branchAddress'] = res?.entityBankDetail?.branchAddress;
    initialData['udhyam'] = res?.miscDetails?.isUdhyamRegistered;
    initialData['udyamNo'] = res?.miscDetails?.udyamNumber;
    initialData['isStartup'] = res?.miscDetails?.isDpiitCertified;
    initialData['womenEntrepreneur'] = res?.miscDetails?.isWomenEntreprenueur;
    initialData['localPerson'] = res?.miscDetails?.isLocalPerson;
    initialData['priorExperience'] = res?.miscDetails?.isExperienced;
    initialData['isUpdatable'] = true;
    initialData['underProcess'] = true;
    initialData['entityUuid'] = res?.uuid;
    initialData['entityUserUuid'] = res?.userUuid;
    initialData['experienceCategory'] = getDropdownSelectedObject(res?.miscDetails?.experience, dropdownOptions['experienceCategory'] || []);

    return initialData;
};

export const getFormatedOwnershipDetails = async (owners: any, dropdownData: any) => {
    const ownershipDetails = await Promise.all(
        owners.map(async (item: any, ind: number) => ({
            ...item,
            serialNo: ind + 1,
            rowId: uuidv4(),
            ownershipFirstnamesalutation: item.salutation,
            ownershipName: item.name,
            ownershipInterest: item.ownershipInterest,
            genderValue: getDropdownSelectedObject(item?.gender, dropdownData['gender'])?.name,
            residentialAddress: (item.address1 || '') + ', ' + (item.address2 || '') + `${item.address2 ? ', ' : ''}` + (item.district || '') + ', ' + (item.country || ''),
            contactNo: item.mobileNumber,
            highestQualificationValue: getDropdownSelectedObject(item?.highestQualification, dropdownData['ownershipHighestQualification'])?.name,
            ownershipLocalPersonDocument: await getEntityDocumentDetails(item?.ownerDocuments?.find((doc: any) => doc.documentType === 'LOCALPERSON')),
            isLocalPerson: item.isLocalPerson
        }))
    );
    return ownershipDetails;
};

export const installmentCalculation = (values: any) => {
    let rowData = [];
    for (let i = 0; i < values.numberOfRemainingInstallment; i++) {
        let dueDate = new Date(values.nextInstallmentDate);
        dueDate.setFullYear(dueDate.getFullYear() + i);
        let cutOffDate = new Date('2024-12-07');
        let principalAmount = (+values.landPremiumBalanceAmount / +values.numberOfRemainingInstallment).toFixed(2).toString();
        let interest: any;
        if (dueDate < cutOffDate) {
            let differenceInTime = cutOffDate.getTime() - dueDate.getTime();
            let differenceInDays = differenceInTime / (1000 * 3600 * 24);
            interest = (
                (+values.landPremiumBalanceAmount - +i * +principalAmount) *
                ((values.previousInterestRate / 100) * (+differenceInDays / 365) + LAND_PREMIUM_INTEREST * (+(365 - differenceInDays) / 365))
            )
                .toFixed(2)
                .toString();
        } else if (cutOffDate <= dueDate) {
            interest = ((+values.landPremiumBalanceAmount - +i * +principalAmount) * LAND_PREMIUM_INTEREST).toFixed(2).toString();
        }
        let penalty = i === 0 && values.sumPenaltyArrearGst ? values.sumPenaltyArrearGst : 0;
        let grandTotal = (+principalAmount + +interest + +(+penalty)).toFixed(2).toString();

        let row = {
            totalLandPremiumAmount: i === 0 ? values?.totalLandPremiumAmount : null,
            paymentType: LAND_PREMIUM,
            dueDate: changeDateFormat(dueDate),
            dueDateddMMyyyy: changeDateFormatToddMMyyyy(dueDate),
            principalAmount: principalAmount,
            interestAmount: interest,
            penaltyAmount: i === 0 ? values.landPremiumPenaltyAmount || 0 : 0,
            cgstPenalty: i === 0 ? values.cgstOnLandPremiumPenaltyAmount || 0 : 0,
            sgstPenalty: i === 0 ? values.sgstOnLandPremiumPenaltyAmount || 0 : 0,
            totalDueAmount:
                (
                    +principalAmount +
                    +interest +
                    +(i === 0 ? values.landPremiumPenaltyAmount || 0 : 0) +
                    +(i === 0 ? values.cgstOnLandPremiumPenaltyAmount || 0 : 0) +
                    +(i === 0 ? values.sgstOnLandPremiumPenaltyAmount || 0 : 0)
                )
                    .toFixed(2)
                    .toString() || 0,
            arrears:
                i === 0
                    ? {
                          principalAmount: values.landPremiumArrearPrincipleAmount || 0,
                          interestAmount: values.landPremiumArrearInterestAmount || 0,
                          penaltyAmount: values.landPremiumArrearPenaltyAmount || 0,
                          cgstPenalty: values.cgstOnLandPremiumArrearPenalty || 0,
                          sgstPenalty: values.sgstOnLandPremiumArrearPenalty || 0,
                          totalDueAmount: (
                              +values.landPremiumArrearPrincipleAmount +
                              +values.landPremiumArrearInterestAmount +
                              +values.landPremiumArrearPenaltyAmount +
                              +values.cgstOnLandPremiumArrearPenalty +
                              +values.sgstOnLandPremiumArrearPenalty
                          )
                              .toFixed(2)
                              .toString()
                      }
                    : null,
            installmentPenaltyArrearGst: penalty,
            installmentTotalAmount: grandTotal
        };
        rowData.push(row);
    }
    return rowData;
};

export const getSubleaseEntityDetails = async (res: any, dropdownOptions: dropdownOptionsInterface) => {
    const permanentAddress = res?.addresses?.find((val: any) => val?.addressType === 'PERMANENT');
    const correspondenceAddress = res?.addresses?.find((val: any) => val?.addressType === 'CORRESPONDENCE');

    const documentData: any = await entityDocumentDataMigration(res, res?.documents);
    // const documentData: any = await entityDocumentDataMigration(res, res?.documents);
    const initialData: any = {};
    initialData['address'] = {};
    initialData['permanantAddress'] = {};
    initialData['user'] = {};
    initialData['documents'] = {};

    initialData['category'] = getDropdownSelectedObject(res?.category, dropdownOptions['category'] || []);
    initialData['address']['address1'] = permanentAddress?.address1;
    initialData['address']['address2'] = permanentAddress?.address2;
    initialData['address']['pincode'] = permanentAddress?.pinCode;
    initialData['address']['district'] = permanentAddress?.district;
    initialData['address']['state'] = permanentAddress?.state;
    initialData['address']['country'] = permanentAddress?.country;
    initialData['permanantAddress']['peraddress1'] = correspondenceAddress?.address1;
    initialData['permanantAddress']['peraddress2'] = correspondenceAddress?.address2;
    initialData['permanantAddress']['perpincode'] = correspondenceAddress?.pinCode;
    initialData['permanantAddress']['perdistrict'] = correspondenceAddress?.district;
    initialData['permanantAddress']['perstate'] = correspondenceAddress?.state;
    initialData['permanantAddress']['percountry'] = correspondenceAddress?.country;
    initialData['user'] = res?.authUsers[0];

    // initialData['user']['firstName'] = res?.authUsers[0]?.firstName;
    // initialData['user']['lastName'] = res?.authUsers[0]?.lastName;
    // initialData['user']['primaryMobileNo'] = res?.authUsers[0]?.primaryMobileNo;
    // initialData['user']['alternateMobileNo'] = res?.authUsers[0]?.alternateMobileNo;
    // initialData['user']['email'] = res?.authUsers[0]?.email;

    // initialData['documents']['gstDocument'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GST && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['documents']['panCard'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.PAN && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['documents']['entityProprietorshipFirm'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['documents']['entityProprietorIdentity'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['documents']['trustDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['documents']['partnershipDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['documents']['certificateOfRegistrationFirms'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['documents']['certificateOfIncorporation'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['documents']['certificateOfRegistrationSocieties'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['documents']['copyRelevantNotification'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['documents']['authorizedPersonPowerOfAttorneyDocument'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY && doc.referenceType === DOCUMENT_TYPE.AUTHUSER
    );

    initialData['documents']['udyamRegistrationCertificate'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION && doc.referenceType === DOCUMENT_TYPE.OTHER
    );
    // initialData['documents']['DPIITCertificate'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.DPIIT && doc.referenceType === DOCUMENT_TYPE.OTHER);
    // initialData['documents']['womenEntreprenuershipDocument'] = documentData?.find(
    //     (doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.WOMENENTREPRENUEUR && doc.referenceType === DOCUMENT_TYPE.OTHER
    // );
    // initialData['documents']['experienceCertificate'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.EXPERIENCED && doc.referenceType === DOCUMENT_TYPE.OTHER);

    // initialData['accountNumber'] = res?.entityBankDetail?.accountNumber;
    // initialData['ifscCode'] = res?.entityBankDetail?.ifscCode;
    // initialData['bankName'] = res?.entityBankDetail?.bankName;
    // initialData['branchAddress'] = res?.entityBankDetail?.branchAddress;
    initialData['udhyamRegisteredEntity'] = res?.miscDetails?.isUdhyamRegistered;
    initialData['udyamNo'] = res?.miscDetails?.udyamNumber;
    initialData['dpiit'] = res?.miscDetails?.isDpiitCertified;
    initialData['womenEntrepreneur'] = res?.miscDetails?.isWomenEntreprenueur;
    initialData['localPerson'] = res?.miscDetails?.isLocalPerson;
    initialData['priorExperience'] = res?.miscDetails?.isExperienced;
    initialData['underProcess'] = true;
    initialData['entityUuid'] = res?.uuid;
    initialData['entityUserUuid'] = res?.userUuid;

    return { ...res, ...initialData };
};

const getOtherDetailsData = async (res: any, dropdownOptions: any) => {
    const initialData: any = {};
    initialData['sublease'] = res?.isSublease;
    if (res.allotmentMigrationRequest) {
        const documentData: any = await entityDocumentDataMigration(res?.allotmentMigrationRequest?.allotmentRequest, res?.allotmentMigrationRequest?.allotmentRequest.documents);
        const otherDocumentData: any = res?.allotmentMigrationRequest?.otherDocument && (await entityDocumentData([res?.allotmentMigrationRequest?.otherDocument]));
        const registereddeedDoc: any =
            res?.allotmentMigrationRequest?.deedMigrationRequest?.registeredDeedDocRefId &&
            (await getEntityDocumentDetails({ docReferenceId: res?.allotmentMigrationRequest?.deedMigrationRequest?.registeredDeedDocRefId }));
        const executedDeedDoc: any =
            res?.allotmentMigrationRequest?.deedMigrationRequest?.executedDeedDocRefId &&
            (await getEntityDocumentDetails({ docReferenceId: res?.allotmentMigrationRequest?.deedMigrationRequest?.executedDeedDocRefId }));

        initialData['plotActionType'] = getDropdownSelectedObject('ALLOTMENT', dropdownOptions['plotActionType'] || []);
        initialData['allotmentType'] = getDropdownSelectedObject('ALLOTMENT', dropdownOptions['plotActionType'] || []);
        initialData['provisionalAllotmentOrderNumber'] = res['allotmentMigrationRequest']['allotmentRequest']['allotmentId'];
        initialData['allotmentDate'] = res['allotmentMigrationRequest']['allotmentRequest']['allotmentDate'];
        initialData['isLeaseDeedExecuted'] = res['allotmentMigrationRequest']['isDeedExecuted'];
        initialData['leaseTermStartDate'] = res['allotmentMigrationRequest']['deedMigrationRequest']['termStartDate'];
        initialData['leaseTermEndDate'] = res['allotmentMigrationRequest']['deedMigrationRequest']['termEndDate'];
        initialData['isLeaseDeedRegistered'] = res['allotmentMigrationRequest']['isDeedRegistered'];
        initialData['leaseDeedRegistrationDate'] = res['allotmentMigrationRequest']['deedMigrationRequest']['deedRegistrationDate'];
        initialData['leaseDeedExecutionDate'] = res['allotmentMigrationRequest']['deedMigrationRequest']['deedExecutionDate'];
        initialData['allotmentOrder'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ALLOTMENT_ORDER);
        initialData['executedLeaseDeed'] = executedDeedDoc;
        initialData['registeredLeaseDeed'] = registereddeedDoc;
        initialData['otherDocumentAllotment'] = otherDocumentData && otherDocumentData[0];
    }
    if (res.transferMigrationRequest) {
        const documentData: any = await entityDocumentDataMigration(res?.transferMigrationRequest?.allotmentRequest, res?.transferMigrationRequest?.allotmentRequest.documents);
        const otherDocumentData: any = res?.transferMigrationRequest?.otherDocument && (await entityDocumentData([res?.transferMigrationRequest?.otherDocument]));
        const registereddeedDoc: any =
            res?.transferMigrationRequest?.deedMigrationRequest?.registeredDeedDocRefId &&
            (await getEntityDocumentDetails({ docReferenceId: res?.transferMigrationRequest?.deedMigrationRequest?.registeredDeedDocRefId }));
        const executedDeedDoc: any =
            res?.transferMigrationRequest?.deedMigrationRequest?.executedDeedDocRefId &&
            (await getEntityDocumentDetails({ docReferenceId: res?.transferMigrationRequest?.deedMigrationRequest?.executedDeedDocRefId }));

        initialData['plotActionType'] = getDropdownSelectedObject('TRANSFER', dropdownOptions['plotActionType'] || []);
        initialData['allotmentType'] = getDropdownSelectedObject('TRANSFER', dropdownOptions['plotActionType'] || []);
        initialData['transferOrderNumber'] = res['transferMigrationRequest']['allotmentRequest']['allotmentId'];
        initialData['transferDate'] = res['transferMigrationRequest']['allotmentRequest']['allotmentDate'];
        initialData['originalLeasetermStartDate'] = res['transferMigrationRequest']['leaseDeedMigrationRequestForTransfer']['termStartDate'];
        initialData['isTripartiteDeedExecuted'] = res['transferMigrationRequest']['isDeedExecuted'];
        initialData['tripartiteDeedExecutionDate'] = res['transferMigrationRequest']['deedMigrationRequest']['deedExecutionDate'];
        initialData['leaseTermStartDateTransfer'] = res['transferMigrationRequest']['deedMigrationRequest']['termStartDate'];
        initialData['leaseTermEndDateTransfer'] = res['transferMigrationRequest']['deedMigrationRequest']['termEndDate'];
        initialData['isTripartiteDeedRegistered'] = res['transferMigrationRequest']['isDeedRegistered'];
        initialData['tripartiteDeedRegistrationDate'] = res['transferMigrationRequest']['deedMigrationRequest']['deedRegistrationDate'];
        initialData['transferOrder'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.TRANSFER_ORDER);
        initialData['executedTripartiteDeed'] = executedDeedDoc;
        initialData['registeredTripartiteDeed'] = registereddeedDoc;
        // initialData['otherDocumentTransfer'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER);
        initialData['otherDocumentTransfer'] = otherDocumentData && otherDocumentData[0];
    }

    if (res.extensionMigrationRequest) {
        const documentData: any = await entityDocumentDataMigration(res?.extensionMigrationRequest?.allotmentRequest, res?.extensionMigrationRequest?.allotmentRequest.documents);
        const otherDocumentData: any = res?.extensionMigrationRequest?.otherDocument && (await entityDocumentData([res?.extensionMigrationRequest?.otherDocument]));

        const registereddeedDoc: any =
            res?.extensionMigrationRequest?.deedMigrationRequest?.registeredDeedDocRefId &&
            (await getEntityDocumentDetails({ docReferenceId: res?.extensionMigrationRequest?.deedMigrationRequest?.registeredDeedDocRefId }));
        const executedDeedDoc: any =
            res?.extensionMigrationRequest?.deedMigrationRequest?.executedDeedDocRefId &&
            (await getEntityDocumentDetails({ docReferenceId: res?.extensionMigrationRequest?.deedMigrationRequest?.executedDeedDocRefId }));

        initialData['isExtension'] = true;
        initialData['extensionOrderNumber'] = res['extensionMigrationRequest']['allotmentRequest']['allotmentId'];
        initialData['extensionDate'] = res['extensionMigrationRequest']['allotmentRequest']['allotmentDate'];
        initialData['isDeedOfExtensionExecuted'] = res['extensionMigrationRequest']['isDeedExecuted'];
        initialData['deedOfExtensionExecutionDate'] = res['extensionMigrationRequest']['deedMigrationRequest']['deedExecutionDate'];
        initialData['leaseTermStartDateExtension'] = res['extensionMigrationRequest']['deedMigrationRequest']['termStartDate'];
        initialData['leaseTermEndDateExtension'] = res['extensionMigrationRequest']['deedMigrationRequest']['termEndDate'];
        initialData['isDeedOfExtensionRegistered'] = res['extensionMigrationRequest']['isDeedRegistered'];
        initialData['deedOfExtensionRegistrationDate'] = res['extensionMigrationRequest']['deedMigrationRequest']['deedRegistrationDate'];
        initialData['extensionOrder'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.EXTENSION_ORDER);
        initialData['executedDeedOfExtension'] = executedDeedDoc;
        initialData['registeredDeedOfExtension'] = registereddeedDoc;
        // initialData['otherDocumentExtension'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER);
        initialData['otherDocumentExtension'] = otherDocumentData && otherDocumentData[0];
    }

    if (res.mortgageMigrationRequests) {
        const docsRefIDs = res['mortgageMigrationRequests'].map((request: any) => request.nocDocReferenceId);
        const documentData: any = await migrationDocData(docsRefIDs);

        initialData['mortgageStatus'] = true;
        initialData['mortgageMigrationRequests'] = res['mortgageMigrationRequests'].map((request: any) => ({
            ...request,
            mortgageType: getDropdownSelectedObject(request['mortgageType'], dropdownOptions['mortgageType'] || []),
            nocDocReferenceId: documentData.find((item: any) => item.id === request.nocDocReferenceId)
        }));
    }

    if (res.waterConnectionMigrationDetailsList) {
        initialData['waterConnection'] = true;
        initialData['migrationWaterConnection'] = res['waterConnectionMigrationDetailsList'].map((request: any) => ({
            waterType: getDropdownSelectedObject(request.waterType, dropdownOptions['waterType'] || []),
            meterNo: request.meterNo,
            connectionDate: request.connectionDate,
            monthlyConsumption: request.applicationDetails.monthlyWaterConsumptionM3,
            connectionPipeDia: getDropdownSelectedObject(request.applicationDetails?.pipeDiaMm, dropdownOptions['connectionPipeDia'] || []),
            securityDeposit: request.applicationDetails.securityDeposit
        }));
    }

    return initialData;
};

const getPaymentDetails = async (res: any, dropdownOptions: any) => {
    const initialData: any = {};
    if (res?.payments) {
        const landPremium = res?.payments?.filter((val: any) => val.paymentType === 'LAND_PREMIUM');
        const leaseRent = res?.payments?.filter((val: any) => val.paymentType === 'LEASE_RENT');
        const houseTax = res?.payments?.filter((val: any) => val.paymentType === 'HOUSE_TAX');
        const waterCharges = res?.payments?.filter((val: any) => val.paymentType === 'WATER_CHARGES');

        initialData['landPremiumDue'] = landPremium?.length > 1 ? true : false;
        initialData['leaseRentPaymentStatus'] = leaseRent[0]?.principalAmount ? true : false;
        initialData['houseTaxPaymentStatus'] = houseTax[0]?.principalAmount ? true : false;
        initialData['waterChargesPaymentStatus'] = waterCharges[0]?.arrears ? true : false;

        initialData['totalLandPremiumAmount'] = landPremium[0]?.totalLandPremiumAmount || landPremium[0]?.paidAmount;
        initialData['totalPaidLandPremiumPrincipalAmount'] = landPremium[0]?.existingPaymentAmount;
        initialData['landPremiumBalanceAmount'] = +landPremium[0]?.totalLandPremiumAmount - +landPremium[0]?.existingPaymentAmount;
        initialData['totalNumberOfInstallment'] = 'NA';
        initialData['numberOfRemainingInstallment'] = landPremium?.length > 1 ? landPremium?.length - 1 : '';
        initialData['previousInterestRate'] = 'NA';
        initialData['landPremiumLastPaymentdate'] = landPremium[0]?.paymentDate;
        initialData['landPremiumPaymentReceipt'] = landPremium[0]?.invoiceRefId && (await getEntityDocumentDetails({ docReferenceId: landPremium[0]?.invoiceRefId }));

        initialData['nextInstallmentDate'] = landPremium?.length > 1 ? landPremium[1]?.dueDate : '';
        initialData['landPremiumPenaltyAmount'] = landPremium?.length > 1 ? landPremium[1]?.penaltyAmount : '';
        initialData['cgstOnLandPremiumPenaltyAmount'] = landPremium?.length > 1 ? landPremium[1]?.cgstPenalty : '';
        initialData['sgstOnLandPremiumPenaltyAmount'] = landPremium?.length > 1 ? landPremium[1]?.sgstPenalty : '';
        initialData['landPremiumArrearPrincipleAmount'] = landPremium?.length > 1 ? landPremium[1]?.arrears?.principalAmount : '';
        initialData['landPremiumArrearInterestAmount'] = landPremium?.length > 1 ? landPremium[1]?.arrears?.interestAmount : '';
        initialData['landPremiumArrearPenaltyAmount'] = landPremium?.length > 1 ? landPremium[1]?.arrears?.penaltyAmount : '';
        initialData['cgstOnLandPremiumArrearPenalty'] = landPremium?.length > 1 ? landPremium[1]?.arrears?.cgstPenalty : '';
        initialData['sgstOnLandPremiumArrearPenalty'] = landPremium?.length > 1 ? landPremium[1]?.arrears?.sgstPenalty : '';
        initialData['sumPenaltyArrearGst'] =
            landPremium?.length > 1
                ? (
                      +landPremium[1]?.penaltyAmount * (1 + 2 * CGST_SGST) +
                      +landPremium[1]?.arrears?.principalAmount +
                      +landPremium[1]?.arrears?.interestAmount +
                      +landPremium[1]?.arrears?.penaltyAmount * (1 + 2 * CGST_SGST)
                  ).toFixed(2)
                : '';

        initialData['leaseRentPrincipalAmount'] = leaseRent[0]?.principalAmount;
        initialData['cgstOnLeaseRentPrincipleAmount'] = leaseRent[0]?.principalAmount ? (+leaseRent[0]?.principalAmount * CGST_SGST).toFixed(2) : '';
        initialData['sgstOnLeaseRentPrincipleAmount'] = leaseRent[0]?.principalAmount ? (+leaseRent[0]?.principalAmount * CGST_SGST).toFixed(2) : '';
        initialData['leaseRentPenaltyAmount'] = leaseRent[0]?.penaltyAmount;
        initialData['cgstOnLeaseRentPenaltyAmount'] = leaseRent[0]?.penaltyAmount ? (+leaseRent[0]?.penaltyAmount * CGST_SGST).toFixed(2) : '';
        initialData['sgstOnLeaseRentPenaltyAmount'] = leaseRent[0]?.penaltyAmount ? (+leaseRent[0]?.penaltyAmount * CGST_SGST).toFixed(2) : '';
        initialData['leaseRentArrearPrincipalAmount'] = leaseRent[0]?.arrears?.principalAmount;
        initialData['cgstOnLeaseRentArrearPrincipalAmount'] = leaseRent[0]?.arrears?.principalAmount ? (+leaseRent[0]?.arrears?.principalAmount * CGST_SGST).toFixed(2) : '';
        initialData['sgstOnLeaseRentArrearPrincipalAmount'] = leaseRent[0]?.arrears?.principalAmount ? (+leaseRent[0]?.arrears?.principalAmount * CGST_SGST).toFixed(2) : '';
        initialData['leaseRentArrearPenaltyAmount'] = leaseRent[0]?.arrears?.penaltyAmount;
        initialData['cgstOnLeaseRentArrearPenaltyAmount'] = leaseRent[0]?.arrears?.penaltyAmount ? (+leaseRent[0]?.arrears?.penaltyAmount * CGST_SGST).toFixed(2) : '';
        initialData['sgstOnLeaseRentArrearPenaltyAmount'] = leaseRent[0]?.arrears?.penaltyAmount ? (+leaseRent[0]?.arrears?.penaltyAmount * CGST_SGST).toFixed(2) : '';
        initialData['leaseRentTotalDueAmmount'] = (+leaseRent[0]?.totalDueAmount + +leaseRent[0]?.arrears?.totalDueAmount).toFixed(2);
        initialData['leaseRentDueDate'] = leaseRent[0]?.dueDate;
        initialData['leaseRentIncrementDate'] = leaseRent[0]?.incrementDate;
        initialData['pendencyReport'] = res?.pendencyReportDocReferenceId && (await getEntityDocumentDetails({ docReferenceId: res?.pendencyReportDocReferenceId }));
        initialData['existingLeaseRentAmount'] = leaseRent[0]?.existingPaymentAmount;
        initialData['LeaseRentNextDueDate'] = leaseRent[0]?.dueDate;
        initialData['leaseRentLastPaymentDate'] = leaseRent[0]?.paymentDate;
        initialData['leaseRentPaymentReceipt'] = leaseRent[0]?.invoiceRefId && (await getEntityDocumentDetails({ docReferenceId: leaseRent[0]?.invoiceRefId }));

        initialData['houseTaxDueDate'] = houseTax[0]?.dueDate;
        initialData['houseTaxPrincipalAmount'] = houseTax[0]?.principalAmount;
        initialData['houseTaxPenaltyAmount'] = houseTax[0]?.penaltyAmount;
        initialData['cgstOnHouseTaxPenaltyAmount'] = houseTax[0]?.penaltyAmount ? (+houseTax[0]?.penaltyAmount * CGST_SGST).toFixed(2) : '';
        initialData['sgstOnHouseTaxPenaltyAmount'] = houseTax[0]?.penaltyAmount ? (+houseTax[0]?.penaltyAmount * CGST_SGST).toFixed(2) : '';
        initialData['houseTaxArrearPrincipalAmount'] = houseTax[0]?.arrears?.principalAmount;
        initialData['houseTaxArrearPenaltyAmount'] = houseTax[0]?.arrears?.penaltyAmount;
        initialData['cgstOnHouseTaxArrearPenaltyAmount'] = houseTax[0]?.arrears?.penaltyAmount ? (+houseTax[0]?.arrears?.penaltyAmount * CGST_SGST).toFixed(2) : '';
        initialData['sgstOnHouseTaxArrearPenaltyAmount'] = houseTax[0]?.arrears?.penaltyAmount ? (+houseTax[0]?.arrears?.penaltyAmount * CGST_SGST).toFixed(2) : '';
        initialData['houseTaxTotalDueAmmount'] =
            houseTax[0]?.totalDueAmount || houseTax[0]?.arrears?.totalDueAmount ? (+houseTax[0]?.totalDueAmount + +houseTax[0]?.arrears?.totalDueAmount).toFixed(2) : '';
        initialData['existingHouseTaxAmount'] = houseTax[0]?.existingPaymentAmount;
        initialData['houseTaxNextDueDate'] = houseTax[0]?.dueDate;
        initialData['houseTaxLastPaymentDate'] = houseTax[0]?.paymentDate;
        initialData['houseTaxPaymentReceipt'] = houseTax[0]?.invoiceRefId && (await getEntityDocumentDetails({ docReferenceId: houseTax[0]?.invoiceRefId }));
    }

    return initialData;
};

export const getDropDownValue = (resValue: any) => {
    return resValue ? { name: resValue, code: resValue } : '';
};

export const CLOCDocData = async (res: any, data: any) => {
    const documentData: any = await entityDocumentDataMigration(res, data);
    const initialData: any = {};
    initialData['CLOCorder'] = documentData?.find((doc: any) => doc.documentName === 'CL/OC order.pdf');
    initialData['COCompletionCertificate'] = documentData?.find((doc: any) => doc.documentName === 'Completion Certificate.pdf');
    initialData['CORevisedPlan'] = documentData?.find((doc: any) => doc.documentName === 'Revised Plan.pdf');
    initialData['COStabilityCertificate'] = documentData?.find((doc: any) => doc.documentName === 'Stability Certificate.pdf');
    initialData['COOtherDoc'] = documentData?.find((doc: any) => doc.documentName === 'Other Doc.pdf');
    initialData['fireDoc'] = documentData?.find((doc: any) => doc.documentName === 'Fire NOC.pdf');
    initialData['healthDoc'] = documentData?.find((doc: any) => doc.documentName === 'Health NOC.pdf');
    initialData['fnbDoc'] = documentData?.find((doc: any) => doc.documentName === 'F&B NOC.pdf');
    return initialData;
};
export const setTechCleaDocData = async (res: any, data: any) => {
    const documentData: any = await entityDocumentDataMigration(res, data);
    const initialData: any = {};
    initialData['ArchitectureCertificate'] = documentData?.find((doc: any) => doc.documentName === 'Architecture Certificate.pdf');
    initialData['LiabilityCertificate'] = documentData?.find((doc: any) => doc.documentName === 'Liability Certificate.pdf');
    initialData['DXForDWG'] = documentData?.find((doc: any) => doc.documentName === 'DXF/DWG.pdf');
    initialData['SubmissionPlan'] = documentData?.find((doc: any) => doc.documentName === 'Submission Plan.pdf');
    initialData['Questionnaire'] = documentData?.find((doc: any) => doc.documentName === 'Questionnaire.pdf');
    initialData['OwnerAffidavit'] = documentData?.find((doc: any) => doc.documentName === 'Owner Affidavit.pdf');
    initialData['TCPowerofAttorney'] = documentData?.find((doc: any) => doc.documentName === 'PowerofAttorney.pdf');
    initialData['TCOrder'] = documentData?.find((doc: any) => doc.documentName === 'Technical Clearance Order.pdf');
    initialData['TCOtherDoc'] = documentData?.find((doc: any) => doc.documentName === 'Other Documents.pdf');
    return initialData;
};
export const setConstLicDocData = async (res: any, data: any) => {
    const documentData: any = await entityDocumentDataMigration(res, data);
    const initialData: any = {};
    initialData['CLOrder'] = documentData?.find((doc: any) => doc.documentName === 'Construction License Order.pdf');
    initialData['CLOtherDoc'] = documentData?.find((doc: any) => doc.documentName === 'Other Documents.pdf');
    return initialData;
};

export const getConstructionLicenseData = async (res: any, dropdownOptions: any) => {
    const techClearanceInitValue = res?.technicalClearance
        ? await Promise.all(
              res?.technicalClearance?.map(async (val: any) => {
                  const docs = await setTechCleaDocData(val, val?.documents);
                  const serviceTypeCode = val?.serviceType.split(',');

                  let initialData: any = {};
                  initialData['architectName'] = val?.architectName;
                  initialData['architectLicenseNo'] = val?.architectLicenseNo;
                  initialData['orderNo'] = val?.orderNo;
                  initialData['orderDate'] = getCorrectDateFormate(val?.orderDate);
                  initialData['validUpto'] = val?.validUpto !== 0 && val?.validUpto ? getCorrectDateFormate(val?.validUpto) : null;
                  initialData['serviceType'] = serviceTypeCode.map((o: any) => {
                      return { ...getDropdownSelectedObject(o, dropdownOptions['serviceType']) };
                  });

                  return { ...initialData, documents: docs };
              })
          )
        : [];
    const constructionLicenseInitValue = res?.constructionLicense
        ? await Promise.all(
              res?.constructionLicense?.map(async (val: any) => {
                  const docs = await setConstLicDocData(val, val?.documents);

                  let initialData: any = {};
                  initialData['technicalClearanceOrderNumber'] = val?.technicalClearanceOrderNumber && getDropDownValue(val?.technicalClearanceOrderNumber || []);
                  initialData['orderNo'] = val?.orderNo;
                  initialData['orderDate'] = getCorrectDateFormate(val?.orderDate);
                  initialData['builtUpArea'] = val?.builtUpArea;
                  initialData['estimatedConstructionCost'] = val?.estimatedConstructionCost;
                  initialData['industryClassification'] = getDropdownSelectedObject(val?.industryClassification, dropdownOptions['technicalClearanceOrderNumber'] || []);
                  initialData['validUpto'] = getCorrectDateFormate(val?.validUpto);
                  return { ...initialData, documents: docs };
              })
          )
        : [];
    const CLOCInitValue = res?.CLOC
        ? await Promise.all(
              res?.CLOC.map(async (val: any) => {
                  const docs = await CLOCDocData(val, val?.documents);

                  let initialData: any = {};
                  initialData['constructionLicenseOrderNumber'] = val?.constructionLicenseOrderNumber && getDropDownValue(val?.constructionLicenseOrderNumber || []);
                  initialData['orderNo'] = val?.orderNo;
                  initialData['orderDate'] = getCorrectDateFormate(val?.orderDate);
                  initialData['builtUpArea'] = val?.builtUpArea;
                  return { ...initialData, documents: docs };
              })
          )
        : [];
    return { technicalClearance: [...techClearanceInitValue], constructionLicense: [...constructionLicenseInitValue], cooc: [...CLOCInitValue] };
};

const calculateYearsDifference = (date1: string, date2: string) => {
    const d1: any = new Date(date1);
    const d2: any = new Date(date2);
    const differenceInMs: any = d2 - d1;

    const msPerYear = 365.25 * 24 * 60 * 60 * 1000;
    const differenceInYears = differenceInMs / msPerYear;

    return differenceInYears;
};

const getSubleaseDetailsData = async (res: any, resOther: any, dropdownOptions: any) => {
    if (resOther?.isSublease === true && res) {
        const subleaseDetails = await Promise.all(
            res?.map(async (val: any) => {
                const documentData: any = await entityDocumentDataMigration(val?.subLeaseEntityDetails, val?.subLeaseEntityDetails?.documents);
                const detailProjdocumentData: any = await entityDocumentDataMigration(val?.subLeaseEnterpriseDetails, val?.subLeaseEnterpriseDetails?.documents);
                const initData: any = await entityDocumentDataMigration(val, val?.documents);

                const gstDocumentData: any = detailProjdocumentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GST);
                const nonGstDocumentData: any = detailProjdocumentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.NON_GST);
                const subLeaseOrderDocument: any = val?.subleaseOrderDocReferenceId && (await getEntityDocumentDetails({ docReferenceId: val?.subleaseOrderDocReferenceId }));
                const documents = val?.nocForSpecialProjectDocRefId && (await getEntityDocumentDetails({ docReferenceId: val?.nocForSpecialProjectDocRefId }));
                const detialProjectReport = detailProjdocumentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.DETAILED_PROJECT_REPORT);

                const filterWaterChargePayment = val?.paymentDetails.filter((value: any) => value?.paymentType === 'WATER_CHARGES');
                const filterSubLeasePayment = val?.paymentDetails.find((value: any) => value?.paymentType === 'SUBLEASE_FEE');
                const invoiceDoc = filterSubLeasePayment?.invoiceRefId && (await getEntityDocumentDetails({ docReferenceId: filterSubLeasePayment?.invoiceRefId }));

                let subleaseWater: any;
                const init: any = {};
                init['paymentDetails'] = {};
                const initialData: any = {};
                const otherInitialData: any = {};
                const enterpriseInitialData: any = {};
                initialData['address'] = {};
                initialData['permanantAddress'] = {};
                initialData['user'] = {};
                initialData['documents'] = {};

                init['subLeaseStartDate'] = val?.subLeaseTermStartDate && changeDateToYYYYMMDD(val?.subLeaseTermStartDate);
                init['subLeaseEndDate'] = val?.subLeaseTermEndDate && changeDateToYYYYMMDD(val?.subLeaseTermEndDate);
                init['durationSubLease'] = val?.subLeaseTermStartDate && val?.subLeaseTermEndDate && calculateYearsDifference(val?.subLeaseTermStartDate, val?.subLeaseTermEndDate).toFixed(0);
                init['subLeaseArea'] = val?.area;
                init['subLeaseOrder'] = subLeaseOrderDocument;
                init['subleaseFeeDue'] = filterSubLeasePayment?.arrears?.principalAmount > 0 ? true : false;
                init['uuid'] = val?.subLeaseEntityDetails?.uuid;
                if (!(filterSubLeasePayment?.arrears?.principalAmount > 0)) {
                    init['paymentDetails']['existingSubLeaseFee'] = filterSubLeasePayment?.existingPaymentAmount;
                    init['paymentDetails']['nextDueDate'] = filterSubLeasePayment?.dueDate && changeDateToYYYYMMDD(filterSubLeasePayment?.dueDate);
                    init['paymentDetails']['lastPaymentDate'] = filterSubLeasePayment?.paymentDate && changeDateToYYYYMMDD(filterSubLeasePayment?.paymentDate);
                    init['paymentDetails']['uploadReceipt'] = invoiceDoc;
                } else {
                    init['paymentDetails']['principalAmount'] = filterSubLeasePayment?.principalAmount;
                    init['paymentDetails']['cgstPrincipalAmount'] = filterSubLeasePayment?.cgstPrincipal;
                    init['paymentDetails']['sgstPrincipalAmount'] = filterSubLeasePayment?.sgstPrincipal;
                    init['paymentDetails']['penaltyAmount'] = filterSubLeasePayment?.penaltyAmount;
                    init['paymentDetails']['cgstPenaltyAmount'] = filterSubLeasePayment?.cgstPenalty;
                    init['paymentDetails']['sgstPenaltyAmount'] = filterSubLeasePayment?.sgstPenalty;
                    init['paymentDetails']['arrearPrincipalAmount'] = filterSubLeasePayment?.arrears?.principalAmount;
                    init['paymentDetails']['cgstArrearPrincipalAmount'] = filterSubLeasePayment?.arrears?.cgstPrincipal;
                    init['paymentDetails']['sgstArrearPrincipalAmount'] = filterSubLeasePayment?.arrears?.sgstPrincipal;
                    init['paymentDetails']['arrearPenaltyAmount'] = filterSubLeasePayment?.arrears?.penaltyAmount;
                    init['paymentDetails']['cgstArrearPenaltyAmount'] = filterSubLeasePayment?.arrears?.cgstPenalty;
                    init['paymentDetails']['sgstArrearPenaltyAmount'] = filterSubLeasePayment?.arrears?.sgstPenalty;
                    init['paymentDetails']['totalDueAmount'] = filterSubLeasePayment?.totalDueAmount;
                    init['paymentDetails']['dueDate'] = filterSubLeasePayment?.dueDate && changeDateToYYYYMMDD(filterSubLeasePayment?.dueDate);
                }
                init['subleaseWater'] = val?.waterConnectionMigrationDetailsList && val?.waterConnectionMigrationDetailsList.length ? true : false;
                if (val?.waterConnectionMigrationDetailsList && val?.waterConnectionMigrationDetailsList.length) {
                    subleaseWater = await Promise.all(
                        val?.waterConnectionMigrationDetailsList?.map(async (value: any, ind: number) => {
                            const invoiceSubleaseWaterConnectionDue =
                                filterWaterChargePayment[ind]?.invoiceRefId && (await getEntityDocumentDetails({ docReferenceId: filterWaterChargePayment[ind]?.invoiceRefId }));
                            const initialData: any = {};
                            initialData['waterType'] = getDropDownValue(value?.waterType || []);
                            initialData['meterNo'] = value?.meterNo;
                            initialData['connectionDate'] = value?.connectionDate;
                            initialData['monthlyConsumption'] = value?.applicationDetails?.monthlyWaterConsumptionM3;
                            initialData['connectionPipeDia'] = getDropdownSelectedObject(value?.applicationDetails?.pipeDiaMm, dropdownOptions['connectionPipeDia'] || []);
                            initialData['securityDeposit'] = value?.applicationDetails?.securityDeposit;
                            initialData['subleaseWaterFeeDue'] = filterWaterChargePayment[ind]?.principalAmount ? true : false;
                            if (filterWaterChargePayment.length) {
                                if (filterWaterChargePayment[ind].principalAmount) {
                                    initialData['waterChargesPrincipalAmount'] = filterWaterChargePayment[ind].principalAmount;
                                    initialData['waterChargesPenaltyAmount'] = filterWaterChargePayment[ind].penaltyAmount;
                                    initialData['cgstOnWaterChargesPenaltyAmount'] = filterWaterChargePayment[ind].cgstPenalty;
                                    initialData['sgstOnWaterChargesPenaltyAmount'] = filterWaterChargePayment[ind].sgstPenalty;
                                    initialData['waterChargesArrearPrincipalAmount'] = filterWaterChargePayment[ind].arrears.principalAmount;
                                    initialData['waterChargesArrearPenaltyAmount'] = filterWaterChargePayment[ind].arrears.penaltyAmount;
                                    initialData['cgstOnWaterChargesArrearPenaltyAmount'] = filterWaterChargePayment[ind].arrears.cgstPenalty;
                                    initialData['sgstOnWaterChargesArrearPenaltyAmount'] = filterWaterChargePayment[ind].arrears.sgstPenalty;
                                    initialData['waterChargesTotalDueAmmount'] =
                                        filterWaterChargePayment[ind].totalDueAmount || filterWaterChargePayment[ind].arrear?.totalDueAmount
                                            ? +filterWaterChargePayment[ind].totalDueAmount + +filterWaterChargePayment[ind].arrear?.totalDueAmount
                                            : '';
                                    initialData['waterChargesDueDate'] = filterWaterChargePayment[ind].dueDate;
                                    initialData['waterChargesLastMeterReading'] = filterWaterChargePayment[ind].currentMeterReading;
                                    initialData['waterChargeslastMeterReadingDate'] = filterWaterChargePayment[ind].currentMeterReadingDate;
                                    initialData['waterChargesTotalDueAmmount'] = filterWaterChargePayment[ind].totalDueAmount;
                                } else {
                                    initialData['waterChargesExistingAmount'] = filterWaterChargePayment[ind].existingPaymentAmount;
                                    initialData['waterChargesNextDueDate'] = filterWaterChargePayment[ind].dueDate;
                                    initialData['waterChargesLastPaymentDate'] = filterWaterChargePayment[ind].paymentDate;
                                    initialData['waterChargesLastMeterReading'] = filterWaterChargePayment[ind].currentMeterReading;
                                    initialData['waterChargeslastMeterReadingDate'] = filterWaterChargePayment[ind].currentMeterReadingDate;
                                    initialData['waterChargesPaymentReceipt'] = invoiceSubleaseWaterConnectionDue;
                                }
                            }
                            return initialData;
                        })
                    );
                }
                initialData['entityName'] = val?.subLeaseEntityDetails?.entityName;
                initialData['email'] = val?.subLeaseEntityDetails?.email;
                initialData['phoneNumber'] = val?.subLeaseEntityDetails?.phoneNumber;
                initialData['category'] = getDropdownSelectedObject(val?.subLeaseEntityDetails?.category, dropdownOptions['category'] || []);
                initialData['panNumber'] = val?.subLeaseEntityDetails?.panNumber;
                initialData['uuid'] = val?.subLeaseEntityDetails?.uuid;
                initialData['documents']['entityProprietorshipFirm'] = documentData?.find(
                    (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM && doc.referenceType === DOCUMENT_TYPE.ENTITY
                );
                initialData['documents']['entityProprietorIdentity'] = documentData?.find(
                    (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY && doc.referenceType === DOCUMENT_TYPE.ENTITY
                );
                initialData['documents']['trustDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
                initialData['documents']['partnershipDeed'] = documentData?.find(
                    (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY
                );
                initialData['documents']['certificateOfRegistrationFirms'] = documentData?.find(
                    (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS && doc.referenceType === DOCUMENT_TYPE.ENTITY
                );
                initialData['documents']['certificateOfIncorporation'] = documentData?.find(
                    (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION && doc.referenceType === DOCUMENT_TYPE.ENTITY
                );
                initialData['documents']['certificateOfRegistrationSocieties'] = documentData?.find(
                    (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES && doc.referenceType === DOCUMENT_TYPE.ENTITY
                );
                initialData['documents']['copyRelevantNotification'] = documentData?.find(
                    (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION && doc.referenceType === DOCUMENT_TYPE.ENTITY
                );
                initialData['documents']['panCard'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.PAN && doc.referenceType === DOCUMENT_TYPE.ENTITY);
                initialData['documents']['authorizedPersonPowerOfAttorneyDocument'] = documentData?.find(
                    (doc: any) => doc.documentType === DOCUMENT_TYPE.POWEROFATTORNEY && doc.referenceType === DOCUMENT_TYPE.AUTHUSER
                );
                initialData['documents']['udyamRegistrationCertificate'] = documentData?.find(
                    (doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION && doc.referenceType === DOCUMENT_TYPE.OTHER
                );

                const permanentAddress = val?.subLeaseEntityDetails?.addresses?.find((val: any) => val?.addressType === 'PERMANENT');
                const correspondenceAddress = val?.subLeaseEntityDetails?.addresses?.find((val: any) => val?.addressType === 'CORRESPONDENCE');

                initialData['address']['address1'] = permanentAddress?.address1;
                initialData['address']['address2'] = permanentAddress?.address2;
                initialData['address']['pincode'] = permanentAddress?.pinCode;
                initialData['address']['district'] = permanentAddress?.district;
                initialData['address']['state'] = permanentAddress?.state;
                initialData['address']['country'] = permanentAddress?.country;
                initialData['permanantAddress']['peraddress1'] = correspondenceAddress?.address1;
                initialData['permanantAddress']['peraddress2'] = correspondenceAddress?.address2;
                initialData['permanantAddress']['perpincode'] = correspondenceAddress?.pinCode;
                initialData['permanantAddress']['perdistrict'] = correspondenceAddress?.district;
                initialData['permanantAddress']['perstate'] = correspondenceAddress?.state;
                initialData['permanantAddress']['percountry'] = correspondenceAddress?.country;
                initialData['user'] = val?.subLeaseEntityDetails?.authUsers[0];

                otherInitialData['udhyamRegisteredEntity'] = val?.subLeaseEntityDetails?.miscDetails?.isUdhyamRegistered;
                otherInitialData['udyamRegistrationCertificate'] = documentData?.find(
                    (doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION && doc.referenceType === DOCUMENT_TYPE.OTHER
                );

                otherInitialData['udyamNo'] = val?.subLeaseEntityDetails?.miscDetails?.udyamNumber;

                enterpriseInitialData['enterpriseCategory'] = getDropdownSelectedObject(val?.subLeaseEnterpriseDetails?.enterpriseCategory, dropdownOptions['enterpriseCategory'] || []);
                enterpriseInitialData['subCategory'] = val?.subLeaseEnterpriseDetails?.enterpriseSubCategories.map((o: any) => {
                    return { ...getDropdownSelectedObject(o, dropdownOptions['subCategory']) };
                });
                enterpriseInitialData['applicantGst'] = val?.subLeaseEnterpriseDetails?.gstApplicable;
                enterpriseInitialData['applicantGstno'] = val?.subLeaseEnterpriseDetails?.gstNumber;
                // enterpriseInitialData['applicantGstdocument'] = gstDocumentData;
                // enterpriseInitialData['entitygstUndertaking'] = nonGstDocumentData;
                enterpriseInitialData['pollutionCategory'] = getDropdownSelectedObject(val?.subLeaseEnterpriseDetails?.pollutionCategory, dropdownOptions['pollutionCategory'] || []);
                enterpriseInitialData['waterRequirement'] = getDropdownSelectedObject(val?.subLeaseEnterpriseDetails?.estimatedWaterRequirement, dropdownOptions['waterRequirement'] || []);
                enterpriseInitialData['powerRequirement'] = getDropdownSelectedObject(val?.subLeaseEnterpriseDetails?.estimatedPowerRequirement, dropdownOptions['powerRequirement'] || []);
                enterpriseInitialData['employement'] = getDropdownSelectedObject(val?.subLeaseEnterpriseDetails?.estimatedNumberOfPersonsToBeEmployed, dropdownOptions['employement'] || []);
                return {
                    ...init,
                    documents: { inPrincipleApproval: documents },
                    subLeaseEntityDetails: { ...initialData },
                    subLeaseOtherDetails: { ...otherInitialData },
                    subLeaseEnterpriseDetails: {
                        ...enterpriseInitialData,
                        documents: { detailedProjectReport: detialProjectReport, applicantGstdocument: gstDocumentData, entitygstUndertaking: nonGstDocumentData }
                    },
                    subLeaseWaterDetails: subleaseWater && [...subleaseWater]
                };
            })
        );
        return { subLeaseDetailsdata: [...subleaseDetails] };
    }
};

export const populateMigrationData = async (res: any, dropdownData: any) => {
    const initialData: any = {};
    initialData['sublease'] = !!res['subLeaseDetails'];

    const houseTax = res?.paymentDetails?.payments?.filter((val: any) => val?.paymentType === 'HOUSE_TAX');

    const applicantData = await getMigrationData(res.applicantDetails, dropdownData);
    const plotData = await getInitPlotData(res.plotDetails);
    const enterpriseData = await getEnterpriseData(res.enterpriseDetails, dropdownData);
    const constructionLicenseData = await getConstructionLicenseData(res.obpasDetails, dropdownData);
    const otherDetailsData = await getOtherDetailsData(res.otherDetails, dropdownData);
    const subleaseDetailsData = await getSubleaseDetailsData(res.subLeaseDetails, res.otherDetails, dropdownData);
    const paymentDetails = await getPaymentDetails(res.paymentDetails, dropdownData);
    const migrationWaterCharges = res.paymentDetails ? await getWaterCharges(res.paymentDetails) : [];

    return {
        ...applicantData,
        ...plotData,
        ...enterpriseData,
        ...otherDetailsData,
        ...constructionLicenseData,
        ...initialData,
        ...paymentDetails,
        migrationWaterCharge: migrationWaterCharges,
        ...subleaseDetailsData,
        technicalClearanceOrder: res?.obpasDetails?.technicalClearance?.length > 0,
        constructionLicenseOrder: res?.obpasDetails?.constructionLicense?.length > 0,
        coocOrder: res?.obpasDetails?.CLOC?.length > 0,
        houseTaxApplicable: houseTax?.length > 0
    };
};

export const getWaterCharges = async (res: any) => {
    if (res?.payments) {
        const waterCharges = res?.payments?.filter((val: any) => val.paymentType === 'WATER_CHARGES');
        const migrationWaterCharges = await Promise.all(
            waterCharges?.map(async (val: any) => ({
                waterChargeMeterNo: {
                    code: val?.meterNumber,
                    name: val?.meterNumber
                },
                waterChargesExistingAmount: val?.existingPaymentAmount,
                waterChargesNextDueDate: val?.dueDate,
                waterChargesLastPaymentDate: val?.paymentDate,
                waterChargesLastMeterReading: val?.currentMeterReading,
                waterChargeslastMeterReadingDate: val?.currentMeterReadingDate,
                waterChargesPaymentReceipt: val?.invoiceRefId && (await getEntityDocumentDetails({ docReferenceId: val?.invoiceRefId })),
                waterChargesPrincipalAmount: val?.principalAmount,
                waterChargesPenaltyAmount: val?.penaltyAmount,
                cgstOnWaterChargesPenaltyAmount: val?.sgstPenalty,
                sgstOnWaterChargesPenaltyAmount: val?.cgstPenalty,
                waterChargesArrearPrincipalAmount: val?.arrears?.principalAmount,
                waterChargesArrearPenaltyAmount: val?.arrears?.penaltyAmount,
                cgstOnWaterChargesArrearPenaltyAmount: val?.arrears?.sgstPenalty,
                sgstOnWaterChargesArrearPenaltyAmount: val?.arrears?.cgstPenalty,
                waterChargesTotalDueAmmount: val?.totalDueAmount,
                waterChargesDueDate: val?.dueDate
            }))
        );

        return migrationWaterCharges;
    }
};

export const formatDate = (dateString: any) => {
    const date = dateString?.split('T')[0];
    const [year, month, day] = date?.split('-');
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
};

export const getInstallmentScheduleTable = (res: any) => {
    const tempRowData = res?.map((val: any, index: number) => ({
        serialNo: index + 1,
        dueDateddMMyyyy: formatDate(val.dueDate),
        principalAmount: val.principalAmount,
        interestAmount: val?.interestAmount,
        installmentPenaltyArrearGst: (
            +(val?.penaltyAmount * (1 + 2 * CGST_SGST) || 0) +
            +(val?.arrears?.principalAmount || 0) +
            +(val?.arrears?.interestAmount || 0) +
            +(val?.arrears?.penaltyAmount * (1 + 2 * CGST_SGST) || 0)
        ).toFixed(2),
        installmentTotalAmount:
            (
                +(val.principalAmount || 0) +
                +(val?.interestAmount || 0) +
                +(val?.penaltyAmount * (1 + 2 * CGST_SGST) || 0) +
                +(val?.arrears?.principalAmount || 0) +
                +(val?.arrears?.interestAmount || 0) +
                +(val?.arrears?.penaltyAmount * (1 + 2 * CGST_SGST) || 0)
            ).toFixed(2) ?? 0
    }));
    return tempRowData;
};

export const migrationDocData = async (refIDs: any) => {
    const { data } = await axios.get(devUrl + '/filestore/v1/files/url', { params: { tenantId: 'ga', fileStoreIds: refIDs?.join() } });
    const formatedEntityDocuments = data.fileStoreIds.map((baseDoc: any) => ({
        ...baseDoc,
        documentSize: baseDoc.fileSize,
        documentUrl: baseDoc.url,
        documentName: baseDoc.tag,
        docReferenceId: baseDoc?.id
    }));
    return formatedEntityDocuments;
};

export const MIGRATION_UPDATE_PAYMENT_UPDATE_PAYLOAD = (searchResponse: any, values: any, subleaseNicDetails: any) => {
    const subLeaseDetailsData = values?.subLeaseDetailsdata?.map((data: any, ind: any) => getSubLeasePayload(data, ind, subleaseNicDetails, searchResponse?.subLeaseDetails));
    const payload = {
        isOnlyPaymentUpdate: true,
        ...searchResponse,
        uuid: searchResponse?.uuid,
        migrationApplicationNumber: searchResponse?.migrationApplicationNumber,
        entityUserUuid: searchResponse?.applicantDetails.user.uuid,
        entityId: searchResponse?.entityId,
        entityName: searchResponse?.entityName,
        estateApplicationId: values.estateApplicationId,
        entityRefDetailsId: values.entityRefDetailsId,
        plotId: searchResponse?.plotId,
        plotNo: values.plotNo?.value,
        paymentDetails: {
            migrationPaymentResetRequest: searchResponse?.paymentDetails?.migrationPaymentResetRequest?.map((val: any) => ({ ...val, isResetCompleted: true })),
            isPaymentDetailsUpdate: true,
            lastMeterReading: values.waterChargesLastMeterReading,
            pendencyReportDocReferenceId: values?.pendencyReport?.docReferenceId,
            payments: paymentDetailsPayload(values),
            uuid: values.paymentDetailsUuid
        },
        subLeaseDetails: subLeaseDetailsData,
        isSubLeaseDetailsUpdate: true
    };
    return payload;
};
