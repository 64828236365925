import _ from 'lodash';
import { formFieldDTO } from '../../../components/moduleCommonFormik/GenericFormCard.constants';
import { endpoints, POST, requestInfo } from '../../../services/modulesServices/userManagement.api';
import { AUTHORIZED_PERSON_TITLE, CONSTANT_DATA_FIELD, FormDataDTO } from '../../../utils/constants';
import { cross, user } from '../../../utils/Logo';
import { DOCUMENT_TYPE } from '../Migration/Migration.const';
import { useState } from 'react';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { entityDocumentDataMigration } from '../Migration/Migration.controller';
import { getPlotDetails } from '../EstateManagement/Allotment/Allotment.controller';
import { changeDateFormatToddMMyyyy, changeDateFormatToIST } from '../../../utils/utils';

const userType = JSON.parse(localStorage.getItem('user') || '{}')?.type;

export const bankDetailsInitialValue = {
    bankIFSC: '',
    bankName: '',
    branchAddress: ''
};

export const MORTGAGE_CORRESPONDENCE = [
    {
        title: 'A1 - Lessee Details',
        field: 'lesseeDetails'
    },
    {
        title: 'A2 - Mortgage Details',
        field: 'mortgageDetails'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Self Declaration',
        field: 'selfDeclaration'
    },
    {
        title: 'D - Payment Details',
        field: 'paymentDetails'
    },
    {
        title: 'E - Mortgage Notes',
        field: 'mortgageNotes'
    }
];

export const setMortgageLesseeData = async (resData: any) => {
    const permanentAddress = resData?.addresses?.find((val: any) => val?.addressType === 'PERMANENT');

    const documentData: any = await entityDocumentDataMigration(resData, resData?.authUsers[0]?.documents);
    let initialData: any = {};
    initialData['name'] = resData?.entityName;
    initialData['emailId'] = resData?.email;
    initialData['phoneNumber'] = resData?.phoneNumber;
    initialData['address1'] = permanentAddress?.address1;
    initialData['address2'] = permanentAddress?.address2;
    initialData['pinCode'] = permanentAddress?.pinCode;
    initialData['district'] = permanentAddress?.district;
    initialData['state'] = permanentAddress?.state;
    initialData['country'] = permanentAddress?.country;
    initialData['authorizedPersonFirstname'] = resData?.authUsers[0]?.firstName;
    initialData['authorizedPersonLastName'] = resData?.authUsers[0]?.lastName;
    initialData['pmobileNumber'] = resData?.authUsers[0]?.primaryMobileNo;
    initialData['alternateMobileNumber'] = resData?.authUsers[0]?.alternateMobileNo;
    initialData['authorizedEmailId'] = resData?.authUsers[0]?.email;
    initialData['letter'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY && doc.referenceType === DOCUMENT_TYPE.AUTHUSER);

    return initialData;
};

export const setMortgagePlotDetails = async (resData: any) => {
    let initialData: any = {};
    initialData['estateName'] = getPlotDetails(resData?.estateDetails?.estateName);
    initialData['phase'] = getPlotDetails(resData?.phase);
    initialData['plotNo'] = getPlotDetails(resData?.plotNo);
    initialData['plotType'] = getPlotDetails(resData?.plotType);
    initialData['plotRate'] = resData?.plotRate;
    initialData['plotArea'] = resData?.plotAreaSqm;
    return initialData;
};
// export const setMortgageDetails = async (resData:any)=>{
//     let initialData : any = {}
//     initialData['estateName'] =  getPlotDetails(resData?.estateDetails?.estateName);
//     initialData['phase'] = getPlotDetails(resData?.phase);
//     initialData['plotNo'] = getPlotDetails(resData?.plotNo);
//     initialData['plotType'] = getPlotDetails(resData?.plotType);
//     initialData['plotRate'] = resData?.plotRate;
//     initialData['plotArea'] = resData?.plotAreaSqm;
//     return initialData
// }
export const MORTGAGE_DECLARATION_LABEL = [
    '(i) the information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed herein.',
    '(ii) it has cleared all outstanding amounts due to the Corporation; and',
    '(iii) it is not in breach of any term and condition of the Lease Deed.'
];

export const MORTGAGE_STEPS_DETAILS = [
    // {
    //     titleCode: 'LESSEE_DETAILS',
    //     name: 'Lessee Details',
    //     index: 1,
    //     isActive: true
    // },
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'MORTGAGE_DETAILS',
        name: 'Mortgage/Charge Details',
        index: 2,
        isActive: false
    },
    {
        titleCode: 'SELF_DECLARATION',
        name: 'Self Declaration',
        index: 3,
        isActive: false
    },
    {
        titleCode: 'PAYMENT_DETAILS',
        name: 'Payment Details',
        index: 4,
        isActive: false
    }
];

export const deedExtensionField = {
    titleCode: 'DEED',
    title: 'Lease Deed/Tripartite Deed/Deed of Extension',
    field: 'lesseeDeedOfExtension',
    type: CONSTANT_DATA_FIELD.FILE_FIELD
};

export const MORTGAGE_DETAILS = [
    {
        formData: [
            {
                titleCode: 'MORTGAGE_TYPE',
                title: 'Mortgage Type',
                field: 'mortgageType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'DEED',
                title: 'Lease Deed/Tripartite Deed/Deed of Extension',
                field: 'lesseeDeedOfExtension',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const MORTGAGE_LESSEE_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: '',
                title: 'Name',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: '',
                title: 'Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: '',
                title: 'Phone No',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: '',
        title: 'Postal Address',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Pin Code',
                field: 'pinCode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },

            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Details of the Authorized Person',
        title: "Authorized Person's Details",
        toolTipDetails: {
            position: 'right',
            content: AUTHORIZED_PERSON_TITLE,
            variant: { height: '20px' }
        },
        formData: [
            {
                title: 'First Name',
                field: 'authorizedPersonFirstname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Last Name',
                field: 'authorizedPersonLastName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Primary Mobile Number',
                field: 'pmobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                title: 'Alternative Mobile Number',
                field: 'alternateMobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },

            {
                title: 'Email ID',
                field: 'authorizedEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6 me-3',
                isDisabled: true
            },

            {
                title: 'The letter / resolution / power of attorney authorising the authorised person',
                titleCode: 'Please attach the letter/resolution/power of attorny ',
                field: 'letter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'authorizedPersonFirstname',
                    documentType: DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY,
                    referenceType: DOCUMENT_TYPE.AUTHUSER
                },
                isDisabled: true
            }
        ]
    }
];
export const MORTGAGE_CHANGES_FINANCIAL_DETAILS = [
    {
        formData: [
            {
                title: 'IFSC Code',
                field: 'bankIFSC',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Bank Name',
                field: 'bankName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isRequired: true
            },
            {
                title: 'Branch Address',
                field: 'branchAddress',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isRequired: true
            }
        ]
    }
];

export const MORTGAGE_EXISTING_MORTGAGE_DETAILS = [
    {
        formData: [
            {
                title: 'IFSC Code',
                field: 'existingIfscCode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Bank Name',
                field: 'existingBankName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Branch Address',
                field: 'existingBranchAddress',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Date of NOC from Financial Institution/Banks issued',
                field: 'existingDateOFNoc',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                isDisabled: userType !== 'ENTERPRISE' ? true : false
            },
            {
                title: 'NOC from existing Financial Institution/Banks',
                field: 'existingNocDocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                isDisabled: userType !== 'ENTERPRISE' ? true : false,
                entityFileData: {
                    documentNamePrefix: 'Financial Institution',
                    documentType: DOCUMENT_TYPE.NOC
                }
            }
        ]
    }
];

export const MORTGAGE_PAYLOAD = (activeIndex: number, values: any, isFinalSubmit: boolean, plotUuid: any, userData: any, resData: any = [], applicationNo: string) => {
    console.log(userType);

    const BankDeatils = values?.bankDetails?.map((val: any) => {
        return {
            bankIFSC: val?.bankIFSC || '',
            bankName: val?.bankName || '',
            branchAddress: val?.branchAddress || '',
            bankRefId: null,
            nocRefId: null,
            nocDate: null,
            status: 'CURRENT'
        };
    });
    const existingBankDetails = values?.existingMortgageDetails?.map((val: any) => {
        return {
            uuid: val?.uuid || '',
            bankIFSC: val?.existingIfscCode || '',
            bankRefId: val?.bankRefId || '',
            nocRefId: val?.existingNocDocument?.docReferenceId || '',
            nocDate: resData?.bankMortgageDetails !== null ? val?.existingDateOFNoc : (!!val?.existingDateOFNoc && changeDateFormatToIST(val?.existingDateOFNoc)) || '',
            status: 'EXISTING'
            // parentMortgageId: 'fc9c0f73-ffb0-42f8-bcda-5027cad79f6c'
        };
    });
    console.log('const existing', existingBankDetails);
    const onCreateApplication = {
        RequestInfo: requestInfo(),
        enterpriseEntity: {
            ...(resData?.estateApplications?.enterpriseEntity ?? []),
            uuid: userData?.uuid,
            entityName: userData?.entityName,
            category: userData?.category,
            email: userData?.email,
            phoneNumber: userData?.phoneNumber,
            panNumber: userData?.panNumber,
            gstApplicable: userData?.gstApplicable,
            gstNumber: userData?.gstNumber,
            addresses: [
                {
                    ...(resData?.estateApplications?.enterpriseEntity?.addresses[1] ?? []),
                    addressType: userData?.addresses[1]?.addressType,
                    address1: userData?.addresses[1]?.address1,
                    address2: userData?.addresses[1]?.address2,
                    pinCode: userData?.addresses[1]?.pinCode,
                    city: null,
                    district: userData?.addresses[1]?.district,
                    state: userData?.addresses[1]?.state,
                    country: userData?.addresses[1]?.country
                },
                {
                    ...(resData?.estateApplications?.enterpriseEntity?.addresses[0] ?? []),
                    addressType: userData?.addresses[0]?.addressType,
                    address1: userData?.addresses[0]?.address1,
                    address2: userData?.addresses[0]?.address2,
                    pinCode: userData?.addresses[0]?.pinCode,
                    city: null,
                    district: userData?.addresses[0]?.district,
                    state: userData?.addresses[0]?.state,
                    country: userData?.addresses[0]?.country
                }
            ],
            documents: userData.documents,
            owners: [...(resData?.estateApplications?.enterpriseEntity?.owners ?? []), ...userData?.owners],
            entityBankDetail: {
                ...(resData?.estateApplications?.enterpriseEntity?.entityBankDetail ?? []),
                accountNumber: userData?.entityBankDetail?.accountNumber,
                ifscCode: userData?.entityBankDetail?.ifscCode,
                bankName: userData?.entityBankDetail?.bankName,
                branchAddress: userData?.entityBankDetail?.branchAddress
            },
            user: {
                ...(resData?.estateApplications?.enterpriseEntity?.user ?? []),
                ...userData?.user
            },
            miscDetails: {
                ...(resData?.estateApplications?.enterpriseEntity?.miscDetails ?? []),
                ...user?.miscDetails
            },
            authUsers: [
                {
                    ...(resData?.estateApplications?.enterpriseEntity?.authUsers ?? []),
                    firstName: userData?.authUsers[0]?.firstName,
                    lastName: userData?.authUsers[0]?.lastName,
                    primaryMobileNo: userData?.authUsers[0]?.primaryMobileNo,
                    alternateMobileNo: userData?.authUsers[0]?.alternateMobileNo,
                    email: userData?.authUsers[0]?.email,
                    documents: userData?.authUsers[0]?.documents
                }
            ]
        },
        plotId: plotUuid
    };
    let uniqueDataFilter;
    if (resData?.bankMortgageDetails) {
        const filteredData = [...(resData?.bankMortgageDetails ?? []), ...(BankDeatils ?? [])].filter((item: any) => item.mortgageId);
        const uniqueMap = new Map(filteredData.map((item: any) => [item.bankIFSC, item]));
        uniqueDataFilter = Array.from(uniqueMap.values());
    }

    const applicationUpdatePayload = {
        estateApplications: resData?.estateApplications,
        uuid: resData?.uuid,
        entityRefDetailId: resData?.entityRefDetails?.uuid,
        estateApplicationId: resData?.estateApplications?.uuid,
        // nocId: '5cff0746-37f1-436b-9e5d-39b76664bcba',
        type: resData?.type,
        status: resData?.status,
        bankMortgageDetails: resData?.bankMortgageDetails ? uniqueDataFilter : [...(BankDeatils ?? []), ...(existingBankDetails ?? [])],
        isFinalSubmit: isFinalSubmit
    };

    const update = {
        ...onCreateApplication,
        ...applicationUpdatePayload
    };
    if (!applicationNo) {
        return onCreateApplication;
    } else {
        return update;
    }
};
export const setExistingMortgageDetails = async (data: any, status: any) => {
    const detailData = _.cloneDeep(data);
    const filteredData = detailData.bankMortgageDetails.filter((val: any) => val?.status === status);
    const handleSearchIFSC = async (value: any) => {
        let bankDetails;
        try {
            bankDetails = {
                uuid: value?.uuid,
                bankRefId: value?.bankRefId,
                bankIFSC: value?.bankIFSC,
                bankName: value?.bankDetailsResponse?.bank || '',
                branchAddress: value?.bankDetailsResponse?.branch || ''
            };
        } catch (error) {
            bankDetails = {
                uuid: '',
                bankIFSC: '',
                bankRefId: '',
                bankName: '',
                branchAddress: ''
            };
        }
        return bankDetails;
    };

    let updatedData = await Promise.all(filteredData?.map(async (value: any) => await handleSearchIFSC(value)));
    updatedData = updatedData.map((val: any) => {
        return {
            uuid: val?.uuid,
            bankRefId: val?.bankRefId,
            existingIfscCode: val?.bankIFSC,
            existingBankName: val?.bankName,
            existingBranchAddress: val?.branchAddress
        };
    });

    console.log(updatedData);
    return updatedData;
};
