import { Card } from 'react-bootstrap';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { PLOT_TABLE_COLUMNS, SPECIAL_PROJECT_DOCUMENT, SPECIAL_TRANSFER_DETAILS, SPECIAL_TRANSFER_DOCUMENTS, SPECIAL_TRANSFER_SIDECARD } from './SpecialTransfer.const';
import { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { FormikProvider, useFormik } from 'formik';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PaymentDetails from '../Allotment/PaymentDetails';
import { POST, POST2, devUrl, endpoints, estateEndpoints, getProgressBarData, requestInfo, spTransferTypeDropdown } from '../../../../services/modulesServices/userManagement.api';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import ScrollToTop from '../../../../components/ScrollToTop';
import { useTranslation } from 'react-i18next';
import { fetchFeeData, fetchGatewayData, getNextActions, getOptions, getQueryParams, loadRazorpay, paymentCheck, paymentPayload } from '../EstateManagement.controller';
import { OWNERSHIP_VALIDATION, SP_TRANSFER_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { ASSIGN_TRANSFEREE_SEARCH, assignTransferreeColumns } from '../Transfer/Transfer.const';
import { check, cross } from '../../../../utils/Logo';
import GenericOwnerShipDetails from '../../../../components/Ownership/GenericOwnership';
import { getFormatedOwnershipDetails, setEntityDocumentDetails } from '../../Migration/Migration.controller';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { getFirstErrorKey } from '../../../../utils/utils';
import { APPLY_SPECIAL_TRANSFER, EMPLOYEE_AUTHORIZED, KEY_ID, SPL_TRANSFER, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../../../utils/constants';
import { specialTransferDetails } from '../../UserManagement/UserMangement.controller';
import { RazorpayOptions } from '../../UserManagement/types';
import Loading from '../../../../components/portalComponents/Loading.component';
import Declaration from '../Allotment/Declaration';
import { declarationList } from '../Transferee/Transferee.const';
import axios from 'axios';
import PaymentSubmissionStatus from '../../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../../components/moduleCommonFormik/ProgressBar';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { EsignModal } from '../../../commonPages/EsignModal';

export default function SpecialTransferApplication() {
    const [activeIndex, setActiveIndex] = useState<number>(1);
    let navigate = useNavigate();
    const { t } = useTranslation();

    const [dropdownData, setDropdownData] = useState<any>(null);
    const [paymentData, setPaymentData] = useState<any>([]);
    const [isPaid, setIsPaid] = useState<any>(false);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [selectedTableRow, setSelectedTableRow] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [showPopUp, setShowPopUp] = useState(false);
    const [stepsDetails, setStepsDetails] = useState(SPECIAL_TRANSFER_SIDECARD);
    const [entityData, setEntityData] = useState<any>(null);
    const [searchTransfereeResponse, setSearchTransfereeResponse] = useState<any>([]);
    const [selectedOneTransferee, setSelectedOneTrasnferee] = useState<null | string>(null);
    const [selectedTransfereeDetails, setSelectedTransfereeDetails] = useState<any>({});
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [finalOwnerShipDetails, setFinalOwnerShipDetails] = useState({
        applicantDetails: []
    });
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [calculated, setCalculated] = useState<boolean>(false);
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [tableRowData, setTableRowData] = useState<any>([]);
    const [selectedPlots, setSelectedPlots] = useState<any>([]);
    const [specialProjectDataResponse, setSpecialProjectDatResponse] = useState<any>([]);
    const [transferDetails, setTransferDetails] = useState<any>(null);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [applicationNo, setApplicationNo] = useState<any>(null);
    const [column, setColumn] = useState(PLOT_TABLE_COLUMNS);
    const [updatedTableRowData, setUpdatedTableRowData] = useState<any>([]);
    const [isChecked, setIsChecked] = useState([false, false, false]);
    const [eSignedDocumentData, setESignedDocumentData] = useState<any>(null);
    const [isChecklistDisabled, setIsChecklistDisabled] = useState<boolean>(false);
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [isChangeInOwnership, setIsChangeInOwnership] = useState<boolean>(false);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>(null);

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = user?.type;
    const [searchParams] = useSearchParams();
    const location: any = useLocation();
    const queryParams = getQueryParams(searchParams);
    const applicationNumber: any = searchParams.get('applicationNumber');
    const isSpecialTransfer = JSON.parse((searchParams.get('isSpecialTransfer') as any) || null);
    const signedFileStoreId = searchParams.get('signedFileStoreId');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            appliedUnderSpecialProject: false,
            esignOption: 'DSC'
        },
        onSubmit: () => {},
        validationSchema: !showOwnershipModal ? SP_TRANSFER_VALIDATION[`page${activeIndex}`] : OWNERSHIP_VALIDATION
    });

    const { values, setValues, errors, setFieldTouched, setFieldValue } = formik;

    useEffect(() => {
        const selfDec = {
            title: 'Self Declaration',
            titleCode: 'SELF_DECLARATION',
            index: 3,
            isActive: true
        };

        const steps =
            values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' || transferDetails?.modificationDetails?.requestDetails?.type === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST'
                ? [
                      ...SPECIAL_TRANSFER_SIDECARD.slice(0, 2),
                      selfDec,
                      {
                          title: 'Payment',
                          titleCode: 'PAYMENT',
                          index: 4,
                          isActive: true
                      }
                  ]
                : [
                      ...SPECIAL_TRANSFER_SIDECARD,
                      {
                          title: 'Assign Transferee',
                          titleCode: 'ASSIGN_TRANSFEREE',
                          index: 4,
                          isActive: true
                      }
                  ];

        setStepsDetails(steps);
    }, [values.specialTransferType, transferDetails]);

    // useEffect(() => {
    //     (async () => {
    //         const nextActions = await getNextActions(applicationNumber);
    //         setHasValidAction(nextActions?.nextValidAction?.length > 0);
    //         if (nextActions?.nextValidAction?.length === 0) {
    //             setIsChecked([true, true, true]);
    //         }
    //     })();
    // }, [applicationNumber]);

    useEffect(() => {
        (async () => {
            if (selectedPlots?.length && transferDetails) {
                try {
                    const payCheck = await paymentCheck('PROCESSING_FEE', selectedPlots, transferDetails?.applicationDetails.uuid, '', true);
                    setIsPaid(payCheck.isPaid);
                    setPaymentTried(payCheck.paymentTried);
                    setPaymentData(payCheck.paymentData);
                    setTotalPaidAmount(payCheck.totalPaidAmount);
                    setTransactionDetails(payCheck.transactionDetails);

                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);

                    const feeData = await fetchFeeData('SPECIAL_TRANSFER', 'PROCESSING_FEE', selectedPlots);
                    setPaymentData(feeData.feeMasterDetails);
                    setTotalPaidAmount(feeData.totalPaidAmount);
                    setPaymentIsLoading(false);
                } catch (e: any) {
                    console.log('errore1', e);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    setPaymentIsLoading(false);
                }
            }
        })();
    }, [activeIndex, selectedPlots]);

    useEffect(() => {
        (async () => {
            const dropdownOption = await spTransferTypeDropdown();
            setDropdownData(dropdownOption);
        })();
    }, []);

    useEffect(() => {
        loadRazorpay();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            //Fetch Transfer Lessee Details
            try {
                setIsLoading(true);
                if (dropdownData) {
                    const { data } = user.uuid && (await POST(endpoints.searchExistingUser + `?userUuid=${user.uuid}`, { RequestInfo: requestInfo() }));
                    setEntityData(data?.enterpriseEntity[0]);

                    if (data) {
                        let { data: plotId } = data?.enterpriseEntity[0].uuid && (await POST(endpoints.validApplication + `?type=${APPLY_SPECIAL_TRANSFER}`, { RequestInfo: requestInfo() }));
                        // let { data: plotId } =
                        //     data?.enterpriseEntity[0].uuid && (await POST(endpoints.entityRefDetails + `?entityIds=${data?.enterpriseEntity[0].uuid}&types=LESSEE`, { RequestInfo: requestInfo() })); // testing purpose
                        let plotIds = plotId?.entityRefDetails?.map((val: any) => val.plotId);

                        // setPlotIdSpecial([...plotIdSpecial, ...plotIds]);

                        plotIds = plotIds?.join(',');
                        const { data: plotDataDetails } = plotIds && (await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() }));
                        // const { data: isSpecialProject } =
                        //     entityData?.uuid &&
                        //     plotIdSpecial &&
                        //     (await POST(endpoints.leaseSpecialProject + `?entityIds=${entityData?.uuid}&plotIds=${plotIdSpecial}`, {
                        //         RequestInfo: requestInfo()
                        //     }));
                        // setSpecialProjectDatResponse(isSpecialProject?.enterpriseDetails);
                        const updatedPlotDetails = plotDataDetails?.plots?.map((val: any) => ({ ...val, estateName: val?.estateDetails.estateName, id: val.uuid }));
                        updatedPlotDetails && setTableRowData([...updatedPlotDetails]);
                    }
                }

                setIsLoading(false);
            } catch (e: any) {
                console.log('eror2r', e);
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };
        fetchData();
    }, [dropdownData, transferDetails]);

    useEffect(() => {
        const fetchESignStatus = async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: transferDetails?.applicationDetails?.applicationNo,
                    filestoreId: signedFileStoreId || undefined
                };

                const { data } = await POST(endpoints.consolidatedDocSearch, payload);
                data.filestoreId && setESignedDocumentData(data);
                !data.isEsignRequired && setIsChecked([true, true, true]);
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (activeIndex === 3 && values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' && hasValidAction && !signedFileStoreId) {
            fetchESignStatus();
        }
    }, [activeIndex, values.specialTransferType, transferDetails]);

    useEffect(() => {
        (async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: transferDetails?.applicationDetails?.applicationNo,
                    filestoreId: signedFileStoreId || undefined
                };
                if (signedFileStoreId) {
                    const { data } = await POST(endpoints.consolidatedDocESign, payload);
                    // setESignedDocumentData(data);
                    const consolidatedDocSearch = await POST(endpoints.consolidatedDocSearch, payload);
                    consolidatedDocSearch?.data.filestoreId && setESignedDocumentData(consolidatedDocSearch?.data);
                    !data.isEsignRequired && setIsChecked([true, true, true]);
                }
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [signedFileStoreId]);

    useEffect(() => {
        if (queryParams?.['activeIndex']) {
            setActiveIndex(+queryParams?.['activeIndex']);
            setIsChecked([true, true, true]);
            setIsChecklistDisabled(true);
        }
    }, [location.search]);

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                await SP_TRANSFER_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: 'Fill in all the required details', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const onClickPrevious = () => {
        const stepperLength = SPECIAL_TRANSFER_SIDECARD.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const onClickNext = () => {
        if (activeIndex === 1) {
            setActiveIndex(activeIndex + 1);
        }
    };

    const onClickCancel = () => {
        navigate('/goa-idc/enterprisedashboard');
    };

    const lastSubmit = (key?: any) => {
        if (
            ((activeIndex === 4 && values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST') ||
                (activeIndex === 3 && values.specialTransferType?.code !== 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST')) &&
            !isPaid
        )
            onPay(key);
        else onSubmit();
    };

    const onChangeNextIndex = () => {
        try {
            if (activeIndex < stepsDetails.length) {
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            }
        } catch (e) {}
    };

    const onSubmit = async () => {
        let docs: any = [
            setEntityDocumentDetails(values.approvalOfRegistrarOfCompanies) || null,
            setEntityDocumentDetails(values.memorandumOfAssociation) || null,
            setEntityDocumentDetails(values.relevantCourtOrder) || null,
            setEntityDocumentDetails(values.corporateInsolvencyResolutionPlan) || null,
            setEntityDocumentDetails(values.LLPRegistration) || null,
            setEntityDocumentDetails(values.deathCertificate) || null,
            setEntityDocumentDetails(values.successionCertificate) || null,
            setEntityDocumentDetails(values.nocGoaIPB) || null
        ].filter(Boolean);

        const payload: any = {
            RequestInfo: requestInfo(),
            isSpecialTransfer: true,
            transferorDetails: transferDetails ? transferDetails?.transferorDetails : entityData,
            transfereeDetails:
                values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' || transferDetails?.modificationDetails?.requestDetails?.type === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST'
                    ? transferDetails
                        ? transferDetails?.transferorDetails
                        : entityData
                    : activeIndex === 4
                    ? selectedTransfereeDetails?.item
                    : null,
            modificationDetails: transferDetails
                ? {
                      ...transferDetails.modificationDetails,
                      requestDetails: {
                          ...transferDetails.modificationDetails.requestDetails,
                          documents: docs,
                          plotOrderRequests: selectedPlots?.map((val: any) => ({ plotId: val })),
                          owners: values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ? values?.ownership || transferDetails.modificationDetails.requestDetails.owners : null
                      }
                  }
                : {
                      modificationType: SPL_TRANSFER,
                      requestDetails: {
                          type: values.specialTransferType?.code,
                          documents: docs,
                          owners:
                              values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ? values?.ownership || transferDetails?.modificationDetails?.requestDetails?.owners : null
                      }
                  },
            isTransferorFinalSubmit: activeIndex === stepsDetails?.length,
            plotIds: selectedPlots
        };

        if (transferDetails) {
            payload['uuid'] = transferDetails.uuid;
            payload['applicationDetails'] = transferDetails.applicationDetails;
            payload['status'] = transferDetails.status;
            payload['auditDetails'] = transferDetails.auditDetails;
        }

        if (values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' && (!values?.ownership || !isChangeInOwnership) && activeIndex === 1) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please update the Ownership Details', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 2 && selectedPlots?.length === 0) {
            setModalDetails({ show: true, title: 'Error!', para: t('SELECT_ATLEAST_1_PLOT', 'Select at least one plot'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 3 && values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' && !isChecked.every((e: boolean) => e)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });

            return;
        }

        if (activeIndex === 3 && values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' && eSignedDocumentData?.isEsignRequired) {
            setModalDetails({ show: true, title: 'E-Sign Pending', para: 'Please sign the document', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 4 && values.specialTransferType?.code !== 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' && !selectedTransfereeDetails?.item) {
            setModalDetails({ show: true, title: 'Error!', para: t('SELECT_ASSIGNEE', 'Please select Assignee'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        // if (!calculated && values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST') {
        //     setModalDetails({ show: true, title: 'Error!', para: 'Please calculate Controlling Ownership', img: cross, type: 'error', reUpload: 'OK' });
        //     return;
        // }

        const apiEndPoint = transferDetails ? endpoints.transferUpdate : endpoints.transferCreate;

        try {
            if (activeIndex <= stepsDetails.length) {
                await SP_TRANSFER_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false });
            }
            try {
                const { data } = await POST(apiEndPoint, payload);
                setTransferDetails(data?.transferDetails[0]);
                setApplicationNo(data?.transferDetails[0]?.applicationDetails?.applicationNo);
                if (activeIndex === 1) {
                    toast(t('APPLICATION_CREATED', 'Application Created'), {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                } else if (activeIndex === 2) {
                    toast(t('DATA_SAVED_SUCCESSFULLY', 'Data saved successfully'), {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                }
                onChangeNextIndex();
                if (activeIndex === 4) {
                    setModalDetails({
                        show: true,
                        title: 'Success!',
                        para: t('APP_ASSIGNED_SUCCESSFULLY', 'Application assigned successfully'),
                        img: check,
                        type: 'success',
                        reUpload: 'OK',
                        finalSubmit: true
                    });
                }
                // else if (values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' && activeIndex === 3) {
                //     setModalDetails({
                //         show: true,
                //         title: 'Success!',
                //         para: t('APP_SUBMITTED_SUCCESSFULLY', 'Application submitted successfully'),
                //         img: check,
                //         type: 'success',
                //         reUpload: 'OK',
                //         finalSubmit: true
                //     });
                // }
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('SPECIAL_TRANSFER_FAILED', 'Application for Special Transfer Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ENTER_ALL_REQUIRED_FIELDS', 'Please fill all the required fields'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getTransactionUpdate = async (params: any) => {
        try {
            if (params.plotIds) {
                const paid: any = await paymentCheck('PROCESSING_FEE', params.plotIds, params.applicationId, '', true);
                if (!paid.isPaid) {
                    setIsLoading(true);
                    const payload = { RequestInfo: requestInfo() };
                    const { data } = await POST2(endpoints.transactionUpdate, payload, { eg_pg_txnid: params.eg_pg_txnid });

                    // if (data?.Transaction?.[0]?.txnStatus === 'FAILURE') {
                    //     getTransactionUpdate(params);
                    // }

                    if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                        setTransactionDetails(data?.Transaction?.[0]);
                        if (data?.Transaction?.[0]?.txnId) {
                            try {
                                const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                                if (resp.data.status === 'SUCCESSFUL') {
                                    setModalDetails({ show: true, title: 'Success!', para: t('PAYMENT_SUCCESSFULL', 'Payment successful'), img: check, type: 'success', reUpload: 'OK' });

                                    setIsPaid(true);
                                } else setIsPaid(false);
                                setIsLoading(false);
                            } catch (e: any) {
                                setIsPaid(false);
                                setIsLoading(false);
                                setPaymentIsLoading(false);
                            }
                        }
                    } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                        setModalDetails({
                            show: true,
                            title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                            para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                            img: cross,
                            type: 'error',
                            reUpload: 'OK'
                        });
                        // window.location.reload();
                    }
                    setIsLoading(false);
                }
            }
            // }
        } catch (e: any) {
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('TRANSACTION_FAILED', 'Transaction Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                if (queryParams?.['applicationNumber']) {
                    queryParams?.['activeIndex'] && setActiveIndex(+queryParams?.['activeIndex']);

                    // let plotIdsArray

                    // if(queryParams?.['plotIds']){
                    //     plotIdsArray = queryParams?.['plotIds'].split(',').map((item: any) => item.trim());
                    // } else{
                    //     plotIdsArray=
                    // }

                    // setSelectedPlots(plotIdsArray);

                    const { data: transferSearch } = await POST(endpoints.transferSearch + `?applicationNos=${queryParams?.['applicationNumber']}`, { RequestInfo: requestInfo() });
                    setTransferDetails(transferSearch.transferDetails[0]);

                    const tempPlotData = transferSearch.transferDetails[0].plotDetails?.map((val: any) => ({ ...val, estateName: val?.estateDetails.estateName, id: val.uuid }));
                    // setTableRowData([...tableRowData, ...tempPlotData]);
                    setFileStoreId(transferSearch?.transferDetails[0]?.signedConsolidatedDocRefId);

                    const plotIdsArray = transferSearch?.transferDetails[0]?.plotDetails?.map((plot: any) => plot.uuid);
                    setSelectedPlots(plotIdsArray);
                    // setPlotIdSpecial([...plotIdSpecial, ...plotIdsArray]);

                    // const { data: plotDataDetails } = plotIdsArray && (await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIdsArray}`, { RequestInfo: requestInfo() }));
                    // const updatedPlotDetails = plotDataDetails?.plots?.map((val: any) => ({ ...val, estateName: val?.estateDetails.estateName, id: val.uuid }));
                    // // setTableRowData(updatedPlotDetails);

                    const dropdownOption = await spTransferTypeDropdown();

                    const specialTransferInitData =
                        transferSearch.transferDetails[0]?.modificationDetails &&
                        dropdownOption &&
                        (await specialTransferDetails(transferSearch.transferDetails[0]?.modificationDetails, dropdownOption));
                    setValues((prev: any) => ({ ...prev, ...specialTransferInitData }));

                    if (transferSearch?.transferDetails[0]?.transfereeDetails) {
                        setSelectedOneTrasnferee(transferSearch?.transferDetails[0]?.transfereeDetails?.uuid);
                    }

                    // if (
                    //     transferSearch.transferDetails[0].modificationDetails.requestDetails.type === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ||
                    //     transferSearch.transferDetails[0].modificationDetails.requestDetails.type === 'SPL_CONVERSION_TO_CORPORATE' ||
                    //     transferSearch.transferDetails[0].modificationDetails.requestDetails.type === 'SPL_CONVERSION_TO_LLP'
                    // ) {
                    //     // let plotIds = tableRowData.map((val: any) => val.uuid);    // tableRowData is empty in all cases
                    //     // setSelectedPlots(plotIds);
                    //     const updatedTableRow = tableRowData.map((val: any) => ({ ...val, isChecked: true, isDisabled: true }));
                    //     setTableRowData(updatedTableRow);
                    // } else {
                    //     const updatedTableRow = tableRowData.map((val: any) => ({ ...val, isChecked: false, isDisabled: false }));
                    //     setTableRowData(updatedTableRow);
                    // }
                    setPaymentIsLoading(true);
                    getTransactionUpdate(queryParams);
                    setPaymentIsLoading(false);
                }
                setIsLoading(false);
            } catch (e: any) {
                console.log('error3', e);
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [location.search]);

    useEffect(() => {
        (async () => {
            try {
                // const { data: existingUserData } = user.uuid && (await POST(endpoints.searchExistingUser + `?userUuid=${user.uuid}`, { RequestInfo: requestInfo() }));
                if (transferDetails || entityData) {
                    let owners;
                    if (transferDetails?.modificationDetails?.requestDetails?.owners) {
                        owners = transferDetails?.modificationDetails?.requestDetails?.owners;
                    } else {
                        owners = entityData?.owners;
                    }
                    const newOwnership = owners?.length && (await getFormatedOwnershipDetails(owners, dropdownData));
                    newOwnership && setOwnershipDetails(newOwnership);
                    owners && setControllingOwnershipResponse(owners);
                }
            } catch (e: any) {
                console.log('errorrrrrr4', e);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [transferDetails, entityData]);

    const onPay = async (key: any) => {
        setIsLoading(true);
        let url =
            window.location.origin +
            `/goa-idc/estate/special-transfer?activeIndex=${activeIndex}&applicationNumber=${transferDetails?.applicationDetails?.applicationNo}&plotIds=${selectedPlots}&applicationId=${transferDetails?.applicationDetails.uuid}`;
        const periodTo = new Date(transferDetails.auditDetails.createdTime).setFullYear(new Date(transferDetails.auditDetails.createdTime).getFullYear() + 1);
        const payload = paymentPayload(
            transferDetails?.applicationDetails,
            values.plotUuid,
            totalPaidAmount,
            url,
            'Transfer Application Fees',
            selectedGateway,
            transferDetails.auditDetails.createdTime,
            periodTo,
            paymentData
        );
        try {
            if (!totalPaidAmount) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: t('TOTAL_AMOUNT', 'Total amount must be greater than zero'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            if (!selectedGateway) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: t('PAYMENT_GATEWAY', 'Please select a payment gateway'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            if (transferDetails?.applicationDetails?.applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data } = await POST(endpoints.paymentGateway, payload);

                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, user, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data.additionalDetails?.walletClientCode}&walletRequestMessage=${data.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
                setIsLoading(false);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setIsLoading(false);
            setModalDetails({ show: true, title: t('PAYMENT_FAILED', 'Payment Initiation Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    // const renderTransferorDetails = () => {
    //     return (
    //         <Card className='card_border'>
    //             <Card.Body>
    //                 {TRANSFEROR_DETAILS.map((val: any) => (
    //                     <GenericFormCard {...val} />
    //                 ))}
    //             </Card.Body>
    //         </Card>
    //     );
    // };

    const onOwnershipDetailsChange = (key: string, value: any) => {
        setFinalOwnerShipDetails({ ...finalOwnerShipDetails, [key]: value });
    };

    const tooltipDetails: any = {
        ownershipDetails: {
            position: 'right',
            content: `<div class="d-flex flex-column font-inter" style="gap:5px;"><div class="mb-2">"Ownership Interest" means:</div> <div>(i)	holding of voting rights in a body corporate as defined under the Companies Act, 2013 (Act No. 18 of 2013); or</div>
            <div>(ii)	ownership of or entitlement to the capital or profits in a limited liability partnership, partnership firm, society or trust;</div></div>
            `
        },
        promoterDetails: {
            position: 'right',
            content: `<div class="d-flex flex-column font-inter" style="gap:5px;"><div class="mb-2">"Promoter" means:</div> <div>(i)	with respect to a body corporate, Person(s) holding, individually or collectively, Controlling Ownership Interest; or</div>
            <div>(ii)	with respect to a limited liability partnership or partnership firm, partner(s) collectively holding Controlling Ownership Interest; or</div>
            <div>(iii)	with respect to any other legal entity recognized under law, the constituent Person(s) collectively holding Controlling Ownership Interest;</div></div>
            `
        }
    };

    const onCheckHandler = (checkedItems: any) => {
        setSelectedPlots(checkedItems);
    };

    useEffect(() => {
        if (
            values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ||
            values.specialTransferType?.code === 'SPL_CONVERSION_TO_CORPORATE' ||
            values.specialTransferType?.code === 'SPL_CONVERSION_TO_LLP' ||
            transferDetails?.modificationDetails?.requestDetails?.type === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ||
            transferDetails?.modificationDetails?.requestDetails?.type === 'SPL_CONVERSION_TO_CORPORATE' ||
            transferDetails?.modificationDetails?.requestDetails?.type === 'SPL_CONVERSION_TO_LLP'
        ) {
            let tableColumn = PLOT_TABLE_COLUMNS.map((val: any, ind: number) => {
                if (ind === 0) {
                    return { ...val, isDisabled: true };
                }
                return val;
            });
            setColumn(tableColumn);

            if (tableRowData?.length) {
                let plotIds = tableRowData?.map((val: any) => val.uuid);
                setSelectedPlots(plotIds);
            }

            const updatedTableRow = tableRowData?.map((val: any) => ({ ...val, isChecked: true, isDisabled: true }));
            setUpdatedTableRowData(updatedTableRow);
        } else {
            let updatedTableRow;

            // console.log('&&&&&&&&&&&&&&&&plotIdsSeleceted', plotIdsSelected);
            if (transferDetails?.modificationDetails?.requestDetails?.plotOrderRequests) {
                const plotIdsSelected = transferDetails?.plotDetails?.map((plot: any) => plot.uuid);
                updatedTableRow = tableRowData?.map((val: any) => {
                    const isChecked = plotIdsSelected?.includes(val.id) ?? false;
                    return { ...val, isChecked: isChecked, isDisabled: true };
                });
            } else {
                updatedTableRow = tableRowData?.map((val: any) => ({ ...val, isChecked: false, isDisabled: false }));
            }

            setUpdatedTableRowData(updatedTableRow);
        }
    }, [values.specialTransferType, transferDetails, tableRowData]);

    useEffect(() => {
        (async () => {
            const data =
                entityData?.uuid &&
                (await POST(endpoints.leaseSpecialProject + `?entityIds=${entityData?.uuid}&plotIds=${selectedPlots}`, {
                    RequestInfo: requestInfo()
                }));

            const filteredSpecialProjectData = data?.data?.enterpriseDetails?.filter((val: any) => val.specialProject === true);
            const hasMatchingPlotId = () => {
                for (let obj of filteredSpecialProjectData) {
                    if (selectedPlots.includes(obj.entityRefDetails.plotId)) {
                        return true;
                    }
                }
                return false;
            };
            const isSpecialProject = (selectedPlots && data?.data?.enterpriseDetails && hasMatchingPlotId()) || false;
            setValues((prev: any) => ({ ...prev, appliedUnderSpecialProject: isSpecialProject, isUpdatable: hasValidAction }));
        })();
    }, [selectedPlots, entityData, hasValidAction]);

    const renderTransferDetails = () => {
        return (
            <Card className='card_border'>
                <Card.Body>
                    {SPECIAL_TRANSFER_DETAILS.map((val: any) => (
                        <GenericFormCard {...val} dropdownOptions={dropdownData} />
                    ))}
                    {values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ||
                    transferDetails?.modificationDetails?.requestDetails?.type === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ? (
                        <GenericOwnerShipDetails
                            showOwnershipModal={showOwnershipModal}
                            setShowOwnershipModal={setShowOwnershipModal}
                            changeOwnershipDetails={(values: any) => onOwnershipDetailsChange('applicantDetails', values)}
                            values={values}
                            dropdownData={dropdownData}
                            setControllingOwnershipResponse={setControllingOwnershipResponse}
                            controllingOwnershipResponse={controllingOwnershipResponse}
                            allOwnerShipDetails={finalOwnerShipDetails}
                            setOwnershipDetails={setOwnershipDetails}
                            setCalculated={setCalculated}
                            ownershipDetails={ownershipDetails}
                            toolTipDetails={tooltipDetails}
                            hideCard={true}
                            isDisabled={!hasValidAction}
                            setIsChangeInOwnership={setIsChangeInOwnership}
                            skipFullOwnershipPercent={entityData?.category === 'LTD_COMPANY'}
                            // scrollNotRequired={true}
                        />
                    ) : (
                        <></>
                    )}
                    {SPECIAL_TRANSFER_DOCUMENTS.map((val: any) => (
                        <GenericFormCard {...val} />
                    ))}
                </Card.Body>
            </Card>
        );
    };

    const renderPlotDetails = () => {
        return (
            <>
                <Card className='card_border'>
                    <Card.Body>
                        <div className='ps-2 py-2 d-flex justify-content-between'>
                            <div className='formTitle m-0'>{t('PLOT_DETAILS', ' Plot Details')}</div>
                        </div>
                        <div className='pb-2'>
                            <GenericActionTable
                                checkType={`multiCheck`}
                                columns={column}
                                searchBarPlaceholder={'SEARCH_BY_PLOT'}
                                rowData={updatedTableRowData}
                                onCheckHandler={onCheckHandler}
                                hideExportBtn={true}
                            />
                        </div>
                        {SPECIAL_PROJECT_DOCUMENT.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const onClickSearch = () => {};

    const onRowCheck = (e: any) => {
        setSelectedTableRow(e?.[0] || null);
    };

    const assignTransfereeData = useMemo(() => {
        const tempData = transferDetails?.transfereeDetails ? [transferDetails?.transfereeDetails] : searchTransfereeResponse;
        return (
            tempData &&
            tempData?.map((item: any) => {
                return {
                    email: item.email,
                    name: item.entityName,
                    phoneNumber: item.phoneNumber,
                    id: item.uuid,
                    item
                };
            })
        );
    }, [searchTransfereeResponse, transferDetails]);

    const onAssignTransfereeSearch = async () => {
        const queryParams = new URLSearchParams();
        if (values.assignTransfereeName) queryParams.append('entityName', values.assignTransfereeName);
        if (values.assignTransfereeEmail) queryParams.append('email', values.assignTransfereeEmail);
        if (values.assignTransfereeMobileNo) queryParams.append('phoneNumber', values.assignTransfereeMobileNo);

        const { data } = await POST(endpoints.searchExistingUser + `?${queryParams.toString()}`, { RequestInfo: requestInfo() });

        if (data?.enterpriseEntity?.length) setSearchTransfereeResponse(data.enterpriseEntity);
        else {
            setModalDetails({ show: true, title: 'Error!', para: t('NO_RECORD_FOUND', 'No Record Found'), img: cross, type: 'error', reUpload: 'OK' });
            setSearchTransfereeResponse([]);
        }
    };

    const onTransfereeSelect = (selectedTransferee: any) => {
        setSelectedOneTrasnferee(selectedTransferee.id);
        setSelectedTransfereeDetails(selectedTransferee);
    };

    const AssignTransferee = () => {
        const assignColumns = [
            {
                colName: '',
                fieldName: 'radio',
                clsName: 'col-sm-1',
                onRadioClick: onTransfereeSelect,
                selectedValue: selectedOneTransferee
            },
            ...assignTransferreeColumns
        ];

        return (
            <div>
                <>
                    <Card className='card_border'>
                        <Card.Body>
                            <>
                                {hasValidAction ? (
                                    <>
                                        {ASSIGN_TRANSFEREE_SEARCH?.map(val => (
                                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                        ))}
                                        <div className='d-flex justify-content-end mb-5'>
                                            <GenericButtons
                                                text={'Reset'}
                                                variant='secondary'
                                                handleClick={() => {
                                                    setValues({ ...values, assignTransfereeName: '', assignTransfereeMobileNo: '', assignTransfereeEmail: '' });
                                                    setSearchTransfereeResponse([]);
                                                    setSelectedOneTrasnferee(null);
                                                }}
                                            />
                                            <GenericButtons
                                                text={'Search'}
                                                variant='primary'
                                                disabled={!values.assignTransfereeName && !values.assignTransfereeEmail && !values.assignTransfereeMobileNo}
                                                handleClick={onAssignTransfereeSearch}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>

                            {assignTransfereeData?.length ? (
                                <Card.Body>
                                    <h3 className='Employee_search_result'>Search Result</h3>
                                    <GenericActionTable hideExportBtn={true} columns={assignColumns} rowData={assignTransfereeData} hideSearchBar={true} />
                                </Card.Body>
                            ) : null}
                        </Card.Body>
                    </Card>
                </>
            </div>
        );
    };

    useEffect(() => {
        if (queryParams?.['applicationNumber']) {
            (async () => {
                try {
                    const nextActions = await getNextActions(queryParams?.['applicationNumber']);
                    setHasValidAction(nextActions?.nextValidAction?.length > 0);
                    // setValues({ ...values, isUpdatable: nextActions?.nextValidAction?.length > 0 });
                    if (nextActions?.nextValidAction?.length === 0) {
                        setIsChecked([true, true, true]);
                    }
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, []);

    useEffect(() => {
        setFieldValue('isUpdatable', hasValidAction);
    }, [hasValidAction]);

    const handleModalClose = (type: string, finalSubmit?: boolean, isUnauthorized?: boolean) => {
        setModalDetails({});
        if (type === 'success' && finalSubmit && !isUnauthorized) {
            navigate('/goa-idc/enterprisedashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            } else if (userType === 'EMPLOYEE') {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const signDocument = async () => {
        const userRequestInfo = requestInfo();
        let url = window.location.origin + `/goa-idc/estate/special-transfer?activeIndex=${activeIndex}&applicationNumber=${applicationNumber || applicationNo}&isSpecialTransfer=true`;
        url = btoa(url);

        if (values.esignOption === 'EKYC') {
            const userRequestInfo = requestInfo();
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${eSignedDocumentData.filestoreId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(
                `/goa-idc/emudra-esign?fileStoreId=${eSignedDocumentData.filestoreId}&callbackUrl=${url}&authType=1&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }`
            );
        }
    };

    const handleClickSignDocument = () => {
        if (activeIndex === 3 && !isChecked.every((e: boolean) => e)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setEsignModal(true);
    };

    const onCancel = () => {
        navigate(-1);
    };

    console.log('formik', formik);
    useEffect(() => {
        if (!!applicationNumber)
            getProgressBarData(applicationNumber)
                .then(res => setProgressBarDetails(res.data))
                .catch(e => console.log(e));
    }, [applicationNumber, dropdownData]);
    const status: any = progressBarDetails?.progressStatus.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: applicationNo,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        // isRoleMD: isRoleMD,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };

    return (
        <>
            <HeaderComponent />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit, modalDetails?.isUnauthorized)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit, modalDetails?.isUnauthorized)}
            />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader
                    text={t('APP_SPECIAL_TRANSER', 'Application for Special Transfer')}
                    additionalClass='sub-text-style'
                    bottomSubText={
                        applicationNo
                            ? `Type : Special Transfer, No : ${applicationNo}`
                            : queryParams?.['applicationNumber']
                            ? `Type : Special Transfer, No : ${queryParams?.['applicationNumber']}`
                            : ``
                    }
                />
                <div className='col-3 stickyCard-section'>
                    <div className='stickyCard'>
                        <GenericHeader
                            text={t('APP_SPECIAL_TRANSER', 'Application for Special Transfer')}
                            sideHeader={true}
                            additionalClass='sub-text-style'
                            bottomSubText={applicationNo ? `Type : Special Transfer, No : ${applicationNo}` : ``}
                        />
                        <Card className='card_border'>
                            <Card.Body>
                                <Card.Text className=' d-flex flex-column row-gap-3  '>
                                    <SideCard steps={stepsDetails} activeIndex={activeIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className='col-9 form-section'>
                    {!!applicationNumber && applicationData.status !== 'Draft' ? (
                        <Card className='card_border my-2'>
                            <Card.Body>
                                <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                            </Card.Body>
                        </Card>
                    ) : (
                        <></>
                    )}
                    <FormikProvider value={formik}>
                        {activeIndex === 1 && renderTransferDetails()}
                        {activeIndex === 2 && renderPlotDetails()}
                        {activeIndex === 3 &&
                            (values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ? (
                                <Declaration
                                    isChecked={isChecked}
                                    declarationList={declarationList}
                                    handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                                    signDocument={handleClickSignDocument}
                                    eSignedDocumentData={eSignedDocumentData}
                                    applicantName={'Transferee/Successor'}
                                    isDisabled={!hasValidAction || isChecklistDisabled}
                                    declarationTitle={!isSpecialTransfer && ''}
                                    fileStoreId={fileStoreId}
                                ></Declaration>
                            ) : !paymentIsLoading ? (
                                <PaymentDetails
                                    paymentData={paymentData}
                                    selectedGateway={selectedGateway}
                                    setSelectedGateway={setSelectedGateway}
                                    isPaid={isPaid}
                                    gatewayData={gatewayData}
                                    transactionDetails={transactionDetails}
                                    title={'Special Transfer Application Fees'}
                                />
                            ) : (
                                <Loading />
                            ))}

                        {activeIndex === 4 &&
                            (values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ? (
                                !paymentIsLoading ? (
                                    <PaymentDetails
                                        paymentData={paymentData}
                                        selectedGateway={selectedGateway}
                                        setSelectedGateway={setSelectedGateway}
                                        isPaid={isPaid}
                                        gatewayData={gatewayData}
                                        transactionDetails={transactionDetails}
                                        title={'Special Transfer Application Fees'}
                                    />
                                ) : (
                                    <Loading />
                                )
                            ) : (
                                AssignTransferee()
                            ))}
                        <GenericPopupModal
                            title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                            primaryBtnTitle={t('CONFIRM', 'Confirm')}
                            secondaryBtnTitle={t('CLOSE', 'Close')}
                            isSecondaryBtn={true}
                            secondaryAction={() => setEsignModal(false)}
                            primaryAction={signDocument}
                            isVisible={esignModal}
                            children={<EsignModal />}
                        />
                    </FormikProvider>
                </div>
            </div>
            <ScrollToTop />
            <footer className='footer fixed-bottom footerBtnPatch'>
                <div className='d-flex'>
                    <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                        {activeIndex !== 1 && <GenericButtons text={'Previous'} handleClick={onClickPrevious} />}
                    </div>
                    <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                        {hasValidAction ? (
                            <>
                                {(activeIndex === 1 || activeIndex === 2 || (activeIndex === 3 && values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST')) && (
                                    <GenericButtons text={'Save & Next'} variant='primary' handleClick={lastSubmit} />
                                )}
                                {((activeIndex === 3 && values.specialTransferType?.code !== 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST') ||
                                    (activeIndex === 4 && values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST')) && (
                                    <GenericButtons
                                        text={
                                            isPaid
                                                ? values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ||
                                                  transferDetails?.modificationDetails?.requestDetails?.type === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST'
                                                    ? t('SUBMIT', 'Submit')
                                                    : t('NEXT', 'Next')
                                                : paymentTried
                                                ? 'Retry Payment'
                                                : 'Pay Now'
                                        }
                                        variant='primary'
                                        handleClick={lastSubmit}
                                    />
                                )}
                                {activeIndex === 4 && values.specialTransferType?.code !== 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' && (
                                    <GenericButtons text={t('ASSIGN', 'Assign')} variant='primary' handleClick={lastSubmit} />
                                )}
                            </>
                        ) : (
                            <>
                                {activeIndex === stepsDetails.length ? (
                                    <>
                                        <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={onCancel} />
                                    </>
                                ) : (
                                    <>
                                        <GenericButtons text={t('NEXT', 'Next')} variant='primary' handleClick={onChangeNextIndex} />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </footer>
        </>
    );
}
