import { formFieldDTO } from '../components/moduleCommonFormik/GenericFormCard.constants';

export const SmapleFormData = [];

export const TENANT_ID = 'ga';
export const KEY_ID: any = {
    HDFC: 'rzp_test_ep5vfxAwJAC07D',
    AXIS: 'rzp_test_RngnB29dTDvo2r'
};

export const ICICI_PAYMENT_URL = 'https://demo.b2biz.co.in/ws/payment';
export const EMUDRA_KYC_DASHBOARD_URL = 'https://app.sandbox.emudhra.com/index.jsp';

export const USER_TYPE = {
    ENTERPRISE: 'ENTERPRISE',
    EMP: 'EMPLOYEE',
    EXT_EMP: 'EXTERNAL_EMPLOYEE'
};

export const EMUDRA_LOCALHOST_URL = 'https://localhost.emudhra.com:26769';
export const BASE_URL_LOCAL = 'https://idc-stage.goa.gov.in';
export interface FormDataDTO {
    titleCode?: string;
    title?: string;
    toolTipDetails?: any;
    formData: formFieldDTO[];
    isReadOnly?: boolean;
    isRequired?: boolean;
    isSameAsAbove?: boolean;
    multisection?: string;
    isEditable?: boolean;
    field?: string;
    type?: string;
    showInr?: boolean;
    clsName?: any;
    viewOnly?: boolean;
}

export const CONSTANT_DATA_FIELD = {
    TEXT_FIELD: 'text',
    SELECT_FIELD: 'select',
    SELECT_WITH_SEARCH_FIELD: 'searchSelect',
    MULTI_SELECT_FIELD: 'multi',
    DATE_FIELD: 'date',
    RADIO_FIELD: 'radio',
    TOGGLE_FIELD: 'toggle',
    FILE_FIELD: 'file',
    NUMERIC_FIELD: 'number',
    CHECKBOX_FIELD: 'checkbox',
    MULTI_FILE_FIELD: 'multifile',
    TEXT_AREA_FIELD: 'textArea',
    BPA_SELECT_FIELD: 'bpa_select',
    TIME_FIELD: 'time',
    NEW_FILE_FIELD: 'newfile',
    PASSWORD: 'password'
};
export const CONSTANT_REGEX_SAMPLE = {
    ALPHANUMERIC: /^[a-zA-Z0-9]*$/,
    NUMERIC: /^[0-9]*$/,
    ALPHABATE: /^[A-Za-z]+$/,
    ALPHANUMERIC_SPECIAL: /^[a-zA-Z0-9\s,'.-]+$/,
    EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    ALPHABATEWITHSPACE: /^([a-zA-Z]+(?:\.)?\s)*[a-zA-Z]+(?:\.)?$/,
    GSTNO: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    PAN: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
    NIC: /^\d{5}(,\d{5})*$|^$/,
    ALT_MOBILE_NUMBER: /^(null|""|[0-9]{10})$/,
    IFSC: /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/,
    PHONE: /^[0-9]{10}$/
};

export const APPLICATION_TYPE = 'NEW';

export const COMPLETION_ORDER = 'COMPLETION_ORDER';

export const PERMANENT = 'PERMANENT';

export const CORRESPONDENCE = 'CORRESPONDENCE';

export const ENTERPRISE = 'ENTERPRISE';

export const ENTITY = 'ENTITY';

export const AUTHUSER = 'AUTHUSER';

export const OTHER = 'OTHER';

export const EXTENSION = 'EXTENSION';

export const ALLOTMENT = 'ALLOTMENT';

export const TRANSFER = 'TRANSFER';

export const CGST_SGST = 0.09;

export const LAND_PREMIUM_INTEREST = 0.11;

export const LAND_PREMIUM = 'LAND_PREMIUM';

export const LEASE_RENT = 'LEASE_RENT';

export const HOUSE_TAX = 'HOUSE_TAX';

export const WATER_CHARGES = 'WATER_CHARGES';

export const SUBLEASE_FEE = 'SUBLEASE_FEE';

export const ALLOTTEE = 'ALLOTTEE';

export const LEASE_DEED = 'LEASE_DEED';

export const LEASER_RENT_RATE = 0.02;

export const SPL_TRANSFER = 'SPL_TRANSFER';

export const DOWNPAYMENT = 0.2;

export const TRIPARTITE_DEED = 'TRIPARTITE_DEED';

export const ADDENDUM_OF_CHANGE = 'ADDENDUM_OF_CHANGE';

export const DEED_OF_SURRENDER = 'DEED_OF_SURRENDER';

export const APPLY_INDENTURE = 'APPLY_INDENTURE';

export const APPLY_TRANSFER = 'APPLY_TRANSFER';

export const APPLY_SPECIAL_TRANSFER = 'APPLY_SPECIAL_TRANSFER';

export const APPLY_SUBLEASE = 'APPLY_SUBLEASE';

export const DEED_OF_EXTENSION = 'DEED_OF_EXTENSION';

export const ADDENDUM_FOR_CHANGE_IN_OWNERSHIP = 'ADDENDUM_FOR_CHANGE_IN_OWNERSHIP';

export const AUTHORIZED_PERSON_TITLE = 'The name should be as per aadhar card';

export const DATA_MIGRATION_API = 'DATA_MIGRATION_API';

export const ILLEGAL_SUB_LEASE_RATE = 8;

export const SUB_LEASE_RATE = 0.08;

export const WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY = 'walletRequestMessageForPayment';

export const EMPLOYEE_AUTHORIZED = 'Unauthorized access';
