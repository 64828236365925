import { FormikProvider, useFormik } from 'formik';
import HeaderComponent from '../../../../../components/header/Header.component';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { Card, Form } from 'react-bootstrap';
import GenericBackButton from '../../../../../components/UI components/Buttons/GenericBackButton';
import NotingComponent from '../../../../../components/Workflow/NotingComponent';
import { useEffect, useState } from 'react';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { POST, endpoints, estateEndpoints, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import { dropdownDataOptions, getFormatedOwnershipDetails } from '../../../Migration/Migration.controller';
import { SPVCorrespondence } from './SPVCorrespondance';
import ScrollToTop from '../../../../../components/ScrollToTop';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../../components/fileviewer/GenericImageViewer';
import { EMPLOYEE_AUTHORIZED, TENANT_ID } from '../../../../../utils/constants';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import { useTranslation } from 'react-i18next';
import NotingDocDownload from '../../../../../components/Workflow/NotingDocDownload';
import CorrespondenceDocDownload from '../../../../../components/Workflow/CorrespondenceDocDownload';
import { check, cross } from '../../../../../utils/Logo';
import { getAllotmentWorkflowInitialData, getEntityData } from '../../Allotment/Allotment.controller';
import { getCheckList, getHistory, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../../EstateManagement.controller';
import { SPV_FORM_VALIDATION } from '../../../../../utils/formikValidation/FormikValidation';
import Loading from '../../../../../components/portalComponents/Loading.component';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../LeaseDeed/LeaseDeed.const';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';

export default function SPVWorkflow() {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [comment, setcomment] = useState('');
    const [activeIndex, setActiveIndex] = useState(1);
    const [searchParams] = useSearchParams();
    const spvNo: any = searchParams.get('applicationNumber') ? searchParams.get('applicationNumber') : '';
    const totalPages = spvNo ? 3 : 1;
    const [content, setContent] = useState('');
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [dropdownData, setDropdownData] = useState<any>([]);
    const [advertisementid, setAdvertisementid] = useState<string | undefined>(undefined);
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [documents, setDocuments] = useState<any>([]);
    const [isChecked, setIsChecked] = useState([true, true, true]);
    const [tabelData, setTabelData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    let navigate = useNavigate();
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [searchApplicationResponse, setSearchApplicationResponse] = useState<any>({});
    const [estateApplicationsData, setEstateApplicationsData] = useState<any>(null);

    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [applicationNo, setApplicationNo] = useState<string>('');
    const [applicationType, setApplicationType] = useState<string>('');
    const [checklistTableData, setChecklistTableData] = useState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);

    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';

    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;

    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');

    const { t } = useTranslation();
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: false,
            noting: {},
            text: ''
        },
        onSubmit: () => {}
    });
    const { values, setValues } = formik;
    const DOC_TABLE_COLUMN = [
        {
            colName: 'SN',
            fieldName: 'serialNo',
            clsName: 'col-sm-1'
        },
        {
            colName: 'Uploaded By',
            fieldName: 'uploadedUserRole',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Date & Time',
            fieldName: 'uploadedDate',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Document',
            fieldName: 'docFileName',
            clsName: 'col-sm-3',

            isClickable: true
        }
    ];

    useEffect(() => {
        dropdownDataOptions()
            .then(dropdownOption => {
                setDropdownData(dropdownOption);
                // setApplicantData(dropdownOption);
            })
            .catch(error => {});
    }, []);

    const fetchData = async () => {
        if (spvNo) {
            try {
                const { data } = await POST(`${endpoints.workflowSearch}&businessIds=${spvNo}`, { RequestInfo: requestInfo() });
                setHistoryDetails(data.ProcessInstances);

                console.log(data);
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    useEffect(() => {
        userType === 'EMPLOYEE' && fetchData();

        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }
    }, []);

    const { setFieldValue, errors, setFieldTouched } = formik;

    useEffect(() => {
        if (userType === 'EMPLOYEE') {
            (async () => {
                try {
                    if (spvNo) {
                        const nextAction = await POST(endpoints.nextValidAction + `?businessId=${spvNo}&tenantId=${TENANT_ID}`, { RequestInfo: requestInfo() });
                        setNextValidAction(nextAction.data);
                        // setFormIsUpdatable(nextAction.data.isUpdatable);g
                        setFieldValue('isUpdatable', isViewOnly ? !isViewOnly : nextAction.data.isUpdatable);
                    }
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [spvNo, dropdownData]);

    useEffect(() => {
        const getChecklistTableData = async () => {
            try {
                const checklistData = await getCheckList(
                    estateApplicationsData?.enterpriseEntity.uuid,
                    estateApplicationsData?.assignedPlotDetails?.uuid || estateApplicationsData?.appliedPlotDetails?.uuid,
                    nextValidAction?.businessService,
                    spvNo
                );
                setChecklistTableData(checklistData);
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };
        estateApplicationsData && userType === 'EMPLOYEE' && getChecklistTableData();
    }, [nextValidAction, estateApplicationsData]);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any = file.referenceId && (await onAttachDocument(spvNo, file?.referenceId, designationName, nextValidAction.businessService, historyDetails[0].id));
            setDocumentDetails(docs.documents);
            setDocumentsUuid([...documentsUuid, docs.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    useEffect(() => {
        if (activeIndex === 2 && documentDetails) {
            const tableData = async () => {
                const responseTableData = documentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${documentDetails.length - ind}` };
                });
                setTabelData(responseTableData);
            };
            if (activeIndex === 2 && userType === 'EMPLOYEE') {
                tableData();
            }
        }
    }, [documentDetails, activeIndex]);

    const handleNextPage = async () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
        if (activeIndex === 1) {
            try {
                const docs = await getWorkflowDocumentSearch(spvNo, nextValidAction?.businessService);
                setDocumentDetails(docs);
                const relatedApplicationDocument = await POST(
                    `/egov-common-masters/related-application/_search?entityIds=${estateApplicationsData?.entityRefDetails.entityId}&plotIds=${estateApplicationsData?.appliedPlotDetails?.uuid}&applicationNos=${spvNo}`,
                    { RequestInfo: requestInfo() }
                );
                setRelatedApplicationDocument(relatedApplicationDocument);
            } catch (err: any) {
                let errMsg = err?.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const onCancel = () => {
        navigate(-1);
    };

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    useEffect(() => {
        const ApplicantDetails = async () => {
            if (spvNo && dropdownData) {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.searchAllotment + `?applicationNos=${spvNo}`, { RequestInfo: requestInfo() });
                    const estateData = data.estateApplications[0];
                    setEstateApplicationsData(estateData);
                    console.log('estate', estateApplicationsData);
                    const historyData = await getHistory(spvNo);
                    setHistoryDetails(historyData);
                    const SpvInitData = await getAllotmentWorkflowInitialData(estateData, dropdownData);

                    setValues((prev: any) => ({
                        ...prev,
                        ...SpvInitData,
                        applicationSubType: estateData.applicationSubType,
                        highestBidRate: estateData.plotRate
                    }));

                    setFileStoreId(estateData?.consolidatedDocRefId);

                    const ownershipTableDetails = await getFormatedOwnershipDetails(estateData?.enterpriseEntity?.owners, dropdownData);
                    setOwnershipDetails(ownershipTableDetails);
                    setControllingOwnershipResponse(estateData?.enterpriseEntity?.owners);

                    setApplicationNo(estateData.applicationNo);
                    setApplicationType(estateData.applicationType);

                    if ((designation === 'GM' && estateData?.status === 'PENDING') || (designation === 'AM' && estateData?.status === 'SCRUTINY_DONE')) {
                        setDisabled(false);
                    }
                    setIsLoading(false);
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    setIsLoading(false);
                }
            }
        };
        userType === 'EMPLOYEE' && ApplicantDetails();
    }, [dropdownData]);

    useEffect(() => {
        if (values?.category?.code) {
            if (values?.category?.code === 'PROP_FIRM') {
                if (ownershipDetails?.length) {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: ownershipDetails[0]?.name }));
                } else {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: '' }));
                }
            } else {
                setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.applicantName }));
            }
        }
    }, [values?.category?.code, ownershipDetails, values?.applicantName]);

    const onApply = async (status: any, comment: string) => {
        const payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: spvNo,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes
                }
            ],
            workFlowDocumentIds: documentsUuid.filter(Boolean),
            estateApplicationRequest: {
                ...estateApplicationsData,
                appliedPlotId: estateApplicationsData?.appliedPlotDetails?.uuid,
                assignedPlotId: estateApplicationsData?.assignedPlotDetails?.uuid || values.allotedPlotId,
                plotRate: values.highestBidRate,
                content: content
            }
        };

        // if ((!comment && !status.isEmployeeAssignee) || errors.emails) {
        //     setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS','Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        //     return;
        // }

        if (!values.notes) {
            if (!comment) {
                setModalDetails({ show: true, title: 'Error!', para: t('ENTER_NOTES', 'Please enter notes!'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            } else {
            }
        }

        try {
            const res = await POST(endpoints.estateWorkflowInitiate, payload);
            const successMessage = getSuccessMessage(status?.action, spvNo);
            setModalDetails({ show: true, title: 'Success!', para: successMessage, img: check, type: 'success', reUpload: 'OK' });
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ title: 'Error!', show: true, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log('formik', formik);

    const handleModalClose = (type: string, isUnauthorized: boolean) => {
        setModalDetails({});
        if (type === 'success') {
            navigate('/goa-idc/dashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            }
        }
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.isUnauthorized)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isUnauthorized)}
                    />
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                        <GenericHeader text={t('SPV', 'SPV')} additionalClass='sub-text-style' bottomSubText={` Type : SPV, No : ${spvNo}`} />
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader text={'Application for Allotment'} sideHeader={true} additionalClass='sub-text-style' bottomSubText={`Type : SPV, No : ${spvNo}`} />
                                    {/* <div className='d-flex justify-content-between align-items-center'>
                                        <p className='noting-heading my-1'>Noting</p>
                                        <NotingDocDownload applicationNumber={spvNo} />
                                    </div>
                                    <NotingComponent history={historyDetails} text={comment} handleOnFileUpload={handleOnFileUpload} /> */}
                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={spvNo}
                                        history={historyDetails}
                                        text={comment}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                        disable={isViewOnly ? isViewOnly : !nextValidAction?.nextValidAction.length}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                {/* <div className='d-flex justify-content-between align-items-center'>
                                    <p className='correspondence-heading my-1'>Correspondence</p>
                                    <CorrespondenceDocDownload applicationNumber={spvNo} />
                                </div> */}
                                <CorrespondenceWrapper applicationNumber={spvNo} designation={designation} nextValidAction={nextValidAction} />

                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <SPVCorrespondence
                                                dropdownData={dropdownData}
                                                ownershipDetails={ownershipDetails}
                                                setOwnershipDetails={setOwnershipDetails}
                                                isChecked={isChecked}
                                                handleCheckboxChange={handleCheckboxChange}
                                                controllingOwnership={controllingOwnershipResponse}
                                                checkListRowData={checklistTableData}
                                                applicationNumber={spvNo}
                                                designation={designation}
                                                fileStoreId={fileStoreId}
                                            />
                                        )}
                                        {activeIndex === 2 && (
                                            <div className='p-4'>
                                                <GenericActionTable columns={DOC_TABLE_COLUMN} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                            </div>
                                        )}
                                        {activeIndex === 3 && (
                                            <div className='p-4'>
                                                <GenericActionTable
                                                    columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                    hideSearchBar={true}
                                                    rowData={relatedApplicationDocument?.data.applicationInfos}
                                                    additionalMethod={onRowClickRedirect}
                                                />
                                            </div>
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>
                        </FormikProvider>
                    </div>
                    <ScrollToTop />
                    {!isViewOnly ? (
                        <GenericFooterComponent
                            onCancel={() => {}}
                            activeIndex={activeIndex}
                            handleOnPrimaryAction={onApply}
                            onSubmit={() => {}}
                            applicationNo={spvNo || null}
                            nextValidAction={nextValidAction}
                            stepperLength={0}
                        />
                    ) : (
                        <></>
                    )}

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                </>
            )}
        </>
    );
}
