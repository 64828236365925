import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { AUTHORIZED_PERSON, BANK_DETAILS, ENTERPRISE_DETAILS_I, ENTERPRISE_DETAILS_II, ENTERPRISE_STEPS_DETAILS, entityDocuments } from '../../UserManagement/UserManagement.const';
import '../UserManagement.scss';
import { toast } from 'react-toastify';
import { POST, devUrl, endpoints, fetchDropdownData, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { ENTERPRISE_REGISTRATION_VALIDATION, OWNERSHIP_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { WelcomePopUp } from './OwnershipDetails';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import ScrollToTop from '../../../../components/ScrollToTop';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { getEnterpriseInitData, getEnterpriseInitialData, handleUpdates } from '../UserMangement.controller';
import { check, cross, warning } from '../../../../utils/Logo';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { IOwnershipDetails, IdocumentTemplate } from '../types';
import { useTranslation } from 'react-i18next';

import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { USER_TYPE } from '../../../../utils/constants';
import GenericBackButton from '../../../../components/UI components/Buttons/GenericBackButton';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import DownloadTemplate from '../../../../components/DownloadTemplate';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import GenericOwnerShipDetails from '../../../../components/Ownership/GenericOwnership';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import { getFormatedOwnershipDetails, setEntityDocumentDetails } from '../../Migration/Migration.controller';
import { TemplateCard } from '../../EstateManagement/TemplatesCard';
import { getFirstErrorKey } from '../../../../utils/utils';

export default function EnterpriseUserRegistration() {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState<number>(1);
    // const [documents, setDocuments] = useState<any>([]);
    const [dropdownData, setDropdownData] = useState<any>([]);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const navigate = useNavigate();
    const [modalDetails, setModalDetails] = useState<any>({});
    const [enterpriseUuid, setEnterpriseUuid] = useState<number | string>('');
    const [userUUID, setUserUUID] = useState<number | string>('');
    const [enterprisePayload, setEnterprisePayload] = useState<any>({});
    const [showValidUserPopup, setShowValidUserPopup] = useState(false);
    const [existingUserData, setExistingUserData] = useState<any>(null);
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [showCalculatedTable, setShowCalculatedTable] = useState(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [formIsUpdatable, setFormIsUpdatable] = useState(true);
    const [showDownloadBox, setShowDownloadBox] = useState(false);
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [time, setTime] = useState<any>(0);
    const [DocumentId, setDocumentId] = useState([]);
    const [documentTemplate, setDocumentTemplate] = useState<any>([]);
    const [filesSelection, setFilesSelection] = useState<any>([]);
    const [templateLinks, setTemplateLinks] = useState([]);
    const doc = [
        { title: 'GST Undertaking', name: 'GST' },
        { title: 'Women Entrepreneurship Declaration', name: 'womenEntrepreneurship' }
    ];
    const [registrationNumber, setRegistrationNumber] = useState<any>(null);
    const [ifscData, setIfscData] = useState<any>(null);
    const [enterprisedetailsII, setEnterpriseDetailsII] = useState<any>(ENTERPRISE_DETAILS_II);

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const applicationNo = searchParams.get('applicationNumber');
    const otpReferenceId = searchParams.get('otpReferenceId');

    const availabilityCheck = JSON.parse(localStorage.getItem('availabilityCheck') || '{}');
    const userType = JSON.parse(localStorage.getItem('type') || 'null');
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    let serachEnterpriseUrl = userType === USER_TYPE.EMP ? endpoints.searchExistingUser : endpoints.searchExistingUserNoLogin;
    let nextValidActionEnterpriseUrl = (userType === USER_TYPE.EMP ? endpoints.nextValidAction : endpoints.nextValidActionNoLogin) + `?businessId=${applicationNo}&tenantId=ga`;
    const [enterpriseUser, setEnterpriseUser] = useState<any>(null);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (applicationNo) {
        serachEnterpriseUrl = serachEnterpriseUrl + `?registrationNumber=${applicationNo}`;
    }

    if (otpReferenceId) {
        serachEnterpriseUrl += `&otpReferenceId=${otpReferenceId}`;
    }

    useEffect(() => {
        (async () => {
            try {
                if (applicationNo && dropdownData) {
                    const nextAction = await POST(nextValidActionEnterpriseUrl, { RequestInfo: requestInfo() });
                    setNextValidAction(nextAction.data);
                    setFormIsUpdatable(nextAction.data.isUpdatable);

                    const { data } = await POST(serachEnterpriseUrl, { RequestInfo: requestInfo() });
                    getExistingEnterpriseData(data);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, [applicationNo, dropdownData]);

    // useEffect(() => {
    //     (async () => {
    //         if (dropdownData && existingUserData) {
    //             try {
    //                 const initData = await getEnterpriseInitialData(existingUserData?.enterpriseEntity[0], dropdownData);
    //                 // try {
    //                 //   await getDoumentsData(existingUserData);
    //                 // } catch (e) {
    //                 //   console.log(e);
    //                 // }

    //                 setValues((prev: any) => ({
    //                     ...prev,
    //                     ...initData,
    //                     isUpdatable: formIsUpdatable,
    //                     underProcess: true
    //                 }));
    //             } catch (e) {
    //                 console.log(e);
    //             }
    //         }
    //     })();
    // }, [existingUserData, dropdownData, formIsUpdatable, applicationNo]);

    const getExistingEnterpriseData = async (resData: any) => {
        const initData = await getEnterpriseInitData(resData, dropdownData);
        setValues({ ...values, ...initData, isUpdatable: formIsUpdatable, underProcess: true });
        setExistingUserData(resData);
        setEnterprisePayload(resData.enterpriseEntity);
        setEnterpriseUuid(resData.enterpriseEntity[0].uuid);
        setFieldValue('userUUID', resData?.enterpriseEntity[0].user?.uuid);
        setUserUUID(resData?.enterpriseEntity[0].user?.uuid);
        await ownershipResponse(resData);
        // setShowCalculatedTable(true);
    };

    // const getDoumentsData = async (res: any) => {
    //   let docs = res.enterpriseEntity[0].documents;
    //   if (Array.isArray(docs) && docs.length) {
    //     const previewData: any = await Promise.all(
    //       docs?.map(async (item: any) => {
    //         let documentUrl = await getFileUrl(item.referenceId);
    //         // let documentUrl = "https://" + documentUrls.split("https://").filter(Boolean)[0].slice(0, -1);
    //         return { ...item, documentUrl };
    //       })
    //     );
    //     setDocuments(previewData);
    //     console.log("previewdata", previewData);
    //   }
    // };

    useEffect(() => {
        const dropdownPayload = {
            RequestInfo: {},
            MdmsCriteria: {
                tenantId: 'ga',
                moduleDetails: [
                    {
                        moduleName: 'common-masters',
                        masterDetails: [
                            {
                                name: 'GenderType',
                                filter: '[?(@.active == true)]'
                            },
                            {
                                name: 'EntityCategory',
                                filter: '[?(@.active == true)]'
                            }
                        ]
                    }
                ]
            }
        };
        fetchDropdownData(dropdownPayload)
            .then((res: any) => {
                console.log('response>>', res);
                const dropdownOptions = {
                    gender: res['common-masters']['GenderType'],
                    entitycategory: res['common-masters']['EntityCategory'],
                    ownershipGender: res['common-masters']['GenderType'],
                    // womenEntrepreneur: [
                    //     { name: 'Yes', code: 'true' },
                    //     { name: 'No', code: 'false' }
                    // ],
                    ownershipHighestQualification: [
                        { name: 'Post Graduate', code: 'POST_GRADUATE' },
                        { name: 'Graduate or Diploma', code: 'GRADUATE_OR_DIPLOMA' }
                    ]
                };
                setDropdownData(dropdownOptions);
            })
            .catch((err: any) => console.log(err));
    }, []);

    console.log('drop', dropdownData);

    useEffect(() => {
        let isLocalPerson = false;
        controllingOwnershipResponse?.map((item: any, _: number) => {
            if (item.controllingOwnership === true && item.ownerDocuments?.find((val: any) => val.documentType === DOCUMENT_TYPE.LOCALPERSON)) {
                isLocalPerson = true;
            }
        });
        setValues({ ...values, localPerson: isLocalPerson });
    }, [controllingOwnershipResponse]);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            ownership: [],
            womenEntrepreneur: false,
            previousApplicationCheck: true,
            individualOrOrganization: 'Individual',
            copyOnCheck: []
        },
        onSubmit: value => {},
        validationSchema: !formIsUpdatable ? null : !showOwnershipModal ? ENTERPRISE_REGISTRATION_VALIDATION[`page${activeIndex}`] : OWNERSHIP_VALIDATION
    });

    const { values, setValues, setFieldValue, errors, setFieldTouched } = formik;

    useEffect(() => {
        if ((errors.hasOwnProperty('entityemailId') || errors.hasOwnProperty('entityPhoneNo')) && !availabilityCheck?.isAvailable) {
            if (availabilityCheck?.applicationStatus === 'PENDING') {
                setShowValidUserPopup(true);
            } else if (availabilityCheck?.applicationStatus === 'APPROVED') {
                let errMsg = t('ALREADY_REGISTERED', 'Credentials have already been registered.');
                setModalDetails({ show: true, title: 'Error', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    }, [availabilityCheck.isAvailable]);

    const handleOnChange = (e: any, field: string) => {
        if (field === 'gstapplicable') {
            setValues({ ...values, entityGstNo: '' });
        }
    };

    useEffect(() => {
        const updatedDetails = handleUpdates(values, enterprisedetailsII, ENTERPRISE_DETAILS_II, 'Address Details', 'Mailing/Correspondence address of the Enterprise');
        setEnterpriseDetailsII(updatedDetails);
    }, [values?.isPermanentPincodeDetailsPresent, values?.isPincodeDetailsPresent, values?.copyOnCheck]);

    const renderProfileDetails = () => {
        return (
            <>
                <Card className='card_border'>
                    <Card.Body>
                        {ENTERPRISE_DETAILS_I.map((val: any, id: number) => (
                            <GenericFormCard module='usermanagement' {...val} dropdownOptions={dropdownData} additionalData={'entitySection'} />
                        ))}
                        {enterprisedetailsII.map((val: any, id: number) => (
                            <GenericFormCard module='usermanagement' {...val} dropdownOptions={dropdownData} additionalData={'entitySection'} onChangeAction={handleOnChange} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const RequestInfo = { RequestInfo: requestInfo() };
                const response = await POST('/egov-common-masters/idc/document/_search?documentCategories=ENTERPISE_REGISTRATION', RequestInfo);

                if (response && response.data && response.data.documentDetails) {
                    setTemplateLinks(response.data.documentDetails.map((detail: any) => detail.documentName));
                    setDocumentId(response.data.documentDetails.map((detail: any) => detail.documentReferenceId));
                } else {
                    console.error('Response format is incorrect:', response);
                }
            } catch (err: any) {
                let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message || t('SOMETHING_WENT_WRONG', 'Something went wrong');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        fetchData();
    }, []);

    const handleOnDownloadTemplate1 = async () => {
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${DocumentId}`;
        window.open(downloadFileUrl);
    };

    const renderAuthorizedPerson = () => {
        return (
            <>
                {AUTHORIZED_PERSON.map((val: any) => (
                    <Card className='card_border mb-4'>
                        <Card.Body>
                            <GenericFormCard module='usermanagement' {...val} dropdownOptions={dropdownData} />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const tooltipDetails: any = {
        ownershipDetails: {
            position: 'right',
            content: `<div class="d-flex flex-column font-inter" style="gap:5px;"><div class="mb-2">"Ownership Interest" means:</div> <div>(i)	holding of voting rights in a body corporate as defined under the Companies Act, 2013 (Act No. 18 of 2013); or</div>
            <div>(ii)	ownership of or entitlement to the capital or profits in a limited liability partnership, partnership firm, society or trust;</div></div>
            `
        },
        promoterDetails: {
            position: 'right',
            content: `<div class="d-flex flex-column font-inter" style="gap:5px;"><div class="mb-2">"Promoter" means:</div> <div>(i)	with respect to a body corporate, Person(s) holding, individually or collectively, Controlling Ownership Interest; or</div>
            <div>(ii)	with respect to a limited liability partnership or partnership firm, partner(s) collectively holding Controlling Ownership Interest; or</div>
            <div>(iii)	with respect to any other legal entity recognized under law, the constituent Person(s) collectively holding Controlling Ownership Interest;</div></div>
            `
        }
    };

    const renderOwnershipDetails = () => {
        return (
            <>
                <GenericOwnerShipDetails
                    showOwnershipModal={showOwnershipModal}
                    setShowOwnershipModal={setShowOwnershipModal}
                    values={values}
                    dropdownData={dropdownData}
                    setControllingOwnershipResponse={setControllingOwnershipResponse}
                    controllingOwnershipResponse={controllingOwnershipResponse}
                    setOwnershipDetails={setOwnershipDetails}
                    ownershipDetails={ownershipDetails}
                    toolTipDetails={tooltipDetails}
                    skipFullOwnershipPercent={values?.entitycategory?.code === 'LTD_COMPANY'}
                />
            </>
        );
    };

    useEffect(() => {
        if (values?.entitycategory?.code) {
            if (values?.entitycategory?.code === 'PROP_FIRM') {
                if (values?.ownership?.length) {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.ownership[0]?.ownershipName }));
                } else {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: '' }));
                }
            } else {
                setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.entityname }));
            }
        }
    }, [values?.entitycategory?.code, values?.ownership, values?.entityname]);

    const bankDetails = () => {
        const handleSearchIFSC = (e: any) => {
            if (e.length === 11) {
                if (time) clearTimeout(time);
                setTime(
                    setTimeout(async () => {
                        try {
                            const { data } = await POST(`${endpoints.getBankDetails}?ifsc=${e}`, { RequestInfo: requestInfo() });
                            setIfscData(data);
                            console.log(data);
                            setValues({
                                ...values,
                                bankName: data?.bank || '',
                                branchAddress: data?.address || '',
                                ifscCode: e
                            });
                        } catch (error: any) {
                            let errMsg = error.response.data?.errorMessage || error.response.data?.Errors?.[0]?.message;
                            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                            setValues({
                                ...values,
                                bankName: '',
                                branchAddress: '',
                                ifscCode: e
                            });
                        }
                    }, 800)
                );
            } else {
                setValues({
                    ...values,
                    bankName: '',
                    branchAddress: '',
                    ifscCode: e
                });
            }
        };
        const bankDetails =
            ifscData !== null && ifscData?.address === '' && ifscData?.bank === ''
                ? BANK_DETAILS.map((val: any) => ({
                      ...val,
                      formData: [
                          ...val.formData.map((value: any, index: number) => {
                              if (index === 3 || index === 4) {
                                  return { ...value, isDisabled: false };
                              }
                              return value;
                          })
                      ]
                  }))
                : BANK_DETAILS;
        return (
            <>
                {bankDetails.map((val, index) => (
                    <Card className='card_border'>
                        <Card.Body>
                            <GenericFormCard
                                module='usermanagement'
                                {...val}
                                onChangeAction={(e, field) => {
                                    if (field === 'ifscCode') {
                                        const newValue = e.target.value.toUpperCase();
                                        setFieldValue(val.formData[2].field, newValue);
                                        val.formData[2].field === 'ifscCode' && handleSearchIFSC(newValue);
                                    }
                                }}
                            />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const ownershipResponse = async (res: any) => {
        const ownershipValues = await getFormatedOwnershipDetails(res.enterpriseEntity[0].owners, dropdownData);
        setOwnershipDetails(ownershipValues);
        setControllingOwnershipResponse(res.enterpriseEntity[0].owners);
        setFieldValue('ownership', ownershipValues);
    };

    const onSubmit = async (changeIndex?: boolean) => {
        let docForCategories: any = {
            PROP_FIRM: ['entityProprietorshipFirm', 'entityProprietorIdentity'],
            TRUST: ['trustDeed'],
            PARTNERSHIP: ['partnershipDeed', 'certificateOfRegistrationFirms'],
            LIMITED_LIABILITY_PARTNERSHIP: ['certificateOfIncorporation'],
            LTD_COMPANY: ['certificateOfIncorporation'],
            COOP_SOCIETY: ['certificateOfRegistrationSocieties'],
            GOV_STAT_CORP: ['copyRelevantNotification']
        };
        let docs: any = [];
        docForCategories[values?.entitycategory?.code]?.forEach((ele: any) => {
            docs.push(setEntityDocumentDetails(values?.[ele]) || null);
        });

        docs = [
            ...docs,
            setEntityDocumentDetails(values?.entitygstDocument) || null,
            setEntityDocumentDetails(values?.entitypanCard) || null,
            setEntityDocumentDetails(values?.authorizedPersonPanCardDocument) || null,
            setEntityDocumentDetails(values?.authorizedPersonPowerOfAttorneyDocument) || null,
            setEntityDocumentDetails(values?.entitygstUndertaking) || null
            // setEntityDocumentDetails(values?.RegCertificate) || null,
            // setEntityDocumentDetails(values?.DPIITCertificate) || null,
            // setEntityDocumentDetails(values?.residence) || null,
            // setEntityDocumentDetails(values?.womenEntreprenuershipDocument) || null,
            // setEntityDocumentDetails(values?.experienceCertificate) || null,
            // setEntityDocumentDetails(values?.educationCertificate) || null,
            // setEntityDocumentDetails(values?.letter) || null
        ].filter(Boolean);

        const isFinal = activeIndex === ENTERPRISE_STEPS_DETAILS.length && !applicationNo;
        const verificationFields = [['entityPhoneNoOtpReferenceId', 'entityemailIdOtpReferenceId']];

        if (!values.underProcess) {
            if (activeIndex < 2 && !verificationFields[activeIndex - 1].some(item => Object.keys(values).includes(item))) {
                let errMsg = t('VERIFICATION_NEEDED', 'Verfication is needed!');
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
        }

        const currOwnership = ownershipDetails
            .filter((item: any) => !item.isDelete)
            .reduce((accumulator: number, subItem: any) => {
                return accumulator + subItem.ownershipInterest;
            }, 0);

        if (activeIndex === 3 && currOwnership != 100 && values?.entitycategory?.code !== 'LTD_COMPANY') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('OWNERSHIP_INTEREST_ADD', 'Your ownership interest does not add upto 100%'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            return;
        }

        if (activeIndex === 3 && !values.ownership?.length) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('CAL_CONTOLLING_OWNERSHIP', 'Please calculate Controlling Ownership'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        const additionalDocs = [
            values.womenEntrepreneurDocuments?.documentName
                ? {
                      documentName: values.womenEntrepreneurDocuments?.documentName,
                      documentType: 'womenentreprenueur',
                      referenceId: values.womenEntrepreneurDocuments?.referenceId
                  }
                : null
        ].filter(Boolean);

        let payload = {
            otpReferenceId: values.entityemailIdOtpReferenceId || otpReferenceId || undefined,
            enterpriseEntity: {
                id: null,
                tenantId: 'ga',
                userUuid: null,
                entityName: values.entityname || null,
                category: values.entitycategory?.code || null,
                email: values.entityemailId || null,
                phoneNumber: values.entityPhoneNo || null,
                panNumber: values.entitypanNo || null,
                gstApplicable: values.gstapplicable ? JSON.parse(values.gstapplicable) : null,
                gstNumber: values.gstapplicable === true ? values.entityGstNo : null,
                udhyamRegistered: values.UdhyamRegisteredEnity ? JSON.parse(values.UdhyamRegisteredEnity) : null,
                womenEntreprenueur: null,
                localPerson: null,
                dpiitCertified: null,
                password: null,
                isActive: null,
                addresses: [
                    {
                        addressType: 'PERMANENT',
                        address1: values.peraddress1,
                        address2: values.peraddress2,
                        pinCode: values.perpincode,
                        district: values.perdistrict,
                        city: '',
                        state: values.perstate,
                        country: values.percountry
                    },
                    {
                        addressType: 'CORRESPONDENCE',
                        address1: values.address1,
                        address2: values.address2,
                        pinCode: values.pincode,
                        district: values.district,
                        city: '',
                        state: values.state,
                        country: values.country
                    }
                ],
                documents: docs,
                owners: ownershipDetails.length ? ownershipDetails : null,
                miscDetails:
                    activeIndex >= 3
                        ? {
                              isUpdate: true,
                              isLocalPerson: values.localPerson ? JSON.parse(values.localPerson) : null,
                              isWomenEntreprenueur: values?.womenEntrepreneur,
                              documents: additionalDocs
                          }
                        : null,
                entityBankDetail:
                    activeIndex === 4
                        ? {
                              accountNumber: values?.accountNo || '',
                              ifscCode: values.ifscCode || '',
                              bankName: values.bankName || '',
                              branchAddress: values.branchAddress || '',
                              isUpdate: true
                          }
                        : null,
                authUsers:
                    activeIndex >= 2
                        ? [
                              {
                                  uuid: values.userUUID || userUUID || null,
                                  username: null,
                                  active: true,
                                  alternateMobileNo: values.authorizedPersonAlternativeMobileNumber || '',
                                  email: values.authorizedPersonEmailId || '',
                                  emailOtpReferenceId: values.authorizedPersonEmailIdOtpReferenceId,
                                  firstName: values.authorizedPersonFirstname || null,
                                  gender: values?.gender?.code || null,
                                  lastName: values.authorizedPersonLastname || null,
                                  primaryMobileNo: values.authorizedPersonPrimaryMobileNumber || null,
                                  mobileOtpReferenceID: values.authorizedPersonPrimaryMobileNumberOtpReferenceId || null,
                                  mobileValidationMandatory: false,
                                  otpValidationMandatory: false,
                                  anAttachmentReferenceId: values.authorizedPersonPanCardDocument?.referenceId,
                                  powerOfAttorneyReferenceId: values.authorizedPersonPowerOfAttorneyDocument?.referenceId,
                                  salutation: values.authorizedPersonFirstnamesalutation || null,
                                  tenantId: 'ga',
                                  type: 'ENTERPRISE',
                                  registrationNumber: registrationNumber,
                                  isUpdate: userUUID ? true : false,
                                  documents: docs.filter((item: any) => item.referenceType === 'AUTHUSER')
                              }
                          ]
                        : existingUserData?.enterpriseEntity[0].authUsers
                        ? existingUserData?.enterpriseEntity[0].authUsers
                        : null,
                // registrationNumber: entityId || undefined,
                uuid: enterpriseUuid,
                registrationStatus: null,
                isUpdate: enterpriseUuid && activeIndex === 1 ? true : false,
                createdBy: null,
                createdDate: null,
                lastModifiedBy: null,
                lastModifiedDate: null,
                isFinalSubmit: isFinal,
                user: enterpriseUser && enterpriseUser
            }
        };

        setEnterprisePayload([payload.enterpriseEntity]);

        try {
            if (activeIndex <= ENTERPRISE_STEPS_DETAILS.length && formIsUpdatable) {
                await ENTERPRISE_REGISTRATION_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false });
            }
            if (!enterpriseUuid) {
                try {
                    const res = await POST(endpoints.createEntity, payload);
                    console.log(res.data);
                    !isFinal && setEnterpriseUuid(res?.data?.enterpriseEntity[0].uuid);
                    !isFinal && setEnterpriseUser(res?.data?.enterpriseEntity[0].user);

                    if (!isFinal) {
                        toast(t('ACTION_SUCCESSFUL', 'Action successful'), { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
                    } else {
                        let msg = t('ENTERPRISE_CREATED', 'Enterprise created successfully');
                        setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'success', reUpload: 'OK' });
                    }
                    changeIndex && onChangeNextIndex();
                } catch (e: any) {
                    let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            } else {
                try {
                    const res = await POST(endpoints.updateEntity, payload);
                    if (!isFinal) {
                        setEnterpriseUuid(res?.data?.enterpriseEntity[0].uuid);
                        setFieldValue('userUUID', res?.data?.enterpriseEntity[0]?.authUsers?.[0]?.uuid);
                        setRegistrationNumber(res?.data?.enterpriseEntity[0]?.authUsers?.[0]?.registrationNumber);
                        res?.data?.enterpriseEntity[0].owners && (await ownershipResponse(res?.data));
                        changeIndex && onChangeNextIndex();
                        if (activeIndex === 1) {
                            toast(t('REGISTRATION_CREATED', 'Registration created successfully'), { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
                        } else {
                            toast(t('DATA_SAVED_SUCCESSFULLY', 'Data saved successfully'), { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
                        }
                    } else {
                        setModalDetails({
                            show: true,
                            title: t('REGISTRATION_SUCCESS_MESSAGE', 'Thank You for registering in Goa-IDC portal, you may now log in!'),
                            img: check,
                            type: 'success',
                            reUpload: 'OK'
                        });
                    }
                    // setFieldValue("underProcess", true);
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    if (!isFinal) {
                        setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    } else if (isFinal) {
                        setModalDetails({ show: true, title: t('ENTERPRISE_REG_FAILED', 'Enterprise Registration Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    }
                }
            }
        } catch (e) {
            console.log('erorr', e);
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
        }
    };

    const onChangeNextIndex = async () => {
        const stepperLength = ENTERPRISE_STEPS_DETAILS.length;

        try {
            if (activeIndex < stepperLength) {
                if (formIsUpdatable) {
                    await ENTERPRISE_REGISTRATION_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false });
                }
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            }
        } catch (e) {
            let errMsg = t('ENTERPRISE_CREATION_FAILED', 'Enterprise creation failed');
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = ENTERPRISE_STEPS_DETAILS.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                if (formIsUpdatable) {
                    await ENTERPRISE_REGISTRATION_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false });
                }
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const handleModalClose = (type: string, msg?: string) => {
        setModalDetails({});
        if (type === 'success') {
            if (msg === 'Existing user data erased') {
                window.location.reload();
            } else {
                navigate('/goa-idc/');
            }
        }
    };

    const handleOnExistingUserModal = async () => {
        const otpRef = values.entityemailIdOtpReferenceId || values.entityPhoneNoOtpReferenceId;

        if (values.previousApplicationCheck === true) {
            try {
                const { data } = await POST(serachEnterpriseUrl + `?uuids=${availabilityCheck.entityId}&otpReferenceId=${otpRef}`, { RequestInfo: {} });
                getExistingEnterpriseData(data);
                localStorage.removeItem('availabilityCheck');
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } else if (values.previousApplicationCheck === false) {
            try {
                await POST(endpoints.deleteExistingUser + `?uuids=${availabilityCheck.entityId}&otpReferenceId=${otpRef}`, { RequestInfo: {} });
                let msg = t('EXISTING_USER_ERASED', 'Existing user data erased');
                setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'success', reUpload: 'OK' });
                localStorage.removeItem('availabilityCheck');
            } catch (e) {
                console.log(e);
            }
        }
        setShowValidUserPopup(false);
    };

    // const handleOnPrimaryAction = async (selectedAction: any, comment: string) => {
    //     const url = userType === USER_TYPE.EMP ? endpoints.initiateEnterpriseWorkflow : endpoints.initiateEnterpriseWorkflowNoLogin;
    //     const payload = {
    //         RequestInfo: requestInfo(),
    //         ProcessInstances: [
    //             {
    //                 tenantId: 'ga',
    //                 businessService: nextValidAction?.businessService,
    //                 businessId: applicationNo,
    //                 moduleName: nextValidAction?.moduleName,
    //                 action: selectedAction?.action,
    //                 comment: comment
    //             }
    //         ],
    //         EnterpriseEntity: enterprisePayload,
    //         isStateUpdatable: nextValidAction?.isUpdatable,
    //         otpReferenceId: values.entityemailIdOtpReferenceId || otpReferenceId || undefined
    //     };

    //     if (!comment) {
    //         toast('Please fill in all the required details!', {
    //             position: toast.POSITION.TOP_RIGHT,
    //             className: 'foo-bar',
    //             type: 'warning'
    //         });
    //         return;
    //     }

    //     try {
    //         // TODO
    //         // const res = await POST('/user/enterprise/nologin/workflow/process/_initiate', payload);
    //         const res = await POST(url, payload);
    //         setShowAlert(prev => false);
    //         const msg =
    //             res.data.processInstances[0]?.assignes?.[0]?.name && userType
    //                 ? `${t('ACTION_SUCCESS_SENT', 'Action Successful! Application send to')} ${res.data.processInstances[0]?.assignes[0]?.name}`
    //                 : `${t('ACTION_SUCCESS', 'Action Successful!')}`;
    //         toast(msg, {
    //             position: toast.POSITION.TOP_RIGHT,
    //             className: 'foo-bar',
    //             type: 'success'
    //         });
    //         navigate('/goa-idc/');
    //     } catch (e) {
    //         toast(t('ACTION_UNSUCCESS', 'Action Unsuccessful! Try again'), {
    //             position: toast.POSITION.TOP_RIGHT,
    //             className: 'foo-bar',
    //             type: 'error'
    //         });
    //     }
    // };

    const handleOnClickDownloadTemplate = async () => {
        setShowDownloadBox(true);
        try {
            const { data } = await POST(endpoints.downloadTemplate, { RequestInfo: requestInfo() });
            setDocumentTemplate(data.documentDetails);
            console.log();
        } catch (e) {
            console.log(e);
        }
    };

    const handleOnDownloadTemplate = async () => {
        const getData = filesSelection.filter((item: IdocumentTemplate) => item.isSelected);
        const getId = getData
            .flatMap((data: any) =>
                documentTemplate.map((item: any) => {
                    if (item.documentName === data.title) return item.documentReferenceId;
                })
            )
            .filter(Boolean);
        const idsUrl = getId.join();

        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${idsUrl}`;
        window.open(downloadFileUrl);
    };

    const getSelectedDocuments = (documents: IdocumentTemplate[]) => {
        setFilesSelection(documents);
    };

    console.log('values===', formik);

    return (
        <>
            <HeaderComponent />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.para)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.para)}
            />

            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <div className='col-sm-12'>
                    <GenericHeader
                        // otherDetails={
                        //     !userType ? (
                        //         <GenericButtons
                        //             text={t('DOWNLOAD_TEMPLATE', 'Download Template')}
                        //             handleClick={() => {
                        //                 handleOnClickDownloadTemplate();
                        //             }}
                        //         />
                        //     ) : (
                        //         ''
                        //     )
                        // }
                        // registrationNo={enterpriseUuid ? enterpriseUuid : ''}
                        text={t('ENTERPRISE_USER_REGISTRATION', 'Enterprise User Registration')}
                    />
                </div>
                <div className='col-3 stickyCard-section   '>
                    <div className='stickyCard' style={{ top: '110px' }}>
                        <Card className={`card_border  hiddenNav  ${isVisible ? '' : 'scrolledNav'}`}>
                            <Card.Body className=''>
                                <div className='d-flex align-items-center'>
                                    <GenericBackButton />
                                    <div className='ps-1'>
                                        <Card.Text className='font-inter-med fs-5'>
                                            {/* {t('ENTERPRISE_USER_REGISTRATION', 'Enterprise User Registration')} {enterpriseUuid ? <span> - {enterpriseUuid}</span> : ''} */}
                                            {t('ENTERPRISE_USER_REGISTRATION', 'Enterprise User Registration')}
                                        </Card.Text>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='card_border p-3'>
                            <Card.Body>
                                <Card.Text className=' d-flex flex-column  row-gap-3'>
                                    <SideCard steps={ENTERPRISE_STEPS_DETAILS} activeIndex={activeIndex} handleStepClick={handleStepClick} />
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <TemplateCard category={`ENTITY_REGISTRATION`} />
                    </div>
                </div>
                <div className='col-9 form-section'>
                    <FormikProvider value={formik}>
                        <GenericPopupModal
                            isVisible={showValidUserPopup}
                            primaryAction={() => {
                                handleOnExistingUserModal();
                            }}
                            secondaryAction={() => {
                                setShowValidUserPopup(false);
                            }}
                            primaryBtnTitle='Continue'
                            secondaryBtnTitle='Close'
                            title={t('WELCOME_BACK', 'Welcome Back!')}
                            subTitle={t('APP_ALREADY_EXIST', 'Existing application found for the given credentials.')}
                            children={<WelcomePopUp />}
                        />
                        <Form>
                            {activeIndex === 1 && renderProfileDetails()}
                            {activeIndex === 2 && renderAuthorizedPerson()}
                            {activeIndex === 3 && renderOwnershipDetails()}
                            {activeIndex === 4 && bankDetails()}
                        </Form>
                    </FormikProvider>
                </div>
            </div>
            <ScrollToTop />
            <GenericFooterComponent
                onClickNext={onChangeNextIndex}
                onCancel={() => navigate(-1)}
                onClickPrev={onChangePrevIndex}
                stepperLength={ENTERPRISE_STEPS_DETAILS.length}
                activeIndex={activeIndex}
                onSubmit={onSubmit}
                applicationNo={applicationNo || null}
                // nextValidAction={nextValidAction}
                // handleOnPrimaryAction={handleOnPrimaryAction}
            />
            <GenericPopupModal
                secondaryAction={() => {
                    setShowDownloadBox(false);
                }}
                primaryAction={() => {
                    handleOnDownloadTemplate();
                }}
                isVisible={showDownloadBox}
                children={<DownloadTemplate doc={doc} getSelectedDocuments={getSelectedDocuments} />}
                primaryBtnTitle={t('DOWNLOAD', 'Download')}
                isSecondaryBtn={false}
                title={t('DOWNLOAD_TEMPLATE', 'Download Template')}
                subTitle={t('STANDARDIZED_TEMPLATE', 'These documents require standardized templates obtainable from the Goa Industrial Development Corporation to fulfill this application.')}
                size='md'
            />
        </>
    );
}
