import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MIGRATION_SEARCH_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { EMPLOYEE_AUTHORIZED, TENANT_ID } from '../../../../utils/constants';
import { t } from 'i18next';
import { cross } from '../../../../utils/Logo';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getFirstErrorKey } from '../../../../utils/utils';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { Card } from 'react-bootstrap';
import { MIGRATION_DATA_EDIT, MIGRATION_SEARCH } from '../../Migration/Migration.const';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';

export function MigrationPaymentSearch() {
    const [isLoading, setIsLoading] = useState<any>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [migrationData, setMigrationData] = useState<any>([]);
    const [rowData, setRowData] = useState<any>([]);
    const [entityId, setEntityId] = useState<any>(null);

    const navigate = useNavigate();
    const location = useLocation();
    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;

    const entityName = location?.state?.entityName;
    const email = location?.state?.email;
    const entitypanNo = location?.state?.entitypanNo;

    const migratedDataEdit = MIGRATION_DATA_EDIT.map((item: any) => {
        if (item.fieldName === 'action') {
            return { ...item, actions: ['edit'] };
        } else {
            return { ...item };
        }
    });
    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: MIGRATION_SEARCH_VALIDATION
    });

    const { values, setValues, errors, setFieldTouched } = formik;

    const onReset = () => {
        setRowData([]);
        setValues((prev: any) => ({ ...prev, entityName: '', email: '', entitypanNo: '' }));
    };

    useEffect(() => {
        setValues((prevVal: any) => ({ ...prevVal, entityName: entityName, email: email, entitypanNo: entitypanNo }));
        if (entityName && email && entitypanNo) {
            onSearch();
        }

        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }
    }, [location]);

    useEffect(() => {
        if (migrationData?.length && entityId) {
            const tempTableData = migrationData?.map((val: any, index: number) => {
                return {
                    serialNo: index + 1,
                    uuid: val?.plotId,
                    applicationNumber: val?.migrationApplicationNumber,
                    plotNo: val?.plotDetails?.plotNo,
                    phase: val?.plotDetails?.phase,
                    estateName: val?.plotDetails?.estateDetails?.estateName,
                    entityId: entityId,
                    lessee: val?.entityName
                };
            });
            setRowData(tempTableData);
        }
    }, [migrationData, entityId]);

    const onSearch = async () => {
        const queryParams = new URLSearchParams();
        if (values.entityName || entityName) queryParams.append('entityName', values.entityName ? values.entityName : entityName);
        if (values.email || email) queryParams.append('email', values.email ? values.email : email);
        if (values.entitypanNo || entitypanNo) queryParams.append('panNumber', values.entitypanNo ? values.entitypanNo : entitypanNo);

        if (!(values?.entityName || entityName || values?.email || email || values?.entitypanNo || entitypanNo)) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: 'Please enter Name, Email or PAN Number', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            await MIGRATION_SEARCH_VALIDATION.validate(values, { abortEarly: false });
            try {
                const { data } = await POST(endpoints.searchExistingUser + `?tenantId=${TENANT_ID}&${queryParams.toString()}`, { RequestInfo: requestInfo() });
                if (data?.enterpriseEntity[0]?.uuid) {
                    setEntityId(data?.enterpriseEntity[0]?.uuid);
                    const { data: migrationSearch } = await POST(endpoints.searchMigration + `?entityIds=${data?.enterpriseEntity[0]?.uuid}`, { RequestInfo: requestInfo() });
                    const migrationDataCompleted = migrationSearch?.estateMigrationData;
                    if (migrationDataCompleted?.length) {
                        setMigrationData(migrationDataCompleted);
                    } else {
                        setRowData([]);
                        setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: 'Migration Data does not exist for the searched Entity', img: cross, type: 'error', reUpload: 'OK' });
                        return;
                    }
                } else {
                    setModalDetails({ show: true, title: 'Error!', para: 'Entity does not exist', img: cross, type: 'error', reUpload: 'OK' });
                    return;
                }
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e: any) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('VALID_DETAILS', 'Please fill in valid details'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
        }
    };

    const handleModalClose = (type: string, isUnauthorized: boolean) => {
        setModalDetails({});
        if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            } else if (userType === 'EMPLOYEE') {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    const onEdit = (row: any) => {
        navigate(`/goa-idc/migration/payment-update?plotId=${row?.uuid}&entityId=${row?.entityId}`);
    };

    console.log('formik', formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={t('MIGRATED_DATA_PAYMENT_UPDATE', 'Migrated Data Payment Update')} />
                        <div className='col-13 form-section'>
                            <Card className='card_border'>
                                <FormikProvider value={formik}>
                                    <Card.Body>
                                        {MIGRATION_SEARCH?.map((val: any) => (
                                            <GenericFormCard {...val} />
                                        ))}
                                        <div className='d-flex justify-content-end pe-2 pb-3'>
                                            <GenericButtons text={t('RESET', 'Reset')} handleClick={onReset} />
                                            <GenericButtons text={t('SEARCH', 'Search')} handleClick={onSearch} variant='primary' />
                                        </div>
                                        <div className='mt-3 p-2'>
                                            {rowData?.length ? (
                                                <>
                                                    <GenericActionTable
                                                        columns={migratedDataEdit}
                                                        rowData={rowData}
                                                        searchBarPlaceholder={t('SEARCH_MIGRATION_FILTER', 'Search by Application No, Estate, Phase or Plot No')}
                                                        hideExportBtn={true}
                                                        handleEditRow={onEdit}
                                                    />
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </Card.Body>
                                </FormikProvider>
                            </Card>
                        </div>
                    </div>
                </>
            )}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
            />
        </>
    );
}
