import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { OwnershipTable } from '../../UserManagement/EnterpriseModule/OwnershipTable';
import { ESTATE_MASTER_TABLE_COLUMNS, estateDropdown } from './master.const';
import { cross } from '../../../../utils/Logo';
import { useNavigate } from 'react-router-dom';
import { convertToINR } from '../EstateManagement.controller';
import { EMPLOYEE_AUTHORIZED } from '../../../../utils/constants';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
interface ICorrespondenceProps {
    checkListData?: any;
    noting?: any;
    toolTipDetails?: any;
}

const EstateMaster = (props: ICorrespondenceProps) => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const [openIndex, setOpenIndex] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [rowData, setRowData] = useState([]);
    const user = localStorage.getItem('user');
    const designation = user ? String(JSON.parse(user)?.designation) : '';
    const [dropdownData, setDropdownData] = useState<any>([]);
    const userType = user ? String(JSON.parse(user)?.type) : '';

    const formik = useFormik<any>({
        initialValues: {
            estateName: '',
            taluka: '',
            surveyNumber: '',
            village: '',
            city: '',
            district: '',
            numberOfPlots: '',
            landRegistrationNumber: '',
            phoneNumber: '',
            emailId: '',
            isUpdatable: true
        },
        onSubmit: value => {}
    });

    const { values, setValues, setTouched } = formik;

    const estateMasterTableColumn =
        designation === 'ITM'
            ? [
                  ...ESTATE_MASTER_TABLE_COLUMNS,
                  {
                      colName: 'Action',
                      type: 'action',
                      clsName: 'fixed-action-column fixed-action-column-bg column-text-start',
                      cellstyle: { width: '10%' },
                      actions: ['edit', 'view']
                  }
              ]
            : [
                  ...ESTATE_MASTER_TABLE_COLUMNS,
                  {
                      colName: 'Action',
                      type: 'action',
                      clsName: 'fixed-action-column fixed-action-column-bg column-text-start',
                      cellstyle: { width: '10%' },
                      actions: ['view']
                  }
              ];

    const getVacantData = async () => {
        try {
            const RequestInfo = { RequestInfo: requestInfo() };
            const res1 = await POST('/idc-estate-service/estate-master-details/_search', RequestInfo);
            if (res1) {
                const modifiedPlots = res1.data.estateMasterDetails.map((plot: any) => {
                    const isActiveName = plot.isActive ? 'Active' : 'Inactive';
                    const village = plot.estateDetails?.villageMasterDetailsList?.map((item: any) => item.villageName).join(', ');
                    const district = plot.estateDetails.districtName;
                    return { ...plot, isActiveName, village: village, district: district, plotRate: convertToINR(plot.estateDetails.plotRate) };
                });
                setRowData(modifiedPlots);
                console.log('modified row dataaaaaa', modifiedPlots);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        if (userType === 'EMPLOYEE') {
            (async () => {
                const dropdownOption = await estateDropdown(userType);
                setDropdownData(dropdownOption || []);
            })();
        }

        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }
    }, []);

    useEffect(() => {
        userType === 'EMPLOYEE' && dropdownData && getVacantData();
    }, [dropdownData]);

    // const showForm = () => {
    //     const val = _.cloneDeep(values);
    //     setIsEditing(false);
    //     setOpenIndex(null);
    //     setTouched({});
    //     const keysToDelete = ['estateName', 'taluka', 'surveyNumber', 'village', 'city', 'district', 'numberOfPlots', 'landRegistrationNumber', 'phoneNumber', 'emailId'];
    //     keysToDelete.forEach(key => {
    //         delete val[key];
    //     });
    //     setValues({ ...val, isUpdatable: true });
    //     setShowPopup(true);
    // };

    // const addEstate = async () => {
    //     try {
    //         await ESTATE_MASTER_VALIDATION.validate(values);
    //     } catch (e) {
    //         setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
    //         return;
    //     }
    //     const activityPayload: any = {
    //         RequestInfo: requestInfo(),
    //         ...values,
    //         district: values.district.code
    //     };

    //     try {
    //         if (isEditing) {
    //             const res = await POST('/idc-estate-service/estate-master-details/_update', activityPayload);
    //             if (res.data) {
    //                 setModalDetails({ show: true, title: 'Success!', para: 'Action Successful', img: check, type: 'success', reUpload: 'OK' });
    //             }
    //         } else {
    //             const res = await POST('/idc-estate-service/estate-master-details/_create', { ...activityPayload, isActive: true });
    //             if (res.data) {
    //                 setModalDetails({ show: true, title: 'Success!', para: 'Action Successful', img: check, type: 'success', reUpload: 'OK' });
    //             }
    //         }

    //         const res1 = await POST('/idc-estate-service/estate-master-details/_search', { RequestInfo: requestInfo() });
    //         // const newres = res1.data.estateMasterDetails;
    //         const modifiedPlots = res1.data.estateMasterDetails.map((plot: any) => {
    //             const isActiveName = plot.isActive ? 'Active' : 'Inactive';
    //             return { ...plot, isActiveName };
    //         });
    //         setRowData(modifiedPlots);
    //     } catch (e: any) {
    //         let errMsg = e.response?.data?.errorMessage || 'Action Unsuccessful';
    //         setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
    //     }

    //     setShowPopup(false);
    // };

    const onEdit = (data: any, isViewOnly?: any) => {
        const detailObj: any = rowData.find((item: any) => item.uuid === data.uuid);
        const index: any = rowData.findIndex((item: any) => item.uuid === data.uuid);

        setOpenIndex(index > -1 ? index + 1 : null);
        navigate('/goa-idc/estate/new-estate-registry', { state: { data: detailObj, disable: isViewOnly } });
    };

    const onView = (status: boolean, data: any) => {
        onEdit(data, true);
    };

    const onAdd = () => {
        navigate('/goa-idc/estate/new-estate-registry', { state: { data: {} } });
    };

    const handleModalClose = (type: string, isUnauthorized: boolean) => {
        setModalDetails({});
        if (type === 'success' && !isUnauthorized) {
            navigate('/goa-idc/dashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            }
        }
    };

    return (
        <>
            <HeaderComponent />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
            />
            <Card.Body>
                <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                    <div className='col-sm-12'>
                        {<GenericHeader text={t('ESTATE_MASTER_REGISTRY', 'Estate Master Registry')} />}
                        <Card className='card_border'>
                            <Card.Body>
                                <div className='d-flex justify-content-start font-inter'>
                                    <h4>
                                        <span style={{ color: '#3A7425', paddingLeft: '25px' }}>
                                            <label>Estate</label>
                                        </span>
                                    </h4>
                                </div>
                                <div className='employee-creation-container position-relative m-auto px-3 py-3 row'>
                                    {designation === 'ITM' && <GenericButtons text={t('ADD', '+ Add')} handleClick={onAdd} variant='primary' ClassName='estate-add-btn' />}
                                    <OwnershipTable
                                        column={estateMasterTableColumn}
                                        tableRowData={rowData}
                                        additionalMethod={onEdit}
                                        updateOwnershipDataStatus={onView}
                                        // deleteOwnershipDetails={onDeleteData}
                                        searchBarPlaceholder={'Search by Estate Name, Taluka, Survey No...'}
                                        showIndex={true}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                {/* <FormikProvider value={formik}>
                    <GenericPopupModal
                        onClose={() => setShowPopup(false)}
                        secondaryAction={() => {}}
                        primaryAction={addEstate}
                        primaryBtnTitle='Save'
                        isPrimaryBtn={true}
                        isSecondaryBtn={false}
                        size='lg'
                        isVisible={ShowPopup}
                        title={`${openIndex ? (isEditing ? 'Edit' : 'Add') : 'Add'} Estate`}
                        children={
                            <div className='col-12'>
                                <Card>
                                    <Card.Body>
                                        {isEditing ? (
                                            <div className='col-12 font-inter' style={{ display: 'flex', alignItems: 'center', marginLeft: '13px', fontSize: '14px' }}>
                                                {isActive ? 'Active :' : 'Inactive :'} &nbsp; &nbsp;
                                                <div
                                                    style={{
                                                        width: '60px',
                                                        height: '30px',
                                                        backgroundColor: isActive ? ' #45852e' : '#ccc',
                                                        borderRadius: '15px',
                                                        position: 'relative',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={handleToggleChange}
                                                >
                                                    <div
                                                        style={{
                                                            width: '26px',
                                                            height: '26px',
                                                            backgroundColor: '#fff',
                                                            borderRadius: '50%',
                                                            position: 'absolute',
                                                            top: '2px',
                                                            left: isActive ? '30px' : '2px',
                                                            transition: 'left 0.3s ease-in-out'
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {renderEstateDetails()}
                                    </Card.Body>
                                </Card>
                            </div>
                        }
                    />
                </FormikProvider> */}
            </Card.Body>
        </>
    );
};

export default EstateMaster;
