import { useEffect, useState } from 'react';
import { Card, CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EXTENSION_TAB, EXTWORKFLOW_DETAILS } from './Extension.const';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import Editor from '../../../../components/ContentEditor';
import AllotedPlotDetails from '../Allotment/Workflow/AllotedPlotDetails';
import ApplicantDetailsWorkflow from '../ApplicantDetailsWorkflow';
import { useFormikContext } from 'formik';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';

interface ICorrespondenceProps {
    content?: any;
    setContent?: any;
    extensionData?: any;
    dropdownData?: any;
    designation?: any;
    nextValidAction?: any;
    vacantPlotData?: any;
    dropdownDataExtension?: any;
    isViewOnly?: any;
    updateNotes?: any;
}

const ExtCorrespondence = (props: ICorrespondenceProps) => {
    const { content, setContent, extensionData, dropdownData, designation, nextValidAction, vacantPlotData, dropdownDataExtension, isViewOnly, updateNotes } = props;

    const [openSection, setOpenSection] = useState<string>('userDetails');
    const { t } = useTranslation();
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [updatedWorkFlowDetails, setWorkFlowDetails] = useState<any>(EXTWORKFLOW_DETAILS);
    const [extensionTab, setExtensionTab] = useState<any>([]);
    const [addButton, setAddButton] = useState(false);

    const { values } = useFormikContext<any>();
    useEffect(() => {
        if (designation === 'AM' && nextValidAction?.businessService === 'EST_EXTENSION_TIMELINE_EMPLOYEE') {
            setIsDisabled(false);
        }
        const UPDATED_WORKFLOW_DETAILS = EXTWORKFLOW_DETAILS.map((val: any) => ({
            ...val,
            formData: val.formData.map((data: any) => {
                if (designation === 'AM' && nextValidAction?.businessService === 'EST_EXTENSION_TIMELINE_EMPLOYEE') {
                    const { isDisabled, ...newData } = data;
                    return newData;
                }
                return data;
            })
        }));
        const tempUpdatedWorkflowDetails = isViewOnly
            ? UPDATED_WORKFLOW_DETAILS.map((val: any) => ({ ...val, formData: val?.formData?.map((item: any) => ({ ...item, isDisabled: true })) }))
            : UPDATED_WORKFLOW_DETAILS;
        setWorkFlowDetails(tempUpdatedWorkflowDetails);
    }, [designation, nextValidAction]);

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (openSection !== currentSection && currentSection === 'timelineExtensionOrderNotes' && designation === 'AM') {
            setAddButton(true);
        }
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    useEffect(() => {
        const tempExtensionTab =
            extensionData?.applicationType === 'TIMELINE_EXTENSION' && extensionData?.applicationSubType === 'ALLOTMENT'
                ? EXTENSION_TAB
                : extensionData?.applicationType === 'DEFAULT_TIMELINE_EXTENSION'
                ? [
                      { title: 'A - User Details', field: 'userDetails' },
                      { title: 'B - Plot Details', field: 'plotDetails' },
                      { title: 'C - Default Notice Notes', field: 'defaultNoticeNotes' }
                  ]
                : values?.extensionType?.code === 'Transfer Order' || values?.extensionType?.code === 'Special Transfer Order' || values?.extensionType?.code === 'Provisional Sub-Lease Order'
                ? [...EXTENSION_TAB, { title: 'D - Timeline Extension Order Notes', field: 'timelineExtensionOrderNotes' }]
                : EXTENSION_TAB;

        setExtensionTab(tempExtensionTab);
    }, [values?.extensionType]);

    const renderApplicantDetails = () => {
        return <ApplicantDetailsWorkflow dropdownOptions={dropdownData} />;
    };

    const renderExtDetails = () => {
        return (
            <>
                {updatedWorkFlowDetails.map((val: any) => (
                    <GenericFormCard {...val} dropdownOptions={dropdownDataExtension} />
                ))}
            </>
        );
    };

    const renderPlotDetails = () => {
        return (
            <>
                <AllotedPlotDetails plotData={vacantPlotData} type={'allotedPlot'} isFieldDisable={isViewOnly ? isViewOnly : isDisabled} />
            </>
        );
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1'>
                            <Editor setContent={setContent} content={content} readOnly={isViewOnly ? isViewOnly : !nextValidAction?.isUpdatable} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <CardBody>
                    <div className='row'>
                        {extensionTab.map((val: any, index: number) => {
                            return (
                                <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                    <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                    <label
                                        htmlFor={`${val.field}Form`}
                                        className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                    >
                                        {val.title}
                                    </label>
                                    {val.title === 'A - User Details' && openSection === 'userDetails' && renderApplicantDetails()}
                                    {val.title === 'B - Plot Details' && openSection === 'plotDetails' && renderPlotDetails()}
                                    {val.title === 'C - Timeline Extension Details' && openSection === 'timelineExtensionDetails' && renderExtDetails()}
                                    {((val.title === 'D - Timeline Extension Order Notes' && openSection === 'timelineExtensionOrderNotes') ||
                                        (val.title === 'C - Default Notice Notes' && openSection === 'defaultNoticeNotes')) &&
                                        renderNoteDetails()}
                                </div>
                            );
                        })}
                    </div>
                </CardBody>
            </Card>

            <GenericPopupModal
                title='Save Confirmation'
                size='md'
                primaryAction={() => {
                    setAddButton(false);
                    updateNotes();
                }}
                secondaryAction={() => {
                    setAddButton(false);
                }}
                isVisible={addButton}
                children={
                    <div>
                        <div className='mx-4 my-3'>
                            <div className='mt-4'>
                                <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                    {t('SAVE_CHANGES', 'Looks like you have some unsaved changes. Would you like to save them before proceeding with the new data?')}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                primaryBtnTitle='Yes'
                secondaryBtnTitle='No'
                onClose={() => setAddButton(false)}
            />
        </>
    );
};
export default ExtCorrespondence;
