import React from 'react';
import GenericCommonRadioField from '../../components/moduleCommonFormik/GenericCommonRadioField.component';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';

export const EsignModal = () => {
    const { t } = useTranslation();

    const formik = useFormikContext<any>();
    const { values, setValues, setFieldValue } = formik;

    const options = [
        { value: 'DSC', title: 'Token Based Sign' }
        // { value: 'EKYC', title: 'KYC Based Sign' }
    ];

    const handleOnChange = (e: any) => {
        setFieldValue('esignOption', e.target.value);
    };
    return (
        <div>
            <Card>
                <Card.Body>
                    <div className='d-flex font-inter'>
                        <label htmlFor={'esignOption'} title={'Select signing option'} className='pb-2 file-form-label' style={{ width: 'fit-content' }}>
                            {t('SELECT_SIGNING_OPTION', 'Select signing option')}
                        </label>
                    </div>
                    <div role='group' aria-labelledby='my-radio-group' className='d-flex' style={{ width: '80%' }}>
                        {!!options?.length &&
                            options.map(
                                (val: any) =>
                                    !val?.isDisabled && (
                                        <label className={`pe-4 m-2 w-100 cursor-pointer font-inter `} key={val?.value}>
                                            <Field
                                                type='radio'
                                                name={'esignOption'}
                                                onChange={(e: any) => {
                                                    handleOnChange(e);
                                                }}
                                                key={val?.value}
                                                value={val?.value}
                                                disabled={false}
                                                className={'cursor-pointer'}
                                            />
                                            {val?.title}
                                        </label>
                                    )
                            )}
                    </div>

                    {/* <GenericCommonRadioField
                        options={[
                            { value: 'DSC', title: 'Token Based Sign' },
                            { value: 'ESIGN', title: 'eSign' }
                        ]}
                        title={t('SELECT_SIGNING_OPTION', 'Select signing option')}
                        field='esignOption'
                    /> */}
                </Card.Body>
            </Card>
        </div>
    );
};
