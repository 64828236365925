import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { useState, useEffect } from 'react';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { Card, Form } from 'react-bootstrap';
import ScrollToTop from '../../../../components/ScrollToTop';
import { ESTATE_ADDITIONAL_DETAILS, ESTATE_DETAILS, estateDropdown, getEstateDetails } from './master.const';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { dropdownDataOptions } from '../../Migration/Migration.controller';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { POST, endpoints, estateEndpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { check, cross } from '../../../../utils/Logo';
import { ESTATE_APPLICATION_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { getFirstErrorKey } from '../../../../utils/utils';
import { EMPLOYEE_AUTHORIZED } from '../../../../utils/constants';

export const EstateApplication = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();
    const user = localStorage.getItem('user');
    const userType = user ? String(JSON.parse(user)?.type) : '';

    const data = location?.state?.data || null;
    const disable = location?.state?.disable || null;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [applicationNo, setApplicationNo] = useState<any>('');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            electricityPowerAvailability: false,
            waterAvailability: false,
            naturalGasAvailability: false,
            stpAvailability: false,
            electricPoleAvailability: false,
            powerSubStationAvailability: false,
            wtpAvailability: false,
            swDisposalFacilityAvailability: false,
            accommodationForWorkers: false,
            availiabilityOfDesignatedTruckParkingArea: false,
            ictAvailability: false
        },
        onSubmit: () => {},
        validationSchema: ESTATE_APPLICATION_VALIDATION
    });

    const { values, setValues, setFieldValue, setTouched, errors, setFieldTouched } = formik;

    const NEW_ESTATE_DETAILS = ESTATE_DETAILS.map((detail: any) => ({ ...detail, formData: detail.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));

    useEffect(() => {
        (async () => {
            const dropdownOption = await estateDropdown(userType);
            setDropdownData(dropdownOption);
        })();

        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }
    }, []);

    useEffect(() => {
        if (data && Object.keys(data).length && dropdownData) {
            let estateAdditionalDetails = {};

            const formData = {
                ...data,
                ...data?.estateDetails,
                // uuid: null,
                village: data.estateDetails.villageMasterDetailsList?.map((item: any) => ({ code: item.villageCode, name: item.villageName })),
                villageMasterDetailsList: data.estateDetails.villageMasterDetailsList?.map((item: any) => ({ code: item.villageCode, name: item.villageName, uuid: item.uuid })),
                district: getDropdownSelectedObject(data.estateDetails.districtName, dropdownData['district']),
                polutionControlCategoryAllowed: data.estateDetails.polutionControlCategoryAllowed
            };

            if (disable) {
                (async () => {
                    try {
                        setIsLoading(true);
                        const { data: additionalDetails } = await POST(estateEndpoints.estateSearch + `?uuid=${data.uuid}&isPopulateDerivedAttributes=true`, { RequestInfo: requestInfo() });
                        const estateData = additionalDetails?.estateMasterDetails[0].estateDetails;
                        if (estateData) {
                            estateAdditionalDetails = {
                                totalNoOfPlotsDerrived: estateData?.totalNoOfPlots,
                                avgSizeOfPlotsDerrived: estateData?.avgSizeOfPlots ? estateData?.avgSizeOfPlots?.toFixed(2) : '',
                                totalNoOfAllottedPlotsDerrived: estateData?.totalNoOfAllottedPlots,
                                totalNoOfVacantPlotsDerrived: estateData?.totalNoOfVacantPlots,
                                totalVacantIndustrialAreaDerrived: estateData?.totalVacantIndustrialArea ? estateData?.totalVacantIndustrialArea?.toFixed(2) : '',
                                landType: estateData?.landType,
                                industrialType: estateData?.industrialType
                            };

                            const updatedValues = { ...values, ...formData, ...estateAdditionalDetails, isUpdatable: !disable };
                            setValues(updatedValues);
                        }
                        setIsLoading(false);
                    } catch (err: any) {
                        setIsLoading(false);
                        console.log(err, 'error');
                        let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                        setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    }
                })();
            } else {
                const updatedValues = { ...values, ...formData, ...estateAdditionalDetails, isUpdatable: !disable };
                setValues(updatedValues);
            }
        }
    }, [dropdownData, data]);

    useEffect(() => {
        setFieldValue('districtCode', values.district?.code);
    }, [values.district]);

    useEffect(() => {
        if (dropdownData) {
            const selectedVillages = values.village?.flatMap((item: any) => dropdownData['village']?.filter((subItem: any) => subItem.code === item.code));
            setFieldValue('villageMasterDetailsList', selectedVillages);
        }
    }, [values.village]);

    const handleModalClose = (type: string, finalSubmit?: true, isUnauthorized?: boolean) => {
        setModalDetails({});
        if (type === 'success' && finalSubmit) {
            navigate(`/goa-idc/estate/estate-registry-workflow?applicationNumber=${applicationNo}`);
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            } else if (userType === 'EMPLOYEE') {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    const onSubmit = async () => {
        const estateDetails = getEstateDetails(values);
        const payload = {
            RequestInfo: requestInfo(),
            refType: 'ESTATE',
            type: data && Object.keys(data).length ? 'MODIFICATION' : 'NEW',
            existingEstateDetails: data && Object.keys(data).length ? { ...data } : null,
            newEstateDetails: {
                uuid: data && Object.keys(data).length ? data.uuid : null,
                estateName: values.estateName,
                status: data && Object.keys(data).length ? 'APPROVED' : null,
                isActive: data && Object.keys(data).length ? true : false,
                estateDetails: { ...estateDetails, estateId: data && Object.keys(data).length ? values.estateId : null }
            },
            existingPlotDetails: null,
            newPlotDetails: null,
            status: null
        };
        try {
            await ESTATE_APPLICATION_VALIDATION.validate(values, { abortEarly: false });
            try {
                const { data } = await POST(endpoints.estateCreation, payload);
                setApplicationNo(data.applicationNo);
                setModalDetails({
                    show: true,
                    title: 'Success!',
                    para: `Estate Registry Application${data.applicationNo ? ' Number ' + data.applicationNo : ''} Initiated Successfully`,
                    img: check,
                    type: 'success',
                    reUpload: 'OK',
                    finalSubmit: true
                });
            } catch (err: any) {
                console.log(err);
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details', img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    console.log(formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader text={t('ESTATE_MASTER_REGISTRY', 'Estate Master Registry')} additionalClass='sub-text-style' />
                        <FormikProvider value={formik}>
                            <div className='col-sm-12 mt-3 form-section '>
                                <Form>
                                    <Card className='card_border p-1'>
                                        {NEW_ESTATE_DETAILS.map((val, index) => (
                                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                        ))}
                                        {disable ? ESTATE_ADDITIONAL_DETAILS?.map((val: any) => <GenericFormCard {...val} />) : <></>}
                                    </Card>
                                </Form>
                            </div>
                        </FormikProvider>
                    </div>
                    <ScrollToTop />
                    {!disable ? (
                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                    <GenericButtons text={t('INITIATE', 'Initiate')} variant='primary' handleClick={() => onSubmit()} />
                                </div>
                            </div>
                        </footer>
                    ) : (
                        <></>
                    )}
                </>
            )}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit, modalDetails?.isUnauthorized)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit, modalDetails?.isUnauthorized)}
            />
        </>
    );
};
