import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Form } from 'react-bootstrap';
import { CONTINGENCIES_COLUMN, ABSTRACT_TABLE_COLUMNS, DPR_CORRESPONDENCE, HEADER_FIELDS, DPR_MEASUREMENT, initialMeasurements, MEASUREMENT_DETAILS_I, MEASUREMENT_DETAILS_II } from './DPR.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import Editor from '../../../../components/ContentEditor';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { OwnershipTable } from '../../UserManagement/EnterpriseModule/OwnershipTable';
import { MEASUREMENT_TABLE_COLUMNS } from './DPR.const';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import _ from 'lodash';
import ModalPopup from '../../../../components/popup/Popup.generic';
import { getFirstErrorKey } from '../../../../utils/utils';
import { cross, DeleteWarning } from '../../../../utils/Logo';
import { v4 as uuidv4 } from 'uuid';
import { CONTINGENCIES_VALIDATION, MEASUREMENT_VALIDATION, DPR_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { convertToINR, entityDocumentData, strToNum } from '../../EstateManagement/EstateManagement.controller';
import DeleteIcon from '../../../../assets/logos/delete.svg';
import '../Workspace.scss';
import GsrMasterDetails from '../GSR/GsrSecondScreen';
import DeletePopUpModal from '../../../../components/moduleCommonFormik/DeletePopUp.component';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import { t } from 'i18next';
import SearchField from '../../../../components/moduleCommonFormik/SearchField';
import GenericCommonTextField from '../../../../components/moduleCommonFormik/GenericCommonTextField.component';

// import DeleteIcon from '../../../../assets';

const DprCorrespondence = ({
    dropdownData,
    setContent,
    content,
    readOnly,
    t,
    show,
    setShow,
    setModalDetails,
    isDisabled,
    measurementData,
    setMeasurementData,
    contingenciesData,
    setDropdownData,
    setReminderPopUp,
    abstractData,
    setAbstractData,
    estimatedTotalCost,
    contingenciesCost,
    dropdownDataGsr,
    setDropdownDataGsr,
    responseData,
    isViewOnly,
    tableData,
    onRowClick
}: any) => {
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const [openSection, setOpenSection] = useState<string>('header');
    const [isEditing, setIsEditing] = useState<any>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [openIndex, setOpenIndex] = useState<any>(null);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [rowDelId, setRowDelId] = useState<any>(null);
    const [dropDownValue, setDropDownValue] = useState();
    const [autoSuggestionData, setAutoSuggestionData] = useState<any>([]);

    const [gsrName, setGsrName] = useState<any>([]);
    const { values, touched, errors, setFieldValue, setTouched, setValues, setFieldTouched, handleChange } = useFormikContext<any>();

    const onSectionChange = async (currentSection: string) => {
        try {
            await DPR_VALIDATION.validate(values, { abortEarly: false, context: values });
            const gstAmount = (estimatedTotalCost / 100) * 18;
            const totalContingencies = Math.ceil(estimatedTotalCost + gstAmount);
            if (currentSection === 'technicalSanction' && values.isUpdatable && (Object.keys(touched).length || responseData?.estimatedTotalCost !== totalContingencies)) {
                setReminderPopUp(true);
            }
            let previousSection = openSection;
            if (previousSection === currentSection) setOpenSection('');
            else setOpenSection(currentSection);
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const fetchOptions = async (e: any) => {
        handleChange(e);
        try {
            if (values.gsrMarketRate === 'GSR') {
                const { data } = await POST(`${endpoints.gsrMasterList}&itemDesc=${e}&gsrNames=${values.gsrName.code}`, { RequestInfo: requestInfo() });
                setAutoSuggestionData(data?.gsrMasterDetailsModel);

                return data?.gsrMasterDetailsModel.map((option: any) => ({
                    value: option.itemDesc,
                    label: option.itemDesc
                }));
            }
        } catch (e) {
            console.log(e);
        }
    };
    const autoSuggestionHandleChnage = (val: any, field: any) => {
        if (val) {
            const checkCode = autoSuggestionData?.find((item: any) => item.itemDesc === (val.value || val.label));

            if (checkCode) {
                setValues((prev: any) => ({
                    ...prev,
                    itemDesc: val,
                    // itemDesc: { value: checkCode?.itemDesc, label: checkCode?.itemDesc },
                    // itemDesc: checkCode?.itemDesc,
                    gsrUnitRate: checkCode.gsrUnitRate,
                    unitOfMeasurement: checkCode.unitOfMeasurement,
                    gsrUuid: checkCode.uuid,
                    gsrItemNo: { code: checkCode?.itemNo, name: checkCode?.itemNo },
                    measurementType: '',
                    measurements: [initialMeasurements]
                }));
            }
        }
    };

    const onGsrChangeAction = async (e: any, field: string) => {
        if (field === 'gsrMarketRate') {
            if (e?.target?.value === 'MarketRate') {
                setValues((prev: any) => ({ ...prev, itemDesc: '', mvUnitRate: '', unitOfMeasurement: '', itemNo: '', measurementType: '', remarks: '', document: '' }));
            }
        }
        if (field === 'measurementType') {
            setValues((prev: any) => ({
                ...prev,
                measurementType: { code: e.value, name: e.label },
                measurements: [initialMeasurements]
            }));
        }

        if (field === 'lbdOrNonlbd' && e?.target?.value === 'Non-LBD') {
            setValues((prev: any) => ({
                ...prev,
                measurements: [initialMeasurements]
            }));
        }
        if (field === 'gsrMarketRate' && e?.target?.value === 'MarketRate') {
            setFieldValue('itemType', 'Non-Scheduled');
        }
        if (field === 'gsrMarketRate' && e?.target?.value === 'GSR') {
            setFieldValue('itemType', 'Scheduled');
            // let tempGsrNames: any = [];

            try {
                const response = await POST(endpoints.gsrMasterList + '&isGsrMasterDetailsRequired=true', { RequestInfo: requestInfo() });

                let tempGsrNames = response.data.gsrNames?.map((val: any) => ({ name: val, code: val }));
                setGsrName(tempGsrNames);

                setDropdownDataGsr((prev: any) => ({ ...prev, gsrName: tempGsrNames, gsrItemNo: [] }));
            } catch (error) {
                console.error('Error fetching GSR data:', error);
            }
        }
        if (field === 'gsrName') {
            try {
                const response = await POST(`${endpoints.gsrMasterList}&gsrNames=${e.value}&isGsrMasterDetailsRequired=true`, { RequestInfo: requestInfo() });
                const tempGsrItemNo = response.data.itemNos?.map((val: any) => ({ name: val, code: val }));

                setDropdownDataGsr((prev: any) => ({ ...prev, gsrName: gsrName, gsrItemNo: tempGsrItemNo }));
                const gsrDetails = response?.data?.gsrMasterDetailsModel?.[0];

                if (gsrDetails) {
                    setValues((prev: any) => ({
                        ...prev,
                        gsrYear: gsrDetails.gsrYear
                    }));
                } else {
                    console.error('GSR Details not found.');
                }
            } catch (e: any) {}
        }
        if (field === 'gsrItemNo') {
            try {
                const response = await POST(`${endpoints.gsrMasterList}&gsrNames=${values.gsrName.code}&itemNos=${e.value}&isGsrMasterDetailsRequired=true`, { RequestInfo: requestInfo() });
                const gsrDetails = response?.data?.gsrMasterDetailsModel?.[0];
                if (gsrDetails) {
                    setValues((prev: any) => ({
                        ...prev,
                        itemDesc: { value: gsrDetails.itemDesc, label: gsrDetails.itemDesc },
                        // itemDesc: gsrDetails?.itemDesc,
                        gsrUnitRate: gsrDetails.gsrUnitRate,
                        unitOfMeasurement: gsrDetails.unitOfMeasurement,
                        gsrUuid: gsrDetails.uuid,
                        itemNo: '',
                        measurementType: '',
                        measurements: [initialMeasurements]
                    }));
                } else {
                    console.error('GSR Details not found.');
                }
            } catch (e: any) {}
        }
        if (field === 'mvUnitRate' && (e || e?.target?.value) && values?.gsrMarketRate === 'MarketRate') {
            const updatedMvUnitRate = e.target.value || null; // Parse mvUnitRate as a float to avoid NaN

            setValues((prev: any) => ({
                ...prev,
                mvUnitRate: updatedMvUnitRate // Update mvUnitRate
            }));
        }
    };

    // Function to calculate quantity with optional breadth and depth
    const calculateQuantity = (length: number, breadth: number | undefined, depth: number | undefined, nos: number) => {
        // Default values for depth and breadth
        const depthValue = depth ? +depth : 1; // Default depth to 1 if not provided
        const breadthValue = breadth ? +breadth : 1; // Default breadth to 1 if not provided
        return (+length * breadthValue * depthValue * +nos).toFixed(2);
    };

    const calculateCost = (quantity: any, gsrUnitRate: number) => {
        return (+quantity * +gsrUnitRate).toFixed(2);
    };

    const handleMeasurements = async (e: any, currentField: string) => {
        let field = currentField.split('.').slice(-1)[0];
        let index = +currentField.split('.')[1];
        const state = _.cloneDeep(values);

        if (field === 'length' && (e || e?.target?.value)) {
            let updatedLength = e.target.value;
            let tempQuantity = calculateQuantity(+updatedLength, +values.measurements[index]?.breadth || 0, +values.measurements[index]?.depth, +values.measurements[index]?.nos || 0);
            _.set(state, `measurements[${index}]`, {
                ...state.measurements[index],
                quantity: strToNum(tempQuantity),
                length: updatedLength,
                cost: calculateCost(tempQuantity, values.gsrMarketRate === 'GSR' ? values.gsrUnitRate : values.mvUnitRate)
            });
        }

        if (field === 'breadth' && (e || e?.target?.value)) {
            let updatedBreadth = e.target.value;
            let tempQuantity = calculateQuantity(+values?.measurements[index]?.length || 0, +updatedBreadth, +values?.measurements[index]?.depth, +values?.measurements[index]?.nos || 0);
            _.set(state, `measurements[${index}]`, {
                ...state.measurements[index],
                quantity: strToNum(tempQuantity),
                breadth: updatedBreadth,
                cost: calculateCost(tempQuantity, values.gsrMarketRate === 'GSR' ? values.gsrUnitRate : values.mvUnitRate)
            });
        }

        if (field === 'depth' && (e || e?.target?.value)) {
            let updatedDepth = e.target.value;
            let tempQuantity = calculateQuantity(+values?.measurements[index]?.length || 0, +values?.measurements[index]?.breadth || 0, +updatedDepth, +values?.measurements[index]?.nos || 0);
            _.set(state, `measurements[${index}]`, {
                ...state.measurements[index],
                quantity: strToNum(tempQuantity),
                depth: updatedDepth,
                cost: calculateCost(tempQuantity, values.gsrMarketRate === 'GSR' ? values.gsrUnitRate : values.mvUnitRate)
            });
        }

        if (field === 'nos' && (e || e?.target?.value)) {
            let updatedNos = e.target.value;
            let tempQuantity = calculateQuantity(+values?.measurements[index]?.length || 0, +values?.measurements[index]?.breadth || 0, +values?.measurements[index]?.depth, +updatedNos);
            _.set(state, `measurements[${index}]`, {
                ...state.measurements[index],
                quantity: strToNum(tempQuantity),
                nos: updatedNos,
                cost: calculateCost(tempQuantity, values.gsrMarketRate === 'GSR' ? values.gsrUnitRate : values.mvUnitRate)
            });
        }

        if (field === 'quantity' && (e || e?.target?.value) && values?.lbdOrNonlbd === 'Non-LBD') {
            let updatedQuantity = e.target.value;

            _.set(state, `measurements[${index}]`, {
                ...state.measurements[index],
                quantity: updatedQuantity,
                cost: calculateCost(updatedQuantity, values.gsrMarketRate === 'GSR' ? values.gsrUnitRate : values.mvUnitRate)
            });
        }

        setValues(state);
    };

    useEffect(() => {
        setDropdownDataGsr((prev: any) => ({ ...prev, measurementType: dropdownData?.measurementType ?? [] }));
    }, [dropdownData, values?.mvUnitRate]);

    useEffect(() => {
        const unitRate = values.gsrMarketRate === 'MarketRate' ? values?.mvUnitRate : values.gsrUnitRate || 0;
        const currentQuantity = values.measurements?.reduce((acc: any, curr: any) => (acc = acc + strToNum(curr.quantity)), 0);
        setFieldValue('totalQuantity', currentQuantity?.toFixed(2));

        if (strToNum(currentQuantity) > 0 && strToNum(unitRate) > 0) {
            const tempCost = Math.ceil(currentQuantity) * unitRate;
            setFieldValue('cost', tempCost?.toFixed(2));
        }
    }, [values?.measurements, values?.mvUnitRate, show]);

    console.log('measurementROW', measurementData);

    const headerFields = !isViewOnly
        ? HEADER_FIELDS
        : HEADER_FIELDS.map((val: any) => ({
              ...val,
              formData: val.formData.map((item: any) => {
                  if (item.field !== 'projectName') {
                      return { ...item, class: 'col-sm-4' };
                  } else {
                      return { ...item };
                  }
              })
          }));

    const renderHeaderDetails = () => {
        return (
            <>
                <Card.Body className='px-3 pt-4'>
                    {headerFields.map((val: any) => (
                        <GenericFormCard {...val} dropdownOptions={dropdownData} />
                    ))}
                </Card.Body>
            </>
        );
    };

    const resetMeasurementForm = () => {
        setValues({
            ...values,
            length: '',
            breadth: '',
            depth: '',
            nos: '',
            quantity: '',
            itemDesc: '',
            gsrUuid: '',
            gsrUnitRate: '',
            mvUnitRate: '',
            measurementType: '',
            itemNo: '',
            unitOfMeasurement: '',
            gsrName: '',
            gsrItemNo: '',
            gsrYear: '',
            cost: '',
            itemType: 'Scheduled',
            gsrMarketRate: 'GSR',
            lbdOrNonlbd: 'LBD',
            measurements: [initialMeasurements],
            remarks: '',
            document: undefined,
            totalQuantity: null
        });

        onGsrChangeAction({ target: { value: 'GSR' } }, 'gsrMarketRate');
    };
    //-----------------------------------------------
    const renderMeasurementsDetails = () => {
        const measurementFields1 = fieldDisabled
            ? MEASUREMENT_DETAILS_I.map((val: any) => ({
                  ...val,
                  formData: [
                      ...val.formData.map((value: any) => ({
                          ...value,
                          isDisabled: true
                      }))
                  ]
              }))
            : MEASUREMENT_DETAILS_I;

        const measurementFields2 = fieldDisabled
            ? MEASUREMENT_DETAILS_II.map((val: any) => ({
                  ...val,
                  formData: [
                      ...val.formData.map((value: any) => ({
                          ...value,
                          isDisabled: true
                      }))
                  ]
              }))
            : MEASUREMENT_DETAILS_II;

        const updateFieldDisabled = (data: any[]) =>
            data.map((val: any) => ({
                ...val,
                formData: val.formData.map((value: any) => ({
                    ...value,
                    isDisabled: fieldDisabled
                }))
            }));

        const measurementsDetails = updateFieldDisabled(DPR_MEASUREMENT);
        const addMeasurement = () => {
            const val = _.cloneDeep(values);

            setIsEditing(false);
            const keysToDelete = [
                'lbdOrNonlbd',
                'gsrMarketRate',
                'length',
                'breadth',
                'depth',
                'nos',
                'quantity',
                'itemDesc',
                'gsrUuid',
                'gsrUnitRate',
                'mvUnitRate',
                'measurementType',
                'itemNo',
                'unitOfMeasurement',
                'gsrName',
                'gsrItemNo',
                'gsrYear',
                'itemType',
                'cost',
                'document',
                'remarks',
                'totalQuantity',
                'rowId'
            ];
            keysToDelete.forEach(key => {
                delete val[key];
            });
            setValues({ ...val, itemType: 'Scheduled', gsrMarketRate: 'GSR', lbdOrNonlbd: 'LBD', measurements: [initialMeasurements] });
            setTouched({});
            setShow(true);
            setDisabled(false);
            setOpenIndex(null);

            onGsrChangeAction({ target: { value: 'GSR' } }, 'gsrMarketRate');
        };
        const saveMeasurementRowData = async () => {
            try {
                await MEASUREMENT_VALIDATION(measurementData).validate(values, { abortEarly: false, context: values });
                setShow(false);
            } catch (validationError) {
                let errorField = getFirstErrorKey(errors);
                if (errorField) {
                    await setFieldTouched(errorField, true, true);
                }
                let errMsg = 'Please fill in all the required details!';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            const newUuid = uuidv4();
            const measurementRowData: any = {
                gsrMarketRate: values?.gsrMarketRate,
                lbdOrNonlbd: values?.lbdOrNonlbd,
                gsrItemNo: values?.gsrItemNo?.code,
                gsrYear: values?.gsrYear,
                gsrName: values?.gsrName?.code,
                gsrUnitRate: values?.gsrUnitRate,
                mvUnitRate: values?.mvUnitRate,
                itemDesc: values.gsrMarketRate === 'GSR' ? values.itemDesc?.label : values?.itemDesc,
                // itemDesc: values?.itemDesc, values.itemDesc?.label
                gsrUuid: values?.gsrUuid,
                unitRate: values?.gsrMarketRate === 'GSR' ? values?.gsrUnitRate : values?.mvUnitRate,
                measurementType: values?.measurementType?.name,
                measurementTypeCode: values?.measurementType?.code,
                itemType: values?.itemType,
                unitOfMeasurement: values?.unitOfMeasurement,
                nos: values?.nos,
                length: values?.length,
                breadth: values?.breadth,
                depth: values?.depth,
                quantity: values?.lbdOrNonlbd === 'Non-LBD' ? (+values?.quantity).toFixed(2) : values?.quantity,
                cost: values?.cost,
                rowId: isEditing ? values.rowId || values.uuid : newUuid,
                itemNo: values?.itemNo,
                measurements: values.measurements,
                document: values.document,
                remarks: values.remarks,
                uuid: values.rowId,
                gsrMasterDetailsModel: values.gsrMasterDetailsModel,
                totalQuantity: Math.ceil(values.totalQuantity).toFixed(2)
            };

            if (isEditing) {
                const updatedData = measurementData.map((data: any) => {
                    if (data.rowId === isEditing) {
                        return measurementRowData;
                    } else return data;
                });
                setMeasurementData([...updatedData]);
                setAbstractData([...updatedData]);
            } else {
                setMeasurementData([...measurementData, measurementRowData]);
                setAbstractData([...measurementData, measurementRowData]);
            }
            setShow(false);
        };

        const onDeleteMeasurementData = async (rowId = null) => {
            setShowDeleteModal(true);
            setRowDelId(rowId);
        };

        const handleModalDelete = async (rowId: any) => {
            // let newData = measurementData?.filter((data: any) => data.rowId !== rowId);
            let newData = measurementData?.map((data: any) => {
                if (data.rowId === rowId) {
                    data['isDelete'] = true;
                }
                return data;
            });
            setMeasurementData(newData);
            setAbstractData(newData);
            // setVisible(false);
            setShow(false);
            setShowDeleteModal(false);
            setIsEditing(false);
        };

        const onMeasurementDataEdit = async (data: any, status: boolean = false) => {
            const detailObj: any = measurementData.find((item: any, _: number) => item.rowId === data.rowId);
            const index: any = measurementData.findIndex((item: any, _: number) => item.rowId === data.rowId);
            setOpenIndex(index > -1 ? index + 1 : null);
            if (!detailObj) return;
            const formData = {
                ...detailObj,
                lbdOrNonlbd: detailObj.lbdOrNonlbd,
                gsrMarketRate: detailObj.gsrMarketRate,
                // measurementType: { code: detailObj?.measurementTypeCode, name: detailObj?.measurementType },
                measurementType: getDropdownSelectedObject(detailObj?.measurementTypeCode, dropdownData['measurementType']),

                gsrItemNo: { code: detailObj?.gsrItemNo, name: detailObj?.gsrItemNo },
                gsrYear: detailObj?.gsrYear,
                gsrName: { code: detailObj?.gsrName, name: detailObj?.gsrName },
                itemDesc: detailObj.gsrMarketRate === 'GSR' ? { value: detailObj?.itemDesc, label: detailObj?.itemDesc } : detailObj?.itemDesc,
                // itemDesc: detailObj?.itemDesc,
                gsrUuid: detailObj?.gsrUuid,
                // unitRate: detailObj.unitRate,
                rowId: detailObj.rowId || detailObj.uuid || null,
                itemType: detailObj?.itemType,
                mvUnitRate: detailObj?.mvUnitRate,
                gsrUnitRate: detailObj?.gsrUnitRate,
                // quantity: detailObj?.quantity,
                // nos: detailObj?.nos,
                // depth: detailObj?.depth,
                // breadth: detailObj?.breadth,
                // length: detailObj?.length,
                itemNo: detailObj.itemNo,
                unitOfMeasurement: detailObj.unitOfMeasurement,
                measurements: detailObj.measurements,
                document: detailObj.document,
                remarks: detailObj.remarks,
                totalQuantity: detailObj.totalQuantity
            };
            setIsEditing(detailObj.rowId || detailObj.uuid);
            setValues({ ...values, ...formData });
            // setVisible(true);
            setShow(true);
            setDisabled(status);
        };

        const onMeasurementView = (status: boolean, data: any) => {
            setDisabled(status);
            setFieldDisabled(true);
            onMeasurementDataEdit(data, status);
        };

        const renderButton = () => {
            return <>{values.isUpdatable ? t('ADD_MEASUREMENTS', '+ Add Measurement') : <></>}</>;
        };

        const updatedColumns = MEASUREMENT_TABLE_COLUMNS.map((col: any) => {
            if (col.type === 'action') {
                return {
                    ...col,
                    actions: values.isUpdatable ? ['edit', 'view', 'delete'] : ['view']
                };
            }
            return col;
        });

        return (
            <div className='px-3 pt-4 text-end '>
                <OwnershipTable
                    column={updatedColumns}
                    tableRowData={measurementData}
                    hideSearchBar={false}
                    deleteOwnershipDetails={onDeleteMeasurementData}
                    additionalMethod={onMeasurementDataEdit}
                    updateOwnershipDataStatus={onMeasurementView}
                    renderButton={renderButton}
                    onClickPopUp={addMeasurement}
                    searchBarPlaceholder={'Search'}
                    hideExportBtn={true}
                    updatable={values.isUpdatable}
                />

                <GenericPopupModal
                    isVisible={show}
                    primaryAction={saveMeasurementRowData}
                    secondaryAction={() => {
                        // setVisible(false);
                        resetMeasurementForm();
                    }}
                    size={'lg'}
                    primaryBtnTitle={t('Save', 'Save')}
                    secondaryBtnTitle={t('RESET', 'Reset')}
                    isSecondaryBtn={!fieldDisabled ? true : false}
                    isPrimaryBtn={!disabled}
                    title={`${openIndex ? (disabled ? 'View' : 'Edit') : 'Add'} Measurement`}
                    // subTitle={`${openIndex ? (disabled ? 'View' : 'Edit') : 'Add'} miscellaneous costs here`}
                    subTitle={'Values of the Measurements will be reflected at Abstract Cost'}
                    onClose={() => {
                        setFieldDisabled(false);
                        setShow(false);
                    }}
                    children={
                        <MeasurementChildren
                            values={values}
                            measurementFields1={measurementFields1}
                            measurementFields2={measurementFields2}
                            dropdownDataGsr={dropdownDataGsr}
                            onGsrChangeAction={onGsrChangeAction}
                            fieldDisabled={fieldDisabled}
                            measurementsDetails={measurementsDetails}
                            handleMeasurements={handleMeasurements}
                            dropDownValue={dropDownValue}
                            fetchOptions={fetchOptions}
                            autoSuggestionHandleChnage={autoSuggestionHandleChnage}
                        />
                    }
                />
                <DeletePopUpModal
                    secondaryAction={() => {
                        setShowDeleteModal(false);
                    }}
                    primaryAction={() => {
                        handleModalDelete(rowDelId);
                    }}
                    isVisible={showDeleteModal}
                    title={'Delete File?'}
                    img={DeleteWarning}
                    primaryBtnTitle={t('DELETE', 'Delete')}
                    secondaryBtnTitle={t('CANCEL', 'Cancel')}
                    children={
                        <>
                            <div className='p-4' style={{ backgroundColor: '#F5F5F6' }}>
                                <div className='font-inter fw-bold lh-22'>
                                    Are you sure you want to delete this file? <br />
                                    You can't undo this action!
                                </div>
                            </div>
                        </>
                    }
                />
            </div>
        );
    };

    const renderAbstractDetails = () => {
        return (
            <>
                <Card.Body className='px-3 pt-4'>
                    {
                        <OwnershipTable
                            column={ABSTRACT_TABLE_COLUMNS}
                            hideSearchBar={false}
                            hideExportBtn={false}
                            tableRowData={abstractData}
                            totalRow={'cost'}
                            totalRowText={t('TOTAL_COST_A', 'Total Cost (A) ')}
                            isDisabled={!values.isUpdatable}
                            searchBarPlaceholder={t('ABSTRACT_SEARCH_PARAM', 'Search by Item No., GSR No., Item description...')}
                        />
                    }
                </Card.Body>
            </>
        );
    };

    const renderContingenciesDetails = () => {
        const gstAmount = (estimatedTotalCost / 100) * 18;
        const totalContingencies = estimatedTotalCost + gstAmount;
        const classnames = 'font-14 lh-21 fw-500 text-dark-emphasis mb-1';
        const calculatedContingencies = [
            {
                title: 'Sub-total [Abstract Cost(A)+Contingencies(B)]',
                total: strToNum(estimatedTotalCost).toFixed(2),
                class: classnames
            },
            {
                title: 'GST Amount (18%) ',
                total: Math.round(gstAmount * 100) / 100,
                class: classnames
            },
            {
                title: 'Total Amount',
                total: Math.round(totalContingencies * 100) / 100,
                class: classnames
            },
            {
                title: 'Round off Total Amount',
                total: Math.ceil(totalContingencies).toFixed(2),
                class: 'font-16 lh-24 fw-semibold '
            }
        ];

        return (
            <>
                <div className='px-3 pt-4 text-end '>
                    <OwnershipTable
                        column={CONTINGENCIES_COLUMN}
                        tableRowData={contingenciesData}
                        hideSearchBar={true}
                        totalRow={'cost'}
                        totalRowText={`Total Contingencies`}
                        isDisabled={!values.isUpdatable}
                    />
                </div>
                <div className='py-3 mt-3 rounded-2 mx-4 pe-3 d-flex flex-column align-items-end  ' style={{ background: '#F4F4F4' }}>
                    {calculatedContingencies.map((val: any) => (
                        <div className='me-5 w-50 d-flex justify-content-between '>
                            <div className={`${val?.class}`}>{val?.title}</div>
                            <div className={`${val?.class} d-flex justify-content-between ms-5 w-25`}>
                                <span>:</span>
                                {convertToINR(val?.total)}
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    const renderTechnicalSanctionDetails = () => {
        return (
            <>
                <Form.Group>
                    <div className='col-lg-12 my-1 px-4 py-4'>
                        <Editor setContent={setContent} content={content} readOnly={!values.isUpdatable} />
                    </div>
                </Form.Group>
            </>
        );
    };

    const renderDocTable = () => {
        return (
            <>
                <div className='px-4 pt-4'>
                    <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tableData} additionalMethod={onRowClick} />
                </div>
            </>
        );
    };

    const updatedCorrespondence =
        responseData?.status === 'TECHNICAL_SENCTION_APPROVED' && !responseData?.adminApprovalDocRefId
            ? DPR_CORRESPONDENCE
            : [
                  ...DPR_CORRESPONDENCE,
                  {
                      title: `${isViewOnly ? 'E - Documents' : responseData?.adminApprovalDocRefId ? 'E - Administrative Approval' : 'E - Technical Sanction'}`,
                      field: 'technicalSanction'
                  }
              ];
    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {/* {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                Condition/Check list
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )} */}

                    {updatedCorrespondence.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A - Header' && openSection === 'header' && renderHeaderDetails()}
                                {val.title === 'B - Measurements' && openSection === 'measurements' && renderMeasurementsDetails()}
                                {val.title === 'C - Abstract' && openSection === 'abstract' && renderAbstractDetails()}
                                {val.title === 'D - Contingencies' && openSection === 'contingencies' && renderContingenciesDetails()}
                                {val.title === 'E - Technical Sanction' && openSection === 'technicalSanction' && renderTechnicalSanctionDetails()}
                                {val.title === 'E - Administrative Approval' && openSection === 'technicalSanction' && renderTechnicalSanctionDetails()}
                                {val.title === 'E - Documents' && openSection === 'technicalSanction' && renderDocTable()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default DprCorrespondence;

// measurementType: getDropdownSelectedObject(detailObj.cos, dropdownData['measurementType'] || []),

export const MeasurementChildren = ({
    values,
    measurementFields1,
    measurementFields2,
    onGsrChangeAction,
    dropdownDataGsr,
    fieldDisabled,
    measurementsDetails,
    handleMeasurements,
    dropDownValue,
    fetchOptions,
    autoSuggestionHandleChnage
}: any) => {
    return (
        <>
            <div style={{ padding: '10px 16px' }}>
                {measurementFields1?.map((val: any) => (
                    <GenericFormCard {...val} onChangeRadioAction={onGsrChangeAction} customHandleChange={onGsrChangeAction} onChangeAction={onGsrChangeAction} dropdownOptions={dropdownDataGsr} />
                ))}
                {values.gsrMarketRate === 'GSR' ? (
                    <div className='col-sm-12' style={{ paddingLeft: '13px', paddingRight: '13px' }}>
                        <SearchField
                            dropdownOptions={dropDownValue}
                            fetchOptions={fetchOptions}
                            customHandleChange={autoSuggestionHandleChnage}
                            field={'itemDesc'}
                            title={'Item Description'}
                            isRequired={true}
                            disabled={fieldDisabled}
                        />
                    </div>
                ) : (
                    <div className='col-sm-12' style={{ paddingLeft: '13px', paddingRight: '13px' }}>
                        <GenericCommonTextField field={'itemDesc'} isRequired={true} title={'Item Description'} isDisabled={fieldDisabled} />
                    </div>
                )}

                {measurementFields2?.map((val: any) => (
                    <GenericFormCard {...val} onChangeRadioAction={onGsrChangeAction} customHandleChange={onGsrChangeAction} onChangeAction={onGsrChangeAction} dropdownOptions={dropdownDataGsr} />
                ))}
                <Form>
                    <CardBody>
                        <FieldArray name='measurements'>
                            {({ remove, push }) => (
                                <div>
                                    {values.measurements?.length > 0 &&
                                        values.measurements.map((data: any, index_p: number) => (
                                            <div className='row' key={index_p}>
                                                <div className='col-sm-12 collapsible_box align-items-center pb-2'>
                                                    <input type='checkbox' id={`measurements.${index_p}`} name={`measurements.${index_p}`} defaultChecked={true} disabled={fieldDisabled} />
                                                    <label htmlFor={`measurements.${index_p}`} className='d-flex mx-3 collapsible_box_label align-items-center justify-content-between'>
                                                        New Measurement - {index_p + 1}
                                                    </label>
                                                    <div className='form_fields ps-1'>
                                                        {index_p !== 0 && values.isUpdatable && (
                                                            <div className='d-flex justify-content-end mx-3'>
                                                                {!fieldDisabled && <img style={{ cursor: 'pointer', zIndex: 1 }} onClick={() => remove(index_p)} src={DeleteIcon} alt='delete' />}
                                                            </div>
                                                        )}
                                                        {measurementsDetails.map((val: any, _: number) => (
                                                            <GenericFormCard fieldPath={`measurements.${index_p}`} {...val} onChangeAction={handleMeasurements} />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    {values.isUpdatable && (
                                        <div className='ms-3'>
                                            {!fieldDisabled && (
                                                <button className='col-sm-12 mt-2 mb-4  justify-content-center align-items-center noc_add_fields' type='button' onClick={() => push({})}>
                                                    + Add Measurement
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </FieldArray>
                        <div className='py-3 mt-1 rounded-2 pe-3  ' style={{ background: '#F4F4F4' }}>
                            <div className='me-5 d-flex justify-content-end '>
                                <div className='font-16 lh-21 text-dark-emphasis '>{t('TOTAL_QUANTITY', `Total Quantity`)}</div>
                                <div className='font-16 lh-21 text-dark-emphasis ms-5'>:</div>
                                <div className='font-16 lh-21 text-dark-emphasis ms-5 '>{values.totalQuantity ? convertToINR(values.totalQuantity) : 0}</div>
                            </div>
                            <div className='me-5 d-flex justify-content-end '>
                                <div className='font-16 lh-21 fw-500 text-dark-emphasis '>{t('ROUND_OFF_TOTAL_QUANTITY', `Round off Total Quantity`)}</div>
                                <div className='font-16 lh-21 fw-500 text-dark-emphasis ms-5'>:</div>
                                <div className='font-16 lh-21 fw-500 text-dark-emphasis ms-5 '>{values.totalQuantity ? convertToINR(Math.ceil(values.totalQuantity)) : 0}</div>
                            </div>
                        </div>
                    </CardBody>
                </Form>
            </div>
        </>
    );
};
