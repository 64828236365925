import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import basicDetails from '../../../../assets/logos/basicDetails.svg';
import search from '../../../../assets/logos/searchUser.svg';

export const TASK_REASSIGNMENT = [
    {
        titleCode: 'UPDATE_EMAIL_MOBILE',
        btnTitle: 'Update Basic Details',
        btnURL: '/goa-idc/task-reassignment/search',
        logo: basicDetails
    },
    {
        titleCode: 'MIGRATION_FORM_SEARCH',
        btnTitle: 'Migration Form Search',
        btnURL: '/goa-idc/migration/application-search',
        logo: search
    }
];

export const UPDATE_STEP_DETAILS = [
    {
        titleCode: 'ENTERPISE_DETAILS',
        name: 'Enterprise Details',
        index: 1,
        isActive: true
    }
];

export const TASKREASSIGNMENT_SEARCH_TABLE = [
    {
        titleCode: '',
        colName: '',
        fieldName: 'checkbox',
        clsName: 'text-center',
        id: 0
    },
    {
        titleCode: 'APPLICATION_TYPE',
        colName: 'Application Type',
        fieldName: 'applicationType',
        clsName: 'col-sm-2 font-14 column-text-start',
        id: 1
    },
    {
        titleCode: 'ENTITY_NAME',
        colName: 'Entity Name',
        fieldName: 'entityName',
        clsName: 'application-number column-text-start',
        id: 2
    },
    {
        titleCode: 'APPLICATION_NO',
        colName: 'Application No.',
        fieldName: 'applicationNo',
        clsName: 'application-number column-text-start',
        id: 3,
        isClickable: false
    },
    {
        titleCode: 'DATE_OF_SUBMISSION',
        colName: 'Date of Submission',
        fieldName: 'assignedDate',
        clsName: 'col-sm-2 column-text-start',
        id: 4,
        isDate: true
    },
    {
        titleCode: 'STATUS',
        colName: 'Status',
        fieldName: 'status',
        clsName: 'col-sm-2 column-text-start',
        id: 5
    },
    {
        titleCode: 'PENDING_WITH',
        colName: 'Pending With',
        fieldName: 'currentOwner',
        fieldName2: 'currentOwnerDesig',
        clsName: 'col-sm-2 column-text-start',
        id: 6
    },
    {
        titleCode: 'PENDENCY',
        colName: 'Pendency',
        fieldName: 'deskPendencyDays',
        clsName: 'col-sm-1 column-text-start',
        id: 7,
        toolTipDetails: {
            position: 'bottom',
            content: '<div>Pendency (No. of Days) </div>',
            variant: { color: 'white' },
            className: 'position-relative'
        }
    }
];
