import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../../components/header/Header.component';
import Loading from '../../../../../components/portalComponents/Loading.component';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { devUrl, endpoints, estateEndpoints, POST, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { check, cross } from '../../../../../utils/Logo';
import { t } from 'i18next';
import { FormikProvider, useFormik } from 'formik';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import { convertToINR, getCheckList, getHistory, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../../EstateManagement.controller';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { Card } from 'react-bootstrap';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../Allotment/Allotment.const';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../LeaseDeed/LeaseDeed.const';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericDocViewer from '../../../../../components/fileviewer/GenericDocViewer';
import axios from 'axios';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import LeaseExtensionWorkflowCorrespondence from './LeaseExtensionWorkflowCorrespondence';
import { EMPLOYEE_AUTHORIZED, TENANT_ID } from '../../../../../utils/constants';
import { getLesseeInitData } from '../../SubLease/SubLeaseConst';
import { getEntityDocumentDetails } from '../../../Migration/Migration.controller';
import { PlotDropdownData } from '../../../UserManagement/types';
import { getInitPlotData } from '../../Allotment/Allotment.controller';
import { EsignModal } from '../../../../commonPages/EsignModal';

export default function LeaseExtensionWorkflow() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [searchApplication, setSearchApplication] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [activeIndex, setActiveIndex] = useState(1);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [rowData, setRowData] = useState<any>([]);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [modalData, setModalData] = useState<any>(null);
    const [content, setContent] = useState('');
    const [isChecked, setIsChecked] = useState([true, true, true, true]);
    const [transactionDetails, setTransactionDetails] = useState<any>(null);
    const [paymentRowData, setPaymentRowData] = useState<any>([]);
    const [checklistTableData, setChecklistTableData] = useState();
    const [dropdownDataPlot, setDropdownDataPlot] = useState<PlotDropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });

    const [refId, setRefId] = useState<any>(null);
    const [approvedEsignData, setApprovedEsignData] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);

    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const applicationNumber = decodeURIComponent(searchParams.get('applicationNumber') || '');
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const totalPages = applicationNumber ? 3 : 1;

    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;

    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: false,
            esignOption: 'DSC'
        },
        onSubmit: () => {}
    });

    const { values, setValues } = formik;

    useEffect(() => {
        if (userType === 'EMPLOYEE') {
            (async () => {
                try {
                    setIsLoading(true);
                    if (applicationNumber) {
                        const { data: applicationSearch } = await POST(endpoints.searchAllotment + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                        const applicationData = applicationSearch?.estateApplications[0];
                        setSearchApplication(applicationData);

                        let { data }: any =
                            applicationData?.enterpriseEntity?.uuid &&
                            applicationData?.appliedPlotDetails.uuid &&
                            (await POST(endpoints.leaseDeedSearch + `?entityIds=${applicationData?.enterpriseEntity?.uuid}&plotIds=${applicationData?.appliedPlotDetails?.uuid}`, {
                                RequestInfo: requestInfo()
                            }));
                        const deedDoc = data?.deedDetails[0]?.executedIndentureRefId && (await getEntityDocumentDetails({ docReferenceId: data?.deedDetails[0]?.executedIndentureRefId }));

                        let { data: coCertificate } =
                            applicationData?.enterpriseEntity?.uuid &&
                            applicationData?.appliedPlotDetails.uuid &&
                            (await POST(
                                endpoints.commercialCertificateSearch + `?entityIds=${applicationData?.enterpriseEntity?.uuid}&plotIds=${applicationData?.appliedPlotDetails.uuid}&statuses=APPROVED`,
                                {
                                    RequestInfo: requestInfo()
                                }
                            ));
                        const commercialCertificate =
                            coCertificate?.commercialOpsDetails[0]?.docRefId && (await getEntityDocumentDetails({ docReferenceId: coCertificate?.commercialOpsDetails[0]?.docRefId }));

                        const historyData = await getHistory(applicationNumber);
                        setHistoryDetails(historyData);

                        const nextActions = await getNextActions(applicationNumber);
                        setNextValidAction(nextActions);

                        const relatedApplicationDocument = await POST(
                            estateEndpoints.relatedApplicationSearch +
                                `?entityIds=${applicationData.enterpriseEntity.uuid}&plotIds=${applicationData.appliedPlotDetails.uuid}&applicationNos=${applicationNumber}`,
                            { RequestInfo: requestInfo() }
                        );
                        setRelatedApplicationDocument(relatedApplicationDocument);

                        setContent(applicationData.content);

                        const lesseeData = await getLesseeInitData(applicationData);
                        const plotData = getInitPlotData(applicationData.appliedPlotDetails);

                        setValues((prev: any) => ({
                            ...prev,
                            ...lesseeData,
                            ...plotData,
                            extensionDuration: applicationData?.modificationDetails?.requestDetails?.duration,
                            leaseDeedExtensiondoc: deedDoc,
                            commericalyCertificate: commercialCertificate
                        }));

                        const getPaymentData = async () => {
                            try {
                                const data = await POST(
                                    endpoints.paymentDetailsSearch + `?paymentTypes=PROCESSING_FEE&plotIds=${applicationData.appliedPlotDetails.uuid}&applicationIds=${applicationData?.uuid}`,
                                    { RequestInfo: requestInfo() }
                                );
                                setTransactionDetails(data?.data?.payments[0]);
                                let payment = data?.data?.payments?.map((val: any, index: number) => ({
                                    feeTypeDisplayName: val.paymentTypeDisplayName,
                                    amount: convertToINR(val.principalAmount),
                                    cgstAmount: val.cgstPrincipal === 0 ? '--' : convertToINR(val.cgstPrincipal),
                                    sgstAmount: val.sgstPrincipal === 0 ? '--' : convertToINR(val.sgstPrincipal),
                                    total: convertToINR(val.paidAmount),
                                    index: index + 1
                                }));
                                setPaymentRowData(payment);
                            } catch (e: any) {
                                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                            }
                        };

                        applicationData && getPaymentData();
                    }
                    setIsLoading(false);
                } catch (e: any) {
                    setIsLoading(false);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }

        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }
    }, []);

    useEffect(() => {
        if (userType === 'EMPLOYEE') {
            (async () => {
                const formatedEntityDocuments = await correspondenceDocument();

                const reverseDocumentDetails = formatedEntityDocuments.reverse();
                const tableData = async () => {
                    const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                        return { ...data, serialNo: `C${ind + 1}` };
                    });
                    const reverseData = responseTableData.reverse();
                    setRowData(reverseData);
                };
                if (activeIndex === 2) {
                    tableData();
                }
            })();
        }
    }, [documentDetails, activeIndex]);

    const correspondenceDocument = async () => {
        const formatedEntityDocuments: any = [];
        const refIDs = documentDetails?.map((docItem: any, _: number) => docItem.docRefId).filter(Boolean);
        if (refIDs && refIDs.length) {
            const { data } = await axios.get(devUrl + '/filestore/v1/files/url', { params: { tenantId: 'ga', fileStoreIds: refIDs?.join() } });
            documentDetails?.map((doc: any, index: number) => {
                data.fileStoreIds.map((baseDoc: any) => {
                    if (baseDoc.id === doc.docRefId) {
                        formatedEntityDocuments.push({
                            ...doc,
                            documentUrl: baseDoc.url
                        });
                    }
                });
            });
        }
        return formatedEntityDocuments;
    };

    const handleModalClose = (type: string, isSaveOnly: boolean, isUnauthorized: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success' && !isSaveOnly && !isUnauthorized) {
            navigate('/goa-idc/dashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            }
        }
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            if (applicationNumber) {
                const docs: any = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction.businessService, historyDetails[0].id));
                setDocumentDetails(docs.documents);
                setDocumentsUuid([...documentsUuid, docs.uuid]);
                setActiveIndex(2);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleNextPage = async () => {
        const docs = applicationNumber && (await getWorkflowDocumentSearch(applicationNumber, nextValidAction.businessService));
        setDocumentDetails(docs);
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };
    const handlePrevPage = () => {
        if (activeIndex >= 2) {
            setActiveIndex(prevInd => prevInd - 1);
        }
    };

    const onRowClick = (row: any) => {
        const extension = (row?.docFileName || row?.tag)?.split('.').pop().toLowerCase();
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        if (extension === 'pdf') {
            setIsPDF(true);
        } else {
            setIsPDF(false);
        }
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };

    const onCancel = () => {
        navigate(-1);
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');

    useEffect(() => {
        if (note && action && searchApplication && nextValidAction) {
            onHandlePrimaryAction({ action: action }, '');
        }

        (async () => {
            if (nextValidAction) {
                const checklistData = await getCheckList(searchApplication?.enterpriseEntity?.uuid, searchApplication?.appliedPlotDetails?.uuid, nextValidAction?.businessService);
                setChecklistTableData(checklistData);
            }
        })();
    }, [searchApplication, nextValidAction]);

    const esign = async (payload: any, action: string) => {
        const userRequestInfo = requestInfo();
        try {
            let approveEsignRes = await POST(endpoints.esignApprove, payload);
            setRefId(approveEsignRes.data.entityRefDocuments[0].docReferenceId);
            const approvedEsignData = approveEsignRes.data.entityRefDocuments.map((item: any) => `${item.uuid}=${item.docReferenceId}`);
            setApprovedEsignData(approvedEsignData);
            setActionAfterEsign(action);
            setEsignModal(true);

            // if (window.location.protocol !== 'http:') {
            // let url =
            //     window.location.origin +
            //     `/goa-idc/estate/lease-term-extension/workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
            //         ','
            //     )}&action=${action}`;
            //     url = btoa(url);
            //     const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
            //         userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
            //     }`;
            //     await axios.get(esignUrl);

            //     window.open(esignUrl, '_self');
            // } else {
            //     let url =
            //         window.location.origin +
            //         `/goa-idc/estate/lease-term-extension/workflow?${searchParams.toString()}&signedFileStoreId=${refId}&note=${values.notes}&docsUuid=${documentsUuid?.join(
            //             ','
            //         )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
            //     window.open(url, '_self');
            // }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const signDocument = () => {
        let url =
            window.location.origin +
            `/goa-idc/estate/lease-term-extension/workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
                ','
            )}&action=${actionAfterEsign}`;
        url = btoa(url);
        // navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
        if (values.esignOption === 'EKYC') {
            const userRequestInfo = requestInfo();
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
        }
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean),
            estateApplicationRequest: {
                ...searchApplication,
                appliedPlotId: searchApplication?.appliedPlotDetails.uuid,
                assignedPlotId: searchApplication?.appliedPlotDetails.uuid,
                content: content
            },
            signedDocDetails:
                approvedEsign?.split(',').map((item: any) => {
                    const esignData = item.split('=');
                    return { [`${esignData[0]}`]: `${signedFileStoreId}` };
                }) || null
        };

        if (!values?.notes && !note) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please enter notes', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (!signedFileStoreId && status.isEsignRequired) {
            esign(payload, status.action);
            return;
        }
        try {
            await POST(endpoints.estateWorkflowInitiate, payload);
            const successMessage = getSuccessMessage(status?.action, applicationNumber);
            setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: successMessage, img: check, type: 'success', reUpload: 'OK' });
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const updateNotes = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            ...searchApplication,
            appliedPlotId: searchApplication?.appliedPlotDetails?.uuid,
            content: content
        };

        try {
            const { data } = await POST(endpoints.updateAllotment, payload);
            setContent(data.estateApplications[0].content);

            setModalDetails({ show: true, title: 'Success!', para: 'Data saved successfully', img: check, type: 'success', reUpload: 'OK', isSaveOnly: true });
        } catch (err: any) {
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log('formik', formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={t('A_PPLICATION_FOR_LEASE_TERM_EXTENSION', 'Application for Lease Term Extension')}
                            additionalClass='sub-text-style'
                            bottomSubText={`File : ${searchApplication?.enterpriseEntity.entityName}, Type : ${t('LEASE_TERM_EXTENSION', 'Lease Term Extension')}, No : ${applicationNumber}`}
                        />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('A_PPLICATION_FOR_LEASE_TERM_EXTENSION', 'Application for Lease Term Extension')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : ${t('LEASE_TERM_EXTENSION', 'Lease Term Extension')}, No : ${applicationNumber}`}
                                    />

                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={isViewOnly ? isViewOnly : !nextValidAction?.nextValidAction.length}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper designation={designation} applicationNumber={applicationNumber} nextValidAction={nextValidAction} />

                                <Card className='card_border p-1'>
                                    {activeIndex === 1 && (
                                        <LeaseExtensionWorkflowCorrespondence
                                            isChecked={isChecked}
                                            content={content}
                                            setContent={setContent}
                                            readOnly={isViewOnly ? isViewOnly : !nextValidAction?.isUpdatable}
                                            fileStoreId={searchApplication?.signedConsolidatedDocRefId}
                                            paymentRowData={paymentRowData}
                                            transactionDetails={transactionDetails}
                                            checkListRowData={checklistTableData}
                                            dropdownDataPlot={dropdownDataPlot}
                                            setDropdownDataPlot={setDropdownDataPlot}
                                        />
                                    )}
                                    {activeIndex === 2 && (
                                        <div className='p-4'>
                                            <GenericActionTable hideSearchBar={true} columns={CORRESPONDENCE_TABLE} rowData={rowData} additionalMethod={onRowClick} />
                                        </div>
                                    )}
                                    {activeIndex === 3 && (
                                        <div className='p-4'>
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        </div>
                                    )}
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>

                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                    </div>
                    {!isViewOnly ? (
                        <GenericFooterComponent
                            onCancel={onCancel}
                            onClickPrev={onChangePrevIndex}
                            activeIndex={activeIndex}
                            handleOnPrimaryAction={onHandlePrimaryAction}
                            onSubmit={() => {}}
                            applicationNo={applicationNumber || null}
                            nextValidAction={nextValidAction}
                            stepperLength={0}
                            extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                            extraButtonAction={updateNotes}
                        />
                    ) : (
                        <></>
                    )}

                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly, modalDetails?.isUnauthorized)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly, modalDetails?.isUnauthorized)}
                    />

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Document Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericDocViewer url={modalData.documentUrl} extension={'docx'} fileName={modalData.indenture} />}
                            </div>
                        </GenericPopupModal>
                    )}
                </>
            )}
        </>
    );
}
