import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { Card } from 'react-bootstrap';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { check, cross } from '../../../../utils/Logo';
import {
    CHANGE_DETAILS,
    CHANGE_IN_NAME,
    DROPDOWN_CHANGE,
    MISCELLANEOUS_SELF_DECLARATION_LIST,
    MISCELLANEOUS_SIDE_CARD,
    miscellaneousApplication,
    OWNERSHIP_CHANGE_DOCUMENTS,
    TRADE_CHANGE_DOCUMENTS
} from './Miscellaneous.cons';
import { FormikProvider, useFormik } from 'formik';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { PLOT_TABLE_COLUMNS } from '../SpecialTransfer/SpecialTransfer.const';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { devUrl, endpoints, estateEndpoints, getProgressBarData, POST, POST2, requestInfo, spTransferTypeDropdown } from '../../../../services/modulesServices/userManagement.api';
import GenericOwnerShipDetails from '../../../../components/Ownership/GenericOwnership';
import ProposedActivityComponent from '../Allotment/ProposedActivityDetails';
import { dropdownDataOptions, getFormatedOwnershipDetails, setEntityDocumentDetails } from '../../Migration/Migration.controller';
import { getFormatedNICDetails } from '../Allotment/Allotment.controller';
import Declaration from '../Allotment/Declaration';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import PaymentDetails from '../Allotment/PaymentDetails';
import { toast } from 'react-toastify';
import axios from 'axios';
import { fetchFeeData, fetchGatewayData, getNextActions, getOptions, getQueryParams, loadRazorpay, paymentCheck, paymentPayload } from '../EstateManagement.controller';
import { RazorpayOptions } from '../../UserManagement/types';
import { MISCELLANEOUS_VALIDATION, OWNERSHIP_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { getFirstErrorKey } from '../../../../utils/utils';
import PaymentSubmissionStatus from '../../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../../components/moduleCommonFormik/ProgressBar';
import { EMPLOYEE_AUTHORIZED, KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../../../utils/constants';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { EsignModal } from '../../../commonPages/EsignModal';

export default function MiscellaneousApplication() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [applicationNo, setApplicationNo] = useState<any>(null);
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [visited, setVisited] = useState<boolean>(false);
    const [column, setColumn] = useState<any>([]);
    const [selectedPlots, setSelectedPlots] = useState<any>([]);
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [finalOwnerShipDetails, setFinalOwnerShipDetails] = useState({
        applicantDetails: []
    });
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [calculated, setCalculated] = useState<boolean>(false);
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);
    const [nicDetails, setNicDetails] = useState<any>([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [enterpriseDetails, setEnterpriseDetails] = useState<any>(null);
    const [rowData, setRowData] = useState<any>(null);
    const [isChecked, setIsChecked] = useState([false, false, false]);
    const [eSignedDocumentData, setESignedDocumentData] = useState<any>(null);
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);
    const [updatedRowData, setUpdatedRowData] = useState<any>([]);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [paymentData, setPaymentData] = useState<any>([]);
    const [response, setResponse] = useState<any>(null);
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [isChangeInOwnership, setIsChangeInOwnership] = useState<boolean>(false);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>(null);

    const stepsDetails = MISCELLANEOUS_SIDE_CARD;
    const getUser = JSON.parse(localStorage.getItem('user') || '{}');
    const tooltipDetails: any = {
        ownershipDetails: {
            position: 'right',
            content: `<div class="d-flex flex-column font-inter" style="gap:5px;"><div class="mb-2">"Ownership Interest" means:</div> <div>(i)	holding of voting rights in a body corporate as defined under the Companies Act, 2013 (Act No. 18 of 2013); or</div>
            <div>(ii)	ownership of or entitlement to the capital or profits in a limited liability partnership, partnership firm, society or trust;</div></div>
            `
        },
        promoterDetails: {
            position: 'right',
            content: `<div class="d-flex flex-column font-inter" style="gap:5px;"><div class="mb-2">"Promoter" means:</div> <div>(i)	with respect to a body corporate, Person(s) holding, individually or collectively, Controlling Ownership Interest; or</div>
            <div>(ii)	with respect to a limited liability partnership or partnership firm, partner(s) collectively holding Controlling Ownership Interest; or</div>
            <div>(iii)	with respect to any other legal entity recognized under law, the constituent Person(s) collectively holding Controlling Ownership Interest;</div></div>
            `
        }
    };
    const [searchParams] = useSearchParams();
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const applicationNumber = decodeURIComponent(searchParams.get('applicationNumber') || '');
    let navigate = useNavigate();
    const location: any = useLocation();
    const queryParams = getQueryParams(searchParams);
    const userType = getUser?.type;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            individualOrOrganization: 'Individual',
            esignOption: 'DSC'
        },
        onSubmit: () => {},
        validationSchema: !showOwnershipModal ? MISCELLANEOUS_VALIDATION[`page${activeIndex}`] : OWNERSHIP_VALIDATION
    });

    const { values, setValues, setFieldValue, setFieldTouched } = formik;

    useEffect(() => {
        if (userType !== 'ENTERPRISE') {
            window.location.href = '/goa-idc/access-restricted';
        }

        if (applicationNumber) {
            (async () => {
                let applicationAccess;
                try {
                    applicationAccess = await POST(endpoints?.applicationAccess + `?businessid=${applicationNumber}`, { RequestInfo: requestInfo() });
                    if (!applicationAccess?.data.isAuthorized) {
                        window.location.href = '/goa-idc/access-restricted';
                    }
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }

        getPlotTableData();

        (async () => {
            const dropdownOption = await spTransferTypeDropdown();
            setDropdownData(dropdownOption);
        })();

        loadRazorpay();

        if (!!applicationNumber) {
            getProgressBarData(applicationNumber)
                .then(res => setProgressBarDetails(res.data))
                .catch(e => console.log(e));
        }
    }, []);

    useEffect(() => {
        if (enterpriseDetails && dropdownData && !applicationNumber) {
            autoPopulateTable(enterpriseDetails.owners, dropdownData);
        }
    }, [enterpriseDetails, dropdownData]);

    useEffect(() => {
        if (activeIndex === 2 && !signedFileStoreId) {
            fetchESignStatus();
        }
    }, [activeIndex, applicationNo]);

    useEffect(() => {
        (async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNo || applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                if (signedFileStoreId) {
                    const { data } = await POST(endpoints.consolidatedDocESign, payload);
                    setESignedDocumentData(data);
                    const consolidDocSearch = await POST(endpoints.consolidatedDocSearch, payload);
                    consolidDocSearch?.data.filestoreId && setESignedDocumentData(consolidDocSearch?.data);
                    !data.isEsignRequired && setIsChecked([true, true, true]);
                }
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [signedFileStoreId]);

    useEffect(() => {
        (async () => {
            if (activeIndex > 1 && selectedPlotDataId) {
                try {
                    const payCheck = await paymentCheck(
                        'PROCESSING_FEE',
                        selectedPlotDataId,
                        response?.uuid,
                        '',
                        values?.natureOfChange?.code === 'CHANGE_IN_OWNERSHIP_INTEREST' || values?.natureOfChange?.code === 'CHANGE_IN_NAME'
                    );
                    setIsPaid(payCheck?.isPaid);
                    setPaymentTried(payCheck?.paymentTried);
                    setPaymentData(payCheck?.paymentData);
                    setTotalPaidAmount(payCheck?.totalPaidAmount);
                    setTransactionDetails(payCheck?.transactionDetails);

                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);

                    const feeData = await fetchFeeData('EXTENSION', 'PROCESSING_FEE', selectedPlotDataId);
                    setPaymentData(feeData?.feeMasterDetails);
                    setTotalPaidAmount(feeData?.totalPaidAmount);
                    setPaymentIsLoading(false);
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: t('PAYMENT_FAILED', 'Payment Initiation Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }
        })();
    }, [activeIndex, selectedPlotDataId]);

    useEffect(() => {
        (async () => {
            if (queryParams?.['applicationNumber']) {
                queryParams?.['activeIndex'] && setActiveIndex(+queryParams?.['activeIndex']);

                try {
                    const { data: applicationSearchResponse } = await POST(endpoints.searchAllotment + `?applicationNos=${queryParams?.['applicationNumber']}`, { RequestInfo: requestInfo() });
                    const applicationData = applicationSearchResponse?.estateApplications[0];
                    if (applicationSearchResponse) {
                        setResponse(applicationData);
                        setApplicationNo(applicationData?.applicationNo);
                        setFileStoreId(applicationData?.signedConsolidatedDocRefId);

                        const dropdownOption = await spTransferTypeDropdown();

                        applicationData?.modificationDetails?.requestDetails?.owners &&
                            dropdownOption &&
                            autoPopulateTable(applicationData?.modificationDetails?.requestDetails?.owners, dropdownOption);

                        setIsChecked([true, true, true]);

                        const miscData = await miscellaneousApplication(applicationData?.modificationDetails, { natureOfChange: DROPDOWN_CHANGE });
                        console.log('miscDataaaaaaaaaaaaaaa', miscData);
                        setValues((prev: any) => ({
                            ...prev,
                            ...miscData,
                            updatedEnterpriseName: applicationData.modificationDetails.requestDetails.entityName,
                            plotUuid: applicationData?.modificationDetails?.requestDetails?.plotOrderRequests?.map((val: any) => val.plotId)
                        }));

                        console.log('applicationData?.modificationDetails?.requestDetails?.plotOrderRequests', applicationData?.modificationDetails?.requestDetails?.plotOrderRequests);
                        const plotIds = applicationData?.modificationDetails?.requestDetails?.plotOrderRequests?.map((val: any) => val.plotId);
                        console.log('plotsIds', plotIds);
                        setSelectedPlotDataId(plotIds);

                        let { data: plotData } = await POST(endpoints.entityRefDetails + `?entityIds=${applicationData?.enterpriseEntity?.uuid}&types=LESSEE`, { RequestInfo: requestInfo() });
                        if (plotData?.entityRefDetails?.length) {
                            let plotIds = plotData?.entityRefDetails?.map((item: any) => item.plotId);
                            plotIds = plotIds.join(',');

                            if (!plotIds) return;
                            let { data } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

                            let plotDataArray = data?.plots?.map((plot: any) => ({
                                estateName: plot?.estateDetails?.estateName,
                                plotType: plot?.plotType,
                                phase: plot?.phase,
                                plotNo: plot?.plotNo,
                                plotAreaSqm: plot?.plotAreaSqm,
                                uuid: plot?.uuid,
                                id: plot?.uuid
                            }));

                            changeTable(applicationData?.modificationDetails?.requestDetails.type, plotDataArray);
                        }

                        if (applicationData?.applicationSubType === 'CHANGE_IN_TRADE') {
                            const dropdownlist: any = await dropdownDataOptions();
                            const newProposed = await getFormatedNICDetails(applicationData?.modificationDetails?.requestDetails?.nicEnterpriseMappingRequests || [], dropdownlist);
                            setNicDetails(newProposed);
                        }
                    }
                } catch (e: any) {
                    setIsLoading(false);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: t('TRANSACTION_FAILED', 'Transaction Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }
        })();

        if (queryParams?.['plotId']) {
            setPaymentIsLoading(true);
            getTransactionUpdate(queryParams);
        }
    }, [location.search]);

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                const nextActions = await getNextActions(applicationNumber);
                setHasValidAction(nextActions?.nextValidAction?.length > 0);
                if (nextActions?.nextValidAction?.length === 0) {
                    setIsChecked([true, true, true]);
                }
                setFieldValue('isUpdatable', nextActions?.nextValidAction?.length > 0);
            })();
        }
    }, [applicationNumber]);

    const getPlotTableData = async () => {
        try {
            const { data: existingUser } = getUser.uuid && (await POST(endpoints.searchExistingUser + `?userUuid=${getUser.uuid}`, { RequestInfo: requestInfo() }));
            console.log('existingUser', existingUser);
            existingUser && setEnterpriseDetails(existingUser?.enterpriseEntity[0]);

            let { data: plotData } = await POST(endpoints.entityRefDetails + `?entityIds=${existingUser?.enterpriseEntity[0]?.uuid}&types=LESSEE`, { RequestInfo: requestInfo() });
            if (plotData?.entityRefDetails?.length) {
                let plotIds = plotData?.entityRefDetails?.map((item: any) => item.plotId);
                plotIds = plotIds.join(',');

                if (!plotIds) return;
                let { data } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

                let plotDataArray = data?.plots?.map((plot: any) => ({
                    estateName: plot?.estateDetails?.estateName,
                    plotType: plot?.plotType,
                    phase: plot?.phase,
                    plotNo: plot?.plotNo,
                    plotAreaSqm: plot?.plotAreaSqm,
                    uuid: plot?.uuid,
                    id: plot?.uuid
                }));
                setRowData(plotDataArray);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getTransactionUpdate = async (params: any) => {
        try {
            const paid: any = await paymentCheck(
                'PROCESSING_FEE',
                params?.['plotId'],
                params?.['applicationId'],
                '',
                params?.['subType'] === 'CHANGE_IN_OWNERSHIP_INTEREST' || params?.['subType'] === 'CHANGE_IN_NAME'
            );
            if (!paid.isPaid) {
                setIsLoading(true);
                const payload = { RequestInfo: requestInfo() };
                const { data } = await POST2(endpoints.transactionUpdate, payload, { eg_pg_txnid: params.eg_pg_txnid });
                if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                    setTransactionDetails(data?.Transaction?.[0]);
                    if (data?.Transaction?.[0]?.txnId) {
                        try {
                            const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                            if (resp.data.status === 'SUCCESSFUL') {
                                setModalDetails({ show: true, title: 'Success!', para: t('PAYMENT_SUCCESSFUL', 'Payment successful'), img: check, type: 'success', reUpload: 'OK' });

                                setIsPaid(true);
                            } else setIsPaid(false);
                            setIsLoading(false);
                            setPaymentIsLoading(false);
                        } catch (e: any) {
                            setIsPaid(false);
                            setIsLoading(false);
                            setPaymentIsLoading(false);
                        }
                    }
                } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                    setModalDetails({
                        show: true,
                        title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                        para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    // window.location.reload();
                }
                setIsLoading(false);
                setPaymentIsLoading(false);
            }
        } catch (e: any) {
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('TRANSACTION_FAILED', 'Transaction Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onPlotSelect = (selectedPlot: any) => {
        setSelectedPlotDataId(selectedPlot.uuid);
        setFieldValue('plotUuid', selectedPlot.uuid);
    };

    useEffect(() => {
        if ((values?.natureOfChange?.code === 'CHANGE_IN_TRADE' || response?.applicationSubType === 'CHANGE_IN_TRADE') && selectedPlotDataId) {
            const select = Array.isArray(selectedPlotDataId) ? selectedPlotDataId[0] : selectedPlotDataId;
            console.log('selected', select);
            const tempColumn = column?.map((val: any, index: number) => {
                if (index === 0) {
                    return { ...val, selectedValue: select };
                }
                return val;
            });
            setColumn(tempColumn);

            (async () => {
                if (!applicationNo) {
                    try {
                        const dropdownlist: any = await dropdownDataOptions();

                        const { data: enterpriseData } = await POST(endpoints.leaseSpecialProject + `?plotIds=${selectedPlotDataId}&entityIds=${getUser.entityId}`, { RequestInfo: requestInfo() });
                        console.log('enterpriseData', enterpriseData);
                        const newProposed = await getFormatedNICDetails(enterpriseData?.enterpriseDetails[0]?.nicEnterpriseMappings || [], dropdownlist);
                        setNicDetails(newProposed);
                    } catch (e: any) {
                        let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                        setModalDetails({ show: true, title: t('ERROR', 'Error'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    }
                }
            })();
        }
    }, [selectedPlotDataId]);

    const changeTable = (value: any, row: any) => {
        if (value === 'CHANGE_IN_TRADE') {
            const tempTable = PLOT_TABLE_COLUMNS?.filter((val: any) => val.fieldName !== 'checkbox');
            const radioTable = [{ colName: '', fieldName: 'radio', clsName: 'col-sm-1', id: 1 }, ...tempTable];
            const plotDetailsTableColumns = radioTable.map((val: any, index: number) => {
                if (index === 0) {
                    return {
                        ...val,
                        onRadioClick: onPlotSelect,
                        selectedValue: selectedPlotDataId
                    };
                }
                return val;
            });
            setColumn(plotDetailsTableColumns);
            setUpdatedRowData(row);
        } else if (value === 'CHANGE_IN_OWNERSHIP_INTEREST' || value === 'CHANGE_IN_NAME') {
            setColumn(PLOT_TABLE_COLUMNS);
            const tempData = row?.map((val: any) => ({ ...val, isChecked: true }));
            setUpdatedRowData(tempData);
        }
    };

    const onChangeAction = (e: any, field: any) => {
        if (field === 'natureOfChange') {
            changeTable(e.value, rowData);
            if (e.value === 'CHANGE_IN_TRADE') {
            } else if (e.value === 'CHANGE_IN_OWNERSHIP_INTEREST' || e.value === 'CHANGE_IN_NAME') {
                setSelectedPlotDataId(rowData?.map((val: any) => val.uuid));
            }
        }
    };

    const autoPopulateTable = async (owners: any, dropdown: any) => {
        if (owners) {
            const newOwnership = owners?.length && (await getFormatedOwnershipDetails(owners, dropdown));
            newOwnership && setOwnershipDetails(newOwnership);
            owners && setControllingOwnershipResponse(owners);
        }
    };

    const handleStepClick = async (index: number) => {
        const stepperLength = stepsDetails.length;
        if (index > activeIndex) {
            try {
                await MISCELLANEOUS_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({
                    show: true,
                    title: t('ERROR', 'Error!'),
                    para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'),
                    img: cross,
                    type: 'error',
                    reUpload: 'OK'
                });
            }
        } else setActiveIndex(index);
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const onCheckHandler = (checkedItems: any) => {
        setSelectedPlots(checkedItems);
    };

    const onOwnershipDetailsChange = (key: string, value: any) => {
        setFinalOwnerShipDetails({ ...finalOwnerShipDetails, [key]: value });
    };

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const fetchESignStatus = async () => {
        try {
            let payload = {
                RequestInfo: requestInfo(),
                applicationNumber: applicationNo || applicationNumber,
                filestoreId: signedFileStoreId || undefined
            };

            const { data } = await POST(endpoints.consolidatedDocSearch, payload);
            data.filestoreId && setESignedDocumentData(data);
            !data.isEsignRequired && setIsChecked([true, true, true]);
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const signDocument = async () => {
        let url = window.location.origin + `/goa-idc/estate/change-in-ownership-name-trade?activeIndex=${activeIndex}&applicationNumber=${applicationNo}`;
        url = btoa(url);

        if (values.esignOption === 'EKYC') {
            const userRequestInfo = requestInfo();
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${eSignedDocumentData.filestoreId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${eSignedDocumentData.filestoreId}&callbackUrl=${url}`);
        }
    };

    const handleClickSignDocument = () => {
        if (activeIndex === 2 && !isChecked.every((e: boolean) => e)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setEsignModal(true);
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const lastSubmit = (isSaveOnly?: boolean, status?: string, comment?: string) => {
        if (activeIndex === 3 && !isPaid) onPay();
        else onSubmit(isSaveOnly);
    };
    console.log('nic details ', nicDetails);

    const onSubmit = async (isSaveOnly?: boolean) => {
        const isFinalSubmit = activeIndex === stepsDetails?.length;

        let docs: any = [
            setEntityDocumentDetails(values.approvalOfRegistrarOfCompanies) || null,
            setEntityDocumentDetails(values.memorandumOfAssociation) || null,
            setEntityDocumentDetails(values.certificateFromRegistrarOfFirms) || null,
            setEntityDocumentDetails(values.RegCertificate) || null,
            setEntityDocumentDetails(values.enterpriseProjectReport) || null
        ].filter(Boolean);

        const payload = {
            RequestInfo: requestInfo(),
            applicationType: 'CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE',
            applicationSubType: values?.natureOfChange?.code,
            enterpriseEntity: enterpriseDetails,
            isFinalSubmit: isFinalSubmit,
            modificationDetails: {
                requestDetails: {
                    plotOrderRequests: values?.natureOfChange?.code === 'CHANGE_IN_TRADE' ? [{ plotId: selectedPlotDataId }] : selectedPlotDataId?.map((val: any) => ({ plotId: val })),
                    owners: values?.natureOfChange?.code === 'CHANGE_IN_OWNERSHIP_INTEREST' ? values?.ownership : undefined,
                    documents: docs,
                    type: values?.natureOfChange?.code,
                    nicEnterpriseMappingRequests:
                        values?.natureOfChange?.code === 'CHANGE_IN_TRADE'
                            ? nicDetails.map((e: any) => {
                                  return { nicMstId: e.nicMstId, proposedActivity: e.proposedActivity, nicMasterDetails: { uuid: e.nicMstId } };
                              })
                            : undefined,
                    entityName: values?.natureOfChange?.code === 'CHANGE_IN_NAME' ? values?.updatedEnterpriseName : undefined
                }
            },
            ...(response && response)
        };

        if (!selectedPlotDataId?.length && activeIndex === 1) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please select plots', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (values?.natureOfChange?.code === 'CHANGE_IN_OWNERSHIP_INTEREST' && (!values?.ownership || !isChangeInOwnership) && activeIndex === 1) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please update the Ownership Details', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            await MISCELLANEOUS_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false });
            try {
                const apiEndPoint = applicationNo ? endpoints.updateAllotment : endpoints.createAllotment;

                const { data: response } = await POST(apiEndPoint, payload);

                !isFinalSubmit && setResponse(response?.estateApplications[0]);
                !isFinalSubmit && setApplicationNo(response?.estateApplications[0]?.applicationNo);

                if (isFinalSubmit) {
                    setModalDetails({ show: true, title: 'Success!', para: 'Application created successfully', img: check, type: 'success', reUpload: 'OK', isFinalSubmit: isFinalSubmit });
                } else {
                    toast('Data saved successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                }

                !isFinalSubmit && setActiveIndex(activeIndex + 1);
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e: any) {
            let errorField = getFirstErrorKey(e);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ENTER_ALL_REQUIRED_FIELDS', 'Please fill all the required fields'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onPay = async () => {
        setPaymentIsLoading(true);
        let url =
            window.location.origin +
            `/goa-idc/estate/change-in-ownership-name-trade?activeIndex=${activeIndex}&applicationNumber=${applicationNo}&signedFileStoreId=${eSignedDocumentData.filestoreId}&plotId=${selectedPlotDataId}&applicationId=${response?.uuid}&subType=${values?.natureOfChange?.code}`;
        const periodTo = new Date(response?.auditDetails?.createdTime).setFullYear(new Date(response?.auditDetails?.createdTime).getFullYear() + 1);

        const payload = paymentPayload(
            response,
            values?.plotUuid?.length === 1 ? values?.plotUuid?.[0] : null,
            totalPaidAmount,
            url,
            'Change in Ownership, Name or Trade Fees',
            selectedGateway,
            response?.auditDetails?.createdTime,
            periodTo,
            paymentData
        );

        if (!totalPaidAmount) {
            setPaymentIsLoading(false);
            setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });

            return;
        }
        if (!selectedGateway) {
            setPaymentIsLoading(false);
            setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

            return;
        }

        try {
            if (response?.applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data: paymentData } = await POST(endpoints.paymentGateway, payload);
                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(
                        paymentData.txnAmount,
                        getUser,
                        'description',
                        paymentData?.additionalDetails.ORDER_ID,
                        paymentData.callbackUrl,
                        KEY_ID[selectedGateway]
                    );
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${paymentData.additionalDetails?.walletClientCode}&walletRequestMessage=${paymentData.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (paymentData && paymentData.redirectUrl) {
                    window.open(paymentData.redirectUrl, '_self');
                }
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setPaymentIsLoading(false);
            setModalDetails({ show: true, title: t('PAYMENT_FAILED', 'Payment Initiation Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onCancel = () => {
        navigate('/goa-idc/enterprisedashboard');
    };

    const onChangeNextIndex = () => {
        setActiveIndex(activeIndex + 1);
    };

    const handleModalClose = (type: string, isFinalSubmit: boolean, isUnauthorized?: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success' && isFinalSubmit && !isUnauthorized) {
            navigate('/goa-idc/enterprisedashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            } else if (userType === 'EMPLOYEE') {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    const status: any = progressBarDetails?.progressStatus?.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: applicationNumber,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };

    const renderChangeDetails = () => {
        return (
            <>
                <Card className='card_border'>
                    <Card.Body>
                        {CHANGE_DETAILS?.map((val: any) => (
                            <GenericFormCard {...val} dropdownOptions={{ natureOfChange: DROPDOWN_CHANGE }} customHandleChange={onChangeAction} />
                        ))}

                        <div className='mb-3'>{values?.natureOfChange?.code && renderPlotDetails()}</div>

                        {values?.natureOfChange?.code === 'CHANGE_IN_OWNERSHIP_INTEREST' ? (
                            <>
                                <GenericOwnerShipDetails
                                    showOwnershipModal={showOwnershipModal}
                                    setShowOwnershipModal={setShowOwnershipModal}
                                    changeOwnershipDetails={(values: any) => onOwnershipDetailsChange('applicantDetails', values)}
                                    values={values}
                                    dropdownData={dropdownData}
                                    setControllingOwnershipResponse={setControllingOwnershipResponse}
                                    controllingOwnershipResponse={controllingOwnershipResponse}
                                    allOwnerShipDetails={finalOwnerShipDetails}
                                    setOwnershipDetails={setOwnershipDetails}
                                    setCalculated={setCalculated}
                                    ownershipDetails={ownershipDetails}
                                    toolTipDetails={tooltipDetails}
                                    hideCard={true}
                                    isDisabled={!hasValidAction}
                                    setIsChangeInOwnership={setIsChangeInOwnership}
                                    skipFullOwnershipPercent={enterpriseDetails?.category === 'LTD_COMPANY'}
                                    // scrollNotRequired={true}
                                />
                                <Card.Body>
                                    {OWNERSHIP_CHANGE_DOCUMENTS?.map((val: any) => (
                                        <GenericFormCard {...val} />
                                    ))}
                                </Card.Body>
                            </>
                        ) : values?.natureOfChange?.code === 'CHANGE_IN_NAME' ? (
                            <>
                                {CHANGE_IN_NAME?.map((val: any) => (
                                    <GenericFormCard {...val} />
                                ))}
                            </>
                        ) : values?.natureOfChange?.code === 'CHANGE_IN_TRADE' && nicDetails.length ? (
                            <>
                                <ProposedActivityComponent isDisabled={!hasValidAction} nicDetails={nicDetails} setNicDetails={setNicDetails} showPopUp={showPopUp} setShowPopUp={setShowPopUp} />
                                {TRADE_CHANGE_DOCUMENTS?.map((val: any) => (
                                    <GenericFormCard {...val} />
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderPlotDetails = () => {
        return (
            <>
                <Card.Body>
                    <div className='ps-2 py-2 d-flex justify-content-between'>
                        <div className='formTitle m-0'>{t('PLOT_DETAILS', ' Plot Details')}</div>
                    </div>
                    <div className='pb-2'>
                        <GenericActionTable
                            // checkType={values?.natureOfChange?.code === 'CHANGE_IN_TRADE' || response?.applicationSubType === 'CHANGE_IN_TRADE' ? undefined : `multiCheck`}
                            columns={column}
                            searchBarPlaceholder={'SEARCH_BY_PLOT'}
                            rowData={updatedRowData}
                            onCheckHandler={onCheckHandler}
                            hideExportBtn={true}
                        />
                    </div>
                </Card.Body>
            </>
        );
    };

    const renderSelfDeclaration = () => {
        return (
            <>
                <Declaration
                    isChecked={isChecked}
                    declarationList={MISCELLANEOUS_SELF_DECLARATION_LIST}
                    handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                    signDocument={handleClickSignDocument}
                    eSignedDocumentData={eSignedDocumentData}
                    applicantName={'Lessee'}
                    isDisabled={!hasValidAction}
                    fileStoreId={fileStoreId}
                ></Declaration>
            </>
        );
    };

    const renderPaymentDetails = () => {
        return (
            <>
                <PaymentDetails
                    paymentData={paymentData}
                    selectedGateway={selectedGateway}
                    setSelectedGateway={setSelectedGateway}
                    isPaid={isPaid}
                    gatewayData={gatewayData}
                    transactionDetails={transactionDetails}
                    title={'Application for Change in Ownership, Name and Trade Fee'}
                ></PaymentDetails>
            </>
        );
    };

    console.log('formikkk', formik);
    console.log('column', column);
    console.log('rowData', updatedRowData);
    console.log('selectedPlotDataId', selectedPlotDataId);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={t('MISCELLANEOUS_APPLICATION', 'Application for Change in Ownership, Name or Trade')}
                            bottomSubText={applicationNo ? `Type : ${t(response?.applicationSubType)}, No : ${applicationNo}` : ''}
                            additionalClass={'sub-text-style'}
                        />

                        <div className='col-3 stickyCard-section'>
                            <div className='stickyCard' style={{ top: '110px' }}>
                                <GenericHeader
                                    text={t('MISCELLANEOUS_APPLICATION', 'Application for Change in Ownership, Name or Trade')}
                                    sideHeader={true}
                                    additionalClass='sub-text-style'
                                    bottomSubText={applicationNo ? `Type :${t(response?.applicationSubType)} , No : ${applicationNo}` : ``}
                                />
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column  row-gap-3'>
                                            <SideCard steps={stepsDetails} visited={visited} activeIndex={activeIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        <div className='col-9 form-section'>
                            {!!applicationNumber && applicationData.status !== 'Draft' && (
                                <Card className='card_border my-2'>
                                    <Card.Body>
                                        <PaymentSubmissionStatus data={applicationData} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                                    </Card.Body>
                                </Card>
                            )}

                            <FormikProvider value={formik}>
                                {activeIndex === 1 && renderChangeDetails()}
                                {activeIndex === 2 && renderSelfDeclaration()}
                                {activeIndex === 3 && (!paymentIsLoading ? renderPaymentDetails() : <Loading />)}
                                <GenericPopupModal
                                    title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                    primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                    secondaryBtnTitle={t('CLOSE', 'Close')}
                                    isSecondaryBtn={true}
                                    secondaryAction={() => setEsignModal(false)}
                                    primaryAction={signDocument}
                                    isVisible={esignModal}
                                    children={<EsignModal />}
                                />
                            </FormikProvider>
                        </div>
                    </div>

                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                            </div>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {hasValidAction ? (
                                    <>
                                        {activeIndex !== stepsDetails.length && <GenericButtons text={t('SAVE', 'Save')} handleClick={() => onSubmit(true)} ClassName='me-3' />}
                                        <GenericButtons
                                            text={
                                                activeIndex !== stepsDetails.length
                                                    ? t('SAVE_NEXT', `Save & Next`)
                                                    : isPaid
                                                    ? t('SUBMIT', `Submit`)
                                                    : paymentTried
                                                    ? t('RETRY_PAYMENT', 'Retry Payment')
                                                    : t('PAY_NOW', 'Pay Now')
                                            }
                                            variant='primary'
                                            handleClick={() => lastSubmit()}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {activeIndex === stepsDetails.length ? (
                                            <>
                                                <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={onCancel} />
                                            </>
                                        ) : (
                                            <>
                                                <GenericButtons text={t('NEXT', 'Next')} variant='primary' handleClick={onChangeNextIndex} />
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </footer>

                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.isFinalSubmit, modalDetails?.isUnauthorized)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isFinalSubmit, modalDetails?.isUnauthorized)}
                    />
                </>
            )}
        </>
    );
}
