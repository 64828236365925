import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../../components/header/Header.component';
import Loading from '../../../../../components/portalComponents/Loading.component';
import { endpoints, estateEndpoints, POST, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getLesseeInitData } from '../../SubLease/SubLeaseConst';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { FormikProvider, useFormik } from 'formik';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import { entityDocumentData, getCheckList, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../../EstateManagement.controller';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { check, cross } from '../../../../../utils/Logo';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import { Card } from 'react-bootstrap';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../Allotment/Allotment.const';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../LeaseDeed/LeaseDeed.const';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../../components/fileviewer/GenericImageViewer';
import { CommercialOperationCorrespondence } from './CommercialOperationCorrespondence';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import { EMPLOYEE_AUTHORIZED, TENANT_ID } from '../../../../../utils/constants';

export function CommercialOperationWorkflow() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [entityName, setEntityName] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState(1);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [rowData, setRowData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [modalData, setModalData] = useState<any>(null);
    const [checklistTableData, setChecklistTableData] = useState();
    const [response, setResponse] = useState<any>(null);

    const [searchParams] = useSearchParams();
    const applicationNumber = searchParams.get('applicationNumber');
    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const totalPages = applicationNumber ? 3 : 1;
    let navigate = useNavigate();

    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;

    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: false
        },
        onSubmit: () => {}
    });

    const { values, setValues } = formik;

    useEffect(() => {
        if (userType === 'EMPLOYEE') {
            (async () => {
                try {
                    setIsLoading(true);

                    const { data: applicationSearch } = await POST(endpoints.commercialCertificateSearch + `?applicationNumbers=${applicationNumber}`, { RequestInfo: requestInfo() });
                    const details = applicationSearch?.commercialOpsDetails[0];
                    setResponse(details);
                    const newDoc = details?.docRefId && (await getDocumentData(details?.docRefId));
                    const oldDoc = details?.previousCommercialOpsDetailsDocRefId && (await getDocumentData(details?.previousCommercialOpsDetailsDocRefId));
                    const oldExpiryDate = details?.previousCommercialOpsDetailsExpiryDate ? details?.previousCommercialOpsDetailsExpiryDate : '';

                    const { data: lesseeDetails } = await POST(endpoints.searchExistingUser + `?uuids=${details?.entityRefDetails?.entityId}`, {
                        RequestInfo: requestInfo()
                    });
                    const lesseeData = await getLesseeInitData({ enterpriseEntity: lesseeDetails?.enterpriseEntity[0] });
                    setEntityName(lesseeDetails?.enterpriseEntity[0]?.entityName);

                    const { data: plots } = await POST(estateEndpoints.plotSearch + `?plotUuids=${details?.entityRefDetails?.plotId}`, {
                        RequestInfo: requestInfo()
                    });
                    const plotData = plots?.plots[0];

                    const { data: processData } = await POST(`${endpoints.workflowSearch}&businessIds=${applicationNumber}`, { RequestInfo: requestInfo() });
                    setHistoryDetails(processData.ProcessInstances);

                    const nextActions = applicationNumber && (await getNextActions(applicationNumber));
                    setNextValidAction(nextActions);

                    const docs = applicationNumber && (await getWorkflowDocumentSearch(applicationNumber, nextActions?.businessService));
                    setDocumentDetails(docs);

                    const relatedApplicationDocument = await POST(
                        estateEndpoints.relatedApplicationSearch + `?entityIds=${details?.entityRefDetails?.entityId}&plotIds=${details?.entityRefDetails?.plotId}&applicationNos=${applicationNumber}`,
                        { RequestInfo: requestInfo() }
                    );
                    setRelatedApplicationDocument(relatedApplicationDocument);

                    const checklistData =
                        details?.entityRefDetails?.entityId &&
                        details?.entityRefDetails?.plotId &&
                        nextActions?.businessService &&
                        (await getCheckList(details?.entityRefDetails?.entityId, details?.entityRefDetails?.plotId, nextActions.businessService));
                    setChecklistTableData(checklistData);

                    setValues((prev: any) => ({
                        ...prev,
                        ...lesseeData,
                        estateName: plotData?.estateDetails?.estateName,
                        phase: plotData?.phase,
                        plotNo: plotData?.plotNo,
                        plotType: plotData?.plotType,
                        newCertificateOfCO: newDoc,
                        existingCertificateOfCO: oldDoc,
                        expiryDate: oldExpiryDate
                    }));

                    setIsLoading(false);
                } catch (e: any) {
                    setIsLoading(false);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }

        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }
    }, []);

    useEffect(() => {
        const tableData = async () => {
            const reverseDocumentDetails = documentDetails?.reverse();
            const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                return { ...data, serialNo: `C${ind + 1}` };
            });
            const reverseData = responseTableData.reverse();
            setRowData(reverseData);
        };
        if (activeIndex === 2) {
            tableData();
        }
    }, [documentDetails, activeIndex]);

    const getDocumentData = async (refId: any) => {
        const certificateOfCO = refId && (await getDocumentDetails(refId, 'COMMERCIAL_CERTIFICATE'));
        return certificateOfCO;
    };

    const getDocumentDetails = async (docRefId: any, docType: any) => {
        const documentData = (await entityDocumentData([{ docReferenceId: docRefId, documentType: docType }]))[0];
        return { ...documentData, documentName: documentData?.tag };
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            setIsLoading(true);
            const docs: any =
                applicationNumber && file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction?.businessService, historyDetails[0].id));
            setDocumentDetails(docs.documents);
            setDocumentsUuid([...documentsUuid, docs.uuid]);
            setIsLoading(false);
            setActiveIndex(2);
        } catch (e: any) {
            setIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string, isSaveOnly: boolean, isUnauthorized: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success' && !isSaveOnly) {
            navigate('/goa-idc/dashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            }
        }
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const payload = {
            RequestInfo: requestInfo(),
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes
                }
            ],
            isUpdatable: true,
            commercialOpsDetailsRequest: { ...response }
        };

        if (!values?.notes) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: 'Please enter notes!', image: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            await POST(endpoints.estateWorkflowInitiate, payload);
            let successMessage = getSuccessMessage(status?.action, applicationNumber);
            setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: successMessage, img: check, type: 'success', reUpload: 'OK' });
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const onCancel = () => {
        navigate(-1);
    };

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };

    const handleNextPage = () => {
        if (activeIndex < totalPages) {
            setActiveIndex(prevInd => prevInd + 1);
        }
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) {
            setActiveIndex(prevInd => prevInd - 1);
        }
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                        <GenericHeader
                            text={t('COMMERCIAL_OPERATION', 'Certificate of Commercial Operation')}
                            additionalClass='sub-text-style'
                            bottomSubText={`File : ${entityName}, Type : ${t('COMMERCIAL_OPERATION', 'Certificate of Commercial Operation')} , No : ${applicationNumber}`}
                        />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard'>
                                    <GenericHeader
                                        text={t('COMMERCIAL_OPERATION', 'Certificate of Commercial Operation')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : ${t('COMMERCIAL_OPERATION', 'Certificate of Commercial Operation')}, No : ${applicationNumber}`}
                                    />
                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                        disable={isViewOnly ? isViewOnly : !nextValidAction?.nextValidAction.length}
                                    />
                                </div>
                            </div>

                            <div className='col-sm-9 mt-3 form-section'>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />

                                <Card className='card_border p-1'>
                                    {activeIndex === 1 && <CommercialOperationCorrespondence checkListRowData={checklistTableData} response={response} />}

                                    {activeIndex === 2 && (
                                        <div className='p-4'>
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideSearchBar={true} rowData={rowData} additionalMethod={onRowClick} />
                                        </div>
                                    )}

                                    {activeIndex === 3 && (
                                        <div className='p-4'>
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        </div>
                                    )}
                                </Card>
                            </div>

                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>
                        </FormikProvider>
                    </div>

                    {!isViewOnly ? (
                        <GenericFooterComponent
                            onCancel={onCancel}
                            onClickPrev={onChangePrevIndex}
                            activeIndex={activeIndex}
                            handleOnPrimaryAction={onHandlePrimaryAction}
                            onSubmit={() => {}}
                            applicationNo={applicationNumber || null}
                            nextValidAction={nextValidAction}
                            stepperLength={0}
                        />
                    ) : (
                        <></>
                    )}

                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly, modalDetails?.isUnauthorized)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly, modalDetails?.isUnauthorized)}
                    />

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                </>
            )}
        </>
    );
}
