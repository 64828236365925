import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import NotingComponent from '../../../../components/Workflow/NotingComponent';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import ScrollToTop from '../../../../components/ScrollToTop';
import ExtCorrespondence from './ExtCorrespondence';
import NotingDocDownload from '../../../../components/Workflow/NotingDocDownload';
import CorrespondenceDocDownload from '../../../../components/Workflow/CorrespondenceDocDownload';
import { CORRESPONDENCE_TABLE } from '../Allotment/Allotment.const';
import { POST, devUrl, endpoints, estateEndpoints, fetchDropdownData, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getCheckList, getHistory, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../EstateManagement.controller';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { check, cross, warning } from '../../../../utils/Logo';
import { getExtensionInitialData } from './ExtensionController';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { EMPLOYEE_AUTHORIZED, TENANT_ID } from '../../../../utils/constants';
import { getApplicantInitData } from '../ApplicantDetailsWorkflow';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../LeaseDeed/LeaseDeed.const';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import { ALLOTED_PLOT_VALIDATION, ALLOTMENT_FORM_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import axios from 'axios';
import { EsignModal } from '../../../commonPages/EsignModal';

export default function ExtWorkflow(props: any) {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const { t } = useTranslation();
    const [tabelData, setTabelData] = useState<any>([]);
    const [searchParams] = useSearchParams();
    const [comment, setcomment] = useState('');
    const [content, setContent] = useState('');
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [extensionData, setExtensionData] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [dropdownData, setDropdownData] = useState<any>([]);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [plotData, setPlotData] = useState<any>([]);
    const [vacantPlotData, setVacantPlotData] = useState<any>([]);
    const [dropdownDataExtension, setDropdownDataExtension] = useState<any>({});
    const [allotmentDetails, setAllotmentdetails] = useState<any>(null);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [esignRequired, setEsignRequired] = useState<any>(true);
    const [eSignDone, setESignDone] = useState<boolean>(false);

    const [refId, setRefId] = useState<any>(null);
    const [approvedEsignData, setApprovedEsignData] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);

    const applicationNumber: any = searchParams.get('applicationNumber');
    let user = localStorage.getItem('currentUserAssignment');
    let navigate = useNavigate();
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const location: any = useLocation();
    const signedFileStoreId = searchParams.get('signedFileStoreId');

    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');

    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;

    const totalPages = applicationNumber ? 3 : 1;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            orderNo: '',
            emails: [{ emailId: '' }],
            esignOption: 'DSC',
            extensionType: ''
        },
        onSubmit: () => {},
        validationSchema: ALLOTED_PLOT_VALIDATION[`page${activeIndex}`]
    });

    useEffect(() => {
        setActiveIndex(1);

        if (userType === 'EMPLOYEE') {
            const dropdownPayload = {
                RequestInfo: {},
                MdmsCriteria: {
                    tenantId: 'ga',
                    moduleDetails: [
                        {
                            moduleName: 'common-masters',
                            masterDetails: [
                                {
                                    name: 'EntityCategory',
                                    filter: '[?(@.active == true)]'
                                }
                            ]
                        }
                    ]
                }
            };
            fetchDropdownData(dropdownPayload)
                .then((res: any) => {
                    const dropdownOptions = {
                        category: res['common-masters']['EntityCategory']
                    };
                    setDropdownData(dropdownOptions);
                })
                .catch((err: any) => console.log(err));
        }

        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }
    }, [applicationNumber]);

    console.log('dropdownData', dropdownData);

    useEffect(() => {
        const getApplicatInitData = async () => {
            const applicantInitData = await getApplicantInitData(extensionData, dropdownData);
            setValues({ ...values, ...applicantInitData });
        };
        if (userType === 'EMPLOYEE') {
            getApplicatInitData();
        }
    }, [dropdownData, extensionData]);

    useEffect(() => {
        const ApplicantAndPlotDetails = async () => {
            if (applicationNumber && dropdownData) {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.searchAllotment + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                    const estateData = data.estateApplications[0];
                    setExtensionData({ ...estateData, attachmentRefId: signedFileStoreId ? signedFileStoreId : data.estateApplications[0].attachmentRefId });
                    const historyData = await getHistory(applicationNumber);
                    setHistoryDetails(historyData);
                    const extensionInitData = await getExtensionInitialData(estateData);

                    setValues((prev: any) => ({
                        ...prev,
                        ...extensionInitData,
                        applicationSubType: estateData.applicationSubType,
                        allotedPlotNo: { name: estateData?.appliedPlotDetails?.plotNo, code: estateData?.appliedPlotDetails?.plotNo },
                        allotedPhase: { name: estateData?.appliedPlotDetails?.phase, code: estateData?.appliedPlotDetails?.phase },
                        allotedPlotType: { name: estateData?.appliedPlotDetails?.plotType, code: estateData?.appliedPlotDetails?.plotType },
                        allotedEstateName: { name: estateData?.appliedPlotDetails?.estateDetails.estateName, code: estateData?.appliedPlotDetails?.estateDetails.estateName },
                        allotedPlotArea: estateData?.appliedPlotDetails?.plotAreaSqm,
                        allotedPlotRate: estateData?.appliedPlotDetails?.plotRate,
                        allotedPlotId: estateData?.appliedPlotDetails?.uuid
                    }));
                    setContent(estateData.content);
                    setIsLoading(false);
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message || 'Something went wrong!';
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    setIsLoading(false);
                }
            }
        };
        if (userType === 'EMPLOYEE') {
            ApplicantAndPlotDetails();
        }
    }, [dropdownData]);

    useEffect(() => {
        if (documentDetails) {
            const reverseDocumentDetails = documentDetails.reverse();
            const tableData = async () => {
                const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${ind + 1}` };
                });
                const reverseData = responseTableData.reverse();
                setTabelData(reverseData);
            };
            if (activeIndex === 2) {
                tableData();
            }
        }
    }, [documentDetails, activeIndex, applicationNumber]);

    useEffect(() => {
        const getCorrespondenceDocument = async () => {
            try {
                const docs = await getWorkflowDocumentSearch(applicationNumber, nextValidAction?.businessService);
                setDocumentDetails(docs);
                const relatedApplicationDocument = await POST(
                    estateEndpoints.relatedApplicationSearch +
                        `?entityIds=${extensionData.entityRefDetails.entityId}&plotIds=${extensionData.entityRefDetails.plotId}&applicationNos=${applicationNumber}`,
                    { RequestInfo: requestInfo() }
                );
                setRelatedApplicationDocument(relatedApplicationDocument);
            } catch (e) {
                console.log(e);
            }
        };
        const getPlotDetails = async () => {
            try {
                const allotmentDetailsData = await POST(endpoints.allotmentDetailsSearch + `?entityIds=${extensionData?.enterpriseEntity?.uuid}`, { RequestInfo: requestInfo() });
                setAllotmentdetails(allotmentDetailsData?.data.allotmentDetails);

                if (designation === 'AM' && nextValidAction?.businessService === 'EST_EXTENSION_TIMELINE_EMPLOYEE') {
                    const filteredObject = allotmentDetailsData?.data.allotmentDetails.filter((val: any) => val.allotmentType === 'ALLOTMENT' || val.allotmentType === 'TRANSFER');
                    let plotIds = filteredObject?.map((item: any) => item.entityRefDetails.plotId);
                    plotIds = plotIds.join(',');

                    let plotData = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });
                    setPlotData(plotData.data.plots);

                    let plotDataArray = plotData.data.plots?.map((plot: any) => ({
                        allotedEstateName: plot?.estateDetails?.estateName,
                        allotedPlotType: plot?.plotType,
                        allotedPhase: plot?.phase,
                        allotedPlotNo: plot?.plotNo,
                        allotedPlotRate: plot?.plotRate,
                        allotedPlotArea: plot?.plotAreaSqm,
                        allotedUuid: plot?.uuid,
                        id: plot?.uuid
                    }));
                    setVacantPlotData(plotDataArray);

                    const allotmentTypes = allotmentDetailsData?.data.allotmentDetails.map((detail: any) => detail.allotmentType);
                    let newOptions: any = {
                        extensionType: []
                    };
                    if (allotmentTypes.includes('ALLOTMENT')) {
                        newOptions.extensionType.push({ name: 'Lease Deed Execution/Payment', code: 'Lease Deed Execution/Payment' });
                    }
                    if (allotmentTypes.includes('TRANSFER')) {
                        newOptions.extensionType.push({ name: 'Transfer Order', code: 'Transfer Order' });
                        newOptions.extensionType.push({ name: 'Special Transfer Order', code: 'Special Transfer Order' });
                    }
                    if (allotmentTypes.includes('SUB_LEASE')) {
                        newOptions.extensionType.push({ name: 'Provisional Sub-Lease Order', code: 'Provisional Sub-Lease Order' });
                    }
                    // if (allotmentTypes.includes('SPECIAL_TRANSFER')) {
                    //     newOptions.extensionType.push({ name: 'Special Transfer Order', code: 'Special Transfer Order' });
                    // }
                    setDropdownDataExtension(newOptions);
                    const uniqueAllotmentTypes = allotmentTypes.filter((val: any, index: number) => allotmentTypes.indexOf(val) === index);
                    if (uniqueAllotmentTypes?.length === 1) {
                        if (uniqueAllotmentTypes[0] === 'ALLOTMENT') {
                            setValues({ ...values, extensionType: { code: 'Lease Deed Execution/Payment', name: 'Lease Deed Execution/Payment' } });
                        } else if (uniqueAllotmentTypes[0] === 'SUB_LEASE') {
                            setValues({ ...values, extensionType: { code: 'Provisional Sub-Lease Order', name: 'Provisional Sub-Lease Order' } });
                        }
                    }
                }
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };
        if (userType === 'EMPLOYEE') {
            extensionData && getCorrespondenceDocument();
            extensionData && getPlotDetails();
        }
    }, [nextValidAction, extensionData, applicationNumber]);

    useEffect(() => {
        if (userType === 'EMPLOYEE') {
            if (!extensionData?.assignedPlotDetails) {
                (async () => {
                    try {
                        const nextActions = await getNextActions(applicationNumber);
                        setNextValidAction(nextActions);
                    } catch (e: any) {
                        let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                        setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    }
                })();
            }
        }
    }, [applicationNumber]);

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const { values, setValues } = formik;

    const handleNextPage = () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const handleModalClose = (type: string, isFinalSubmit?: boolean, isUnauthorized?: boolean) => {
        setModalDetails({});
        if (type === 'success' && isFinalSubmit) {
            navigate('/goa-idc/dashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            }
        }
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction.businessService, historyDetails[0].id));
            setDocumentDetails(docs.documents);
            setDocumentsUuid([...documentsUuid, docs.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const onCancel = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (userType === 'EMPLOYEE') {
            if (allotmentDetails) {
                const selectedApplicantDetails = allotmentDetails?.filter((item: any) => item.entityRefDetails.plotId === values.allotedPlotId);
                setValues({ ...values, orderNo: selectedApplicantDetails[0]?.allotmentId });
            }
        }
    }, [allotmentDetails, nextValidAction, values.allotedPlotId]);

    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');

    useEffect(() => {
        if (note && action && extensionData && nextValidAction && !eSignDone) {
            onHandlePrimaryAction({ action: action }, '');
            setESignDone(true);
        }
    }, [extensionData, nextValidAction]);

    const esign = async (payload: any, action: string) => {
        const userRequestInfo = requestInfo();
        try {
            let approveEsignRes = await POST(endpoints.esignApprove, payload);
            setEsignRequired(!!approveEsignRes.data?.entityRefDocuments?.length);
            if (!approveEsignRes.data?.entityRefDocuments?.length) {
                return true;
            } else {
                setRefId(approveEsignRes.data?.entityRefDocuments?.[0]?.docReferenceId);
                const approvedEsignData = approveEsignRes.data?.entityRefDocuments?.map((item: any) => `${item?.uuid}=${item?.docReferenceId}`);
                setApprovedEsignData(approvedEsignData);
                setActionAfterEsign(action);
                setEsignModal(true);
                return false;
            }
            // return;

            // if (window.location.protocol !== 'http:') {
            // let url =
            //     window.location.origin +
            //     `/goa-idc/estate/extensionworkflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
            //         ','
            //     )}&action=${action}`;
            //     url = btoa(url);
            //     const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
            //         userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
            //     }`;
            //     await axios.get(esignUrl);

            //     window.open(esignUrl, '_self');
            // } else {
            //     let url =
            //         window.location.origin +
            //         `/goa-idc/estate/extensionworkflow?${searchParams.toString()}&signedFileStoreId=${refId}&note=${values.notes}&docsUuid=${documentsUuid?.join(
            //             ','
            //         )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
            //     console.log('url', url, documentsUuid);
            //     window.open(url, '_self');
            // }
        } catch (e) {
            console.log('esign error', e);
        }
    };

    const signDocument = () => {
        let url =
            window.location.origin +
            `/goa-idc/estate/extensionworkflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
                ','
            )}&action=${actionAfterEsign}`;
        url = btoa(url);
        // navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);

        if (values.esignOption === 'EKYC') {
            const userRequestInfo = requestInfo();
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
        }
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const extensionPayload =
            designation === 'AM' && nextValidAction?.businessService === 'EST_EXTENSION_TIMELINE_EMPLOYEE'
                ? {
                      applicationSubType:
                          values.extensionType?.code === 'Lease Deed Execution/Payment'
                              ? 'ALLOTMENT'
                              : values.extensionType?.code === 'Transfer Order'
                              ? 'TRANSFER'
                              : values.extensionType?.code === 'Provisional Sub-Lease Order'
                              ? 'SUB_LEASE'
                              : values.extensionType?.code === 'Special Transfer Order'
                              ? 'SPECIAL_TRANSFER'
                              : undefined,
                      appliedPlotId: values.allotedPlotId,
                      parentOrderNumber: values.orderNo
                  }
                : {};

        const payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid ? docsUuid?.split(',') : null,
            estateApplicationRequest: {
                ...extensionData,
                appliedPlotId: extensionData?.appliedPlotDetails?.uuid,
                assignedPlotId: extensionData?.assignedPlotDetails?.uuid || values.allotedPlotId,
                modificationDetails:
                    designation === 'AM' && nextValidAction?.businessService === 'EST_EXTENSION_TIMELINE_EMPLOYEE'
                        ? {
                              uuid: extensionData?.modificationDetails?.uuid,
                              requestDetails: {
                                  documents: [
                                      {
                                          documentType: values.supportingdocument?.documentType,
                                          fileStoreId: values.supportingdocument?.docReferenceId
                                      }
                                  ],
                                  orderNo: values.orderNo,
                                  expiryDate: values.expiryDate?.split('-').reverse().join('/'),
                                  reason: values.comments
                              }
                          }
                        : extensionData?.modificationDetails,
                // content: content
                ...extensionPayload,
                content: content
            },
            signedDocDetails:
                approvedEsign?.split(',').map((item: any) => {
                    const esignData = item.split('=');
                    return { [`${esignData[0]}`]: `${signedFileStoreId}` };
                }) || null
        };

        if (designation === 'AM' && nextValidAction?.businessService === 'EST_EXTENSION_TIMELINE_EMPLOYEE') {
            if (
                !values.comments ||
                !values.extensionType ||
                !values.allotedEstateName ||
                !values.allotedPhase ||
                !values.allotedPlotNo ||
                !values.allotedPlotArea ||
                !values.expiryDate ||
                !values.orderNo
            ) {
                setModalDetails({ show: true, title: 'Error!', para: t('ENTER_ALL_REQUIRED_FIELDS', 'Please enter all the required fields!'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
        }

        if (!values.notes && !note) {
            if (!comment) {
                setModalDetails({ show: true, title: 'Error!', para: t('ENTER_NOTES', 'Please enter notes!'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            } else {
            }
        }

        if (!signedFileStoreId && esignRequired && status.isEsignRequired) {
            //esignRequired check from approveEsign api required??
            const notRequired = await esign(payload, status.action);
            if (!notRequired) return;
        }

        try {
            await POST(endpoints.estateWorkflowInitiate, payload);
            const successMessage = getSuccessMessage(status?.action, applicationNumber);
            setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: successMessage, img: check, type: 'success', reUpload: 'OK', isFinalSubmit: true });
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };

    const updateNotes = async () => {
        const extensionPayload =
            designation === 'AM' && nextValidAction?.businessService === 'EST_EXTENSION_TIMELINE_EMPLOYEE'
                ? {
                      applicationSubType:
                          values.extensionType?.code === 'Lease Deed Execution/Payment'
                              ? 'ALLOTMENT'
                              : values.extensionType?.code === 'Transfer Order'
                              ? 'TRANSFER'
                              : values.extensionType?.code === 'Provisional Sub-Lease Order'
                              ? 'SUB_LEASE'
                              : values.extensionType?.code === 'Special Transfer Order'
                              ? 'SPECIAL_TRANSFER'
                              : undefined,
                      appliedPlotId: values.allotedPlotId,
                      parentOrderNumber: values.orderNo
                  }
                : {};

        const payload = {
            RequestInfo: requestInfo(),
            ...extensionData,
            appliedPlotId: extensionData?.appliedPlotDetails?.uuid,
            assignedPlotId: extensionData?.assignedPlotDetails?.uuid || values.allotedPlotId,
            modificationDetails:
                designation === 'AM' && nextValidAction?.businessService === 'EST_EXTENSION_TIMELINE_EMPLOYEE'
                    ? {
                          uuid: extensionData?.modificationDetails?.uuid,
                          requestDetails: {
                              documents: [
                                  {
                                      documentType: values.supportingdocument?.documentType,
                                      fileStoreId: values.supportingdocument?.docReferenceId
                                  }
                              ],
                              orderNo: values.orderNo,
                              expiryDate: values.expiryDate?.split('-').reverse().join('/'),
                              reason: values.comments
                          }
                      }
                    : extensionData?.modificationDetails,
            // content: content
            ...extensionPayload,
            content: content
        };

        try {
            const { data } = await POST(endpoints.updateAllotment, payload);
            setContent(data.estateApplications[0].content);

            setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: t('DATA_SAVED_SUCCESSFULLY', 'Data saved successfully'), img: check, type: 'success', reUpload: 'OK' });
        } catch (err: any) {
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log('formik', formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={t('', `Application for ${extensionData?.applicationType === 'DEFAULT_TIMELINE_EXTENSION' ? 'Default ' : ''}Timeline Extension`)}
                            additionalClass='sub-text-style'
                            bottomSubText={`${t('File', `File : ${extensionData?.enterpriseEntity.entityName}`)},  ${t('Type', `Type : ${'Extension'}`)} ${
                                applicationNumber ? t('', `, No : ${applicationNumber}`) : ''
                            }`}
                        />
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('', `Application for ${extensionData?.applicationType === 'DEFAULT_TIMELINE_EXTENSION' ? 'Default ' : ''}Timeline Extension`)}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : Extension, No : ${applicationNumber}`}
                                    />
                                    <NotingWrapper
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={isViewOnly ? isViewOnly : !nextValidAction?.nextValidAction.length}
                                        text={comment}
                                        handleOnFileUpload={handleOnFileUpload}
                                        designation={designation}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>

                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />
                                <Form>
                                    {activeIndex === 1 && (
                                        <Card className='card_border p-1'>
                                            <ExtCorrespondence
                                                content={content}
                                                setContent={setContent}
                                                extensionData={extensionData}
                                                dropdownData={dropdownData}
                                                designation={designation}
                                                nextValidAction={nextValidAction}
                                                vacantPlotData={vacantPlotData}
                                                dropdownDataExtension={dropdownDataExtension}
                                                isViewOnly={isViewOnly}
                                                updateNotes={updateNotes}
                                            />
                                        </Card>
                                    )}
                                    {activeIndex === 2 && (
                                        <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                    )}
                                    {activeIndex === 3 && (
                                        <GenericActionTable
                                            columns={CORRESPONDENCE_TABLE_APP_FORM}
                                            hideSearchBar={true}
                                            rowData={relatedApplicationDocument?.data.applicationInfos}
                                            additionalMethod={onRowClickRedirect}
                                        />
                                    )}
                                </Form>
                            </div>

                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                        <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                            <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                            </span>
                            Page {activeIndex} of {totalPages}
                            <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                            </span>
                        </div>
                    </div>
                    <ScrollToTop />
                    {!isViewOnly ? (
                        <GenericFooterComponent
                            onCancel={onCancel}
                            onClickPrev={onChangePrevIndex}
                            activeIndex={activeIndex}
                            handleOnPrimaryAction={onHandlePrimaryAction}
                            onSubmit={() => {}}
                            applicationNo={applicationNumber || null}
                            nextValidAction={nextValidAction}
                            stepperLength={0}
                            extraButton={isViewOnly ? null : nextValidAction?.isUpdatable ? 'Save' : null}
                            extraButtonAction={updateNotes}
                        />
                    ) : (
                        <></>
                    )}
                </>
            )}

            {modalData && isFilePreviewModal && (
                <GenericPopupModal
                    title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                    primaryAction={() => {}}
                    secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                    isVisible={isFilePreviewModal}
                    isPrimaryBtn={false}
                    isSecondaryBtn={false}
                    size={'xl'}
                    class='file-viewer-popup'
                >
                    <div className='popup-children'>
                        {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                        {!isPDF && <GenericImageViewer />}
                    </div>
                </GenericPopupModal>
            )}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.isFinalSubmit, modalDetails?.isUnauthorized)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isFinalSubmit, modalDetails?.isUnauthorized)}
            />
        </>
    );
}
