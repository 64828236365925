import { FormikProvider, useFormik } from 'formik';
import HeaderComponent from '../../../../../components/header/Header.component';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import Loading from '../../../../../components/portalComponents/Loading.component';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import { Form, useNavigate, useSearchParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../../EstateManagement/Allotment/Allotment.const';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../../components/fileviewer/GenericImageViewer';
import ScrollToTop from '../../../../../components/ScrollToTop';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import { endpoints, POST, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { getHistory, onAttachDocument } from '../../../EstateManagement/EstateManagement.controller';
import { check, cross } from '../../../../../utils/Logo';
import { ContractClosureWorkflowCorrespondence } from './ContractClosureWorkflowCorrespondence';
import { HeaderInitialValues } from '../../ContractCreationScreens/ContractCreation.const';
import GenericButtons from '../../../../../components/UI components/Buttons/GenericButtons';
import { CONTRACT_CLOSURE_VALIDATION } from '../../../../../utils/formikValidation/FormikValidation';
import { getFirstErrorKey } from '../../../../../utils/utils';
import { USER_TYPE } from '../../../../../utils/constants';

const ContractClosureWorkflow = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState(1);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [tabelData, setTabelData] = useState<any>([]);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [dropdowanData, setDropdownData] = useState<any>(null);
    const [contractClosureDetails, setContractClosureDetails] = useState<any>(null);

    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const [searchParams] = useSearchParams();
    const applicationNumber: any = decodeURIComponent(searchParams.get('applicationNumber') || '');
    let navigate = useNavigate();
    const totalPages = applicationNumber ? 2 : 2;
    const users = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = users?.type;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: CONTRACT_CLOSURE_VALIDATION
    });

    const { values, setValues, setFieldTouched, errors } = formik;

    useEffect(() => {
        if (userType !== USER_TYPE.EMP) {
            window.location.href = '/goa-idc/access-restricted';
        }

        (async () => {
            try {
                setIsLoading(true);

                if (applicationNumber) {
                    const searchContractClosureResponse = await POST(endpoints.searchContractClosure + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                    const resData = searchContractClosureResponse?.data?.contractClosureDetails?.[0];
                    const dprHeaderDetails = searchContractClosureResponse?.data?.contractClosureDetails[0]?.contractDetails?.workOrderDetails?.loaDetails?.worksTenderDetails?.dprHeaderDetails;
                    setContractClosureDetails(resData);
                    const contractDetails = await HeaderInitialValues(resData?.contractDetails);

                    const contractPhase = resData?.contractPhaseList?.map((val: any) => {
                        return { name: val, code: val };
                    });

                    console.log('contractPhase', contractPhase);

                    setDropdownData({ contractPhase: contractPhase });

                    setValues((prev: any) => ({
                        ...prev,
                        ...contractDetails,
                        workOrderNumber: resData?.contractDetails?.workOrderDetails?.workOrderNo,
                        workOrderDate: resData?.contractDetails?.workOrderDetails?.workOrderDate,
                        projectName: dprHeaderDetails?.projectName,
                        estate: dprHeaderDetails?.estateMasterDetails?.estateName,
                        projectType: dprHeaderDetails?.projectType,
                        natureOfWork: dprHeaderDetails?.natureOfWork,
                        projectDuration: dprHeaderDetails?.periodConsumed
                    }));

                    // const { data } = await POST(endpoints.dprValidActions + `?businessId=${applicationNumber}&tenantId=ga`, {
                    //     RequestInfo: requestInfo()
                    // });
                    // setNextValidAction(data);

                    // if (data) {
                    getCorrespondenceDocument();
                    // }

                    const historyData = await getHistory(applicationNumber);
                    setHistoryDetails(historyData);
                }

                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    const handleOnFileUpload = async (file: any) => {
        try {
            const docs: any =
                file.referenceId &&
                (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction?.businessService, historyDetails?.length ? historyDetails[0].id : null, true));
            setDocumentDetails(docs?.documents);
            setDocumentsUuid([...documentsUuid, docs?.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    const getCorrespondenceDocument = async () => {
        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber}&businessService=${nextValidAction?.businessService}`, {
                RequestInfo: requestInfo()
            });
            setDocumentDetails(data?.documents);

            // const relatedApplicationDocument = await POST(endpoints.worksRelatedDocument + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
            // setRelatedApplicationDocument(relatedApplicationDocument);
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/dashboard');
    };

    const handleNextPage = async () => {
        // const docs = await getWorkflowDocumentSearch(allotmentAppNo, nextValidAction.businessService);
        // setDocumentDetails(docs);
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const onCancel = () => {
        navigate(-1);
    };

    const onHandlePrimaryAction = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            contractClosureDetails: { ...contractClosureDetails, comments: values?.comments, contractPhase: values?.contractPhase?.name, docReferenceId: values?.referenceDocument?.docReferenceId }
        };

        if (!values?.notes) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please enter notes', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            await CONTRACT_CLOSURE_VALIDATION.validate(values, { abortEarly: false, context: values });

            try {
                setIsLoading(true);

                await POST(endpoints.updateContractClosure, payload);
                setModalDetails({ show: true, title: 'Success!', para: t('CONTRACT_CLOSURE_SUBMITTED', 'Contract closure submitted successfully'), img: check, type: 'success', reUpload: 'OK' });

                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e: any) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log('Ta-Da', formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={t('CONTRACT_CLOSURE', 'Contract Closure')}
                            additionalClass='sub-text-style'
                            bottomSubText={`Type : Contract Closure, No : ${applicationNumber ? applicationNumber : ''} `}
                        />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('CONTRACT_CLOSURE', 'Contract Closure')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : Contract Closure, No : ${applicationNumber ? applicationNumber : ''} `}
                                    />
                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={true}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                {/* <div className='d-flex justify-content-between align-items-center'>
                                    <p className='correspondence-heading my-1'>Correspondence</p>
                                    <CorrespondenceDocDownload applicationNumber={allotmentAppNo} />
                                </div> */}

                                <CorrespondenceWrapper designation={designation} applicationNumber={applicationNumber} nextValidAction={nextValidAction} />

                                <Card className='card_border p-1'>
                                    {activeIndex === 1 && (
                                        <>
                                            <ContractClosureWorkflowCorrespondence dropdownData={dropdowanData} />
                                        </>
                                    )}
                                    {/* {activeIndex === 2 && (
                                        <div className='p-4'>
                                            <GenericActionTable hideSearchBar={true} columns={CORRESPONDENCE_TABLE} rowData={tabelData} additionalMethod={onRowClick} />
                                        </div>
                                    )} */}
                                </Card>
                            </div>
                            {/* <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === 2 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div> */}
                        </FormikProvider>
                    </div>
                    <ScrollToTop />
                    {/* <GenericFooterComponent
                        onCancel={onCancel}
                        onClickPrev={onChangePrevIndex}
                        activeIndex={0}
                        handleOnPrimaryAction={onHandlePrimaryAction}
                        onSubmit={onHandlePrimaryAction}
                        applicationNo={applicationNumber || null}
                        nextValidAction={nextValidAction}
                        stepperLength={0}
                    /> */}
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                <GenericButtons text={t('SUBMIT', 'Submit')} variant='primary' handleClick={() => onHandlePrimaryAction()} />
                            </div>
                        </div>
                    </footer>

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />
                </>
            )}
        </>
    );
};

export default ContractClosureWorkflow;
