import { isDisabled } from '@testing-library/user-event/dist/utils';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import { WO_CORRESPONDENCE } from '../ContractCreationScreens/ContractCreation.const';
import { ABSTRACT_TABLE_COLUMNS } from '../DPR creation screen/DPR.const';
import { LOA_DATA_COLUMN } from '../WorkOrderScreens/WorkOrder.const';
import _ from 'lodash';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';

export const SEARCH_CONTRACT_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'WORK_CONTRACT',
                title: 'Work Contract',
                field: 'workContract',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-5'
            },
            {
                titleCode: 'INDUSTRIAL_ESTATE',
                title: 'Industrial Estate',
                field: 'industrialEstate',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const RABILL_DATA_COLUMN: any = [
    LOA_DATA_COLUMN[0],
    {
        titleCode: 'CONTRACT_NO',
        colName: 'Contract No',
        fieldName: 'contractNo',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'VENDOR_NAME',
        colName: 'Vendor Name',
        fieldName: 'vendorName',
        clsName: 'column-text-start col-2'
    },
    ...LOA_DATA_COLUMN.slice(2)
];

export const RABILL_CORRESPONDENCE = [
    ...WO_CORRESPONDENCE.slice(0, 3),
    {
        title: '(D) MB & Bill',
        field: 'mbAndBill'
    },
    {
        title: '(E) Royalty On Minerals',
        field: 'royaltyOnMinerals'
    },
    {
        title: '(F) Bill Notes',
        field: 'notes'
    }
    // {
    //     title: '(G) Memorandum of Payment',
    //     field: 'MOP'
    // },
    // {
    //     title: '(H) Memorandum of Payment Notes',
    //     field: 'MOPNotes'
    // }
];

export const MOP_CORRESPONDENCE = [
    {
        title: '(G) Memorandum of Payment',
        field: 'MOP'
    },
    {
        title: '(H) Memorandum of Payment Notes',
        field: 'MOPNotes'
    }
];

export const RABILL_MEASURMENT_COLUMN = [
    ...ABSTRACT_TABLE_COLUMNS.slice(0, 5),
    {
        titleCode: 'ESTIMATED_QUANTITY',
        colName: 'Estimated Quantity',
        fieldName: 'estimatedQuantity',
        cellstyle: { width: '200px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'UP_TO_DATE_QUANTITY',
        colName: 'Up to date Quantity',
        fieldName: 'upToDateQuantity',
        cellstyle: { width: '200px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'PREVIOUS_BILL_QUANTITY',
        colName: 'Since Previous Bill Quantity',
        fieldName: 'sincePreviousQuantity',
        cellstyle: { width: '250px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'BALANCE_QUANTITY',
        colName: 'Balance Quantity',
        fieldName: 'balanceQuantity',
        cellstyle: { width: '200px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'MEASURED_VALUE',
        colName: 'Measured Value (INR)',
        fieldName: 'measuredValue',
        cellstyle: { width: '200px' },
        clsName: 'column-text-start'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg'
    }
];

export const POPUP_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'ITEM_NO',
                title: 'Item No.',
                field: 'itemNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'ITEM_DESCRIPTION',
                title: 'Item Description',
                field: 'itemDesc',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Measurement Type',
                field: 'lbdOrNonlbd',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: 'LBD', title: 'LBD' },
                    { value: 'Non-LBD', title: 'Non-LBD' }
                ],
                isDisabled: true
            },
            {
                title: 'Type',
                field: 'type',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true,
                renderCondition: (values: any) => !_.get(values, `lbdOrNonlbd`) || _.get(values, `lbdOrNonlbd`) === 'LBD'
            },
            {
                titleCode: 'MV_UNIT_RATE',
                title: 'Unit Rate(INR)',
                field: 'mvUnitRate',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'ESTIMATED_QUANTITY',
                title: 'Estimated Quantity',
                field: 'estimatedQuantity',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'UNIT_OF_MEASUREMENT',
                title: 'Unit of Measurement',
                field: 'unitOfMeasurement',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'SINCE_PREVIOUS_QUANTITY',
                title: 'Since Previous  Quantity',
                field: 'sincePreviousQuantity',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'UP_TO_DATE_QUANTITY',
                title: 'Up to Date Quantity',
                field: 'upToDateQuantity',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            // {
            //     titleCode: 'LAST_APPROVED_QUANTITY',
            //     title: 'Last Approved Quantity',
            //     field: 'lastApprovedQuantity',
            //     type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
            //     isDisabled: true
            // },
            {
                titleCode: 'BALANCE_QUANTITY',
                title: 'Balance Quantity',
                field: 'balanceQuantity',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'MEASURED_VALUE',
                title: 'Measured Value (INR)',
                field: 'measuredValue',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const MEASUREMENT_ENTRY_FIELD = [
    {
        formData: [
            {
                titleCode: 'MEASUREMENT_DATE',
                title: 'Measurement Date',
                field: 'measurementDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                class: 'col-6',
                isRequired: true,
                disableCondition: (values: any, index: any) => (_.get(values, `measurementEntry[${index}].refRaBillId`) ? false : true)
            }
        ]
    },
    {
        formData: [
            {
                titleCode: 'ITEM_NO',
                title: 'Nos',
                field: 'itemNos',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'width-20',
                disableCondition: (values: any, index: any) => (_.get(values, `measurementEntry[${index}].refRaBillId`) || _.get(values, `lbdOrNonlbd`) === 'Non-LBD' ? false : true)
            },
            {
                titleCode: 'LENGTH',
                title: 'Length (in meters)',
                field: 'lengthInMeters',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'width-20',
                disableCondition: (values: any, index: any) => (_.get(values, `measurementEntry[${index}].refRaBillId`) || _.get(values, `lbdOrNonlbd`) === 'Non-LBD' ? false : true)
            },
            {
                titleCode: 'BREADTH',
                title: 'Breadth (in meters)',
                field: 'breadthInMeters',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'width-20',
                disableCondition: (values: any, index: any) =>
                    _.get(values, `measurementEntry[${index}].refRaBillId`) || _.get(values, `lbdOrNonlbd`) === 'Non-LBD' || _.get(values, 'type')?.code === 'RUNNING_METER' ? false : true
            },
            {
                titleCode: 'DEPTH',
                title: 'Depth (in meters)',
                field: 'depthInMeters',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'width-20',
                disableCondition: (values: any, index: any) =>
                    _.get(values, `measurementEntry[${index}].refRaBillId`) ||
                    _.get(values, `lbdOrNonlbd`) === 'Non-LBD' ||
                    _.get(values, 'type')?.code === 'RUNNING_METER' ||
                    _.get(values, 'type')?.code === 'AREA'
                        ? false
                        : true
            },
            {
                titleCode: 'QUANTITY',
                title: 'Quantity',
                field: 'itemQuantity',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'width-20',
                disableCondition: (values: any, index: any) => (_.get(values, `measurementEntry[${index}].refRaBillId`) || _.get(values, `lbdOrNonlbd`) === 'LBD' ? false : true)
            },

            {
                titleCode: 'REMARKS',
                title: 'Remarks',
                field: 'remarks',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                disableCondition: (values: any, index: any) => (_.get(values, `measurementEntry[${index}].refRaBillId`) ? false : true)
            },
            {
                titleCode: 'SUPPORTING_DOCUMENT',
                title: 'Supporting Document',
                field: 'document',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: DOCUMENT_TYPE.OTHER
                },
                disableCondition: (values: any, index: any) => (_.get(values, `measurementEntry[${index}].refRaBillId`) ? false : true)
            }
        ]
    }
];

export const measurmentInitialField = {
    measurementDate: null,
    itemNos: null,
    lengthInMeters: null,
    breadthInMeters: null,
    depthInMeters: null,
    itemQuantity: null
};

export const RA_BILL_TYPE_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'IS_FINAL_BILL',
                title: 'Is Final Bill',
                field: 'isFinalBill',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                titleCode: 'BILL_NAME',
                title: 'Bill Name',
                field: 'billName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'CONTRACT_CLOSURE_DATE',
                title: 'Contract Closure Date',
                field: 'contractClosureDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                renderCondition: (value: any) => _.get(value, `isFinalBill`),
                isRequired: true
            }
        ]
    }
];

export const RA_BILL_SEARCH_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'MB_BILL_APPLICATION_NUMBER',
                title: 'Enter MB & Bill Application Number',
                field: 'raBillApplicationNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-5'
            },
            {
                titleCode: 'INDUSTRIAL_ESTATE',
                title: 'Industrial Estate',
                field: 'industrialEstate',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            }
        ]
    }
];

export const RA_BILL_SEARCH_TABLE: any = [
    {
        titleCode: 'SERIAL_NO',
        colName: 'S.N.',
        fieldName: 'serialNo',
        clsName: 'column-text-start col-1'
    },
    {
        titleCode: 'MB_BILL_NO',
        colName: 'MB & Bill No.',
        fieldName: 'raBillNo',
        clsName: 'column-text-start col-2',
        isClickable: true
    },
    {
        titleCode: 'VENDOR_NAME',
        colName: 'Vendor Name',
        fieldName: 'vendorName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'PROJECT_NAME',
        colName: 'Project Name',
        fieldName: 'projectName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'INDUSTRIAL_NAME',
        colName: 'Industrial Name',
        fieldName: 'industrialName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'PROJECT_TYPE',
        colName: 'Project Type',
        fieldName: 'projectType',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'ESTIMATE_AMOUNT',
        colName: 'Estimate Amount',
        fieldName: 'estimateAmount',
        clsName: 'column-text-start col-1'
    }
];

export const ROYALTY_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'TOTAL_ROYALTY_ON_MINERALS_AMOUNT',
                title: 'Total Royalty Amount',
                field: 'totalRoyaltyOnMinerals',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-6 me-3'
            },
            {
                titleCode: 'ALREADY_PAID_ROYALTY_AMOUNT',
                title: 'Already paid Royalty Amount',
                field: 'alreadyPaidRoyaltyAmount',
                class: 'col-6',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD
            },
            {
                titleCode: 'SUPPORTING_DOCUMENT',
                title: 'Supporting Document for Royalty Payment',
                field: 'paidRoyaltyDoc',
                class: 'col-6',
                type: CONSTANT_DATA_FIELD.FILE_FIELD
            },
            {
                titleCode: 'ROYALTY_AMOUNT_ADJUSTED_IN_BILL',
                title: 'Royalty Amount adjusted in Current Bill',
                field: 'adjustedRoyaltyAmount',
                class: 'col-6',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const MOP_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'TOTAL_VALUE_OF_MEASURED_WORK_SINCE_PREVIOUS',
                title: 'Total value of Measured work since previous',
                field: 'netValueOfWork',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'TOTAL_UP_TO_DATE_PAYMENT',
                title: 'Total Up-to Date Payment',
                field: 'totalUpToDatePayment',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'TOTAL_UP_TO_DATE_PAYMENT_TILL_LAST_BILL',
                title: 'Total Up-to-Date Payment till Last Bill',
                field: 'totalUpToDatePaymentTillLastBill',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'TOTAL_UP_TO_DATE_PAYMENT_SINCE_LAST_BILL',
                title: 'Total Up-to-Date Payment since Last Bill',
                field: 'totalUpToDatePaymentSinceLastBill',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'CGST_9%',
                title: 'CGST(9%)',
                field: 'cgstMeasuredValue',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'SGST_9%',
                title: 'SGST(9%)',
                field: 'sgstMeasuredValue',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'PASSED_BILL_AMOUNT',
                title: 'Passed Bill Amount',
                field: 'passedBillAmount',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const MOP_CLAIMABLE_TABLE_COLUMN = [
    {
        titleCode: 'DEDUCTION_NAME',
        colName: 'Deduction Name',
        fieldName: 'name',
        cellstyle: { width: '220px' },
        clsName: 'column-text-start',
        type: 'select'
    },
    {
        titleCode: 'DEDUCTION_TYPE',
        colName: 'Deduction Type',
        fieldName: 'type',
        cellstyle: { width: '220px' },
        clsName: 'column-text-start',
        type: 'select'
    },
    {
        titleCode: 'DEDUCTION_RATE',
        colName: 'Deduction Rate',
        fieldName: 'rate',
        cellstyle: { width: '220px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'DEDUCTION_AMOUNT',
        colName: 'Deduction Amount',
        fieldName: 'amount',
        cellstyle: { width: '220px' },
        clsName: 'column-text-start'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['edit', 'view']
    }
];

export const TAX_TABLE_COLUMN = [
    {
        titleCode: 'DEDUCTION_NAME',
        colName: 'Deduction Name',
        fieldName: 'name',
        cellstyle: { width: '220px' },
        clsName: 'column-text-start',
        type: 'select'
    },
    {
        titleCode: 'DEDUCTION_TYPE',
        colName: 'Deduction Type',
        fieldName: 'type',
        cellstyle: { width: '220px' },
        clsName: 'column-text-start',
        type: 'select'
    },
    {
        titleCode: 'DEDUCTION_RATE',
        colName: 'Deduction Rate',
        fieldName: 'rate',
        cellstyle: { width: '220px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'DEDUCTION_AMOUNT',
        colName: 'Deduction Amount',
        fieldName: 'amount',
        cellstyle: { width: '220px' },
        clsName: 'column-text-start'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['edit', 'view']
    }
];

export const MOP_CLAIMABLE_POPUP = [
    {
        formData: [
            {
                titleCode: 'DEDUCTION_NAME',
                title: 'Deduction Name',
                field: 'reclaimable.name',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            },
            {
                titleCode: 'DEDUCTION_TYPE',
                title: 'Deduction Type',
                field: 'reclaimable.type',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            },
            {
                titleCode: 'DEDUCTION_RATE',
                title: 'Deduction Rate',
                field: 'reclaimable.rate',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                disableCondition: (values: any, index: any) => !(_.get(values, 'reclaimable.type')?.code !== 'RATE')
            },
            {
                titleCode: 'DEDUCTION_AMOUNT',
                title: 'Deduction Amount',
                field: 'reclaimable.amount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                disableCondition: (values: any, index: any) => !(_.get(values, 'reclaimable.type')?.code === 'RATE')
            }
        ]
    }
];

export const MOP_TAX_POPUP = [
    {
        formData: [
            {
                titleCode: 'NAME',
                title: 'Name',
                field: 'tax.name',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            },
            {
                titleCode: 'TYPE',
                title: 'Type',
                field: 'tax.type',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            },
            {
                titleCode: 'RATE',
                title: 'Rate',
                field: 'tax.rate',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                disableCondition: (values: any, index: any) => !(_.get(values, 'tax.type')?.code !== 'RATE')
            },
            {
                titleCode: 'AMOUNT',
                title: 'Amount',
                field: 'tax.amount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                disableCondition: (values: any, index: any) => !(_.get(values, 'tax.type')?.code === 'RATE')
            }
        ]
    }
];

export const getFormattedReclaimableTable = (data: any, dropdownData: any) => {
    return data?.map((val: any) => {
        return {
            ...val,
            name: getDropdownSelectedObject(val?.name, dropdownData['name']),
            type: getDropdownSelectedObject(val?.type, dropdownData['type']),
            rowId: val?.uuid
        };
    });
};

export const taxTypes = {
    name: [
        { code: 'CGST_TDS', name: 'CGST TDS' },
        { code: 'SGST_TDS', name: 'SGST TDS' },
        { code: 'INCOME_TAX', name: 'Income tax' },
        { code: 'LABOR_CESS', name: 'Labor Cess' },
        { code: 'ROYALTY_ON_MINERALS', name: 'Royalty on Minerals' },
        { code: 'TCS_ON_ROYALTY', name: 'TCS on Royalty' },
        { code: 'COMPENSATION_FROM_CONTRACTOR', name: 'Compensation from contractor' },
        { code: 'CGST_ON_COMPENSATION', name: 'CGST on Compensation' },
        { code: 'SGST_ON_COMPENSATION', name: 'SGST on Compensation' }
    ],
    type: [
        { code: 'AMOUNT', name: 'Amount' },
        { code: 'RATE', name: 'Rate' }
    ]
};
