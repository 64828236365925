import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../../components/header/Header.component';
import Loading from '../../../../../components/portalComponents/Loading.component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import { t } from 'i18next';
import { check, cross } from '../../../../../utils/Logo';
import { devUrl, endpoints, estateEndpoints, POST, requestInfo, spTransferTypeDropdown } from '../../../../../services/modulesServices/userManagement.api';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { Formik, FormikProvider, useFormik } from 'formik';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import { getCheckList, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument, paymentCheck } from '../../EstateManagement.controller';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../Allotment/Allotment.const';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../LeaseDeed/LeaseDeed.const';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../../components/fileviewer/GenericImageViewer';
import { Card } from 'react-bootstrap';
import { MiscellaneousWorkflowCorrespondence } from './MiscellaneousWorkflowCorrespondence';
import { dropdownDataOptions, getFormatedOwnershipDetails } from '../../../Migration/Migration.controller';
import { DROPDOWN_CHANGE, miscellaneousApplication, WORKFLOW_PLOT_TABLE_COLUMNS } from '../Miscellaneous.cons';
import { getLesseeInitData } from '../../SubLease/SubLeaseConst';
import { EMPLOYEE_AUTHORIZED, TENANT_ID } from '../../../../../utils/constants';
import { ESIGN_COLUMNS } from '../../Transfer/Transfer.const';
import axios from 'axios';
import { getFormatedNICDetails } from '../../Allotment/Allotment.controller';
import GenericButtons from '../../../../../components/UI components/Buttons/GenericButtons';
import { EsignModal } from '../../../../commonPages/EsignModal';

export function MiscellaneousWorkflow() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [searchResponse, setSearchResponse] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState(1);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [rowData, setRowData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [modalData, setModalData] = useState<any>(null);
    const [content, setContent] = useState<any>(null);
    const [showOwnershipModal, setShowOwnershipModal] = useState<boolean>(false);
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [nicDetails, setNicDetails] = useState<any>([]);
    const [showPopUp, setShowPopUp] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState([true, true, true]);
    const [transactionDetails, setTransactionDetails] = useState<any>(null);
    const [paymentRowData, setPaymentRowData] = useState<any>([]);
    const [checklistTableData, setChecklistTableData] = useState();
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);
    const [updatedRowData, setUpdatedRowData] = useState<any>([]);
    const [column, setColumn] = useState<any>(WORKFLOW_PLOT_TABLE_COLUMNS);
    const [esignRowdata, setEsignRowdata] = useState<any>([]);
    const [isEsignPending, setIsEsignPending] = useState<any>(true);
    const [comment, setcomment] = useState('');
    const [esignColumns, setEsignColumns] = useState<any>(null);

    const [refId, setRefId] = useState<any>(null);
    const [approvedEsignData, setApprovedEsignData] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [signedIdsBeforeEsign, setSignedIdsBeforeEsign] = useState<any>(null);

    const [searchParams] = useSearchParams();
    const applicationNumber = decodeURIComponent(searchParams.get('applicationNumber') || '');
    let navigate = useNavigate();
    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const totalPages = applicationNumber ? 3 : 1;
    const signedFileStoreId: any = searchParams.get('signedFileStoreId');
    const currentRefId: any = searchParams.get('currentRefId');

    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;

    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: false,
            esignOption: 'DSC'
        },
        onSubmit: () => {}
    });

    const { values, setValues, setFieldValue } = formik;

    useEffect(() => {
        if (userType === 'EMPLOYEE') {
            (async () => {
                try {
                    setIsLoading(true);

                    const { data: searchApplication } = await POST(endpoints.searchAllotment + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                    setSearchResponse(searchApplication?.estateApplications[0]);

                    setContent(searchApplication?.estateApplications[0].modificationDetails.requestDetails.plotOrderRequests);

                    if (searchApplication?.estateApplications[0].applicationSubType === 'CHANGE_IN_OWNERSHIP_INTEREST') {
                        const owners = searchApplication?.estateApplications[0].modificationDetails.requestDetails.owners;
                        const dropdownOption = await spTransferTypeDropdown();
                        const newOwnership = owners?.length && (await getFormatedOwnershipDetails(owners, dropdownOption));
                        newOwnership && setOwnershipDetails(newOwnership);
                        owners && setControllingOwnershipResponse(owners);
                    }

                    if (searchApplication?.estateApplications[0].applicationSubType === 'CHANGE_IN_TRADE') {
                        const dropdownlist: any = await dropdownDataOptions();
                        const newProposed = await getFormatedNICDetails(
                            searchApplication?.estateApplications[0]?.modificationDetails?.requestDetails?.nicEnterpriseMappingRequests || [],
                            dropdownlist
                        );
                        setNicDetails(newProposed);
                    }

                    const plotData = searchApplication?.estateApplications[0]?.modificationDetails.requestDetails.plotOrderRequests?.map((val: any) => val.plotId);
                    setSelectedPlotDataId(plotData);
                    if (!plotData) return;
                    let { data } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotData}`, { RequestInfo: requestInfo() });

                    let plotDataArray = data?.plots?.map((plot: any, index: number) => ({
                        estateName: plot?.estateDetails?.estateName,
                        plotType: plot?.plotType,
                        phase: plot?.phase,
                        plotNo: plot?.plotNo,
                        plotAreaSqm: plot?.plotAreaSqm,
                        uuid: plot?.uuid,
                        id: plot?.uuid,
                        serialNo: index + 1
                    }));

                    console.log('plotDataArray', plotDataArray);
                    setUpdatedRowData(plotDataArray);

                    const miscData = await miscellaneousApplication(searchApplication?.estateApplications[0]?.modificationDetails, { natureOfChange: DROPDOWN_CHANGE });
                    const lesseeData = await getLesseeInitData(searchApplication?.estateApplications[0]);
                    setValues((prev: any) => ({ ...prev, ...miscData, ...lesseeData, updatedEnterpriseName: searchApplication?.estateApplications[0].modificationDetails.requestDetails.entityName }));

                    setIsLoading(false);
                } catch (e: any) {
                    setIsLoading(false);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();

            fetchDropdownNextValidActionAndHistoryData();
        }

        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }
    }, [applicationNumber]);

    useEffect(() => {
        const fetchDocumentData = async () => {
            try {
                setIsLoading(true);
                if (applicationNumber) {
                    const docs = await getWorkflowDocumentSearch(applicationNumber, nextValidAction?.businessService);
                    setDocumentDetails(docs);

                    const plotIds = searchResponse?.modificationDetails.requestDetails.plotOrderRequests?.map((val: any) => val.plotId);
                    const relatedApplicationDocument = await POST(
                        estateEndpoints.relatedApplicationSearch + `?entityIds=${searchResponse?.enterpriseEntity.uuid}&plotIds=${plotIds}&applicationNos=${applicationNumber}`,
                        { RequestInfo: requestInfo() }
                    );
                    setRelatedApplicationDocument(relatedApplicationDocument);
                }
                setIsLoading(false);
            } catch (e: any) {
                console.log('error 6:', e);
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (activeIndex === 2) {
            fetchDocumentData();
        }

        if (activeIndex === 1 && userType === 'EMPLOYEE') {
            (async () => {
                const plotId = searchResponse?.modificationDetails?.requestDetails?.plotOrderRequests?.map((val: any) => val.plotId);
                const checklistData =
                    searchResponse?.enterpriseEntity?.uuid &&
                    plotId &&
                    nextValidAction?.businessService &&
                    (await getCheckList(searchResponse?.enterpriseEntity?.uuid, plotId, nextValidAction.businessService));
                setChecklistTableData(checklistData);
            })();
        }
    }, [activeIndex, searchResponse, nextValidAction]);

    useEffect(() => {
        const reverseDocumentDetails = documentDetails.reverse();
        const tableData = async () => {
            const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                return { ...data, serialNo: `C${ind + 1}` };
            });
            const reverseData = responseTableData.reverse();
            setRowData(reverseData);
        };
        if (activeIndex === 2) {
            tableData();
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        userType === 'EMPLOYEE' && getPaymentData();
    }, [searchResponse]);

    useEffect(() => {
        if (searchResponse?.status === 'APPROVED' && nextValidAction && searchResponse?.applicationSubType !== 'CHANGE_IN_TRADE') {
            const payload = getPayload('APPROVE', comment);
            showEsignTable(payload);
        }
    }, [content, searchResponse, nextValidAction]);

    const fetchDropdownNextValidActionAndHistoryData = async () => {
        try {
            setIsLoading(true);
            if (applicationNumber) {
                const nextActions = await getNextActions(applicationNumber);
                setNextValidAction(nextActions);

                console.log('nextActions', nextActions.nextValidAction);
                if (nextActions.nextValidAction.length === 0) {
                    const tempEsignColumn = ESIGN_COLUMNS?.filter(val => val.fieldName !== 'action');
                    const tempColumn = tempEsignColumn?.map((val: any) => {
                        if (val.fieldName === 'order') {
                            return { ...val, clsName: 'col-sm-8 column-text-start' };
                        }
                        return { ...val };
                    });
                    console.log('tempColumn', tempColumn);
                    setEsignColumns(tempColumn);
                } else {
                    setEsignColumns(ESIGN_COLUMNS);
                }

                const { data } = await POST(`${endpoints.workflowSearch}&businessIds=${applicationNumber}`, { RequestInfo: requestInfo() });
                setHistoryDetails(data.ProcessInstances);
            }
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            console.log('error 1:', e);
        }
    };

    const getPaymentData = async () => {
        try {
            if (searchResponse) {
                const payCheck = await paymentCheck(
                    'PROCESSING_FEE',
                    searchResponse?.modificationDetails?.requestDetails?.plotOrderRequests?.map((val: any) => val.plotId),
                    searchResponse?.uuid,
                    searchResponse?.allEntityRefDetails?.map((val: any) => val?.entityId),
                    searchResponse?.applicationSubType === 'CHANGE_IN_OWNERSHIP_INTEREST' || searchResponse?.applicationSubType === 'CHANGE_IN_NAME'
                );
                setPaymentRowData(payCheck?.paymentData);
                setTransactionDetails(payCheck?.transactionDetails);
            }
        } catch (e: any) {
            console.log('error 3:', e);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            setIsLoading(true);
            const docs: any =
                applicationNumber && file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction?.businessService, historyDetails[0].id));
            setDocumentDetails(docs.documents);
            setDocumentsUuid([...documentsUuid, docs.uuid]);
            setIsLoading(false);
            setActiveIndex(2);
        } catch (e: any) {
            setIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string, isSaveOnly: boolean, isUnauthorized: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success' && !isSaveOnly && !isUnauthorized) {
            navigate('/goa-idc/dashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            }
        }
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const onCancel = () => {
        navigate(-1);
    };

    const handleNextPage = () => {
        if (activeIndex < totalPages) {
            setActiveIndex(prevInd => prevInd + 1);
        }
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) {
            setActiveIndex(prevInd => prevInd - 1);
        }
    };

    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const signedIds: any = searchParams.get('signedIds');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');

    useEffect(() => {
        if (note) {
            setFieldValue('notes', note);
        }
    }, [note]);

    useEffect(() => {
        // for transfer only
        if (signedFileStoreId && action && searchResponse && nextValidAction && searchResponse?.applicationSubType === 'CHANGE_IN_TRADE') {
            onHandlePrimaryAction({ action: action }, '');
        }
    }, [searchResponse, nextValidAction]);

    const getPayload = (status?: string, comment?: string) => {
        let payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            estateApplicationRequest: {
                ...searchResponse,
                modificationDetails: { ...searchResponse?.modificationDetails, requestDetails: { ...searchResponse?.modificationDetails?.requestDetails, plotOrderRequests: content } }
            },
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : (docsUuid && docsUuid?.split(',')) || null,
            signedDocDetails:
                searchResponse?.applicationSubType !== 'CHANGE_IN_TRADE'
                    ? esignRowdata.length
                        ? esignRowdata.map((item: any) => ({ [`${item.uuid}`]: item.newDocRefID }))
                        : undefined
                    : approvedEsign?.split(',').map((item: any) => {
                          const esignData = item.split('=');
                          return { [`${esignData[0]}`]: `${signedFileStoreId}` };
                      }) || null
        };

        return payload;
    };

    const esign = async (payload: any, action: string) => {
        // esign for transfer workflow
        const userRequestInfo = requestInfo();
        try {
            let approveEsignRes = await POST(endpoints.esignApprove, payload);
            setRefId(approveEsignRes.data.entityRefDocuments[0].docReferenceId);
            const approvedEsignData = approveEsignRes.data.entityRefDocuments.map((item: any) => `${item.uuid}=${item.docReferenceId}`);
            setApprovedEsignData(approvedEsignData);
            setActionAfterEsign(action);
            setEsignModal(true);

            // if (window.location.protocol !== 'http:') {
            // let url =
            //     window.location.origin +
            //     `/goa-idc/estate/change-in-ownership-name-trade/workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(
            //         ','
            //     )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
            //     url = btoa(url);
            //     const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
            //         userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
            //     }`;
            //     await axios.get(esignUrl);

            //     window.open(esignUrl, '_self');
            // } else {
            //     let url =
            //         window.location.origin +
            //         `/goa-idc/estate/change-in-ownership-name-trade/workflow?${searchParams.toString()}&signedFileStoreId=${refId}&note=${values.notes}&docsUuid=${documentsUuid?.join(
            //             ','
            //         )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
            //     console.log('url', url, documentsUuid);
            //     window.open(url, '_self');
            // }
        } catch (e) {
            console.log('esign error', e);
        }
    };

    const signDocument = () => {
        let url =
            searchResponse?.applicationSubType === 'CHANGE_IN_TRADE'
                ? window.location.origin +
                  `/goa-idc/estate/change-in-ownership-name-trade/workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
                      ','
                  )}&action=${actionAfterEsign}`
                : window.location.origin +
                  `/goa-idc/estate/change-in-ownership-name-trade/workflow?applicationNumber=${searchParams.get('applicationNumber')}&tenantId=ga&note=${values.notes || ''}&docsUuid=${
                      documentsUuid?.join(',') || ''
                  }&signedIds=${signedIdsBeforeEsign.join(',')}&currentRefId=${refId}`;
        url = btoa(url);

        if (values.esignOption === 'EKYC') {
            const userRequestInfo = requestInfo();
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
        }
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        let payload = getPayload(status.action, comment);

        if (!values?.notes && !note) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please enter notes', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (designation === 'MD' && searchResponse?.applicationSubType !== 'CHANGE_IN_TRADE' && !esignRowdata.length && status.isEsignRequired) {
            showEsignTable(payload);
            return;
        }

        if (designation === 'MD' && searchResponse?.applicationSubType !== 'CHANGE_IN_TRADE' && status.isEsignRequired && isEsignPending) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please E-Sign all the documents!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        } else if (!signedFileStoreId && searchResponse?.applicationSubType === 'CHANGE_IN_TRADE' && status.isEsignRequired) {
            esign(payload, status.action);
            return;
        }

        try {
            await POST(endpoints.estateWorkflowInitiate, payload);
            const successMessage = getSuccessMessage(status?.action, applicationNumber);
            setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: successMessage, img: check, type: 'success', reUpload: 'OK' });
        } catch (e: any) {}
    };

    const updateNotes = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            ...searchResponse,
            modificationDetails: { ...searchResponse?.modificationDetails, requestDetails: { ...searchResponse?.modificationDetails?.requestDetails, plotOrderRequests: content } }
        };

        try {
            await POST(endpoints.updateAllotment, payload);
            setModalDetails({ show: true, title: 'Success!', para: 'Data saved successfully', img: check, type: 'success', reUpload: 'OK', isSaveOnly: true });
        } catch (e: any) {}
    };

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };

    const showEsignTable = async (payload: any) => {
        try {
            if (searchResponse) {
                let approveEsignRes = await POST(endpoints.esignApprove, payload);
                const approvedDocData = approveEsignRes?.data.entityRefDocuments;

                const oldsignedIds = signedIds?.split(',').map((item: any) => item.split('=')[0]) || [];
                const newsignedIds = signedIds?.split(',').map((item: any) => item.split('=')[1]) || [];

                if (searchResponse?.applicationSubType !== 'CHANGE_IN_TRADE' && designation === 'MD' && content) {
                    const tableData = approvedDocData?.map((item: any, index: number) => {
                        console.log('newIds', oldsignedIds, newsignedIds);
                        if (item.docReferenceId === currentRefId || oldsignedIds?.indexOf(item.docReferenceId) > -1) {
                            const indexOfId = oldsignedIds?.indexOf(item.docReferenceId);
                            return {
                                ...item,
                                serialNo: index + 1,
                                order: `Change Order Notes - ${index + 1}`,
                                verification: 'DONE',
                                newDocRefID: item.docReferenceId === currentRefId ? signedFileStoreId : newsignedIds[indexOfId]
                            };
                        } else if (nextValidAction?.nextValidAction?.length === 0 && searchResponse?.status === 'APPROVED') {
                            return {
                                ...item,
                                serialNo: index + 1,
                                order: `Change Order Notes - ${index + 1}`,
                                verification: 'DONE'
                                // newDocRefID: item.docReferenceId === currentRefId ? signedFileStoreId : newsignedIds[indexOfId]
                            };
                        } else {
                            return { ...item, serialNo: index + 1, order: `Change Order Notes - ${index + 1}`, verification: `PENDING` };
                        }
                    });

                    setEsignRowdata(tableData);

                    const esignPending = tableData.filter((item: any) => item.verification !== 'DONE');
                    setIsEsignPending(!!esignPending.length);
                }
            }
        } catch (err: any) {
            console.log(err);

            let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleOtherAction = async (row: any) => {
        let signedIds = esignRowdata
            .map((item: any) => {
                if (item.verification === 'DONE') {
                    return `${item.docReferenceId}=${item.newDocRefID}`;
                }
            })
            .filter(Boolean);

        setRefId(row.docReferenceId);
        setSignedIdsBeforeEsign(signedIds);
        setEsignModal(true);
        console.log(signedIds);

        // try {
        //     const userRequestInfo = requestInfo();

        //     if (window.location.protocol !== 'http:') {
        // let url =
        //     window.location.origin +
        //     `/goa-idc/estate/change-in-ownership-name-trade/workflow?applicationNumber=${searchParams.get('applicationNumber')}&tenantId=ga&note=${values.notes || ''}&docsUuid=${
        //         documentsUuid?.join(',') || ''
        //     }&signedIds=${signedIds.join(',')}&currentRefId=${row.docReferenceId}`;
        //         url = btoa(url);
        //         const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${row.docReferenceId}&accessToken=${userRequestInfo.authToken}&userType=${
        //             userRequestInfo.userType
        //         }${userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''}`;
        //         await axios.get(esignUrl);

        //         window.open(esignUrl, '_self');
        //     } else {
        //         let url =
        //             window.location.origin +
        //             `/goa-idc/estate/change-in-ownership-name-trade/workflow?\applicationNumber=${searchParams.get('applicationNumber')}&tenantId=ga&signedFileStoreId=${row.docReferenceId}&note=${
        //                 values.notes || ''
        //             }&docsUuid=${documentsUuid?.join(',') || ''}&signedIds=${signedIds.join(',')}&currentRefId=${row.docReferenceId}`;
        //         console.log('url', url, documentsUuid);
        //         window.open(url, '_self');
        //     }
        // } catch (e) {
        //     console.log('esign error', e);
        // }
    };

    const onApprovalSubmit = () => {
        if (isEsignPending) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please E-Sign all the documents!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        onHandlePrimaryAction({ action: 'APPROVE' }, '');
    };

    console.log('content', content);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                        <GenericHeader
                            text={t('MISCELLANEOUS_APPLICATION', 'Application for Change in Ownership, Name or Trade')}
                            additionalClass='sub-text-style'
                            bottomSubText={`File : ${searchResponse?.enterpriseEntity?.entityName}, Type : ${t(searchResponse?.applicationSubType)} , No : ${applicationNumber}`}
                        />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard'>
                                    <GenericHeader
                                        text={t('MISCELLANEOUS_APPLICATION', 'Application for Change in Ownership, Name or Trade')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : ${t(searchResponse?.applicationSubType)}, No : ${applicationNumber}`}
                                    />
                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                        disable={isViewOnly ? isViewOnly : !nextValidAction?.nextValidAction.length}
                                    />
                                </div>
                            </div>

                            <div className='col-sm-9 mt-3 form-section'>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />

                                {activeIndex === 1 && designation === 'MD' && searchResponse?.applicationSubType !== 'CHANGE_IN_TRADE' && esignRowdata.length ? (
                                    <Card className='card_border p-1 mb-3'>
                                        <Card className='checklist-outer mb-4 mx-3'>
                                            <div className='row  my-3 px-3 py-2'>
                                                <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                                                    <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                                                    <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                                        {t('E_VERIFICATION', 'e-Verification')}
                                                    </label>
                                                    <div className='form_fields px-2'>
                                                        <GenericActionTable columns={esignColumns} hideSearchBar={true} rowData={esignRowdata} handleOtherAction={handleOtherAction} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Card>
                                ) : (
                                    <></>
                                )}

                                <Card className='card_border p-1'>
                                    {activeIndex === 1 && (
                                        <MiscellaneousWorkflowCorrespondence
                                            content={content}
                                            setContent={setContent}
                                            searchResponse={searchResponse}
                                            showOwnershipModal={showOwnershipModal}
                                            setShowOwnershipModal={setShowOwnershipModal}
                                            setOwnershipDetails={setOwnershipDetails}
                                            ownershipDetails={ownershipDetails}
                                            controllingOwnership={controllingOwnershipResponse}
                                            nicDetails={nicDetails}
                                            setNicDetails={setNicDetails}
                                            showPopUp={showPopUp}
                                            setShowPopUp={setShowPopUp}
                                            isChecked={isChecked}
                                            transactionDetails={transactionDetails}
                                            paymentRowData={paymentRowData}
                                            nextValidAction={nextValidAction}
                                            checkListRowData={checklistTableData}
                                            selectedPlotDataId={selectedPlotDataId}
                                            updatedRowData={updatedRowData}
                                            column={column}
                                            isViewOnly={isViewOnly}
                                        />
                                    )}
                                    {activeIndex === 2 && (
                                        <div className='p-4'>
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideSearchBar={true} rowData={rowData} additionalMethod={onRowClick} />
                                        </div>
                                    )}
                                    {activeIndex === 3 && (
                                        <div className='p-4'>
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        </div>
                                    )}
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>

                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                    </div>

                    {!isViewOnly ? (
                        <>
                            {esignRowdata.length || (searchResponse?.status === 'APPROVED' && searchResponse?.applicationSubType !== 'CHANGE_IN_TRADE') ? (
                                <footer className='footer fixed-bottom footerBtnPatch'>
                                    <div className='d-flex'>
                                        <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                            <GenericButtons text={t('SUBMIT', 'submit')} handleClick={onApprovalSubmit} />
                                        </div>
                                    </div>
                                </footer>
                            ) : (
                                <GenericFooterComponent
                                    onCancel={onCancel}
                                    onClickPrev={onChangePrevIndex}
                                    activeIndex={activeIndex}
                                    handleOnPrimaryAction={onHandlePrimaryAction}
                                    onSubmit={() => {}}
                                    applicationNo={applicationNumber || null}
                                    nextValidAction={nextValidAction}
                                    stepperLength={0}
                                    extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                                    extraButtonAction={updateNotes}
                                />
                            )}
                        </>
                    ) : (
                        <></>
                    )}

                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly, modalDetails?.isUnauthorized)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly, modalDetails?.isUnauthorized)}
                    />

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                </>
            )}
        </>
    );
}
