import { Card, Form } from 'react-bootstrap';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { APPLICANT_DETAILS_II, CHECKLIST_TABLE, DECLARATION_DETAILS, ENTERPRISE_DETAILS, PROJECT_DETAILS, PROMOTER_DETAILS, paymentColumns } from '../../Allotment/Allotment.const';
import {
    REVISED_LEASE_RENT,
    SPECIAL_TRANSFER_CORRESPONDENCE_CARD,
    SPECIAL_TRANSFER_CORRESPONDENCE_CARD_FILTERED,
    TRANSFER_CORRESPONDENCE_CARD,
    TRANSFER_DETAILS_SEARCH,
    transferDeclarationList
} from '../Transfer.const';
import { useEffect, useState } from 'react';
import EnterpriseDetails from '../../Allotment/EnterpriseDetails';
import { useFormikContext } from 'formik';
import Declaration from '../../Allotment/Declaration';
import { APPLICANT_DETAILS, TRANSFEREE_DETAILS_SEARCH, TRANSFEREE_OTHER_DETAILS, TRANSFEROR_DETAILS_SEARCH } from '../../Transferee/Transferee.const';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import Editor from '../../../../../components/ContentEditor';
import ApplicantDetails from '../../Allotment/ApplicantDetails';
import PlotDetails from '../../../Migration/pages/PlotDetails';
import { SPECIAL_PROJECT_DOCUMENT, SPECIAL_TRANSFER_DETAILS, SPECIAL_TRANSFER_DOCUMENTS } from '../../SpecialTransfer/SpecialTransfer.const';
import GenericOwnerShipDetails from '../../../../../components/Ownership/GenericOwnership';
import { WorkflowPaymentDetails } from '../../WorkflowPaymentDetails';
import { useTranslation } from 'react-i18next';

export const TransferWorkflowCorrespondence = ({
    checkListRowData,
    dropdownData,
    nicDetails,
    setNicDetails,
    isChecked,
    handleCheckboxChange,
    fileStoreId,
    content,
    setContent,
    ownershipDetails,
    setOwnershipDetails,
    controllingOwnership,
    dropdownDataPlot,
    setDropdownDataPlot,
    paymentRowData,
    nextValidAction,
    isSpecialTransfer,
    applicationSearchResponse,
    showOwnershipModal,
    setShowOwnershipModal,
    isTransferChecked,
    transactionDetails,
    showOwnershipModalTransferor,
    setShowOwnershipModalTransferor,
    setOwnershipDetailsTransferor,
    ownershipDetailsTransferor,
    controllingOwnershipTransferor,
    isViewOnly,
    readOnly
}: any) => {
    const { t } = useTranslation();
    const [openSection, setOpenSection] = useState<string>(
        !isSpecialTransfer
            ? 'transfereeDetails'
            : applicationSearchResponse?.modificationDetails?.requestDetails?.type !== 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST'
            ? 'transfereeDetails'
            : 'transferorDetails'
    );
    const { values, touched, errors, setFieldValue } = useFormikContext<any>();
    const [specialProjectDocument, setSpecialProjectDocument] = useState<any>(SPECIAL_PROJECT_DOCUMENT);
    const [specialTransferDocument, setSpecialTransferDocument] = useState<any>(SPECIAL_TRANSFER_DOCUMENTS);
    const [specialTransferDetail, setSpecialTransferDetails] = useState<any>(SPECIAL_TRANSFER_DETAILS);

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    console.log('contentttttt', content);

    const transferContent =
        content && Array.isArray(content)
            ? content?.map((item: any, index: number) => ({
                  title: `D${index + 1} - Special Transfer Order Notes - ${index + 1}`,
                  field: `transferOrderNotesDetails${index + 1}`
              }))
            : [];

    const transferCorrespondenceTab =
        applicationSearchResponse?.status !== 'REJECTION_PENDING'
            ? TRANSFER_CORRESPONDENCE_CARD
            : [
                  ...TRANSFER_CORRESPONDENCE_CARD.filter((val: any) => val.title !== 'G - Transfer Order Notes'),
                  {
                      title: 'G - Rejection Order',
                      field: 'rejectionOrder'
                  }
              ];

    const specialTransferConditional =
        applicationSearchResponse?.modificationDetails?.requestDetails?.type !== 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST'
            ? SPECIAL_TRANSFER_CORRESPONDENCE_CARD
            : SPECIAL_TRANSFER_CORRESPONDENCE_CARD_FILTERED;

    const specialTransferCorrespondenceTab =
        applicationSearchResponse?.status !== 'REJECTION_PENDING'
            ? [...specialTransferConditional, ...transferContent]
            : [
                  ...specialTransferConditional.filter((val: any) => val.title !== 'D - Special Transfer Order Notes'),
                  {
                      title: 'D - Rejection Order',
                      field: 'rejectionOrder'
                  }
              ];

    console.log(specialTransferCorrespondenceTab);

    const correspondenceCard = !isSpecialTransfer ? transferCorrespondenceTab : specialTransferCorrespondenceTab;

    const renderTransfereeDetails = () => {
        return (
            <ApplicantDetails
                controllingOwnershipResponse={controllingOwnership}
                dropdownOptions={dropdownData}
                setOwnershipDetails={setOwnershipDetails}
                ownershipDetails={ownershipDetails}
                isDisabled={!values.isUpdatable}
                showOwnershipModal={showOwnershipModal}
                setShowOwnershipModal={setShowOwnershipModal}
                applicantDetails1={TRANSFEREE_DETAILS_SEARCH}
                applicantDetails2={APPLICANT_DETAILS}
                promoterDetails={TRANSFEREE_OTHER_DETAILS}
                scrollNotRequired={true}
                isWorkflow={true}
            />
        );
    };

    const renderTransferorDetails = () => {
        return (
            <div>
                <>
                    <Card.Body>
                        {TRANSFEROR_DETAILS_SEARCH?.map(val => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        ))}
                        {isSpecialTransfer ? (
                            <GenericOwnerShipDetails
                                showOwnershipModal={showOwnershipModalTransferor}
                                setShowOwnershipModal={setShowOwnershipModalTransferor}
                                setOwnershipDetails={setOwnershipDetailsTransferor}
                                ownershipDetails={ownershipDetailsTransferor}
                                hideCard={true}
                                isDisabled={true}
                                controllingOwnershipResponse={controllingOwnershipTransferor}
                                isWorkflow={true}
                            />
                        ) : (
                            <></>
                        )}
                    </Card.Body>
                </>
            </div>
        );
    };

    const renderTransferDetails = () => {
        return (
            <div>
                <>
                    <Card.Body>
                        {TRANSFER_DETAILS_SEARCH?.map(val => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        ))}

                        <div className='ps-2 py-2 d-flex justify-content-between'>
                            <div className='formTitle m-0'>
                                {'Transferor Self Declaration'} <span className='text-danger'>*</span>
                            </div>
                        </div>

                        <div className='ps-2 d-flex flex-column fw-medium'>
                            <p className='mt-3 font-inter'>The Transferor does hereby affirm and declare that:</p>
                            {transferDeclarationList.map((item, index) => (
                                <label key={index} className='mb-3 d-flex gap-1'>
                                    <input type='checkbox' checked={isTransferChecked[index]} onChange={() => handleCheckboxChange(index)} disabled={true} className={'default-checked'} />
                                    <div className='flex-grow-0 font-inter'>{item.label}</div>
                                </label>
                            ))}
                        </div>
                    </Card.Body>
                </>
            </div>
        );
    };

    const renderPlotDetails = () => {
        return (
            <>
                <Card.Body>
                    <PlotDetails dropdownData={dropdownDataPlot} isFieldDisable={!values.isUpdatable} setDropdownData={setDropdownDataPlot} hideCard={true} />
                </Card.Body>
            </>
        );
    };

    const renderEnterpriseDetails = () => {
        return (
            <EnterpriseDetails
                enterpriseDetails={ENTERPRISE_DETAILS}
                enterpriseProjectDetails={PROJECT_DETAILS}
                declarationDetails={DECLARATION_DETAILS}
                dropdownOptions={dropdownData}
                nicDetails={nicDetails}
                setNicDetails={setNicDetails}
                isDisabled={!values.isUpdatable}
                applicantName={'Transferee'}
            ></EnterpriseDetails>
        );
    };

    const renderDeclarationDetails = () => {
        const declarationList = [
            '(i) the information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed herein.',
            '(ii) it has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy, insolvency or liquidation proceedings.',
            '(iii) the provision allotment order or the lease deed, as the case may be, shall be liable to be cancelled if there is any misdeclaration or misrepresentation of the information submitted under or along with this application.'
        ];
        return (
            <Card.Body>
                <Declaration
                    isChecked={isChecked}
                    hideCard={true}
                    declarationList={declarationList}
                    handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                    fileStoreId={fileStoreId}
                    applicantName={'Transferee'}
                    isDisabled={true}
                ></Declaration>
            </Card.Body>
        );
    };

    const tooltipDetails: any = {
        ownershipDetails: {
            position: 'right',
            content: `<div class="d-flex flex-column font-inter" style="gap:5px;"><div class="mb-2">"Ownership Interest" means:</div> <div>(i)	holding of voting rights in a body corporate as defined under the Companies Act, 2013 (Act No. 18 of 2013); or</div>
            <div>(ii)	ownership of or entitlement to the capital or profits in a limited liability partnership, partnership firm, society or trust;</div></div>
            `
        },
        promoterDetails: {
            position: 'right',
            content: `<div class="d-flex flex-column font-inter" style="gap:5px;"><div class="mb-2">"Promoter" means:</div> <div>(i)	with respect to a body corporate, Person(s) holding, individually or collectively, Controlling Ownership Interest; or</div>
            <div>(ii)	with respect to a limited liability partnership or partnership firm, partner(s) collectively holding Controlling Ownership Interest; or</div>
            <div>(iii)	with respect to any other legal entity recognized under law, the constituent Person(s) collectively holding Controlling Ownership Interest;</div></div>
            `
        }
    };

    useEffect(() => {
        if (isSpecialTransfer) {
            const updatedsSecialProjectDocument = specialProjectDocument.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, isDisabled: true })) }));
            setSpecialProjectDocument(updatedsSecialProjectDocument);
            const updatedSpecialTransferDocument = specialTransferDocument.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, isDisabled: true })) }));
            setSpecialTransferDocument(updatedSpecialTransferDocument);
            const updatedSpecialTransferDetails = specialTransferDetail.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, isDisabled: true })) }));
            setSpecialTransferDetails(updatedSpecialTransferDetails);
        }
    }, []);

    const renderSpecialTransferDetails = () => {
        return (
            <div>
                <>
                    <Card.Body>
                        <>
                            {specialTransferDetail.map((val: any) => (
                                <GenericFormCard {...val} dropdownOptions={dropdownData} />
                            ))}
                            {values.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ? (
                                <GenericOwnerShipDetails
                                    showOwnershipModal={showOwnershipModal}
                                    setShowOwnershipModal={setShowOwnershipModal}
                                    setOwnershipDetails={setOwnershipDetails}
                                    ownershipDetails={ownershipDetails}
                                    hideCard={true}
                                    isDisabled={true}
                                    controllingOwnershipResponse={controllingOwnership}
                                    isWorkflow={true}
                                />
                            ) : (
                                <></>
                            )}
                            {specialTransferDocument.map((val: any) => (
                                <GenericFormCard {...val} />
                            ))}
                            {specialProjectDocument.map((val: any) => (
                                <GenericFormCard {...val} />
                            ))}
                        </>
                    </Card.Body>
                </>
            </div>
        );
    };

    console.log('transactionDetails', transactionDetails);
    console.log('paymentRowData', paymentRowData);

    const renderPaymentDetails = () => {
        return (
            <>
                <div className='px-4 py-4'>
                    <WorkflowPaymentDetails transactionDetails={transactionDetails} paymentColumns={paymentColumns} paymentRowData={paymentRowData} />
                </div>
            </>
        );
    };

    const renderRevisedLeaseRentDetails = () => {
        return (
            <Card.Body>
                {REVISED_LEASE_RENT.map(val => (
                    <GenericFormCard {...val} />
                ))}
            </Card.Body>
        );
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={setContent} content={content} readOnly={readOnly} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    const renderTransferNoteDetails = (item: any, index: number) => {
        const setCustomContent = (data: any) => {
            let updatedContent = content?.filter((contentItem: any) => contentItem.plotId !== item.plotId);
            updatedContent.splice(index, 0, { ...item, content: data });
            setContent(updatedContent);
        };

        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={setCustomContent} content={content[index].content} readOnly={readOnly} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    console.log('cardddddddddd', correspondenceCard);

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row  my-3 px-3 py-2'>
                    {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                {t('CHECK_LIST', 'Condition/Check list')}
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {correspondenceCard.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A1 - Transferee Details' && openSection === 'transfereeDetails' && renderTransfereeDetails()}
                                {(val.title === 'A2 - Transferor Details' || val.title === 'A1 - Transferor Details') && openSection === 'transferorDetails' && renderTransferorDetails()}
                                {val.title === 'A3 - Transfer Details' && openSection === 'transferDetails' && renderTransferDetails()}
                                {val.title === 'B - Plot Details' && openSection === 'plotDetails' && renderPlotDetails()}
                                {val.title === 'C - Enterprise Details' && openSection === 'enterpriseDetails' && renderEnterpriseDetails()}
                                {(val.title === 'D - Transferee Self Declaration' || val.title === 'B - Self Declaration') && openSection === 'selfDeclaration' && renderDeclarationDetails()}
                                {(val.title === 'E - Payment Details' || val.title === 'C - Payment Details') && openSection === 'paymentDetails' && renderPaymentDetails()}
                                {val.title === 'F - Revised Lease Rent' && openSection === 'revisedLeaseRentDetails' && renderRevisedLeaseRentDetails()}
                                {val.title === 'G - Transfer Order Notes' && openSection === 'transferOrderNotesDetails' && renderNoteDetails()}
                                {Array.isArray(content) &&
                                    content?.map((item: any, index: number) => {
                                        return (
                                            val.title === `D${index + 1} - Special Transfer Order Notes - ${index + 1}` &&
                                            openSection === `transferOrderNotesDetails${index + 1}` &&
                                            renderTransferNoteDetails(item, index)
                                        );
                                    })}

                                {(val.title === 'G - Rejection Order' || val.title === 'D - Rejection Order') && openSection === 'rejectionOrder' && renderNoteDetails()}
                                {(val.title === 'A2 - Special Transfer Details' || val.title === 'A3 - Special Transfer Details') &&
                                    openSection === 'transferDetails' &&
                                    renderSpecialTransferDetails()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};
