import axios from 'axios';
import { dropdownOptionsInterface } from '../../components/moduleCommonFormik/GenericFormCard.constants';
import { assignmentDepartmentDesignationInterface, assignmentInterface, getOtpInterface, searchApplication, userInterface, UserRequestInterface } from '../../features/modules/UserManagement/types';
import { getUserDesignationInterface, loginInterface, PasswordChangeInterface, validateOtpInterface } from '../../features/loginPage/LoginPage.types';
import { extractFileUrl, isAuthenticated } from '../../utils/utils';
import { TENANT_ID } from '../../utils/constants';

const getHeaders = () => {
    return {
        headers: { 'Content-Type': 'application/json' }
    };
};

export const devUrl = process.env.REACT_APP_DEV_URL || 'https://idc-stage.goa.gov.in';

export const getUrl = () => {
     return 'https://idc-stage.goa.gov.in';
};

export const requestInfo = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const authToken = JSON.parse(localStorage.getItem('token') || '{}');
    const currAssignment = JSON.parse(localStorage.getItem('currentUserAssignment') || '{}');
    const type = JSON.parse(localStorage.getItem('type') || '{}');
    // const authToken = token;

    if (Object.keys(authToken).length) {
        return {
            authToken: authToken || '',
            userType: type || user.type,
            departmentCode: currAssignment?.department?.code || user.departmentCode
        };
    } else {
        return {};
    }
};

// common method for all post calls
export const POST = (url: string, payload: any) => {
    console.log(devUrl + url, payload);
    return axios.post(devUrl + url, payload);
};

export const POST2 = (url: string, payload: any, params?: any) => {
    console.log(devUrl + url, payload);
    return axios.post(devUrl + url, payload, { params });
};

//
export const createLogin = (payload: loginInterface, userType: string) => {
    const requestBody = { ...payload, userType: userType };
    return axios.post(devUrl + '/user/employee/_login', requestBody);
};

export const getOtp = (payload: loginInterface) => {
    const otpPayload = {
        otp: { tenantId: 'ga', type: 'login', identity: payload.username }
    };
    return axios.post(devUrl + `/user-otp/v1/_send`, otpPayload);
};

export const enterpriseValidateOtp = ({ otp, username }: validateOtpInterface) => {
    const otpPayload = {
        username: username,
        otp: otp,
        tenantId: 'ga'
    };
    return axios.post(devUrl + `/user/enterprise/_login`, otpPayload);
};

export const getUserPrimaryDesignation = async (payload: getUserDesignationInterface, userType: string) => {
    const moduleDetails =
        userType === 'EMPLOYEE'
            ? [
                  {
                      moduleName: 'common-masters',
                      masterDetails: [
                          {
                              name: 'Designation',
                              filter: `[?(@.code == "${payload.designation}")]`
                          },
                          {
                              name: 'Department',
                              filter: `[?(@.code == "${payload.department}")]`
                          }
                      ]
                  }
              ]
            : [
                  {
                      moduleName: 'ACCESSCONTROL-ROLES',
                      masterDetails: [
                          {
                              name: 'roles',
                              filter: `[?(@.code == "${payload.designation}")]`
                          }
                      ]
                  }
              ];

    const requestBody = {
        RequestInfo: requestInfo(),
        MdmsCriteria: {
            tenantId: 'ga',
            moduleDetails: moduleDetails
        }
    };
    const res = await axios.post(devUrl + '/egov-mdms-service/v1/_search', requestBody);
    return userType === 'EMPLOYEE' ? res.data?.MdmsRes?.['common-masters'] : res.data?.MdmsRes?.['ACCESSCONTROL-ROLES'];
};

export const userLogout = async () => {
    const payload = {
        RequestInfo: requestInfo(),
        access_token: requestInfo().authToken
    };
    try {
        await axios.post(devUrl + '/user/_logout', payload);
    } catch (e) {
        console.log(e);
    }
};

export const updatePassword = (username: string, passwordData: PasswordChangeInterface, type?: string) => {
    const requestBody = {
        RequestInfo: {},
        existingPassword: passwordData.oldPassword,
        newPassword: passwordData.newPassword,
        tenantId: 'ga',
        username: username,
        type: type ? type : 'EMPLOYEE'
    };
    return axios.post(devUrl + `/user/password/_update`, requestBody);
};

export const getPasswordChangeOtp = (payload: any) => {
    return axios.post(devUrl + '/user-otp/v1/_send', payload);
};

export const passwordUpdateNoLogin = (payload: any) => {
    return axios.post(devUrl + '/user/password/nologin/_update', payload);
};

export const BPArequestInfo = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    const currAssignment = JSON.parse(localStorage.getItem('currentUserAssignment') || '{}');
    const type = JSON.parse(localStorage.getItem('type') || '{}');
    const authToken = token;
    console.log(currAssignment, token, type);

    return {
        authToken: authToken || '',
        userType: type || user.type,
        departmentCode: currAssignment?.department?.code || user.departmentCode,
        userInfo: user !== null ? user : null
    };
};

export const getUserDataOnRefresh = async () => {
    if (requestInfo().userType === 'EMPLOYEE') {
        return axios.post(devUrl + `/user/_details?access_token=${requestInfo().authToken}&department_code=${requestInfo().departmentCode}&user_type=${requestInfo().userType}`, {
            RequestInfo: requestInfo()
        });
    } else {
        return axios.post(devUrl + `/user/_details?access_token=${requestInfo().authToken}`, {
            RequestInfo: requestInfo()
        });
    }
};

export const createEmployee = (payload: any, createApi: any) => {
    return axios.post(devUrl + createApi, payload, getHeaders());
};

export const updateEmployee = (payload: any, updateApi: any) => {
    return axios.post(devUrl + updateApi, payload, getHeaders());
};

export const getLocation = async (pincode: number) => {
    let locationData = await axios.post(devUrl + `/egov-common-masters/utils/address?pincode=${pincode}`, { RequestInfo: {} });

    return locationData;
};

export const getDropdownOptions = async () => {
    const payload = {
        RequestInfo: requestInfo(),
        MdmsCriteria: {
            tenantId: 'ga',
            moduleDetails: [
                {
                    moduleName: 'common-masters',
                    masterDetails: [
                        {
                            name: 'Department',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'Designation',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'GenderType',
                            filter: '[?(@.active == true)]'
                        }
                    ]
                },
                {
                    moduleName: 'ACCESSCONTROL-ROLES',
                    masterDetails: [
                        {
                            name: 'roles',
                            filter: '[?(@.code != "ENTERPRISE" && @.code != "INTERNAL_MICROSERVICE_ROLE")]'
                        }
                    ]
                },
                {
                    moduleName: 'egov-hrms',
                    masterDetails: [
                        {
                            name: 'EmployeeType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'EmployeeStatus',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'Degree',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'Specalization',
                            filter: '[?(@.active == true)]'
                        }
                    ]
                }
            ]
        }
    };

    try {
        let { data } = await axios.post(devUrl + '/egov-mdms-service/v1/_search', payload);

        const dropdownOptions: dropdownOptionsInterface = {
            gender: data['MdmsRes']['common-masters']['GenderType'],
            status: data['MdmsRes']['egov-hrms']['EmployeeStatus'],
            empType: data['MdmsRes']['egov-hrms']['EmployeeType'],
            isCurrentAssignment: [
                { code: 'Yes', name: 'Yes' },
                { code: 'No', name: 'No' }
            ],
            department: data['MdmsRes']['common-masters']['Department'],
            designation: data['MdmsRes']['common-masters']['Designation'],
            qualification: data['MdmsRes']['egov-hrms']['Degree'],
            stream: data['MdmsRes']['egov-hrms']['Specalization'],
            roles: data['MdmsRes']['ACCESSCONTROL-ROLES']['roles'],
            identityDetailsDropdown: [
                { code: 'Pan Card', name: 'Pan Card' },
                { code: 'Passport', name: 'Passport' }
            ],
            addressDetailsDropdown: [
                { code: 'Passport', name: 'Passport' },
                { code: 'Driving License', name: 'Driving License' }
            ],
            letterDetailsDropdown: [
                { code: 'Joining Letter', name: 'Joining Letter' },
                { code: 'Appointment Letter', name: 'Appointment Letter' }
            ]
        };

        return dropdownOptions;
    } catch (e) {
        console.log(e);
    }
};

export const getUserRoles = async (department?: string) => {
    let url = `/egov-hrms/employees/departmentroles?tenantId=ga`;
    if (department) {
        url = `${url}&departmentCode=${department}`;
    }
    // const data = await axios.post(devUrl + `/egov-hrms/employees/departmentroles/ga/${department}`, { RequestInfo: requestInfo() });
    const data = await axios.post(devUrl + url, { RequestInfo: requestInfo() });
    return data;
};

export const getEnterpriseOtp = (payload: getOtpInterface) => {
    const token = JSON.parse(localStorage.getItem('token') || 'null');

    let requestPayload = {
        RequestInfo: {},
        otp: {
            tenantId: 'ga',
            identity: payload.data,
            type: token ? 'register' : 'ENTERPRISE_ENTITY_REGISTER'
        }
    };

    return axios.post(devUrl + '/user-otp/v1/_send', requestPayload);
};

export const getSubLeaseOtp = (payload: getOtpInterface) => {
    // const token = JSON.parse(localStorage.getItem('token') || 'null');

    let requestPayload = {
        RequestInfo: {},
        otp: {
            tenantId: 'ga',
            identity: payload.data,
            type: 'ENTERPRISE_ENTITY_REGISTER'
        }
    };

    return axios.post(devUrl + '/user-otp/v1/_send', requestPayload);
};

export const validateUserOtp = (payload: any) => {
    return axios.post(devUrl + '/user-otp/v1/_validate', payload);
};

export const validateOtp = (payload: any) => {
    const requestBody = {
        otp: {
            tenantId: 'ga',
            identity: payload.field,
            otp: payload.otp
        }
    };
    return axios.post(devUrl + '/otp/v1/_validate', requestBody);
};

export const createEnterprise = (payload: any) => {
    return axios.post(devUrl + '/user/enterprise/_create', payload, getHeaders());
};

export const checkUserAvailability = (payload: any) => {
    return axios.post(devUrl + '/user/checkavailability', payload);
};

export const fetchDropdownData = async (payload: any) => {
    const res = await axios.post(devUrl + '/egov-mdms-service/v1/_search', payload);
    return res.data?.MdmsRes;
};

export const getMappedRoles = async (user: userInterface[]) => {
    const uniqueDesignations: any[] = [...new Set(user?.map((item: any) => item.designation))];
    const uniqueDepartments: any[] = [...new Set(user?.map((item: any) => item.department))];

    const masterDetails = {
        RequestInfo: requestInfo(),
        MdmsCriteria: {
            tenantId: 'ga',
            moduleDetails: [
                {
                    moduleName: 'common-masters',
                    masterDetails: [
                        {
                            name: 'Designation',
                            filter: `[?(@.code == '${uniqueDesignations.join("' || @.code == '")}')]`
                        },
                        {
                            name: 'Department',
                            filter: `[?(@.code == '${uniqueDepartments.join("' || @.code == '")}')]`
                        }
                    ]
                }
            ]
        }
    };
    let getAssignmentRoles: assignmentInterface;
    try {
        getAssignmentRoles = await fetchDropdownData(masterDetails);
    } catch (e) {
        console.log(e);
    }

    const mappedUser = user?.map(currUser => {
        const designationDetails = getAssignmentRoles?.['common-masters']?.Designation?.find((item: assignmentDepartmentDesignationInterface) => item.code === currUser.designation);
        const departmentDetails = getAssignmentRoles?.['common-masters']?.Department?.find((item: assignmentDepartmentDesignationInterface) => item.code === currUser.department);
        return {
            ...currUser,
            department: { name: departmentDetails?.name || '', code: departmentDetails?.code || '' },
            designation: { name: designationDetails?.name || '', code: designationDetails?.code || '' }
        };
    });

    return mappedUser;
};

export const getEmployeeSearchTableData = (userPayload: UserRequestInterface) => {
    if (requestInfo().userType === 'EMPLOYEE') {
        return axios.post(devUrl + `/egov-workflow-v2/egov-wf/_inbox?assignee=${userPayload?.uuid}&isPendencyRequired=true`, { RequestInfo: requestInfo() });
    } else {
        return axios.post(devUrl + `/egov-workflow-v2/egov-wf/_inbox?initialProcessRequested=true&sortBy=CREATED_DATE_DESC&isPendencyRequired=true`, { RequestInfo: requestInfo() });
    }
};

export const getDashboardDetails = () => {
    return axios.post(devUrl + '/egov-common-masters/dashboard/cards/_search', { RequestInfo: requestInfo() });
};

export const getEmployeeData = (registrationNumber: number | string) => {
    return axios.post(devUrl + `/egov-hrms/employees/_search?registrationNumber=${registrationNumber}&tenantId=ga`, { RequestInfo: requestInfo() });
};

export const submitAction = (payload: any) => {
    const requestBody = {
        RequestInfo: requestInfo(),
        Employees: payload.employees,
        ProcessInstances: payload.processInstances,
        isStateUpdatable: payload.isStateUpdatable
        // isTerminatedState: payload.isTerminatedState,
    };
    return axios.post(devUrl + `/egov-hrms/employees/workflow/process/_initiate`, requestBody);
};

export const getFileUrl = async (id: number | string) => {
    try {
        const res = await axios.get(devUrl + '/filestore/v1/files/url', {
            params: {
                tenantId: 'ga',
                fileStoreIds: id
            }
        });
        return extractFileUrl(res.data.fileStoreIds?.[0]?.url);
    } catch (e) {
        console.log(e);
    }
};

export const getMultipleFilesUrl = async (id: string) => {
    try {
        const res = await axios.get(devUrl + '/filestore/v1/files/url', {
            params: {
                tenantId: 'ga',
                fileStoreIds: id
            }
        });
        return res.data.fileStoreIds;
    } catch (e) {
        console.log(e);
    }
};

export const getArchitectSearchTableData = (userPayload: UserRequestInterface) => {
    return axios.post(devUrl + `/egov-workflow-v2/egov-wf/architect/_inbox`, { RequestInfo: requestInfo() });
    // return axios.post(devUrl + `/egov-workflow-v2/egov-wf/_inbox?assignee=${userPayload?.uuid}`, { RequestInfo: requestInfo() });
};

export const getArchitectData = (registrationNumber: number | string) => {
    return axios.post(devUrl + `/egov-hrms/employees/_search?registrationNumber=${registrationNumber}&tenantId=ga`, { RequestInfo: requestInfo() });
};

export const getProgressBarData = (businessId: any) => {
    return axios.post(devUrl + `/egov-workflow-v2/egov-wf/process/progress-bar?businessId=${businessId}`, { RequestInfo: requestInfo() });
};

export const getMenuData = () => {
    if (isAuthenticated()) {
        const requestBody = { RequestInfo: requestInfo() };
        return axios.post(devUrl + `/egov-common-masters/menu/_search`, requestBody);
    } else {
        const requestBody = { RequestInfo: {}, defaultMenu: true };
        return axios.post(devUrl + `/egov-common-masters/menu/default/_search`, requestBody);
    }
};

export const getEmployeeSearchDropdownData = (type: string, searchParam: string | undefined) => {
    const requestBody = {
        RequestInfo: requestInfo(),
        type: type,
        searchParameter: searchParam || undefined
    };

    return axios.post(devUrl + `/egov-common-masters/application/search/metadata`, requestBody);
};

export const fetchEmployeeSearchedData = (payload: searchApplication) => {
    const fromDate = new Date(payload.dateFrom);
    const toDate = new Date(payload.dateTo);

    fromDate.setHours(0, 0, 0, 0);

    toDate.setHours(23, 59, 59, 999);

    const fromEpochTime = fromDate.getTime();
    const toEpochTime = toDate.getTime();

    const requestBody = {
        RequestInfo: requestInfo(),
        applicationModule: payload.applicationModules,
        applicationType: payload.applicationTypes,
        applicationStatus: payload.applicationStatus,
        applicationNumber: payload.applicationNumber,
        fromDate: fromEpochTime,
        toDate: toEpochTime
    };
    return axios.post(devUrl + `/egov-common-masters/application/search/`, requestBody);
};
export const getNextValidAction = (registrationNumber: any) => {
    return axios.post(devUrl + `/egov-workflow-v2/egov-wf/_action?businessId=${registrationNumber}&tenantId=ga`, { RequestInfo: requestInfo() });
};

export const spTransferTypeDropdown = async () => {
    const dropdownPayload = {
        RequestInfo: requestInfo(),
        MdmsCriteria: {
            tenantId: 'ga',
            moduleDetails: [
                {
                    moduleName: 'Estate',
                    masterDetails: [
                        {
                            name: 'SpecialTransferType',
                            filter: '[?(@.active == true)]'
                        }
                    ]
                },
                {
                    moduleName: 'common-masters',
                    masterDetails: [
                        {
                            name: 'GenderType',
                            filter: '[?(@.active == true)]'
                        }
                    ]
                }
            ]
        }
    };

    try {
        const res = await fetchDropdownData(dropdownPayload);
        const options = {
            specialTransferType: res['Estate']['SpecialTransferType'],
            ownershipGender: res['common-masters']['GenderType'],
            ownershipHighestQualification: [
                { name: 'Post Graduate', code: 'POST_GRADUATE' },
                { name: 'Graduate or Diploma', code: 'GRADUATE_OR_DIPLOMA' }
            ],
            gender: res['common-masters']['GenderType']
        };
        return options;
    } catch (e) {
        console.log(e);
    }
};

export const hdfcPaymentCheckout = () => {
    return 'https://checkout.razorpay.com/v1/checkout.js';
};

export const endpoints = {
    fileStoreApi: '/filestore/v1/files/url',
    createEntity: '/user/enterprise/nologin/entity/_create',
    updateEntity: '/user/enterprise/nologin/entity/_update',
    deleteExistingUser: '/user/enterprise/nologin/entity/delete',
    deleteEmployee: '/egov-hrms/employees/nologin/_delete',
    searchEmployee: `/egov-hrms/employees/_search?tenantId=${TENANT_ID}`,
    searchEmployeeNoLogin: `/egov-hrms/employees/nologin/_search?tenantId=${TENANT_ID}`,
    employeeWorkflow: `/egov-hrms/employees/workflow/process/_initiate`,
    searchExistingUser: '/user/enterprise/entity/_search',
    searchExistingUserNoLogin: '/user/enterprise/nologin/entity/_search',
    getCommonDocument: '/egov-common-masters/idc/document/_search',
    updateOwner: '/user/enterprise/nologin/owner/_update',
    initiateEnterpriseWorkflow: '/user/enterprise/workflow/process/_initiate',
    initiateEnterpriseWorkflowNoLogin: '/user/enterprise/nologin/workflow/process/_initiate',
    downloadTemplate: '/egov-common-masters/idc/document/_search?documentCategories=ENTERPISE_REGISTRATION',
    nextValidAction: '/egov-workflow-v2/egov-wf/_action',
    nextValidActionNoLogin: '/user/enterprise/nologin/workflow/process/_actions',
    updateEmpProfile: '/egov-hrms/employees/profile-update/_initiate',
    updateEmpProfileSearch: '/egov-hrms/employees/profile-update/_search',
    initiateNocComment: '/noc-services/v2/noc/workflow/process/_initiate',
    createNoc: '/noc-services/v2/noc/_create',
    initiateTransaction: '/pg-service/transaction/v1/_create',
    updateTransaction: '/pg-service/transaction/v1/_update',
    createMigration: '/idc-estate-service/data-migration/_create',
    updateMigration: '/idc-estate-service/data-migration/_update',
    searchMigration: '/idc-estate-service/data-migration/_search',
    getBankDetails: '/egov-common-masters/utils/bank-details',
    workflowSearch: '/egov-workflow-v2/egov-wf/process/_search?tenantId=ga&history=true',
    transferCreate: '/idc-estate-service/transfer/_create',
    transferUpdate: '/idc-estate-service/transfer/_update',
    transferSearch: '/idc-estate-service/transfer/_search',
    entityRefDetails: '/idc-estate-service/entity-reference-details/_search',
    leaseSpecialProject: '/idc-estate-service/enterprise-details/_search',
    createAllotment: '/idc-estate-service/estate-application/_create',
    updateAllotment: '/idc-estate-service/estate-application/_update',
    searchAllotment: '/idc-estate-service/estate-application/_search',
    leaseDeedCreate: '/idc-legal-service/legal/v1/deed/_create',
    leaseDeedSearch: '/idc-legal-service/legal/v1/deed/_search',
    leaseDeedWorkflow: '/idc-legal-service/legal/v1/deed/workflow/process/_initiate',
    createSubLease: '/idc-estate-service/sub-lease-details/_create',
    updateSubLease: '/idc-estate-service/sub-lease-details/_update',
    searchSubLease: '/idc-estate-service/sub-lease-details/_search',
    paymentGateway: '/pg-service/idc-payment-gateway/transaction/_initiate',
    transactionUpdate: '/pg-service/transaction/v1/_update',
    paymentDetails: '/pg-service/idc-payment-gateway/payment-details/_update',
    leaseDeedUpdate: '/idc-legal-service/legal/v1/deed/_update',
    feeMasterDetailsSearch: '/idc-estate-service/fee-master-details/_search',
    paymentDetailsSearch: '/idc-estate-service/payment-details/_search',
    scheduledPaymentDetailsSearch: '/idc-estate-service/scheduled-payment-details/_search',
    allotmentDetailsSearch: '/idc-estate-service/allotments/_search',
    calculateWorkingDays: '/egov-common-masters/utils/calculate-due-date',
    migrationDocumentSearch: '/egov-common-masters/documents/migration-documents/_search',
    scheduledPaymentDetails: '/idc-estate-service/scheduled-payment-details/pending-payments/_verify',
    schedulePaymentUnpaid: '/idc-estate-service/scheduled-payment-details/_searchUnpaidFeeDetails',
    consolidatedDocSearch: '/idc-estate-service/estate/consolidated-doc/_search',
    consolidatedDocESign: '/idc-estate-service/estate/consolidated-doc/_esign',
    estateWorkflowInitiate: '/idc-estate-service/estate/workflow/process/_initiate',
    checklistValidationSearch: '/idc-estate-service/checklist-validation/_search',
    legalDeedDocumentSearch: '/idc-legal-service/legal/v1/deed/documents/_search',
    documentSeach: '/egov-common-masters/documents/_search',
    correspondenceDocGenerate: '/egov-common-masters/documents/correspondence-doc/_generate',
    notingDocGenerate: '/egov-common-masters/documents/noting-documents/_generate',
    estateCreation: '/idc-estate-service/master-application/_create',
    allotmentActionSearch: '/idc-estate-service/allotments-valid-action/_search',
    esignInitiate: '/idc-doc-esign-services/esign/_initiate',
    feeDetailsSearch: '/idc-estate-service/fee-details/_search',
    waterChargesByDiameter: '/ws-services/water-master/_search-connection-fee',
    createWaterConnection: '/ws-services/wc/_create-application',
    waterConnectionInitiate: '/ws-services/water/workflow/process/_initiate',
    validateWaterConnection: '/ws-services/wc/_get-validate-application',
    searchUserCertificates: '/ws-services/wc/_search-user-certificate',
    waterConnectionSearch: '/ws-services/wc/_search-application',
    getAllWaterConnection: '/ws-services/water-connection/_search',
    submitWaterConnection: '/ws-services/water-connection/_create',
    calculationByMeterReading: '/ws-services/meter-reading/_calculate',
    meterReadingUpdate: '/ws-services/meter-reading/_update',
    updateWaterConnection: '/ws-services/water-connection/_update',

    getwaterConnectionDocuments: '/ws-services/water-document/document/_search',
    esignApprove: '/idc-estate-service/estate/workflow/application/_approve',
    validApplication: '/idc-estate-service/apply-valid-action/_search',
    createMortgage: '/idc-estate-service/mortgage-details/_create',
    updateMortgage: '/idc-estate-service/mortgage-details/_update',
    searchMortgage: '/idc-estate-service/mortgage-details/_search',
    estateApplicationCreate: '/idc-estate-service/estate-application/_create',
    dashboardDocumentSearch: '/idc-estate-service/estate-dashboard/doc-details/_search',
    paymentSearchViaTxnID: '/pg-service/transaction/v1/_search',
    commercialCertificateSearch: '/idc-estate-service/commercial-ops-details/_search',
    commercialCertificateCreate: '/idc-estate-service/commercial-ops-details/_create',
    createQueryEntry: '/egov-common-masters/query-details/_create',
    searchQueryEntry: '/egov-common-masters/query-details/_search',
    updateQueryEntry: '/egov-common-masters/query-details/_update',
    initiateQueryEntry: '/egov-common-masters/common-workflow/process/_initiate',
    waterConnectionBillSearch: '/ws-services/meter-reading/_search',
    otherDocumentSearch: '/idc-estate-service/estate-dashboard/other-doc-details/_search',
    architectCreateNoLogin: '/egov-hrms/employees/nologin/_create',
    architectUpdateNoLogin: '/egov-hrms/employees/nologin/_update',
    employeeCreation: '/egov-hrms/employees/_create',
    employeeCreationV2: '/egov-hrms/employees/v2/_create',
    employeeUpdation: '/egov-hrms/employees/_update',
    newDashboardDetails: '/egov-common-masters/application/search/dashboard',
    createPost: '/egov-common-masters/idc/document/_create',
    updatePost: '/egov-common-masters/idc/document/_update',
    createTenderPost: '/egov-common-masters/tender/_create',
    updateTender: '/egov-common-masters/tender/_update',
    dprNumber: '/egov-idgen/id/_generate',
    dprCreate: '/idc-works-service/dpr/_create',
    dprUpdate: '/idc-works-service/dpr/_update',
    dprSearchApplication: '/idc-works-service/dpr/_search',
    nitSearchNumber: '/idc-works-service/dpr/_search',
    inspectionReportSearch: '/idc-estate-service/inspection-details/_search',
    inspectionReportUpdate: '/idc-estate-service/inspection-details/_update',
    inspectionReportInitiateWorkflow: '/idc-estate-service/inspection-details/workflow/process/_initiate',
    inspectionReportApprove: '/idc-estate-service/inspection-details/workflow/application/_forward',
    estateDropDown: '/idc-estate-service/estate-manager/_search',
    gsrMasterList: '/idc-works-service/gsr/_search?isActive=true',
    applicationAccess: '/egov-common-masters/common-workflow/application-access/_validation',
    createGsr: '/idc-works-service/gsr/_create',
    updateGsr: '/idc-works-service/gsr/_update',
    searchGsr: '/idc-works-service/gsr/_search?isActive=true',
    downloadGsrTemplate: '/idc-works-service/gsr/excel/_generateTemplate',
    gsrImport: '/idc-works-service/gsr/excel/_import',
    managerMetaSearch: '/idc-estate-service/estate-manager/metadata/_search',
    managerSearch: '/idc-estate-service/estate-manager/_search',
    managerCreate: '/idc-estate-service/estate-manager/_create',
    managerUpdate: '/idc-estate-service/estate-manager/_update',
    nitCreate: '/idc-works-service/tender-details/_create',
    nitUpdate: '/idc-works-service/tender-details/_update',
    nitSearch: '/idc-works-service/tender-details/_search',
    dprValidActions: '/idc-works-service/works/workflow/_action',
    worksWorkflowInitiate: '/idc-works-service/works/workflow/process/_initiate',
    loaCreate: '/idc-works-service/loa/_create',
    worksRelatedDocument: '/idc-works-service/related-application/_search',
    worksCorrespondenceDocument: '/idc-works-service/documents/_search',
    loaUpdate: '/idc-works-service/loa/_update',
    loaSearch: '/idc-works-service/loa/_search',
    workOrderCreate: '/idc-works-service/work-order/_create',
    workOrderUpdate: '/idc-works-service/work-order/_update',
    workOrderSearch: '/idc-works-service/work-order/_search',
    createContract: '/idc-works-service/contract/_create',
    updateContract: '/idc-works-service/contract/_update',
    searchContract: '/idc-works-service/contract/_search',
    createRaBill: '/idc-works-service/ra-bill/_create',
    searchRaBill: '/idc-works-service/ra-bill/_search',
    createRaBillItem: '/idc-works-service/ra-bill-item/_create',
    updateRaBillItem: '/idc-works-service/ra-bill-item/_update',
    createContractClosure: '/idc-works-service/contract-closure/_create',
    searchContractClosure: '/idc-works-service/contract-closure/_search',
    updateContractClosure: '/idc-works-service/contract-closure/_update',
    raBillType: '/idc-works-service/ra-bill/_types',
    updateRaBill: '/idc-works-service/ra-bill/_update',
    contractExtensionCreate: '/idc-works-service/contract-extension/_create',
    contractExtensionSearch: '/idc-works-service/contract-extension/_search',
    contractExtensionUpdate: '/idc-works-service/contract-extension/_update',
    latestGsrDownload: '/idc-works-service/gsr/excel/_latestGsrDownload',
    enterpriseUserUpdate: '/user/profile/email-mobile/_updatenovalidate',
    migrationMISReport: '/idc-estate-service/migration-report/mis/_generate',
    migrationDataEntryReport: '/idc-estate-service/migration-report/report/_generate',
    kycSubmit: '/dsc-services/eSign/v1/registration/_submit',
    kycInitiate: '/dsc-services/eSign/v1/_initiate',
    paymentDetailsDelete: '/idc-estate-service/data-migration/payment-details/_delete'
};

export const estateEndpoints = {
    documentCreate: '/egov-workflow-v2/egov-wf/process/document/_create',
    advSearch: '/idc-estate-service/advertisement/_search?',
    estateSearch: '/idc-estate-service/estate-master-details/_search',
    plotSearch: '/idc-estate-service/plot/_search',
    relatedApplicationSearch: '/egov-common-masters/related-application/_search'
};
