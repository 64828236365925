import React, { useEffect, useRef, useState } from 'react';
import '../UI components/Buttons/Button.scss';
import DownArrow from '../../assets/logos/down_arrow.svg';
import ExportMenu from './ExportMenu';
import _ from 'lodash';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useLocation } from 'react-router-dom';
import GenericButtons from '../UI components/Buttons/GenericButtons';

const GenericExportDropdown = (props: any) => {
    const { disabled, size, variant, ClassName, type, columns, rowData, additionalPdfData, userType, gsrTitle } = props;
    const [isDropdown, setIsDropdown] = useState(false);
    const toggleDropdown = () => setIsDropdown(!isDropdown);
    const { pathname } = useLocation();
    const exportRef = useRef<any>(null);
    // In this object we have to add more key and values, for different titles.
    const pathObject: any = {
        '/goa-idc/empsearch': 'Employee_Search',
        '/goa-idc/tender': 'Tender_Report',
        '/goa-idc/viewNotification': 'Notifications',
        '/goa-idc/estate/apply-for-plot': 'Search_Advertisement',
        '/goa-idc/estate/estateRegistry': 'E-state_registry',
        '/goa-idc/architectdashboard': 'Architect_Dashboard',
        '/goa-idc/usermanagement/searchapplication': 'Search_Application',
        '/goa-idc/dashboard': 'Dashboard',
        '/goa-idc/enterprisedashboard': 'Enterprise_Dashboard',
        '/goa-idc/scrutinysharedplans': 'Scrutiny_Shared_Plans',
        '/goa-idc/estate/estate-registry': 'Estate_Master_Data',
        '/goa-idc/estate/plot-registry': `${userType === 'EMPLOYEE' ? 'Plot_Master_Data' : 'Vacant_Plot_Details'}`,
        '/goa-idc/estate/allotment/workflow': 'Scrutiny_Report',
        '/goa-idc/gsr-master-details': gsrTitle,
        '/goa-idc/dpr-creation': 'Abstract_Details'
    };

    const fileName = pathObject[pathname] || 'save_report';

    const processData = () => {
        let data: any = [];
        rowData?.map((oneRow: any) => {
            let allValues: any = [];
            let obj: any = {};
            columns.map((oneCol: any) => {
                let value = `${_.get(oneRow, oneCol.fieldName)} ${oneCol.fieldName2 ? _.get(oneRow, oneCol.fieldName2) : ''}`;
                allValues.push(value);
                obj[oneCol.colName] = value;
            });
            data.push(obj);
        });

        return data;
    };

    const onCSVExport = () => {
        toggleDropdown();
        let headers: any = [];
        columns?.map((oneCol: any) => {
            headers.push(oneCol.colName);
        });

        let data = rowData?.map((oneRow: any) => {
            let allValues: any = [];
            columns.map((oneCol: any) => {
                let value = `${_.get(oneRow, oneCol.fieldName)} ${oneCol.fieldName2 ? _.get(oneRow, oneCol.fieldName2) : ''}`;
                allValues.push(value);
            });
            return allValues?.join(',');
        });

        let csvString = `${headers.join(',')}\n${data.join('\n')}`;

        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const onExcelExport = () => {
        toggleDropdown();
        let data = processData();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const exportToCSV = (apiData: any) => {
            const ws = XLSX.utils.json_to_sheet(apiData);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        };

        exportToCSV(data);
    };

    const onExportPdf = () => {
        toggleDropdown();
        const data = processData();
        const heading = props?.additionalPdfData || '';
        const date = props?.additionalPdfData ? 'Date :' : '';

        const generatePdfFromData = (dataArray: any) => {
            // const doc = new jsPDF();
            const doc = new jsPDF('l', 'pt');

            doc.setFontSize(12); // Set font size for the heading
            doc.text(heading, 40, 25); // Add heading text at (x: 10, y: 20)
            // doc.text(date, 500, 20); // Add heading text at (x: 10, y: 20)
            doc.setFont('Inter', 'bold');

            const columns = Object.keys(dataArray[0]);
            const rows = dataArray.map((obj: any) => Object.values(obj));
            // @ts-ignore
            doc.autoTable({
                head: [columns],
                body: rows,
                theme: 'grid',
                headStyles: { fillColor: [69, 133, 46] }
                // startY: 100
            });
            doc.save(`${fileName}.pdf`);
        };

        generatePdfFromData(data);
    };

    useEffect(() => {
        const handleOutsideClick = (e: any) => {
            if (exportRef.current && !exportRef.current.contains(e.target)) {
                setIsDropdown(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return !additionalPdfData ? (
        <>
            <div ref={exportRef} id='export_button' className={`d-inline-flex flex-row align-items-center justify-content-around stickyCard`} style={{ zIndex: '999' }}>
                <button
                    onClick={toggleDropdown}
                    type={type ? type : 'button'}
                    disabled={disabled}
                    className={`${ClassName} ${size === 'sm' ? 'Button_small' : 'Button_medium'} Export_button`}
                    style={{ backgroundColor: `${variant === 'primary' && '#45852E'}`, color: `${variant === 'primary' && '#fff'}` }}
                >
                    <span>Export As</span> <span className='Export_button_saperator' /> <img alt='' className='Export_button_downarrow' src={DownArrow} />
                </button>
                {isDropdown && <ExportMenu onCSVExport={onCSVExport} onExcelExport={onExcelExport} onExportPdf={onExportPdf} />}
            </div>
        </>
    ) : (
        <GenericButtons text={'Print'} handleClick={onExportPdf} />
    );
};

export default GenericExportDropdown;
