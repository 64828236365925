import { isDisabled } from '@testing-library/user-event/dist/utils';
import { formFieldDTO } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import _ from 'lodash';

export const SEARCH_NIT_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'TENDER_APPLICATION_NUMBER',
                title: 'Enter Tender Application No.',
                field: 'tenderNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-5'
                // isRequired: true
            },
            {
                titleCode: 'INDUSTRIAL_ESTATE',
                title: 'Industrial Estate',
                field: 'estate',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            }
        ]
    }
];
//table
export const DPR_DATA_COLUMN: any = [
    {
        titleCode: '',
        colName: '',
        fieldName: 'radio',
        clsName: 'column-text-start col-1'
    },
    {
        titleCode: 'Tender_NO',
        colName: 'Tender No',
        fieldName: 'tenderNo',
        clsName: 'column-text-start col-3'
    },
    {
        titleCode: 'TENDER_DOCUMENT_AVAILABILITY_DATE',
        colName: 'Tender Document Availability Date',
        fieldName: 'tenderDocumentAvailabilityDate',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'LAST_DATE_OF_ONLINE_SUBMISSION',
        colName: 'Last Date of Online Application',
        fieldName: 'lastDateOfOnlineApplication',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'SUBMISSION_END_DATE',
        colName: 'Submission End Date',
        fieldName: 'tenderSubmissionEndDate',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'BID_OPENING_DATE',
        colName: 'Bid Opening Date',
        fieldName: 'bidOpenStartDate',
        clsName: 'column-text-start col-2'
    }
];

//Correspondence
export const LOA_CORRESPONDENCE = [
    {
        title: '(A) DPR Details',
        field: 'dprDetails'
    },
    {
        title: '(B) Bidder Details',
        field: 'bidderDetails'
    },
    {
        title: '(C) LOA Details',
        field: 'loaDetails'
    }
];

//pop-up fields
export const BIDDER_FIELDS_I: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Name of Bidder',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
                // class: 'col-12'
            },
            {
                title: 'Mobile Number',
                field: 'mobileNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            }
        ]
    }
];

export const BIDDER_FIELDS_II = [
    {
        titleCode: 'Address Details',
        title: 'Address Details',
        formData: [
            {
                title: 'Address Line 1',
                field: 'addressLine1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2',
                field: 'addressLine2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isRequired: true
                // disableCondition: (values: any) => {
                //     const pincode = _.get(values, 'pincode');
                //     return !pincode || (_.get(values, 'pincode') && !_.get(values, 'district'));
                // }
            },
            {
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isRequired: true
                // disableCondition: (values: any) => {
                //     const pincode = _.get(values, 'pincode');
                //     return !pincode || (_.get(values, 'pincode') && !_.get(values, 'district'));
                // }
            },
            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isRequired: true
                // disableCondition: (values: any) => {
                //     const pincode = _.get(values, 'pincode');
                //     return !pincode || (_.get(values, 'pincode') && !_.get(values, 'district'));
                // }
            }
        ]
    }
];

export const LOA_DETAILS_FIELDS: any[] = [
    {
        formData: [
            {
                title: 'Project Execution Period (Days)',
                field: 'periodConsumed',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Bid Amount',
                field: 'bidPrice',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'BID_AMOUNT_DEVIATION',
                title: 'Bid Amount Deviation',
                field: 'bidAmountDeviation',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'ESTIMATE_TOTAL_COST',
                title: 'Estimate Total Cost',
                field: 'estimatedTotalCost',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'DEVIATION',
                title: 'Deviation (in %)',
                field: 'deviation',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'BID_SUBMISSION_DATE',
                title: 'Bid Submission Date',
                field: 'bidSubmissionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true,
                isRequired: true
            },
            {
                title: 'Performance Guarantee Amount',
                field: 'perfGuaranteeAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                renderCondition: (values: any) => _.get(values, `tenderType`) === 'NORMAL'
            },
            {
                title: 'Performance Guarantee Amount',
                field: 'perfGuaranteeAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                renderCondition: (values: any) => _.get(values, `tenderType`) === 'SHORT'
            },
            {
                title: 'Performance Guarantee validity date',
                field: 'perfGuaranteeValidityDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, `tenderType`) === 'NORMAL'
            },
            {
                title: 'Performance Guarantee validity date',
                field: 'perfGuaranteeValidityDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true,
                renderCondition: (values: any) => _.get(values, `tenderType`) === 'SHORT'
            }
        ]
    }
];

export const loaDetailValues = (resData: any) => {
    const bidderInitValues: any = {};

    bidderInitValues['name'] = resData?.bidderDetails?.name;
    bidderInitValues['mobileNo'] = resData?.bidderDetails?.mobileNo;
    bidderInitValues['emailId'] = resData?.bidderDetails?.emailId;
    bidderInitValues['addressLine1'] = resData?.bidderDetails?.addressLine1;
    bidderInitValues['addressLine2'] = resData?.bidderDetails?.addressLine2;
    bidderInitValues['state'] = resData?.bidderDetails?.state;
    bidderInitValues['country'] = resData?.bidderDetails?.country;
    bidderInitValues['pincode'] = resData?.bidderDetails?.pin;
    bidderInitValues['district'] = resData?.bidderDetails?.district;
    bidderInitValues['bidPrice'] = resData?.bidPrice;
    bidderInitValues['bidSubmissionDate'] = resData?.bidSubmissionDate;
    bidderInitValues['perfGuaranteeAmount'] = resData?.perfGuaranteeAmount;
    bidderInitValues['perfGuaranteeValidityDate'] = resData?.perfGuaranteeValidityDate;
    bidderInitValues['bidAmountDeviation'] = resData?.bidAmountDeviation;
    bidderInitValues['deviation'] = resData?.deviationPercentage;

    return bidderInitValues;
};

export const LOA_APPLICATION_SEARCH_COLUMN: any = [
    {
        titleCode: 'SERIAL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'column-text-start col-1'
    },
    {
        titleCode: 'LOA_NO',
        colName: 'LOA No',
        fieldName: 'loaNumber',
        clsName: 'column-text-start col-3',
        isClickable: true
    },
    {
        titleCode: 'PROJECT_NAME',
        colName: 'Project Name',
        fieldName: 'projectName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'INDUSTRIAL_NAME',
        colName: 'Industrial Estate Name',
        fieldName: 'industrialName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'PROJECT_TYPE',
        colName: 'Project Type',
        fieldName: 'projectType',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'ESTIMATE_AMOUNT',
        colName: 'Estimate Amount',
        fieldName: 'estimateAmount',
        clsName: 'column-text-start col-2'
    }
];

export const LOA_APPLICATION_SEARCH_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'LOA_NUMBER',
                title: 'Enter LOA Number',
                field: 'loaNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-5',
                isRequired: true
            },
            {
                titleCode: 'INDUSTRIAL_ESTATE',
                title: 'Industrial Estate',
                field: 'estate',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            }
        ]
    }
];

export const DPR_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'DPR_NO',
                title: 'DPR No.',
                field: 'dprNumber',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                class: 'col-6 me-3'
            },
            {
                title: 'Project Name',
                field: 'projectName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-12'
            },
            {
                title: 'Estate',
                field: 'estate',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            },
            {
                title: 'Project Type',
                field: 'projectType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            },
            {
                title: 'Nature of Work',
                field: 'natureOfWork',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            },
            {
                title: 'Period Consumed (No. of Days)',
                field: 'periodConsumed',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            }
        ]
    }
];
