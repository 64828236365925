import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { POST, requestInfo, devUrl, endpoints } from '../../../../services/modulesServices/userManagement.api';
import { cross, check } from '../../../../utils/Logo';
import { DOWNLOAD_TYPE_STEP, DROPDOWN_DATA, MIGRATION_REPORT } from './ReportsDashboard.const';

export default function ReportDashboard() {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});

    const formik = useFormik<any>({
        initialValues: { isUpdatable: true, reportName: '' },
        onSubmit: () => {}
    });
    const { values } = formik;

    const handleModalClose = (type: string) => {
        setModalDetails({});
    };

    const downloadReport = async () => {
        const selectedReport = values.reportName;
        const apiUrl = selectedReport.code === '1' ? endpoints.migrationMISReport : selectedReport.code === '2' ? endpoints.migrationDataEntryReport : '';
        if (!apiUrl) {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error'),
                para: t('INVALID_REPORT_TYPE_SELECTED.', 'Invalid report type selected.'),
                img: cross,
                type: 'error',
                reUpload: t('OK', 'OK')
            });
            return;
        }
        try {
            setIsLoading(true);
            const response = await POST(apiUrl, { RequestInfo: requestInfo() });
            const fileStoreId = response.data.reportAttachment;
            const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${fileStoreId}`;
            window.location.href = downloadFileUrl;
            setModalDetails({
                show: true,
                title: t('SUCCESS', 'Success'),
                para: t('FILE_DOWNLOADED_SUCCESSFULLY', 'Report downloaded successfully.'),
                img: check,
                type: 'success',
                reUpload: t('OK', 'OK')
            });
        } catch (error) {
            console.error('Error while downloading report:', error);
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('SOMETHING_WENT_WRONG_WHILE_DOWNLOADING_THE_REPORT', 'Something went wrong while downloading the report. Please try again.'),
                img: cross,
                type: 'error',
                reUpload: t('OK', 'OK')
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <Loading />}
            <HeaderComponent />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader text={t('REPORTS', 'Reports')} />
                <div className='col-12 form-section'>
                    <FormikProvider value={formik}>
                        <Card className='card_border'>
                            <Card.Body style={{ marginBottom: '-22px' }}>
                                <div className='row my-0'>
                                    {MIGRATION_REPORT.map(val => (
                                        <GenericFormCard key={val.formData[0].field} {...val} dropdownOptions={DROPDOWN_DATA} />
                                    ))}
                                </div>
                            </Card.Body>
                        </Card>
                    </FormikProvider>
                </div>
            </div>
            <footer className='footer fixed-bottom footerBtnPatch'>
                <div className='d-flex justify-content-end container me-3' style={{ padding: 15 }}>
                    <GenericButtons text='Download' variant='primary' disabled={!values.reportName} handleClick={downloadReport} />
                </div>
            </footer>
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
        </>
    );
}