import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { FormikProvider, useFormik } from 'formik';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import { Card, Form } from 'react-bootstrap';
import ScrollToTop from '../../../../components/ScrollToTop';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import PopUps from '../../../../components/PopUps';
import { useTranslation } from 'react-i18next';
import DprCorrespondence from './DprCorrespondence';
import { dropdownDataOptions } from '../../Migration/Migration.controller';
import { devUrl, endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { CONTINGENCIES_VALIDATION, DPR_VALIDATION, MEASUREMENT_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { dprDropdownDataOptions, getCalculatedValue } from './DPR.controller';
import { getFirstErrorKey } from '../../../../utils/utils';
import { check, cross } from '../../../../utils/Logo';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { initialMeasurements, setDprMeasurmentData } from './DPR.const';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import { getHistory, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument, strToNum } from '../../EstateManagement/EstateManagement.controller';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { setDprHeaderData } from './DPR.const';
import { TENANT_ID, USER_TYPE } from '../../../../utils/constants';
import { EsignModal } from '../../../commonPages/EsignModal';
import { TemplateCard } from '../../EstateManagement/TemplatesCard';

const DprWorkflow = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [content, setContent] = useState('');
    const [dprNumber, setDprnumber] = useState<any>(null);
    const [contingenciesData, setContingenciesData] = useState<any>([]);
    const [measurementData, setMeasurementData] = useState<any>([]);
    const [abstractData, setAbstractData] = useState<any>([]);
    const [show, setShow] = useState<any>(false);
    const [abstractCost, setAbstractCost] = useState<number>(0);
    const [contingenciesCost, setContingenciesCost] = useState<number>(0);
    const estimatedTotalCost = abstractCost + contingenciesCost;
    const [estateData, setEstateData] = useState<any>([]);
    const [reminderPopUp, setReminderPopUp] = useState<boolean>(false);
    const [resData, setResData] = useState<any>(null);
    const [modalData, setModalData] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [tableData, setTableData] = useState<any>([]);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [dropdownDataGsr, setDropdownDataGsr] = useState<any>({
        gsrName: [],
        gsrItemNo: []
    });
    const [refId, setRefId] = useState<any>(null);
    const [approvedEsignData, setApprovedEsignData] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>('');
    const [latestGsrItemDocRefId, setLatestGsrItemDocRefId] = useState<any>('');

    const totalPages = 2;
    let navigate = useNavigate();
    const { t } = useTranslation();
    const location: any = useLocation();
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userUuid = getUserDetails?.uuid;
    let user = localStorage.getItem('currentUserAssignment');
    const storedDprNumber = localStorage.getItem('dprNumber');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const [searchParams] = useSearchParams();
    const applicationNumber: any = searchParams.get('applicationNumber');
    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const action: any = searchParams.get('action');
    const eSignTried: boolean = JSON.parse(searchParams.get('eSignTried') || 'false');
    const success: boolean = JSON.parse(searchParams.get('success') || 'true');
    console.log('designation', designation);
    const users = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = users?.type;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            itemType: 'Scheduled',
            gsrMarketRate: 'GSR',
            lbdOrNonlbd: 'LBD',
            measurements: [initialMeasurements],
            esignOption: 'DSC'
        },
        onSubmit: () => {},
        validationSchema: show ? MEASUREMENT_VALIDATION(measurementData) : DPR_VALIDATION
    });
    const { values, setValues, errors, setFieldValue, setFieldTouched, setTouched } = formik;
    console.log(formik);

    useEffect(() => {
        if (userType !== USER_TYPE.EMP) {
            window.location.href = '/goa-idc/access-restricted';
        }

        return () => {
            localStorage.removeItem('dprNumber');
        };
    }, [location]);

    useEffect(() => {
        if (note && action && nextValidAction && signedFileStoreId) {
            setFieldValue('notes', note);
            if (eSignTried && !success) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('E_SIGN_FAILED', 'e-Sign Failed'), img: cross, type: 'error', reUpload: 'OK' });
            } else if (success) {
                handleOnNextValidActions({ action: action }, note);
            }
        }
    }, [nextValidAction]);

    const signDocument = () => {
        const eSignParam = new URLSearchParams(searchParams);
        eSignParam.delete('note');
        eSignParam.delete('action');
        eSignParam.delete('eSignTried');
        eSignParam.delete('signedFileStoreId');
        eSignParam.delete('success');
        let url = window.location.origin + `/goa-idc/dpr-creation?${eSignParam.toString()}&note=${values.notes}&action=${actionAfterEsign}&eSignTried=true`;
        url = btoa(url);

        if (values.esignOption === 'EKYC') {
            const userRequestInfo = requestInfo();
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
        }
    };

    const toGetDprNumber = async () => {
        try {
            const { data } = await POST(endpoints.dprNumber, {
                RequestInfo: requestInfo(),
                idRequests: [
                    {
                        idName: 'idc.works.dprNumber',
                        tenantId: 'ga',
                        format: ''
                    }
                ]
            });
            const uniqueDprNumber = data?.idResponses[0]?.id;

            localStorage.setItem('dprNumber', uniqueDprNumber);
            return uniqueDprNumber;
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        (async () => {
            let dropdownOption;
            let estateDropdown;
            let dropdowDataCheck;
            try {
                dropdownOption = await dprDropdownDataOptions();

                const { data } = await POST(endpoints.estateDropDown + `?userUuids=${userUuid}&isEstateDetailsRequired=true`, { RequestInfo: requestInfo() });
                const estateValues = data?.estateManagers?.map((val: any) => {
                    return { estateName: val?.estateDetails?.estateName, estateId: val?.estateDetails?.uuid };
                });
                setEstateData(estateValues);
                estateDropdown = estateValues.map((value: any) => {
                    return { name: value?.estateName, code: value?.estateName };
                });
                dropdowDataCheck = { ...dropdownOption, estate: estateDropdown };
                setDropdownData(dropdowDataCheck);
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }

            let uniqueDprNumber;
            if (!storedDprNumber && dprNumber === null) {
                uniqueDprNumber = await toGetDprNumber();
                setDprnumber(uniqueDprNumber);
            } else if (storedDprNumber) {
                setDprnumber(storedDprNumber);
            }

            let resCheck;

            if (dropdownOption && estateDropdown) {
                if (uniqueDprNumber || storedDprNumber || applicationNumber) {
                    try {
                        const { data } = await POST(endpoints.dprSearchApplication + `?dprNos=${applicationNumber || dprNumber || storedDprNumber}`, { RequestInfo: requestInfo() });
                        const gsrRes = await POST(endpoints.latestGsrDownload + `?dprNo=${applicationNumber || dprNumber || storedDprNumber}`, { RequestInfo: requestInfo() });
                        const gsrLatestDocFileId = gsrRes?.data?.id;
                        setLatestGsrItemDocRefId(gsrLatestDocFileId);
                        if (data?.dprHeaderDetails?.length) {
                            resCheck = data?.dprHeaderDetails[0];
                            setResData(data?.dprHeaderDetails[0]);
                            setDprnumber(data?.dprHeaderDetails[0]?.dprNo);
                            if (designation === 'MD') {
                                setRefId(data?.dprHeaderDetails[0]?.adminApprovalDocRefId);
                            } else {
                                setRefId(data?.dprHeaderDetails[0]?.tsDocRefId);
                            }
                            // setRefId(data?.dprHeaderDetails[0]?.adminApprovalDocRefId ? data?.dprHeaderDetails[0]?.adminApprovalDocRefId : data?.dprHeaderDetails[0]?.tsDocRefId);

                            setContent(data?.dprHeaderDetails[0]?.adminApprovalDocRefId ? data?.dprHeaderDetails[0]?.adminApprovalContent : data?.dprHeaderDetails[0]?.content);
                            localStorage.setItem('dprNumber', data?.dprHeaderDetails[0]?.dprNo);

                            const initData = await setDprHeaderData(data?.dprHeaderDetails[0], dropdowDataCheck);

                            setValues((prev: any) => ({ ...prev, ...initData }));
                        }
                    } catch (e: any) {
                        let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                        setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    }
                }
            }

            if (applicationNumber && resCheck) {
                try {
                    const nextActions = await POST(endpoints.dprValidActions + `?businessId=${applicationNumber}&tenantId=${TENANT_ID}${resCheck ? '&amount=' + resCheck.estimatedTotalCost : ''}`, {
                        RequestInfo: requestInfo()
                    });
                    setNextValidAction(nextActions.data);
                    setFieldValue('isUpdatable', isViewOnly ? !isViewOnly : nextActions.data.isUpdatable);

                    const historyData = await getHistory(applicationNumber);
                    setHistoryDetails(historyData);
                } catch (e) {
                    console.log(e);
                }
            }
            return () => {
                localStorage.removeItem('dprNumber');
            };
        })();
    }, []);

    useEffect(() => {
        if (resData && dropdownData) {
            (async () => {
                try {
                    const rowData = await setDprMeasurmentData(resData?.dprItemModelList, dropdownData);
                    if (resData?.dprItemModelList) {
                        setMeasurementData(rowData);
                        setAbstractData(rowData);
                    }
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [resData, dropdownData]);

    const toCalculateCosts = () => {
        const abstractTotalCost = getCalculatedValue(abstractData);
        const percentage = abstractTotalCost >= 10000000 ? 3 : 5;
        const contingenciesRowData = [
            {
                ...(resData && resData?.contingencyDetailsList?.length ? resData?.contingencyDetailsList[0] : {}),
                costHead: 'Contingencies',
                percentage: percentage,
                cost: Math.round((abstractTotalCost / 100) * percentage * 100) / 100,
                remarks: ''
            }
        ];
        const contingenciesTotalCost = getCalculatedValue(contingenciesRowData);

        setAbstractCost(abstractTotalCost);
        setContingenciesCost(contingenciesTotalCost);
        if (abstractData.length) {
            setContingenciesData(contingenciesRowData);
        }
    };

    useEffect(() => {
        toCalculateCosts();
    }, [abstractData]);

    useEffect(() => {
        const getEstateId = estateData?.find((val: any) => values?.estate?.code === val?.estateName);
        setValues((prevVal: any) => ({ ...prevVal, estateId: getEstateId?.estateId }));
    }, [estateData, values?.estate?.code]);

    useEffect(() => {
        if ((activeIndex === 2 || !!isViewOnly) && documentDetails) {
            const tableData = async () => {
                const responseTableData = documentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${documentDetails.length - ind}` };
                });
                setTableData(responseTableData);
            };

            tableData();
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        const getCorrespondenceDocument = async () => {
            try {
                setIsLoading(true);
                const { data } = await POST(endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber || dprNumber}&businessService=${nextValidAction?.businessService}`, {
                    RequestInfo: requestInfo()
                });
                setDocumentDetails(data?.documents);

                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (nextValidAction) {
            getCorrespondenceDocument();
        }
    }, [nextValidAction]);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        if (applicationNumber || dprNumber) {
            try {
                const docs: any =
                    file.referenceId &&
                    (await onAttachDocument(
                        dprNumber || applicationNumber,
                        file?.referenceId,
                        designationName,
                        nextValidAction ? nextValidAction?.businessService : 'DPR',
                        historyDetails.length ? historyDetails[0].id : null,
                        true
                    ));
                setDocumentDetails(docs.documents);
                setDocumentsUuid([...documentsUuid, docs.uuid]);
                setActiveIndex(2);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const commonPayload = () => {
        const gstAmount = (estimatedTotalCost / 100) * 18;
        console.log('measurementData>>', measurementData);
        // Step 1: Calculate totalQuantity across all measurements in measurementData
        // const totalQuantityOfData = measurementData?.reduce((totalAcc: number, val: any) => {
        //     return totalAcc + (val.totalQuantity || 0); // Accumulate the total across all measurement items
        // }, 0);

        // console.log('Total Quantity across all measurements:', totalQuantityOfData);
        const measurementPayloadData = measurementData?.map((val: any) => {
            console.log('measurements>>', val);

            return {
                ...val,
                itemOrderNo: val?.itemNo || null,
                itemDesc: val?.itemDesc || null,
                measurementType: val?.measurementTypeCode || null,
                lbdMeasurementType: val?.lbdOrNonlbd || null,
                remarks: val.remarks,
                totalQuantity: val.totalQuantity,
                documentRefId: val.document?.docReferenceId,
                unitOfMeasurement: val?.unitOfMeasurement || null,
                unitRate: val?.unitRate || null,
                itemCost: val?.cost || null,
                isActive: !val.isDelete,
                gsrMasterDetailsModel:
                    val?.gsrMarketRate === 'GSR'
                        ? {
                              ...val.gsrMasterDetailsModel,
                              uuid: val.gsrUuid || val.gsrMasterDetailsModel.uuid,
                              gsrYear: val?.gsrYear || null,
                              gsrName: val?.gsrName || null,
                              itemNo: val?.gsrItemNo || null,
                              itemDesc: val?.itemDesc || null,
                              unitOfMeasurement: val?.unitOfMeasurement || null,
                              gsrUnitRate: val?.gsrUnitRate || null,
                              isActive: true
                          }
                        : null,
                dprItemMeasurementDetailsModelList: val.measurements?.map((measurementItem: any) => ({
                    ...measurementItem,
                    itemQuantity: strToNum(measurementItem.quantity),
                    quantity: strToNum(measurementItem.quantity)
                }))
            };
        });

        const dprHeaderDetails = {
            ...resData,
            dprNo: dprNumber,
            projectName: values?.projectName,
            estateId: values?.estateId || resData?.estateId,
            projectType: values?.projectType?.code,
            natureOfWork: values?.natureOfWork?.code,
            periodConsumed: +values?.periodConsumed,
            estimatedTotalCost: Math.ceil(abstractCost + contingenciesCost + gstAmount),
            contingencyCost: contingenciesCost?.toFixed(2),
            abstractCost: abstractCost?.toFixed(2),
            gstAmount: gstAmount?.toFixed(2),
            content: content,
            // tsDocRefId: signedFileStoreId ? signedFileStoreId : resData?.tsDocRefId,
            // itemMeasurementDetailsList: measurementPayloadData?.length ? measurementPayloadData : null,
            dprItemModelList: measurementPayloadData?.length ? measurementPayloadData : null,
            contingencyDetailsList: contingenciesData?.length ? contingenciesData : null,
            isTsDocUpdate: resData?.status === 'TECHNICAL_SENCTION_APPROVED' ? false : true
        };
        return dprHeaderDetails;
    };
    const handelPrimaryAction = async (isEsignUpdate?: boolean) => {
        const payload = commonPayload();
        try {
            if (!resData) {
                const { data } = await POST(endpoints.dprCreate, {
                    RequestInfo: requestInfo(),
                    dprHeaderDetails: {
                        ...payload,
                        isTsDocUpdateRequired: isEsignUpdate && designation === 'GM' ? true : false,
                        isAdminDocUpdateRequired: isEsignUpdate && designation === 'MD' ? true : false
                    },
                    dprWfDocumentIds: !applicationNumber ? (values.file?.referenceId ? [values.file?.referenceId] : undefined) : resData.dprDocumentIds
                });
                setResData(data?.dprHeaderDetails[0]);
                setContent(data?.dprHeaderDetails[0]?.content);
                if (designation === 'MD') {
                    setRefId(data?.dprHeaderDetails[0]?.adminApprovalDocRefId);
                } else {
                    setRefId(data?.dprHeaderDetails[0]?.tsDocRefId);
                }
                const rowData = await setDprMeasurmentData(data?.dprHeaderDetails[0]?.dprItemModelList, dropdownDataGsr);
                data?.dprHeaderDetails[0]?.dprItemModelList && setMeasurementData(rowData);
                setTouched({});
                !isEsignUpdate &&
                    setModalDetails({
                        show: true,
                        title: 'Success',
                        para: t('DPR_CREATED_SUCCESSFULLY', 'DPR Created Successfully!'),
                        img: check,
                        type: 'Create success',
                        reUpload: 'OK'
                    });
            } else {
                const { data } = await POST(endpoints.dprUpdate, {
                    RequestInfo: requestInfo(),
                    dprHeaderDetails: {
                        ...payload,
                        isTsDocUpdateRequired: isEsignUpdate && designation === 'GM' ? true : false,
                        isAdminDocUpdateRequired: isEsignUpdate && designation === 'MD' ? true : false
                    },
                    dprWfDocumentIds: !applicationNumber ? (values.file?.referenceId ? [values.file?.referenceId] : undefined) : resData.dprDocumentIds,
                    notes: values.notes || note
                });
                setResData(data?.dprHeaderDetails[0]);
                setContent(data?.dprHeaderDetails[0]?.content);
                if (designation === 'MD') {
                    setRefId(data?.dprHeaderDetails[0]?.adminApprovalDocRefId);
                } else {
                    setRefId(data?.dprHeaderDetails[0]?.tsDocRefId);
                }
                const rowData = await setDprMeasurmentData(data?.dprHeaderDetails[0]?.dprItemModelList, dropdownDataGsr);

                data?.dprHeaderDetails[0]?.dprItemModelList && setMeasurementData(rowData);
                setTouched({});
                !isEsignUpdate &&
                    setModalDetails({
                        show: true,
                        title: 'Success',
                        para: t('DPR_UPDATED_SUCCESSFULLY', 'DPR Updated Successfully!'),
                        img: check,
                        type: 'Create success',
                        reUpload: 'OK'
                    });
            }
        } catch (e: any) {
            console.log('e>>>', e);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onSubmit = async (currentStateCode?: any) => {
        const payload = commonPayload();
        try {
            await DPR_VALIDATION.validate(values, { abortEarly: false, context: values });
            if (!measurementData?.length) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('MEASUREMENTDATA_REQUIRED', 'Atleast Add one Measurement Data'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            try {
                if (!resData) {
                    const { data } = await POST(endpoints.dprCreate, {
                        RequestInfo: requestInfo(),
                        dprHeaderDetails: { ...payload, isWorkflowInitiate: currentStateCode === 'Draft' ? false : true },
                        dprWfDocumentIds: !applicationNumber ? (values.file?.referenceId ? [values.file?.referenceId] : undefined) : resData.dprDocumentIds,
                        notes: values.notes || note
                    });
                    setResData(data?.dprHeaderDetails[0]);
                    setContent(data?.dprHeaderDetails[0]?.content);
                    // setRefId(data?.dprHeaderDetails[0]?.tsDocRefId);
                    const rowData = await setDprMeasurmentData(data?.dprHeaderDetails[0]?.dprItemModelList, dropdownDataGsr);
                    data?.dprHeaderDetails[0]?.dprItemModelList && setMeasurementData(rowData);
                    setTouched({});
                    setModalDetails({
                        show: true,
                        title: 'Success',
                        para: t('DPR_CREATED_SUCCESSFULLY', 'DPR Created Successfully!'),
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                    });
                } else {
                    if (!values.notes) {
                        setModalDetails({ show: true, title: 'Warning!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
                        return;
                    }
                    const { data } = await POST(endpoints.dprUpdate, {
                        RequestInfo: requestInfo(),
                        dprHeaderDetails: { ...payload, isWorkflowInitiate: true },
                        dprWfDocumentIds: !applicationNumber ? (values.file?.referenceId ? [values.file?.referenceId] : undefined) : resData.dprDocumentIds,
                        notes: values.notes || note
                    });
                    setResData(data?.dprHeaderDetails[0]);
                    setContent(data?.dprHeaderDetails[0]?.content);
                    // setRefId(data?.dprHeaderDetails[0]?.tsDocRefId);
                    const rowData = await setDprMeasurmentData(data?.dprHeaderDetails[0]?.dprItemModelList, dropdownDataGsr);
                    data?.dprHeaderDetails[0]?.dprItemModelList && setMeasurementData(rowData);
                    setTouched({});
                    setModalDetails({
                        show: true,
                        title: 'Success',
                        para: t('DPR_CREATED_SUCCESSFULLY', 'DPR Updated Successfully!'),
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                    });
                }
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const extraBtnAction = async () => {
        try {
            await DPR_VALIDATION.validate(values, { abortEarly: false, context: values });
            await handelPrimaryAction();
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleNextPage = async () => {
        try {
            // if (dprNumber && !applicationNumber) {
            const { data } = await POST(endpoints.worksCorrespondenceDocument + `?businessId=${dprNumber || applicationNumber}&businessService=${'DPR'}`, {
                RequestInfo: requestInfo()
            });
            setDocumentDetails(data?.documents);
            // }
        } catch (e) {}
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };
    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const handleOnNextValidActions = async (status: any, comment: string) => {
        if (!values?.notes && !note) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        const payload = {
            RequestInfo: requestInfo(),
            dprRequest: {
                dprHeaderDetails: {
                    ...resData,
                    content: content,
                    // adminApprovalDocRefId: designation === 'MD' && signedFileStoreId
                    // ? signedFileStoreId
                    // : resData?.adminApprovalDocRefId
                    //   ? resData.adminApprovalDocRefId
                    //   : null,
                    adminApprovalDocRefId: designation === 'MD' && signedFileStoreId ? signedFileStoreId : resData?.adminApprovalDocRefId,
                    tsDocRefId: designation === 'GM' && signedFileStoreId ? signedFileStoreId : resData?.tsDocRefId
                }
            },
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction.moduleName,
                    action: status.action,
                    comment: '',
                    notes: values.notes || note
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : null
        };

        if ((!signedFileStoreId && status.isEsignRequired) || !success) {
            // esign(payload, status.action);
            await handelPrimaryAction(true);
            setActionAfterEsign(status.action);
            setEsignModal(true);
            return;
        }
        try {
            setIsLoading(true);
            const res = await POST(endpoints.worksWorkflowInitiate, payload);
            if (res.data) {
                const successMessage = getSuccessMessage(status?.action, applicationNumber);
                setModalDetails({ show: true, title: 'Success!', para: successMessage, img: check, type: 'success', reUpload: 'OK', finalSubmit: true });
                setIsLoading(false);
            }
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={isViewOnly ? t('DPR_VIEW', 'DPR View') : t('DPR_CREATION', 'DPR Creation')}
                            additionalClass='sub-text-style'
                            bottomSubText={`${isViewOnly ? '' : 'Type : DPR Creation,'} No : ${dprNumber}`}
                        />

                        <FormikProvider value={formik}>
                            {!isViewOnly ? (
                                <>
                                    <div className='col-sm-3 mt-3 stickyCard-section '>
                                        <div className='stickyCard' style={{ top: '15%' }}>
                                            <GenericHeader
                                                text={t('DPR_CREATION', 'DPR Creation')}
                                                sideHeader={true}
                                                additionalClass='sub-text-style'
                                                bottomSubText={`Type : DPR Creation, No : ${dprNumber} `}
                                            />

                                            <NotingWrapper
                                                history={historyDetails}
                                                handleOnFileUpload={handleOnFileUpload}
                                                applicationNumber={applicationNumber}
                                                nextValidAction={nextValidAction}
                                                // disable={!nextValidAction?.nextValidAction?.length}
                                            />
                                            <div className='mt-3'>
                                                <TemplateCard docReferenceId={latestGsrItemDocRefId}></TemplateCard>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            <div className={`${isViewOnly ? 'col-sm-12' : 'col-sm-9'} mt-3 form-section`}>
                                <CorrespondenceWrapper applicationNumber={''} designation={''} nextValidAction={nextValidAction} isViewOnly={isViewOnly} />
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <DprCorrespondence
                                                dropdownData={dropdownData}
                                                content={content}
                                                setContent={setContent}
                                                t={t}
                                                show={show}
                                                setShow={setShow}
                                                setModalDetails={setModalDetails}
                                                contingenciesData={contingenciesData}
                                                measurementData={measurementData}
                                                setMeasurementData={setMeasurementData}
                                                setDropdownData={setDropdownData}
                                                abstractData={abstractData}
                                                setAbstractData={setAbstractData}
                                                setReminderPopUp={setReminderPopUp}
                                                estimatedTotalCost={estimatedTotalCost}
                                                contingenciesCost={contingenciesCost}
                                                setDropdownDataGsr={setDropdownDataGsr}
                                                dropdownDataGsr={dropdownDataGsr}
                                                responseData={resData}
                                                isViewOnly={isViewOnly}
                                                tableData={tableData}
                                                onRowClick={onRowClick}
                                            />
                                        )}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tableData} additionalMethod={onRowClick} />
                                        )}
                                        {/* {activeIndex === 3 && (
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        )} */}
                                    </Form>
                                </Card>
                            </div>
                            {isViewOnly ? (
                                <></>
                            ) : (
                                <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                    <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                        <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                    </span>
                                    Page {activeIndex} of {totalPages}
                                    <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                        <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                    </span>
                                </div>
                            )}
                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                    </div>
                    <ScrollToTop />
                    {!isViewOnly ? (
                        <GenericFooterComponent
                            activeIndex={0}
                            handleOnPrimaryAction={handleOnNextValidActions}
                            onSubmit={onSubmit}
                            applicationNo={applicationNumber || null}
                            nextValidAction={nextValidAction}
                            stepperLength={0}
                            extraButton={values.isUpdatable ? 'Save' : ''}
                            extraButtonAction={extraBtnAction}
                        />
                    ) : (
                        <></>
                    )}

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                    <GenericPopupModal
                        secondaryAction={() => {
                            setReminderPopUp(false);
                        }}
                        primaryAction={() => {
                            setReminderPopUp(false);
                            handelPrimaryAction();
                        }}
                        isVisible={reminderPopUp}
                        title='Save Confirmation'
                        size='md'
                        children={
                            <div>
                                <div className='mx-4 my-3'>
                                    <div className='mt-4'>
                                        <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                            {t('SAVE_CHANGES', 'Looks like you have some unsaved changes. Would you like to save them before proceeding with the new data?')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        primaryBtnTitle='Yes'
                        secondaryBtnTitle='No'
                        // onClose={() => setReminderPopUp(false)}
                    />
                </>
            )}
        </>
    );
};

export default DprWorkflow;
