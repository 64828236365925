import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { Card, CardBody, Form, Image } from 'react-bootstrap';
import GenericCommonTextAreaField from '../moduleCommonFormik/GenericCommonTextAreaField.component';
import attachmentIcon from '../../assets/logos/attachment.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { devUrl } from '../../services/modulesServices/userManagement.api';
import { extractFileUrl } from '../../utils/utils';
import { ThreeDots } from 'react-loader-spinner';
import { cross } from '../../utils/Logo';
import { useTranslation } from 'react-i18next';
import StatusAlertPopup from '../moduleCommonFormik/StatusAlertPopup';

const NotingComponent = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [history, setHistory] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const { t } = useTranslation();

    const { values, errors, touched, setFieldTouched, handleChange, setFieldValue } = useFormikContext<any>();

    useEffect(() => {
        const notingData = props.history?.filter((item: any) => item.notes);
        console.log(notingData, props?.history);
        setHistory(notingData);
    }, [props?.history]);

    const handleOnChange = async (e: any) => {
        const file: any = e.target.files && e.target.files[0];
        const tempExtension = file?.name && file?.name?.split('.');
        const extension = tempExtension[tempExtension?.length - 1].toLowerCase();

        if (extension === 'pdf') {
            try {
                if (!file) {
                    console.error('No file selected.');
                    return;
                }

                // DO IF NEEDED
                // if (!(fileExtensionType === 'image/jpeg' || fileExtensionType === 'image/jpg' || fileExtensionType === 'application/pdf')) {
                //     toast(t('FILE_UPLOAD_UNSUCCESSFUL', 'File upload unsuccessful'), {
                //         position: toast.POSITION.TOP_RIGHT,
                //         className: 'foo-bar',
                //         type: 'error'
                //     });
                //     return;
                // }

                setIsLoading(true);
                try {
                    const response: any = await axios.post(
                        devUrl + '/filestore/v1/files',
                        {
                            file: file,
                            tenantId: 'ga',
                            tag: file.name,
                            module: 'hrms-upload'
                        },
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    );

                    const fileResponseUrl = await axios.get(devUrl + '/filestore/v1/files/url', {
                        params: {
                            tenantId: 'ga',
                            fileStoreIds: response.data.files[0].fileStoreId
                        }
                    });
                    const fileData = {
                        documentName: fileResponseUrl?.data?.fileStoreIds[0].tag,
                        documentSize: fileResponseUrl?.data?.fileStoreIds[0].fileSize,
                        referenceId: fileResponseUrl?.data?.fileStoreIds[0].id,
                        documentUrl: extractFileUrl(fileResponseUrl.data.fileStoreIds[0].url),
                        uploadedDate: fileResponseUrl?.data?.fileStoreIds[0].uploadedDate
                    };

                    setFieldValue('file', fileData);
                    setIsLoading(false);
                    props.handleOnFileUpload(fileData);
                    // props.handleOnFileUpdate(fileData);
                } catch (error) {
                    setIsLoading(false);
                    let errMsg = t('FILE_UPLOAD_UNSUCCESSFUL', 'File upload unsuccessful');
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    return;
                }
            } catch (e) {
                console.log(e);
                setIsLoading(false);
            }
        } else {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('INVALID_FILE_ONLY_PDF_MESSAGE', 'Please upload .pdf files only'), image: cross, type: 'error', reUpload: 'OK' });
        }
        // console.log(e.currentTarget.value.slice(12));
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
    };

    return (
        <>
            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                <Card className='noting-outer-area p-2 pt-1'>
                    <CardBody>
                        <div className='row noting-note-part justify-content-center  px-1 pt-2 '>
                            <div className='col-12 notes font-inter p-2 mb-2  '>
                                <Form>
                                    <GenericCommonTextAreaField
                                        field={'notes'}
                                        title={''}
                                        isDisabled={props?.disable ? props.disable : props.nextValidAction ? !props.nextValidAction?.nextValidAction?.length : false}
                                    />
                                </Form>
                            </div>
                            {/* <span className='note-divider'></span> */}
                            {isLoading ? (
                                <ThreeDots visible={true} height='80' width='80' color='#45852e' radius='9' ariaLabel='three-dots-loading' wrapperStyle={{}} wrapperClass='' />
                            ) : (
                                !props.disable && (
                                    <div className='col-12 note-attachfile text-end my-1 p-2'>
                                        <Image src={attachmentIcon} alt='Attach Document Icon' /> <span className='cursor-pointer'>Attach Document</span>
                                        <input className='uploadBox upload-document cursor-pointer' type='file' onChange={handleOnChange} />
                                    </div>
                                )
                            )}
                        </div>
                        <div className='row justify-content-center mt-3'>
                            <div className='col-12 d-flex justify-content-evenly align-items-center p-0  '>
                                <span className='noting-history'></span>
                                <span>HISTORY</span>
                                <span className='noting-history'></span>
                            </div>
                            {history?.map((value: any, ind: number) =>
                                value.notes ? (
                                    <div className='col-12  mt-2' key={ind}>
                                        <div className='row'>
                                            <div className='col-1 d-flex z-3   p-0 history-track-line '>
                                                <span className='history-track text-white  '> {`N${history?.length - ind}`}</span>
                                            </div>
                                            <div className={`col-11 pb-2 ${ind === history?.length ? '' : 'history-border-bottom'} `}>
                                                <p className='note-history note-by-name'>{value.assignerName}</p>
                                                <p className='note-history note-by-designation'>{value.assignerDesignation}</p>
                                                <p className='note-history note-comment mt-2'>{value.notes}</p>
                                                <p className='note-history note-date-time mt-1'>{value.assignedDate}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )
                            )}
                        </div>
                    </CardBody>
                </Card>

                <StatusAlertPopup
                    title={modalDetails.title}
                    para={modalDetails.para}
                    img={modalDetails.img}
                    show={modalDetails.show}
                    handleClose={() => handleModalClose(modalDetails.type)}
                    type={modalDetails.type}
                    reUpload={modalDetails.reUpload}
                    handleSubmit={() => handleModalClose(modalDetails.type)}
                />
            </div>
        </>
    );
};
export default NotingComponent;
