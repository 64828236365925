import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../../components/header/Header.component';
import Loading from '../../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import { Card, Form } from 'react-bootstrap';
import ScrollToTop from '../../../../../components/ScrollToTop';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import PopUps from '../../../../../components/PopUps';
import { useSearchParams } from 'react-router-dom';
import EmployeeRegistrationCorrespondence from './EmployeeRegistrationCorrespondence';
import { endpoints, getDropdownOptions, getEmployeeData, getFileUrl, POST, requestInfo, submitAction } from '../../../../../services/modulesServices/userManagement.api';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../../EstateManagement/Allotment/Allotment.const';
import { EMPLOYEE_CREATE_APPLICATION_FORM } from '../../../../../utils/formikValidation/FormikValidation';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import { InitialValues } from './EmployeeRegistration.const';
import { getHistory, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../../../EstateManagement/EstateManagement.controller';
import { check, cross } from '../../../../../utils/Logo';
import { getFirstErrorKey } from '../../../../../utils/utils';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { v4 as uuidv4 } from 'uuid';
import { dropdownDataOptions } from '../../../Migration/Migration.controller';
import { getInitialData } from '../../UserMangement.controller';
import { EMPLOYEE_AUTHORIZED } from '../../../../../utils/constants';

const EmployeeRegistration = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [tabelData, setTabelData] = useState<any>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [documents, setDocuments] = useState<any>([]);
    const [employeePayload, setEmployeePayload] = useState<any>(null);

    const totalPages = 2;

    const { t } = useTranslation();
    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const [searchParams] = useSearchParams();
    const docsUuid = searchParams.get('docsUuid');
    const applicationNumber: any = searchParams.get('applicationNumber');
    const newUuid = uuidv4();

    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;

    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            ...InitialValues
        },
        onSubmit: () => {},
        validationSchema: EMPLOYEE_CREATE_APPLICATION_FORM
    });

    const { values, setValues, errors, setFieldTouched } = formik;

    useEffect(() => {
        getDropdownOptions()
            .then(res => {
                setDropdownData(res);
            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }

        if (userType === 'EMPLOYEE') {
            (async () => {
                try {
                    setIsLoading(true);
                    const nextActions = await getNextActions(applicationNumber);
                    setNextValidAction(nextActions);

                    const historyData = await getHistory(applicationNumber);
                    setHistoryDetails(historyData);
                    setIsLoading(false);
                } catch (e: any) {
                    setIsLoading(false);
                    let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, []);

    useEffect(() => {
        const fetchEmployeeData = async () => {
            if (applicationNumber && dropdownData && nextValidAction) {
                try {
                    const response = await getEmployeeData(applicationNumber);
                    const initData = await getInitialData(response.data.Employees[0], dropdownData);
                    // if (nextValidAction?.isUpdatable !== true) {
                    setEmployeePayload(response.data.Employees[0]);
                    // }
                    await getDoumentsData(response);
                    setValues((prev: any) => ({
                        ...prev,
                        ...initData,
                        isUpdatable: isViewOnly ? !isViewOnly : nextValidAction?.isUpdatable,
                        underProcess: true
                    }));
                } catch (err: any) {
                    let errMsg = err.response?.data?.errorMessage || t('AN_ERROR_OCCURRED', 'An error occurred');
                    setModalDetails({ show: true, title: 'ERROR', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }
        };
        userType === 'EMPLOYEE' && fetchEmployeeData();
    }, [dropdownData, applicationNumber, nextValidAction]);

    const getDoumentsData = async (res: any) => {
        let docs = res.data.Employees[0].documents;
        if (Array.isArray(docs) && docs.length) {
            const previewData: any = await Promise.all(
                docs?.map(async (item: any) => {
                    let documentUrl = await getFileUrl(item.referenceId);
                    return { ...item, documentUrl };
                })
            );
            setDocuments(previewData);
        }
    };

    useEffect(() => {
        // const reverseDocumentDetails = documentDetails?.reverse();
        // const tableData = async () => {
        //     const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
        //         return { ...data, serialNo: `C${ind + 1}` };
        //     });
        //     const reverseData = responseTableData.reverse();
        //     setTabelData(reverseData);
        // };
        const tableData = async () => {
            const responseTableData = documentDetails?.map((data: any, ind: number) => {
                return { ...data, serialNo: `C${documentDetails.length - ind}` };
            });
            setTabelData(responseTableData);
        };
        if (activeIndex === 2) {
            tableData();
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        const getCorrespondenceDocument = async () => {
            try {
                setIsLoading(true);
                const docs = await getWorkflowDocumentSearch(applicationNumber, nextValidAction?.businessService);
                setDocumentDetails(docs);
                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (nextValidAction && userType === 'EMPLOYEE') {
            getCorrespondenceDocument();
        }
    }, [nextValidAction]);

    const handleNextPage = () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any =
                file.referenceId &&
                (await onAttachDocument(
                    applicationNumber ? applicationNumber : newUuid,
                    file?.referenceId,
                    designationName,
                    nextValidAction ? nextValidAction?.businessService : 'EMP',
                    historyDetails?.length ? historyDetails[0].id : null
                ));
            setDocumentDetails(docs?.documents);
            setDocumentsUuid([...documentsUuid, docs?.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    const commonPayload = () => {
        const mergeRoles: any = [];

        const assignments = values.assignment.map((val: any) => {
            mergeRoles.push(...(val?.roles || ''));
            return {
                tenantid: 'ga',
                fromDate: new Date(val.fromDate).getTime(),
                isCurrentAssignment: val?.isCurrentAssignment?.name === 'Yes' ? true : false,
                department: val.department?.code,
                designation: val.designation?.code,
                isPrimary: val.isPrimary,
                roles: val?.roles?.length && val?.roles,
                toDate: val?.isCurrentAssignment?.name === 'No' ? new Date(val.toDate).getTime() : null
            };
        });

        const education =
            values?.qualifications?.length &&
            values.qualifications.map((val: any) => ({
                tenantid: 'ga',
                qualification: val?.qualification?.code,
                stream: val?.stream?.code,
                yearOfPassing: +val.yearOfPassing,
                university: val.university,
                remarks: val.remarks
            }));
        const userRoles =
            mergeRoles.length &&
            mergeRoles?.map((role: any) => ({
                code: role.code,
                name: `ACCESSCONTROL_ROLES_ROLES_${role.name}`,
                tenantId: 'ga'
            }));
        const docs = documents.map((item: DocumentInfo) => {
            const { documentSize, lastModified, uploadedDate, documentUrl, ...doc } = item;
            return doc;
        });
        const payload = {
            RequestInfo: requestInfo(),
            Employees: [
                {
                    ...employeePayload,
                    employeeStatus: values.status?.code || null,
                    employeeType: values.empType?.code || null,
                    dateOfAppointment: new Date(values.appointmentDate).getTime() || null,
                    IsActive: true,
                    tenantId: 'ga',
                    assignments: assignments?.length && assignments,
                    education: values?.qualifications?.length ? education : null,
                    documents: docs,
                    user: {
                        ...employeePayload?.user,
                        salutation: values.firstnamesalutation || '',
                        registrationNumber: applicationNumber || null,
                        firstName: values.firstname || '',
                        lastName: values.lastname || '',
                        dob: new Date(values.dob).getTime(),
                        mobileNumber: values.phoneNo || '',
                        emailId: values.emailId || '',
                        gender: values?.gender?.code || '',
                        permanentAddress1: values.peraddress1 || '',
                        permanentAddress2: values.peraddress2 || '',
                        permanentDistrict: values.perdistrict || '',
                        permanentState: values.perstate || '',
                        permanentCountry: values.percountry || '',
                        permanentPinCode: values.perpincode || '',
                        correspondenceAddress1: values.address1 || '',
                        correspondenceAddress2: values.address2 || '',
                        correspondenceDistrict: values.district || '',
                        correspondenceState: values.state || '',
                        correspondenceCountry: values.country || '',
                        correspondencePinCode: values.pincode || '',
                        pan: (values.identityDetailsDropdown?.name === 'Pan Card' && values.documentNo) || '',
                        passport: (values.identityDetailsDropdown?.name === 'Passport' && values.documentNo) || '',
                        tenantId: 'ga',
                        roles: userRoles || ''
                    },
                    isFinalSubmit: !!employeePayload ? false : true
                }
            ],
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    notes: values.notes
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean)
        };
        return payload;
    };
    const onSubmit = async (e: any) => {
        // e.stopPropagation();
        const payload = commonPayload();
        try {
            await EMPLOYEE_CREATE_APPLICATION_FORM.validate(values, { abortEarly: false, context: values });

            if (!values.notes) {
                setModalDetails({ show: true, title: 'Warning!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            try {
                const { data } = await POST(endpoints.employeeCreationV2, payload);
                if (data) {
                    setModalDetails({
                        show: true,
                        title: 'Success',
                        para: t('EMPLOYEE_CREATED_SUCCESSFULLY', 'Employee created successfully'),
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                    });
                }
            } catch (e: any) {
                let errMsg = e?.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                setIsLoading(false);
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onPrimaryAction = async (status: any, comment: string) => {
        const empPayload = commonPayload();
        const payload = {
            processInstances: [
                {
                    tenantId: 'ga',
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes
                }
            ],
            employees: [empPayload.Employees[0]],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean),
            isStateUpdatable: nextValidAction?.isUpdatable
        };

        if (!values.notes) {
            setModalDetails({ show: true, title: 'Warning!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            if (values.isUpdatable === true) {
                await EMPLOYEE_CREATE_APPLICATION_FORM.validate(values, { abortEarly: false, context: values });
            }

            try {
                const res = await submitAction(payload);
                if (res) {
                    const successMessage = getSuccessMessage(status?.action, applicationNumber);
                    setModalDetails({
                        show: true,
                        title: 'Success',
                        para: successMessage ? successMessage : t('FORWARDED_SUCCESSFULLY', 'Forwarded Successfully'),
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                    });
                }
            } catch (e: any) {
                let errMsg = e?.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                setIsLoading(false);
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={t('EMPLOYEE_REGISTRATION', 'Employee Registration')}
                            additionalClass='sub-text-style'
                            bottomSubText={applicationNumber ? `Type : Employee Registration, No : ${applicationNumber}` : ''}
                        />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('EMPLOYEE_REGISTRATION', 'Employee Registration')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={applicationNumber ? `Type : Employee Registration, No : ${applicationNumber}` : ''}
                                    />

                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={isViewOnly ? isViewOnly : !nextValidAction?.nextValidAction.length && designation !== 'AM'}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper applicationNumber={''} designation={''} nextValidAction={''} />
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <EmployeeRegistrationCorrespondence dropdownData={dropdownData} documents={documents} setDocuments={setDocuments} setDropdownData={setDropdownData} />
                                        )}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={() => {}} />
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>
                        </FormikProvider>
                    </div>
                    <ScrollToTop />
                    {!isViewOnly ? (
                        <GenericFooterComponent
                            activeIndex={1}
                            onSubmit={!employeePayload ? onSubmit : () => {}}
                            handleOnPrimaryAction={onPrimaryAction}
                            nextValidAction={nextValidAction}
                            applicationNo={applicationNumber || null}
                            stepperLength={1}
                            hideSaveBtn={true}
                        />
                    ) : (
                        <></>
                    )}

                    {/* {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )} */}
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} userType={userType} />
                </>
            )}
        </>
    );
};

export default EmployeeRegistration;
