import _ from 'lodash';
import { AUTHORIZED_PERSON_TITLE, CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';

export const declarationList = [
    '(i) the information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed herein.',
    '(ii) it has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy, insolvency or liquidation proceedings.',
    '(iii) the provision allotment order or the lease deed, as the case may be, shall be liable to be cancelled if there is any misdeclaration or misrepresentation of the information submitted under or along with this application.'
];

export const TRANSFEREE_FORM_STEPS = [
    {
        titleCode: 'TRANSFEREE_DETAILS',
        name: 'Transferee Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'TRANSFEROR_DETAILS',
        name: 'Transferor Details',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'TRANSFER_DETAILS',
        name: 'Transfer Details',
        index: 3,
        isActive: true
    },
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        index: 4,
        isActive: true
    },
    {
        titleCode: 'ENTERPRISE_DETAILS',
        name: 'Enterprise Details',
        index: 5,
        isActive: false
    },

    {
        titleCode: 'SELF_DECLARATION',
        name: 'Self Declaration',
        index: 6,
        isActive: false
    }
];

export const SP_TRANSFEREE_FORM_STEPS = [
    {
        titleCode: 'TRANSFEREE_DETAILS',
        name: 'Transferee Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'TRANSFEROR_DETAILS',
        name: 'Transferor Details',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'TRANSFER_DETAILS',
        name: 'Transfer Details',
        index: 3,
        isActive: true
    },
    {
        titleCode: 'SELF_DECLARATION',
        name: 'Self Declaration',
        index: 4,
        isActive: false
    }
];

export const DOCUMENT_TYPE = {
    ENTITY: 'ENTITY',
    OTHER: 'OTHER',
    AUTHUSER: 'AUTHUSER',
    OWNER: 'OWNER',
    LOCALPERSON: 'LOCALPERSON',
    EDUCATION: 'EDUCATION',
    PAN: 'PAN',
    ENTITY_CATEGORY: {
        PROPRIETORSHIPFIRM: 'PROPRIETORSHIPFIRM',
        IDENTITY: 'IDENTITY',
        TRUST_DEED: 'TRUST_DEED',
        PARTNERSHIP_DEED: 'PARTNERSHIP_DEED',
        CERTIFICATE_OF_REGISTRATION_FIRMS: 'CERTIFICATE_OF_REGISTRATION_FIRMS',
        CERTIFICATE_OF_INCORPORATION: 'CERTIFICATE_OF_INCORPORATION',
        REGISTRATION_OF_SOCITIES: 'REGISTRATION_OF_SOCITIES',
        GOVT_NOTIFICATION: 'GOVT_NOTIFICATION',
        GST: 'GST',
        NON_GST: 'GST_NON_APPLICABILITY'
    },
    OTHER_CATEGORY: {
        UDYAMREGISTRATION: 'UDYAMREGISTRATION',
        DPIIT: 'DPIIT',
        WOMENENTREPRENUEUR: 'WOMENENTREPRENUEUR',
        EXPERIENCED: 'EXPERIENCED'
    },
    AUTH_CATEGORY: {
        POWEROFATTORNEY: 'POWEROFATTORNEY'
    },
    DETAILED_PROJECT_REPORT: 'DETAILED_PROJECT_REPORT',
    IN_PRINCIPAL_APPROVAL: 'IN_PRINCIPAL_APPROVAL',
    MOHA_FDI_UNDERTAKING_APPROVAL: 'MOHA_FDI_UNDERTAKING_APPROVAL',
    SPECIAL_PROJECT: 'SPECIAL_PROJECT',
    SUB_LEASE_ORDER: 'SUB_LEASE_ORDER',
    ALLOTMENT_ORDER: 'PROVISIONAL_ALLOTMENT_ORDER',
    ALLOTMENT: 'ALLOTMENT',
    TRANSFER_ORDER: 'TRANSFER_ORDER',
    TRANSFER: 'TRANSFER',
    EXTENSION_ORDER: 'EXTENSION_ORDER',
    EXTENSION: 'EXTENSION',
    EXECUTED_LD: 'EXECUTED_LD',
    REGISTERED_LD: 'REGISTERED_LD',
    EXECUTED_TD: 'EXECUTED_TD',
    REGISTERED_TD: 'REGISTERED_TD',
    EXECUTED_EXT_LD: 'EXECUTED_EXT_LD',
    REGISTERED_EXT_LD: 'REGISTERED_EXT_LD',
    NOC_SPECIAL_PROJECT: 'NOC_SPECIAL_PROJECT',
    OCCUPANCY_DETAILS: 'OCCUPANCY_DETAILS'
};

export const TRANSFEREE_DETAILS_SEARCH: any = [
    {
        title: 'Name',
        formData: [
            {
                title: 'Transferee Name',
                titleCode: 'TRANSFEREE_NAME',
                field: 'applicantName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Transferee Category',
                titleCode: 'TRANSFEREE_CATEGORY',
                field: 'category',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Letter stating the name of the Proprietorship firm',
                titleCode: 'PROPRIETORSHIP_FIRM_NAME',
                field: 'entityProprietorshipFirm',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PROP_FIRM',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Identity of the proprietor',
                titleCode: 'IDENTITY_OF_PROPRIETOR',
                field: 'entityProprietorIdentity',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PROP_FIRM',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Trust Deed',
                titleCode: 'TRUST_DEED',
                field: 'trustDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => {
                    return _.get(value, `category`)?.code === 'TRUST';
                },
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                class: 'col-sm-6 me-3',
                isRequired: true
            },
            {
                title: 'Partnership Deed',
                titleCode: 'PARTNERSHIP_DEED',
                field: 'partnershipDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PARTNERSHIP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Certificate of registration from the Registrar of Firms',
                titleCode: 'REGISTRATION_CERTIFICATE_FIRMS',
                field: 'certificateOfRegistrationFirms',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PARTNERSHIP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Certificate of incorporation from Registrar of Companies',
                titleCode: 'INCORPORATION_CERTIFICATE',
                field: 'certificateOfIncorporation',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'LIMITED_LIABILITY_PARTNERSHIP' || _.get(value, `category`)?.code === 'LTD_COMPANY',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                class: 'col-sm-6 me-3',
                isRequired: true
            },
            {
                title: 'Certificate of registration from the Registrar of Societies',
                titleCode: 'REGISTRATION_CERTIFICATE_SOCIETIES',
                field: 'certificateOfRegistrationSocieties',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'COOP_SOCIETY',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                class: 'col-sm-6 me-3',
                isRequired: true
            },
            {
                title: 'Copy of relevant notification',
                titleCode: 'RELEVANT_NOTIFICATION',
                field: 'copyRelevantNotification',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'GOV_STAT_CORP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                class: 'col-sm-6 me-3',
                isRequired: true
            }
        ]
    }
];

export const APPLICANT_DETAILS = [
    {
        formData: [
            {
                titleCode: 'EMAIL_ID',
                title: 'Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'PHONE_NO',
                title: 'Phone No',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                isDisabled: true
            },

            // {
            //     titleCode: 'GST_APPLICABLE',
            //     title: 'Is GST Applicable?',
            //     field: 'applicantGst',
            //     type: CONSTANT_DATA_FIELD.RADIO_FIELD,
            //     options: [
            //         { value: true, title: 'Yes' },
            //         { value: false, title: 'No' }
            //     ],
            //     class: 'col-sm-12'
            // },
            // {
            //     titleCode: 'GST_NO',
            //     title: 'GST No',
            //     field: 'applicantGstno',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     condition1: (values: any) => _.get(values, `applicantGst`) === true,
            //     isDisabled: true
            // },
            {
                titleCode: 'PAN_NO',
                title: 'PAN No',
                field: 'applicantPanNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-sm-6 me-3'
            },
            {
                title: 'PAN Card',
                titleCode: 'PAN_CARD',
                field: 'applicantPancard',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                hideFileSize: true,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.PAN,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                isDisabled: true
            }
        ]
    },

    {
        titleCode: 'ADDRESS_OF_TRANSFEREE',
        title: 'Address of the Transferee',
        formData: [
            {
                title: 'Address Line 1',
                titleCode: 'ADDRESS_LINE_1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2',
                titleCode: 'ADDRESS_LINE_2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                titleCode: 'PIN_CODE',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'District',
                titleCode: 'DISTRICT',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State/UT',
                titleCode: 'STATE',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },

            {
                title: 'Country',
                titleCode: 'COUNTRY',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'CORRESPONDENCE_TRANSFEREE',
        title: 'Mailing/Correspondence address of the Transferee',
        isRequired: true,
        isSameAsAbove: true,
        formData: [
            {
                title: 'Address Line 1',
                titleCode: 'ADDRESS_LINE_1',
                field: 'peraddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2 ',
                titleCode: 'ADDRESS_LINE_2',
                field: 'peraddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                titleCode: 'PIN_CODE',
                field: 'perpincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'District',
                titleCode: 'DISTRICT',
                field: 'perdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State/UT',
                titleCode: 'STATE',
                field: 'perstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Country',
                titleCode: 'COUNTRY',
                field: 'percountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'DETAILS_OF_AUTHORIZED_PERSON',
        title: 'Details of Authorized Person',
        toolTipDetails: {
            position: 'right',
            content: AUTHORIZED_PERSON_TITLE,
            variant: { height: '20px' }
        },
        formData: [
            {
                title: 'First Name',
                titleCode: 'FIRST_NAME',
                field: 'authorizedPersonFirstname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Last Name',
                titleCode: 'LAST_NAME',
                field: 'authorizedPersonLastName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },

            {
                title: 'Primary Mobile Number',
                titleCode: 'PRIMARY_MOBILE_NUMBER',
                field: 'pmobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Alternative Mobile Number',
                titleCode: 'ALTERNATIVE_MOBILE_NUMBER',
                field: 'alternateMobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false
            },
            {
                title: 'Email ID',
                titleCode: 'EMAIL_ID',
                field: 'authorizedEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
            },
            {
                title: 'Letter of Authorization',
                titleCode: 'LETTER_OF_AUTHORIZATION',
                field: 'letter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                hideFileSize: true,
                entityFileData: {
                    documentNamePrefix: 'authorizedPersonFirstname',
                    documentType: DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY,
                    referenceType: DOCUMENT_TYPE.AUTHUSER
                },
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>The letter / resolution / power of attorney authorising the authorised person to apply on behalf of the Applicant</div>'
                }
            }
        ]
    }
];

export const PUSH_CLOC = {
    applicationDate: null,
    occupancyPercent: null,
    orderDate: null,
    orderNo: null,
    validUpto: null,
    constructionLicenseOrderNumber: null
};

export const initialValues = {
    isUpdatable: true,
    isSpecialTransfer: false,
    individualOrOrganization: 'Individual',
    confirmation: false,
    applicantGst: false,
    udhyam: false,
    specialProject: false,
    copyOnCheck: [],
    esignOption: 'DSC'
};

export const TRANSFEREE_OTHER_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Udyam Registered Enterprise',
                field: 'udhyam',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                class: 'col-sm-6 me-3'
            },
            {
                title: 'Enter Udyam Registration Number',
                field: 'udyamNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                renderCondition: (values: any) => _.get(values, `udhyam`) === true,
                class: ' col-sm-6 me-3'
            },
            {
                title: 'Udyam Registered Enterprise document ',
                titleCode: 'Udhyam Registered Enterprise document ',
                field: 'RegCertificate',
                hideTitle: true,
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                renderCondition: (value: any) => _.get(value, `udhyam`) === true
            }
        ]
    }
];

export const TRANSFEROR_DETAILS_SEARCH: FormDataDTO[] = [
    {
        title: 'Name',
        formData: [
            {
                title: 'Name',
                titleCode: 'NAME',
                field: 'transferorEntityName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Email ID',
                titleCode: 'EMAIL_ID',
                field: 'transferorEmail',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Phone No',
                titleCode: 'PHONE_NO',
                field: 'transferorPhoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'POSTAL_ADDRESS',
        title: 'Postal Address',
        formData: [
            {
                title: 'Address Line 1',
                titleCode: 'ADDRESS_LINE_1',
                field: 'transferorAddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Address Line 2',
                titleCode: 'ADDRESS_LINE_2',
                field: 'transferorAddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Pin Code',
                titleCode: 'PIN_CODE',
                field: 'transferorPinCode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'District',
                titleCode: 'DISTRICT',
                field: 'transferorDistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State/UT',
                titleCode: 'STATE',
                field: 'transferorState',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },

            {
                title: 'Country',
                titleCode: 'COUNTRY',
                field: 'transferorCountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

// export const TRANSFER_DETAILS_SEARCH = [
//     {
//         title: 'Transfer Details',
//         formData: [
//             {
//                 title: 'Lease Term remaining Duration',
//                 field: 'termReamainingDuration',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD,
//                 isRequired: false,
//                 isDisabled: true
//             },
//             {
//                 title: 'Transferor has utilised the Built-Up area, such that it covers 30% of the Plot Area',
//                 field: 'isBuiltUpAreaUtilised',
//                 type: CONSTANT_DATA_FIELD.RADIO_FIELD,
//                 options: [
//                     { value: true, title: 'Yes' },
//                     { value: false, title: 'No' }
//                 ],
//                 isDisabled: true
//             },
//             {
//                 title: 'Lease Deed/Tripartite Deed/Deed of Extension',
//                 titleCode: 'Lease Deed/Tripartite Deed/Deed of Extension',
//                 field: 'leaseDeed',
//                 type: CONSTANT_DATA_FIELD.FILE_FIELD,
//                 isDisabled: true,
//                 entityFileData: {
//                     documentNamePrefix: 'applicantName',
//                     documentType: DOCUMENT_TYPE.SUB_LEASE_ORDER,
//                     referenceType: DOCUMENT_TYPE.ENTITY
//                 }
//             },
//             {
//                 title: 'Occupancy Certificate',
//                 titleCode: 'Occupancy Certificate',
//                 field: 'occupancyCertificate',
//                 type: CONSTANT_DATA_FIELD.FILE_FIELD,
//                 isDisabled: true,
//                 entityFileData: {
//                     documentNamePrefix: 'applicantName',
//                     documentType: DOCUMENT_TYPE.OCCUPANCY_DETAILS,
//                     referenceType: DOCUMENT_TYPE.ENTITY
//                 }
//             },
//             {
//                 title: 'Transferor paid entire amount of Land Premium',
//                 field: 'transferOrLandPremium',
//                 type: CONSTANT_DATA_FIELD.RADIO_FIELD,
//                 options: [
//                     { value: true, title: 'Yes' },
//                     { value: false, title: 'No' }
//                 ],
//                 isDisabled: true
//             },
//             {
//                 title: 'Transferor is expressly prohibited or barred by any court ot tribunal or financial',
//                 field: 'isTransferProhibited',
//                 type: CONSTANT_DATA_FIELD.RADIO_FIELD,
//                 options: [
//                     { value: true, title: 'Yes' },
//                     { value: false, title: 'No' }
//                 ],
//                 isDisabled: true
//             },
//             {
//                 title: 'Transferor is making transfer to a Disqualified Entity',
//                 field: 'isTransfereeDisqualified',
//                 type: CONSTANT_DATA_FIELD.RADIO_FIELD,
//                 options: [
//                     { value: true, title: 'Yes' },
//                     { value: false, title: 'No' }
//                 ],
//                 isDisabled: true
//             },
//             {
//                 title: 'Transferor is making a Transfer from an Industrial Undertaking to Commercial Undertaking',
//                 field: 'isIndustrialCommercial',
//                 type: CONSTANT_DATA_FIELD.RADIO_FIELD,
//                 options: [
//                     { value: true, title: 'Yes' },
//                     { value: false, title: 'No' }
//                 ],
//                 isDisabled: true
//             },
//             {
//                 title: 'Transferor is making a Transfer of Special Project allotted through Goa-IPB',
//                 field: 'isSpecialProject',
//                 type: CONSTANT_DATA_FIELD.RADIO_FIELD,
//                 options: [
//                     { value: true, title: 'Yes' },
//                     { value: false, title: 'No' }
//                 ],
//                 class: 'col-sm-6 me-3',
//                 isDisabled: true
//             },
//             {
//                 title: 'NOC from Goa-IPB',
//                 titleCode: 'Special Project document',
//                 field: 'nocFromGoaIpb',
//                 type: CONSTANT_DATA_FIELD.FILE_FIELD,
//                 hideTitle: true,
//                 renderCondition: (value: any) => _.get(value, `isSpecialProject`) === true,
//                 isDisabled: true,
//                 entityFileData: {
//                     documentNamePrefix: 'Special Project',
//                     documentType: DOCUMENT_TYPE.SPECIAL_PROJECT,
//                     referenceType: DOCUMENT_TYPE.ENTITY
//                 }
//             }
//         ]
//     }
// ];

export const ENTERPRISE_DETAILS_SEARCH = [
    {
        title: 'Enterprise Details',
        titleCode: 'Enterprise Details',
        isRequired: true,
        formData: [
            {
                title: 'Category of Enterprise',
                field: 'enterpriseCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: false
            },
            {
                title: 'Sub-category',
                field: 'subCategory',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD,
                isRequired: true,
                multiSelect: true
            }
        ]
    }
];

export const TRANSFER_TABLE = [
    {
        colName: 'Application Type',
        fieldName: 'applicationType',
        clsName: 'col-sm-2 text-start ps-2'
    },
    {
        colName: 'Application No',
        fieldName: 'applicationNo',
        clsName: 'col-sm-2 text-start ps-2'
    },
    {
        colName: 'Date of Submission',
        fieldName: 'dateOfSubmission',
        clsName: 'col-sm-2 text-start ps-2'
    },
    {
        colName: 'Status',
        fieldName: 'status',
        clsName: 'col-sm-2'
    },
    {
        colName: 'Pending with',
        fieldName: 'pendingWith',
        clsName: 'col-sm-2 text-start ps-2'
    },
    {
        colName: 'Day Elapsed',
        fieldName: 'dayElapsed',
        clsName: 'col-sm-2 text-start ps-2'
    }
];
