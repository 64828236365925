import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { getCheckList, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../EstateManagement.controller';
import { t } from 'i18next';
import { check, cross } from '../../../../utils/Logo';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE, CORRESPONDENCE_TABLE_APP_FORM } from '../LeaseDeed/LeaseDeed.const';
import { Card } from 'react-bootstrap';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import { IllegalityApplicationCorrespondence } from './IllegalityApplicationCorrespondence';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericDocViewer from '../../../../components/fileviewer/GenericDocViewer';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import { devUrl, endpoints, estateEndpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getLesseeInitData } from '../SubLease/SubLeaseConst';
import { ILLEGAL_SUB_LEASE_RATE, TENANT_ID } from '../../../../utils/constants';
import axios from 'axios';
import { getInspectionDetails } from '../InspectionReport/InspectionReportConst';
import { getEntityDocumentDetails } from '../../Migration/Migration.controller';

export function IllegalityApplication() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState(1);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [modalData, setModalData] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [rowData, setRowData] = useState<any>([]);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [checklistTableData, setChecklistTableData] = useState();
    const [content, setContent] = useState('');
    const [response, setResponse] = useState<any>(null);
    const [rowDocData, setRowDocData] = useState<any>([]);
    const [isInspectionByFm, setIsInspectionByFm] = useState<boolean>(false);

    const [searchParams] = useSearchParams();
    const applicationNumber = decodeURIComponent(searchParams.get('applicationNumber') || 'ABC');
    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const totalPages = applicationNumber ? 3 : 1;
    let navigate = useNavigate();

    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');
    const signedFileStoreId = searchParams.get('signedFileStoreId');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            penaltyInterest: ILLEGAL_SUB_LEASE_RATE
        },
        onSubmit: () => {}
    });

    const { values, setValues } = formik;

    const fetchDocumentDetails = async (docRefId: any) => {
        try {
            const docData = await getEntityDocumentDetails({ docReferenceId: docRefId });
            return docData;
        } catch (error) {
            console.error('Error fetching document details:', error);
            return {};
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);

                if (applicationNumber) {
                    const { data: applicationSearch } = await POST(endpoints.searchAllotment + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                    const applicationData = applicationSearch?.estateApplications[0];
                    const lesseeData = await getLesseeInitData(applicationData);
                    const plotData = applicationData?.appliedPlotDetails;
                    const inspectionData = applicationData?.modificationDetails?.requestDetails?.inspectionDetails
                        ? getInspectionDetails(applicationData?.modificationDetails?.requestDetails?.inspectionDetails)
                        : {};

                    if (applicationData?.modificationDetails?.requestDetails?.inspectionDetails) {
                        setIsInspectionByFm(!!applicationData?.modificationDetails?.requestDetails?.inspectionDetails);

                        const searchtableData = applicationData?.modificationDetails?.requestDetails?.inspectionDetails
                            ? await Promise.all(
                                  applicationData?.modificationDetails?.requestDetails?.inspectionDetails?.inspectionDocDetailsResponseList?.map(async (val: any, index: number) => {
                                      const docData = await fetchDocumentDetails(val?.docRefId);
                                      return { ...val, documentName: docData?.documentName, documentUrl: docData?.documentUrl, serialNo: index + 1 };
                                  })
                              )
                            : [];

                        setRowDocData(searchtableData);
                    }

                    setValues((prev: any) => ({
                        ...prev,
                        ...lesseeData,
                        ...inspectionData,
                        estateName: plotData?.estateDetails?.estateName,
                        phase: plotData?.phase,
                        plotNo: plotData?.plotNo,
                        plotType: plotData?.plotType,
                        reason: applicationData?.modificationDetails?.requestDetails?.reason || '',
                        dueDate: applicationData?.modificationDetails?.requestDetails?.dueDate || ''
                    }));

                    setContent(applicationData?.content);
                    setResponse(applicationData);

                    const { data: processData } = await POST(`${endpoints.workflowSearch}&businessIds=${applicationNumber}`, { RequestInfo: requestInfo() });
                    setHistoryDetails(processData.ProcessInstances);

                    const nextActions = applicationNumber && (await getNextActions(applicationNumber));
                    setNextValidAction(nextActions);

                    const docs = applicationNumber && (await getWorkflowDocumentSearch(applicationNumber, nextActions?.businessService));
                    setDocumentDetails(docs);

                    const relatedApplicationDocument = await POST(
                        estateEndpoints.relatedApplicationSearch +
                            `?entityIds=${applicationData?.entityRefDetails?.entityId}&plotIds=${applicationData?.entityRefDetails?.plotId}&applicationNos=${applicationNumber}`,
                        { RequestInfo: requestInfo() }
                    );
                    setRelatedApplicationDocument(relatedApplicationDocument);

                    const checklistData = await getCheckList(applicationData?.entityRefDetails?.entityId, applicationData?.entityRefDetails?.plotId, nextActions?.businessService);
                    setChecklistTableData(checklistData);
                }

                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [applicationNumber]);

    useEffect(() => {
        if (documentDetails?.length) {
            // const tableData = async () => {
            //     const reverseDocumentDetails = documentDetails?.reverse();
            //     const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
            //         return { ...data, serialNo: `C${ind + 1}`, uploadedDate: data?.uploadedDate && data?.uploadedDate?.split(' ')[0] };
            //     });
            //     const reverseData = responseTableData.reverse();
            //     setRowData(reverseData);
            // };
            const tableData = async () => {
                const responseTableData = documentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${documentDetails.length - ind}`, uploadedDate: data?.uploadedDate && data?.uploadedDate?.split(' ')[0] };
                });
                setRowData(responseTableData);
            };
            if (activeIndex === 2) {
                tableData();
            }
        }
    }, [documentDetails, activeIndex]);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            if (applicationNumber) {
                const docs: any = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction?.businessService, historyDetails[0].id));
                setDocumentDetails(docs.documents);
                setDocumentsUuid([...documentsUuid, docs.uuid]);
                setActiveIndex(2);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onRowClick = (row: any) => {
        const extension = (row?.docFileName || row?.tag)?.split('.').pop().toLowerCase();
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        if (extension === 'pdf') {
            setIsPDF(true);
        } else {
            setIsPDF(false);
        }
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };

    const handleNextPage = async () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };
    const handlePrevPage = () => {
        if (activeIndex >= 2) {
            setActiveIndex(prevInd => prevInd - 1);
        }
    };

    const handleModalClose = (type: string, isSaveOnly: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success' && !isSaveOnly) {
            navigate('/goa-idc/dashboard');
        }
    };

    const onCancel = () => {
        navigate(-1);
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    useEffect(() => {
        if (note && action && response && nextValidAction) {
            onHandlePrimaryAction({ action: action }, '');
        }
    }, [response, nextValidAction]);

    const esign = async (payload: any, action: string) => {
        const userRequestInfo = requestInfo();
        try {
            let approveEsignRes = await POST(endpoints.esignApprove, payload);
            const refId = approveEsignRes.data.entityRefDocuments[0].docReferenceId;
            const approvedEsignData = approveEsignRes.data.entityRefDocuments.map((item: any) => `${item.uuid}=${item.docReferenceId}`);

            if (window.location.protocol !== 'http:') {
                let url =
                    window.location.origin +
                    `/goa-idc/estate/default-notice?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
                url = btoa(url);
                const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }`;
                await axios.get(esignUrl);

                window.open(esignUrl, '_self');
            } else {
                let url =
                    window.location.origin +
                    `/goa-idc/estate/default-notice?${searchParams.toString()}&signedFileStoreId=${refId}&note=${values.notes}&docsUuid=${documentsUuid?.join(
                        ','
                    )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
                window.open(url, '_self');
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean),
            estateApplicationRequest: {
                ...response,
                appliedPlotId: response?.appliedPlotDetails.uuid,
                assignedPlotId: response?.appliedPlotDetails.uuid,
                content: content
            },
            signedDocDetails:
                approvedEsign?.split(',').map((item: any) => {
                    const esignData = item.split('=');
                    return { [`${esignData[0]}`]: `${signedFileStoreId}` };
                }) || null
        };

        if (!values?.notes && !note) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: 'Please enter notes', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (response?.status !== 'PENDING_FOR_ACCEPTANCE' && designation === 'MD' && !signedFileStoreId && status.isEsignRequired) {
            esign(payload, status.action);
            return;
        }

        try {
            await POST(endpoints.estateWorkflowInitiate, payload);
            const successMessage = getSuccessMessage(status?.action, applicationNumber);
            setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: successMessage, img: check, type: 'success', reUpload: 'OK' });
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const updateNotes = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            ...response,
            appliedPlotId: response?.appliedPlotDetails?.uuid,
            content: content
        };

        try {
            const { data } = await POST(endpoints.updateAllotment, payload);
            setContent(data.estateApplications[0].content);

            setModalDetails({ show: true, title: 'Success!', para: 'Data saved successfully', img: check, type: 'success', reUpload: 'OK', isSaveOnly: true });
        } catch (err: any) {
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log('formik', formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={'Application of Default Notice'}
                            additionalClass='sub-text-style'
                            bottomSubText={applicationNumber ? `Type : ${t(response?.applicationType)}, No : ${applicationNumber}` : ''}
                        />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={'Application of Default Notice'}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={applicationNumber ? `Type :  ${t(response?.applicationType)}, No : ${applicationNumber}` : ''}
                                    />

                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={!nextValidAction?.nextValidAction?.length}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>

                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper designation={designation} applicationNumber={applicationNumber} nextValidAction={nextValidAction} />

                                <Card className='card_border p-1'>
                                    {activeIndex === 1 && (
                                        <IllegalityApplicationCorrespondence
                                            checkListRowData={checklistTableData}
                                            designation={designation}
                                            content={content}
                                            setContent={setContent}
                                            readOnly={!nextValidAction?.isUpdatable}
                                            response={response}
                                            onRowClick={onRowClick}
                                            rowDocData={rowDocData}
                                            isInspectionByFm={isInspectionByFm}
                                            nextValidAction={nextValidAction}
                                        />
                                    )}
                                    {activeIndex === 2 && (
                                        <div className='p-4'>
                                            <GenericActionTable hideSearchBar={true} columns={CORRESPONDENCE_TABLE} rowData={rowData} additionalMethod={onRowClick} />
                                        </div>
                                    )}
                                    {activeIndex === 3 && (
                                        <div className='p-4'>
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        </div>
                                    )}
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>
                        </FormikProvider>
                    </div>

                    <GenericFooterComponent
                        onCancel={onCancel}
                        onClickPrev={onChangePrevIndex}
                        activeIndex={activeIndex}
                        handleOnPrimaryAction={onHandlePrimaryAction}
                        onSubmit={() => {}}
                        applicationNo={applicationNumber || null}
                        nextValidAction={nextValidAction}
                        stepperLength={0}
                        extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                        extraButtonAction={updateNotes}
                    />

                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly)}
                    />

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Document Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericDocViewer url={modalData.documentUrl} extension={'docx'} fileName={modalData.indenture} />}
                            </div>
                        </GenericPopupModal>
                    )}
                </>
            )}
        </>
    );
}
