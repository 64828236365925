import { CGST_SGST, CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import {
    initialSubleaseWater,
    SUB_LEASE_DUE_FALSE,
    SUB_LEASE_DUE_TRUE,
    SUB_LEASE_FEE_DUE,
    SUB_LEASE_PAYMENT_DUE_FALSE,
    SUB_LEASE_PAYMENT_DUE_TRUE,
    SUB_LEASE_WATER,
    SUB_LEASE_WATER_FIELDS,
    SUBLEASE_WATER_CHARGES_NOT_PAID,
    SUBLEASE_WATER_CHARGES_PAID
} from '../Migration.const';
import { Card } from 'react-bootstrap';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { FieldArray, useFormikContext } from 'formik';
import { useState } from 'react';
import _ from 'lodash';
import DeleteIcon from '../../../../assets/logos/delete.svg';
import { cross } from '../../../../utils/Logo';
import { updateJson } from '../MigrationPaymentUpdate';

export const SublLeasePayment = ({ dropdownOptions, setModalDetails, paymentDisableCheck }: any) => {
    const [count, setCount] = useState<number>(0);

    const subLeaseEntityName = [
        {
            formData: [
                {
                    title: 'Name',
                    field: 'subLeaseEntityDetails.entityName',
                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                    isDisabled: true
                },
                {
                    titleCode: 'Start Date',
                    title: 'Start Date',
                    field: 'subLeaseStartDate',
                    type: CONSTANT_DATA_FIELD.DATE_FIELD,
                    isDisabled: true,
                    enableFutureDate: true
                },
                {
                    titleCode: 'End Date',
                    title: 'End Date',
                    field: 'subLeaseEndDate',
                    type: CONSTANT_DATA_FIELD.DATE_FIELD,
                    isDisabled: true,
                    enableFutureDate: true
                },
                {
                    titleCode: 'SUB_LEASE_AREA',
                    title: 'Sub-Lease area(sqm)',
                    field: 'subLeaseArea',
                    type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                    isDisabled: true
                }
            ]
        }
    ];

    const tempSubLeaseFeeDue = removeDisableCondition(SUB_LEASE_FEE_DUE);
    const subLeaseFeeDue = paymentDisableCheck ? updateJson(tempSubLeaseFeeDue) : tempSubLeaseFeeDue;

    const tempSubLeaseDueTrue = removeDisableCondition(SUB_LEASE_DUE_TRUE);
    const subLeaseDueTrue = paymentDisableCheck ? updateJson(tempSubLeaseDueTrue) : tempSubLeaseDueTrue;

    const tempSubLeaseDueFalse = removeDisableCondition(SUB_LEASE_DUE_FALSE);
    const subLeaseDueFalse = paymentDisableCheck ? updateJson(tempSubLeaseDueFalse) : tempSubLeaseDueFalse;

    const tempSubLeaseWaterField = removeDisableCondition(SUB_LEASE_WATER_FIELDS);
    const subLeaseWaterField = tempSubLeaseWaterField?.map((val: any) => ({
        ...val,
        formData: val?.formData?.map((item: any) => {
            if (item.field !== 'subleaseWaterFeeDue') {
                return { ...item, isDisabled: true };
            } else {
                return { ...item };
            }
        })
    }));

    const tempSubLeaseWaterChargesPaid = removeDisableCondition(SUBLEASE_WATER_CHARGES_PAID);
    const subleaseWaterChargesPaid = paymentDisableCheck ? updateJson(tempSubLeaseWaterChargesPaid) : tempSubLeaseWaterChargesPaid;

    const tempSubLeaseWaterChargesNotPaid = removeDisableCondition(SUBLEASE_WATER_CHARGES_NOT_PAID);
    const subleaseWaterChargesNotPaid = paymentDisableCheck ? updateJson(tempSubLeaseWaterChargesNotPaid) : tempSubLeaseWaterChargesNotPaid;

    const { values, setValues } = useFormikContext<any>();

    const handleAddWaterConnection = (push: any) => {
        push(initialSubleaseWater);
    };

    const handleRemove = (remove: any, index: number) => {
        remove(index);
        setCount(count - 1);
    };

    const onChangeAction = (e: any, field: string) => {
        if (field.indexOf('subLeaseEndDate') !== -1 || field.indexOf('subLeaseStartDate') !== -1) {
            if (values.subLeaseDetailsdata?.length) {
                let subLeaseDetailsdata = values.subLeaseDetailsdata?.map((data: any) => {
                    if (data.subLeaseStartDate && data.subLeaseEndDate) {
                        const date1: Date = new Date(data.subLeaseStartDate);
                        const date2: Date = new Date(data.subLeaseEndDate);

                        if (date1 > date2) {
                            setModalDetails({ show: true, title: 'Error!', para: 'End date should not be less then start date', img: cross, type: 'error', reUpload: 'OK' });
                        } else {
                            let diffDays = 0;
                            let diffYears = date2.getFullYear() - date1.getFullYear();
                            let diffMonths = date2.getMonth() - date1.getMonth();
                            if (diffMonths < 0) {
                                diffYears--;
                                diffMonths += 12;
                            }
                            if (diffMonths === 0) {
                                diffDays = date2.getDate() - date1.getDate();
                            }
                            data.durationSubLease = diffMonths === 0 ? `${diffYears} Year(s) ${diffDays} Day(s)` : `${diffYears} Year(s) ${diffMonths} Month(s)`;
                        }
                    }
                    return data;
                });
                setValues({ ...values, subLeaseDetailsdata });
            }
        } else {
            let currentField = field.split('.').slice(-1)[0];
            let index = +field.split('.')[1];
            if (currentField === 'principalAmount') {
                setValues((prevValues: any) => ({
                    ...prevValues,
                    subLeaseDetailsdata: prevValues.subLeaseDetailsdata.map((item: any, idx: number) =>
                        idx === index
                            ? {
                                  ...item,
                                  paymentDetails: {
                                      ...item.paymentDetails,
                                      principalAmount: e.target.value,
                                      sgstPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      cgstPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      totalDueAmount: (
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.penaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPenaltyAmount || 0) +
                                          +e.target.value * (2 * CGST_SGST + 1)
                                      )
                                          .toFixed(2)
                                          .toString()
                                  }
                              }
                            : item
                    )
                }));
            } else if (currentField === 'penaltyAmount') {
                setValues((prevValues: any) => ({
                    ...prevValues,
                    subLeaseDetailsdata: prevValues.subLeaseDetailsdata.map((item: any, idx: number) =>
                        idx === index
                            ? {
                                  ...item,
                                  paymentDetails: {
                                      ...item.paymentDetails,
                                      penaltyAmount: e.target.value,
                                      sgstPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      cgstPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      totalDueAmount: (
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.principalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPenaltyAmount || 0) +
                                          +e.target.value * (2 * CGST_SGST + 1)
                                      )
                                          .toFixed(2)
                                          .toString()
                                  }
                              }
                            : item
                    )
                }));
            } else if (currentField === 'arrearPenaltyAmount') {
                setValues((prevValues: any) => ({
                    ...prevValues,
                    subLeaseDetailsdata: prevValues.subLeaseDetailsdata.map((item: any, idx: number) =>
                        idx === index
                            ? {
                                  ...item,
                                  paymentDetails: {
                                      ...item.paymentDetails,
                                      arrearPenaltyAmount: e.target.value,
                                      sgstArrearPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      cgstArrearPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      totalDueAmount: (
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.principalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.penaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPrincipalAmount || 0) +
                                          +e.target.value * (2 * CGST_SGST + 1)
                                      )
                                          .toFixed(2)
                                          .toString()
                                  }
                              }
                            : item
                    )
                }));
            } else if (currentField === 'arrearPrincipalAmount') {
                setValues((prevValues: any) => ({
                    ...prevValues,
                    subLeaseDetailsdata: prevValues.subLeaseDetailsdata.map((item: any, idx: number) =>
                        idx === index
                            ? {
                                  ...item,
                                  paymentDetails: {
                                      ...item.paymentDetails,
                                      arrearPrincipalAmount: e.target.value,
                                      sgstArrearPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      cgstArrearPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      totalDueAmount: (
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.principalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.penaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPenaltyAmount || 0) +
                                          +e.target.value * (2 * CGST_SGST + 1)
                                      )
                                          .toFixed(2)
                                          .toString()
                                  }
                              }
                            : item
                    )
                }));
            }
        }
    };

    const onChangeWaterCharges = (e: any, field: any) => {
        let newValues = _.cloneDeep(values);
        let currentField = field.split('.').slice(-1)[0];
        let index = +field.split('.')[1];
        let waterIndex = +field.split('.')[3];
        let currentPath = newValues.subLeaseDetailsdata[index].subLeaseWaterDetails[waterIndex];

        if (currentField === 'waterChargesPrincipalAmount') {
            let tempSum =
                +(currentPath.waterChargesPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.waterChargesArrearPrincipalAmount || 0) +
                +(currentPath.waterChargesArrearPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesArrearPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesArrearPenaltyAmount || 0);
            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value).toFixed(2).toString();
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesPrincipalAmount`, e.target.value);
            setValues(newValues);
        }
        if (currentField === 'waterChargesPenaltyAmount') {
            let tempSum =
                +(currentPath.waterChargesPrincipalAmount || 0) +
                +(currentPath.waterChargesArrearPrincipalAmount || 0) +
                +(currentPath.waterChargesArrearPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesArrearPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesArrearPenaltyAmount || 0);
            const cgstOnWaterChargesPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const sgstOnWaterChargesPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value * (2 * CGST_SGST + 1)).toFixed(2).toString();
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].cgstOnWaterChargesPenaltyAmount`, cgstOnWaterChargesPenaltyAmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].sgstOnWaterChargesPenaltyAmount`, sgstOnWaterChargesPenaltyAmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesPenaltyAmount`, e.target.value);
            setValues(newValues);
            // setValues({ ...values, cgstOnWaterChargesPenaltyAmount, sgstOnWaterChargesPenaltyAmount, waterChargesTotalDueAmmount, waterChargesPenaltyAmount: e.target.value });
        }
        if (currentField === 'waterChargesArrearPrincipalAmount') {
            let tempSum =
                +(currentPath.waterChargesPrincipalAmount || 0) +
                +(currentPath.waterChargesPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.waterChargesArrearPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesArrearPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesArrearPenaltyAmount || 0);

            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value).toFixed(2).toString();
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesArrearPrincipalAmount`, e.target.value);
            setValues(newValues);

            // setValues({
            //     ...values,
            //     waterChargesTotalDueAmmount,
            //     waterChargesArrearPrincipalAmount: e.target.value
            // });
        }
        if (currentField === 'waterChargesArrearPenaltyAmount') {
            let tempSum =
                +(currentPath.waterChargesPrincipalAmount || 0) +
                +(currentPath.waterChargesPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.waterChargesArrearPrincipalAmount || 0);

            const cgstOnWaterChargesArrearPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const sgstOnWaterChargesArrearPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value * (2 * CGST_SGST + 1)).toFixed(2).toString();

            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].cgstOnWaterChargesArrearPenaltyAmount`, cgstOnWaterChargesArrearPenaltyAmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].sgstOnWaterChargesArrearPenaltyAmount`, sgstOnWaterChargesArrearPenaltyAmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesArrearPenaltyAmount`, e.target.value);
            setValues(newValues);
            // setValues({ ...values, cgstOnWaterChargesArrearPenaltyAmount, sgstOnWaterChargesArrearPenaltyAmount, waterChargesTotalDueAmmount, waterChargesArrearPenaltyAmount: e.target.value });
        }
    };

    return (
        <>
            <form>
                <FieldArray name='subLeaseDetailsdata'>
                    {({ insert, remove, push }) => (
                        <div>
                            {values.subLeaseDetailsdata?.length > 0 &&
                                values.subLeaseDetailsdata.map((_subLeaseDetailsdatas: any, index: number) => (
                                    <div className='row' key={index}>
                                        <div className='col-sm-12 collapsible_box  align-items-center pb-2 '>
                                            <input type='checkbox' id={`sublease${index}`} name={`sublease${index}`} defaultChecked={true} style={{ display: 'none' }} />
                                            <label htmlFor={`sublease${index}`} className='d-flex collapsible_box_label align-items-center justify-content-between'>
                                                Sub-Lease Details - {index + 1}
                                            </label>

                                            <div className='form_fields' style={{ position: 'relative' }}>
                                                <Card className='card_border mb-4'>
                                                    {/* {index !== 0 && values?.isUpdatable && (
                                                        <div className='d-flex justify-content-end pt-3 pe-3' style={{ position: 'absolute', right: '2%' }}>
                                                            <img style={{ cursor: 'pointer', zIndex: 100 }} onClick={() => handleRemove(remove, index)} src={DeleteIcon} alt='delete' />
                                                        </div>
                                                    )} */}

                                                    {subLeaseEntityName?.map((val: any, _: number) => {
                                                        return (
                                                            <Card.Body key={index}>
                                                                <GenericFormCard
                                                                    fieldPath={`subLeaseDetailsdata.${index}`}
                                                                    {...val}
                                                                    addressPath={`subLeaseDetailsdata.${index}.subLeaseEntityDetails`}
                                                                    index={index}
                                                                    // dropdownOptions={dropdownOptions}
                                                                    onChangeAction={onChangeAction}
                                                                />
                                                            </Card.Body>
                                                        );
                                                    })}

                                                    {subLeaseFeeDue.map((val: any, ind: number) => (
                                                        <Card.Body>
                                                            <GenericFormCard {...val} index={index} fieldPath={`subLeaseDetailsdata.${index}`} />
                                                            {!!values.subLeaseDetailsdata[index].subleaseFeeDue ? (
                                                                <>
                                                                    {subLeaseDueTrue.map((val: any, ind: number) => (
                                                                        <GenericFormCard
                                                                            fieldPath={`subLeaseDetailsdata.${index}`}
                                                                            addressPath={`subLeaseDetailsdata.${index}`}
                                                                            {...val}
                                                                            index={index}
                                                                            onChangeAction={onChangeAction}
                                                                        />
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {subLeaseDueFalse.map((val: any, ind: number) => (
                                                                        <GenericFormCard
                                                                            fieldPath={`subLeaseDetailsdata.${index}`}
                                                                            addressPath={`subLeaseDetailsdata.${index}`}
                                                                            {...val}
                                                                            index={index}
                                                                            onChangeAction={onChangeAction}
                                                                        />
                                                                    ))}
                                                                </>
                                                            )}
                                                        </Card.Body>
                                                    ))}

                                                    {SUB_LEASE_WATER.map((val: any, ind: number) => (
                                                        <Card.Body>
                                                            <GenericFormCard {...val} index={index} fieldPath={`subLeaseDetailsdata.${index}`} />
                                                            {!!values.subLeaseDetailsdata[index].subleaseWater ? (
                                                                <>
                                                                    <FieldArray name={`subLeaseDetailsdata.${index}.subLeaseWaterDetails`}>
                                                                        {({ insert, remove, push }) => (
                                                                            <div>
                                                                                {values.subLeaseDetailsdata?.[`${index}`].subLeaseWaterDetails?.length > 0 &&
                                                                                    values.subLeaseDetailsdata?.[`${index}`].subLeaseWaterDetails.map(
                                                                                        (subLeaseDetailsdatas: any, waterIndex: number) => (
                                                                                            <div className='row' key={waterIndex}>
                                                                                                <div className='col-sm-12 collapsible_box  align-items-center pb-2 '>
                                                                                                    <input
                                                                                                        type='checkbox'
                                                                                                        id={`subleaseWater${waterIndex}`}
                                                                                                        name={`subleaseWater${waterIndex}`}
                                                                                                        defaultChecked={true}
                                                                                                        style={{ display: 'none' }}
                                                                                                    />
                                                                                                    <label
                                                                                                        htmlFor={`subleaseWater${waterIndex}`}
                                                                                                        className='d-flex collapsible_box_label align-items-center justify-content-between'
                                                                                                    >
                                                                                                        Sub-Lease Water Connection Details - {waterIndex + 1}
                                                                                                    </label>

                                                                                                    <div className='form_fields' style={{ position: 'relative' }}>
                                                                                                        {/* {waterIndex !== 0 && values?.isUpdatable && (
                                                                                                            <div
                                                                                                                className='d-flex justify-content-end pe-3'
                                                                                                                style={{ position: 'absolute', right: '0%' }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    style={{ cursor: 'pointer', zIndex: 100 }}
                                                                                                                    onClick={() => handleRemove(remove, waterIndex)}
                                                                                                                    src={DeleteIcon}
                                                                                                                    alt='delete'
                                                                                                                />
                                                                                                            </div>
                                                                                                        )} */}

                                                                                                        {subLeaseWaterField.map((val: any, ind: number) => (
                                                                                                            <GenericFormCard
                                                                                                                fieldPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                addressPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                {...val}
                                                                                                                index={waterIndex}
                                                                                                                dropdownOptions={dropdownOptions}
                                                                                                                // onChangeAction={onChangeAction}
                                                                                                            />
                                                                                                        ))}

                                                                                                        {!values.subLeaseDetailsdata[index]?.subLeaseWaterDetails[waterIndex]?.subleaseWaterFeeDue
                                                                                                            ? subleaseWaterChargesPaid.map((val: any, ind: number) => (
                                                                                                                  <div style={{ marginTop: '-30px' }}>
                                                                                                                      <GenericFormCard
                                                                                                                          fieldPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                          addressPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                          {...val}
                                                                                                                          index={index}
                                                                                                                          //   dropdownOptions={dropdownOptions}
                                                                                                                          onChangeAction={onChangeWaterCharges}
                                                                                                                      />
                                                                                                                  </div>
                                                                                                              ))
                                                                                                            : subleaseWaterChargesNotPaid.map((val: any, ind: number) => (
                                                                                                                  <div style={{ marginTop: '-30px' }}>
                                                                                                                      <GenericFormCard
                                                                                                                          fieldPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                          addressPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                          {...val}
                                                                                                                          index={index}
                                                                                                                          onChangeAction={onChangeWaterCharges}
                                                                                                                          //   onChangeAction={onChangeAction}
                                                                                                                      />
                                                                                                                  </div>
                                                                                                              ))}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                {/* {values.subLeaseDetailsdata?.[`${index}`].subLeaseWaterDetails?.length < 2 && values?.isUpdatable ? (
                                                                                    <button
                                                                                        className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center add_fields'
                                                                                        type='button'
                                                                                        onClick={() => handleAddWaterConnection(push)}
                                                                                    >
                                                                                        + Add Water Connection Details
                                                                                    </button>
                                                                                ) : (
                                                                                    <></>
                                                                                )} */}
                                                                            </div>
                                                                        )}
                                                                    </FieldArray>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Card.Body>
                                                    ))}
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            {/* {values.isUpdatable && (
                                <button className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center add_fields' type='button' onClick={() => handleAddSubLease(push)}>
                                    + Add Sub-Lease Details
                                </button>
                            )} */}
                        </div>
                    )}
                </FieldArray>
            </form>
        </>
    );
};

export const removeDisableCondition = (orijinalJson: any) => {
    const updatedJson = orijinalJson?.map((val: any) => ({
        ...val,
        formData: val?.formData?.map((item: any) => {
            const { disableCondition, ...rest } = item;
            return { ...rest };
        })
    }));
    return updatedJson;
};
