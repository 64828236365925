import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ENTERPRISE_DETAILS, SURRENDER_DETAILS, SURRENDER_EXISTING_MORTGAGE_DETAILS, SURRENDER_PAYLOAD, SURRENDER_STEPS_DETAILS } from './Surrender.const';
import { DropdownData } from '../Allotment/AllotmentPage';
import { dropdownDataOptions, getEntityDocumentDetails } from '../../Migration/Migration.controller';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { devUrl, endpoints, estateEndpoints, getProgressBarData, POST, POST2, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import PopUps from '../../../../components/PopUps';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import Declaration from '../Allotment/Declaration';
import { DECLARATION_LABEL, PLOT_DETAILS_TABLE } from '../SubLease/SubLeaseConst';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import ScrollToTop from '../../../../components/ScrollToTop';
import PlotDetails from '../../Migration/pages/PlotDetails';
import PaymentDetails from '../Allotment/PaymentDetails';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/authSlice';
import { fetchFeeData, fetchGatewayData, fetchLeaseDurationDoc, getOptions, getQueryParams, loadRazorpay, paymentCheck } from '../EstateManagement.controller';
import { entityDocumentData } from '../LeaseDeed/LeaseDeed.controller';
import { MORTGAGE_DECLARATION_LABEL, MORTGAGE_EXISTING_MORTGAGE_DETAILS, setExistingMortgageDetails } from '../../Mortgage Lessee/Mortgage.const';
import axios from 'axios';
import { check, cross } from '../../../../utils/Logo';
import { RazorpayOptions } from '../../UserManagement/types';
import { getFirstErrorKey } from '../../../../utils/utils';
import { toast } from 'react-toastify';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { SURRENDER_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import ProgressBar from '../../../../components/moduleCommonFormik/ProgressBar';
import PaymentSubmissionStatus from '../../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import GenericFileUploadField from '../../../../components/moduleCommonFormik/GenericFileUpload.component';
import { EMPLOYEE_AUTHORIZED, KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../../../utils/constants';
import { EsignModal } from '../../../commonPages/EsignModal';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';

const SurrenderComponent = () => {
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const { t } = useTranslation();
    const [eSignedDocumentData, setESignedDocumentData] = useState<any>(null);
    const [isChecked, setIsChecked] = useState([false, false, false]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [paymentData, setPaymentData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [isPaid, setIsPaid] = useState<any>(true);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [transactionDetails, setTransactionDetails] = useState<any>(null);
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);
    const [tableRowData, setTableRowData] = useState<any>([]);
    const [documents, setDocuments] = useState<any>([]);
    const [applicationNo, setApplicationNo] = useState<any>();
    const [resData, setResData] = useState<any>([]);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [entityDetails, setEntityDetails] = useState<any>({});
    const [visited, setVisited] = useState(false);
    const [existMortgageDetails, setExistMortgageDetails] = useState<any>([]);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>(null);

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const userType = JSON.parse(localStorage.getItem('user') || '{}')?.type;
    const location = useLocation();
    const searchParams: any = new URLSearchParams(location.search);
    const userData = useSelector(selectUser);
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    const userUuid = JSON.parse(localStorage.getItem('user') || '{}')?.uuid;
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const applicationNumber = searchParams.get('applicationNumber');

    const queryParams = getQueryParams(searchParams);
    let navigate = useNavigate();

    const [dropdownDataPlot, setDropdownDataPlot] = useState<DropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });

    const formik = useFormik<any>({
        initialValues: { isUpdatable: true, financingObtained: false, esignOption: 'DSC' },
        onSubmit: (value: any) => {},
        validationSchema: null
    });
    useEffect(() => {
        dropdownDataOptions()
            .then((dropdownOption: any) => {
                setDropdownData(dropdownOption);
            })
            .catch(error => {});

        if (applicationNumber) {
            if (userType !== 'ENTERPRISE') {
                window.location.href = '/goa-idc/access-restricted';
            }

            (async () => {
                let applicationAccess;
                try {
                    applicationAccess = await POST(endpoints?.applicationAccess + `?businessid=${applicationNumber}`, { RequestInfo: requestInfo() });
                    if (!applicationAccess?.data.isAuthorized) {
                        window.location.href = '/goa-idc/access-restricted';
                    }
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, []);

    const { values, setValues, setFieldTouched, errors, setFieldValue } = formik;

    useEffect(() => {
        const getPlotData = async () => {
            try {
                setIsLoading(true);
                const { data } = await POST(endpoints.validApplication + '?type=APPLY_SURRENDER', { RequestInfo: requestInfo() });
                if (data?.entityRefDetails.length > 0) {
                    let getPlotIds = data?.entityRefDetails.map((data: any) => data?.plotId);
                    const plotIds = getPlotIds.join(',');
                    if (!getPlotIds) return;

                    const { data: plotData } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

                    const plotRowData = plotData?.plots?.map((value: any) => ({
                        estateName: value?.estateDetails?.estateName,
                        plotType: value?.plotType,
                        phase: value?.phase,
                        plotNo: value?.plotNo,
                        plotAreaSqm: value?.plotAreaSqm,
                        uuid: value?.uuid,
                        id: value?.uuid
                    }));
                    setTableRowData(plotRowData);
                }
            } catch (e: any) {}
            setIsLoading(false);
        };
        getPlotData();
    }, []);

    const onPlotSelect = (selectedPlot: any) => {
        setSelectedPlotDataId(selectedPlot.uuid);
        setValues((prev: any) => ({ ...prev, plotUuid: selectedPlot.uuid }));
    };

    // setting plot columns with funtion
    const plotDetailsTableColumns = PLOT_DETAILS_TABLE.map((val: any, index: number) => {
        if (index === 0) {
            return {
                ...val,
                onRadioClick: onPlotSelect,
                selectedValue: selectedPlotDataId
            };
        }
        return val;
    });

    useEffect(() => {
        const leaseDeedDoc = async () => {
            let data = selectedPlotDataId && (await POST(endpoints.leaseDeedSearch + `?entityIds=${entityId}&plotIds=${selectedPlotDataId}`, { RequestInfo: requestInfo() }));
            let deedDoc: any;
            deedDoc = data?.data?.deedDetails[0]?.executedIndentureRefId && (await getEntityDocumentDetails({ docReferenceId: data?.data?.deedDetails[0]?.executedIndentureRefId }));
            const deedExecutionDate = data?.data?.deedDetails[0]?.deedExecutionDate;
            setValues((prev: any) => ({
                ...prev,
                lesseeDeedOfExtension: deedDoc,
                possessionDate: deedExecutionDate
            }));
        };
        if (selectedPlotDataId) {
            leaseDeedDoc();
        }
    }, [selectedPlotDataId]);

    const getTransactionUpdate = async (params: any) => {
        try {
            if (params.plotId) {
                const paid: any = await paymentCheck('PROCESSING_FEE', params.plotId, params.applicationId || resData?.uuid);
                if (!paid.isPaid) {
                    const payload = { RequestInfo: requestInfo() };
                    const { data } = await POST2(endpoints.transactionUpdate, payload, { eg_pg_txnid: params.eg_pg_txnid });
                    if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                        setTransactionDetails(data?.Transaction?.[0]);
                        if (data?.Transaction?.[0]?.txnId) {
                            try {
                                const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                                if (resp.data.status === 'SUCCESSFUL') {
                                    setModalDetails({ show: true, title: 'Success!', para: 'Payment successful', img: check, type: 'Payment success', reUpload: 'OK' });
                                    setIsPaid(true);
                                } else {
                                    setIsPaid(false);
                                }
                            } catch (e: any) {
                                setIsPaid(false);
                                setPaymentIsLoading(false);
                            }
                        }
                    } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                        setModalDetails({
                            show: true,
                            title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                            para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                            img: cross,
                            type: 'error',
                            reUpload: 'OK'
                        });
                        // window.location.reload();
                    } else {
                        setIsPaid(false);
                    }
                }
            }
            setIsLoading(false);
            setPaymentIsLoading(false);
        } catch (e: any) {
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Transaction Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    // payment fetching

    useEffect(() => {
        (async () => {
            try {
                if (selectedPlotDataId && activeIndex === 5) {
                    const payCheck = await paymentCheck('PROCESSING_FEE', selectedPlotDataId, resData?.uuid);
                    setIsPaid(payCheck?.isPaid);
                    setPaymentTried(payCheck?.paymentTried);
                    setPaymentData(payCheck?.paymentData);
                    setTotalPaidAmount(payCheck?.totalPaidAmount);
                    setTransactionDetails(payCheck?.transactionDetails);

                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);

                    const feeData = await fetchFeeData('SURRENDER', `PROCESSING_FEE`, selectedPlotDataId);
                    setPaymentData(feeData?.feeMasterDetails);
                    setTotalPaidAmount(feeData?.totalPaidAmount);
                }
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                setPaymentIsLoading(false);
            }
        })();
    }, [activeIndex, selectedPlotDataId]);

    useEffect(() => {
        const fetchESignStatus = async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNo || applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                const { data } = await POST(endpoints.consolidatedDocSearch, payload);
                data.filestoreId && setESignedDocumentData(data);
                !data.isEsignRequired && setIsChecked([true, true, true]);
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };
        if (activeIndex === 4 && !signedFileStoreId) {
            fetchESignStatus();
        }
    }, [activeIndex]);

    useEffect(() => {
        (async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNo || applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                if (signedFileStoreId) {
                    const { data } = await POST(endpoints.consolidatedDocESign, payload);
                    // setESignedDocumentData(data);
                    const consolidatedDocSearch = await POST(endpoints.consolidatedDocSearch, payload);
                    consolidatedDocSearch?.data.filestoreId && setESignedDocumentData(consolidatedDocSearch?.data);
                    !data.isEsignRequired && setIsChecked([true, true, true]);
                }
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [signedFileStoreId]);

    const signDocument = async () => {
        let url = window.location.origin + `/goa-idc/estate/surrender?activeIndex=${activeIndex}&applicationNumber=${applicationNo}`;
        url = btoa(url);

        if (values.esignOption === 'EKYC') {
            const userRequestInfo = requestInfo();
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${eSignedDocumentData.filestoreId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${eSignedDocumentData.filestoreId}&callbackUrl=${url}`);
        }
    };

    const handleClickSignDocument = () => {
        if (activeIndex === 4 && !isChecked.every((e: boolean) => e)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setEsignModal(true);
    };

    useEffect(() => {
        loadRazorpay();
    }, []);

    useEffect(() => {
        const getEntityDetails = async () => {
            const { data } = await POST(endpoints.searchExistingUser + `?tenantId=ga&userUuid=${userUuid}`, { RequestInfo: requestInfo() });
            setEntityDetails(data?.enterpriseEntity[0]);
        };
        getEntityDetails();
    }, [userUuid]);

    const onPay = async () => {
        setPaymentIsLoading(true);
        let url = window.location.origin + `/goa-idc/estate/surrender?plotId=${selectedPlotDataId}&activeIndex=${activeIndex}&applicationNumber=${applicationNo}&applicationId=${resData?.uuid}`;
        const periodTo = new Date(resData?.entityRefDetails?.auditDetails?.createdTime).setFullYear(new Date(resData?.entityRefDetails?.auditDetails?.createdTime).getFullYear() + 1);

        // const oneYearLaterDate = new Date(allotmentDate);
        // oneYearLaterDate.setFullYear(oneYearLaterDate.getFullYear() + 1);
        // const periodTo = oneYearLaterDate.getTime();
        const payload = {
            RequestInfo: requestInfo(),
            applicationNumber: applicationNo || applicationNumber,
            plotId: selectedPlotDataId,
            totalPaidAmount: +totalPaidAmount,
            callbackUrl: url,
            productInfo: 'Allotment Application Fees',
            gateway: selectedGateway,
            periodFrom: resData?.entityRefDetails?.auditDetails?.createdTime,
            periodTo: periodTo,
            payNowPaymentDetails: paymentData.map((obj: any) => ({
                scheduledPaymentDetails: {
                    paymentType: obj.feeType,
                    principalAmount: obj.principalAmount,
                    cgstPrincipal: obj.cgstPrincipal,
                    sgstPrincipal: obj.sgstPrincipal,
                    totalDueAmount: ((+obj.principalAmount || 0) + (+obj.cgstPrincipal || 0) + (+obj.sgstPrincipal || 0)).toFixed(2),
                    paidAmount: ((+obj.principalAmount || 0) + (+obj.cgstPrincipal || 0) + (+obj.sgstPrincipal || 0)).toFixed(2)
                }
            })),
            applicationId: resData?.uuid
        };
        try {
            if (!totalPaidAmount) {
                setPaymentIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (!selectedGateway) {
                setPaymentIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data } = await POST(endpoints.paymentGateway, payload);

                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, user, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data.additionalDetails?.walletClientCode}&walletRequestMessage=${data.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setPaymentIsLoading(false);
            setModalDetails({ show: true, title: t('PAYMENT_FAILED', 'Payment Initiation Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const onChangeNextIndex = async () => {
        const stepperLength = SURRENDER_STEPS_DETAILS.length;
        try {
            setIsLoading(true);
            if (activeIndex < stepperLength && selectedPlotDataId !== null) {
                // await MORTGAGE_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
                setIsLoading(false);
            }
            setIsLoading(false);
        } catch (e) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            setIsLoading(false);
        }
    };
    // application creation, updation and submit
    const lastSubmit = (isSaveOnly?: boolean, status?: string, comment?: string) => {
        if (!isPaid) onPay();
        else onSubmit(isSaveOnly, status, comment);
    };
    const onSubmit = async (isSaveOnly?: boolean, status?: any, comment?: string) => {
        const isFinalSubmit = activeIndex === SURRENDER_STEPS_DETAILS?.length ? true : false;
        const payload = SURRENDER_PAYLOAD(values, isFinalSubmit, selectedPlotDataId, entityDetails, resData, applicationNo, existMortgageDetails);
        let data: any;

        if (activeIndex === 4 && eSignedDocumentData?.isEsignRequired) {
            setModalDetails({ show: true, title: 'E-Sign Pending', para: 'Please sign the document', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            await SURRENDER_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });

            if (activeIndex === 1 && selectedPlotDataId === null) {
                setModalDetails({ show: true, title: 'Error!', para: t('SELECT_PLOT', 'Please Select The plot!'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            try {
                setIsLoading(true);
                if (!applicationNo && selectedPlotDataId !== null) {
                    data = await POST(endpoints.createAllotment, payload);
                    // setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: t('APPLICATION_CREATED', 'Application Created'), img: check, type: 'create success', reUpload: 'OK' });
                    toast(`${t('APPLICATION_CREATED_SUCCESSFULLY', 'Application Created Successfully!')}`, {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                    setIsLoading(false);
                } else {
                    data = await POST(endpoints.updateAllotment, payload);
                    setIsLoading(false);
                }
                setIsLoading(false);
                !isSaveOnly && onChangeNextIndex();
            } catch (e: any) {
                let errMsg = e?.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                setIsLoading(false);
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
            if (data) {
                if (!data?.data?.estateApplications?.[0].modificationDetails?.requestDetails?.mortgageClosureRequests.length) {
                    mortgageSearchData();
                }
                setResData(data?.data?.estateApplications[0]);
                setApplicationNo(data?.data?.estateApplications[0].applicationNo);

                if (isFinalSubmit) {
                    setModalDetails({
                        show: true,
                        title: 'Success!',
                        para: `Application${applicationNo ? ' Number ' + applicationNo : ''} for Surrender submitted successfully`,
                        finalSubmit: true,
                        img: check,
                        type: 'success',
                        reUpload: 'OK',
                        navigateUrl: '/goa-idc/enterprisedashboard'
                    });
                }
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const mortgageSearchData = async () => {
        const { data } = await POST(endpoints.searchMortgage + `?entityIds=${entityId}&plotIds=${selectedPlotDataId}&statusList=OPEN`, {
            RequestInfo: requestInfo()
        });
        try {
            if (data?.MortgageDetails?.length) {
                setExistMortgageDetails(data?.MortgageDetails[0]?.bankMortgageDetails);
                let formData = await Promise.all(data?.MortgageDetails?.map(async (item: any) => await setExistingMortgageDetails(item, 'CURRENT')));
                formData = formData.flat().map(obj => obj);
                console.log('formdata', formData);
                const updatedValues = { existingMortgageDetails: formData };
                setValues((prev: any) => ({ ...prev, existingMortgageDetails: formData, financingObtained: true }));
            }
        } catch (e) {}
    };

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                try {
                    setPaymentIsLoading(true);
                    queryParams?.['activeIndex'] && setActiveIndex(+queryParams?.['activeIndex']);
                    if (applicationNumber) {
                        const { data } = await POST(endpoints.searchAllotment + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                        setSelectedPlotDataId(data?.estateApplications[0]?.assignedPlotDetails?.uuid);
                        setApplicationNo(data?.estateApplications[0]?.applicationNo);
                        setResData(data?.estateApplications[0]);
                        const reasonValue = data?.estateApplications[0]?.modificationDetails?.requestDetails?.reason;
                        setIsChecked([true, true, true]);
                        setFileStoreId(data?.estateApplications[0]?.signedConsolidatedDocRefId);

                        const existingStatusBankDetails = await Promise.all(
                            data?.estateApplications?.[0]?.modificationDetails?.requestDetails?.mortgageClosureRequests.map(async (value: any) => {
                                const exisNocDocument = value?.document?.docReferenceId && (await entityDocumentData(value?.document?.docReferenceId));
                                console.log('noc doc exis', exisNocDocument[0]);
                                return {
                                    existingIfscCode: value?.bankMortgageDetails?.bankIFSC,
                                    existingBankName: value?.bankMortgageDetails?.bankDetailsResponse?.bank,
                                    existingBranchAddress: value?.bankMortgageDetails?.bankDetailsResponse?.address,
                                    existingNocDocument: exisNocDocument && exisNocDocument?.[0]
                                };
                            })
                        );
                        const financingObtained = existingStatusBankDetails.length > 0;

                        const tempPlotData = data?.estateApplications[0]?.appliedPlotDetails;
                        const plotRowData = [
                            {
                                estateName: tempPlotData?.estateDetails?.estateName,
                                plotType: tempPlotData?.plotType,
                                phase: tempPlotData?.phase,
                                plotNo: tempPlotData?.plotNo,
                                plotAreaSqm: tempPlotData?.plotAreaSqm,
                                uuid: tempPlotData?.uuid,
                                id: tempPlotData?.uuid
                            }
                        ];

                        setTableRowData([...tableRowData, ...plotRowData]);

                        setValues((prev: any) => ({ ...prev, surrenderReason: reasonValue, existingMortgageDetails: existingStatusBankDetails, financingObtained: financingObtained }));
                    }
                    if (queryParams?.['applicationNumber'] && queryParams?.['eg_pg_txnid']) {
                        getTransactionUpdate(queryParams);
                    } else {
                        setPaymentIsLoading(false);
                    }
                } catch (e: any) {
                    setPaymentIsLoading(false);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [location.search]);

    console.log(values);
    const SurrenderDetails = () => {
        return (
            <>
                <Card className='card_border '>
                    <Card.Body>
                        {SURRENDER_DETAILS.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };
    const EnterpriseDetails = () => {
        return (
            <>
                <Card className='card_border '>
                    <Card.Body>
                        {ENTERPRISE_DETAILS.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}
                        {!!values.financingObtained && values.existingMortgageDetails.length && (
                            <form>
                                <FieldArray name='existingMortgageDetails'>
                                    {({ insert, remove, push }) => (
                                        <div>
                                            {values.existingMortgageDetails?.length > 0 &&
                                                values.existingMortgageDetails.map((data: any, index_p: number) => (
                                                    <div className='row' key={index_p}>
                                                        <div className='col-sm-12  align-items-center pb-2'>
                                                            <div className='formHeading'>{`Financial Institution/Bank - ${index_p + 1}`}</div>
                                                            <div className=' ps-1'>
                                                                {SURRENDER_EXISTING_MORTGAGE_DETAILS.map((val: any, _: number) => (
                                                                    <GenericFormCard
                                                                        fieldPath={`existingMortgageDetails.${index_p}`}
                                                                        {...val}
                                                                        // onChangeAction={val?.formData?.map((formField: any, ind: number) => formField)}
                                                                    />
                                                                ))}
                                                                {/* <div className='col-6 py-3'>
                                                                <GenericFileUploadField
                                                                    field={`existingMortgageDetails.${index_p}.existingNocDocument`}
                                                                    title={`NOC from Financial Institution`}
                                                                    entityFileData={{ documentNamePrefix: `${values.existingMortgageDetails[index_p].bankIFSC}_Bank Letter `, documentType: 'Financial Institution_NOC' }}
                                                                    isDisabled={userType !== 'ENTERPRISE' ? true : false}
                                                                    isRequired={true}
                                                                />
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </FieldArray>
                            </form>
                        )}
                    </Card.Body>
                </Card>
            </>
        );
    };
    useEffect(() => {
        if (!!applicationNumber) {
            getProgressBarData(applicationNumber)
                .then(res => setProgressBarDetails(res.data))
                .catch(e => console.log(e));
        }
    }, []);
    const status: any = progressBarDetails?.progressStatus?.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: applicationNumber,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };
    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const renderPlotTable = () => {
        return (
            <>
                {!!applicationNumber && applicationData.status !== 'Draft' && (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <PaymentSubmissionStatus data={applicationData} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                        </Card.Body>
                    </Card>
                )}
                <Card className='card_border'>
                    <Card.Body>
                        <div className='ps-2 py-2 d-flex justify-content-between'>
                            <div className='formTitle m-0'>{'Plot Details'}</div>
                        </div>
                        <GenericActionTable rowData={tableRowData} columns={plotDetailsTableColumns} searchBarPlaceholder={'SEARCH_BY_PLOT'} />
                    </Card.Body>
                </Card>
            </>
        );
    };
    const onChangePrevIndex = () => {
        const stepperLength = SURRENDER_STEPS_DETAILS.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };
    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                await SURRENDER_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };
    return (
        <>
            <>
                <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} userType={userType} />
                <HeaderComponent />
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                            <GenericHeader
                                text={t('APPLICATION_FOR_SURRENDER', 'Application for Surrender')}
                                bottomSubText={applicationNo ? `Type : Surrender, No :${applicationNo} ` : ''}
                                additionalClass='sub-text-style'
                            />
                            <div className='col-3 stickyCard-section'>
                                <div className='stickyCard' style={{ top: '110px' }}>
                                    <GenericHeader
                                        text={t('APPLICATION_FOR_SURRENDER', 'Application for Surrender')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={applicationNo ? `Type : Surrender, No :${applicationNo} ` : ''}
                                    />
                                    <Card className='card_border p-3'>
                                        <Card.Body>
                                            <Card.Text className=' d-flex flex-column  row-gap-3'>
                                                <SideCard
                                                    steps={SURRENDER_STEPS_DETAILS}
                                                    activeIndex={activeIndex}
                                                    handleStepClick={handleStepClick}
                                                    indexType={'custom'}
                                                    indexArray={['A', 'B', 'C', 'D', 'E']}
                                                />
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className='col-9 form-section'>
                                <FormikProvider value={formik}>
                                    <Form>
                                        {/* {activeIndex === 1 && lesseeDetails()} */}

                                        {activeIndex === 2 && SurrenderDetails()}
                                        {activeIndex === 1 && renderPlotTable()}
                                        {activeIndex === 3 && EnterpriseDetails()}
                                        {activeIndex === 4 && (
                                            <Declaration
                                                isChecked={isChecked}
                                                declarationList={MORTGAGE_DECLARATION_LABEL}
                                                handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                                                setDocuments={setDocuments}
                                                signDocument={handleClickSignDocument}
                                                eSignedDocumentData={eSignedDocumentData}
                                                applicantName={'Lessee'}
                                                fileStoreId={fileStoreId}
                                            ></Declaration>
                                        )}

                                        {activeIndex === 5 && (
                                            <PaymentDetails
                                                paymentData={paymentData}
                                                selectedGateway={selectedGateway}
                                                setSelectedGateway={setSelectedGateway}
                                                isPaid={isPaid}
                                                gatewayData={gatewayData}
                                                transactionDetails={transactionDetails}
                                                title={'Application Fees'}
                                            ></PaymentDetails>
                                        )}
                                    </Form>
                                    <GenericPopupModal
                                        title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                        primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                        secondaryBtnTitle={t('CLOSE', 'Close')}
                                        isSecondaryBtn={true}
                                        secondaryAction={() => setEsignModal(false)}
                                        primaryAction={signDocument}
                                        isVisible={esignModal}
                                        children={<EsignModal />}
                                    />
                                </FormikProvider>
                            </div>
                        </div>
                        <ScrollToTop />
                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                    {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                                </div>
                                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                    {activeIndex !== SURRENDER_STEPS_DETAILS.length && activeIndex !== 5 && activeIndex !== 1 && (
                                        <GenericButtons text={t('SAVE', 'Save')} handleClick={() => onSubmit(true)} ClassName='me-3' />
                                    )}
                                    <GenericButtons
                                        text={
                                            activeIndex !== SURRENDER_STEPS_DETAILS.length
                                                ? activeIndex === 1
                                                    ? ` Next`
                                                    : `Save & Next`
                                                : isPaid === null
                                                ? ''
                                                : !isPaid
                                                ? paymentTried
                                                    ? `Retry Payment`
                                                    : `Pay Now`
                                                : t('SUBMIT', 'Submit')
                                        }
                                        variant='primary'
                                        handleClick={() => (activeIndex !== SURRENDER_STEPS_DETAILS.length ? onSubmit() : lastSubmit && lastSubmit())}
                                    />
                                </div>
                            </div>
                        </footer>
                    </>
                )}
            </>
        </>
    );
};

export default SurrenderComponent;
