import { Card } from 'react-bootstrap';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CHECKLIST_TABLE, PLOT_DETAILS } from '../Allotment/Allotment.const';
import { INSPECTION_DETAILS, INSPECTION_REPORT_CARD, TABLE_COLUMN_IR, UNAUTHORIZED_CONSTRUCTION, UNAUTHORIZED_SUB_LEASE, UPLOAD_DOCUMENT, UPLOADING_DOCUMENT } from './InspectionReportConst';
import { useEffect, useState } from 'react';
import LesseeDetails from '../SubLease/LesseeDetails';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { t } from 'i18next';
import { useFormikContext } from 'formik';
import { SUB_LEASE_RATE } from '../../../../utils/constants';
import GenericCommonTextField from '../../../../components/moduleCommonFormik/GenericCommonTextField.component';
import { getEntityDocumentDetails } from '../../Migration/Migration.controller';

export function InspectionReportCorrespondence({
    checkListRowData,
    onRowClick,
    rowDocData,
    setRowDocData,
    searchResponse,
    openSection,
    setOpenSection,
    nextValidAction,
    documentList,
    setDocumentList,
    isViewOnly,
    readOnly
}: any) {
    const { values, setValues, setFieldValue } = useFormikContext<any>();

    const plotData = [{ formData: PLOT_DETAILS[0]?.formData }];
    const inspectiondetails = INSPECTION_DETAILS?.map((val: any) => ({ ...values, formData: val.formData?.map((item: any) => ({ ...item, isDisabled: !values?.isUpdatable || isViewOnly })) }));

    const uploadDocument = UPLOAD_DOCUMENT?.map((val: any) => ({ ...val, formData: val.formData?.map((item: any) => ({ ...item, isDisabled: !values?.uploadingDocumentFor?.code || isViewOnly })) }));

    const tempColumnIR = TABLE_COLUMN_IR?.filter((val: any) => val.fieldName !== 'action');
    const tableIR =
        nextValidAction?.isUpdatable && !isViewOnly
            ? TABLE_COLUMN_IR
            : tempColumnIR?.map((val: any) => {
                  if (val.fieldName === 'documentName') {
                      return { ...val, clsName: 'col-sm-6' };
                  } else {
                      return { ...val };
                  }
              });

    const dropdownOptions = {
        uploadingDocumentFor: [
            { name: 'Unauthorized change in activity', code: 'UNAUTHORIZED_CHANGE_IN_ACTIVITY' },
            { name: 'Unauthorized construction/development', code: 'UNAUTHORIZED_CONSTRUCTION_DEVELOPMENT' },
            { name: 'Unauthorized Sub-Lease', code: 'UNAUTHORIZED_SUB_LEASE' },
            { name: 'Encroachment', code: 'ENCROACHMENT' },
            { name: 'Other issue', code: 'OTHER_ISSUE' }
        ]
    };

    useEffect(() => {
        setFieldValue('documentPrefix', values?.uploadingDocumentFor?.code);
    }, [values?.uploadingDocumentFor?.code]);

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const onChangeAction = (e: any, field: string) => {
        console.log('onChangeAction', e.target.value, field);
        if (field === 'areaOfSubLease') {
            let tempSubleaseFee = (((+values?.duration || 0) / 12) * +SUB_LEASE_RATE * e.target.value * +searchResponse?.plotDetails?.plotRate).toFixed(2);
            let tempPenalty = (+tempSubleaseFee * (+values?.penaltyInterest / 100)).toFixed(2);
            let tempIllegalFee = (+tempSubleaseFee + +tempPenalty).toFixed(2);
            setValues((prev: any) => ({ ...prev, illegalSubleaseFee: tempIllegalFee, areaOfSubLease: e.target.value }));
        }
        if (field === 'duration') {
            let tempSubleaseFee = (((+e.target.value || 0) / 12) * +SUB_LEASE_RATE * values?.areaOfSubLease * +searchResponse?.plotDetails?.plotRate).toFixed(2);
            let tempPenalty = (+tempSubleaseFee * (+values?.penaltyInterest / 100)).toFixed(2);
            let tempIllegalFee = (+tempSubleaseFee + +tempPenalty).toFixed(2);
            setValues((prev: any) => ({ ...prev, illegalSubleaseFee: tempIllegalFee, duration: e.target.value }));
        }
    };

    const renderLesseeDetails = () => {
        return (
            <>
                <Card.Body>
                    <LesseeDetails />
                </Card.Body>
            </>
        );
    };

    const renderPlotDetails = () => {
        return (
            <>
                <Card.Body>
                    {plotData?.map((val: any) => (
                        <GenericFormCard {...val} />
                    ))}
                    <div className='row d-flex m-0 p-0'>
                        <div className={`py-3 col-sm-6`}>
                            <GenericCommonTextField showInr={true} field={'plotRate'} title={'Plot Rate (INR/Sq.m.)'} titleCode={'PLOT_RATE'} isDisabled={true} />
                        </div>
                    </div>
                </Card.Body>
            </>
        );
    };

    const onDeleteRow = (row: any) => {
        const documentResponse = documentList?.find((val: any) => val?.docRefId === row?.docRefId);
        if (documentResponse) {
            const tempRowData = rowDocData?.filter((val: any) => val?.docRefId !== row?.docRefId);
            const tempData = tempRowData?.map((val: any, index: number) => ({ ...val, serialNo: index + 1 }));
            setRowDocData(tempData);
            const tempDocAfterDelete = documentList?.filter((val: any) => val?.docRefId !== row?.docRefId);
            setDocumentList(tempDocAfterDelete);
        } else {
            const updatedValues = values?.attachment?.length && values?.attachment?.filter((val: any) => val?.docReferenceId !== row?.docRefId);
            setFieldValue('attachment', updatedValues);
        }
    };

    const renderInspectionReport = () => {
        const UNAUTHORIZED_SUB_LEASE_2 = UNAUTHORIZED_SUB_LEASE?.map((val: any) => ({
            ...values,
            formData: val.formData?.map((item: any) => ({ ...item, isDisabled: isViewOnly }))
        }));
        const UNAUTHORIZED_CONSTRUCTION_2 = UNAUTHORIZED_CONSTRUCTION?.map((val: any) => ({
            ...values,
            formData: val.formData?.map((item: any) => ({ ...item, isDisabled: isViewOnly }))
        }));

        return (
            <>
                <Card.Body>
                    {inspectiondetails?.map((val: any) => (
                        <GenericFormCard {...val} />
                    ))}
                    {(values?.unauthorizedSubLease || values?.unauthorizedConstruction) && (
                        <div className='font-18 font-inter fw-bolder' style={{ paddingLeft: '0.6rem' }}>
                            {t('PENALTY_DETAILS', 'Penalty Details')}
                        </div>
                    )}

                    {values?.unauthorizedSubLease && UNAUTHORIZED_SUB_LEASE_2?.map((val: any) => <GenericFormCard {...val} onChangeAction={onChangeAction} />)}
                    {values?.unauthorizedConstruction && UNAUTHORIZED_CONSTRUCTION_2?.map((val: any) => <GenericFormCard {...val} />)}
                    <div className='font-18 font-inter fw-bolder' style={{ paddingLeft: '0.6rem' }}>
                        {t('ATTACHMENTS', 'Attachments')}
                    </div>
                    {nextValidAction?.isUpdatable && !isViewOnly ? (
                        <>
                            {UPLOADING_DOCUMENT?.map((val: any) => (
                                <GenericFormCard {...val} dropdownOptions={dropdownOptions} />
                            ))}
                            {uploadDocument?.map((val: any) => (
                                <GenericFormCard {...val} />
                            ))}
                        </>
                    ) : (
                        <></>
                    )}

                    <div style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}>
                        <GenericActionTable columns={tableIR} hideSearchBar={true} rowData={rowDocData} onDeleteRow={onDeleteRow} additionalMethod={onRowClick} itemsPerPage={5} />
                    </div>
                </Card.Body>
            </>
        );
    };

    console.log('uploadDocument', uploadDocument, rowDocData);

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                {t('CHECK_LIST', 'Condition/Check list')}
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {INSPECTION_REPORT_CARD.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.titleCode ? t(val.titleCode) : val.title}
                                </label>
                                {val.title === 'A - Lessee Details' && openSection === 'lesseeDetails' && renderLesseeDetails()}
                                {val.title === 'B - Plot Details' && openSection === 'plotDetails' && renderPlotDetails()}
                                {val.title === 'C - Inspection Details' && openSection === 'inspectionDetails' && renderInspectionReport()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
}
