import { Card, Form } from 'react-bootstrap';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import {
    CHECKLIST_TABLE,
    EXECUTED_DEED,
    INDENTURE_DETAILS,
    INDENTURE_TABLE,
    INDENTURE_UPLOADS,
    LEASE_DEED_APPLICATION,
    LEASE_DEED_CORRESPONDENCE_CARD,
    REGISTERED_INDENTURE_CORRESPONDENCE_CARD
} from '../LeaseDeed.const';
import { useEffect, useState } from 'react';
import PlotDetails from '../../../Migration/pages/PlotDetails';
import { PlotDropdownData } from '../../../UserManagement/types';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import Editor from '../../../../../components/ContentEditor';
import GenericEmailIDNotification from '../../../../../components/moduleCommonFormik/GenericEmailIDNotification.component';
import { useFormikContext } from 'formik';
import GenericFileUploadField from '../../../../../components/moduleCommonFormik/GenericFileUpload.component';
import { entityDocumentData } from '../LeaseDeed.controller';

export const LeaseDeedCorrespondence = ({
    content,
    setContent,
    checkListRowData,
    applicationStatus,
    designation,
    leaseDeedSearchRes,
    nextValidAction,
    leaseDeedDocuments,
    onRowClick,
    openSection,
    setOpenSection,
    isViewOnly
}: any) => {
    const [dropdownDataPlot, setDropdownDataPlot] = useState<PlotDropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });

    const { values, setValues } = useFormikContext<any>();

    const EXECUTED_DEED_UPDATED =
        designation === 'LA' && applicationStatus === 'APPROVED'
            ? EXECUTED_DEED
            : EXECUTED_DEED.map(item => ({
                  ...item,
                  formData: item.formData.map(field => ({
                      ...field,
                      isDisabled: true
                  }))
              }));

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };
    console.log(leaseDeedSearchRes);
    useEffect(() => {
        if (designation === 'LA' && applicationStatus === 'PENDING') {
            setValues({ ...values, readOnly: false });
        } else {
            setValues({ ...values, readOnly: true });
        }
    }, []);

    const LEASE_DEED_CORRESPONDENCE_CARD_RENDER =
        applicationStatus === 'APPROVED'
            ? [
                  ...LEASE_DEED_CORRESPONDENCE_CARD,
                  {
                      title: 'D - Indenture Execution',
                      field: 'indentureExecution'
                  }
              ]
            : applicationStatus === 'REGISTRATION_PENDING'
            ? REGISTERED_INDENTURE_CORRESPONDENCE_CARD
            : LEASE_DEED_CORRESPONDENCE_CARD;

    const handleEmailValues = (emails: any) => {
        setValues((prev: any) => ({ ...prev, emails: emails }));
    };

    const renderAllotteeDetails = () => {
        return (
            <Card.Body>
                {LEASE_DEED_APPLICATION.map(val => (
                    <GenericFormCard {...val} />
                ))}
            </Card.Body>
        );
    };
    useEffect(() => {
        const setCoverLetter = async () => {
            const subRegistrarIndentureRefDoc = leaseDeedSearchRes?.subRegistrarIndentureRefId && (await entityDocumentData(leaseDeedSearchRes?.subRegistrarIndentureRefId));
            setValues({ ...values, coverLetterOnApprove: subRegistrarIndentureRefDoc?.[0] });
        };
        setCoverLetter();
    }, [leaseDeedSearchRes?.subRegistrarIndentureRefId]);

    const onLeaseDeedDownload = (row: any) => {
        onRowClick(row);
        window.open(row?.documentUrl);
    };

    const renderNoteDetails = () => {
        const indentureUploads = isViewOnly ? INDENTURE_UPLOADS?.map((val: any) => ({ ...val, formData: val?.formData.map((item: any) => ({ ...item, isDisabled: true })) })) : INDENTURE_UPLOADS;
        return (
            <>
                {/* <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4'>
                            <Editor setContent={setContent} content={content} readOnly={values.readOnly} />
                        </div>
                    </Form.Group>
                </div> */}
                <Card.Body>
                    <div className='px-2'>
                        <GenericActionTable columns={INDENTURE_TABLE} hideSearchBar={true} itemsPerPage={5} rowData={leaseDeedDocuments} additionalMethod={onLeaseDeedDownload} />
                    </div>
                    {applicationStatus !== 'APPROVED' ? (
                        indentureUploads?.map((val: any) => <GenericFormCard {...val} />)
                    ) : applicationStatus === 'APPROVED' ? (
                        <div className='col-6 m-2 mt-3'>
                            <GenericFileUploadField field={'coverLetterOnApprove'} title={'Cover Letter'} isDisabled={true} />
                        </div>
                    ) : (
                        <></>
                    )}
                </Card.Body>
            </>
        );
    };

    const renderNotificationDetails = () => {
        return (
            <>
                <div className='px-4'>
                    <GenericEmailIDNotification isEditable={values.isEmailUpdatable} emailIdsData={values.emails} getEmailIds={handleEmailValues} />
                </div>
            </>
        );
    };

    const renderDeedExecution = () => {
        const executedDeedUpdate = isViewOnly
            ? EXECUTED_DEED_UPDATED?.map((val: any) => ({ ...val, formData: val?.formData.map((item: any) => ({ ...item, isDisabled: true })) }))
            : EXECUTED_DEED_UPDATED;
        return (
            <>
                <div className='px-4'>
                    {executedDeedUpdate.map((val: any) => (
                        <GenericFormCard {...val} />
                    ))}
                </div>
            </>
        );
    };

    const renderIndentureDetails = () => {
        return (
            <Card.Body>
                {INDENTURE_DETAILS.map(val => (
                    <GenericFormCard {...val} />
                ))}
            </Card.Body>
        );
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row  my-3 px-3 py-2'>
                    {leaseDeedSearchRes?.deedType !== 'TRIPARTITE_DEED' &&
                        (checkListRowData?.length ? (
                            <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                                <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                                <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                    Condition/Check list
                                </label>
                                <div className='form_fields px-2'>
                                    <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                                </div>
                            </div>
                        ) : (
                            ''
                        ))}
                    {LEASE_DEED_CORRESPONDENCE_CARD_RENDER.map((val: any, index: number) => {
                        return (
                            <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0'>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A - User Details' && openSection === 'userDetails' && renderAllotteeDetails()}
                                {val.title === 'B - Plot Details' && openSection === 'plotDetails' && (
                                    <Card.Body>
                                        <PlotDetails dropdownData={dropdownDataPlot} setDropdownData={setDropdownDataPlot} hideCard={true} isFieldDisable={true} auction={true} />
                                        {/* auction={true} is for hiding plot rate in the Indenture Workflow */}
                                    </Card.Body>
                                )}
                                {val.title === 'C - Indenture Notes' && openSection === 'indentureNotes' && renderNoteDetails()}
                                {val.title === 'C - Indenture Details' && openSection === 'indentureDetails' && renderIndentureDetails()}
                                {/* {val.title === 'D - Notification Details' && openSection === 'notificationDetails' && renderNotificationDetails()} */}
                                {val.title === 'D - Indenture Execution' && openSection === 'indentureExecution' && renderDeedExecution()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};
