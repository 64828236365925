import React, { useState, useEffect, useRef } from 'react';
import style from '../../features/modules/UserManagement/moduleComponents/table.module.css';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import GenericExportDropdown from '../export/GenericExport';
import editIcon from '../../assets/icons/edit.svg';
import viewIcon from '../../assets/icons/view.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import ToolTipDetails from '../ToolTipFile';
import { convertToINR } from '../../features/modules/EstateManagement/EstateManagement.controller';
import StatusAlertPopup from './StatusAlertPopup';
import { cross } from '../../utils/Logo';
import { getEpoch } from '../../utils/utils';

const GenericActionTable = (props: any) => {
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);
    const [selected, setSelected] = useState<any[]>([]);
    const [sortedColumn, setSortedColumn] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<string>('');

    const [toggle, setToggle] = useState(-1);
    const [value, setValue] = useState();
    const [errormsg, setErrorMsg] = useState('');
    const [total, setTotal] = useState<any>(0);
    const [searchedData, setSearchedData] = useState(props.rowData || []);
    const [searchedText, setSearchedText] = useState<string>('');
    const [isChecked, setIsChecked] = useState(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isDate, setIsDate] = useState<boolean>(false);

    useEffect(() => {
        props.selectedItems?.length && setSelected(props.selectedItems);
    }, [props.selectedItems]);

    const filteredColumn = props.columns?.filter((item: any) => item.fieldName !== 'checkbox' && item.type !== 'action' && item.fieldName !== 'radio');

    const handleChange = (e: any, index: number, row: any) => {
        const searchDataClone = _.cloneDeep(searchedData);
        const scoreWithoutDecimal = e.target.value.replace(/[^0-9]/g, '');
        setValue(scoreWithoutDecimal);
        const inputValue = parseInt(e.target.value);

        const newSearchData = searchDataClone.map((val: any, ind: number) => {
            if (ind === index) {
                return { ...val, [`${props.totalRow}`]: inputValue };
            } else return val;
        });
        setSearchedData(newSearchData);

        if (inputValue > +row.maxMarks && inputValue) {
            setErrorMsg('Marks should be less than Max Marks');
        } else if (inputValue <= +row.maxMarks) {
            setErrorMsg('');
        } else if (!inputValue) {
            setErrorMsg('Field is empty');
        }
    };

    const handleDoubleClick = (fieldValue: any, index: number) => {
        if (!errormsg) {
            setValue(fieldValue);
            setToggle(index);
        }
    };

    const handleBlur = (e: any, row: any) => {
        if (!errormsg) {
            props.setRowData(searchedData);
            setToggle(-1);
        }
        props.functionReturnError && props.functionReturnError(errormsg);
    };

    useEffect(() => {
        props?.rowData && setSearchedData(props?.rowData);
        setCurrentPage(1);
        setSearchedText('');

        // const selectedRows = props?.rowData.filter((item: any) => item.isChecked).map((item: any) => item.id);
        // setSelected(selectedRows);
    }, [props?.rowData]);

    const [itemsPerPage, setItemPerPage] = useState(props?.itemsPerPage || 10);
    const [activeIndex, setActiveIndex] = useState(null);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = searchedData?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(props?.rowData?.length / itemsPerPage);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleOnSearch = (e: any) => {
        if (e.target.value) {
            setSearchedText(e.target.value);
            const searchValue = e.target.value.toLowerCase();

            const filterObject = (obj: any): boolean => {
                return Object.values(obj).some(value => {
                    if (typeof value === 'object' && value !== null) {
                        return filterObject(value);
                    }
                    return value?.toString().toLowerCase().includes(searchValue);
                });
            };

            const filtered = props?.rowData?.filter((item: any) => filterObject(item));
            setSearchedData(filtered);
            setCurrentPage(1);
        } else {
            setSearchedData(props?.rowData);
            setSearchedText('');
        }
    };

    const sortByColumn = (column: string, dateCheck: boolean) => {
        if (sortedColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedColumn(column);
            setSortOrder('asc');
        }
        setIsDate(dateCheck);
    };

    const showSortedColumnIcon = (columnName: string) => {
        return sortedColumn === columnName ? (
            sortOrder === 'asc' ? (
                <i className='fa fa-long-arrow-up table-arrow' aria-hidden='true' />
            ) : (
                <i className='fa fa-long-arrow-down table-arrow' aria-hidden='true'></i>
            )
        ) : (
            <></>
        );
    };

    useEffect(() => {
        if (sortOrder && sortedColumn) {
            const sortedData = [...searchedData]?.sort((a, b) => {
                const columnValueA = isDate ? getEpoch(_.get(a, sortedColumn)) : _.get(a, sortedColumn);
                const columnValueB = isDate ? getEpoch(_.get(b, sortedColumn)) : _.get(b, sortedColumn);

                if (sortOrder === 'asc') {
                    return typeof columnValueA === 'string' ? columnValueA?.localeCompare(columnValueB) : columnValueA - columnValueB;
                } else {
                    return typeof columnValueA === 'string' ? columnValueB?.localeCompare(columnValueA) : columnValueB - columnValueA;
                }
            });
            setSearchedData(sortedData);
        }
    }, [sortOrder, sortedColumn, isDate]);

    const handleAdd = () => {};
    const handleDeleteRow = (rowData: any) => {
        props?.onDeleteRow(rowData);
    };

    const onCheckColumn = (event: any, data: any) => {
        if (props?.checkType == 'multiCheck') {
            if (event.target.checked) {
                const newSelecteds = currentItems?.map((n: any, index: number) => n.id); // replaced rowData to currentItems to select only the current page items
                setSelected(newSelecteds);
                props?.onCheckHandler(newSelecteds);
                return;
            }
            setSelected([]);
            props?.onCheckHandler([]);
        } else {
            setSelected([]);
        }
    };

    const onRowCheck = (event: any, id: any, col: any) => {
        if (props.serialCheckEnable) {
            const currentIndex = props.rowData.findIndex((item: any) => item.id === id);
            if (event.target.checked) {
                // Check if all previous records are selected
                for (let i = 0; i < currentIndex; i++) {
                    if (!selected.includes(props.rowData[i].id) && !props.rowData[i].isChecked) {
                        setModalDetails({ show: true, title: 'Error!', para: t('PLEASE_SELECT_PREVIOUS_RECORD', 'Please select previous records first'), img: cross, type: 'error', reUpload: 'OK' });
                        return;
                    }
                }
            } else {
                // Uncheck all records after the unchecked record
                const newSelected = selected.filter(selectedId => {
                    const selectedIndex = props.rowData.findIndex((item: any) => item.id === selectedId);
                    return selectedIndex < currentIndex;
                });
                setSelected(newSelected);
                props?.onCheckHandler(newSelected, col);
                return;
            }
        }

        if (props?.checkType == 'multiCheck') {
            const selectedIndex: any = selected.indexOf(id as never);
            let newSelected: any = [];
            const NOCHECK_ITEM = -1;
            if (selectedIndex === NOCHECK_ITEM) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            }
            setSelected(newSelected);
            props?.onCheckHandler(newSelected, col);
        } else {
            const selectedIndex: any = selected.indexOf(id as never);
            let newSelected: any = [];
            const NOCHECK_ITEM = -1;
            if (selectedIndex === NOCHECK_ITEM) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            }
            setSelected(newSelected);
            props?.onCheckHandler(newSelected, col);
        }
    };

    const isSelected = (id: any) => {
        return selected.indexOf(id as never) !== -1;
    };

    const uncheckLast = () => {
        if (props?.checkType === 'singleCheck') {
            let ns: any = selected.slice(1);
            setSelected(ns);
        }
    };

    useEffect(() => {
        let x: any = document.getElementById('table-column-check');
        const checkedItems = selected.every(id => currentItems.some((obj: any) => obj.id === id));

        if (props?.checkType && props?.checkType !== 'singleCheck') {
            if (selected.length > 0 && selected.length < props?.rowData?.length && selected.length === currentItems.length && checkedItems) {
                x.indeterminate = true;
            } else {
                x.indeterminate = false;
            }
            if (selected.length > 1 && props?.checkType === 'singleCheck') {
                uncheckLast();
            }
            // props?.onCheckHandler(selected);
        }
    }, [selected, currentItems]);

    const onRowClick = (event: any, rowData: any, index: any, field: any) => {
        props.additionalMethod(rowData, field);
        if (props?.highlightRow) setActiveIndex(index);
    };

    const sum = (arr: any[], key: string) => {
        if (props?.totalData !== undefined && props?.totalData !== null) {
            props?.setCalculatedTotal && props?.setCalculatedTotal(props?.totalData);
            return convertToINR(props?.totalData);
        }
        let data = convertToINR(
            +arr
                .reduce((acc: number, cur: any) => {
                    let value: any = cur[key];

                    if (typeof value === 'string') {
                        value = value.replace(/,/g, '');
                    } else if (typeof value === 'number') {
                        value = value;
                    }
                    return acc + +value;
                }, 0)
                .toFixed(2)
        );
        props?.setCalculatedTotal && props?.setCalculatedTotal(data);
        return data;
    };

    const PaginationOptions = () => {
        const [isShow, setIsShow] = useState(false);
        const exportRef = useRef<any>();

        useEffect(() => {
            const handleOutsideClick = (e: any) => {
                if (exportRef.current && !exportRef.current.contains(e.target)) {
                    setIsShow(false);
                }
            };
            document.addEventListener('click', handleOutsideClick);

            return () => {
                document.removeEventListener('click', handleOutsideClick);
            };
        }, []);

        return (
            <div id='export_button' ref={exportRef} className={`d-inline-flex flex-row align-items-center justify-content-around stickyCard my-1`}>
                <div className='ps-3'>{t('ROWS_PER_PAGES', 'Rows per pages')}</div>
                <button onClick={() => setIsShow(!isShow)} type={'button'} className={`Button_small me-3 pagination-count-button`} style={{ minWidth: '50px' }}>
                    <span className='font-14'>{itemsPerPage}</span>
                </button>
                {isShow && (
                    <div className='dropdown-content userprofile-content' style={{ top: '-160px', right: '15px', width: 50, borderRadius: 4, cursor: 'pointer' }}>
                        {[10, 25, 50, 100].map(item => (
                            <div onClick={() => setItemPerPage(item)} style={{ borderBottom: '1px solid #ccc', borderRadius: 0 }}>
                                {item}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    useEffect(() => {
        if (currentItems.every((item: any) => item.isChecked === true)) {
            setIsChecked(true);
        } else setIsChecked(false);
    }, [currentItems]);

    const handleModalClose = (type: string) => {
        setModalDetails({ show: false });
    };

    return (
        <>
            <div className='container mb-5 px-0 mx-0 generic-action-table font-inter'>
                {!props.hideSearchBar && (
                    <div className={`col-sm-12 position-relative ${style.application_search} d-flex ${props?.hideSearchBar ? 'justify-content-end mb-2' : 'justify-content-between'}`}>
                        {!props.hideSearchBar && (
                            <input
                                type='search'
                                value={searchedText}
                                defaultValue={''}
                                onChange={handleOnSearch}
                                placeholder={t(props.searchBarPlaceholder)}
                                className={`col-sm-6 my-3 p-2 ${props?.hideSearchBar && 'd-none'}`}
                            />
                        )}
                        {props?.rowData?.length > 0 && !props.hideExportBtn && <GenericExportDropdown columns={filteredColumn} rowData={props.rowData} />}
                    </div>
                )}
                <div style={{ position: 'absolute', right: '2%', top: '3.5%' }}>
                    {props?.additionalPdfData && <GenericExportDropdown columns={filteredColumn} rowData={props.rowData} additionalPdfData={props.additionalPdfData} />}
                </div>
                <div className={`col-12 ${style.table} bg-white`}>
                    <div className={`px-1 d-flex ps-3 pe-3 text-center ${style.table_header}`}>
                        {props?.columns?.map((data: any) => {
                            const checkedItems = selected.every(id => currentItems.some((obj: any) => obj.id === id));
                            return data.fieldName === 'checkbox' ? (
                                <span className={`${data?.clsName} table-column-checkbox table-column-seprator py-3 `}>
                                    {props?.checkType !== 'singleCheck' ? (
                                        <input
                                            type='checkbox'
                                            id='table-column-check'
                                            ref={input => {
                                                if (input) {
                                                    if (selected.length === currentItems.length) input.indeterminate = true;
                                                    else {
                                                        input.indeterminate = false;
                                                    }
                                                }
                                            }}
                                            checked={(props?.rowData?.length > 0 && selected.length === currentItems.length && checkedItems) || isChecked}
                                            name='column-check'
                                            onClick={e => onCheckColumn(e, data)}
                                            disabled={data?.isDisabled}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </span>
                            ) : (
                                <span
                                    className={`${data?.clsName} py-3 table-column-seprator cursor-pointer  ${
                                        data.toolTipDetails ? ' d-flex justify-content-center column-gap-1 position-relative align-items-center' : ''
                                    }`}
                                    onClick={() => sortByColumn(data.fieldName, data.isDate)}
                                >
                                    {data.titleCode ? t(data.titleCode) : data.colName} {showSortedColumnIcon(data.fieldName)}
                                    {data.toolTipDetails && <ToolTipDetails toolTipDetails={data.toolTipDetails} />}
                                </span>
                            );
                        })}
                    </div>
                    {searchedData?.length === 0 ? (
                        <div className='text-center fw-bold mt-3'>
                            <h5>Sorry, we couldn't find any results</h5>
                        </div>
                    ) : (
                        <>
                            {currentItems?.map((row: any, index: number) => {
                                const rowId = row.id;
                                const isItemSelected = isSelected(rowId);

                                return (
                                    <>
                                        <div className={`col-sm-12 px-0 py-3 pe-3 d-flex ps-3  text-center`} style={{ backgroundColor: `${activeIndex === index ? '#e0f1da' : ''}` }}>
                                            {props?.columns.map((o: any) =>
                                                o?.isClickable ? (
                                                    <span
                                                        className={`${o.clsName}`}
                                                        style={{ color: '#3370D4', cursor: 'pointer', fontWeight: `${row.isRead === false ? 'bolder' : 'normal'}` }}
                                                        onClick={e => onRowClick(e, row, index, o.fieldName)}
                                                    >
                                                        {_.get(row, o.fieldName)}
                                                        {o.secondaryText && <div>{o.secondaryText}</div>}
                                                    </span>
                                                ) : o.fieldName === 'checkbox' ? (
                                                    <span className={`${o.clsName}`}>
                                                        <input
                                                            type='checkbox'
                                                            key={Math.random()}
                                                            checked={isItemSelected || row.isChecked}
                                                            name='row-check'
                                                            onChange={event => onRowCheck(event, rowId, row)}
                                                            disabled={row.isDisabled}
                                                            className={row.isChecked ? 'default-checked' : ''}
                                                        />
                                                    </span>
                                                ) : o.fieldName === 'radio' ? (
                                                    <span className={`${o.clsName}`}>
                                                        <input
                                                            type='radio'
                                                            className='generic-table-radio-field'
                                                            key={o.id}
                                                            name='row-check'
                                                            checked={o.selectedValue === row.id}
                                                            onChange={event => o.onRadioClick(row)}
                                                        />
                                                    </span>
                                                ) : o.fieldName !== 'action' ? (
                                                    <span
                                                        className={`${o.clsName} ${_.get(row, o.fieldName)}-status  ${
                                                            o.fieldName === 'deskPendencyDays'
                                                                ? !!row.isDeskPendency && row.isDeskPendency !== null
                                                                    ? 'Desk-Pendency-Valid ms-2 h-100'
                                                                    : !row.isDeskPendency && row.isDeskPendency !== null
                                                                    ? 'Desk-Pendency-notValid ms-2 h-100'
                                                                    : row.isDeskPendency === null
                                                                    ? 'Desk-Pendency-Dash ms-2 h-100'
                                                                    : ''
                                                                : o.fieldName === 'overallPendencyDays'
                                                                ? !!row.isOverallPendency && row.isOverallPendency !== null
                                                                    ? 'Desk-Pendency-Valid ms-2 h-100'
                                                                    : !row.isOverallPendency && row.isOverallPendency !== null
                                                                    ? 'Desk-Pendency-notValid ms-2 h-100'
                                                                    : row.isOverallPendency === null
                                                                    ? 'Desk-Pendency-Dash ms-2 h-100'
                                                                    : ''
                                                                : ''
                                                        }`}
                                                        style={{ fontWeight: `${row.isRead === false ? 'bolder' : 'normal'}` }}
                                                    >
                                                        {o.isEditable ? (
                                                            toggle !== index ? (
                                                                <input
                                                                    onFocus={e => handleDoubleClick(`${_.get(row, o.fieldName)}${o.fieldName2 ? _.get(row, o.fieldName2) : ''}`, index)}
                                                                    className='table-content-right'
                                                                    value={_.get(row, o.fieldName)}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <input
                                                                        type='text'
                                                                        value={value}
                                                                        onChange={e => handleChange(e, index, row)}
                                                                        onBlur={e => handleBlur(e, row)}
                                                                        className='table-content-right'
                                                                    />
                                                                    <p className=' text-danger font-12'>{errormsg}</p>
                                                                </>
                                                            )
                                                        ) : (
                                                            <div className='d-flex flex-column'>
                                                                <span>
                                                                    {t(
                                                                        _.get(row, o.fieldName) === undefined || _.get(row, o.fieldName) === null || _.get(row, o.fieldName) === '0.00'
                                                                            ? '--'
                                                                            : `${!!o.addDecimal ? _.get(row, o.fieldName).toFixed(2) : _.get(row, o.fieldName)}`
                                                                    )}
                                                                </span>
                                                                <span>
                                                                    {t(
                                                                        o.fieldName2 && o.fieldName2 === 'currentOwnerDesig' && row.currentOwnerDesig === '-'
                                                                            ? 'Enterprise User'
                                                                            : o.fieldName2
                                                                            ? _.get(row, o.fieldName2)
                                                                            : ''
                                                                    )}
                                                                </span>
                                                                <span>{o.fieldName === 'ind' ? index + 1 : ''}</span>
                                                            </div>
                                                        )}
                                                    </span>
                                                ) : (
                                                    o.fieldName === 'action' &&
                                                    !props.disable && (
                                                        <span className={`${o.clsName}`}>
                                                            {o.actions.includes('add') && (
                                                                <button className={`${style.arrow}`} onClick={handleAdd} type='button'>
                                                                    <i className='fa fa-plus' aria-hidden='true'></i>
                                                                </button>
                                                            )}
                                                            {o.actions.includes('edit') && (
                                                                <button className={`${style.arrow}`} onClick={() => props.handleEditRow(row)} type='button'>
                                                                    <img src={editIcon} alt='edit' className='table-action-icon' />
                                                                </button>
                                                            )}
                                                            {o.actions.includes('delete') && (
                                                                <button className={`${style.arrow}`} onClick={() => handleDeleteRow(row)} type='button'>
                                                                    <img src={deleteIcon} alt='delete' className='table-action-icon' />
                                                                </button>
                                                            )}
                                                            {o.actions.includes('view') && (
                                                                <button className={`${style.arrow}`} onClick={() => props.handleView(row)} type='button'>
                                                                    <img src={viewIcon} alt='view' className='table-action-icon' />
                                                                </button>
                                                            )}
                                                            {o.actions.includes('other') &&
                                                                (row.verification !== 'DONE' ? (
                                                                    <span onClick={() => props.handleOtherAction(row)} style={{ color: '#3370D4', cursor: 'pointer', fontWeight: `normal` }}>
                                                                        {o.actionText}
                                                                    </span>
                                                                ) : (
                                                                    '-'
                                                                ))}
                                                        </span>
                                                    )
                                                )
                                            )}
                                        </div>
                                    </>
                                );
                            })}
                        </>
                    )}

                    {props?.totalRow && currentItems?.length > 0 ? (
                        <>
                            <div className={`col-sm-12 py-3 ps-3 d-flex justify-content-end `} style={{ borderTop: `1px solid #33333314`, paddingRight: '31px' }}>
                                <span>{props?.totalRowText} </span>
                                {props?.totalTooltip && <ToolTipDetails toolTipDetails={props?.totalTooltip} />}
                                <span>&nbsp;:&nbsp;</span>
                                <span className='fw-medium'>{sum(currentItems, props?.totalRow)}</span>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {!props.hideFooter ? (
                        <div className={`col-sm-12  py-1 position-relative  d-flex justify-content-between align-items-center  ${style.table_footer}`}>
                            <div className='ps-3'>
                                {t('ENTRIES_FOUND', 'Entries found')} : {props.rowData?.length}
                            </div>
                            <div className='d-flex align-items-center' style={{ marginRight: 30 }}>
                                <div>
                                    <PaginationOptions />
                                </div>
                                <div>
                                    <button onClick={handlePrevPage} type='button' disabled={currentPage === 1} className={`${style.arrow}`}>
                                        <i className='fa fa-arrow-left' aria-hidden='true'></i>
                                    </button>

                                    <span className={` mx-1 ${style.pagenumber}`}>{currentPage}</span>

                                    <button onClick={handleNextPage} type='button' disabled={currentPage === totalPages} className={`${style.arrow}`}>
                                        <i className='fa fa-arrow-right' aria-hidden='true'></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />
                </div>
            </div>
        </>
    );
};
export default GenericActionTable;
