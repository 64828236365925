import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { Card } from 'react-bootstrap';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { t } from 'i18next';
import { EXTENSION_DETAILS_FORM, extensionDeclarationList, LEASE_EXTENSION_FORM_STEPS } from './LeaseExtension.const';
import { FormikProvider, useFormik } from 'formik';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { POST, POST2, devUrl, endpoints, estateEndpoints, getProgressBarData, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import './LeaseExtension.scss';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getFirstErrorKey } from '../../../../utils/utils';
import PaymentDetails from '../Allotment/PaymentDetails';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import Loading from '../../../../components/portalComponents/Loading.component';
import { fetchFeeData, fetchGatewayData, getNextActions, getOptions, getQueryParams, loadRazorpay, paymentCheck, paymentPayload } from '../EstateManagement.controller';
import { getEntityDocumentDetails } from '../../Migration/Migration.controller';
import { toast } from 'react-toastify';
import { check, cross } from '../../../../utils/Logo';
import { EXTENSION_LEASE_TERM } from '../../../../utils/formikValidation/FormikValidation';
import { PLOT_DETAILS_TABLE } from '../SubLease/SubLeaseConst';
import { RazorpayOptions } from '../../UserManagement/types';
import Declaration from '../Allotment/Declaration';
import { EMPLOYEE_AUTHORIZED, KEY_ID, TENANT_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../../../utils/constants';
import axios from 'axios';
import PaymentSubmissionStatus from '../../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../../components/moduleCommonFormik/ProgressBar';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { EsignModal } from '../../../commonPages/EsignModal';

const LeaseExtension = () => {
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visited, setVisited] = useState(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>(null);
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);

    const getUser = JSON.parse(localStorage.getItem('user') || '{}');
    let navigate = useNavigate();
    const stepsDetails = LEASE_EXTENSION_FORM_STEPS;
    const [paymentData, setPaymentData] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState([false, false, false, false]);
    const [documents, setDocuments] = useState<any>([]);
    const [eSignedDocumentData, setESignedDocumentData] = useState<any>(null);
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);
    const [existingUser, setExistingUser] = useState<any>(null);
    const [response, setResponse] = useState<any>(null);
    const [applicationNo, setApplicationNo] = useState<any>(null);
    const [applicationId, setApplicationId] = useState<any>(null);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>(null);

    const [searchParams] = useSearchParams();
    const location: any = useLocation();

    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const applicationNumber = searchParams.get('applicationNumber');
    const userId = JSON.parse(localStorage.getItem('user') || '{}')?.uuid;
    const userType = getUser?.type;

    const formik: any = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            isSpecialProject: false,
            esignOption: 'DSC'
            // commericalyCertificate: commercialCertificate //dummy data
        },
        onSubmit: value => {},
        validationSchema: EXTENSION_LEASE_TERM[`page${activeIndex}`]
    });

    const { values, setValues, setFieldValue, errors, setFieldTouched } = formik;

    const onPlotSelect = (selectedPlot: any) => {
        setSelectedPlotDataId(selectedPlot.uuid);
        setFieldValue('plotUuid', selectedPlot.uuid);
    };

    const plotDetailsTableColumns = PLOT_DETAILS_TABLE.map((val: any, index: number) => {
        if (index === 0) {
            return {
                ...val,
                onRadioClick: onPlotSelect,
                selectedValue: selectedPlotDataId
            };
        }
        return val;
    });

    useEffect(() => {
        if (userType !== 'ENTERPRISE') {
            window.location.href = '/goa-idc/access-restricted';
        }

        if (applicationNumber) {
            (async () => {
                let applicationAccess;
                try {
                    applicationAccess = await POST(endpoints?.applicationAccess + `?businessid=${applicationNumber}`, { RequestInfo: requestInfo() });
                    if (!applicationAccess?.data.isAuthorized) {
                        window.location.href = '/goa-idc/access-restricted';
                    }
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }

        const fetchData = async () => {
            //Fetch Transfer Lessee Details
            try {
                const { data: existingUser } = getUser.uuid && (await POST(endpoints.searchExistingUser + `?userUuid=${getUser.uuid}`, { RequestInfo: requestInfo() }));
                if (existingUser) {
                    setExistingUser(existingUser?.enterpriseEntity[0]);
                }
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        fetchData();

        loadRazorpay();

        if (!!applicationNumber) {
            getProgressBarData(applicationNumber)
                .then(res => setProgressBarDetails(res.data))
                .catch(e => console.log(e));
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            setPaymentIsLoading(true);
            const queryParams = getQueryParams(searchParams);
            if (queryParams?.['applicationNumber']) {
                queryParams?.['activeIndex'] && setActiveIndex(+queryParams?.['activeIndex']);

                try {
                    const { data: applicationSearchResponse } = await POST(endpoints.searchAllotment + `?applicationNos=${queryParams?.['applicationNumber']}`, { RequestInfo: requestInfo() });

                    setSelectedPlotDataId(applicationSearchResponse?.estateApplications[0]?.appliedPlotDetails?.plotDetails?.plotId);
                    setApplicationId(applicationSearchResponse?.estateApplications[0].uuid);
                    setResponse(applicationSearchResponse?.estateApplications[0]);
                    setApplicationNo(applicationSearchResponse.estateApplications[0]?.applicationNo);
                    setFileStoreId(applicationSearchResponse?.estateApplications[0]?.signedConsolidatedDocRefId);
                    setValues((prev: any) => ({
                        ...prev,
                        plotUuid: applicationSearchResponse?.estateApplications[0]?.appliedPlotDetails?.plotDetails?.plotId,
                        extensionDuration: applicationSearchResponse?.estateApplications[0]?.modificationDetails?.requestDetails?.duration
                    }));
                } catch (e: any) {
                    setIsLoading(false);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: t('ERROR', 'Error'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }

                if (queryParams?.['plotId']) {
                    setPaymentIsLoading(true);
                    getTransactionUpdate(queryParams);
                }
            }
            setIsLoading(false);
            setPaymentIsLoading(false);
        })();
    }, [location.search]);

    useEffect(() => {
        (async () => {
            if (activeIndex > 3 && values.plotUuid) {
                try {
                    const payCheck = await paymentCheck('PROCESSING_FEE', values.plotUuid, applicationId);
                    setIsPaid(payCheck?.isPaid);
                    setPaymentTried(payCheck?.paymentTried);
                    setPaymentData(payCheck?.paymentData);
                    setTotalPaidAmount(payCheck?.totalPaidAmount);
                    setTransactionDetails(payCheck?.transactionDetails);

                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);

                    const feeData = await fetchFeeData('EXTENSION', 'PROCESSING_FEE', values.plotUuid);
                    setPaymentData(feeData?.feeMasterDetails);
                    setTotalPaidAmount(feeData?.totalPaidAmount);
                    setPaymentIsLoading(false);
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: t('ERROR', 'Error'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }
        })();

        const fetchESignStatus = async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNo || applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                const { data } = await POST(endpoints.consolidatedDocSearch, payload);
                data.filestoreId && setESignedDocumentData(data);
                !data.isEsignRequired && setIsChecked([true, true, true, true]);
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (activeIndex === 3 && !signedFileStoreId) {
            fetchESignStatus();
        }
    }, [activeIndex, values.plotUuid]);

    useEffect(() => {
        (async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNo || applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                if (signedFileStoreId) {
                    const { data } = await POST(endpoints.consolidatedDocESign, payload);
                    // setESignedDocumentData(data);
                    const consolidatedDocSearch = await POST(endpoints.consolidatedDocSearch, payload);
                    consolidatedDocSearch?.data.filestoreId && setESignedDocumentData(consolidatedDocSearch?.data);
                    !data.isEsignRequired && setIsChecked([true, true, true, true]);
                }
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [signedFileStoreId]);

    useEffect(() => {
        setValues((prev: any) => ({ ...prev, isUpdatable: hasValidAction }));
    }, [hasValidAction]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let appliedPlotDetails: any = [];
                let plotDataArray = [];
                if (response) {
                    appliedPlotDetails = [{ ...response?.appliedPlotDetails, estateName: response?.appliedPlotDetails?.estateDetails?.estateName, id: response?.appliedPlotDetails?.uuid }];
                }
                let { data: plotData } = await POST(endpoints.validApplication + `?type=APPLY_LEASE_TERM_EXTENSION`, { RequestInfo: requestInfo() });
                if (plotData?.entityRefDetails?.length) {
                    let plotIds = plotData?.entityRefDetails?.map((item: any) => item.plotId);
                    plotIds = plotIds.join(',');

                    if (!plotIds) return;
                    let { data } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

                    plotDataArray = data?.plots?.map((plot: any) => ({
                        estateName: plot?.estateDetails?.estateName,
                        plotType: plot?.plotType,
                        phase: plot?.phase,
                        plotNo: plot?.plotNo,
                        plotAreaSqm: plot?.plotAreaSqm,
                        uuid: plot?.uuid,
                        id: plot?.uuid
                    }));
                }

                const tempPlotData = [...plotDataArray, ...appliedPlotDetails];
                setRowData(tempPlotData);
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (existingUser?.uuid) fetchData();
    }, [existingUser, response]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (existingUser.uuid && values.plotUuid) {
                    let { data }: any = await POST(endpoints.leaseDeedSearch + `?entityIds=${existingUser.uuid}&plotIds=${values.plotUuid}`, { RequestInfo: requestInfo() });
                    let { data: coCertificate } = await POST(endpoints.commercialCertificateSearch + `?entityIds=${existingUser.uuid}&plotIds=${values.plotUuid}&statuses=APPROVED`, {
                        RequestInfo: requestInfo()
                    });
                    const deedDoc = data?.deedDetails[0]?.executedIndentureRefId && (await getEntityDocumentDetails({ docReferenceId: data?.deedDetails[0]?.executedIndentureRefId }));
                    const commercialCertificate =
                        coCertificate?.commercialOpsDetails[0]?.docRefId && (await getEntityDocumentDetails({ docReferenceId: coCertificate?.commercialOpsDetails[0]?.docRefId }));
                    setValues((prev: any) => ({ ...prev, leaseDeedExtensiondoc: deedDoc, commericalyCertificate: commercialCertificate }));
                }
            } catch (e: any) {}
        };

        values.plotUuid && existingUser && fetchData();
    }, [values.plotUuid, existingUser]);

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                const nextActions = await getNextActions(applicationNumber);
                setHasValidAction(nextActions?.nextValidAction?.length > 0);
                if (nextActions?.nextValidAction?.length === 0) {
                    setIsChecked([true, true, true, true]);
                }
            })();
        }
    }, [applicationNumber]);

    const getTransactionUpdate = async (params: any) => {
        try {
            const paid: any = await paymentCheck('PROCESSING_FEE', params?.['plotId'], params?.['applicationId']);
            if (!paid.isPaid) {
                setIsLoading(true);
                const payload = { RequestInfo: requestInfo() };
                const { data } = await POST2(endpoints.transactionUpdate, payload, { eg_pg_txnid: params.eg_pg_txnid });
                if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                    setTransactionDetails(data?.Transaction?.[0]);
                    if (data?.Transaction?.[0]?.txnId) {
                        try {
                            const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                            if (resp.data.status === 'SUCCESSFUL') {
                                setModalDetails({ show: true, title: 'Success!', para: t('PAYMENT_SUCCESSFUL', 'Payment successful'), img: check, type: 'success', reUpload: 'OK' });

                                setIsPaid(true);
                            } else setIsPaid(false);
                            setIsLoading(false);
                            setPaymentIsLoading(false);
                        } catch (e: any) {
                            setIsPaid(false);
                            setIsLoading(false);
                            setPaymentIsLoading(false);
                        }
                    }
                } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                    setModalDetails({
                        show: true,
                        title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                        para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    // window.location.reload();
                }
                setIsLoading(false);
                setPaymentIsLoading(false);
            }
        } catch (e: any) {
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('TRANSACTION_FAILED', 'Transaction Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleStepClick = async (index: number) => {
        const stepperLength = stepsDetails.length;
        if (index > activeIndex) {
            try {
                await EXTENSION_LEASE_TERM[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({
                    show: true,
                    title: t('ERROR', 'Error!'),
                    para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'),
                    img: cross,
                    type: 'error',
                    reUpload: 'OK'
                });
            }
        } else setActiveIndex(index);
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const onChangeNextIndex = async () => {
        const stepperLength = stepsDetails.length;
        try {
            if (activeIndex < stepperLength) {
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            }
        } catch (e) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const lastSubmit = (isSaveOnly?: boolean, status?: string, comment?: string) => {
        if (activeIndex === 4 && !isPaid) onPay();
        else onSubmit(isSaveOnly);
    };

    const onSubmit = async (isSaveOnly?: boolean) => {
        const isFinalSubmit = activeIndex === stepsDetails?.length;

        const payload: any = {
            RequestInfo: requestInfo(),
            ...(response ? response : {}),
            appliedPlotId: values.plotUuid,
            applicationType: 'EXTENSION',
            enterpriseEntity: {
                ...existingUser
            },
            modificationDetails: {
                ...(response ? response.modificationDetails : {}),
                requestDetails: {
                    type: 'EXTENSION_LEASE_TERM',
                    plotOrderRequests: [{ plotId: values.plotUuid }],
                    duration: values?.extensionDuration || undefined
                }
            },
            isFinalSubmit: isFinalSubmit
        };

        if (!values?.plotUuid) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please select a plot', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 3 && !isChecked.every((e: boolean) => e)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });

            return;
        }

        if (activeIndex === 3 && eSignedDocumentData?.isEsignRequired) {
            setModalDetails({ show: true, title: 'E-Sign Pending', para: 'Please sign the document', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            await EXTENSION_LEASE_TERM[`page${activeIndex}`]?.validate(values, { abortEarly: false, context: values });
            try {
                const apiEndPoint = applicationNo ? endpoints.updateAllotment : endpoints.createAllotment;
                const { data: response } = await POST(apiEndPoint, payload);
                !isFinalSubmit && setResponse(response?.estateApplications[0]);
                !isFinalSubmit && setApplicationNo(response?.estateApplications[0]?.applicationNo);
                if (isFinalSubmit) {
                    setModalDetails({ show: true, title: 'Success!', para: 'Application created successfully', img: check, type: 'success', reUpload: 'OK', isFinalSubmit: isFinalSubmit });
                } else {
                    toast('Data saved successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                }

                !isFinalSubmit && setActiveIndex(activeIndex + 1);
            } catch (error: any) {
                let errMsg = error?.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onPay = async () => {
        setPaymentIsLoading(true);
        let url =
            window.location.origin +
            `/goa-idc/estate/lease-term-extension?activeIndex=${activeIndex}&applicationNumber=${applicationNo}&signedFileStoreId=${eSignedDocumentData.filestoreId}&plotId=${values?.plotUuid}&applicationId=${response?.uuid}`;
        const periodTo = new Date(response?.auditDetails?.createdTime).setFullYear(new Date(response?.auditDetails?.createdTime).getFullYear() + 1);

        const payload = paymentPayload(response, values.plotUuid, totalPaidAmount, url, 'Extension of Lease Term Fees', selectedGateway, response?.auditDetails?.createdTime, periodTo, paymentData);

        if (!totalPaidAmount) {
            setPaymentIsLoading(false);
            setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });

            return;
        }
        if (!selectedGateway) {
            setPaymentIsLoading(false);
            setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

            return;
        }

        try {
            if (response?.applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data: paymentData } = await POST(endpoints.paymentGateway, payload);
                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(
                        paymentData.txnAmount,
                        getUser,
                        'description',
                        paymentData?.additionalDetails.ORDER_ID,
                        paymentData.callbackUrl,
                        KEY_ID[selectedGateway]
                    );
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    console.log(paymentData.additionalDetails);
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${paymentData.additionalDetails?.walletClientCode}&walletRequestMessage=${paymentData.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (paymentData && paymentData.redirectUrl) {
                    window.open(paymentData.redirectUrl, '_self');
                }
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setPaymentIsLoading(false);
            setModalDetails({ show: true, title: t('PAYMENT_FAILED', 'Payment Initiation Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const handleModalClose = (type: string, isFinalSubmit: boolean, isUnauthorized?: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success' && isFinalSubmit && !isUnauthorized) {
            navigate('/goa-idc/enterprisedashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            } else if (userType === 'EMPLOYEE') {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const signDocument = async () => {
        let url = window.location.origin + `/goa-idc/estate/lease-term-extension?activeIndex=${activeIndex}&applicationNumber=${applicationNo}`;
        url = btoa(url);

        if (values.esignOption === 'EKYC') {
            const userRequestInfo = requestInfo();
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${eSignedDocumentData.filestoreId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${eSignedDocumentData.filestoreId}&callbackUrl=${url}`);
        }
    };

    const handleClickSignDocument = () => {
        if (activeIndex === 3 && !isChecked.every((e: boolean) => e)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setEsignModal(true);
    };

    const onChangeAction = (e: any, field: any) => {
        if (field === 'extensionDuration') {
            const numericValue = e.target.value.replace(/[^0-9]/g, '');
            e.target.value = numericValue;
            setFieldValue(field, numericValue);
        }
    };

    const status: any = progressBarDetails?.progressStatus?.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: applicationNumber,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };

    const ExtensionDetails = () => {
        return (
            <div>
                <>
                    <Card className='card_border'>
                        <Card.Body>
                            {EXTENSION_DETAILS_FORM?.map(val => (
                                <GenericFormCard {...val} onChangeAction={onChangeAction} />
                            ))}
                        </Card.Body>
                    </Card>
                </>
            </div>
        );
    };

    const renderSelfDeclaration = () => {
        return (
            <>
                <Declaration
                    isChecked={isChecked}
                    declarationList={extensionDeclarationList}
                    handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                    setDocuments={setDocuments}
                    signDocument={handleClickSignDocument}
                    eSignedDocumentData={eSignedDocumentData}
                    applicantName={'Lessee'}
                    isDisabled={!hasValidAction}
                    fileStoreId={fileStoreId}
                ></Declaration>
            </>
        );
    };

    const onCancel = () => {
        navigate('/goa-idc/enterprisedashboard');
    };

    console.log('formik', formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.isFinalSubmit, modalDetails?.isUnauthorized)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isFinalSubmit, modalDetails?.isUnauthorized)}
                    />
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={t('LEASE_EXTENSION_TITLE', 'Extension of Lease Term')}
                            bottomSubText={applicationNo ? `Type : ${t(response?.applicationType)}, No : ${applicationNo}` : ''}
                            additionalClass={'sub-text-style'}
                        />

                        <div className='col-3 stickyCard-section'>
                            <div className='stickyCard' style={{ top: '110px' }}>
                                <GenericHeader
                                    text={t('LEASE_EXTENSION_TITLE', 'Extension of Lease Term')}
                                    sideHeader={true}
                                    additionalClass='sub-text-style'
                                    bottomSubText={applicationNo ? `Type : ${t(response?.applicationType)}, No : ${applicationNo}` : ``}
                                />
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column  row-gap-3'>
                                            <SideCard steps={stepsDetails} visited={visited} activeIndex={activeIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        <div className='col-9 form-section'>
                            {!!applicationNumber && applicationData.status !== 'Draft' && (
                                <Card className='card_border my-2'>
                                    <Card.Body>
                                        <PaymentSubmissionStatus data={applicationData} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                                    </Card.Body>
                                </Card>
                            )}

                            <FormikProvider value={formik}>
                                {activeIndex === 1 && (
                                    <Card className='card_border'>
                                        <Card.Body>
                                            <div className='ps-2 py-2 d-flex justify-content-between'>
                                                <div className='formTitle m-0'>{t('PLOT_DETAILS', 'Plot Details')}</div>
                                            </div>
                                            <GenericActionTable rowData={rowData} columns={plotDetailsTableColumns} searchBarPlaceholder={'SEARCH_BY_PLOT'} />
                                        </Card.Body>
                                    </Card>
                                )}
                                {activeIndex === 2 && ExtensionDetails()}
                                {activeIndex === 3 && renderSelfDeclaration()}
                                {activeIndex === 4 &&
                                    (!paymentIsLoading ? (
                                        <PaymentDetails
                                            paymentData={paymentData}
                                            selectedGateway={selectedGateway}
                                            setSelectedGateway={setSelectedGateway}
                                            isPaid={isPaid}
                                            gatewayData={gatewayData}
                                            transactionDetails={transactionDetails}
                                            title={'Application Fee'}
                                        ></PaymentDetails>
                                    ) : (
                                        <Loading />
                                    ))}
                                <GenericPopupModal
                                    title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                    primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                    secondaryBtnTitle={t('CLOSE', 'Close')}
                                    isSecondaryBtn={true}
                                    secondaryAction={() => setEsignModal(false)}
                                    primaryAction={signDocument}
                                    isVisible={esignModal}
                                    children={<EsignModal />}
                                />
                            </FormikProvider>
                        </div>
                    </div>

                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                            </div>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {hasValidAction ? (
                                    <>
                                        {activeIndex !== stepsDetails.length && activeIndex !== 1 && <GenericButtons text={t('SAVE', 'Save')} handleClick={() => onSubmit(true)} ClassName='me-3' />}
                                        <GenericButtons
                                            text={
                                                activeIndex === 1
                                                    ? t('NEXT', 'Next')
                                                    : activeIndex !== stepsDetails.length && activeIndex !== 1
                                                    ? t('SAVE_NEXT', `Save & Next`)
                                                    : isPaid
                                                    ? t('SUBMIT', `Submit`)
                                                    : paymentTried
                                                    ? t('RETRY_PAYMENT', 'Retry Payment')
                                                    : t('PAY_NOW', 'Pay Now')
                                            }
                                            variant='primary'
                                            handleClick={() => lastSubmit()}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {activeIndex === stepsDetails.length ? (
                                            <>
                                                <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={onCancel} />
                                            </>
                                        ) : (
                                            <>
                                                <GenericButtons text={t('NEXT', 'Next')} variant='primary' handleClick={onChangeNextIndex} />
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </footer>
                </>
            )}
        </>
    );
};

export default LeaseExtension;
