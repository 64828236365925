import { useLocation, useNavigate } from 'react-router-dom';
import HeaderComponent from '../../../../components/header/Header.component';
import { useEffect, useState } from 'react';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { Card, Form } from 'react-bootstrap';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { ENTERPRISE_UPDATE_FIELDS, UPDATE_STEP_DETAILS } from './EnterpriseManagement.const';
import { check, cross } from '../../../../utils/Logo';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { FormikProvider, useFormik } from 'formik';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { ENTERPRISE_UPDATE_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getFirstErrorKey } from '../../../../utils/utils';
import { EMPLOYEE_AUTHORIZED } from '../../../../utils/constants';

export default function EnterpriseDetailsUpdate() {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visitedIndex, setVisitedIndex] = useState<number>(1);
    const [modalDetails, setModalDetails] = useState<any>({});

    const location: any = useLocation();
    const enterpriseData = location?.state?.enterpriseData;
    let navigate = useNavigate();
    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: ENTERPRISE_UPDATE_VALIDATION
    });

    const { values, setValues, errors, setFieldTouched } = formik;

    useEffect(() => {
        setValues((prevVal: any) => ({
            ...prevVal,
            enterpriseName: enterpriseData?.entityName,
            emailId: enterpriseData?.email,
            phoneNo: enterpriseData?.phoneNumber,
            panNumber: enterpriseData?.panNumber,
            entityUuid: enterpriseData?.uuid,
            userUuid: enterpriseData?.user?.uuid
        }));

        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }
    }, []);

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const handleModalClose = (type: string, isUnauthorized: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success') {
            navigate('/goa-idc/enterprise-management/search');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            } else if (userType === 'EMPLOYEE') {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    const onSave = async () => {
        let payload = {
            RequestInfo: requestInfo(),
            userUuid: values?.userUuid,
            email: values?.emailId,
            phoneNumber: values?.phoneNo,
            entityName: values?.enterpriseName
        };

        if (enterpriseData?.email === values?.emailId && enterpriseData?.phoneNumber === values?.phoneNo && enterpriseData?.entityName === values?.enterpriseName) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('NO_CHANGES_MADE', 'No changes have been made'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            await ENTERPRISE_UPDATE_VALIDATION.validate(values, { abortEarly: false });
            try {
                const { data } = await POST(endpoints.enterpriseUserUpdate, payload);
                if (data?.status === 'SUCCESS') {
                    setModalDetails({
                        show: true,
                        title: t('SUCCESS', 'Success!'),
                        para: t('ENTERPRISE_DETAILS_UPDATE_SUCCESSFULLY', 'Enterprise details updated successfully'),
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                    });
                }
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('VALID_DETAILS', 'Please fill in valid details'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
        }
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={t('ENTERPRISE_DETAILS_UPDATE', `Enterprise Details Update`)} additionalClass='sub-text-style' />
                        <div className='col-3 stickyCard-section'>
                            <div className='stickyCard'>
                                <GenericHeader text={t('ENTERPRISE_DETAILS_UPDATE', `Enterprise Details Update`)} sideHeader={true} additionalClass='sub-text-style' />

                                <Card className='card_border'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column row-gap-3  '>
                                            <SideCard steps={UPDATE_STEP_DETAILS} activeIndex={activeIndex} visitedIndex={visitedIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className='col-9 form-section'>
                            <FormikProvider value={formik}>
                                <Form>
                                    {activeIndex === 1 && (
                                        <Card className='card_border'>
                                            <Card.Body>
                                                {ENTERPRISE_UPDATE_FIELDS?.map((val: any) => (
                                                    <GenericFormCard {...val} />
                                                ))}
                                            </Card.Body>
                                        </Card>
                                    )}
                                </Form>
                            </FormikProvider>
                        </div>

                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                    {<GenericButtons text={t('SAVE', 'Save')} variant='primary' ClassName='fw-500' handleClick={onSave} />}
                                </div>
                            </div>
                        </footer>
                    </div>

                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
                    />
                </>
            )}
        </>
    );
}
