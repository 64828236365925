import { upperCase } from 'lodash';
import { CONSTANT_DATA_FIELD, CONSTANT_REGEX_SAMPLE } from '../../../../utils/constants';

export const DSC_REGISTRATION_FIELDS = [
    {
        formData: [
            {
                title: 'Token',
                titleCode: 'TOKEN',
                field: 'token',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Certificate',
                titleCode: 'CERTIFICATE',
                field: 'certificate',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Password',
                titleCode: 'PASSWORD',
                field: 'keyStorePassPhrase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                subType: CONSTANT_DATA_FIELD.PASSWORD,
                disableCondition: (values: any, index: any) => values.token?.code !== 'Microsoft Windows Store'
            }
        ]
    }
];

export const KYC_REGISTRATION_FIELDS = [
    {
        title: 'e-Mudra KYC Registration',
        titleCode: 'EMUDRA_KYC_REGISTRATION',
        formData: [
            {
                title: 'Username',
                titleCode: 'USERNAME',
                field: 'username',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'PAN No',
                titleCode: 'PAN_NO',
                field: 'PAN',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                upperCase: true

                // validation: {
                //     required: true,
                //     pattern: CONSTANT_REGEX_SAMPLE.PAN, // Only uppercase letters, numbers, and dashes
                //     minLength: 5,
                //     maxLength: 20,
                //     customMessage: 'Enter Valid Pan',
                //     when: (values: any,index:any) => values.isSpecialWorkOrder === true // Condition
                // }
            },
            {
                title: 'Name on Pan',
                titleCode: 'NAME_ON_PAN',
                field: 'nameOnPAN',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Mobile Number',
                titleCode: 'MOBILE_NUMBER',
                field: 'mobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Email ID',
                titleCode: 'EMAIL_ID',
                field: 'emailID',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'DATE_OF_BIRTH',
                title: 'Date of Birth',
                field: 'dateOfBirth',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                titleCode: 'GENDER',
                title: 'Gender',
                field: 'gender',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line',
                titleCode: 'ADDRESS_LINE',
                field: 'address',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            // {
            //     title: 'Address Line 2',
            //     titleCode: 'ADDRESS_LINE_2',
            //     field: 'address2',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD
            // },
            {
                title: 'Pin Code',
                titleCode: 'PINCODE',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'District',
                titleCode: 'DISTRICT',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'State/UT',
                titleCode: 'STATE',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Country',
                titleCode: 'COUNTRY',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            }
        ]
    },
    {
        title: 'Documents',
        titleCode: 'DOCUMENTS',
        formData: [
            {
                title: 'Photo',
                titleCode: '',
                field: 'photo',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                base64Format: true,
                fileTypes: ['jpg', 'jpeg', 'pdf']
            },
            {
                title: 'ID Proof',
                titleCode: '',
                field: 'idProof',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                base64Format: true,
                fileTypes: ['jpg', 'jpeg', 'pdf']
            },
            {
                title: 'Address Proof',
                titleCode: '',
                field: 'addressProof',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                base64Format: true,
                fileTypes: ['jpg', 'jpeg', 'pdf']
            }
        ]
    }
];
