import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { Card, CardBody } from 'react-bootstrap';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';
import { endpoints, estateEndpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/portalComponents/Loading.component';
import { dprDropdownDataOptions } from '../DPR creation screen/DPR.controller';
import { RA_BILL_SEARCH_FIELDS, RA_BILL_SEARCH_TABLE } from './RaBill.const';
import { USER_TYPE } from '../../../../utils/constants';

const RaBillApplicationSearch = () => {
    const { t } = useTranslation();
    const [modalDetails, setModalDetails] = useState<any>({});
    const [searchData, setSearchData] = useState<[]>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [estateData, setEstateData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const users = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = users?.type;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: null
    });
    const { values, setValues } = formik;

    const toGetEstateDropdown = async () => {
        const { data } = await POST(estateEndpoints.estateSearch, { RequestInfo: requestInfo() });
        const estateValues = data?.estateMasterDetails?.map((val: any) => {
            return { estateName: val?.estateName, estateId: val?.uuid };
        });
        setEstateData(estateValues);
        const estateDropdown = estateValues?.map((value: any) => {
            return { name: value?.estateName, code: value?.estateName };
        });
        setDropdownData((prev: any) => ({ ...prev, industrialEstate: estateDropdown }));
    };

    useEffect(() => {
        if (userType !== USER_TYPE.EMP) {
            window.location.href = '/goa-idc/access-restricted';
        }

        try {
            dprDropdownDataOptions()
                .then(res => {
                    setDropdownData(res);
                })
                .catch(err => console.log(err));
            toGetEstateDropdown();
        } catch (e: any) {}
    }, []);

    const onReset = () => {
        const initValue = { industrialEstate: '', raBillApplicationNumber: '' };
        setValues((prev: any) => ({ ...prev, ...initValue }));
        setSearchData([]);
    };

    const handleSearchDpr = async () => {
        const getEstateId = estateData?.find((val: any) => val?.estateName === values?.industrialEstate?.name);
        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.searchRaBill + `?applicationNos=${values.raBillApplicationNumber || ''}&estateIds=${getEstateId ? getEstateId?.estateId : ''}`, {
                RequestInfo: requestInfo()
            });
            const tableData =
                data?.raBillDetails?.length &&
                data?.raBillDetails?.map((val: any, index: number) => {
                    const tempData = val?.contractDetails?.workOrderDetails?.loaDetails?.dprHeaderDetails;
                    const projectType = getDropdownSelectedObject(tempData?.projectType, dropdownData['projectType'] || []);
                    return {
                        serialNo: index + 1,
                        raBillNo: val?.applicationNo,
                        projectName: tempData?.projectName,
                        industrialName: tempData?.estateMasterDetails?.estateName,
                        projectType: projectType?.name,
                        estimateAmount: tempData?.estimatedTotalCost?.toFixed(2),
                        id: val?.uuid,
                        vendorName: val?.contractDetails?.workOrderDetails?.loaDetails?.bidderDetails?.name
                    };
                });
            setSearchData(tableData);
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message || t('SOMETHING_WENT_WRONG', 'Something went wrong');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onRowClick = (row: any) => {
        navigate(`/goa-idc/running-account-bill?applicationNumber=${row?.raBillNo}&isViewOnly=true`);
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader text={t('SEARCH_MB_BILL', `Search MB & Bill`)} additionalClass='sub-text-style' />
                        <FormikProvider value={formik}>
                            <div>
                                <Card className='card_border my-4 pb-4 pe-4'>
                                    <CardBody>
                                        {RA_BILL_SEARCH_FIELDS.map((val: any) => {
                                            return (
                                                <>
                                                    <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                                </>
                                            );
                                        })}
                                    </CardBody>
                                    <div className='d-flex justify-content-end'>
                                        <GenericButtons text={'Reset'} handleClick={onReset} />
                                        <GenericButtons text={'Search'} variant='primary' handleClick={handleSearchDpr} />
                                    </div>
                                </Card>
                                <Card className='card_border row mx-1 p-4 pb-0'>
                                    <div className='font-20 project-primary-color lh-base mb-3'>Search Result</div>
                                    <GenericActionTable
                                        columns={RA_BILL_SEARCH_TABLE}
                                        rowData={searchData}
                                        additionalMethod={onRowClick}
                                        searchBarPlaceholder={t('DPR_SEARCH_PARAM', 'Search by DPR No., Project Name...')}
                                    />
                                </Card>
                            </div>
                        </FormikProvider>
                    </div>
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                </>
            )}
        </>
    );
};

export default RaBillApplicationSearch;
