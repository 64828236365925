import { changeDateFormat } from '../../../../utils/utils';
import { convertToINR } from '../EstateManagement.controller';
import { convertDateFormatddMMyyyytoyyyyMMdd } from '../LeaseDeed/LeaseDeed.controller';

export const INSTALLMENT_COL = [
    {
        colName: 'multiCheck',
        fieldName: 'checkbox',
        clsName: 'my-2 mx-2',
        id: 1
    },
    {
        colName: 'Installment No',
        fieldName: 'SN',
        clsName: 'col-sm-1 my-2 mx-2',
        isClickable: false,
        id: 2
    },
    {
        colName: 'Due Date',
        fieldName: 'dueDate',
        clsName: 'width-12 my-2 ',
        id: 3
    },
    {
        colName: 'Premium Due',
        fieldName: 'principalAmount',
        clsName: 'width-12 my-2 column-text-end ',
        id: 4
    },
    {
        colName: 'Interest Due (at 11% per annum)',
        fieldName: 'interestAmount',
        clsName: 'width-12 my-2 column-text-end ',
        id: 5
    },
    {
        colName: 'Default Interest (8% annually) in INR',
        fieldName: 'penaltyAmount',
        clsName: 'width-12 my-2 column-text-end ',
        id: 6
    },
    {
        colName: 'CGST on Default Interest (9%)',
        fieldName: 'cgstPenalty',
        clsName: 'width-12 my-2 column-text-end',
        id: 7
    },
    {
        colName: 'SGST on Default Interest (9%)',
        fieldName: 'sgstPenalty',
        clsName: 'width-12 my-2 column-text-end',
        id: 7
    },
    {
        colName: 'Total Land Premium',
        fieldName: 'totalDueAmount',
        clsName: 'col-sm-2 my-2 column-text-end',
        id: 1
    }
];

export const PAYMENT_COL = [
    {
        colName: 'multiCheck',
        fieldName: 'checkbox',
        clsName: 'my-2',
        id: 0
    },
    {
        colName: 'SN',
        fieldName: 'SN',
        clsName: 'width-2 my-2 ',
        id: 1
    },
    {
        colName: 'Description',
        fieldName: 'paymentTypeDisplayName',
        clsName: 'width-10 column-text-start-description my-2 ',
        id: 3
    },
    {
        colName: 'Amount (in INR)',
        fieldName: 'principalAmount',
        clsName: 'col-sm-1 my-2 column-text-end ',
        id: 4
    },
    {
        colName: 'CGST',
        fieldName: 'cgstPrincipal',
        clsName: 'width-6  my-2 column-text-end',
        id: 7
    },
    {
        colName: 'SGST',
        fieldName: 'sgstPrincipal',
        clsName: 'width-6  my-2 column-text-end',
        id: 7
    },
    {
        colName: 'Interest Amount (11%)',
        fieldName: 'interestAmount',
        clsName: 'col-sm-1 my-2 column-text-end ',
        id: 5
    },
    {
        colName: 'Arrear Amount',
        fieldName: 'arrearPrincipalAmount',
        clsName: 'col-sm-1 my-2 column-text-end ',
        id: 6
    },
    {
        colName: 'CGST on Arrear',
        fieldName: 'arrearcgstPrincipal',
        clsName: 'col-sm-1 my-2 column-text-end',
        id: 7
    },
    {
        colName: 'SGST on Arrear',
        fieldName: 'arrearsgstPrincipal',
        clsName: 'col-sm-1 my-2 column-text-end',
        id: 8
    },
    {
        colName: 'Default Interest (8%)',
        fieldName: 'penaltyAmount',
        clsName: 'col-sm-1 my-2 column-text-end ',
        id: 6
    },
    {
        colName: 'CGST on Default Interest',
        fieldName: 'cgstPenalty',
        clsName: 'col-sm-1 my-2 column-text-end',
        id: 7
    },
    {
        colName: 'SGST on Default Interest',
        fieldName: 'sgstPenalty',
        clsName: 'col-sm-1 my-2 column-text-end',
        id: 8
    },
    // {
    //     colName: 'Arrear',
    //     fieldName: 'arrear',
    //     clsName: 'width-12 my-2 column-text-end text-decoration-underline',
    //     id: 9,
    //     isClickable: true,
    //     secondaryText: '(View breakup)',
    //     toolTipDetails: {
    //         position: 'bottom',
    //         content: '<div>Controlling Ownership Interest (%)</div>',
    //         variant: { color: 'white', height: '20px' }
    //     }
    // },
    {
        colName: 'Total Amount',
        fieldName: 'totalDueAmount',
        clsName: 'col-sm-1 my-2 column-text-end',
        id: 10
    }
];

export const PAID_PAYMENT_COL = [...PAYMENT_COL.slice(2, 3), ...INSTALLMENT_COL.slice(1)];

export const WATER_CHARGES_PAYMENT_COL = [
    {
        colName: 'multiCheck',
        fieldName: 'checkbox',
        clsName: 'my-2',
        id: 0
    },
    {
        colName: 'SN',
        fieldName: 'SN',
        clsName: 'width-4 my-2 ',
        id: 1
    },
    {
        colName: 'Description',
        fieldName: 'paymentTypeDisplayName',
        clsName: 'width-10 column-text-start-description my-2 ',
        id: 3
    },
    {
        colName: 'Water Connection No.',
        fieldName: 'waterConnectionBillDetails.connectionId',
        clsName: 'col-sm-1 my-2 ',
        id: 1
    },
    {
        colName: 'Connection Type',
        fieldName: 'waterConnectionBillDetails.waterType',
        clsName: 'col-sm-1 column-text-start-description my-2 ',
        id: 3
    },
    {
        colName: 'Amount (in INR)',
        fieldName: 'principalAmount',
        clsName: 'col-sm-1 my-2 column-text-end ',
        id: 4
    },
    {
        colName: 'Default Interest (8%)',
        fieldName: 'penaltyAmount',
        // clsName: 'width-6 my-2 column-text-end ',
        clsName: 'width-10 my-2 column-text-end ',
        id: 6
    },
    {
        colName: 'CGST on Default Interest',
        fieldName: 'cgstPenalty',
        // clsName: 'width-6 my-2 column-text-end',
        clsName: 'width-10 my-2 column-text-end',
        id: 7
    },
    {
        colName: 'SGST on Default Interest',
        fieldName: 'sgstPenalty',
        // clsName: 'width-6 my-2 column-text-end',
        clsName: 'width-10 my-2 column-text-end',
        id: 8
    },
    {
        colName: 'Arrear Amount',
        fieldName: 'arrearPrincipalAmount',
        // clsName: 'width-6 my-2 column-text-end ',
        clsName: 'width-10 my-2 column-text-end ',
        id: 6
    },
    // {
    //     colName: 'CGST on Arrear',
    //     fieldName: 'arrearcgstPrincipal',
    //     clsName: 'width-6 my-2 column-text-end',
    //     id: 7
    // },
    // {
    //     colName: 'SGST on Arrear',
    //     fieldName: 'arrearsgstPrincipal',
    //     clsName: 'col-sm-1 my-2 column-text-end',
    //     id: 8
    // },
    {
        colName: 'Consumption',
        fieldName: 'waterConnectionBillDetails.consumption',
        clsName: 'col-sm-1 my-2 column-text-end ',
        id: 4
    },
    {
        colName: 'Bill Amount (in INR)',
        fieldName: 'totalDueAmount',
        clsName: 'width-12 my-2 column-text-end ',
        id: 4
    }
];
export const getFormatedPaymentData = (item: any, id: number) => {
    return {
        ...item?.scheduledPaymentDetails,
        ...item?.arrearDetails,
        waterConnectionBillDetails: {
            ...item?.scheduledPaymentDetails?.waterConnectionBillDetails,
            consumption: item?.scheduledPaymentDetails?.waterConnectionBillDetails?.consumption === 0 ? '--' : item?.scheduledPaymentDetails?.waterConnectionBillDetails?.consumption
        },
        SN: id + 1,
        id: item?.scheduledPaymentDetails?.uuid,
        paymentTypeDisplayName: item?.scheduledPaymentDetails?.paymentTypeDisplayName,
        principalAmount: convertToINR(item?.scheduledPaymentDetails?.principalAmount),
        cgstPrincipal: convertToINR(item?.scheduledPaymentDetails?.cgstPrincipal),
        sgstPrincipal: convertToINR(item?.scheduledPaymentDetails?.sgstPrincipal),
        arrearPrincipalAmount: convertToINR(item?.arrearDetails?.arrearPrincipalAmount),
        sgstPenalty: convertToINR((item?.scheduledPaymentDetails?.sgstPenalty + (item?.arrearDetails?.arrearsgstPenalty || 0))?.toFixed(2)),
        cgstPenalty: convertToINR((item?.scheduledPaymentDetails?.cgstPenalty + (item?.arrearDetails?.arrearcgstPenalty || 0))?.toFixed(2)),
        penaltyAmount: convertToINR((item?.scheduledPaymentDetails?.penaltyAmount + (item?.arrearDetails?.arrearPenaltyAmount || 0))?.toFixed(2)),
        interestAmount: convertToINR(item?.scheduledPaymentDetails?.interestAmount),
        totalDueAmount: convertToINR((item?.scheduledPaymentDetails?.totalDueAmount + (item?.arrearDetails?.totalArrearDueAmount || 0))?.toFixed(2)),
        arrearsgstPrincipal: convertToINR(item?.arrearDetails?.arrearsgstPrincipal),
        arrearcgstPrincipal: convertToINR(item?.arrearDetails?.arrearcgstPrincipal)
    };
};

export const getDateRangeForPayment = (dateToCheck: string) => {
    const dateToCheckFormatted = convertDateFormatddMMyyyytoyyyyMMdd(dateToCheck);
    const currentDate = new Date(); // Current date
    const futureDate = new Date(); // 60 days from now
    futureDate.setDate(currentDate.getDate() + 60); // Adding 60 days to the current date

    // Convert input date to a Date object
    const date = new Date(dateToCheckFormatted);

    if (date >= currentDate && date <= futureDate) {
        return 'CURR_DATE';
    } else if (date < currentDate) {
        return 'PAST_ARREAR_DATE';
    } else {
        return 'FUTURE_DATE';
    }
};
