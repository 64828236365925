import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import basicDetails from '../../../../assets/logos/basicDetails.svg';
import search from '../../../../assets/logos/searchUser.svg';

export const ENTERPRISE_MANAGEMENT = [
    {
        titleCode: 'UPDATE_EMAIL_MOBILE',
        btnTitle: 'Update Basic Details',
        btnURL: '/goa-idc/enterprise-management/search',
        logo: basicDetails
    },
    {
        titleCode: 'MIGRATION_FORM_SEARCH',
        btnTitle: 'Migration Form Search',
        btnURL: '/goa-idc/migration/application-search',
        logo: search
    },
    {
        titleCode: 'MIGRATED_DATA_PAYMENT_UPDATE',
        btnTitle: 'Migrated Data Payment Update',
        btnURL: '/goa-idc/migration/payment-search',
        logo: search
    }
];

export const UPDATE_STEP_DETAILS = [
    {
        titleCode: 'ENTERPISE_DETAILS',
        name: 'Enterprise Details',
        index: 1,
        isActive: true
    }
];

export const ENTERPRISE_SEARCH = [
    {
        formData: [
            {
                title: 'Name',
                titleCode: 'NAME',
                field: 'entityName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'Email',
                titleCode: 'EMAIL',
                field: 'email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'Phone No.',
                titleCode: 'PHONE_NO',
                field: 'phoneNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'PAN Number',
                titleCode: 'PAN_NO',
                field: 'entitypanNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4'
            }
        ]
    }
];

export const ENTERPRISE_SEARCH_TABLE = [
    {
        colName: 'S.N.',
        titleCode: 'SERIAL_NO',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Enterprise Name',
        titleCode: 'ENTERPRISE_NAME',
        fieldName: 'enterpriseName',
        clsName: 'col-sm-3',
        id: 2
    },
    {
        colName: 'Email ID',
        titleCode: 'EMAIL_ID',
        fieldName: 'emailId',
        clsName: 'col-sm-3',
        id: 3
    },
    {
        colName: 'Phone Number',
        titleCode: 'PHONE_NUMBER',
        fieldName: 'phoneNumebr',
        clsName: 'col-sm-2',
        id: 4
    },
    {
        colName: 'PAN Number',
        titleCode: 'PAN_NUMBER',
        fieldName: 'panNumber',
        clsName: 'col-sm-2',
        id: 5
    },
    {
        colName: 'Action',
        titleCode: 'ACTION',
        fieldName: 'action',
        clsName: 'col-sm-1',
        actions: ['edit'],
        id: 6
    }
];

export const ENTERPRISE_UPDATE_FIELDS = [
    {
        formData: [
            {
                titleCode: 'ENTERPRISE_NAME',
                title: 'Enterprise Name',
                field: 'enterpriseName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                // class: 'col-sm-12',
                isRequired: true
            },
            {
                titleCode: 'EMAIL_ID',
                title: 'Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
                // class: 'col-sm-12'
            },
            {
                titleCode: 'PHONE_NO',
                title: 'Phone Number',
                field: 'phoneNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
                // class: 'col-sm-12'
            }
            // {
            //     titleCode: 'PAN_NO',
            //     title: 'PAN Number',
            //     field: 'panNumber',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isDisabled: true
            // }
        ]
    }
];
