import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../components/header/Header.component';
import { t } from 'i18next';
import { EMPLOYEE_AUTHORIZED } from '../../utils/constants';
import { cross } from '../../utils/Logo';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage = () => {
    let navigate = useNavigate();
    const [modalDetails, SetModalDetails] = useState<any>({});

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = user?.type;

    useEffect(() => {
        SetModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED), img: cross, type: 'error', reUpload: 'OK', isUnauthorized: true });
    }, []);

    const handleModalClose = (isUnauthorized?: boolean) => {
        SetModalDetails({});
        if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            } else if (userType === 'EMPLOYEE') {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    return (
        <div>
            <HeaderComponent />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails?.isUnauthorized)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails?.isUnauthorized)}
            />
        </div>
    );
};

export default UnauthorizedPage;
