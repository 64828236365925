import { ErrorMessage, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import { cross, file_upload } from '../../utils/Logo';
import './GenericStyles.scss';
import axios from 'axios';
import { devUrl, endpoints } from '../../services/modulesServices/userManagement.api';
import { DocumentInfo, EnterpriseFileEnum, FileEnum } from './GenericFormCard.constants';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { extractFileUrl, getFileIcon } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { TENANT_ID, USER_TYPE } from '../../utils/constants';
import ToolTipDetails from '../ToolTipFile';
import fileDown from '../../assets/logos/download.svg';
import trashLogo from '../../assets/logos/trash-white.svg';
import GenericPopupModal from './GenericModalPopup.component';
import GenericPDFViewer from '../fileviewer/GenericPDFViewer';
import GenericImageViewer from '../fileviewer/GenericImageViewer';
import StatusAlertPopup from './StatusAlertPopup';
import PopUps from '../PopUps';
import GenericDocViewer from '../fileviewer/GenericDocViewer';

interface textFieldProps {
    titleCode?: any;
    field: string;
    title: string;
    setDocuments?: any;
    documents?: any;
    module?: string;
    isRequired?: boolean;
    fileType?: string;
    additionalData?: any;
    isDisabled?: boolean;
    condition1?: (val: any, index: any) => boolean;
    index?: any;
    entityFileData?: any;
    hideTitle?: boolean;
    hideFileSize?: boolean;
    toolTipDetails?: any;
    fileTypes?: any;
    disableCondition?: (val: any, index: number) => boolean;
    base64Format?: boolean;
}

export default function GenericFileUploadField(props: textFieldProps) {
    const {
        setDocuments,
        documents,
        module,
        title,
        toolTipDetails,
        field,
        fileType,
        additionalData,
        isDisabled,
        condition1,
        index,
        entityFileData,
        hideFileSize,
        fileTypes,
        disableCondition,
        base64Format
    } = props;
    const { t } = useTranslation();

    const { values, setFieldValue, handleChange, setValues } = useFormikContext<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [isRequired, setIsRequired] = useState(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [extension, setExtension] = useState<any>('');

    const userType = JSON.parse(localStorage.getItem('type') || '{}');
    let user = JSON.parse(localStorage.getItem('user') || '{}');

    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>({});
    const [isPDF, setIsPDF] = useState(true);
    const [isDisable, setIsDisable] = useState(false);

    useEffect(() => {
        if (condition1 && condition1(values, index)) {
            setIsRequired(true);
        } else if (condition1 && !condition1(values, index)) {
            setIsRequired(false);
        }

        if (props?.disableCondition && props?.disableCondition(values, props?.index)) {
            setIsDisable(false);
        } else if (props?.disableCondition && !props?.disableCondition(values, props?.index)) {
            setIsDisable(true);
        }
    }, [field, values]);

    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let customFile, customFileName;
        const file: any = e.target.files && e.target.files[0];
        const fileExtensionType = file?.type;
        const extension: string = file?.name.split('.').pop()?.toLowerCase() || '';
        setExtension(extension);

        customFileName =
            userType === USER_TYPE.EMP && FileEnum[title] === 'ADDRESS_PROOF' && (values?.empId || values?.employeeID) ? `${values?.empId || values?.employeeID}_AddressProof.${extension}` : file.name;
        customFile = userType === USER_TYPE.EMP && FileEnum[title] === 'ADDRESS_PROOF' && (values?.empId || values?.employeeID) ? new File([file], customFileName, { type: fileExtensionType }) : file;

        if (entityFileData) {
            customFileName = entityFileData.documentNamePrefix
                ? `${_.get(values, entityFileData.documentNamePrefix) || entityFileData.documentNamePrefix}${entityFileData.documentType ? '_' : ''}${entityFileData.documentType}.${extension}`
                : file.name;
            customFile = new File([file], customFileName, { type: fileExtensionType });
        }

        try {
            if (!file) {
                console.error('No file selected.');
                return;
            }

            if (!fileTypes && fileExtensionType !== 'application/pdf') {
                let errMsg = t('INVALID_DOCUMENT_TYPEE', `Please upload ${!fileTypes ? 'PDF' : fileTypes.join(',')} files only`);
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                e.target.value = ''; // Clear the file input
                return;
            } else if (fileTypes && !fileTypes?.includes(extension)) {
                let errMsg = t('INVALID_DOCUMENT_TYPEE', `Please upload ${!fileTypes ? 'PDF' : fileTypes.join(',')} files only`);
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                e.target.value = ''; // Clear the file input
                return;
            }

            if (extension !== 'pdf') {
                setIsPDF(false);
            } else setIsPDF(true);

            setIsLoading(true);

            const response: any = await axios.post(
                devUrl + '/filestore/v1/files',
                {
                    file: customFile,
                    tenantId: 'ga',
                    tag: customFileName,
                    module: 'hrms-upload',
                    requestInfo: JSON.stringify({
                        userInfo: {
                            uuid: user?.uuid ?? 'SYSTEM'
                        }
                    })
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            handleChange(e);

            const fileResponseUrl = await axios.get(devUrl + endpoints.fileStoreApi, {
                params: {
                    tenantId: 'ga',
                    fileStoreIds: response.data.files[0].fileStoreId
                }
            });
            const fileDate = new Date(fileResponseUrl?.data?.fileStoreIds[0].uploadedDate);

            const day = fileDate.getDate(),
                month = fileDate.getMonth() + 1,
                year = fileDate.getFullYear();

            const formattedDate = `${day}/${month}/${year}`;

            if (entityFileData) {
                setFieldValue(field, {
                    documentName: fileResponseUrl?.data?.fileStoreIds[0].tag,
                    documentType: entityFileData.documentType,
                    referenceType: entityFileData.referenceType,
                    docReferenceId: fileResponseUrl?.data?.fileStoreIds[0].id,
                    documentUrl: extractFileUrl(fileResponseUrl.data.fileStoreIds[0].url),
                    documentSize: fileResponseUrl?.data?.fileStoreIds[0].fileSize,
                    uploadedDate: formattedDate
                });
            } else {
                setFieldValue(field, {
                    documentName: fileResponseUrl?.data?.fileStoreIds[0].tag,
                    documentSize: fileResponseUrl?.data?.fileStoreIds[0].fileSize,
                    documentType: EnterpriseFileEnum[title] ? EnterpriseFileEnum[title] : '',
                    referenceId: fileResponseUrl?.data?.fileStoreIds[0].id,
                    docReferenceId: fileResponseUrl?.data?.fileStoreIds[0].id,
                    documentUrl: extractFileUrl(fileResponseUrl.data.fileStoreIds[0].url),
                    uploadedDate: formattedDate
                });
            }

            module === 'usermanagement' && setFieldValue(`${field}name`, file?.name);

            if (module === 'usermanagement' && Array.isArray(documents)) {
                if (userType === 'EMPLOYEE' || userType === 'EXTERNAL_EMPLOYEE') {
                    let docs = documents.filter((doc: any) => doc.referenceType !== FileEnum[title]);
                    setDocuments([
                        ...docs,
                        {
                            documentName: fileResponseUrl?.data?.fileStoreIds[0].tag,
                            documentType: values?.[`${fileType}`]?.name || title || '',
                            documentId: fileResponseUrl.data.fileStoreIds[0].id,
                            referenceType: FileEnum[title],
                            referenceId: fileResponseUrl.data.fileStoreIds[0].id, //id
                            documentUrl: fileResponseUrl.data.fileStoreIds[0].url, //id
                            documentSize: fileResponseUrl?.data?.fileStoreIds[0].fileSize,
                            uploadedDate: formattedDate,
                            tenantid: TENANT_ID
                        }
                    ]);
                } else if (additionalData === 'entitySection') {
                    setDocuments([
                        ...documents,
                        {
                            documentName: fileResponseUrl?.data?.fileStoreIds[0].tag,
                            documentType: EnterpriseFileEnum[title] ? EnterpriseFileEnum[title] : '',
                            referenceId: fileResponseUrl?.data?.fileStoreIds[0].id, //id
                            documentSize: fileResponseUrl?.data?.fileStoreIds[0].fileSize,
                            documentUrl: extractFileUrl(fileResponseUrl.data.fileStoreIds[0].url),
                            uploadedDate: formattedDate
                        }
                    ]);
                }
            }
            setIsLoading(false);

            // Get Base64 representation of the image
            if (base64Format) {
                const getBase64 = (file: File): Promise<string> =>
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () => resolve(reader.result as string);
                        reader.onerror = error => reject(error);
                        reader.readAsDataURL(file);
                    });

                const base64Image = await getBase64(customFile);
                setFieldValue(`${field}base64`, base64Image);
                console.log('Base64 Image:', base64Image); // Base64 string of the image
            }
        } catch (error) {
            setIsLoading(false);
            let errMsg = t('FILE_UPLOAD_UNSUCCESSFUL', 'File upload unsuccessful');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onDeleteFile = (file: DocumentInfo) => {
        const val = _.cloneDeep(values);
        delete val[`${field}name`];
        // delete val[`${field}`];
        _.unset(val, field);
        setValues(val);
        const docs = documents?.filter((item: DocumentInfo, index: number) => file.referenceId !== item.referenceId);
        setDocuments && setDocuments(docs);
    };

    const downloadUploadedFile = async (id: string, documentName: string) => {
        const fileExtension: string = documentName.split('.').pop()?.toLowerCase() || '';

        let contentType = '';
        if (fileExtension === 'pdf') {
            contentType = 'application/pdf';
        } else if (fileExtension === 'jpeg' || fileExtension === 'jpg') {
            contentType = 'image/jpeg';
        }

        fetch(devUrl + `/filestore/v1/files/id?tenantId=ga&fileStoreId=${id}`, {
            headers: {
                'Content-Type': contentType
            }
        })
            .then(response => {
                return response.arrayBuffer().then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link: any = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${documentName}`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
            })

            .catch(error => console.error('Error downloading file:', error));
    };
    const viewDocument = (id: string, name: string) => {
        // Check if the name contains ".xlsx"
        if (name.toLowerCase().includes('.xlsx')) {
            console.log('Excel files are not previewable.');
            return; // Exit the function without setting the modal
        }

        // Proceed to set modal state if not an Excel file
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData({ id: id, name: name });
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <div className='d-flex justify-content-center flex-column'>
                {isLoading ? (
                    <ThreeDots visible={true} height='80' width='80' color='#45852e' radius='9' ariaLabel='three-dots-loading' wrapperStyle={{}} wrapperClass='' />
                ) : (
                    <Form.Group style={{ width: '100%' }}>
                        {!props.hideTitle && (
                            <div className='d-flex'>
                                <Form.Label
                                    className='file-form-label pb-2'
                                    style={props.title.length > 60 ? { maxWidth: '95%' } : { width: 'fit-content' }}
                                    title={props.titleCode ? t(props.titleCode) : props.title}
                                >
                                    {props.titleCode ? t(props.titleCode, props.title) : props.title}
                                </Form.Label>
                                {(props.isRequired || isRequired) && <span className='mandatory-indicator'>*</span>}
                                {toolTipDetails && <ToolTipDetails toolTipDetails={toolTipDetails} />}
                            </div>
                        )}
                        <div className='d-flex gap-2'>
                            {/*DOWNLOAD BUTTON IF REQUIRED */}
                            {/* {field === '' && (
                            <div className={`btn btn-none fileUploadBox download_button p-4  `} style={{ borderRadius: 20, width: '40%' }}>
                                <>
                                    <div className='d-flex justify-content-center align-items-center font-12 fw-thin'>
                                        <Image src={download_logo} height={40} onClick={onClickFileItem} />
                                    </div>
                                    <div>
                                        <div className='file-name ms-2'> Download Undertaking</div>
                                    </div>
                                </>
                            </div>
                        )} */}
                            {!_.get(values, field) && (
                                <div className={`btn btn-none  fileUploadBox ${field === '' ? 'py-3' : 'p-3'}`} style={{ backgroundColor: '#ecf2e9', width: `${field === '' ? '60%' : '100%'}` }}>
                                    <>
                                        <div className='d-flex justify-content-center align-items-center font-10 fw-thin'>
                                            <input
                                                className='uploadBox'
                                                name={props.field}
                                                title={`Upload ${props.title}`}
                                                type='file'
                                                id={field}
                                                onChange={handleImageChange}
                                                disabled={!values.isUpdatable || isDisabled || isDisable}
                                            />
                                            <Image src={file_upload} height={40} />
                                        </div>

                                        <div>
                                            {/* <div className='file-name'>{t('DRAG_AND_DROP', 'Drag and drop files here')}</div> */}
                                            <div className='file-name text-truncate' title={`Upload ${props.title}`}>
                                                {t('UPLOAD', `Upload ${props.title}`)}
                                            </div>

                                            <div style={{ fontSize: 10 }} className='text-muted'>
                                                &nbsp; {`only ${props.fileTypes ? fileTypes.join(', ') : '.pdf'} file`} <br />
                                                {!hideFileSize && t('MAX_SIZE', '2 MB max size')}
                                            </div>
                                        </div>
                                    </>
                                </div>
                            )}

                            {_.get(values, field) && (
                                <div className={`btn btn-none fileUploadBox  p-3`} style={{ borderRadius: '8px', justifyContent: 'space-between', backgroundColor: '#45852E', cursor: 'default' }}>
                                    <div className='d-flex align-items-center mx-0'>
                                        {/* <Link to={_.get(values, field).documentUrl} target='_blank'>
                                        <Image src={getFileIcon(_.get(values, field)?.documentName)} height={40} />
                                    </Link> */}
                                        <div
                                            onClick={() =>
                                                viewDocument(
                                                    _.get(values, field).docReferenceId ? _.get(values, field).docReferenceId : _.get(values, field).fileStoreId,
                                                    _.get(values, field).documentName
                                                )
                                            }
                                            className='text-start cursor-pointer'
                                        >
                                            <div className='file-name uploaded-file-name mt-2 text-nowrap text-truncate ' title={_.get(values, field)?.documentName}>
                                                {_.get(values, field)?.documentName}
                                            </div>
                                            {/* <Link to={_.get(values, field).documentUrl} target='_blank'>
                                            <div className='file-name uploaded-file-name mt-2 text-nowrap text-truncate ' title={_.get(values, field)?.documentName}>
                                                {_.get(values, field)?.documentName}
                                            </div>
                                        </Link> */}
                                            <div className='d-flex mt-2 uploaded-file-timing text-white  ' style={{ cursor: 'auto' }}>
                                                <div className='uploaded-file-timing'>{_.get(values, field)?.documentSize || _.get(values, field)?.fileSize}</div>
                                                <li className='uploaded-file-timing ms-2'>{_.get(values, field)?.uploadedDate}</li>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex cursor-pointer'>
                                        <Image
                                            src={fileDown}
                                            height={20}
                                            onClick={() => downloadUploadedFile(_.get(values, field).referenceId || _.get(values, field).docReferenceId, _.get(values, field).documentName)}
                                        />
                                        {values.isUpdatable && !isDisabled && !isDisable ? (
                                            <Image src={trashLogo} height={20} className='ms-4 ' onClick={() => onDeleteFile(_.get(values, field))} />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Form.Group>
                )}
                <ErrorMessage name={props.field}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                {modalData && isFilePreviewModal && (
                    <GenericPopupModal
                        title={isPDF ? 'PDF Viewer' : ['png', 'jpg'].includes(extension) ? 'Image Viewer' : 'Document Viewer'}
                        primaryAction={() => {}}
                        secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                        isVisible={isFilePreviewModal}
                        isPrimaryBtn={false}
                        isSecondaryBtn={false}
                        size={'xl'}
                        class='file-viewer-popup'
                    >
                        <div className='popup-children'>
                            {isPDF && <GenericPDFViewer fileStoreId={modalData.id} documentName={modalData.name} />}
                            {['png', 'jpg'].includes(extension) && <GenericImageViewer imageUrl={_.get(values, field).documentUrl} />}
                            {['docx', 'doc'].includes(extension) && <GenericDocViewer url={_.get(values, field).documentUrl} extension={extension} fileName={_.get(values, field).documentName} />}
                        </div>
                    </GenericPopupModal>
                )}
            </div>
        </>
    );
}
