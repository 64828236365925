import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { Card } from 'react-bootstrap';
import { FormikProvider, useFormik } from 'formik';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { t } from 'i18next';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { ENTERPRISE_SEARCH_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { cross } from '../../../../utils/Logo';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { EMPLOYEE_AUTHORIZED, TENANT_ID } from '../../../../utils/constants';
import { getFirstErrorKey } from '../../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { ENTERPRISE_SEARCH, ENTERPRISE_SEARCH_TABLE } from './EnterpriseManagement.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';

export default function EnterpriseSearch() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [enterpriseData, setEnterpriseData] = useState<any>(null);
    const [tableData, setTableData] = useState<any>([]);

    let navigate = useNavigate();
    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: ENTERPRISE_SEARCH_VALIDATION
    });

    useEffect(() => {
        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }
    }, []);

    const { values, setValues, errors, setFieldTouched } = formik;

    const onReset = () => {
        setValues((prev: any) => ({ ...prev, entityName: '', email: '', entitypanNo: '' }));
    };

    const onSearch = async () => {
        const queryParams = new URLSearchParams();
        if (values?.entityName) queryParams.append('entityName', values.entityName);
        if (values?.email) queryParams.append('email', values.email);
        if (values?.entitypanNo) queryParams.append('panNumber', values.entitypanNo);
        if (values?.phoneNo) queryParams.append('phoneNumber', values?.phoneNo);

        if (!(values?.entityName || values?.email || values?.entitypanNo || values?.phoneNo)) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: 'Please enter Name, Email or PAN Number', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            await ENTERPRISE_SEARCH_VALIDATION.validate(values, { abortEarly: false });
            try {
                const { data } = await POST(endpoints.searchExistingUser + `?tenantId=${TENANT_ID}&${queryParams.toString()}`, { RequestInfo: requestInfo() });
                if (data?.enterpriseEntity[0]?.uuid) {
                    setEnterpriseData(data?.enterpriseEntity?.[0]);
                    const tempTableData = [
                        {
                            serialNo: 1,
                            enterpriseName: data?.enterpriseEntity?.[0]?.entityName,
                            emailId: data?.enterpriseEntity?.[0]?.email,
                            phoneNumebr: data?.enterpriseEntity?.[0]?.phoneNumber,
                            panNumber: data?.enterpriseEntity?.[0]?.panNumber
                        }
                    ];
                    setTableData(tempTableData);
                } else {
                    setModalDetails({ show: true, title: 'Error!', para: 'Entity does not exist', img: cross, type: 'error', reUpload: 'OK' });
                    return;
                }
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e: any) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('VALID_DETAILS', 'Please fill in valid details'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
        }
    };

    const handleModalClose = (type: string, isUnauthorized: boolean) => {
        setModalDetails({});
        if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            } else if (userType === 'EMPLOYEE') {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    const handleEditRow = (row: any) => {
        navigate('/goa-idc/enterprise-management/update', { state: { enterpriseData: enterpriseData } });
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={t('ENTERPRISE_SEARCH', 'Enterprise Search')} />
                        <div className='col-13 form-section'>
                            <Card className='card_border'>
                                <FormikProvider value={formik}>
                                    <Card.Body>
                                        {ENTERPRISE_SEARCH?.map((val: any) => (
                                            <GenericFormCard {...val} />
                                        ))}
                                        <div className='d-flex justify-content-end pe-2 pb-3'>
                                            <GenericButtons text={t('RESET', 'Reset')} handleClick={onReset} />
                                            <GenericButtons text={t('SEARCH', 'Search')} handleClick={onSearch} variant='primary' />
                                        </div>
                                    </Card.Body>

                                    <Card.Body>
                                        {tableData.length ? (
                                            <GenericActionTable columns={ENTERPRISE_SEARCH_TABLE} rowData={tableData} hideExportBtn={true} hideSearchBar={true} handleEditRow={handleEditRow} />
                                        ) : (
                                            <></>
                                        )}
                                    </Card.Body>
                                </FormikProvider>
                            </Card>
                        </div>
                    </div>
                </>
            )}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
            />
        </>
    );
}
