import _ from 'lodash';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';

export const DOWNLOAD_TYPE_STEP = [
    {
        titleCode: 'MIGRATION_REPORTS',
        title: 'Migration Report',
        index: 1,
        isActive: true
    }
];

export const MIGRATION_REPORT = [
    {
        formData: [
            {
                titleCode: 'REPORT_TYPE',
                title: 'Report Type',
                field: 'reportName',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            }
        ]
    }
];

export const DROPDOWN_DATA = {
    reportName: [
        { code: '1', name: 'Migration Data Payable Amount Report', active: true },
        { code: '2', name: 'Migration Data Entry Report', active: true }
    ]
};