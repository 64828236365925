import React, { useEffect, useState } from 'react';
import {
    DISCONNECTION_CONNECTION_DETAILS_FORM_1,
    DISCONNECTION_CONNECTION_DETAILS_FORM_2,
    DISCONNECTION_CONNECTION_DETAILS_FORM_FOR_EMP,
    lesseInitialValue,
    modifyPlotList,
    WATER_CONNECTION_FORM_STEPS,
    WATER_LESSEE_DETAILS
} from './WaterConnection.const';
import HeaderComponent from '../../components/header/Header.component';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { convertToINR, fetchLeaseDurationDoc, getHistory, getOptions, getQueryParams, onAttachDocument, paymentCheck, paymentPayload } from '../modules/EstateManagement/EstateManagement.controller';
import { BPArequestInfo, POST, POST2, devUrl, endpoints, estateEndpoints, getNextValidAction, requestInfo } from '../../services/modulesServices/userManagement.api';
import { check, cross, user } from '../../utils/Logo';
import { TRANSFER_VALIDATION } from '../../utils/formikValidation/FormikValidation';
import { toast } from 'react-toastify';
import { getFirstErrorKey, getNavigateTo } from '../../utils/utils';
import { RazorpayOptions } from '../modules/UserManagement/types';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { Button, Card, CardBody } from 'react-bootstrap';
import Loading from '../../components/portalComponents/Loading.component';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { Formik, FormikProvider, useFormik } from 'formik';
import PaymentDetails from '../modules/EstateManagement/Allotment/PaymentDetails';
import { CorrespondenceWrapper, NotingWrapper } from '../../components/Workflow/NotingWrapper';
import { Clearance_Form_Initial_Data } from '../scrutinyFlowScreen/ScrutinyFormPage.module';
import GenericFooterComponent from '../../components/moduleCommonFormik/GenericFooterStepper.component';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';
import { DocumentInfo, NextValidActionItem } from '../../components/moduleCommonFormik/GenericFormCard.constants';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import GenericPopupModal from '../../components/moduleCommonFormik/GenericModalPopup.component';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import { CONSTANT_DATA_FIELD, KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../utils/constants';
import moment from 'moment';
import { OwnershipTable } from '../modules/UserManagement/EnterpriseModule/OwnershipTable';
import { EMP_QUESTIONNAIRE_DOCUMENT_COLUMN } from '../orderCompletionScreen/OrderCompletionFormPage.const';
import axios from 'axios';
import GenericPDFViewer from '../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../components/fileviewer/GenericImageViewer';
import activeArrow from '../../assets/logos/page arrow.svg';
import inactiveArrow from '../../assets/logos/page left arrow.svg';

const M_WaterConnectionDisconnection = () => {
    const initialValues = Clearance_Form_Initial_Data;
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visited, setVisited] = useState(false);
    const [transferorDetails, setTransferorDetails] = useState<any>({});
    const [transferDetails, setTransferDetails] = useState<any>(null);
    const [selectedTransfereeDetails, setSelectedTransfereeDetails] = useState<any>({});
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [waterConnectionData, setWaterConnectionData] = useState<any>([]);
    const [rowData, setRowData] = useState(null);
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;

    const getUser = JSON.parse(localStorage.getItem('user') || '{}');
    let navigate = useNavigate();
    const stepsDetails = WATER_CONNECTION_FORM_STEPS;
    const [paymentData, setPaymentData] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [openSection, setOpenSection] = useState<string>('LESSEE_DETAILS');
    const [actionsData, setActionsData] = useState<any>(null);

    const [searchParams] = useSearchParams();
    const location: any = useLocation();
    const [comment, setcomment] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const applicationNumber = urlParams.get('applicationNumber') || '';
    const [allotmentDetails, setAllotmentDetails] = useState<any>(null);
    const [plotDetails, setPlotDetails] = useState<any>(null);
    const [tabelData, setTabelData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const [plotList, setPlotList] = useState<any>([]);
    const [certificateData, setCertificateData] = useState<any>([]);
    const [selectedCertificate, setSelectedCertificate] = useState<any>(null);
    const [selectedOneCert, setSelectedOneCert] = useState<any>(null);
    const [questions, setQuestions] = useState(['']);
    const [showBackOptionsBtn, setShowBackOptionsBtn] = useState<boolean>(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [questionnaireData, setQuestionnaireData] = useState<any>([]);
    let user = localStorage.getItem('currentUserAssignment');
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [documentDetails, setDocumentsDetails] = useState<any>([]);
    const [existingConnectionDetails, setExistingConnectionDetails] = useState<any>([]);
    const [paymentRowData, setPaymentRowData] = useState<any>([]);
    let questionnaireDetails = questionnaireData;
    const totalPages = 2;
    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');
    const formik: any = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            isSpecialProject: false
        },
        onSubmit: value => {}
        // validationSchema: TRANSFER_VALIDATION[`page${activeIndex}`]
    });
    const { values, setValues, errors, setFieldTouched } = formik;

    const handleStepClick = (index: number) => {
        const stepperLength = stepsDetails.length;
        if (index > activeIndex) {
            try {
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const DOC_TABLE_COLUMN = [
        {
            colName: 'SN',
            fieldName: 'serialNo',
            clsName: 'col-sm-2'
        },
        {
            colName: 'Uploaded By',
            fieldName: 'uploadedUserRole',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Date & Time',
            fieldName: 'uploadedDate',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Document',
            fieldName: 'docFileName',
            clsName: 'col-sm-2',

            isClickable: true
        }
    ];

    const CERTIFICATES_COLUMNS = [
        {
            colName: 'Select',
            fieldName: 'radio',
            selectedValue: selectedOneCert,
            clsName: 'col-sm-1',
            id: 0
        },
        {
            colName: 'SN',
            fieldName: 'index',
            clsName: 'col-sm-1',
            id: 1
        },
        {
            colName: 'Application No',
            fieldName: 'applicationNumber',
            clsName: 'col-sm-3 column-text-start',
            id: 2
        },
        {
            colName: 'Document Name',
            fieldName: 'documentName',
            clsName: 'col-sm-3',
            id: 3
        },
        {
            colName: 'Document Type',
            fieldName: 'documentType',
            clsName: 'col-sm-3',
            id: 4
        }
    ];

    useEffect(() => {
        if (applicationNumber) {
            getActionsData();
            getHistoryData();
            getDocuments();
        }
    }, [applicationNumber]);

    useEffect(() => {
        if (activeIndex === 2 && documentDetails) {
            // const reverseDocumentDetails = documentDetails;
            // const tableData = async () => {
            //     const responseTableData = reverseDocumentDetails?.map((data: any, ind: number) => {
            //         return { ...data, serialNo: `C${ind + 1}` };
            //     });
            //     const reverseData = responseTableData;
            //     setTabelData(reverseData);
            // };
            const tableData = async () => {
                const responseTableData = documentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${documentDetails.length - ind}` };
                });
                setTabelData(responseTableData);
            };
            if (activeIndex === 2) {
                tableData();
            }
        }
    }, [documentDetails, activeIndex]);

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const getPlotData = async (entityId: any) => {
        try {
            setIsLoading(true);
            const { data } = await POST(`/idc-estate-service/entity-reference-details/_search?isPlotDetailsRequired=true&entityIds=${entityId}&types=${'LESSEE'}`, { RequestInfo: requestInfo() });
            if (data?.entityRefDetails.length > 0) {
                setPlotList(data?.entityRefDetails);
            }
        } catch (e: any) {}
        setIsLoading(false);
    };

    const fetchDetails = (plotID: any, entityId: any) => {
        fetchAllotmentDetails(plotID, entityId);
        fetchPlotDetails(entityId);
    };

    const fetchAllotmentDetails = async (plotID: any, entityId: any) => {
        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.allotmentDetailsSearch + `?plotIds=${plotID}&entityIds=${entityId}`, { RequestInfo: requestInfo() });
            setAllotmentDetails(data.allotmentDetails[0]);
            // values.plotNo = plotDetails?.plotNo;
            values.allotmentOrderNo = data.allotmentDetails[0]?.allotmentId;
            values.allotmentOrderDate = data.allotmentDetails[0]?.allotmentDate;

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const fetchPlotDetails = async (entityId: any) => {
        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.entityRefDetails + `?isPlotDetailsRequired=true&types=ALLOTTEE,LESSEE,TRANSFEROR&entityIds=${entityId}`, { RequestInfo: requestInfo() });
            setPlotDetails(data.entityRefDetails[0]?.plotDetails);
            values.estateName = data.entityRefDetails[0]?.plotDetails?.estateDetails?.estateName;
            values.plotType = data.entityRefDetails[0]?.plotDetails?.plotType;
            values.phase = data.entityRefDetails[0]?.plotDetails?.phase;

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const getQuestionnaires = (val: any) => {
        setIsLoading(true);
        if (!!val) {
            TechnicalServices.searchQuestionnaire(val, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    setIsLoading(false);
                    setQuestionnaireData(response?.data?.backQuestionnaires);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (applicationNumber) {
            const fetchWaterDetails = async () => {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.waterConnectionSearch + `?applicationNo=${applicationNumber}`, { RequestInfo: requestInfo() });
                    setWaterConnectionData(data[0]?.applicationDetails);
                    setExistingConnectionDetails(data[0]?.applicationDetails.existingConnectionDetails);
                    values.plotNo = data[0]?.applicationDetails?.plotDetails?.uuid;
                    getPlotData(data[0]?.applicationDetails?.leasseeDetails?.uuid);
                    fetchDetails(data[0]?.applicationDetails?.plotDetails?.uuid, data[0]?.applicationDetails?.leasseeDetails?.uuid);
                    // setCertificateData(data[0]?.applicationDetails?.documents);
                    const mappedDocuments = data[0]?.applicationDetails?.documents.map((doc: any, index: any) => ({
                        ...doc,
                        index: index + 1
                    }));

                    setCertificateData(mappedDocuments);
                    const lesseeInitValue: any = await lesseInitialValue(data[0]?.applicationDetails);
                    setValues((prevVal: any) => ({ ...prevVal, ...lesseeInitValue }));
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    console.error('Error fetching data:', error);
                }
            };
            if (applicationNumber) {
                fetchWaterDetails();
                getActionsData();
                getQuestionnaires(applicationNumber);
            }
        }
    }, [applicationNumber]);

    const onChangeNextIndex = async () => {
        const stepperLength = stepsDetails.length;
        try {
            if (activeIndex < stepperLength) {
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleNextPage = () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const lastSubmit = (isSaveOnly?: boolean, status?: string, comment?: string) => {
        if (activeIndex === 3 && !isPaid) onPay();
        else onSubmit(isSaveOnly);
    };

    const onSubmit = async (isSaveOnly?: boolean) => {
        if (activeIndex === 4 && !selectedTransfereeDetails?.item) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please select at least one asignee', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 1 && !values.plotUuid) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please select plot!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 3 && isPaid) {
            setActiveIndex(4);
            return;
        }

        const payload: any = {
            RequestInfo: requestInfo(),
            isBuiltUpAreaUtilised: values.isBuiltUpAreaUtilised,
            isTransferProhibited: values.isTransferProhibited,
            isTransfereeDisqualified: values.isTransfereeDisqualified,
            isIndustrialCommercial: values.isIndustrialCommercial,
            plotId: values.plotUuid || null,
            isSpecialTransfer: false,
            transferorDetails,
            transfereeDetails: selectedTransfereeDetails?.item || null,
            nocSpecialProjectDocument: values.nocFromGoaIpb?.docReferenceId
        };

        if (transferDetails) {
            payload['uuid'] = transferDetails.uuid;
            payload['applicationDetails'] = transferDetails.applicationDetails;
            payload['status'] = transferDetails.status;
            payload['auditDetails'] = transferDetails.auditDetails;
        }

        if (activeIndex === 4 && selectedTransfereeDetails?.item) {
            payload['isTransferorFinalSubmit'] = true;
        }

        const apiEndPoint = transferDetails ? endpoints.transferUpdate : endpoints.transferCreate;

        try {
            await TRANSFER_VALIDATION[`page${activeIndex}`]?.validate(values, { abortEarly: false, context: values });
            try {
                const { data } = await POST(apiEndPoint, payload);
                setTransferDetails(data?.transferDetails[0]);
                if (activeIndex === 1) {
                    toast('Application Created', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                } else if (activeIndex === 2) {
                    toast('Data saved successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                }
                !isSaveOnly && onChangeNextIndex();
                if (activeIndex === 4) {
                    setModalDetails({ show: true, title: 'Success!', para: 'Application assigned successfully', img: check, type: 'success', reUpload: 'OK' });
                }
            } catch (error: any) {
                let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            let errorField = getFirstErrorKey(errors);
            console.log(errors, e);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const fetchPayDetails = (data: any) => {
        return [
            {
                paymentType: '',
                principalAmount: +data?.adjustedSecurityDeposit,
                cgstPrincipal: null,
                sgstPrincipal: null,
                totalDueAmount: +data?.adjustedSecurityDeposit,
                feeTypeDisplayName: 'Adjusted Security Deposit',
                cgstAmount: data?.cgst === 0 ? '--' : data?.cgst,
                sgstAmount: data?.sgst === 0 ? '--' : data?.sgst,
                amount: convertToINR(data?.adjustedSecurityDeposit),
                total: convertToINR(data?.adjustedSecurityDeposit),
                index: 1
            }
        ];
    };

    const onPay = async () => {
        setIsLoading(true);
        let url =
            window.location.origin +
            `/goa-idc/estate/transfer?plotId=${values.plotUuid}&activeIndex=${activeIndex}&applicationNo=${transferDetails?.applicationDetails?.applicationNo}&entityId=${transferorDetails.uuid}`;
        const periodTo = new Date(transferDetails?.auditDetails?.createdTime).setFullYear(new Date(transferDetails?.auditDetails?.createdTime).getFullYear() + 1);
        const payload = paymentPayload(
            transferDetails?.applicationDetails,
            values.plotUuid,
            totalPaidAmount,
            url,
            'Transfer Application Fees',
            selectedGateway,
            transferDetails.auditDetails.createdTime,
            periodTo,
            paymentData
        );

        try {
            if (!totalPaidAmount) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (!selectedGateway) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (transferDetails?.applicationDetails?.applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data } = await POST(endpoints.paymentGateway, payload);
                const data1 = data;
                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, getUser, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    console.log(data1.additionalDetails);
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data1.additionalDetails.walletClientCode}&walletRequestMessage=${data1.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
                setIsLoading(false);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Payment Initiation Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            //Fetch Transfer Lessee Details
            try {
                const { data } = getUser.uuid && (await POST(endpoints.searchExistingUser + `?userUuid=${getUser.uuid}`, { RequestInfo: requestInfo() }));
                const transferor = data?.enterpriseEntity[0];
                if (transferor) {
                    setTransferorDetails(transferor);
                    const { entityName, phoneNumber, email } = transferor;
                    const postalAddress = transferor?.addresses?.find((address: any) => address.addressType === 'PERMANENT') || {};
                    const lesseeDataObj = { name: entityName, email, phoneNumber, ...postalAddress };
                    setValues({ ...values, ...lesseeDataObj });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let { data: plotData } = await POST(endpoints.entityRefDetails + `?entityIds=${transferorDetails.uuid}&types=LESSEE`, { RequestInfo: requestInfo() });
                if (plotData?.entityRefDetails?.length) {
                    let plotIds = plotData?.entityRefDetails?.map((item: any) => item.plotId);
                    plotIds = plotIds.join(',');

                    if (!plotIds) return;
                    let { data } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

                    let plotDataArray = data?.plots?.map((plot: any) => ({
                        estateName: plot?.estateDetails?.estateName,
                        plotType: plot?.plotType,
                        phase: plot?.phase,
                        plotNo: plot?.plotNo,
                        plotAreaSqm: plot?.plotAreaSqm,
                        uuid: plot?.uuid,
                        id: plot?.uuid
                    }));
                    setRowData(plotDataArray);
                    // setPlotData(plotDataArray);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (transferorDetails.uuid) fetchData();
    }, [transferorDetails]);

    useEffect(() => {
        const fetchData = async () => {
            const detailsData = await fetchLeaseDurationDoc(values.plotUuid, transferorDetails.uuid);

            setValues((prevState: any) => ({
                ...prevState,
                ...detailsData
            }));
        };

        values.plotUuid && transferorDetails && fetchData();
    }, [values.plotUuid, transferorDetails]);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleModalClose = (type: string) => {
        if (modalDetails.para === 'Submitted successfully') {
            window.location.reload();
        } else {
            setModalDetails({ show: false });
            if (type === 'success') {
                navigateToDashboard();
            }
        }
    };

    const downloadFile = (id: any) => {
        if (!id) return;
        axios
            .get(devUrl + `/filestore/v1/files/url?tenantId=ga&fileStoreIds=${id}`)
            .then((response: any) => {
                window.open(response?.data?.fileStoreIds[0]?.url);
            })
            .catch(error => {});
    };

    const renderQuestionnaireDetails = () => {
        return (
            <>
                {!!questionnaireDetails &&
                    questionnaireDetails.map((val: any, ind: number) => {
                        values[`que_${val?.id}`] = val?.question;
                        if (!!val?.answer) values[`ans_${val?.id}`] = val?.answer;
                        const myArray = {
                            formData: [
                                {
                                    title: 'Question',
                                    field: `que_${val?.id}`,
                                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                                    isRequired: true,
                                    isDisabled: true,
                                    titleCode: `OC_LBL_ENTERPRISE_NAMEs ${ind + 1}`,
                                    class: 'me-2'
                                },
                                {
                                    title: 'Answer',
                                    field: `ans_${val?.id}`,
                                    type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                                    isRequired: true,
                                    titleCode: 'Your Feedback',
                                    class: 'me-2',
                                    isDisabled: true
                                }
                            ]
                        };

                        const uploadedTime = questionnaireData[0]?.documents[0]?.createdTime;
                        console.log(uploadedTime);

                        // const uploadedTime = 1723443622459;
                        const dateObject = new Date(uploadedTime);

                        // Convert the date to IST by adding the IST offset
                        const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC +5:30
                        const istDateObject = new Date(dateObject.getTime() + istOffset);

                        // Format the date as DD/MM/YYYY
                        const formattedDate = istDateObject.toLocaleDateString('en-IN');
                        console.log(formattedDate); // Output: "12/08/2024"

                        return (
                            <>
                                {/* <Card>
                                    <CardBody> */}
                                <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 my-2'>
                                    <input type='checkbox' id={`${val?.id}_${ind}`} name={`${val?.id}_${ind}`} defaultChecked={false} />
                                    <label htmlFor={`${val?.id}_${ind}`} className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold  '>
                                        {t('OC_EMP_REPORT_HEADING', `Correction Report ${ind + 1}`)}
                                    </label>
                                    <div className='form_fields px-2'>
                                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 '>
                                            <GenericFormCard {...myArray} key={ind} />
                                        </div>
                                        <div className='mt-3'>
                                            <OwnershipTable
                                                column={EMP_QUESTIONNAIRE_DOCUMENT_COLUMN}
                                                tableRowData={val?.documents || []}
                                                additionalMethod={() => {}}
                                                deleteOwnershipDetails={() => {}}
                                                updateOwnershipDataStatus={(isId: any, data: any) => downloadFile(data?.rowId ?? data?.fileStoreId)}
                                                isDisabled={false}
                                                hideSearchBar
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* </CardBody>
                                </Card> */}
                            </>
                        );
                    })}
                {!questionnaireDetails.length && (
                    // <Card>
                    //     <CardBody>
                    <p className='text-center fw-bold pt-2'>No Data Available</p>
                    //     </CardBody>
                    // </Card>
                )}
            </>
        );
    };

    const LesseeDetails = () => {
        return (
            <>
                {WATER_LESSEE_DETAILS?.map(val => (
                    <GenericFormCard {...val} />
                ))}
            </>
        );
    };

    const ConnectionDetails = () => {
        values.estateName = plotDetails?.estateDetails?.estateName;
        values.plotNo = plotDetails?.plotNo;
        values.plotType = plotDetails?.plotType;
        values.phase = plotDetails?.phase;
        values.connectionType = waterConnectionData?.applicationType;
        // values.allotmentOrderNo = allotmentDetails?.allotmentId;
        // values.allotmentOrderDate = allotmentDetails?.allotmentDate;
        // values.waterType = waterConnectionData?.waterType;
        // values.connectionPipeDia = waterConnectionData?.pipeDiaMm;
        // values.monthlyConsumption = waterConnectionData?.monthlyWaterConsumptionM3;
        const connection_details_formData = applicationNumber ? DISCONNECTION_CONNECTION_DETAILS_FORM_FOR_EMP : DISCONNECTION_CONNECTION_DETAILS_FORM_1;
        // const connection_details_formData_1 = applicationNumber ? CONNECTION_DETAILS_FORM_FOR_EMP_SECOND : CONNECTION_DETAILS_FORM_2;

        connection_details_formData[0].formData.map((val: any, id: any) => {
            if (val?.field === 'plotNo') {
                val.type = CONSTANT_DATA_FIELD.TEXT_FIELD;
                val.options = modifyPlotList(plotList);
            }
        });
        return (
            <div>
                <>
                    {/* <Card className='card_border'>
                        <Card.Body> */}
                    {connection_details_formData?.map(val => (
                        <GenericFormCard {...val} />
                    ))}

                    <div className='pb-2'>
                        <GenericActionTable
                            columns={CERTIFICATES_COLUMNS}
                            searchBarPlaceholder={'SEARCH_BY_PLOT'}
                            rowData={certificateData}
                            hideExportBtn={true}
                            checkType={'singleCheck'}
                            itemsPerPage={10}
                            hideSearchBar
                        />
                    </div>

                    {/* {connection_details_formData_1?.map(val => (
                                <GenericFormCard {...val} />
                            ))} */}
                    {/* </Card.Body>
                    </Card> */}
                </>
            </div>
        );
    };

    const DisconnectionDetails = () => {
        values.industrialEstate = plotDetails?.estateDetails?.estateName;
        values.plotNo = plotDetails?.plotNo;
        values.allotmentOrderNo = allotmentDetails?.allotmentId;
        values.allotmentOrderDate = allotmentDetails?.allotmentDate;
        values.waterType = waterConnectionData?.waterType;
        values.connectionPipeDia = waterConnectionData?.pipeDiaMm;
        values.monthlyConsumption = waterConnectionData?.monthlyWaterConsumptionM3;
        values.connectionType = waterConnectionData?.applicationType;
        // const connection_details_formData = applicationNumber ? DISCONNECTION_CONNECTION_DETAILS_FORM_FOR_EMP : DISCONNECTION_CONNECTION_DETAILS_FORM_1;
        const connection_details_formData_1 = applicationNumber ? DISCONNECTION_CONNECTION_DETAILS_FORM_2 : DISCONNECTION_CONNECTION_DETAILS_FORM_2;

        // connection_details_formData[0].formData.map((val: any, id: any) => {
        //     if (val?.field === 'plotNo') {
        //         val.options = modifyPlotList(plotList);
        //     }
        // });
        return (
            <div>
                <>
                    {/* <Card className='card_border'>
                        <Card.Body> */}
                    {/* {connection_details_formData?.map(val => (
                        <GenericFormCard {...val} />
                    ))} */}

                    {connection_details_formData_1?.map(val => (
                        <GenericFormCard {...val} />
                    ))}
                    {/* </Card.Body>
                    </Card> */}
                </>
            </div>
        );
    };
    const onSectionChange = (currentSection: string) => {
        setOpenSection(currentSection);
    };

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const renderPayment = () => {
        return !paymentIsLoading ? (
            <PaymentDetails
                paymentData={paymentRowData.length ? paymentRowData : fetchPayDetails(waterConnectionData)}
                selectedGateway={''}
                setSelectedGateway={setSelectedGateway}
                isPaid={paymentRowData.length ? true : false}
                gatewayData={[]}
                transactionDetails={transactionDetails}
                title={'Water Connection Fee'}
                isVisiblePaid={true}
                hideTitle={true}
                isCardDisable={true}
                isPaymentOption={false}
            ></PaymentDetails>
        ) : (
            <Loading />
        );
    };

    const onActionClick = (action: string) => {
        if (action === 'SEND_BACK_TO_LESSEE') {
            setShowPopUp(true);
            return;
        }
        if (action === 'ACT_OC_MARK_AS_COMPLETE') {
            markAsComplete();
            return;
        }
        // if (action === 'SUBMIT') {
        //     submitWaterConnection(action);
        //     return;
        // }
        onLastSubmit(action);
    };

    const onLastSubmit = async (action: string) => {
        window.scrollTo(0, 850);
        setOpenSection('DISCONNECTION_DETAILS');

        const payload: any = {
            RequestInfo: BPArequestInfo(),
            waterApplicationRequest: {
                applicationDetails: waterConnectionData
            },
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: 'WATER_CONNECTION_DISCONNECTION',
                    businessId: waterConnectionData?.applicationNo,
                    moduleName: 'Water Connection Disconnection',
                    action: action,
                    notes: values?.notes,
                    comment: values?.notes
                }
            ]
        };

        const apiEndPoint = endpoints.waterConnectionInitiate;

        try {
            // setModalDetails({ show: true, title: 'Success!', para: 'Application updated successfully', img: check, type: 'success', reUpload: 'OK' });
            if (action === 'FORWARD_TO_ARM') {
                const successMessageOC = `Application${applicationNumber ? ' number ' + applicationNumber : ''} forwarded successfully`;
                setModalDetails({
                    show: true,
                    // para: t('POPUP_MSG_ACT_OC_SEND_BACK_LESSEE', 'Field Manager (site) has sent application back to Lessee'),
                    para: successMessageOC,
                    title: 'Success!',
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                });
            }
            if (action === 'SEND_BACK_TO_LESSEE') {
                const successMessageOC = `Application${applicationNumber ? ' number ' + applicationNumber : ''} Field Manager (site) has sent application back to Lessee`;
                setModalDetails({
                    show: true,
                    // para: t('POPUP_MSG_ACT_OC_SEND_BACK_LESSEE', 'Field Manager (site) has sent application back to Lessee'),
                    para: successMessageOC,
                    title: 'Success!',
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                });
            }
            if (action === 'APPROVE') {
                const successMessageOC = `Application${applicationNumber ? ' number ' + applicationNumber : ''} approved successfully`;
                setModalDetails({
                    show: true,
                    // para: t('POPUP_MSG_ACT_OC_SEND_BACK_LESSEE', 'Field Manager (site) has sent application back to Lessee'),
                    para: successMessageOC,
                    title: 'Success!',
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                });
            }
            if (action === 'REJECT') {
                const successMessageOC = `Application${applicationNumber ? ' number ' + applicationNumber : ''} has been rejected`;
                setModalDetails({
                    show: true,
                    // para: t('POPUP_MSG_ACT_OC_SEND_BACK_LESSEE', 'Field Manager (site) has sent application back to Lessee'),
                    para: successMessageOC,
                    title: 'Success!',
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                });
            }
            if (action === 'RETURN_TO_FM') {
                const successMessageOC = `Application${applicationNumber ? ' number ' + applicationNumber : ''} Area Manager has sent application back to FM (site)`;
                setModalDetails({
                    show: true,
                    // para: t('POPUP_MSG_ACT_OC_SEND_BACK_LESSEE', 'Field Manager (site) has sent application back to Lessee'),
                    para: successMessageOC,
                    title: 'Success!',
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                });
            }
            if (action === 'SUBMIT') {
                // Update the waterConnectionData with new values
                waterConnectionData.finalMeterReading = values?.lastReading;
                waterConnectionData.disconnectionDate = moment(new Date(values?.disconnectionDate)).format('DD/MM/yyyy');
                waterConnectionData.existingConnectionDetails = existingConnectionDetails;

                if (!values?.lastReading || !values?.disconnectionDate) {
                    setModalDetails({
                        show: true,
                        para: 'Please fill required details.',
                        title: 'Error!',
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    return;
                }
                const successMessageOC = `Water connection disconnected successfully`;
                setModalDetails({
                    show: true,
                    // para: t('POPUP_MSG_ACT_OC_SEND_BACK_LESSEE', 'Field Manager (site) has sent application back to Lessee'),
                    para: successMessageOC,
                    title: 'Success!',
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                });
            }
            if (!values?.notes) {
                setModalDetails({ show: true, title: 'Error!', para: t('TC_MSG_CHECK_NOTING', 'Please add the noting and proceed'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            setIsLoading(true);

            const { data } = await POST(apiEndPoint, payload);
            console.log(data);
            setIsLoading(false);
            // return;
        } catch (error: any) {
            setIsLoading(false);
            let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const submitWaterConnection = async (action: string) => {
        window.scrollTo(0, 850);
        setOpenSection('INSTALLATION_DETAILS');

        // if (!values?.connectionStatus || !values?.connectionDate || !values?.meterNo) {
        //     setModalDetails({ show: true, title: 'Error!', para: t('TC_MSG_CHECK_VALID_INSTALLATION', 'Please add required data'), img: cross, type: 'error', reUpload: 'OK' });
        //     return;
        // }

        if (!values?.notes) {
            setModalDetails({ show: true, title: 'Error!', para: t('TC_MSG_CHECK_NOTING', 'Please add the noting and proceed'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        setIsLoading(true);
        const payload: any = {
            RequestInfo: BPArequestInfo(),
            connectionDetails: {
                applicationDetails: waterConnectionData,
                leasseeDetails: {
                    uuid: waterConnectionData?.leasseeDetails?.uuid
                },
                plotDetails: {
                    uuid: waterConnectionData?.plotDetails?.uuid
                },
                subLeasseeDetails: {
                    uuid: waterConnectionData?.subLeasseeDetails?.uuid || null
                },
                // connectionStatus: !!values?.connectionStatus ? 'ACTIVE' : 'INACTIVE',
                connectionStatus: 'DISCONNECT',
                connectionDate: moment(new Date(values?.connectionDate)).format('DD/MM/yyyy'),
                disconnectionDate: moment(new Date(values?.connectionDate)).format('DD/MM/yyyy'),
                isActive: false,
                notes: values?.notes,
                meterNo: values?.meterNo,
                waterType: waterConnectionData?.waterType
            }
        };

        const apiEndPoint = endpoints.updateWaterConnection;

        try {
            // if (action === 'SUBMIT') {
            //     // Update the waterConnectionData with new values
            //     waterConnectionData.finalMeterReading = values?.lastReading;
            //     waterConnectionData.disconnectionDate = moment(new Date(values?.connectionDate)).format('DD/MM/yyyy');
            //     waterConnectionData.existingConnectionDetails = existingConnectionDetails;
            // }
            const { data } = await POST(apiEndPoint, payload);
            setIsLoading(false);
            onLastSubmit(action);
        } catch (error: any) {
            setIsLoading(false);
            let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getActionsData = () => {
        if (!!applicationNumber) {
            setIsLoading(true);

            getNextValidAction(applicationNumber)
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        setActionsData(response?.data);
                    }

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    const getPaymentData = async (appNumber: any) => {
        try {
            const data = await POST(endpoints.paymentDetailsSearch + `?applicationIds=${appNumber || waterConnectionData?.uuid}`, { RequestInfo: requestInfo() });
            // setTransactionDetails(data?.data?.payments[0]);
            // let payment = data?.data?.payments?.map((val: any, index: number) => ({
            //     feeTypeDisplayName: val.paymentTypeDisplayName,
            //     amount: convertToINR(val.principalAmount),
            //     cgstAmount: val.cgstPrincipal === 0 ? '--' : convertToINR(val.cgstPrincipal),
            //     sgstAmount: val.sgstPrincipal === 0 ? '--' : convertToINR(val.sgstPrincipal),
            //     total: convertToINR(val.paidAmount),
            //     index: index + 1
            // }));
            if (data?.data?.payments.length) {
                let paymentDetails = data?.data?.payments[0]?.payment;
                setTransactionDetails({
                    txnId: paymentDetails?.transactionNumber || '',
                    auditDetails: { createdTime: paymentDetails?.transactionDate || '' },
                    txnStatus: paymentDetails?.paymentStatus || '',
                    invoiceRefId: data?.data?.payments[0]?.invoiceRefId || ''
                });
                let payment = data?.data?.payments?.map((val: any, index: number) => ({
                    feeTypeDisplayName: val.paymentTypeDisplayName,
                    amount: convertToINR(val.principalAmount),
                    cgstAmount: val.cgstPrincipal === 0 ? '--' : convertToINR(val.cgstPrincipal),
                    sgstAmount: val.sgstPrincipal === 0 ? '--' : convertToINR(val.sgstPrincipal),
                    total: convertToINR(val.paidAmount),
                    index: index + 1
                }));
                setPaymentRowData(payment);
            }
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const submitQuestionnaire = () => {
        if (!!questions.filter(val => val === '').length) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill the question data', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setIsLoading(true);
        let questionLists: any = [];
        !!questions.length &&
            questions.map((val, ind) => {
                questionLists.push({
                    applicationNumber: applicationNumber,
                    questionnaireId: ind + 1,
                    question: val,
                    documents: []
                });
                return questionLists;
            });

        let payload: any = {
            RequestInfo: BPArequestInfo(),
            backQuestionnaires: questionLists
        };

        TechnicalServices.manageQuestionnaire(payload, false)
            .then((response: any) => {
                setIsLoading(false);
                setShowPopUp(false);
                const successMessageOCQuestionnaire = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                    'POPUP_MSG_SUCCESS_QUESTIONNAIRE_SUBMITTED',
                    'Questions submitted successfully'
                )}`;
                setModalDetails({
                    show: true,
                    title: 'Success!',
                    // para: t('POPUP_MSG_SUCCESS_QUESTIONNAIRE_SUBMITTED', 'Questions submitted successfully'),
                    para: successMessageOCQuestionnaire,
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                });
                // submitForm(selectedAction);
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const onClose = () => {
        setShowPopUp(false);
    };

    const getDocuments = async () => {
        if (applicationNumber) {
            try {
                // const historyData: any = await TechnicalServices.searchAllDocuments(applicationNumber, { RequestInfo: BPArequestInfo() });
                const historyData: any = await POST(endpoints.getwaterConnectionDocuments + `?applicationNo=${applicationNumber}`, { RequestInfo: requestInfo() });

                setDocumentsDetails(historyData?.data?.documents);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something went wrong!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, '', null));
            getDocuments();
            setActiveIndex(2);
        } catch (e) {}
    };

    const getHistoryData = async () => {
        if (applicationNumber) {
            try {
                const historyData = await getHistory(applicationNumber);
                console.log(historyData, 'historyData');
                setHistoryDetails(historyData);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something went wrong!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    const markAsComplete = () => {
        setIsLoading(true);

        TechnicalServices.markAsCompleteFlow(applicationNumber, { RequestInfo: BPArequestInfo() })
            .then((response: any) => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Success!', para: t('', 'Submitted successfully'), img: check, type: 'success', reUpload: 'OK' });
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };
    // const renderActionBtn = () => (
    //     <>
    //         {actionsData?.nextValidAction.map((val: any) => {
    //             // eslint-disable-next-line array-callback-return
    //             if (val?.action === 'Submit') return;
    //             return (
    //                 <>
    //                     <Button
    //                         variant='disabled outline-success new_btn btn btn-light me-3 font-karla-med p-10 fs-6'
    //                         style={{ borderColor: '#45852e', color: '#FFF', backgroundColor: '#45852e', width: 'max-content', borderRadius: 8 }}
    //                         onClick={() => onActionClick(val?.action)}
    //                     >
    //                         {/* {val?.action.replace(/_/g, ' ')} */}
    //                         {t(val?.action, 'Localisation Not Working')}
    //                     </Button>
    //                 </>
    //             );
    //         })}
    //     </>
    // );

    const renderActionBtn = () => {
        const handleAddField = () => {
            setQuestions([...questions, '']);
        };

        const handleRemoveField = (index: any) => {
            const newQuestions = questions.filter((_, i) => i !== index);
            setQuestions(newQuestions);
        };

        const handleChange = (e: any, index: any) => {
            const newQuestions = questions.map((question, i) => (i === index ? e.target.value : question));
            setQuestions(newQuestions);
        };

        // const nextMarkAsCompleteAction = actionMark;
        return (
            <>
                {actionsData?.nextValidAction
                    .map((val: any, index: number) => {
                        if (val?.action === 'Submit') return;
                        if (val?.action === 'FORWARD_TO_ARM' && !waterConnectionData?.showForwardButton) return false;
                        // if (!!waterConnectionData?.markAsCompletedPending) return;
                        if (val?.action === 'RETURN_TO_FM') {
                            return (
                                <>
                                    <div className='position-relative'>
                                        <GenericButtons
                                            text={
                                                <>
                                                    {t('REVERT', 'Revert')} <FontAwesomeIcon icon={faChevronUp} />
                                                </>
                                            }
                                            handleClick={() => {
                                                setShowBackOptionsBtn(!showBackOptionsBtn);
                                            }}
                                        />

                                        <div className={`position-absolute showBackBtns ${showBackOptionsBtn ? 'visible' : ''}`} style={{ bottom: '3rem' }}>
                                            {actionsData?.nextValidAction && showBackOptionsBtn && (
                                                <div className='send-back-card justify-content-center'>
                                                    {actionsData?.nextValidAction
                                                        ?.filter((val: any) => val?.action === 'RETURN_TO_FM')
                                                        .map((item: NextValidActionItem, index: number) => (
                                                            <>
                                                                <div className='  font-16 dropdown-text cursor-pointer font-inter' onClick={e => onActionClick(val?.action)}>
                                                                    <div> {t(val?.action, 'NO LOCALIZATION')}</div>
                                                                </div>
                                                            </>
                                                        ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* SAMPLECODE */}
                                    {/* <GenericButtons
                                        key={val?.action}
                                        variant={actionsData?.nextValidAction.length > 0 && index === 0 ? 'primary' : ''}
                                        text={t(val?.action, `${(val?.action).replace(/_/g, ' ')}`)}
                                        handleClick={() => onActionClick(val?.action)}
                                        ClassName={'variant'}
                                    /> */}

                                    {showPopUp && (
                                        <GenericPopupModal
                                            isPrimaryBtn={true}
                                            isSecondaryBtn={true}
                                            secondaryBtnTitle={t('CLOSE', 'Close')}
                                            primaryBtnTitle={t('SEND', 'Send')}
                                            secondaryAction={() => setShowPopUp(false)}
                                            onClose={onClose}
                                            primaryAction={() => {
                                                submitQuestionnaire();
                                            }}
                                            isVisible={showPopUp}
                                            children={
                                                <div className='' style={{ background: '#F5F5F6' }}>
                                                    <div className='my-3 mx-3'>
                                                        {questions.map((question, index) => (
                                                            <div key={index} className='mb-3'>
                                                                <label className='mb-3'>
                                                                    {t('QUESTIONNAIREs', 'Questionnaire/Query')}
                                                                    <span style={{ color: 'red' }}> *</span>
                                                                </label>
                                                                {/* <GenericCommonTextAreaField field={``} title='' isRequired={true} /> */}
                                                                <textarea value={question} onChange={e => handleChange(e, index)} rows={4} style={{ width: '100%' }} />
                                                                <button
                                                                    type='button'
                                                                    className='col-sm-12 mt-2  mb-2 d-flex justify-content-center align-items-center  noc_add_fields'
                                                                    onClick={() => handleRemoveField(index)}
                                                                >
                                                                    - {t('REMOVE_FIELD', 'Remove Field')}
                                                                </button>
                                                            </div>
                                                        ))}
                                                        <button className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center  noc_add_fields' onClick={handleAddField}>
                                                            + {t('ADD_FIELD', 'Add Field')}
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            title={`${t('', 'New Water Connection')}`}
                                            subTitle={t('CLARIFICATION_REQUIRED', 'Clarification required')}
                                            size='xl'
                                            // display='d-none'
                                        />
                                    )}
                                </>
                            );
                        }
                        if (val?.action === 'SEND_BACK_TO_LESSEE') {
                            return (
                                <>
                                    <div className='position-relative'>
                                        <GenericButtons
                                            text={
                                                <>
                                                    {t('REVERT', 'Revert')} <FontAwesomeIcon icon={faChevronUp} />
                                                </>
                                            }
                                            handleClick={() => {
                                                setShowBackOptionsBtn(!showBackOptionsBtn);
                                            }}
                                        />

                                        <div className={`position-absolute showBackBtns ${showBackOptionsBtn ? 'visible' : ''}`} style={{ bottom: '3rem' }}>
                                            {actionsData?.nextValidAction && showBackOptionsBtn && (
                                                <div className='send-back-card justify-content-center'>
                                                    {actionsData?.nextValidAction
                                                        ?.filter((val: any) => val?.action === 'SEND_BACK_TO_LESSEE')
                                                        .map((item: NextValidActionItem, index: number) => (
                                                            <>
                                                                <div className='  font-16 dropdown-text cursor-pointer font-inter' onClick={e => onActionClick(val?.action)}>
                                                                    <div> {t(val?.action, 'NO LOCAL')}</div>
                                                                </div>
                                                            </>
                                                        ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {showPopUp && (
                                        <GenericPopupModal
                                            isPrimaryBtn={true}
                                            isSecondaryBtn={true}
                                            secondaryBtnTitle={t('CLOSE', 'Close')}
                                            primaryBtnTitle={t('SEND', 'Send')}
                                            secondaryAction={() => setShowPopUp(false)}
                                            onClose={onClose}
                                            primaryAction={() => {
                                                submitQuestionnaire();
                                            }}
                                            isVisible={showPopUp}
                                            children={
                                                <div className='' style={{ background: '#F5F5F6' }}>
                                                    <div className='my-3 mx-3'>
                                                        {questions.map((question, index) => (
                                                            <div key={index} className='mb-3'>
                                                                <label className='mb-3'>
                                                                    {t('QUESTIONNAIREs', 'Questionnaire/Query')}
                                                                    <span style={{ color: 'red' }}> *</span>
                                                                </label>
                                                                {/* <GenericCommonTextAreaField field={``} title='' isRequired={true} /> */}
                                                                <textarea value={question} onChange={e => handleChange(e, index)} rows={4} style={{ width: '100%' }} />
                                                                <button
                                                                    type='button'
                                                                    className='col-sm-12 mt-2  mb-2 d-flex justify-content-center align-items-center  noc_add_fields'
                                                                    onClick={() => handleRemoveField(index)}
                                                                >
                                                                    - {t('REMOVE_FIELD', 'Remove Field')}
                                                                </button>
                                                            </div>
                                                        ))}
                                                        <button className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center  noc_add_fields' onClick={handleAddField}>
                                                            + {t('ADD_FIELD', 'Add Field')}
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            title={`${t('', 'New Water Connection')}`}
                                            subTitle={t('CLARIFICATION_REQUIRED', 'Clarification required')}
                                            size='xl'
                                            // display='d-none'
                                        />
                                    )}
                                </>
                            );
                        }
                        return (
                            <GenericButtons
                                key={val?.action}
                                variant={actionsData?.nextValidAction.length > 0 && index === 0 ? 'primary' : ''}
                                text={t(val?.action, `${(val?.action).replace(/_/g, ' ')}`)}
                                handleClick={() => onActionClick(val?.action)}
                                ClassName={'variant'}
                            />
                        );
                    })
                    .reverse()}
                {!!waterConnectionData?.markAsCompletedPending && (
                    <GenericButtons variant='primary' text={t('ACT_OC_MARK_AS_COMPLETE', 'Mark as complete')} handleClick={() => onActionClick('ACT_OC_MARK_AS_COMPLETE')} />
                )}
            </>
        );
    };

    const renderAcknowledgementDetails = () => {
        return (
            <>
                {/* <Card className='card_border my-2'>
                    <Card.Body> */}
                <div className='row ps-2 py-2'>
                    {/* <div className='formTitle m-0 fw-bold'>
                                {t('', 'Acknowledgement')}
                                <span className='text-danger'>*</span>
                            </div> */}
                </div>
                <div className='row d-flex m-0'>
                    <p className='fw-bold'>The Lessee does hereby affirm and declare that:</p>

                    {/* <span className='checkmark'></span> */}
                    <span className='font-inter' style={{ fontWeight: '600' }}>
                        <p>1. I/We will carry out pipeline work from the connection point to the shed as directed by the Engineer-in-charge of the Corporation.</p>
                        <p>
                            2. I/We will provide the water meter, approved by the Engineer-In-Charge of the Corporation and all other necessary fittings will be provided at my/our own cost. I/we also
                            undertake to repair these fittings if the situation warrants.
                        </p>
                        <p>3. I/We will keep a duplicate key with the in charge of the Estate and will have no objection for taking water meter reading daily.</p>
                        <p>4. I/We will also undertake to pay the water consumption charges every month before 10th of next month.</p>
                        <p>
                            5. I/We will make necessary drainage arrangements including soak pit etc. in the plot or my/our shed, before the water connection is given to my/our shed to the
                            satisfaction of the Engineer-in-charge.
                        </p>
                        <p>6. I/We understand that the water supply will be disconnected by the Corporation if the unit is in the arrears of water charges for more than TWO months.</p>
                        <p>7. I/We agree to pay the security deposit and connection charges as required by the Corporation.</p>
                        <p>8. I/We will pay the water supply charges at the rates decided time to time. We are aware that the present rate is Rs. 45/-(Rupees forty Five only) per cubic meter.</p>
                        <p>
                            9. The monthly consumption of water will be 250 m<sup>3</sup> for the said connection. (Copy of Project report attached).
                        </p>
                        <p>10. Electromagnetic type digital water meter (B/class) will be provided by us.</p>
                    </span>
                    <Card.Body>
                        <div className={`ps-2 d-flex gap-2 border my-3 rounded success`} style={{ fontSize: '16px', padding: '12px' }}>
                            <div className='fw-bold'>
                                <img alt='' height={16} src={check} />
                            </div>
                            <span className='fw-medium'>I will abide all the statement mentioned above.</span>
                        </div>
                    </Card.Body>
                </div>
                {/* {!!props?.previewData?.lesseeDigitalSignFlage || props?.eSignFlag ? (
                                            <>
                                                <div className='ps-2 pt-2 font-14 font-inter fw-bold m-0'>Digitally Signed</div>
                                                <div className='ps-2 pb-2 font-14 font-inter'>{eSignedDocumentData?.esignedDate}</div>
                                                <div onClick={() => downloadApplicationDocument()} className='ps-2 d-flex align-items-center'>
                                                    <Image src={download} height={14} />
                                                    <div className='ps-2 font-14' style={{ cursor: 'pointer', color: '#619050', textDecoration: 'underline' }}>
                                                        Download Application
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <GenericButtons text='Sign the document' ClassName='px-3' handleClick={props?.signDocument} />
                                        )} */}
                {/* </Card.Body>
                </Card> */}
            </>
        );
    };
    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
            {/* <StatusAlertPopup para={bodydata} img={cross} handleClose={handleClose} handleSubmit={handleStatusSubmit} show={showStatus} title={''} /> */}
            <div className='applicationPage m-auto px-5 py-4'>
                <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                    <GenericHeader
                        // subText={`Permit No. : ${values?.refApplication || applicationNumber || 'NA'}`}
                        text={t('', 'Water Connection')}
                        additionalClass='sub-text-style'
                        // onActionClick={() => navigateToDashboard()}
                        bottomSubText={`File : ${values?.name}, Type : ${t('', 'Water Connection')}, No :${applicationNumber || 'NA'}`}
                    />
                    <Formik initialValues={initialValues} onSubmit={value => {}}>
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('', 'Water Connection')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : ${t('', 'Water Connection')}, No : ${applicationNumber || 'NA'}`}
                                    />

                                    <NotingWrapper
                                        text={comment}
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        // disable={!values.isUpdatable}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={actionsData}
                                        disable={isViewOnly ? isViewOnly : !actionsData?.nextValidAction?.length}
                                    />
                                    {/* <p className='noting-heading my-1'>{t('OC_EMP_NOTING_HEADING', 'Noting')}</p>

                                    <NotingComponent history={historyDetails} text={comment} handleOnFileUpload={handleOnFileUpload} /> */}
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper
                                    applicationNumber={applicationNumber}
                                    //  designation={designation}
                                    nextValidAction={{}}
                                />
                                <Card className='checklist-outer mb-4 mx-3'>
                                    <CardBody>
                                        {activeIndex === 1 && (
                                            <div className='row'>
                                                <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                    <input
                                                        type='checkbox'
                                                        id='LESSEE_DETAILS_Checkbox'
                                                        name='LESSEE_DETAILS'
                                                        checked={openSection === 'LESSEE_DETAILS'}
                                                        onChange={() => onSectionChange('LESSEE_DETAILS')}
                                                    />
                                                    <label
                                                        htmlFor='LESSEE_DETAILS_Checkbox'
                                                        className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                    >
                                                        {t('', 'A - Lessee Details')}
                                                    </label>
                                                    {openSection === 'LESSEE_DETAILS' && LesseeDetails()}
                                                </div>
                                                <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                    <input
                                                        type='checkbox'
                                                        id='CONNECTION_DETAILS_Checkbox'
                                                        name='CONNECTION_DETAILS'
                                                        checked={openSection === 'CONNECTION_DETAILS'}
                                                        onChange={() => onSectionChange('CONNECTION_DETAILS')}
                                                    />
                                                    <label
                                                        htmlFor='CONNECTION_DETAILS_Checkbox'
                                                        className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                    >
                                                        {t('', 'B - Connection Details')}
                                                    </label>
                                                    {openSection === 'CONNECTION_DETAILS' && ConnectionDetails()}
                                                </div>
                                                <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                    <input type='checkbox' id='PAYMENT_Checkbox' name='PAYMENT' checked={openSection === 'PAYMENT'} onChange={() => onSectionChange('PAYMENT')} />
                                                    <label
                                                        htmlFor='PAYMENT_Checkbox'
                                                        className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                    >
                                                        {t('', 'C - Payment Details')}
                                                    </label>
                                                    {openSection === 'PAYMENT' && renderPayment()}
                                                </div>
                                                <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                    <input
                                                        type='checkbox'
                                                        id='ACKNOWLDGEMENT_Checkbox'
                                                        name='ACKNOWLDGEMENT'
                                                        checked={openSection === 'ACKNOWLDGEMENT'}
                                                        onChange={() => onSectionChange('ACKNOWLDGEMENT')}
                                                    />
                                                    <label
                                                        htmlFor='ACKNOWLDGEMENT_Checkbox'
                                                        className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                    >
                                                        {t('', 'D - Acknowledgement')}
                                                    </label>
                                                    {openSection === 'ACKNOWLDGEMENT' && renderAcknowledgementDetails()}
                                                </div>
                                                {questionnaireDetails.length > 0 && (
                                                    <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                        <input
                                                            type='checkbox'
                                                            id='QUESTIONAAIRE_Checkbox'
                                                            name='QUESTIONAAIRE'
                                                            checked={openSection === 'QUESTIONAAIRE'}
                                                            onChange={() => onSectionChange('QUESTIONAAIRE')}
                                                        />
                                                        <label
                                                            htmlFor='QUESTIONAAIRE_Checkbox'
                                                            className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                        >
                                                            {t('', 'E - Questionnaire Details')}
                                                        </label>
                                                        {openSection === 'QUESTIONAAIRE' && renderQuestionnaireDetails()}
                                                    </div>
                                                )}
                                                {actionsData?.nextValidAction.filter((val: any) => val?.action === 'SUBMIT').length > 0 && (
                                                    <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                        <input
                                                            type='checkbox'
                                                            id='DISCONNECTION_DETAILS_Checkbox'
                                                            name='DISCONNECTION_DETAILS'
                                                            checked={openSection === 'DISCONNECTION_DETAILS'}
                                                            onChange={() => onSectionChange('DISCONNECTION_DETAILS')}
                                                        />
                                                        <label
                                                            htmlFor='DISCONNECTION_DETAILS_Checkbox'
                                                            className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                        >
                                                            {t('', 'F - Disconnection Details')}
                                                        </label>
                                                        {openSection === 'DISCONNECTION_DETAILS' && DisconnectionDetails()}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={DOC_TABLE_COLUMN} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                        )}
                                    </CardBody>
                                </Card>
                            </div>
                        </FormikProvider>
                    </Formik>
                    <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                        <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                            <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === 2 && `rotate-class`}`} />
                        </span>
                        Page {activeIndex} of {totalPages}
                        <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                            <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                        </span>
                    </div>
                    {!isViewOnly ? (
                        <GenericFooterComponent
                            onClickNext={() => {}}
                            onSubmit={() => {}}
                            onCancel={() => navigateToDashboard()}
                            onClickPrev={() => {}}
                            stepperLength={0}
                            activeIndex={activeIndex}
                            children={renderActionBtn()}
                            isSaveBtn={false}
                            isSubmitBtn={false}
                        />
                    ) : (
                        <></>
                    )}
                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                </div>
            </div>
        </>
    );
};
export default M_WaterConnectionDisconnection;
