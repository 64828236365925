import React, { useState, useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import HeaderComponent from '../../../../components/header/Header.component';
import '../UserManagement.scss';
import { ErrorMessage, Field, Form, Formik, FormikProvider, useFormik } from 'formik';
import { EMPLOYEE_SEARCH_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchEmployeeSearchedData, getEmployeeSearchDropdownData } from '../../../../services/modulesServices/userManagement.api';
import { dropdownOptions, tableData } from '../types';
import { DASHBOARD_TABLE_COLUMNS, DASHBOARD_TABLE_COLUMNS_Filter, DASHBOARD_TABLE_COLUMNS_MD } from '../UserManagement.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { useTranslation } from 'react-i18next';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericBackButton from '../../../../components/UI components/Buttons/GenericBackButton';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import PopUps from '../../../../components/PopUps';
import { cross } from '../../../../utils/Logo';

const ApplicationSearchPage = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const module = location?.state?.module || null;
    console.log(module);
    console.log("Fin Year",getFinancialYearDates())

    const [dropdownData, setDropdownData] = useState<any>(null);
    const [tableData, setTableData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [modalDetails, setModalDetails] = useState({});
    const user = localStorage.getItem('user');
    const designation = user ? String(JSON.parse(user)?.designation) : '';

    // const searchTableColumns = designation === 'MD' ? DASHBOARD_TABLE_COLUMNS_MD : DASHBOARD_TABLE_COLUMNS;
    const searchTableColumns = designation === 'MD'? DASHBOARD_TABLE_COLUMNS_MD: (designation === 'ITM' || designation === 'DGM' || designation === 'GM')? DASHBOARD_TABLE_COLUMNS_Filter: DASHBOARD_TABLE_COLUMNS;


    const initialData = {
        applicationModules: module,
        applicationTypes: '',
        dateFrom: '',
        dateTo: '',
        applicationStatus: '',
        applicationNumber: ''
    };

    const formik = useFormik<any>({
        initialValues: initialData,
        onSubmit: async value => {
            const res = await fetchEmployeeSearchedData(value);
            if (!res.data.inboxs.length) {
                setModalDetails({ show: true, title: 'Error!', para: t('NO_DATA_FOUND', 'No data found!'), img: cross, type: 'error', reUpload: 'OK' });
            }
            setTableData(res.data.inboxs);
        },
        validationSchema: EMPLOYEE_SEARCH_VALIDATION
    });

    const { values, setValues, handleChange } = formik;
    useEffect(() => {
        const financialYear = getFinancialYearDates();
        handleOnChange('applicationModules');
        setValues({
            ...values,
            dateFrom: financialYear.startDate,
            dateTo: financialYear.endDate
        });
    }, []);

    const handleOnChange = async (fieldName: string, e: any = '') => {
        let type, searchParam;
        if (e) handleChange(e);
        switch (fieldName) {
            case 'applicationModules':
                type = 'APPLICATION_TYPE';
                searchParam = e.target?.value || values.applicationModules;
                break;
            case 'applicationTypes':
                type = 'APPLICATION_STATUS';
                searchParam = e.target?.value || values.applicationTypes;
                break;
            default:
                type = '';
                searchParam = '';
                break;
        }

        try {
            let dropdownValues = dropdownData;
            if (!e) {
                const intialResponse = await getEmployeeSearchDropdownData('MODULE_TYPE', '');
                dropdownValues = { ...dropdownValues, applicationModules: intialResponse.data.applicationModules };
            }

            if (type) {
                const { data } = await getEmployeeSearchDropdownData(type, searchParam);
                dropdownValues = data.applicationModules?.length ? { ...dropdownValues, applicationModules: data.applicationModules } : { ...dropdownValues };
                dropdownValues = data.applicationTypes?.length ? { ...dropdownValues, applicationTypes: data.applicationTypes } : { ...dropdownValues };
                dropdownValues = data.applicationStatus?.length ? { ...dropdownValues, applicationStatus: data.applicationStatus } : { ...dropdownValues };
                setDropdownData({ ...dropdownData, ...dropdownValues });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleOnReset = () => {
        setValues({ ...initialData, applicationModules: '' });
        setTableData(null);
    };

    const handleRowClick = (rowData: tableData) => {
        const url = rowData.navUrl + `&isViewOnly=true`;
        navigate(url, { state: { rowData } });
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <HeaderComponent />
            <div className='px-5 search_application_parents'>
                <div className='row mt-4'>
                    <GenericHeader text={t('SEARCH_APPLICATION', 'Search Application')} />
                    <div className='col-sm-12'>
                        {/* <Card className="card_border py-2">
                            <Card.Body>
                                <div className="d-flex">
                                    <GenericBackButton />
                                    <div className="ps-2">
                                        <Card.Text className="search_application_heading fw-bold pt-2">{t("SEARCH_APPLICATION", "Search Application")}</Card.Text>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card> */}

                        <Card className='card_border mt-4 py-2'>
                            <Card.Body>
                                {/* <Formik initialValues={SEARCH_APPLICATION_INITIAL_DATA} validationSchema={EMPLOYEE_SEARCH_VALIDATION} onSubmit={() => { }}> */}
                                <FormikProvider value={formik}>
                                    <Form>
                                        <div className='row gx-3 gy-4 px-3 py-1 '>
                                            <div className='col-sm-4 d-flex flex-column '>
                                                <label htmlFor='applicationModules' className='search_application_label'>
                                                    {t('APPLICATION_MODULE', 'Application Module')} <span className='mandatory-indicator'>*</span>
                                                </label>
                                                <Field name='applicationModules' className='form-control' as='select' onChange={(e: any) => handleOnChange('applicationModules', e)}>
                                                    <option value='' disabled selected hidden>
                                                        {t('SELECT', 'Select')}
                                                    </option>
                                                    {dropdownData?.applicationModules?.map((item: dropdownOptions) => (
                                                        <option value={item.code}>{item.name}</option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage name='applicationModules'>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                                            </div>

                                            <div className='col-sm-4 d-flex flex-column '>
                                                <label htmlFor='applicationTypes' className='search_application_label'>
                                                    {t('APPLICATION_TYPE', 'Application Type')}
                                                </label>
                                                <Field name='applicationTypes' className='form-control' as='select' onChange={(e: any) => handleOnChange('applicationTypes', e)}>
                                                    <option value='' disabled selected hidden>
                                                        {t('SELECT', 'Select')}
                                                    </option>
                                                    {dropdownData?.applicationTypes?.map((item: dropdownOptions) => (
                                                        <option value={item.code}>{item.name}</option>
                                                    ))}
                                                </Field>
                                            </div>

                                            <div className='col-sm-4 '>
                                                <div className='row gx-3'>
                                                    <label htmlFor='applicationDate' className='search_application_label'>
                                                        {t('FROM_TO_DATE', 'From-To Date')} <span className='mandatory-indicator'>*</span>
                                                    </label>
                                                    <div className='col-sm-6'>
                                                        <Field name='dateFrom' type='date' placeholder='' className='  search_application_placeholder form-control' />
                                                        <ErrorMessage name='dateFrom'>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <Field name='dateTo' type='date' placeholder='' className='form-control search_application_placeholder' />
                                                        <ErrorMessage name='dateTo'>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-sm-4 d-flex flex-column '>
                                                <label htmlFor='applicationStatus' className='search_application_label'>
                                                    {t('APPLICATION_STATUS', 'Application Status')}
                                                </label>
                                                <Field name='applicationStatus' className='form-control' as='select' onChange={(e: any) => handleOnChange('applicationStatus', e)}>
                                                    <option value='' disabled selected hidden>
                                                        {t('SELECT', 'Select')}
                                                    </option>
                                                    {dropdownData?.applicationStatus?.map((item: dropdownOptions) => (
                                                        <option value={item.code}>{item.name}</option>
                                                    ))}
                                                </Field>
                                            </div>

                                            <div className='col-sm-4 d-flex flex-column'>
                                                <label htmlFor='applicationNumber' className='search_application_label'>
                                                    {t('APPLICATION_NO', 'Application No.')}
                                                </label>
                                                <Field name='applicationNumber' type='text' placeholder='' className='form-control' />
                                            </div>

                                            <div className='col-sm-12  d-flex justify-content-end  '>
                                                {/* <button className="px-4 py-2 me-2 " type="reset" onClick={handleOnReset}>
                                                    {t("RESET", "Reset")}
                                                </button>
                                                <button className="px-4 py-2 " style={{ backgroundColor: "#45852E", color: "white" }} type="submit">
                                                    {t("SUBMIT", "Submit")}
                                                </button> */}
                                                <GenericButtons text={t('RESET', 'Reset')} ClassName='me-3' type='reset' handleClick={handleOnReset} />
                                                <GenericButtons text={t('SUBMIT', 'Submit')} variant='primary' ClassName='me-3' type='submit' />
                                            </div>
                                        </div>
                                    </Form>
                                </FormikProvider>
                                {/* </Formik> */}
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                {tableData?.length ? (
                    <div className=' rounded-5 bg-white mt-4 px-4 py-4' style={{ border: '1px solid rgba(0, 0, 0, 0.175)' }}>
                        <p style={{ fontSize: '20px', color: '#45852E' }} className='fw-bold m-0'>
                            {t('SEARCH_RESULT', 'Search Resul')}
                        </p>
                        <GenericActionTable columns={searchTableColumns} rowData={tableData} additionalMethod={handleRowClick} searchBarPlaceholder='SEARCH_BY'></GenericActionTable>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};
function getFinancialYearDates() {
    const today = new Date();
    const year = today.getFullYear();
 
    // Determining the start and end years of the financial year
    const startYear = today.getMonth() >= 3 ? year : year - 1;
    const endYear = startYear + 1;
 
    // Generating the start and end dates
    const startDate = new Date(startYear, 3, 1);
    const endDate = new Date(endYear, 2, 31);
 
    // Format the dates as yyyy-MM-dd
    const formatDate = (date:any) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
 
    return { 
        startDate: formatDate(startDate), 
        endDate: formatDate(endDate) 
    };
}

export default ApplicationSearchPage;
