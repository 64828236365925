import React, { useEffect, useState } from 'react';
import ScrollToTop from '../../../components/ScrollToTop';
import { Card, Form } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from 'formik';
import SideCard from '../../../components/moduleCommonFormik/SideCardGenericComponent';
import GenericHeader from '../../../components/moduleCommonFormik/GenericHeader';
import PopUps from '../../../components/PopUps';
import HeaderComponent from '../../../components/header/Header.component';
import Loading from '../../../components/portalComponents/Loading.component';
import { useTranslation } from 'react-i18next';
import trash from '../../../assets/logos/greenTrash.svg';
import {
    MORTGAGE_CHANGES_FINANCIAL_DETAILS,
    MORTGAGE_EXISTING_MORTGAGE_DETAILS,
    MORTGAGE_STEPS_DETAILS,
    deedExtensionField,
    MORTGAGE_PAYLOAD,
    setExistingMortgageDetails,
    MORTGAGE_DECLARATION_LABEL,
    bankDetailsInitialValue
} from './Mortgage.const';
import GenericFooterComponent from '../../../components/moduleCommonFormik/GenericFooter.component';
import GenericFormCard from '../../../components/moduleCommonFormik/GenericFormCard.component';
import { dropdownDataOptions, getEntityDocumentDetails } from '../Migration/Migration.controller';
import PlotDetails from '../Migration/pages/PlotDetails';
import { devUrl, endpoints, estateEndpoints, getProgressBarData, POST, POST2, requestInfo } from '../../../services/modulesServices/userManagement.api';
import { DropdownData } from '../EstateManagement/Allotment/AllotmentPage';
import Declaration from '../EstateManagement/Allotment/Declaration';
import { DECLARATION_LABEL, PLOT_DETAILS_TABLE } from '../EstateManagement/SubLease/SubLeaseConst';
import GenericFileUploadField from '../../../components/moduleCommonFormik/GenericFileUpload.component';
import PaymentDetails from '../EstateManagement/Allotment/PaymentDetails';
import GenericActionTable from '../../../components/moduleCommonFormik/GenericActionTable';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/authSlice';
import { EMPLOYEE_AUTHORIZED, ENTERPRISE, KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../../utils/constants';
import GenericButtons from '../../../components/UI components/Buttons/GenericButtons';
import { check, cross } from '../../../utils/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import GenericCommonTextField from '../../../components/moduleCommonFormik/GenericCommonTextField.component';
import _ from 'lodash';
import { fetchFeeData, fetchGatewayData, fetchLeaseDurationDoc, getOptions, getQueryParams, loadRazorpay, paymentCheck } from '../EstateManagement/EstateManagement.controller';
import { convertDateFormatddMMyyyytoyyyyMMdd, entityDocumentData } from '../EstateManagement/LeaseDeed/LeaseDeed.controller';
import axios from 'axios';
import { MORTGAGE_VALIDATION } from '../../../utils/formikValidation/FormikValidation';
import { RazorpayOptions } from '../UserManagement/types';
import { changeDateFormatToddMMyyyy, changeDateFormatToIST, getDiffBWDates, getFirstErrorKey } from '../../../utils/utils';
import { toast } from 'react-toastify';
import PaymentSubmissionStatus from '../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../components/moduleCommonFormik/ProgressBar';
import GenericPopupModal from '../../../components/moduleCommonFormik/GenericModalPopup.component';
import { EsignModal } from '../../commonPages/EsignModal';

const MortgageComponent = () => {
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const { t } = useTranslation();
    const [eSignedDocumentData, setESignedDocumentData] = useState<any>(null);
    const [isChecked, setIsChecked] = useState([false, false, false]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [isPaid, setIsPaid] = useState<any>(false);
    const [paymentData, setPaymentData] = useState<any>([]);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [tableRowData, setTableRowData] = useState([]);
    const [visited, setVisited] = useState(false);
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);
    const [entityDetails, setEntityDetails] = useState<any>({});
    const [time, setTime] = useState<any>(0);
    const [resData, setResData] = useState<any>([]);
    const [applicationNo, setApplicationNo] = useState<any>();
    const [documents, setDocuments] = useState<any>([]);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [ifscData, setIfscData] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>(null);

    // const [bankDetailsRes,setBankDetailsRes] = useState([])
    // const [existingMortgageDetails,setExistingMortgageDetails] = useState<any>([])

    const location = useLocation();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = user?.type;
    const searchParams: any = new URLSearchParams(location.search);
    let navigate = useNavigate();

    const userData = useSelector(selectUser);
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    const userUuid = JSON.parse(localStorage.getItem('user') || '{}')?.uuid;
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const applicationNumber = searchParams.get('applicationNo');
    const applicationNumb = searchParams.get('applicationNumber');
    const queryParams = getQueryParams(searchParams);

    // const data = location?.state?.data;
    const [dropdownDataPlot, setDropdownDataPlot] = useState<DropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });

    const formik = useFormik<any>({
        initialValues: { isUpdatable: true, bankDetails: [bankDetailsInitialValue], esignOption: 'DSC' },
        onSubmit: (value: any) => {},
        validationSchema: MORTGAGE_VALIDATION[`page${activeIndex}`]
    });
    useEffect(() => {
        if (userType !== 'ENTERPRISE') {
            window.location.href = '/goa-idc/access-restricted';
        }

        if (applicationNumb) {
            (async () => {
                let applicationAccess;
                try {
                    applicationAccess = await POST(endpoints?.applicationAccess + `?businessid=${applicationNumb}`, { RequestInfo: requestInfo() });
                    if (!applicationAccess?.data.isAuthorized) {
                        window.location.href = '/goa-idc/access-restricted';
                    }
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }

        dropdownDataOptions()
            .then((dropdownOption: any) => {
                setDropdownData(dropdownOption);
            })
            .catch(error => {});
    }, []);

    const { values, setValues, setFieldTouched, errors, setFieldValue } = formik;

    const onPlotSelect = (selectedPlot: any) => {
        setSelectedPlotDataId(selectedPlot.uuid);
        setValues((prev: any) => ({ ...prev, plotUuid: selectedPlot.uuid }));
    };

    // setting plot columns with funtion
    const plotDetailsTableColumns = PLOT_DETAILS_TABLE.map((val: any, index: number) => {
        if (index === 0) {
            return {
                ...val,
                onRadioClick: onPlotSelect,
                selectedValue: selectedPlotDataId
            };
        }
        return val;
    });

    const setPlotDetailsInTable = async (plotIds: any) => {
        const { data: plotData } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

        const plotRowData = plotData?.plots?.map((value: any) => ({
            estateName: value?.estateDetails?.estateName,
            plotType: value?.plotType,
            phase: value?.phase,
            plotNo: value?.plotNo,
            plotAreaSqm: value?.plotAreaSqm,
            uuid: value?.uuid,
            id: value?.uuid
        }));
        setTableRowData(plotRowData);
    };

    // to show plot details in table
    useEffect(() => {
        if (resData?.estateApplications?.status === 'DRAFT' || !applicationNumb) {
            const getPlotData = async () => {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.entityRefDetails + `?entityIds=${entityId}&types=${'LESSEE'}`, { RequestInfo: requestInfo() });
                    if (data?.entityRefDetails.length > 0) {
                        let getPlotIds = data?.entityRefDetails.map((data: any) => data?.plotId);
                        const plotIds = getPlotIds.join(',');
                        if (!getPlotIds) return;
                        await setPlotDetailsInTable(plotIds);
                    }
                } catch (e: any) {}
                setIsLoading(false);
            };
            getPlotData();
        }
    }, []);

    const getTransactionUpdate = async (params: any) => {
        try {
            if (params.plotId) {
                const paid: any = await paymentCheck('PROCESSING_FEE', params.plotId, params.applicationId || resData?.estateApplications?.uuid);
                if (!paid.isPaid) {
                    const payload = { RequestInfo: requestInfo() };
                    const { data } = await POST2(endpoints.transactionUpdate, payload, { eg_pg_txnid: params.eg_pg_txnid });
                    if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                        setTransactionDetails(data?.Transaction?.[0]);
                        if (data?.Transaction?.[0]?.txnId) {
                            try {
                                const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                                if (resp.data.status === 'SUCCESSFUL') {
                                    setModalDetails({ show: true, title: 'Success!', para: 'Payment successful', img: check, type: 'Payment success', reUpload: 'OK' });
                                    setIsPaid(true);
                                } else {
                                    setIsPaid(false);
                                }
                            } catch (e: any) {
                                setIsPaid(false);
                                setPaymentIsLoading(false);
                            }
                        }
                    } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                        setModalDetails({
                            show: true,
                            title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                            para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                            img: cross,
                            type: 'error',
                            reUpload: 'OK'
                        });
                        // window.location.reload();
                    } else {
                        setIsPaid(false);
                    }
                }
            }
            setIsLoading(false);
            setPaymentIsLoading(false);
        } catch (e: any) {
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Transaction Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    // payment fetching

    useEffect(() => {
        (async () => {
            try {
                if (selectedPlotDataId && activeIndex === 4) {
                    const payCheck = await paymentCheck('PROCESSING_FEE', selectedPlotDataId, resData?.estateApplications?.uuid);
                    setIsPaid(payCheck?.isPaid);
                    setPaymentTried(payCheck?.paymentTried);
                    setPaymentData(payCheck?.paymentData);
                    setTotalPaidAmount(payCheck?.totalPaidAmount);
                    setTransactionDetails(payCheck?.transactionDetails);

                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);

                    const feeData = await fetchFeeData('MORTGAGE', `PROCESSING_FEE`, selectedPlotDataId);
                    setPaymentData(feeData?.feeMasterDetails);
                    setTotalPaidAmount(feeData?.totalPaidAmount);
                }
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                setPaymentIsLoading(false);
            }
        })();
    }, [activeIndex, selectedPlotDataId]);

    useEffect(() => {
        const fetchESignStatus = async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNo || applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                const { data } = await POST(endpoints.consolidatedDocSearch, payload);
                data.filestoreId && setESignedDocumentData(data);
                !data.isEsignRequired && setIsChecked([true, true, true]);
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };
        if (activeIndex === 3 && !signedFileStoreId) {
            fetchESignStatus();
        }
    }, [activeIndex]);

    useEffect(() => {
        (async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNo || applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                if (signedFileStoreId) {
                    const { data } = await POST(endpoints.consolidatedDocESign, payload);
                    // setESignedDocumentData(data);
                    const consolidatedDocSearch = await POST(endpoints.consolidatedDocSearch, payload);
                    consolidatedDocSearch?.data.filestoreId && setESignedDocumentData(consolidatedDocSearch?.data);
                    !data.isEsignRequired && setIsChecked([true, true, true]);
                }
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [signedFileStoreId]);

    const signDocument = async () => {
        let url = window.location.origin + `/goa-idc/estate/mortgage?activeIndex=${activeIndex}&applicationNo=${applicationNo}`;
        url = btoa(url);

        if (values.esignOption === 'EKYC') {
            const userRequestInfo = requestInfo();
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${eSignedDocumentData.filestoreId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${eSignedDocumentData.filestoreId}&callbackUrl=${url}`);
        }
    };

    const handleClickSignDocument = () => {
        if (activeIndex === 3 && !isChecked.every((e: boolean) => e)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setEsignModal(true);
    };

    useEffect(() => {
        loadRazorpay();
    }, []);

    useEffect(() => {
        if (!!applicationNumb) {
            getProgressBarData(applicationNumb)
                .then(res => setProgressBarDetails(res.data))
                .catch(e => console.log(e));
        }
    }, []);
    const status: any = progressBarDetails?.progressStatus?.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: applicationNumb,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };
    const renderPlotTable = () => {
        return (
            <>
                {!!applicationNumb && applicationData.status !== 'Draft' && (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <PaymentSubmissionStatus data={applicationData} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                        </Card.Body>
                    </Card>
                )}
                <Card className='card_border'>
                    <Card.Body>
                        <div className='ps-2 py-2 d-flex justify-content-between'>
                            <div className='formTitle m-0'>{'Plot Details'}</div>
                        </div>
                        <GenericActionTable rowData={tableRowData} columns={plotDetailsTableColumns} searchBarPlaceholder={'SEARCH_BY_PLOT'} />
                    </Card.Body>
                </Card>
            </>
        );
    };
    useEffect(() => {
        const leaseDeedDoc = async () => {
            let data = selectedPlotDataId && (await POST(endpoints.leaseDeedSearch + `?entityIds=${entityId}&plotIds=${selectedPlotDataId}`, { RequestInfo: requestInfo() }));
            let deedDoc: any;
            deedDoc = data?.data?.deedDetails[0]?.executedIndentureRefId && (await getEntityDocumentDetails({ docReferenceId: data?.data?.deedDetails[0]?.executedIndentureRefId }));
            setValues((prev: any) => ({ ...prev, lesseeDeedOfExtension: deedDoc }));
        };
        leaseDeedDoc();
    }, [selectedPlotDataId]);

    const handleSearchIFSCMortgage = (e: any, index: number) => {
        if (e.length === 11) {
            if (time) clearTimeout(time);
            setTime(
                setTimeout(async () => {
                    try {
                        const { data } = await POST(`${endpoints.getBankDetails}?ifsc=${e}`, { RequestInfo: requestInfo() });
                        setIfscData(data);
                        setValues((prev: any) => ({
                            ...prev,
                            bankDetails: values?.bankDetails.map((item: any, i: number) => (i === index ? { ...item, bankIFSC: e, bankName: data.bank, branchAddress: data?.address } : item))
                        }));
                    } catch (error) {
                        setValues((prev: any) => ({
                            ...prev,
                            bankDetails: values?.bankDetails.map((item: any, i: number) => (i === index ? { ...item, bankIFSC: e, bankName: '', branchAddress: '' } : item))
                        }));
                    }
                }, 800)
            );
        } else {
            setValues((prev: any) => ({
                ...prev,
                bankDetails: values.bankDetails.map((item: any, i: number) => (i === index ? { ...item, bankIFSC: e, bankName: '', branchAddress: '' } : item))
            }));
        }
    };
    const existingMortgageDetails =
        resData?.estateApplications?.status !== 'DRAFT'
            ? MORTGAGE_EXISTING_MORTGAGE_DETAILS.map((val: any) => ({
                  ...val,
                  formData: [
                      ...val.formData.map((value: any) => ({
                          ...value,
                          isDisabled: true
                      }))
                  ]
              }))
            : MORTGAGE_EXISTING_MORTGAGE_DETAILS;

    const MortgageChargeDetails = () => {
        values.loansSanctioned = values?.bankDetails?.length;

        return (
            <>
                <Card className='card_border p-3'>
                    <Card.Body className='col-6'>
                        <GenericFileUploadField title={deedExtensionField.title} field={deedExtensionField.field} isDisabled={true} />
                    </Card.Body>
                </Card>
                <Card className='card_border '>
                    <Card.Body>
                        <div className='formTitle m-0 p-3'>{t('FINANCIAL_DETAILS', 'Details of Financial Institution/Bank')}</div>
                        <div className='col-6 p-3'>
                            <GenericCommonTextField field='loansSanctioned' title='No of Financial Institution/Banks from where loan(s) is(are) sanctioned' isDisabled={true} />
                        </div>
                        <form>
                            <FieldArray name='bankDetails'>
                                {({ insert, remove, push }) => (
                                    <div>
                                        {values.bankDetails?.length > 0 &&
                                            values.bankDetails.map((data: any, bank_index: number) => (
                                                <div className='row' key={bank_index}>
                                                    <div className='col-sm-12 align-items-center pb-2'>
                                                        <div className='formHeading'>{`Financial Institution/Bank - ${bank_index + 1}`}</div>
                                                        <div className=' ps-1'>
                                                            {bank_index !== 0 && (
                                                                <div className='d-flex justify-content-end  mx-3'>
                                                                    <img
                                                                        style={{ cursor: 'pointer', zIndex: 1, background: '#45852E1F', padding: '6px', height: '35px', borderRadius: '6px' }}
                                                                        onClick={() => remove(bank_index)}
                                                                        src={trash}
                                                                        alt='delete'
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='row d-flex m-0'>
                                                            {MORTGAGE_CHANGES_FINANCIAL_DETAILS[0]?.formData.map((formField: any, id: number) => (
                                                                <div className={`py-3 col-sm-6`} key={id}>
                                                                    <label htmlFor={`bankDetails.${bank_index}.${formField.field}`} className='pb-2 file-form-label'>
                                                                        {formField.title} - {bank_index + 1}
                                                                        {formField.isRequired && <span className='mandatory-indicator'> *</span>}
                                                                    </label>

                                                                    <Field
                                                                        name={`bankDetails.${bank_index}.${formField.field}`}
                                                                        type={'text'}
                                                                        id={`bankDetails.${bank_index}.${formField.field}`}
                                                                        className={` form-control was-validated`}
                                                                        onChange={(e: any) => {
                                                                            const newValue =
                                                                                formField.type === 'number'
                                                                                    ? parseInt(e.target.value)
                                                                                    : formField.field === 'bankIFSC'
                                                                                    ? e.target.value.toUpperCase()
                                                                                    : e.target.value;
                                                                            setFieldValue(`bankDetails.${bank_index}.${formField.field}`, newValue);
                                                                            formField.field === 'bankIFSC' && handleSearchIFSCMortgage(newValue, bank_index);
                                                                        }}
                                                                        value={_.get(values, `bankDetails.${bank_index}.${formField.field}`)}
                                                                        disabled={
                                                                            values.bankDetails[bank_index].bankIFSC?.length >= 11 &&
                                                                            values.bankDetails[bank_index].bankName === '' &&
                                                                            ifscData !== null &&
                                                                            ifscData?.bank === ''
                                                                                ? false
                                                                                : resData?.estateApplications?.status !== 'DRAFT'
                                                                                ? true
                                                                                : values.bankDetails[bank_index].bankIFSC?.length >= 11 && values.bankDetails[bank_index].bankName === ''
                                                                                ? false
                                                                                : formField.isDisabled
                                                                        }
                                                                    />
                                                                    <ErrorMessage name={`bankDetails.${bank_index}.${formField.field}`}>
                                                                        {msg => <span className='text-danger font-12'>{msg}</span>}
                                                                    </ErrorMessage>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        {resData?.estateApplications?.status === 'DRAFT' && (
                                            <div className='ms-3'>
                                                <button
                                                    className='col-sm-12 mt-2 mb-4  justify-content-center align-items-center noc_add_fields'
                                                    type='button'
                                                    onClick={() => push(bankDetailsInitialValue)}
                                                >
                                                    + Add Bank
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </FieldArray>
                        </form>
                    </Card.Body>
                </Card>

                {values?.existingMortgageDetails?.length > 0 && (
                    <Card className='card_border '>
                        <Card.Body>
                            <div className='formTitle m-0 p-3'>Existing Mortgage Details</div>

                            <form>
                                <FieldArray name='existingMortgageDetails'>
                                    {({ insert, remove, push }) => (
                                        <div>
                                            {values.existingMortgageDetails?.length > 0 &&
                                                values.existingMortgageDetails.map((data: any, index_p: number) => (
                                                    <div className='row' key={index_p}>
                                                        <div className='col-sm-12  align-items-center pb-2'>
                                                            <div className='formHeading'>{`Financial Institution/Bank - ${index_p + 1}`}</div>
                                                            <div className=' ps-1'>
                                                                {existingMortgageDetails.map((val: any, _: number) => (
                                                                    <GenericFormCard
                                                                        fieldPath={`existingMortgageDetails.${index_p}`}
                                                                        {...val}
                                                                        // onChangeAction={val?.formData?.map((formField: any, ind: number) => formField)}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </FieldArray>
                            </form>
                        </Card.Body>
                    </Card>
                )}
            </>
        );
    };

    // for getting enterprise details
    useEffect(() => {
        const getEntityDetails = async () => {
            const { data } = await POST(endpoints.searchExistingUser + `?tenantId=ga&userUuid=${userUuid}`, { RequestInfo: requestInfo() });
            setEntityDetails(data?.enterpriseEntity[0]);
        };
        getEntityDetails();
    }, [userUuid]);

    const onChangeNextIndex = async () => {
        const stepperLength = MORTGAGE_STEPS_DETAILS.length;
        try {
            setIsLoading(true);
            if (activeIndex < stepperLength && selectedPlotDataId !== null) {
                // await MORTGAGE_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
                setIsLoading(false);
            }
            setIsLoading(false);
        } catch (e) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            setIsLoading(false);
        }
    };

    // checkbox declaration
    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    // existing data search for bank details
    const mortgageSearchData = async () => {
        const { data } = await POST(endpoints.searchMortgage + `?entityIds=${entityId}&plotIds=${selectedPlotDataId}&statusList=OPEN`, {
            RequestInfo: requestInfo()
        });
        console.log(data);
        // setExistingMortgageDetails(data?.MortgageDetails)
        if (data && data?.MortgageDetails?.length) {
            let formData = await Promise.all(data?.MortgageDetails?.map(async (item: any) => await setExistingMortgageDetails(item, 'CURRENT')));
            formData = formData.flat().map(obj => obj);
            console.log('formdata', formData);
            const updatedValues = { existingMortgageDetails: formData };
            // console.log(updatedValues);
            setValues((prev: any) => ({ ...prev, existingMortgageDetails: formData }));
        }
    };

    // while paying
    const onPay = async () => {
        setPaymentIsLoading(true);
        let url =
            window.location.origin +
            `/goa-idc/estate/mortgage?plotId=${selectedPlotDataId}&activeIndex=${activeIndex}&applicationNo=${applicationNo}&applicationId=${resData?.estateApplications?.uuid}`;
        const periodTo = new Date(resData?.entityRefDetails?.auditDetails?.createdTime).setFullYear(new Date(resData?.entityRefDetails?.auditDetails?.createdTime).getFullYear() + 1);

        // const oneYearLaterDate = new Date(allotmentDate);
        // oneYearLaterDate.setFullYear(oneYearLaterDate.getFullYear() + 1);
        // const periodTo = oneYearLaterDate.getTime();
        const payload = {
            RequestInfo: requestInfo(),
            applicationNumber: applicationNo || applicationNumber,
            plotId: selectedPlotDataId,
            totalPaidAmount: +totalPaidAmount,
            callbackUrl: url,
            productInfo: 'Allotment Application Fees',
            gateway: selectedGateway,
            periodFrom: resData?.entityRefDetails?.auditDetails?.createdTime,
            periodTo: periodTo,
            payNowPaymentDetails: paymentData.map((obj: any) => ({
                scheduledPaymentDetails: {
                    paymentType: obj.feeType,
                    principalAmount: obj.principalAmount,
                    cgstPrincipal: obj.cgstPrincipal,
                    sgstPrincipal: obj.sgstPrincipal,
                    totalDueAmount: ((+obj.principalAmount || 0) + (+obj.cgstPrincipal || 0) + (+obj.sgstPrincipal || 0)).toFixed(2),
                    paidAmount: ((+obj.principalAmount || 0) + (+obj.cgstPrincipal || 0) + (+obj.sgstPrincipal || 0)).toFixed(2)
                }
            })),
            applicationId: resData?.estateApplications?.uuid
        };
        try {
            if (!totalPaidAmount) {
                setPaymentIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (!selectedGateway) {
                setPaymentIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data } = await POST(endpoints.paymentGateway, payload);

                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, user, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data.additionalDetails?.walletClientCode}&walletRequestMessage=${data.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setPaymentIsLoading(false);
            setModalDetails({ show: true, title: t('PAYMENT_FAILED', 'Payment Initiation Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    // application creation, updation and submit
    const lastSubmit = (isSaveOnly?: boolean, status?: string, comment?: string) => {
        if (!isPaid) onPay();
        else onSubmit(isSaveOnly, status, comment);
    };

    // application creation and updation
    const onSubmit = async (isSaveOnly?: boolean, status?: any, comment?: string) => {
        const isFinalSubmit = activeIndex === MORTGAGE_STEPS_DETAILS?.length ? true : false;
        const payload = MORTGAGE_PAYLOAD(activeIndex, values, isFinalSubmit, selectedPlotDataId, entityDetails, resData, applicationNo);
        let data: any;

        if (activeIndex === 3 && eSignedDocumentData?.isEsignRequired) {
            setModalDetails({ show: true, title: 'E-Sign Pending', para: 'Please sign the document', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            await MORTGAGE_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });

            if (activeIndex === 1 && selectedPlotDataId === null) {
                setModalDetails({ show: true, title: 'Error!', para: t('SELECT_PLOT', 'Please Select The plot!'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            try {
                setIsLoading(true);
                if (!applicationNo && selectedPlotDataId !== null) {
                    data = await POST(endpoints.createMortgage, payload);
                    // setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: t('APPLICATION_CREATED', 'Application Created'), img: check, type: 'create success', reUpload: 'OK' });
                    toast(`${t('APPLICATION_CREATED_SUCCESSFULLY', 'Application Created Successfully!')}`, {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                    setIsLoading(false);
                } else {
                    data = await POST(endpoints.updateMortgage, payload);
                    setIsLoading(false);
                }
                setIsLoading(false);
                !isSaveOnly && onChangeNextIndex();
            } catch (e: any) {
                let errMsg = e?.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                setIsLoading(false);
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
            if (data) {
                mortgageSearchData();
                setResData(data?.data?.MortgageDetails);
                setApplicationNo(data?.data?.MortgageDetails?.estateApplications?.applicationNo);

                if (isFinalSubmit) {
                    setModalDetails({
                        show: true,
                        title: 'Success!',
                        para: `Application${applicationNo ? ' Number ' + applicationNo : ''} for Mortgage submitted successfully`,
                        finalSubmit: true,
                        img: check,
                        type: 'success',
                        reUpload: 'OK',
                        navigateUrl: '/goa-idc/enterprisedashboard'
                    });
                }
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                await MORTGAGE_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const onChangePrevIndex = () => {
        const stepperLength = MORTGAGE_STEPS_DETAILS.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };
    useEffect(() => {
        if (applicationNumber || applicationNumb) {
            (async () => {
                try {
                    setPaymentIsLoading(true);
                    queryParams?.['activeIndex'] && setActiveIndex(+queryParams?.['activeIndex']);
                    if (applicationNumber || applicationNumb) {
                        const { data } = await POST(endpoints.searchMortgage + `?applicationNos=${applicationNumber || applicationNumb}`, { RequestInfo: requestInfo() });
                        await setPlotDetailsInTable(data?.MortgageDetails[0]?.estateApplications?.appliedPlotDetails?.uuid);
                        setSelectedPlotDataId(data?.MortgageDetails[0]?.estateApplications?.appliedPlotDetails?.uuid);
                        setApplicationNo(data?.MortgageDetails[0]?.estateApplications?.applicationNo);
                        setResData(data?.MortgageDetails[0]);
                        setIsChecked([true, true, true]);
                        setFileStoreId(data?.MortgageDetails[0]?.estateApplications?.signedConsolidatedDocRefId);
                        const currentStatusBankDetails = data?.MortgageDetails[0]?.bankMortgageDetails
                            ?.filter((val: any) => val?.status === 'CURRENT')
                            .map((value: any) => ({
                                bankIFSC: value?.bankIFSC,
                                bankName: value?.bankDetailsResponse?.bank,
                                branchAddress: value?.bankDetailsResponse?.branch
                            }));

                        const uniqueBankDetails = currentStatusBankDetails.reduce((acc: any[], current: any) => {
                            const x = acc.find(item => item.bankIFSC === current.bankIFSC);
                            if (!x) {
                                return acc.concat([current]);
                            } else {
                                return acc;
                            }
                        }, []);

                        const existingStatusBankDetails = await Promise.all(
                            data?.MortgageDetails[0]?.bankMortgageDetails
                                ?.filter((val: any) => val?.status === 'EXISTING')
                                .map(async (value: any) => {
                                    const exisNocDocument = value?.nocRefId && (await entityDocumentData(value?.nocRefId));
                                    console.log(exisNocDocument[0]);
                                    return {
                                        existingIfscCode: value?.bankIFSC,
                                        existingBankName: value?.bankDetailsResponse?.bank,
                                        existingBranchAddress: value?.bankDetailsResponse?.branch,
                                        existingDateOFNoc: value?.nocDate,
                                        existingNocDocument: exisNocDocument && exisNocDocument?.[0]
                                    };
                                })
                        );
                        console.log('locat', existingStatusBankDetails);

                        setValues((prev: any) => ({
                            ...prev,
                            bankDetails: uniqueBankDetails,
                            existingMortgageDetails: existingStatusBankDetails
                        }));
                        console.log(values);
                    }
                    if (queryParams?.['applicationNo'] && queryParams?.['eg_pg_txnid']) {
                        getTransactionUpdate(queryParams);
                    } else {
                        setPaymentIsLoading(false);
                    }
                } catch (e: any) {
                    setPaymentIsLoading(false);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [location.search]);
    console.log(values);

    return (
        <>
            <>
                <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} userType={userType} />
                <HeaderComponent />
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                            <GenericHeader
                                text={t('APPLICATION_FOR_MORTGAGE_OR_CREATION_OF_CHARGE', 'Application for Mortgage or Creation of Charge')}
                                bottomSubText={applicationNo && `Type : Mortgage, No: ${applicationNo}`}
                                additionalClass='sub-text-style'
                            />
                            <div className='col-3 stickyCard-section'>
                                <div className='stickyCard' style={{ top: '110px' }}>
                                    <GenericHeader
                                        text={t('APPLICATION_FOR_MORTGAGE_OR_CREATION_OF_CHARGE', 'Application for Mortgage or Creation of Charges')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={applicationNo && `Type : Mortgage, No: ${applicationNo}`}
                                    />
                                    <Card className='card_border p-3'>
                                        <Card.Body>
                                            <Card.Text className=' d-flex flex-column  row-gap-3'>
                                                <SideCard steps={MORTGAGE_STEPS_DETAILS} visited={visited} activeIndex={activeIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className='col-9 form-section'>
                                <FormikProvider value={formik}>
                                    <Form>
                                        {/* {activeIndex === 1 && lesseeDetails()} */}

                                        {activeIndex === 1 && renderPlotTable()}
                                        {activeIndex === 2 && MortgageChargeDetails()}

                                        {activeIndex === 3 && (
                                            <Declaration
                                                isChecked={isChecked}
                                                declarationList={MORTGAGE_DECLARATION_LABEL}
                                                handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                                                setDocuments={setDocuments}
                                                signDocument={handleClickSignDocument}
                                                eSignedDocumentData={eSignedDocumentData}
                                                applicantName={'Lessee'}
                                                fileStoreId={fileStoreId}
                                            ></Declaration>
                                        )}

                                        {activeIndex === 4 && (
                                            <PaymentDetails
                                                paymentData={paymentData}
                                                selectedGateway={selectedGateway}
                                                setSelectedGateway={setSelectedGateway}
                                                isPaid={isPaid}
                                                gatewayData={gatewayData}
                                                transactionDetails={transactionDetails}
                                                title={t('APPLICATION_FEES', 'Application Fees')}
                                            ></PaymentDetails>
                                        )}
                                    </Form>
                                    <GenericPopupModal
                                        title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                        primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                        secondaryBtnTitle={t('CLOSE', 'Close')}
                                        isSecondaryBtn={true}
                                        secondaryAction={() => setEsignModal(false)}
                                        primaryAction={signDocument}
                                        isVisible={esignModal}
                                        children={<EsignModal />}
                                    />
                                </FormikProvider>
                            </div>
                        </div>
                        <ScrollToTop />
                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                    {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                                </div>
                                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                    {activeIndex !== MORTGAGE_STEPS_DETAILS.length && activeIndex !== 4 && activeIndex !== 1 && (
                                        <GenericButtons text={t('SAVE', 'Save')} handleClick={() => onSubmit(true)} ClassName='me-3' />
                                    )}
                                    <GenericButtons
                                        text={
                                            activeIndex !== MORTGAGE_STEPS_DETAILS.length
                                                ? activeIndex === 1
                                                    ? ` Next`
                                                    : `Save & Next`
                                                : isPaid === null
                                                ? ''
                                                : !isPaid
                                                ? paymentTried
                                                    ? `Retry Payment`
                                                    : `Pay Now`
                                                : t('SUBMIT', 'Submit')
                                        }
                                        variant='primary'
                                        handleClick={() => (activeIndex !== MORTGAGE_STEPS_DETAILS.length ? onSubmit() : lastSubmit && lastSubmit())}
                                    />
                                </div>
                            </div>
                        </footer>
                    </>
                )}
            </>
        </>
    );
};

export default MortgageComponent;
