import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import ScrollToTopButton from '../../../../components/ScrollToTop';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import HeaderComponent from '../../../../components/header/Header.component';
import { USER_DETAILS, MENU_DETAILS, PLOT_DETAILS_TABLE_LD } from './Extension.const';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { POST, endpoints, estateEndpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { cross, check } from '../../../../utils/Logo';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { EXTENSION_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { EMPLOYEE_AUTHORIZED, TENANT_ID } from '../../../../utils/constants';
import { convertDateFormatddMMyyyytoyyyyMMdd } from '../LeaseDeed/LeaseDeed.controller';
import { getExtensionInitialData } from './ExtensionController';
import { getNextActions } from '../EstateManagement.controller';
import Loading from '../../../../components/portalComponents/Loading.component';
import { changeDateFormatToIST } from '../../../../utils/utils';

export default function Extension() {
    const [plotRowData, setPlotRowData] = useState<any[]>([]);
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);
    const [selectedPlotData, setSelectedPlotData] = useState<any>(null);
    const navigate = useNavigate();
    const [dropdownData, setDropdownData] = useState<any>({
        extensionType: []
    });
    const [modalDetails, setModalDetails] = useState<any>({});
    const [enterpriseEntity, setEnterpriseEntity] = useState<any>(null);
    const [filteredPlotRowData, setFilteredPlotRowData] = useState<any>(null);
    const location: any = useLocation();
    const plotId = location?.state?.plotId || null;
    const type = location?.state?.type || null;
    const [searchParams] = useSearchParams();
    const applicationNumber = searchParams.get('applicationNumber');
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isApplicationAccessible, setIsApplicationAccessible] = useState<boolean>(true);

    const { t } = useTranslation();

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = user?.type;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {},
        validationSchema: EXTENSION_VALIDATION
    });

    const { values, setValues } = formik;

    const menuDetails = MENU_DETAILS?.map((val: any) => ({ ...val, formData: val.formData?.map((item: any) => ({ ...item, isDisabled: !hasValidAction })) }));
    const userDetails = USER_DETAILS?.map((val: any) => ({ ...val, formData: val.formData?.map((item: any) => ({ ...item, isDisabled: !hasValidAction })) }));

    const onPlotSelect = (selectedPlot: any) => {
        console.log('selected plot', selectedPlot);
        setSelectedPlotDataId(selectedPlot.id);
        setSelectedPlotData(selectedPlot);
        setValues((prevValues: any) => ({
            ...prevValues,
            allotmentId: selectedPlot.allotmentId,
            oldExpiryDate: convertDateFormatddMMyyyytoyyyyMMdd(selectedPlot.expiryDate),
            applicationSubType: selectedPlot.applicationSubType
        }));
    };

    useEffect(() => {
        console.log('type', type, 'plotRowData', plotRowData);
        if (plotRowData?.length) {
            if (type === 'ALLOTMENT') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'ALLOTMENT'));
                setValues({ ...values, extensionType: { code: 'Lease Deed Execution/Payment', name: 'Lease Deed Execution/Payment' } });
            } else if (type === 'SPECIAL_TRANSFER') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'SPECIAL_TRANSFER'));
                setValues({ ...values, extensionType: { name: 'Special Transfer Order', code: 'Special Transfer Order' } });
            } else if (type === 'PROVISIONAL_SUB_LEASE') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'PROVISIONAL_SUB_LEASE'));
                setValues({ ...values, extensionType: { code: 'Provisional Sub-Lease Order', name: 'Provisional Sub-Lease Order' } });
            } else if (type === 'TRANSFER') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'TRANSFER'));
                setValues({ ...values, extensionType: { name: 'Transfer Order', code: 'Transfer Order' } });
            } else if (type === 'MORTGAGE') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'MORTGAGE'));
                setValues({ ...values, extensionType: { name: 'Mortgage NOC Order', code: 'Mortgage NOC Order' } });
            } else if (type === 'CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE'));
                setValues({ ...values, extensionType: { name: 'Change in Name/Trade/Owner Order', code: 'Change in Name/Trade/Owner Order' } });
            }
        }
    }, [type, plotId, plotRowData]);

    useEffect(() => {
        const calculateWorkingDays = async () => {
            let payload = {
                RequestInfo: requestInfo(),
                startDate: values.oldExpiryDate?.split('-').reverse().join('/'),
                numberOfDays: values.extensionType?.code === 'Lease Deed Execution/Payment' ? 15 : 0
            };
            try {
                const { data } = await POST(endpoints.calculateWorkingDays, payload);
                setValues({ ...values, expiryDate: convertDateFormatddMMyyyytoyyyyMMdd(data.estimatedDate) });
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('EXPIRY_DATE_CALCULATE_FAIL', 'Failed to calculate expiry date'), img: cross, type: 'error', reUpload: 'OK' });
            }
        };
        if (values.oldExpiryDate && values.extensionType) {
            calculateWorkingDays();
        }
    }, [values.oldExpiryDate, values.extensionType]);

    const plotDetailsTableColumns = PLOT_DETAILS_TABLE_LD.map((val: any, index: number) => {
        if (index === 0) {
            return {
                ...val,
                onRadioClick: onPlotSelect,
                selectedValue: selectedPlotDataId
            };
        }
        return val;
    });

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                let applicationAccess;
                try {
                    setIsLoading(true);
                    applicationAccess = await POST(endpoints?.applicationAccess + `?businessid=${applicationNumber}`, { RequestInfo: requestInfo() });
                    if (!applicationAccess?.data.isAuthorized) {
                        window.location.href = '/goa-idc/access-restricted';
                    }
                    setIsApplicationAccessible(applicationAccess?.data.isAuthorized);
                    const nextActions = await getNextActions(applicationNumber);
                    setHasValidAction(nextActions?.nextValidAction?.length > 0);
                    const { data } = await POST(endpoints.searchAllotment + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                    const estateData = data.estateApplications[0];
                    const extensionInitData = await getExtensionInitialData(estateData);
                    let { data: plotData } = await POST(estateEndpoints.plotSearch + `?plotUuids=${estateData?.appliedPlotDetails?.uuid}`, { RequestInfo: requestInfo() });
                    const tempTabledata = [{ ...plotData?.plots[0], estateName: plotData?.plots[0]?.estateDetails.estateName, id: plotData?.plots[0]?.uuid }];
                    setFilteredPlotRowData(tempTabledata);
                    setSelectedPlotDataId(estateData?.appliedPlotDetails?.uuid);
                    setValues({ ...values, ...extensionInitData, isUpdatable: nextActions?.nextValidAction?.length > 0 });
                    setIsLoading(false);
                } catch (err: any) {
                    setIsLoading(false);
                    let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [applicationNumber]);

    const customHandleChange = async (e: any, field: string) => {
        console.log('event', e);
        console.log('field', field);
        if (field === 'extensionType') {
            if (e.value === 'Lease Deed Execution/Payment') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'ALLOTMENT'));
            } else if (e.value === 'Special Transfer Order') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'SPECIAL_TRANSFER'));
            } else if (e.value === 'Provisional Sub-Lease Order') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'PROVISIONAL_SUB_LEASE'));
            } else if (e.value === 'Transfer Order') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'TRANSFER'));
            } else if (e.value === 'Mortgage NOC Order') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'MORTGAGE'));
            } else if (e.value === 'Change in Name/Trade/Owner Order') {
                setFilteredPlotRowData(plotRowData.filter((item: any) => item.applicationSubType === 'CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE'));
            } else if (e.label === 'Please Select') {
                setFilteredPlotRowData([]);
                setValues((prevValues: any) => ({
                    ...prevValues,
                    allotmentId: '',
                    oldExpiryDate: ''
                }));
            } else {
                setFilteredPlotRowData(plotRowData);
            }
        }
    };

    const fetchExtensionData = async () => {
        const payload: any = {
            RequestInfo: requestInfo(),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            const enterpriseSearch = await POST(endpoints.searchExistingUser + `?tenantId=${TENANT_ID}&userUuid=${JSON.parse(localStorage.getItem('user') || '{}')?.uuid}`, {
                RequestInfo: requestInfo()
            });
            setEnterpriseEntity(enterpriseSearch.data?.enterpriseEntity[0]);
            const allotmentDetailsSearch = await POST(endpoints.validApplication + `?type=APPLY_TIMELINE_EXTENSION&plotId=${plotId}`, payload);
            const allotmentTypes = allotmentDetailsSearch?.data.allotmentDetails.map((detail: any) => detail.allotmentType);
            let newOptions: any = {
                extensionType: []
            };
            if (allotmentTypes.includes('ALLOTMENT')) {
                newOptions.extensionType.push({ name: 'Lease Deed Execution/Payment', code: 'Lease Deed Execution/Payment' });
            }
            if (allotmentTypes.includes('SPECIAL_TRANSFER')) {
                newOptions.extensionType.push({ name: 'Special Transfer Order', code: 'Special Transfer Order' });
            }
            if (allotmentTypes.includes('TRANSFER')) {
                newOptions.extensionType.push({ name: 'Transfer Order', code: 'Transfer Order' });
            }
            if (allotmentTypes.includes('PROVISIONAL_SUB_LEASE')) {
                newOptions.extensionType.push({ name: 'Provisional Sub-Lease Order', code: 'Provisional Sub-Lease Order' });
            }
            if (allotmentTypes.includes('MORTGAGE')) {
                newOptions.extensionType.push({ name: 'Mortgage NOC Order', code: 'Mortgage NOC Order' });
            }
            if (allotmentTypes.includes('CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE')) {
                newOptions.extensionType.push({ name: 'Change in Name/Trade/Owner Order', code: 'Change in Name/Trade/Owner Order' });
            }
            // if (allotmentTypes.includes('SPECIAL_TRANSFER')) {
            //     newOptions.extensionType.push({ name: 'Special Transfer Order', code: 'Special Transfer Order' });
            // }
            setDropdownData(newOptions);
            const uniqueAllotmentTypes = allotmentTypes.filter((val: any, index: number) => allotmentTypes.indexOf(val) === index);
            if (uniqueAllotmentTypes?.length === 1) {
                if (uniqueAllotmentTypes[0] === 'ALLOTMENT') {
                    setValues({ ...values, extensionType: { code: 'Lease Deed Execution/Payment', name: 'Lease Deed Execution/Payment' } });
                } else if (uniqueAllotmentTypes[0] === 'PROVISIONAL_SUB_LEASE') {
                    setValues({ ...values, extensionType: { code: 'Provisional Sub-Lease Order', name: 'Provisional Sub-Lease Order' } });
                } else if (uniqueAllotmentTypes[0] === 'SPECIAL_TRANSFER') {
                    setValues({ ...values, extensionType: { name: 'Special Transfer Order', code: 'Special Transfer Order' } });
                } else if (uniqueAllotmentTypes[0] === 'TRANSFER') {
                    setValues({ ...values, extensionType: { name: 'Transfer Order', code: 'Transfer Order' } });
                } else if (uniqueAllotmentTypes[0] === 'MORTGAGE') {
                    setValues({ ...values, extensionType: { name: 'Mortgage NOC Order', code: 'Mortgage NOC Order' } });
                } else if (uniqueAllotmentTypes[0] === 'CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE') {
                    setValues({ ...values, extensionType: { name: 'Change in Name/Trade/Owner Order', code: 'Change in Name/Trade/Owner Order' } });
                }
            }

            const allotmentDetails = allotmentDetailsSearch.data.allotmentDetails;
            if (allotmentDetailsSearch.data.allotmentDetails?.length > 0) {
                let plotIds = allotmentDetails.map((detail: any) => detail.entityRefDetails.plotId);
                plotIds = plotIds.join(',');
                const plotDataResponse = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, payload);
                let tempRowData: any = [];
                allotmentDetailsSearch.data.allotmentDetails?.map((item: any) => {
                    let plotDataTable = plotDataResponse?.data?.plots.find((val: any) => val.uuid === item.entityRefDetails.plotId);
                    if (plotDataTable) {
                        let tempRow: any = {
                            ...plotDataTable,
                            allotmentId: item.allotmentId,
                            expiryDate: item.expiryDate,
                            extensionType: item.entityRefDetails.type,
                            applicationSubType: item.allotmentType,
                            id: plotDataTable?.uuid
                        };
                        tempRowData.push(tempRow);
                    }
                });
                setPlotRowData(tempRowData);
                newOptions.extensionType.length === 1 && setFilteredPlotRowData(tempRowData);
            }
        } catch (err: any) {
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        fetchExtensionData();

        if (userType !== 'ENTERPRISE') {
            window.location.href = '/goa-idc/access-restricted';
        }
    }, []);

    const onSubmit = async () => {
        let payload = {
            RequestInfo: requestInfo(),
            applicationType: 'TIMELINE_EXTENSION',
            applicationSubType: values.applicationSubType,
            appliedPlotId: selectedPlotData?.uuid,
            parentOrderNumber: values.allotmentId,
            modificationDetails: {
                uuid: null,
                requestDetails: {
                    documents: [
                        {
                            documentType: values.supportingdocument?.documentType,
                            fileStoreId: values.supportingdocument?.docReferenceId
                        }
                    ],
                    orderNo: values.allotmentId,
                    expiryDate: changeDateFormatToIST(values?.expiryDate),
                    reason: values.comments
                }
            },
            enterpriseEntity: { ...enterpriseEntity }
        };

        try {
            await EXTENSION_VALIDATION.validate(values, { abortEarly: false });
            if (selectedPlotData) {
                try {
                    await POST(endpoints.createAllotment, payload);
                    setModalDetails({
                        show: true,
                        title: 'Success',
                        para: t('EXTENSION_SUBMITTED_SUCCESSFULLY', 'Application for extension submitted successfully'),
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                    });
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: t('APPLICATION_SUBMISSION_FAILED', 'Application submission failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            } else {
                setModalDetails({ show: true, title: 'Error!', para: t('SELECT_PLOT', 'Please select a plot'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/enterprisedashboard');
    };

    const onClose = () => {
        navigate(-1);
    };

    console.log(formik, 'formik check');
    console.log('enterpriseEntity', enterpriseEntity);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={t('APPLICATION_FOR_EXTENSION', 'Application for Timeline Extension')} additionalClass='sub_head_style' />
                        <div className='col-12 form-section'>
                            <FormikProvider value={formik}>
                                <Form>
                                    <Card className='card_border'>
                                        <Card.Body>
                                            {menuDetails.map((val, id: number) => (
                                                <GenericFormCard {...val} dropdownOptions={dropdownData} customHandleChange={customHandleChange} />
                                            ))}
                                            <div className='formTitle m-0 ps-2 d-flex justify-content-between'>Plot Details</div>
                                            <div className='col-12 px-2 mb-4 form-section'>
                                                <GenericActionTable columns={plotDetailsTableColumns} hideSearchBar={true} hideExportBtn={true} rowData={filteredPlotRowData} />
                                            </div>

                                            {userDetails.map((user, index) => (
                                                <GenericFormCard key={`user_${index}`} {...user} />
                                            ))}
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </FormikProvider>
                        </div>
                    </div>
                </>
            )}
            <ScrollToTopButton />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />

            <footer className='footer fixed-bottom footerBtnPatch'>
                <div className='d-flex'>
                    <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                        {hasValidAction ? (
                            <GenericButtons text={t('SUBMIT', 'Submit')} variant='primary' handleClick={onSubmit} />
                        ) : (
                            <GenericButtons text={t('CLOSE', 'Close')} handleClick={onClose} variant='primary' />
                        )}
                    </div>
                </div>
            </footer>
        </>
    );
}
