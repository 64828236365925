import React, { useEffect, useState } from 'react';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { FormikProvider, useFormik } from 'formik';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { Card, Form } from 'react-bootstrap';
import { devUrl, endpoints, estateEndpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import { getHistory, getSuccessMessage, onAttachDocument } from '../../EstateManagement/EstateManagement.controller';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { check, cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import { convertDateToDDmmYYYY, convertMonDDYYYYtoDDmmYYYY, getFirstErrorKey } from '../../../../utils/utils';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../EstateManagement/LeaseDeed/LeaseDeed.const';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import { WO_DETAILS_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import WorkOrderCorrespondence from './WorkOrderCorrespondence';
import { setWoPbgData, workOrderDetailValues } from './WorkOrder.const';
import { EsignModal } from '../../../commonPages/EsignModal';
import { getDocumentDetails } from '../ContractCreationScreens/ContractCreation.const';
import { USER_TYPE } from '../../../../utils/constants';

const WorkOrderWorkflow = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [content, setContent] = useState('');
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [tabelData, setTabelData] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [resData, setResData] = useState<any>(null);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const [reminderPopUp, setReminderPopUp] = useState<boolean>(false);
    const [pbgData, setPbgData] = useState<any>([]);
    const [show, setShow] = useState<any>(false);
    const [refId, setRefId] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [isPBGRequired, setIsPBGRequired] = useState<boolean>(true);
    const [eSignTry, setESignTry] = useState<boolean>(false);

    const totalPages = 3;
    const reqInfo = { RequestInfo: requestInfo() };

    const { t } = useTranslation();
    const location: any = useLocation();
    const workOrderDetails = location?.state?.workOrderDetails;
    const loaRowData = location?.state?.selectedLoaRowData;
    let user = localStorage.getItem('currentUserAssignment');
    const [searchParams] = useSearchParams();
    const applicationNumber: any = searchParams.get('applicationNumber');
    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const docsUuid = searchParams.get('docsUuid');
    const action: any = searchParams.get('action');
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const note: any = searchParams.get('note');
    const navigate = useNavigate();
    const eSignTried: boolean = JSON.parse(searchParams.get('eSignTried') || 'false');
    const success: boolean = JSON.parse(searchParams.get('success') || 'true');
    const users = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = users?.type;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            esignOption: 'DSC'
        },
        onSubmit: () => {},
        validationSchema: WO_DETAILS_VALIDATION
    });
    const { values, setValues, errors, setFieldTouched, setTouched, setFieldValue } = formik;

    useEffect(() => {
        if (userType !== USER_TYPE.EMP) {
            window.location.href = '/goa-idc/access-restricted';
        }

        setFieldValue('projectName', workOrderDetails?.loaDetails?.worksTenderDetails?.dprHeaderDetails?.projectName);
        setFieldValue('bidPrice', workOrderDetails?.loaDetails?.bidPrice);
        setFieldValue('loaNo', workOrderDetails?.loaDetails?.loaNo);
        setFieldValue('loaIssuanceDate', workOrderDetails?.loaDetails?.loaIssuanceDate);
        setFieldValue('name', workOrderDetails?.loaDetails?.bidderDetails?.name);

        (async () => {
            try {
                setIsLoading(true);

                const { data } = await POST(endpoints.dprValidActions + `?businessId=${applicationNumber || workOrderDetails?.applicationNo}&tenantId=ga`, {
                    RequestInfo: requestInfo()
                });
                setNextValidAction(data);
                setFieldValue('isUpdatable', isViewOnly ? !isViewOnly : data?.isUpdatable);

                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(historyData);
                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if ((applicationNumber || workOrderDetails?.applicationNo) && !resData && !nextValidAction) {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.workOrderSearch + `?applicationNos=${applicationNumber || workOrderDetails?.applicationNo}`, reqInfo);
                    setResData(data?.workOrderModelList[0]);
                    setContent(data?.workOrderModelList[0].content);
                    setRefId(data?.workOrderModelList[0]?.docReferenceId);
                    setIsPBGRequired(+data?.workOrderModelList[0]?.loaDetails?.perfGuaranteeAmount > 0);

                    await setFieldInitialValues(data?.workOrderModelList[0]);
                    const rowData = await setWoPbgData(data?.workOrderModelList[0]?.pbgDetailsModelList);
                    if (data?.workOrderModelList[0]?.pbgDetailsModelList) {
                        setPbgData(rowData);
                    }
                    setIsLoading(false);
                } catch (e) {
                    console.log(e);
                    setIsLoading(false);
                }
            }
        })();
    }, [resData]);

    useEffect(() => {
        if (nextValidAction) {
            getCorrespondenceDocument();
        }
    }, [nextValidAction]);

    useEffect(() => {
        const tableData = async () => {
            const responseTableData = documentDetails?.map((data: any, ind: number) => {
                return { ...data, serialNo: `C${documentDetails.length - ind}` };
            });
            setTabelData(responseTableData);
        };
        if (activeIndex === 2 || !!isViewOnly) {
            tableData();
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        if (note && action && nextValidAction && resData && (!isPBGRequired || pbgData?.length)) {
            setFieldValue('notes', note);
            if (eSignTried && !success && !eSignTry) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('E_SIGN_FAILED', 'e-Sign Failed'), img: cross, type: 'error', reUpload: 'OK' });
            } else if (success) {
                handlePrimaryAction({ action: action }, note);
            }
        }
    }, [nextValidAction, resData, pbgData, isPBGRequired]);

    const signDocument = () => {
        const eSignParam = new URLSearchParams(searchParams);
        eSignParam.delete('note');
        eSignParam.delete('action');
        eSignParam.delete('eSignTried');
        eSignParam.delete('signedFileStoreId');
        eSignParam.delete('success');
        let url = window.location.origin + `/goa-idc/work-order?${eSignParam.toString()}&note=${values.notes}&action=${actionAfterEsign}&eSignTried=true`;
        url = btoa(url);

        if (values.esignOption === 'EKYC') {
            const userRequestInfo = requestInfo();
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
        }
    };

    const setFieldInitialValues = async (resData: any) => {
        if (workOrderDetails || applicationNumber) {
            setIsLoading(true);
            const fieldsInitialValues = await workOrderDetailValues(resData, applicationNumber || workOrderDetails?.applicationNo);
            setValues((prevVal: any) => {
                return {
                    ...prevVal,
                    ...fieldsInitialValues
                };
            });
            setContent(resData?.content);
            setIsLoading(false);
        }
    };
    const getCorrespondenceDocument = async () => {
        try {
            setIsLoading(true);
            const { data } = await POST(
                endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber || workOrderDetails?.applicationNo}&businessService=${nextValidAction.businessService}`,
                {
                    RequestInfo: requestInfo()
                }
            );
            setDocumentDetails(data?.documents);

            const relatedApplicationDocument = await POST(endpoints.worksRelatedDocument + `?applicationNos=${applicationNumber || workOrderDetails?.applicationNo}`, { RequestInfo: requestInfo() });
            setRelatedApplicationDocument(relatedApplicationDocument);
            setIsLoading(false);
        } catch (e: any) {
            console.log(e);
            setIsLoading(false);
            let errMsg = e?.response?.data?.errorMessage || 'Something Went Wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const commonApiCall = async (endpoint: string, payload: any) => {
        try {
            const { data } = await POST(endpoint, payload);
            return data;
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message || 'Something Went Wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log('formik>>', formik);

    // common update payload
    const payload = {
        workOrderDetails: {
            ...workOrderDetails,
            ...resData,
            loaSubmissionTime: values?.loaSubmissionTime,
            loaAcceptanceDate: values?.loaAcceptanceDate ? convertDateToDDmmYYYY(values.loaAcceptanceDate) : null,
            stipulatedDateCommencement: values?.stipulatedDateCommencement ? convertDateToDDmmYYYY(values.stipulatedDateCommencement) : null,
            stipulatedDateCompletion: values?.stipulatedDateCompletion ? convertDateToDDmmYYYY(values.stipulatedDateCompletion) : null,
            pbgDetailsModelList: pbgData?.length ? pbgData : [],
            docReferenceId: signedFileStoreId ? signedFileStoreId : resData?.docReferenceId,
            content: content
        }
    };
    const onUpdateWo = async (isEsignUpdate?: boolean) => {
        const updatePayload = {
            ...reqInfo,
            workOrderDetails: { ...payload.workOrderDetails, isTsDocUpdateRequired: isEsignUpdate ? isEsignUpdate : false }
        };
        try {
            await WO_DETAILS_VALIDATION.validate(values, { abortEarly: false, context: values });

            const response: any = await commonApiCall(endpoints.workOrderUpdate, updatePayload);
            if (response) {
                setResData(response?.workOrderModelList[0]);
                setContent(response?.workOrderModelList[0]?.content);
                setRefId(response?.workOrderModelList[0]?.docReferenceId);
                isEsignUpdate && setESignTry(true);
                const rowData = await setWoPbgData(response?.workOrderModelList[0]?.pbgDetailsModelList);
                // if (response?.workOrderModelList[0]?.pbgDetailsModelList) {
                //     setPbgData(rowData);
                // }
                const pbgData = response?.workOrderModelList[0]?.pbgDetailsModelList;
                if (pbgData?.length) {
                    const updatedRowData = await Promise.all(
                        pbgData.map(async (val: any) => {
                            const pbgDocument = val?.pbgDocRefId && (await getDocumentDetails(val?.pbgDocRefId, 'OTHER'));
                            return {
                                ...val,
                                documentPbg: pbgDocument,
                                rowId: val?.uuid,
                                docName: pbgDocument?.documentName
                            };
                        })
                    );
                    setPbgData(updatedRowData);
                }
                setTouched({});
                !isEsignUpdate && setModalDetails({ show: true, title: 'Success', para: 'Data Saved Successfully', img: check, type: 'save success', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setTouched({});
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handlePrimaryAction = async (status: any, comment: string) => {
        const initiatePayload = {
            ...reqInfo,
            workOrderRequest: { ...payload },
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber ? applicationNumber : workOrderDetails?.applicationNo,
                    moduleName: nextValidAction?.moduleName,
                    action: action ? action : status?.action,
                    notes: values.notes || note,
                    comment: values.notes
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean)
        };
        if (!pbgData?.length && isPBGRequired) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('PBG_REQUIRED', 'Atleast Add one PBG Data'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        if (!values.notes && !note) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        if ((!signedFileStoreId && status.isEsignRequired) || !success) {
            await onUpdateWo(status.isEsignRequired);
            setActionAfterEsign(status.action);
            setEsignModal(true);
            return;
        }
        try {
            await WO_DETAILS_VALIDATION.validate(values, { abortEarly: false, context: values });
            const response = await commonApiCall(endpoints.worksWorkflowInitiate, initiatePayload);
            if (response) {
                const successMessage = getSuccessMessage(action ? action : status.action, applicationNumber);
                setTouched({});
                setModalDetails({ show: true, title: 'Success', para: successMessage, img: check, type: 'success', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setTouched({});
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any =
                file.referenceId &&
                (await onAttachDocument(
                    applicationNumber ? applicationNumber : workOrderDetails?.applicationNo,
                    file?.referenceId,
                    designationName,
                    nextValidAction?.businessService,
                    historyDetails?.length ? historyDetails[0].id : null
                ));
            setDocumentDetails(docs?.documents);
            setDocumentsUuid([...documentsUuid, docs?.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    const handleNextPage = async () => {
        try {
            const { data } = await POST(
                endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber || workOrderDetails?.applicationNo}&businessService=${nextValidAction.businessService}`,
                {
                    RequestInfo: requestInfo()
                }
            );
            setDocumentDetails(data?.documents);
        } catch (e) {
            console.log(e);
        }
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };
    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };
    const primaryAction = () => {
        onUpdateWo();
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={isViewOnly ? t('WORKORDER_VIEW_HEADING', 'Work Order View') : t('WORKORDER_HEADING', 'Work Order')}
                            additionalClass='sub-text-style'
                            onActionClick={() => {
                                navigate('/goa-idc/search-loa');
                            }}
                            bottomSubText={`${isViewOnly ? '' : 'Type : Work Order Application,'} No : ${applicationNumber ? applicationNumber : workOrderDetails?.applicationNo} `}
                        />

                        <FormikProvider value={formik}>
                            {!isViewOnly ? (
                                <>
                                    <div className='col-sm-3 mt-3 stickyCard-section '>
                                        <div className='stickyCard' style={{ top: '15%' }}>
                                            <GenericHeader
                                                text={t('WORKORDER_HEADING', 'Work Order')}
                                                sideHeader={true}
                                                additionalClass='sub-text-style'
                                                bottomSubText={`Type : Work Order Application, No : ${applicationNumber ? applicationNumber : workOrderDetails?.applicationNo} `}
                                            />

                                            <NotingWrapper
                                                designation={designation}
                                                applicationNumber={applicationNumber}
                                                history={historyDetails}
                                                // disable={!nextValidAction?.nextValidAction?.length}
                                                handleOnFileUpload={handleOnFileUpload}
                                                nextValidAction={nextValidAction}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}

                            <div className={`${!isViewOnly ? 'col-sm-9' : 'col-sm-12'} mt-3 form-section `}>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} isViewOnly={isViewOnly} />
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <WorkOrderCorrespondence
                                                content={content}
                                                setContent={setContent}
                                                loaRowData={loaRowData}
                                                t={t}
                                                show={show}
                                                setShow={setShow}
                                                workOrderDetails={workOrderDetails}
                                                readOnly={!nextValidAction?.isUpdatable || isViewOnly}
                                                modalDetails={modalDetails}
                                                setModalDetails={setModalDetails}
                                                setReminderPopUp={setReminderPopUp}
                                                pbgData={pbgData}
                                                setPbgData={setPbgData}
                                                isViewOnly={isViewOnly}
                                                tableData={tabelData}
                                                onRowClick={onRowClick}
                                                isPBGRequired={isPBGRequired}
                                            />
                                        )}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                        )}
                                        {activeIndex === 3 && (
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            {isViewOnly ? (
                                <></>
                            ) : (
                                <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                    <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                        <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                    </span>
                                    Page {activeIndex} of {totalPages}
                                    <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                        <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                    </span>
                                </div>
                            )}
                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                    </div>
                    {!isViewOnly ? (
                        <>
                            <GenericFooterComponent
                                activeIndex={0}
                                stepperLength={0}
                                handleOnPrimaryAction={handlePrimaryAction}
                                onSubmit={() => {}}
                                nextValidAction={nextValidAction}
                                extraButton={applicationNumber ? false : 'Save'}
                                extraButtonAction={primaryAction}
                            />
                        </>
                    ) : (
                        <></>
                    )}

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                    <GenericPopupModal
                        secondaryAction={() => {
                            setReminderPopUp(false);
                        }}
                        primaryAction={() => {
                            setReminderPopUp(false);
                            primaryAction();
                        }}
                        isVisible={reminderPopUp}
                        title='Save Confirmation'
                        size='md'
                        children={
                            <div>
                                <div className='mx-4 my-3'>
                                    <div className='mt-4'>
                                        <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                            {t('SAVE_CHANGES', 'Looks like you have some unsaved changes. Would you like to save them before proceeding with the new data?')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        primaryBtnTitle='Yes'
                        secondaryBtnTitle='No'
                        // onClose={() => setReminderPopUp(false)}
                    />
                </>
            )}
        </>
    );
};

export default WorkOrderWorkflow;
