import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import RenderNotes from './RenderNotes.component';
import EmailNotification from './RenderEmailUpdation.component';
import { Card, CardBody, Form } from 'react-bootstrap';
import ProgressBar from '../../components/moduleCommonFormik/ProgressBar';
import PaymentSubmissionStatus from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import { POST, getProgressBarData, requestInfo } from '../../services/modulesServices/userManagement.api';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import { getFeeDetailsList } from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.controller';
import GenericPayment from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/GenericPayment';
import GenericPaymentStatus from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/GenericPaymentStatus';
import { InitiateConstSteps } from './ConstructionLicensePage.const';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';
import { CHECKLIST_TABLE, CHECKLIST_TABLE_TC, paymentColumnsForTC } from '../modules/EstateManagement/Allotment/Allotment.const';
import Editor from '../../components/ContentEditor';
import PaymentDetails from '../modules/EstateManagement/Allotment/PaymentDetails';
import { t } from 'i18next';

interface ConstructionLicenseSubPageProps {
    activeStepper: string;
    setEditorContent: Dispatch<SetStateAction<string>>;
    editorContent: string;
    applicationNumber: string;
    previewData: any;
    bpaData: any;
    sanctionFee: any;
    setFormValues: Dispatch<
        SetStateAction<
            {
                emailId: string;
            }[]
        >
    >;
    formValues: {
        emailId: string;
    }[];
    paymentRowData: any;
    content: any;
    setContent: React.Dispatch<React.SetStateAction<string>>;
    // isViewOnly: any;
    // readOnly: any;
}

export default function M_ConstructionLicenseSubPage(props: ConstructionLicenseSubPageProps) {
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [auditTrial, setAuditTrial] = useState<any>(null);
    const [openSection, setOpenSection] = useState<string>('LESSEE_DETAITLS');
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [checklistTableData, setChecklistTableData] = useState();

    const handleContentChange = (content: string) => {
        props.setEditorContent(content);
    };

    const onSectionChange = (currentSection: string) => {
        // if (openSection !== currentSection && currentSection === 'isNoteDetails' && showSaveModal) {
        //     setAddButton(true);
        // }
        setOpenSection(currentSection);
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={props?.setContent} content={props?.previewData?.provisionalCertificateContent} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    useEffect(() => {
        try {
            if (!!props?.applicationNumber) getProgressBarData(props?.applicationNumber).then(res => setProgressBarDetails(res.data));
        } catch (e) {
            console.log(e);
        }
    }, [props?.applicationNumber]);

    const renderNotesDetails = () => {
        const notesStep = InitiateConstSteps.find(step => step.code === 'NOTES');
        if (notesStep && notesStep.isActive) {
            return (
                <>
                    <p className='font-inter-reg' style={{ fontWeight: 500, fontSize: '20px', lineHeight: '32px', letterSpacing: '-2%', color: '#45852E' }}></p>
                    <RenderNotes onContentChange={handleContentChange} editorContent={props.editorContent} isEditable={props?.bpaData?.editable} />
                </>
            );
        }
        return null; // or any default component to render if the step is not active
    };

    const renderNotificationDetails = () => {
        const notificationStep = InitiateConstSteps.find(step => step.code === 'NOTIFICATION_DETAILS');
        if (notificationStep && notificationStep.isActive) {
            return <EmailNotification formValues={props?.formValues} setFormValues={props?.setFormValues} isEditable={props?.bpaData?.editable} />;
        }
        return null; // or any default component to render if the step is not active
    };

    const getAuditData = () => {
        if (!props?.applicationNumber) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.getAuditTrail(payload, props?.applicationNumber, 'ga')
            .then(res => {
                setAuditTrial(res?.data?.ProcessInstances);
            })
            .catch(err => console.log(err));
    };

    const getCheckListData = () => {
        if (!props?.applicationNumber) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.bpaCheckList(payload, props?.applicationNumber, 'WF_BPA_CL')
            .then(res => {
                const checklistDataWithIndex = res?.data?.checklistValidationDetails?.map((item: any, index: any) => ({
                    ...item,
                    index: index + 1
                }));
                setChecklistTableData(checklistDataWithIndex);
            })
            .catch(err => console.log(err));
    };

    const fetchGatewayData = async () => {
        try {
            const { data } = await POST(`/egov-mdms-service/v1/_search`, {
                RequestInfo: requestInfo(),
                MdmsCriteria: {
                    tenantId: 'ga',
                    moduleDetails: [
                        {
                            moduleName: 'egov-pg-service',
                            masterDetails: [
                                {
                                    name: 'Gateway',
                                    filter: '[?(@.active == true)]'
                                }
                            ]
                        }
                    ]
                }
            });
            let feeMasterDetails = data?.['MdmsRes']?.['egov-pg-service']?.['Gateway'];
            setGatewayData(feeMasterDetails || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const renderPayment = () => {
        const SANCTION_DETAILS = {
            txnId: props?.paymentRowData?.transactionNumber || '',
            auditDetails: { createdTime: props?.paymentRowData?.transactionDate || '' },
            txnStatus: 'SUCCESS',
            additionalDetails: { paymentReceiptDocRefId: props?.paymentRowData?.receiptId || '' }
        };

        // Function to format numbers with commas
        const formatNumberWithCommas = (number: any) => {
            return number.toLocaleString('en-IN');
        };

        // Format the BPAPaymentDetails array
        const formattedPaymentDetails = (props?.paymentRowData?.BPAPaymentDetails || []).map(
            (
                detail: {
                    principalAmount: any;
                    cgstPrincipal: any;
                    sgstPrincipal: any;
                    interestAmount: any;
                    penaltyAmount: any;
                    cgstPenalty: any;
                    sgstPenalty: any;
                    totalDueAmount: any;
                    paidAmount: any;
                },
                index: number
            ) => ({
                ...detail,
                index: index + 1,
                principalAmount: formatNumberWithCommas(detail.principalAmount),
                cgstPrincipal: formatNumberWithCommas(detail.cgstPrincipal),
                sgstPrincipal: formatNumberWithCommas(detail.sgstPrincipal),
                interestAmount: formatNumberWithCommas(detail.interestAmount),
                penaltyAmount: formatNumberWithCommas(detail.penaltyAmount),
                cgstPenalty: formatNumberWithCommas(detail.cgstPenalty),
                sgstPenalty: formatNumberWithCommas(detail.sgstPenalty),
                totalDueAmount: formatNumberWithCommas(detail.totalDueAmount),
                paidAmount: formatNumberWithCommas(detail.paidAmount)
            })
        );

        return (
            <>
                {/* <Card className='card_border my-2'>
                    {SANCTION_DETAILS.map((val: any) => (
                        <Card.Body>
                            <GenericPayment {...val} />
                        </Card.Body>
                    ))}
                </Card> */}
                <PaymentDetails
                    columns={paymentColumnsForTC}
                    paymentData={formattedPaymentDetails}
                    // title=''
                    selectedGateway={''}
                    setSelectedGateway={() => {}}
                    isPaid={props?.paymentRowData?.isPaymentRecived}
                    gatewayData={gatewayData}
                    transactionDetails={SANCTION_DETAILS}
                    totalLabel={'totalDueAmount'}
                    isVisiblePaid={true}
                    hideTitle={true}
                    isCardDisable={true}
                />
                {/* <GenericPaymentStatus status='Success' /> */}
                {/* <GenericPopupModal children={<PaymentSuccessPopUp />} isVisible={visible} primaryAction={() => { }} secondaryAction={handelVisible} primaryBtnTitle="Download Invoice" secondaryBtnTitle='Cancel' size="xl" /> */}
            </>
        );
    };

    useEffect(() => {
        getAuditData();
        fetchGatewayData();
        getCheckListData();
    }, []);

    const applicationData = {
        applicationNo: props?.applicationNumber,
        status: props?.previewData?.status,
        data: auditTrial,
        isRoleMD: true,
        bpaData: props?.bpaData
    };
    return (
        <>
            {/* {!!props.applicationNumber && (
                <Card className='card_border my-2'>
                    <Card.Body>
                        <PaymentSubmissionStatus data={applicationData} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                    </Card.Body>
                </Card>
            )} */}
            <Card className='checklist-outer mb-4 mx-3'>
                <CardBody>
                    <div className='row'>
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3    align-items-center pb-2 '>
                            <input type='checkbox' id='Primary Assignment' name='assignments' defaultChecked={true} />
                            <label htmlFor='Primary Assignment' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold  '>
                                {t('CL_EMP_CONDITION_CHECKLIST_HEADING', 'Condition/Check list')}
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE_TC} hideSearchBar={true} hideFooter={true} rowData={checklistTableData} />
                            </div>
                        </div>
                        {/* <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='NOTES_Checkbox' name='NOTES' checked={openSection === 'NOTES'} onChange={() => onSectionChange('NOTES')} />
                            <label
                                htmlFor='NOTES_Checkbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                A - Notes
                            </label>
                            {openSection === 'NOTES' && renderNotesDetails()}
                        </div> */}
                        {/* <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input
                                type='checkbox'
                                id='NOTIFICATION_DETAILS_Checkbox'
                                name='NOTIFICATION_DETAILS'
                                checked={openSection === 'NOTIFICATION_DETAILS'}
                                onChange={() => onSectionChange('NOTIFICATION_DETAILS')}
                            />
                            <label
                                htmlFor='NOTIFICATION_DETAILS_Checkbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                B - Notifications
                            </label>
                            {openSection === 'NOTIFICATION_DETAILS' && renderNotificationDetails()}
                        </div> */}
                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='PAYMENT_Checkbox' name='PAYMENT' checked={openSection === 'PAYMENT'} onChange={() => onSectionChange('PAYMENT')} />
                            <label
                                htmlFor='PAYMENT_Checkbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('CL_EMP_HEADING_PAYMENT_DETAILS', 'A - Payment Details')}
                            </label>
                            {openSection === 'PAYMENT' && renderPayment()}
                        </div>
                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='NOTESDETAILS_Checkbox' name='NOTESDETAILS' checked={openSection === 'NOTESDETAILS'} onChange={() => onSectionChange('NOTESDETAILS')} />
                            <label
                                htmlFor='NOTESDETAILS_Checkbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('CL_EMP_HEADING_TC_ORDER', 'B - Construction License Order')}
                            </label>
                            {openSection === 'NOTESDETAILS' && renderNoteDetails()}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}
