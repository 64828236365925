import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ScrollToTop from '../../../../components/ScrollToTop';
import GenericBackButton from '../../../../components/UI components/Buttons/GenericBackButton';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { POST, devUrl, endpoints, getProgressBarData, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { check, cross } from '../../../../utils/Logo';
import { TENANT_ID } from '../../../../utils/constants';
import { OWNERSHIP_VALIDATION, SPV_FORM_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { dropdownDataOptions, getFormatedOwnershipDetails, getMigrationData } from '../../Migration/Migration.controller';
import ApplicantDetails from '../Allotment/ApplicantDetails';
import { APPLICANT_DETAILS_2, stepsDetails } from './SPV.const';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { FaTimes } from 'react-icons/fa';
import { getAllotmentWorkflowInitialData, getEntityData, setEnterpriseDocData, setEntityDocData } from '../Allotment/Allotment.controller';
import { SPVPayload } from './SPV.controller';
import { getFirstErrorKey } from '../../../../utils/utils';

import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { APPLICANT_DETAILS_I, PROMOTER_DETAILS } from '../Allotment/Allotment.const';
import { TemplateCard } from '../TemplatesCard';
import Loading from '../../../../components/portalComponents/Loading.component';
import { getNextActions } from '../EstateManagement.controller';
import PaymentSubmissionStatus from '../../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../../components/moduleCommonFormik/ProgressBar';
import { handleUpdates } from '../../UserManagement/UserMangement.controller';

export default function SPVApplication(props: any) {
    const location: any = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const searchParams: any = new URLSearchParams(location.search);
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [steps, setSteps] = useState<any>(stepsDetails);
    const [addButton, setAddButton] = useState(false);
    const [applicationType, setApplicationType] = useState<any>('');
    const [showDownloadBox, setShowDownloadBox] = useState(false);
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [templateLinks, setTemplateLinks] = useState([]);
    const [DocumentId, setDocumentId] = useState([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [estateResponse, setEstateResponse] = useState<any>({});
    const [searchApplicationResponse, setSearchApplicationResponse] = useState<any>({});
    const [applicationNo, setApplicationNo] = useState<any>('');
    // const [plotId, setPlotId] = useState<any>('');
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const allotmentId = location?.state?.allotmentId || null;
    const plotId = location?.state?.plotId || null;
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [calculated, setCalculated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userObject, setUserObject] = useState<any>({});
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [ownershipDisable, setOwnershipDisable] = useState<boolean>(false);
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);
    const [allotmentNo, setAllotmentNo] = useState<any>(null);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const spvNo: any = searchParams.get('applicationNumber') ? searchParams.get('applicationNumber') : '';
    const [applicationDetailsII, setApplicationDetailsII] = useState<any>(APPLICANT_DETAILS_2);
    const [existingUser, setExistingUser] = useState<any>(null);

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = user?.type;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            applicantGst: false,
            udhyam: false,
            isStartup: false,
            womenEntrepreneur: false,
            localPerson: false,
            priorExperience: false,
            allotmentId: '',
            individualOrOrganization: 'Individual',
            ownership: []
        },
        onSubmit: value => {},
        validationSchema: !showOwnershipModal ? SPV_FORM_VALIDATION[`page${activeIndex}`] : OWNERSHIP_VALIDATION
    });

    useEffect(() => {
        if (userType !== 'ENTERPRISE') {
            window.location.href = '/goa-idc/access-restricted';
        }
        dropdownDataOptions()
            .then(dropdownOption => {
                setDropdownData(dropdownOption);
                // setApplicantData(dropdownOption);
            })
            .catch(error => {});

        if (!!spvNo)
            getProgressBarData(spvNo)
                .then(res => setProgressBarDetails(res.data))
                .catch(e => console.log(e));
    }, []);

    const { values, setValues, setTouched, setFieldValue, setFieldTouched, errors, resetForm } = formik;
    console.log(formik);

    const subLeaseAutoPopulate = async () => {
        try {
            const entityData = await getEntityData(existingUser, dropdownData);

            const newOwnership = await getFormatedOwnershipDetails(existingUser?.owners, dropdownData);
            setOwnershipDetails(newOwnership);
            setControllingOwnershipResponse(existingUser?.owners);

            setValues((prev: any) => ({ ...prev, ...entityData }));
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        if (existingUser) {
            subLeaseAutoPopulate();
        }
    }, [existingUser]);
    const findExistingUser = async (email: any) => {
        try {
            const { data: existing } = await POST(endpoints.searchExistingUser + `?email=${email}`, { RequestInfo: requestInfo() });
            setExistingUser(existing?.enterpriseEntity[0]);
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || 'Something Went Wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        if (values.subLeaseEmailIdOtpReferenceId && values.subLeaseEmailId) {
            findExistingUser(values.subLeaseEmailId);
        }
    }, [values.subLeaseEmailIdOtpReferenceId]);
    const addModal = () => {
        setAddButton(true);
    };

    const onSubmit = async (key?: any) => {
        console.log('Ownership Details before submit:', ownershipDetails);
        const payload = SPVPayload(values, plotId, ownershipDetails, estateResponse, allotmentId, userObject, existingUser);
        let data: any;

        try {
            await SPV_FORM_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });

            const currOwnership = ownershipDetails
                .filter((item: any) => !item.isDelete)
                .reduce((accumulator: number, subItem: any) => {
                    return accumulator + subItem.ownershipInterest;
                }, 0);

            if (currOwnership != 100 && values?.category?.code !== 'LTD_COMPANY') {
                setModalDetails({ show: true, title: 'Error!', para: t('OWNERSHIP_INTEREST', 'Your ownership interest does not add upto 100%'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            if (controllingOwnershipResponse.length === 0) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please calculate Controlling Ownership!', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            try {
                setIsLoading(true);
                data = await POST(endpoints.createAllotment, payload);
                if (data) {
                    setApplicationNo(data.data.estateApplications?.[0].applicationNo || '');
                    setApplicationType(data.data.estateApplications?.[0].applicationType || '');
                    setSearchApplicationResponse(data.data.estateApplications?.[0].enterpriseEntity || {});
                    setEstateResponse(data.data.estateApplications?.[0] || {});
                    setValues((prev: any) => ({
                        ...prev,
                        entityUserUuid: data.data.estateApplications?.[0].uuid
                    }));
                    setModalDetails({ show: true, title: 'Success!', para: t('SPV_SUBMITTED', 'Application for SPV submitted successfully'), img: check, type: 'success', reUpload: 'OK' });
                }
                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            console.log(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                await SPV_FORM_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userId = JSON.parse(localStorage.getItem('user') || '{}')?.uuid;
                const { data } = await POST(endpoints.searchExistingUser + `?tenantId=${TENANT_ID}&userUuid=${userId}`, { RequestInfo: requestInfo() });
                setUserObject(data?.enterpriseEntity[0]?.user);
                const RequestInfo = { RequestInfo: requestInfo() };
                const response = await POST('/egov-common-masters/idc/document/_search?documentCategories=ENTERPISE_REGISTRATION', RequestInfo);

                if (response && response.data && response.data.documentDetails) {
                    setTemplateLinks(response.data.documentDetails.map((detail: any) => detail.documentName));
                    setDocumentId(response.data.documentDetails.map((detail: any) => detail.documentReferenceId));
                    setValues((prev: any) => ({ ...prev, applicationType: 'SPV' }));
                } else {
                    console.error('Response format is incorrect:', response);
                }
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (values?.category?.code) {
            if (values?.category?.code === 'PROP_FIRM') {
                if (ownershipDetails?.length) {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.ownership[0]?.name }));
                } else {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: '' }));
                }
            } else {
                setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.applicantName }));
            }
        }
    }, [values?.category?.code, values?.ownership, values?.applicantName]);

    useEffect(() => {
        if (spvNo) {
            (async () => {
                const applicationAccess = await POST(endpoints?.applicationAccess + `?businessid=${spvNo}`, { RequestInfo: requestInfo() });
                if (!applicationAccess?.data.isAuthorized) {
                    window.location.href = '/goa-idc/access-restricted';
                }
                const nextActions = await getNextActions(spvNo);
                setHasValidAction(nextActions?.nextValidAction?.length > 0);
                setNextValidAction(nextActions);
            })();
        }
    }, [spvNo]);

    useEffect(() => {
        if (values?.category?.code) {
            if (values?.category?.code === 'PROP_FIRM') {
                if (ownershipDetails?.length) {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: ownershipDetails[0]?.name }));
                } else {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: '' }));
                }
            } else {
                setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.applicantName }));
            }
        }
    }, [values?.category?.code, ownershipDetails, values?.applicantName]);

    useEffect(() => {
        const ApplicantDetails = async () => {
            if (spvNo && dropdownData) {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.searchAllotment + `?applicationNos=${spvNo}`, { RequestInfo: requestInfo() });
                    const estateData = data.estateApplications[0];
                    const SpvInitData = await getAllotmentWorkflowInitialData(estateData, dropdownData);
                    setAllotmentNo(estateData.parentOrderNumber);

                    setValues((prev: any) => ({
                        ...prev,
                        ...SpvInitData,
                        applicationSubType: estateData.applicationSubType,
                        highestBidRate: estateData.plotRate,
                        isUpdatable: hasValidAction
                    }));

                    const ownershipTableDetails = await getFormatedOwnershipDetails(estateData?.enterpriseEntity?.owners, dropdownData);
                    setOwnershipDetails(ownershipTableDetails);
                    setControllingOwnershipResponse(estateData?.enterpriseEntity?.owners);
                    nextValidAction && setOwnershipDisable(!hasValidAction);

                    setIsLoading(false);
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    setIsLoading(false);
                }
            }
        };
        ApplicantDetails();
    }, [dropdownData, nextValidAction, hasValidAction]);

    useEffect(() => {
        const updatedDetails = handleUpdates(values, applicationDetailsII, APPLICANT_DETAILS_2, 'Address Details', 'Mailing/Correspondence address of the Entity');
        setApplicationDetailsII(updatedDetails);
    }, [values?.isPermanentPincodeDetailsPresent, values?.isPincodeDetailsPresent, values?.copyOnCheck]);

    const handleOnDownloadTemplate = async () => {
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${DocumentId}`;
        window.open(downloadFileUrl);
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/enterprisedashboard');
    };

    const onClose = () => {
        navigate(-1);
    };

    const status: any = progressBarDetails?.progressStatus.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: spvNo,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={t('APPLICATION_FOR_SPV', 'Application for SPV')} sideSubText={`Allotment No : ${allotmentId ? allotmentId : allotmentNo}`} />
                        <div className='col-3 stickyCard-section'>
                            <div className='stickyCard'>
                                <GenericHeader text={'Application for SPV'} sideHeader={true} additionalClass='sub-text-style' />
                                <Card className='card_border'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column row-gap-3  '>
                                            <SideCard steps={steps} activeIndex={activeIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                                <TemplateCard category={`ENTITY_REGISTRATION`} />
                            </div>
                        </div>
                        <div className='col-9 form-section'>
                            {!!spvNo && applicationData.status !== 'Draft' && (
                                <Card className='card_border my-2'>
                                    <Card.Body>
                                        <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                                    </Card.Body>
                                </Card>
                            )}
                            <FormikProvider value={formik}>
                                <Form>
                                    {activeIndex === 1 && (
                                        <Card className='card_border'>
                                            <ApplicantDetails
                                                dropdownOptions={dropdownData}
                                                setOwnershipDetails={setOwnershipDetails}
                                                controllingOwnershipResponse={controllingOwnershipResponse}
                                                setControllingOwnershipResponse={setControllingOwnershipResponse}
                                                ownershipDetails={ownershipDetails}
                                                showOwnershipModal={showOwnershipModal}
                                                setShowOwnershipModal={setShowOwnershipModal}
                                                applicantDetails1={APPLICANT_DETAILS_I}
                                                applicantDetails2={applicationDetailsII}
                                                promoterDetails={PROMOTER_DETAILS}
                                                ownershipDisable={ownershipDisable}
                                                scrollNotRequired={!hasValidAction}
                                                skipFullOwnershipPercent={values?.category?.code === 'LTD_COMPANY'}
                                            ></ApplicantDetails>
                                        </Card>
                                    )}
                                </Form>
                            </FormikProvider>
                        </div>
                    </div>
                    <ScrollToTop />
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                                {/* <GenericButtons text={t('CANCEL', 'Cancel')} handleClick={() => addModal()} /> */}
                                {hasValidAction ? (
                                    <GenericButtons text={t('SUBMIT', 'Submit')} handleClick={() => onSubmit()} variant='primary' />
                                ) : (
                                    <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={onClose} />
                                )}
                            </div>
                        </div>
                    </footer>
                </>
            )}
        </>
    );
}
