import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Form } from 'react-bootstrap';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import { check, cross } from '../../../../../utils/Logo';
import { useTranslation } from 'react-i18next';
import './Advertisement.scss';
import AdvWorkflow from './AdvWorkflow';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { POST, devUrl, endpoints, estateEndpoints, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../../components/fileviewer/GenericImageViewer';
import HeaderComponent from '../../../../../components/header/Header.component';
import GenericBackButton from '../../../../../components/UI components/Buttons/GenericBackButton';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import NotingComponent from '../../../../../components/Workflow/NotingComponent';
import ScrollToTop from '../../../../../components/ScrollToTop';
import { EMPLOYEE_AUTHORIZED, TENANT_ID } from '../../../../../utils/constants';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { ADEVERTISMENT_WORKFLOW } from '../../../../../utils/formikValidation/workflowValidation';
import { getSuccessMessage, getVacantPlots } from '../../EstateManagement.controller';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import { toast } from 'react-toastify';
import { getFirstErrorKey } from '../../../../../utils/utils';
import axios from 'axios';
import { EsignModal } from '../../../../commonPages/EsignModal';
export default function AdvertisementPage(props: any) {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [dropdownData, setDropdownData] = useState<any>([]);
    const [isPDF, setIsPDF] = useState(true);
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [tabelData, setTabelData] = useState<any>([]);
    const [searchParams] = useSearchParams();

    const [comment, setcomment] = useState('');
    const [content, setContent] = useState('');
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [advertisementid, setAdvertisementid] = useState<string | undefined>(undefined);
    const [rowData, setRowData] = useState([]);
    const [selectedPlots, setSelectedPlots] = useState<any[]>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [formIsUpdatable, setFormIsUpdatable] = useState(true);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [vacantPlotRowData, setVacantPlotRowData] = useState([]);
    const [selectedVacantPlots, setSelectedvacantPlots] = useState([]);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [applicationEstateData, setApplicationEstateData] = useState<any>(null);

    const [refId, setRefId] = useState<any>(null);
    const [approvedEsignData, setApprovedEsignData] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);

    const advNo = decodeURIComponent(searchParams.get('applicationNumber') || '');
    const totalPages = advNo ? 2 : 1;
    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;

    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');

    console.log(designation);
    const navigate = useNavigate();
    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            emails: [{ emailId: '' }],
            esignOption: 'DSC'
        },
        onSubmit: () => {},
        validationSchema: ADEVERTISMENT_WORKFLOW[`page${activeIndex}`]
    });

    const signedFileStoreId = searchParams.get('signedFileStoreId');

    const DOC_TABLE_COLUMN = [
        {
            colName: 'SN',
            fieldName: 'serialNo',
            clsName: 'col-sm-2'
        },
        {
            colName: 'Uploaded By',
            fieldName: 'uploadedUserRole',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Date & Time',
            fieldName: 'uploadedDate',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Document',
            fieldName: 'docFileName',
            clsName: 'col-sm-2',

            isClickable: true
        }
    ];

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const { values, setValues, setFieldValue, errors, setFieldTouched } = formik;

    const handleNextPage = async () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);

        try {
            const res2 = await POST(endpoints.documentSeach + `?businessId=${advNo}&businessService=ADV`, { RequestInfo: requestInfo() });
            docsTableData(res2?.data?.documents);
        } catch (e) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('DOCUMENT_DETAILS_FAILED', 'Failed to fetch document details'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const fetchData = async () => {
        if (advNo) {
            try {
                const { data } = await POST(`${endpoints.workflowSearch}&businessIds=${advNo}`, { RequestInfo: requestInfo() });
                setHistoryDetails(data.ProcessInstances);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    useEffect(() => {
        (async () => {
            try {
                if (advNo && userType === 'EMPLOYEE') {
                    const nextAction = await POST(endpoints.nextValidAction + `?businessId=${advNo}&tenantId=${TENANT_ID}`, { RequestInfo: requestInfo() });
                    setNextValidAction(nextAction.data);
                    // setFormIsUpdatable(nextAction.data.isUpdatable);g
                    setFieldValue('isUpdatable', isViewOnly ? !isViewOnly : nextAction.data.isUpdatable);
                }
            } catch (e) {}
        })();
    }, [advNo, dropdownData]);

    useEffect(() => {
        if (userType === 'EMPLOYEE') {
            (async () => {
                const data: any = await getVacantPlots();
                setVacantPlotRowData(data);
            })();

            fetchAdvertisementData();
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }

        const fetchData = async () => {
            try {
                const RequestInfo = { RequestInfo: requestInfo() };
                const response = await POST(endpoints.documentSeach + `?businessId=${advNo}&businessService=ADV`, RequestInfo);
                docsTableData(response?.data?.documents);
            } catch (err) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('DOCUMENT_DETAILS_FAILED', 'Failed to fetch document details'), img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        userType === 'EMPLOYEE' && fetchData();
    }, []);

    console.log(formik);

    useEffect(() => {
        if (rowData.length < 2) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [rowData]);

    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');

    useEffect(() => {
        if (note && applicationEstateData && nextValidAction) {
            onApply({ action: action }, note);
        }
    }, [applicationEstateData, nextValidAction]);

    const esign = async (payload: any, action: string) => {
        const userRequestInfo = requestInfo();

        try {
            let approveEsignRes = await POST(endpoints.esignApprove, payload);
            console.log('approvedEsignRes', approveEsignRes);

            setRefId(approveEsignRes.data.entityRefDocuments?.[0].docReferenceId);
            const approvedEsignData = approveEsignRes.data.entityRefDocuments.length ? approveEsignRes.data.entityRefDocuments.map((item: any) => `${item?.uuid}=${item?.docReferenceId}`) : [];
            setApprovedEsignData(approvedEsignData);
            setActionAfterEsign(action);
            setEsignModal(true);

            // if (window.location.protocol !== 'http:') {
            // let url =
            //     window.location.origin +
            //     `/goa-idc/createadvertisement?${searchParams.toString()}&note=${values.notes}&docsUuid=${[advertisementid]?.join(',')}&approvedEsign=${approvedEsignData.join(
            //         ','
            //     )}&action=${action}`;
            //     url = btoa(url);
            //     const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
            //         userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
            //     }`;
            //     await axios.get(esignUrl);
            //     window.open(esignUrl, '_self');
            // } else {
            //     let url =
            //         window.location.origin +
            //         `/goa-idc/createadvertisement?${searchParams.toString()}&signedFileStoreId=${refId}&note=${values.notes}&docsUuid=${[advertisementid]?.join(
            //             ','
            //         )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
            //     console.log('url', url, [advertisementid]);
            //     window.open(url, '_self');
            // }
        } catch (e) {
            console.log('esign error', e);
        }
    };

    const signDocument = () => {
        let url =
            window.location.origin +
            `/goa-idc/createadvertisement?${searchParams.toString()}&note=${values.notes}&docsUuid=${[advertisementid]?.join(',')}&approvedEsign=${approvedEsignData.join(
                ','
            )}&action=${actionAfterEsign}`;
        url = btoa(url);

        if (values.esignOption === 'EKYC') {
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${refId}&accessToken=${requestInfo().authToken}&userType=${requestInfo().userType}${
                    requestInfo().departmentCode ? `&departmentCode=${requestInfo().departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
        }
    };

    const onApply = async (status: any, comment: string) => {
        const emails = values.emails?.map((item: any) => item.emailId);

        const payload = {
            RequestInfo: requestInfo(),

            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: 'ADV',
                    businessId: values.advNo,
                    moduleName: 'Advertisement Approval',
                    action: status?.action,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            workFlowDocumentIds: [advertisementid].length ? [advertisementid].filter(Boolean) : docsUuid?.split(',').filter(Boolean),
            advertisement: {
                uuid: values.uuid,
                plotIds: rowData.map((e: any) => e.uuid || null),

                advNo: values.advNo,
                publicationDate: values?.publicationDate || '',
                submissionStartDate: values?.submissionStartDate || '',
                submissionEndDate: values?.submissionEndDate || '',

                status: values.status,
                docReferenceId: values.docReferenceId || '',
                additionalDetails: emails
                    ? {
                          notificationDetails: {
                              emailIds: emails
                          }
                      }
                    : null,

                content: content
            },
            signedDocDetails:
                approvedEsign?.split(',').map((item: any) => {
                    const esignData = item.split('=');
                    return { [`${esignData[0]}`]: `${signedFileStoreId}` };
                }) || null
        };

        // if ((!comment && !status.isEmployeeAssignee) || errors.emails) {
        //     setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });

        //     return;
        // }

        if (!values.notes && !approvedEsign?.length) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('ENTER_NOTES', 'Please enter Notes!'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            if (activeIndex <= totalPages && formIsUpdatable) {
                await ADEVERTISMENT_WORKFLOW[`page1`].validate(values, { abortEarly: false });

                if (!signedFileStoreId && status.isEsignRequired) {
                    esign(payload, status.action);
                    return;
                }

                if (rowData.length) {
                    POST(endpoints.estateWorkflowInitiate, payload)
                        .then(res => {
                            if (res.data) {
                                const successMessage = getSuccessMessage(status?.action, advNo);
                                setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: successMessage, img: check, type: 'success', reUpload: 'OK' });
                            }
                        })
                        .catch((err: any) => {
                            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                            setModalDetails({ title: t('ERROR', 'Error!'), show: true, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                        });
                } else {
                    setModalDetails({
                        title: t('ERROR', 'Error!'),
                        show: true,
                        para: t('CONTINUE_ADVERTISEMENTS', 'You cannot continue advertisement without seleceting any plot(s)'),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                }
            }
        } catch (e) {
            let errorField = getFirstErrorKey(errors);
            console.log(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        if (designation === 'DGM') {
            await updateAdvertisement();
        }
        const payload = {
            RequestInfo: requestInfo(),
            idcWfDocument: {
                businessId: advNo,
                docRefId: file?.referenceId || '',
                uploadedUserRole: designationName,
                processInstanceId: historyDetails[0].id
            }
        };
        try {
            const res1 = await POST(estateEndpoints.documentCreate, payload);
            setAdvertisementid(res1.data.uuid);

            toast(t('DOUMENT_UPLOADED', 'Document Upload Successfully'), {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar',
                type: 'success'
            });

            const res2 = await POST(endpoints.documentSeach + `?businessId=${advNo}&businessService=ADV`, { RequestInfo: requestInfo() });
            docsTableData(res2?.data?.documents);
            setActiveIndex(2);
        } catch (err) {
            console.log(err);
        }
    };

    const updateAdvertisement = async () => {
        const emails = values.emails?.map((item: any) => item.emailId);
        const RequestInfo = {
            RequestInfo: requestInfo(),
            uuid: values.uuid,
            plotIds: rowData.map((e: any) => e.uuid || null),
            advNo: values.advNo,
            publicationDate: values?.publicationDate || '',
            submissionStartDate: values?.submissionStartDate || '',
            submissionEndDate: values?.submissionEndDate || '',
            status: values.status,
            docReferenceId: null,
            content: content,
            additionalDetails: emails
                ? {
                      notificationDetails: {
                          emailIds: emails
                      }
                  }
                : null
        };

        console.log('RequestInfo:', RequestInfo);
        try {
            const response = await POST('/idc-estate-service/advertisement/_update', RequestInfo);
            if (response && response.data && response.data.advertisementsDetails) {
                setData(response?.data?.advertisementsDetails[0]);
                setShowSaveModal(false);
                console.log('API call successful:', response);
            } else {
                console.error('Invalid response structure:', response);
            }
        } catch (error: any) {
            setModalDetails({
                title: t('ERROR', 'Error!'),
                show: true,
                para: t('PLOT_FOR_ADVERTISEMENT', 'There should be at least 1 plot for advertisement'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            // setContent('');
        }
    };

    const fetchAdvertisementData = async () => {
        const payload = { RequestInfo: requestInfo() };

        try {
            const res = await POST(`/idc-estate-service/advertisement/_search?advNos=${advNo}`, payload);
            setApplicationEstateData({ ...res?.data?.advertisementsDetails[0], docReferenceId: signedFileStoreId ? signedFileStoreId : res?.data?.advertisementsDetails[0].docReferenceId });
            setData(res?.data?.advertisementsDetails[0]);
            setShowSaveModal(false);
            console.log('Advertisement autopolulate:', res.data);
        } catch (err) {
            console.log(err);
        }
    };

    const setData = (data: any) => {
        setContent(data.content);
        const plotData = data.advertisementPlotMappings?.map((plotItem: any) => plotItem.plotResponse);
        setRowData(plotData || []);
        setSelectedvacantPlots([]);

        setValues({
            ...values,
            ...data,
            submissionEndDate: data.submissionEndDate || '',
            submissionStartDate: data.submissionStartDate || '',
            publicationDate: data.publicationDate || '',
            docReferenceId: data.docReferenceId,
            emails: data.additionalDetails?.notificationDetails?.emailIds?.map((item: string) => ({ emailId: item }))
        });
    };

    const onChangeAction = (e: any, field: string) => {
        if (field === 'publicationDate' && values.publicationDate !== e) setShowSaveModal(true);
        if (field === 'submissionStartDate' && values.submissionStartDate !== e) setShowSaveModal(true);
        if (field === 'submissionEndDate' && values.submissionEndDate !== e) setShowSaveModal(true);
        setFieldTouched(field, true);
    };

    const docsTableData = (docs: any) => {
        const responseTableData = docs.map((data: any, ind: number) => {
            return { ...data, serialNo: `C${docs.length - ind}` };
        });
        // const reverseData = responseTableData.reverse();
        setTabelData(responseTableData);
    };

    const handleModalClose = (type: string, isUnauthorized: boolean) => {
        setModalDetails({});
        if (type === 'success' && !isUnauthorized) {
            navigate('/goa-idc/dashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            }
        }
    };

    return (
        <>
            {modalData && isFilePreviewModal && (
                <GenericPopupModal
                    title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                    primaryAction={() => {}}
                    secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                    isVisible={isFilePreviewModal}
                    isPrimaryBtn={false}
                    isSecondaryBtn={false}
                    size={'xl'}
                    class='file-viewer-popup'
                >
                    <div className='popup-children'>
                        {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                        {!isPDF && <GenericImageViewer />}
                    </div>
                </GenericPopupModal>
            )}
            <HeaderComponent />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
            />
            <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                <GenericHeader
                    text={t('ADVERTISEMENT', 'Advertisement')}
                    additionalClass='sub-text-style'
                    bottomSubText={` ${t('Type', `Type : ${'Advertisement'}`)} ${advNo ? t('', `, No : ${advNo}`) : ''}`}
                />
                <FormikProvider value={formik}>
                    <div className='col-sm-3 mt-3 stickyCard-section '>
                        <div className='stickyCard' style={{ top: '20%' }}>
                            <Card className={`card_border hiddenNav ${isVisible ? '' : 'scrolledNav'}`}>
                                <Card.Body>
                                    <div className='d-flex align-items-center'>
                                        <GenericBackButton />
                                        <div className='ps-1'>
                                            <Card.Text className='font-inter-med fs-5'>{t('ALLOTMENT_APPLICATION', 'Allotment Application')}</Card.Text>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            {/* <p className='noting-heading my-1'>Noting</p> */}
                            {/* <NotingComponent history={historyDetails} disable={!values.isUpdatable} text={comment} handleOnFileUpload={handleOnFileUpload} /> */}
                            <NotingWrapper
                                applicationNumber={advNo}
                                history={historyDetails}
                                disable={isViewOnly ? isViewOnly : !nextValidAction?.nextValidAction.length}
                                text={comment}
                                handleOnFileUpload={handleOnFileUpload}
                                designation={designation}
                                nextValidAction={nextValidAction}
                            />
                        </div>
                    </div>

                    <div className='col-sm-9 mt-3 form-section '>
                        {/* <p className='correspondence-heading my-1'>Correspondence</p> */}
                        <CorrespondenceWrapper applicationNumber={advNo} designation={designation} nextValidAction={nextValidAction} />

                        <Form>
                            {activeIndex === 1 && (
                                <Card className='card_border p-1'>
                                    <AdvWorkflow
                                        onChangeAction={onChangeAction}
                                        setRowData={setRowData}
                                        rowData={rowData}
                                        content={content}
                                        setContent={setContent}
                                        setSelectedvacantPlots={setSelectedvacantPlots}
                                        selectedVacantPlots={selectedVacantPlots}
                                        setVacantPlotRowData={setVacantPlotRowData}
                                        vacantPlotRowData={vacantPlotRowData}
                                        updateAdvertisement={updateAdvertisement}
                                        fetchAdvertisementData={fetchAdvertisementData}
                                        showSaveModal={showSaveModal}
                                        setShowSaveModal={setShowSaveModal}
                                        designation={designation}
                                        nextValidAction={nextValidAction}
                                        isDisabled={isDisabled}
                                        isViewOnly={isViewOnly}
                                    />
                                </Card>
                            )}
                            {activeIndex === 2 && (
                                <div className='p-4'>
                                    <GenericActionTable columns={DOC_TABLE_COLUMN} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                </div>
                            )}
                        </Form>
                    </div>

                    <GenericPopupModal
                        title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                        primaryBtnTitle={t('CONFIRM', 'Confirm')}
                        secondaryBtnTitle={t('CLOSE', 'Close')}
                        isSecondaryBtn={true}
                        secondaryAction={() => setEsignModal(false)}
                        primaryAction={signDocument}
                        isVisible={esignModal}
                        children={<EsignModal />}
                    />
                </FormikProvider>
                <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                    <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                        <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === 2 && `rotate-class`}`} />
                    </span>
                    Page {activeIndex} of {totalPages}
                    <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                        <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                    </span>
                </div>
            </div>

            {!isViewOnly ? (
                <GenericFooterComponent
                    onCancel={() => {}}
                    activeIndex={activeIndex}
                    handleOnPrimaryAction={onApply}
                    onSubmit={() => {}}
                    applicationNo={advNo || null}
                    nextValidAction={nextValidAction}
                    stepperLength={0}
                />
            ) : (
                <></>
            )}

            <ScrollToTop />
        </>
    );
}
