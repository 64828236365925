import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { Card } from 'react-bootstrap';
import { Field, FormikProvider, Form, useFormik } from 'formik';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { t } from 'i18next';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { ENTERPRISE_SEARCH_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { check, cross } from '../../../../utils/Logo';
import { endpoints, getDropdownOptions, getUserRoles, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getFirstErrorKey } from '../../../../utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { TASKREASSIGNMENT_SEARCH_TABLE } from './TaskReassignment.const';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { REASSIGN_FORM } from '../EmployeeModule/EmployeeMapping/EmployeeMapping.const';

export default function TaskReassignmentSearch() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isChecked, setIsChecked] = useState([false, false, false]);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [openIndex, setOpenIndex] = useState<any>(null);
    const [employeeRecord, setEmployeeData] = useState<any>(null);
    const [appNo, setApplicationNo] = useState<string[]>([]);
    const [dropdownData, setDropDownData] = useState<any>({});
    // const [employeeType, setEmployeeType] = useState<string>('');
    const [tableData, setTableData] = useState<any>([]);
    const [showAssignEmployeeModal, setShowAssignEmployeeModal] = useState<boolean>(false);
    const [employeeDataView, setEmployeeDataView] = useState<boolean>(false);
    const [isReassignDisabled, setReassignDisabled] = useState<boolean>(true);

    let navigate = useNavigate();
    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;
    const location: any = useLocation();
    const employeeData = location?.state?.data;
    let searchedEmployee: any;
    // let isReassignDisabled: boolean = true;
    let designation: any;
    let department: any;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {}
        // validationSchema: ENTERPRISE_SEARCH_VALIDATION
    });

    const { values, setValues, errors, setFieldTouched } = formik;

    const onReset = () => {
        setValues((prev: any) => ({ ...prev, employeeCode: '', employeeDepartment: '', employeeDesignation: '' }));
        setTableData([]);
    };
    const onClose = async () => {
        //debugger
        setShowAssignEmployeeModal(false);
        const dropdownOptions = await getDropdownOptions();
        const { data } = await getUserRoles();
        setDropDownData(dropdownOptions);
        setValues((prev: any) => ({ ...prev, employeeId: '', employeeName: '', notes: '', docReferenceId: '', isUpdatable: true }));
        // setEmployeeType('');
        setEmployeeDataView(false);
        // setReassignDisabled(true);
    };
    useEffect(() => {
        (async () => {
            try {
                //debugger
                const dropdownOptions = await getDropdownOptions();
                const { data } = await getUserRoles();
                setDropDownData(dropdownOptions);
            } catch (error) {
                console.error('Error fetching dropdown options:', error);
            }
        })();
    }, []);
    const onSearch = async () => {
        searchedEmployee = values.employeeCode;
        const queryParams = new URLSearchParams();
        if (values?.employeeCode) queryParams.append('codes', values.employeeCode);
        if (values?.employeeDepartment) queryParams.append('departments', values.employeeDepartment);
        if (values?.employeeDesignation) queryParams.append('designations', values.employeeDesignation);
        if (!values?.employeeCode) {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('PLEASE_ENTER_EMPLOYEE_ID', 'Please enter Employee ID'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            setTableData([]);
            return;
        } else if (!values?.employeeDepartment) {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('PLEASE_ENTER_EMPLOYEE_DEPARTMENT', 'Please enter Employee Department'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            setTableData([]);
            return;
        } else if (!values?.employeeDesignation) {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('PLEASE_ENTER_EMPLOYEE_DESIGNATION', 'Please enter Employee Designation'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            setTableData([]);
            return;
        }

        try {
            await ENTERPRISE_SEARCH_VALIDATION.validate(values, { abortEarly: false });

            const { data: employeeData } = await POST(endpoints.searchEmployee + `&${queryParams.toString()}`, { RequestInfo: requestInfo() });
            if (employeeData?.Employees?.[0]?.uuid) {
                const employeeUUID = employeeData.Employees[0].uuid;
                setEmployeeData(employeeData.Employees[0]);
                designation = employeeData?.Employees?.[0]?.assignments[0]?.designation;
                const matchingRoleCodes =
                    employeeData?.Employees?.flatMap((employee: any) =>
                        employee?.assignments
                            ?.filter((assignment: any) => assignment.department === values.employeeDepartment)
                            .flatMap((assignment: any) => assignment.roles?.map((role: any) => role.code) || [])
                    )?.join(',') || '';
                console.log('roles==', matchingRoleCodes);
                department = employeeData?.Employees?.[0]?.assignments[0]?.department;

                try {
                    let secondApiUrl = `/egov-workflow-v2/egov-wf/_inbox?roleCheck=false&assignee=${employeeUUID}&currentActionRoleCodes=${matchingRoleCodes}&tenantId=ga&isPendencyRequired=true`;
                    const { data } = await POST(secondApiUrl, { RequestInfo: requestInfo() });
                    console.log('Second API data:', data);
                    if (data && data.inboxs?.length) {
                        const tempTableData = data.inboxs.map((item: any) => ({
                            isChecked: false,
                            id: item?.applicationNo,
                            applicationType: item?.applicationType || '',
                            entityName: item?.entityName || '',
                            applicationNo: item?.applicationNo || '',
                            assignedDate: item?.assignedDate || '',
                            currentOwner: `${item?.currentOwner || ''} - ${item?.currentOwnerDesig || ''}` || '',
                            status: item?.status || '',
                            deskPendencyDays: item?.deskPendencyDays || ''
                        }));
                        setTableData(tempTableData);
                    } else {
                        setModalDetails({
                            show: true,
                            title: t('ERROR', 'Error!'),
                            para: t('NO_DATA_FOUND', 'No data found'),
                            img: cross,
                            type: 'error',
                            reUpload: 'OK'
                        });
                        setTableData([]);
                    }
                } catch (e: any) {
                    const errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message || 'Error fetching pendency data.';
                    setModalDetails({
                        show: true,
                        title: 'Error!',
                        para: errMsg,
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                }
            } else {
                setModalDetails({
                    show: true,
                    title: t('ERROR', 'Error!'),
                    para: t('EMPLOYEE_DOES_NOT_EXIST', 'Employee does not exist.'),
                    img: cross,
                    type: 'error',
                    reUpload: 'OK'
                });
                setTableData([]);
            }
        } catch (e: any) {
            const errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                const getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('VALID_DETAILS', 'Please fill in valid details'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
        }
    };
    useEffect(() => {
        if (userType === 'EMPLOYEE') {
            try {
                const fetchData = async () => {
                    setIsLoading(false);
                    const { data } = await POST(`${endpoints.searchEmployee}&designations=${values?.Designation?.code}&departments=${values?.Department?.code}`, { RequestInfo: requestInfo() });
                    const employeeId = data?.Employees?.filter((val: any) => {
                        return val?.code !== values.employeeCode;
                    }).map((val: any) => ({
                        name: val?.code,
                        code: val?.user?.name,
                        userUuid: val?.uuid
                    }));

                    const employeeName = data?.Employees?.filter((val: any) => {
                        // Find the employee whose code matches the selected employeeCode
                        const excludedEmployee = data?.Employees?.find((val: { code: any }) => val?.code === values.employeeCode);

                        // Exclude the name corresponding to the matched code
                        return val?.user?.name !== excludedEmployee?.user?.name;
                    }).map((val: any) => ({
                        name: val?.user?.name,
                        code: val?.code,
                        userUuid: val?.uuid
                    }));

                    setDropDownData({ employeeId, employeeName });
                    setIsLoading(false);
                };

                (async () => {
                    await fetchData();
                })();
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    }, [showAssignEmployeeModal]);

    const customHandleChange = (e: any, field: any) => {
        if (field === 'employeeId') {
            const currentEmployeeName = dropdownData?.employeeName?.find((val: any) => val?.name === e.value);
            setValues((prev: any) => ({ ...prev, employeeName: currentEmployeeName, employeeId: { name: e.label, code: e.value } }));
        }
        if (field === 'employeeName') {
            const currentEmployeeId = dropdownData?.employeeId?.find((val: any) => val?.name === e.value);
            setValues((prev: any) => ({ ...prev, employeeName: { name: e.label, code: e.value }, employeeId: currentEmployeeId }));
        }
    };
    const showForm = () => {
        setOpenIndex(null);
        setShowAssignEmployeeModal(true);
        // setEmployeeType(employee);
    };
    const handleModalClose = (type: string) => {
        setModalDetails({});
    };
    const addEmployeeData = async () => {
        if (!values.notes) {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('NOTING_FIELD_CANNOT_BE_BLANK', 'Noting field cannot be blank'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            return;
        }
        const selectedEmployee = dropdownData?.employeeId?.find((val: any) => val?.name === values?.employeeId.name);
        //debugger;
        const payload = {
            RequestInfo: requestInfo(),
            businessIds: appNo || '',
            assigneeUuid: selectedEmployee?.userUuid || '',
            notes: values.notes || '',
            docReferenceId: values?.referenceDoc?.docReferenceId || ''
        };
        // const dropdownOptions = await getDropdownOptions();
        //         const { data } = await getUserRoles();
        //         setDropDownData(dropdownOptions);

        try {
            await POST(`/egov-workflow-v2/egov-wf/task-re-assignment/_initiate`, payload);
            setShowAssignEmployeeModal(false);
            setModalDetails({ show: true, title: 'Success!', para: 'Employee re-assigned successfully', img: check, type: 'success', reUpload: 'OK' });
            setTimeout(() => {
                navigate('/goa-idc/dashboard');
            }, 3000);
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            setReassignDisabled(true);
        }
    };

    const onCheckHandler = (e: any) => {
        console.log('Status of e==', e);
        const appNo = Object.values(e).map(value => String(value));
        setApplicationNo(appNo);
        setSelectedRows(e);
        if (e.length > 0) {
            setReassignDisabled(false);
        } else {
            setReassignDisabled(true);
        }
    };

    const reassign = () => {
        const matchedDepartment = dropdownData?.department?.find((data: any) => data?.code === employeeRecord?.assignments[0]?.department);

        const matchedDesignation = dropdownData?.designation?.find((data: any) => data?.code === employeeRecord?.assignments[0]?.designation);

        setValues((prev: any) => ({
            ...prev,
            Designation: {
                code: matchedDesignation?.code,
                name: matchedDesignation?.name
            },
            Department: {
                code: matchedDepartment?.code,
                name: matchedDepartment?.name
            }
        }));
        showForm();
    };
    console.log('Values==', values, designation, department);
    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={t('TASKREASSIGNMENT_SEARCH', 'Task Reassignment')} />
                        <div className='col-13 form-section'>
                            <Card className='card_border'>
                                <FormikProvider value={formik}>
                                    <Card.Body>
                                        <Form>
                                            <div className='row gx-3 gy-4 px-3 py-1 '>
                                                <div className='col-sm-4 d-flex flex-column'>
                                                    <label htmlFor='employeeId' className='search_application_label mb-2' style={{ fontSize: '14px', fontWeight: '400', fontFamily: 'Inter' }}>
                                                        {t('EMPLOYEE_ID', 'Employee ID')}
                                                        <span className='text-danger'>*</span>
                                                    </label>
                                                    <Field name='employeeCode' type='text' placeholder='' className='form-control' />
                                                </div>
                                                <div className='col-sm-4 d-flex flex-column '>
                                                    <label htmlFor='employeeDepartment' className='search_application_label mb-2' style={{ fontSize: '14px', fontWeight: '400', fontFamily: 'Inter' }}>
                                                        {t('DEPARTMENT', 'Department')}
                                                        <span className='text-danger'>*</span>
                                                    </label>
                                                    <Field name='employeeDepartment' className='form-control' as='select'>
                                                        <option value='' disabled selected hidden>
                                                            {t('SELECT', 'Select')}
                                                        </option>
                                                        {dropdownData?.department?.map((data: any, index: number) => (
                                                            <option value={data?.code}>{data?.name}</option>
                                                        ))}
                                                    </Field>
                                                    {/* <ErrorMessage name="employeeStatus">{(msg) => <span className="text-danger font-12">{msg}</span>}</ErrorMessage> */}
                                                </div>
                                                <div className='col-sm-4 d-flex flex-column '>
                                                    <label htmlFor='employeeDesignation' className='search_application_label mb-2' style={{ fontSize: '14px', fontWeight: '400', fontFamily: 'Inter' }}>
                                                        {t('DESIGNATION', 'Designation')}
                                                        <span className='text-danger'>*</span>
                                                    </label>
                                                    <Field name='employeeDesignation' className='form-control' as='select'>
                                                        <option value='' disabled selected hidden>
                                                            {t('SELECT', 'Select')}
                                                        </option>
                                                        {dropdownData?.designation?.map((data: any, index: number) => (
                                                            <option value={data?.code}>{data?.name}</option>
                                                        ))}
                                                    </Field>
                                                    {/* <ErrorMessage name="employeeStatus">{(msg) => <span className="text-danger font-12">{msg}</span>}</ErrorMessage> */}
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='d-flex justify-content-end pe-2 mt-3'>
                                            <GenericButtons text={t('RESET', 'Reset')} handleClick={onReset} />
                                            <GenericButtons text={t('SEARCH', 'Search')} handleClick={onSearch} variant='primary' />
                                        </div>
                                    </Card.Body>

                                    <Card.Body>
                                        {tableData.length > 0 && <h3 className='font-20 project-primary-color lh-base mb-3'>Search Result</h3>}
                                        {tableData.length ? (
                                            <GenericActionTable
                                                columns={TASKREASSIGNMENT_SEARCH_TABLE}
                                                rowData={tableData}
                                                hideExportBtn={true}
                                                hideSearchBar={false}
                                                searchBarPlaceholder={'Search by Application Number, Application Type'}
                                                onCheckHandler={onCheckHandler}
                                                checkType='multiCheck'
                                            />
                                        ) : (
                                            <></>
                                        )}
                                        <footer className='footer fixed-bottom footerBtnPatch'>
                                            <div className='d-flex justify-content-end mt-3 mb-2'>
                                                {tableData.length ? <GenericButtons text={t('REASSIGN', 'Re-Assign')} variant='primary' disabled={isReassignDisabled} handleClick={reassign} /> : <></>}
                                            </div>
                                        </footer>
                                    </Card.Body>
                                    <GenericPopupModal
                                        title={`${employeeDataView ? t('VIEW_EMPLOYEE', 'View Employee') : t('TASK_REASSIGNMENT', 'Task Re-Assignment')}`}
                                        size='lg'
                                        primaryAction={addEmployeeData}
                                        secondaryAction={onClose}
                                        isVisible={showAssignEmployeeModal}
                                        children={<RENDER_REASSIGN_FORM dropdownData={dropdownData} customHandleChange={customHandleChange} />}
                                        primaryBtnTitle={employeeDataView ? t('CLOSE', 'Close') : t('SUBMIT', 'Submit')}
                                        secondaryBtnTitle={t('CLOSE', 'Close')}
                                        onClose={onClose}
                                        isPrimaryBtn={values.isUpdatable}
                                    />
                                </FormikProvider>
                            </Card>
                        </div>
                    </div>
                </>
            )}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
        </>
    );
}
const RENDER_REASSIGN_FORM = ({ dropdownData, customHandleChange }: any) => {
    return (
        <>
            {REASSIGN_FORM?.map((val: any) => (
                <GenericFormCard {...val} dropdownOptions={dropdownData} customHandleChange={customHandleChange} />
            ))}
        </>
    );
};
