import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../components/header/Header.component';
import Loading from '../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { FormikProvider, useFormik } from 'formik';
import { Card, Form } from 'react-bootstrap';
import GenericBackButton from '../../../components/UI components/Buttons/GenericBackButton';
import SideCard from '../../../components/moduleCommonFormik/SideCardGenericComponent';
import {
    APPLICANT_DETAILS,
    APPLICANT_DETAILS_SEARCH,
    BANK_DETAILS,
    initialValues,
    MIGRATION_FORM_STEPS_PAYMENT,
    PLOT_DETAILS_PAYMENTs,
    PROMOTER_DETAILS1,
    SUB_LEASE_FEE_PAYMENT
} from './Migration.const';
import { check, cross } from '../../../utils/Logo';
import StatusAlertPopup from '../../../components/moduleCommonFormik/StatusAlertPopup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import GenericFormCard from '../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericOwnerShipDetails from '../../../components/Ownership/GenericOwnership';
import { PaymentDetails } from './pages/PaymentDetails';
import { SublLeasePayment } from './pages/SubLeasePayment';
import { dropdownDataOptions, getFormatedOwnershipDetails, getInstallmentScheduleTable, MIGRATION_UPDATE_PAYMENT_UPDATE_PAYLOAD, populateMigrationData } from './Migration.controller';
import { endpoints, POST, requestInfo } from '../../../services/modulesServices/userManagement.api';
import { getFormatedNICDetails } from '../EstateManagement/Allotment/Allotment.controller';
import GenericButtons from '../../../components/UI components/Buttons/GenericButtons';
import { MIGRATION_PAYMENT_UPDATE_VALIDATION } from '../../../utils/formikValidation/FormikValidation';
import { convertDateToDDmmYYYY, getFirstErrorKey } from '../../../utils/utils';
import GenericPopupModal from '../../../components/moduleCommonFormik/GenericModalPopup.component';

const MigrationPaymentUpdate = () => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const entityId = searchParams.get('entityId');
    const plotId = searchParams.get('plotId');
    const activeIndexOnReset = searchParams.get('activeIndex');

    const [loading, setLoading] = useState<any>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [visited, setVisited] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [showOwnershipModal, setShowOwnershipModal] = useState<boolean>(false);
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]); //setOwnershipdata refer MiscellaneousWorkflow line 114
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]); //setOwnershipdata refer MiscellaneousWorkflow line 115
    const [paymentDisableCheck, setPaymentDisableCheck] = useState<any>(true); //migration form line 325
    const [installmentTableRowData, setInstallmentTableRowData] = useState<any>([]); //migration form line 177
    const [showInstallmentTable, setShowInstallmentTable] = useState<boolean>(false); //migration form line 176
    const [dropdownData, setDropdownData] = useState<any>(null); //migration form 1157
    const [migrationResponse, setMigrationResponse] = useState<any>({});
    const [subleaseNicDetails, setSubleaseNicDetails] = useState<any>({ 0: [] });
    const [showResetModal, setShowResetModal] = useState<boolean>(false);
    const [checkBoxItemList, setCheckBoxItemList] = useState<any>(null);
    const [isChecked, setIsChecked] = useState<any>([]);
    const [checkedPaymentResetOptions, setCheckedPaymentResetOptions] = useState<any>([]);
    const [migrationPaymentResetRequest, setMigrationPaymentResetRequest] = useState<any>([]);
    const [index, setIndex] = useState<any>([]);

    const formik: any = useFormik<any>({
        initialValues: initialValues,
        onSubmit: value => {},
        validationSchema: MIGRATION_PAYMENT_UPDATE_VALIDATION[`page${activeIndex}`],
        enableReinitialize: true
    });

    const { values, setValues, setFieldValue, errors, setFieldTouched } = formik;

    useEffect(() => {
        if (activeIndexOnReset) {
            setActiveIndex(+activeIndexOnReset);
            setPaymentDisableCheck(false);
        }
        (async () => {
            const dropdownOptions = await dropdownDataOptions();
            let waterChargeMeterNo: any = [];

            if (dropdownOptions && plotId) {
                try {
                    setLoading(true);
                    const { data } = await POST(endpoints.searchMigration + `?plotIds=${plotId}&entityIds=${entityId}`, { RequestInfo: requestInfo() });
                    setMigrationResponse(data?.estateMigrationData[0]);

                    const paymentData = Object.values(
                        data?.estateMigrationData[0]?.paymentDetails?.payments?.reduce((acc: any, { paymentType }: any) => {
                            if (!acc[paymentType]) {
                                acc[paymentType] = { paymentType: paymentType, title: displayPaymentType(paymentType), entityId: '', isSubLessee: false };
                            }
                            return acc;
                        }, {})
                    );

                    let subleaseCheckedBoxItems: any = [];

                    if (data?.estateMigrationData[0]?.subLeaseDetails) {
                        data?.estateMigrationData[0]?.subLeaseDetails?.map((val: any, index: number) => {
                            const tempSubleaseCheckedBoxItems = Object.values(
                                val?.paymentDetails?.reduce((acc: any, { paymentType }: any) => {
                                    if (!acc[paymentType]) {
                                        acc[paymentType] = {
                                            paymentType: paymentType,
                                            title: `Sub-Lease ${index + 1}: ${val?.subLeaseEntityDetails?.entityName}, ${convertDateToDDmmYYYY(
                                                val?.subLeaseTermStartDate?.split('T')?.[0]
                                            )} - ${displayPaymentType(paymentType)}`,
                                            entityId: val?.subLeaseEntityDetails?.uuid,
                                            isSubLessee: true
                                        };
                                    }
                                    return acc;
                                }, {})
                            );
                            subleaseCheckedBoxItems = [...subleaseCheckedBoxItems, ...tempSubleaseCheckedBoxItems];
                        });
                    }

                    const incompleteResetData = data?.estateMigrationData?.[0]?.paymentDetails?.migrationPaymentResetRequest?.filter((val: any) => !val?.isResetCompleted);

                    setMigrationPaymentResetRequest(incompleteResetData ? incompleteResetData : []);
                    let tempCheckBoxItemList = [...paymentData, ...subleaseCheckedBoxItems, ...(incompleteResetData ? incompleteResetData : [])];
                    setCheckBoxItemList(tempCheckBoxItemList);

                    if (incompleteResetData.length > 0) {
                        setCheckedPaymentResetOptions(incompleteResetData);
                        let indexes: any = [];
                        tempCheckBoxItemList?.map((val: any, ind: number) => {
                            incompleteResetData?.map((item: any) => {
                                if (item.title === val.title) {
                                    indexes.push(ind);
                                }
                            });
                        });
                        setIndex(indexes);

                        let res = new Array(Math.max(...indexes) + 1).fill(false);
                        indexes.forEach((i: any) => {
                            res[i] = true;
                        });

                        setIsChecked(res);
                    }

                    const res = await populateMigrationData(data?.estateMigrationData[0], dropdownOptions);

                    data?.estateMigrationData[0]?.otherDetails?.waterConnectionMigrationDetailsList?.map((val: any, ind: number) =>
                        waterChargeMeterNo.push({ name: val?.meterNo, code: val?.meterNo })
                    );

                    const newOwnership = await getFormatedOwnershipDetails(data?.estateMigrationData[0].applicantDetails.owners, dropdownOptions);
                    setOwnershipDetails(newOwnership);
                    setControllingOwnershipResponse(data?.estateMigrationData[0].applicantDetails.owners);

                    let nameOfAccountHolder: any;
                    if (data?.estateMigrationData?.[0]?.applicantDetails?.category === 'PROP_FIRM') {
                        if (newOwnership?.length) {
                            nameOfAccountHolder = newOwnership[0]?.name;
                        } else {
                            nameOfAccountHolder = '';
                        }
                    } else {
                        nameOfAccountHolder = data?.estateMigrationData?.[0]?.applicantDetails?.entityName;
                    }

                    if (data?.estateMigrationData[0]?.subLeaseDetails) {
                        const subleaseNicDetail = await Promise.all(
                            data?.estateMigrationData[0]?.subLeaseDetails?.map(async (val: any, ind: number) => {
                                const updatedNicDetails = await getFormatedNICDetails(val?.subLeaseEnterpriseDetails?.nicEnterpriseMappings, dropdownOptions);
                                return {
                                    [ind]: updatedNicDetails
                                };
                            })
                        );
                        const nicTableData = subleaseNicDetail?.map((val: any, ind: number) => val[ind]);
                        setSubleaseNicDetails(nicTableData);
                    }

                    setValues((prev: any) => ({
                        ...prev,
                        ...res,
                        searchResponse: data?.estateMigrationData[0],
                        nameOfAccountHolder: nameOfAccountHolder
                        // subLeaseFeePayment: subLeasePaymentData
                    }));

                    if (data?.estateMigrationData[0]?.subLeaseDetails) {
                    }

                    const installmentScheduleData = data.estateMigrationData[0]?.paymentDetails?.payments?.filter((val: any) => val.paymentType === 'LAND_PREMIUM').slice(1);
                    if (installmentScheduleData?.length) {
                        const tempRowData = getInstallmentScheduleTable(installmentScheduleData);
                        setShowInstallmentTable(true);
                        setInstallmentTableRowData(tempRowData);
                    }
                    setLoading(false);
                } catch (e: any) {
                    setLoading(false);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }

            setDropdownData({
                ...dropdownOptions,
                waterChargeMeterNo: waterChargeMeterNo
            });
        })();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = MIGRATION_FORM_STEPS_PAYMENT.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                // await ALLOTMENT_FORM_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const handleModalClose = (type: string, isDelete: boolean) => {
        if (type === 'success' && !isDelete) {
            navigate('/goa-idc/dashboard');
        } else if (type === 'success' && isDelete) {
            window.location.href = `/goa-idc/migration/payment-update?plotId=${plotId}&entityId=${entityId}&activeIndex=${activeIndex}`;
        }
        setModalDetails({});
    };

    const renderApplicantDetails = () => {
        const applicantDetailsSearch = updateJson(APPLICANT_DETAILS_SEARCH);
        const applicantDetails = updateJson(APPLICANT_DETAILS);
        const promoterDetails = updateJson(PROMOTER_DETAILS1);
        const bankDetails = updateJson(BANK_DETAILS);
        return (
            <>
                <Card className='card_border'>
                    <Card.Body>
                        {applicantDetailsSearch?.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}
                        {applicantDetails?.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}
                        <GenericOwnerShipDetails
                            showOwnershipModal={showOwnershipModal}
                            setShowOwnershipModal={setShowOwnershipModal}
                            setOwnershipDetails={setOwnershipDetails}
                            ownershipDetails={ownershipDetails}
                            hideCard={true}
                            isDisabled={true}
                            controllingOwnershipResponse={controllingOwnershipResponse}
                            isWorkflow={true}
                        />
                        {bankDetails.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}
                    </Card.Body>
                </Card>
                <Card className='card_border'>
                    <Card.Body>
                        {promoterDetails.map((val: any) => (
                            <GenericFormCard module='usermanagement' {...val} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderPlotDetails = () => {
        const plotDetails = updateJson(PLOT_DETAILS_PAYMENTs);
        return (
            <>
                <Card className='card_border'>
                    <Card.Body>
                        {plotDetails.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderPaymentDetails = () => {
        return (
            <>
                <PaymentDetails
                    paymentDisableCheck={paymentDisableCheck}
                    installmentTableRowData={installmentTableRowData}
                    setInstallmentTableRowData={setInstallmentTableRowData}
                    showInstallmentTable={showInstallmentTable}
                    setShowInstallmentTable={setShowInstallmentTable}
                    dropdownData={dropdownData}
                    isPaymentUpdate={true}
                    // isUpdate={isUpdate}
                />
                {migrationResponse?.subLeaseDetails && <SublLeasePayment dropdownOptions={dropdownData} setModalDetails={setModalDetails} paymentDisableCheck={paymentDisableCheck} />}
            </>
        );
    };

    const onSubmit = async () => {
        const stepperLength = MIGRATION_FORM_STEPS_PAYMENT.length;
        if (activeIndex < stepperLength) {
            setActiveIndex(activeIndex + 1);
            window.scrollTo(0, 0);
        } else if (activeIndex === stepperLength) {
            const payload = {
                RequestInfo: requestInfo(),
                estateMigrationData: MIGRATION_UPDATE_PAYMENT_UPDATE_PAYLOAD(migrationResponse, values, subleaseNicDetails)
            };
            try {
                await MIGRATION_PAYMENT_UPDATE_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                try {
                    await POST(endpoints.updateMigration, payload);
                    setModalDetails({ show: true, title: 'Success!', para: 'Payment Updated Successfully.', img: check, type: 'success', reUpload: 'OK' });
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message || t('SOMETHING_WENT_WRONG', 'Something Went Wrong');
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            } catch (e: any) {
                let errorField = getFirstErrorKey(errors);
                if (errorField) {
                    const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                    await setFieldTouched(errorField, true, true);
                    let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                    getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    const onReset = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            entityId: entityId,
            plotId: plotId,
            migrationPaymentResetRequest: checkedPaymentResetOptions
        };

        if (checkedPaymentResetOptions?.length === 0) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('SELECT_AT_LEAST_ONE_OPTION', 'Please select at least one option'), type: 'error', img: cross, reUpload: 'OK' });
            return;
        }
        try {
            await POST(endpoints.paymentDetailsDelete, payload);
            setShowResetModal(false);
            setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: 'Payment details deleted successfully', img: check, type: 'success', reUpload: 'OK', isDelete: true });
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message || t('SOMETHING_WENT_WRONG', 'Something Went Wrong');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const ResetCheckBox = () => {
        const handleCheckboxChange = (i: any, val: any) => {
            let checks = isChecked;
            checks[i] = !isChecked[i];
            setIsChecked([...checks]);
            if (checkedPaymentResetOptions?.some((item: any) => item.title === val?.title)) {
                const filteredCheckedPaymentOptions = checkedPaymentResetOptions?.filter((items: any) => items?.title !== val?.title);
                setCheckedPaymentResetOptions(filteredCheckedPaymentOptions);
            } else {
                setCheckedPaymentResetOptions([...checkedPaymentResetOptions, val]);
            }
        };
        return (
            <>
                <div className='px-4 d-flex flex-column fw-medium col-sm-12'>
                    <p className='mt-3 file-form-label font-karla'>{t('PAYMENT_RESET_OPTIONS', 'Please select the payment options that needs to be reset')} </p>
                    {checkBoxItemList?.map((val: any, i: any) => (
                        <>
                            {/* <div className={`py-1 col-sm-6`}> */}
                            <label className='mb-3 d-flex file-form-label font-karla'>
                                <input
                                    type='checkbox'
                                    checked={isChecked[i]}
                                    onChange={() => handleCheckboxChange(i, val)}
                                    disabled={index?.includes(i)}
                                    className={index?.includes(i) ? 'default-checked' : ''}
                                />
                                <div className='flex-grow-0'>{val.title}</div>
                            </label>
                            {/* </div> */}
                        </>
                    ))}
                </div>
            </>
        );
    };

    console.log('formik', formik);

    return (
        <div>
            <HeaderComponent />
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={t('APPLICATION_MIGRATION_FORM', 'Application Migration Form')}
                            bottomSubText={`${values.migrationApplicationNumber ? `Migration application number - ${values.migrationApplicationNumber}` : ''}`}
                        />
                        <div className='col-3 stickyCard-section font-inter'>
                            <div className='stickyCard'>
                                <Card className={`card_border hiddenNav ${isVisible ? '' : 'scrolledNav'}`}>
                                    <Card.Body>
                                        <div className='d-flex align-items-center'>
                                            <GenericBackButton />
                                            <div className='ps-1'>
                                                <Card.Text className='font-inter-med fs-5'>{t('APPLICATION_MIGRATION_FORM', 'Application Migration Form')}</Card.Text>
                                                {values.migrationApplicationNumber && (
                                                    <Card.Subtitle className='text-muted text-nav font-inter p-0 m-0'>{`Migration application number - ${values.migrationApplicationNumber}`}</Card.Subtitle>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column row-gap-3  '>
                                            <SideCard steps={MIGRATION_FORM_STEPS_PAYMENT} visited={visited} activeIndex={activeIndex} handleStepClick={handleStepClick} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className='col-9 form-section'>
                            <FormikProvider value={formik}>
                                <Form>
                                    {activeIndex === 1 && renderApplicantDetails()}
                                    {activeIndex === 2 && renderPlotDetails()}
                                    {activeIndex === 3 && renderPaymentDetails()}
                                </Form>

                                <GenericPopupModal
                                    title={t('PAYMENT_RESET', 'Payment Reset')}
                                    primaryBtnTitle={t('RESET', 'Reset')}
                                    secondaryBtnTitle={t('CLOSE', 'Close')}
                                    isSecondaryBtn={true}
                                    secondaryAction={() => {
                                        setShowResetModal(false);
                                        setIsChecked([]);
                                        setCheckedPaymentResetOptions([]);
                                    }}
                                    primaryAction={onReset}
                                    isVisible={showResetModal}
                                    children={<ResetCheckBox />}
                                />
                            </FormikProvider>
                        </div>
                    </div>

                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                            </div>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {activeIndex === MIGRATION_FORM_STEPS_PAYMENT.length && (
                                    <GenericButtons
                                        text={t('RESET', 'Reset')}
                                        handleClick={() => {
                                            setShowResetModal(true);
                                        }}
                                    />
                                )}
                                <GenericButtons
                                    text={MIGRATION_FORM_STEPS_PAYMENT.length === activeIndex ? t('SUBMIT', 'submit') : t('NEXT', 'Next')}
                                    variant='primary'
                                    handleClick={onSubmit}
                                    disabled={activeIndex === 3 && paymentDisableCheck}
                                />
                            </div>
                        </div>
                    </footer>
                </>
            )}

            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.isDelete)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isDelete)}
                btnLabel={modalDetails.btnLabel}
            />
        </div>
    );
};

export default MigrationPaymentUpdate;

export const updateJson = (originalJson: any) => {
    const updatedJson = originalJson.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, isDisabled: true })) }));
    return updatedJson;
};

export const displayPaymentType = (type: string) => {
    if (type === 'LAND_PREMIUM') {
        return 'Land Premium';
    } else if (type === 'LEASE_RENT') {
        return 'Lease Rent';
    } else if (type === 'HOUSE_TAX') {
        return 'House Tax';
    } else if (type === 'WATER_CHARGES') {
        return 'Water Charges';
    } else if (type === 'SUBLEASE_FEE') {
        return 'Sub-Lease Fee';
    }
};
