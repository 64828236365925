import _ from 'lodash';
import { AUTHORIZED_PERSON_TITLE, CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import { axis, federal, hdfc, icici, pnb, sbi } from '../../../../utils/Images';
import { isDisabled } from '@testing-library/user-event/dist/utils';

export const PLOT_DETAILS: any[] = [
    {
        titleCode: 'PLOT_DETAILS',
        title: 'Plot Details',
        formData: [
            {
                title: 'Estate Name',
                titleCode: 'ESTATE_NAME',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Phase',
                titleCode: 'PHASE',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Plot No',
                titleCode: 'PLOT_NO',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                title: 'Plot Type',
                titleCode: 'PLOT_TYPE',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const ALLOTED_PLOT_DETAILS: any[] = [
    {
        titleCode: 'PLOT_DETAILS',
        title: 'Plot Details',
        formData: [
            {
                title: 'Estate Name',
                titleCode: 'ESTATE_NAME',
                field: 'allotedEstateName',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Phase',
                titleCode: 'PHASE',
                field: 'allotedPhase',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Plot No',
                titleCode: 'PLOT_NO',
                field: 'allotedPlotNo',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Plot Type',
                titleCode: 'PLOT_TYPE',
                field: 'allotedPlotType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const PAYMENT_DETAILS: any[] = [
    {
        title: 'Allotment Application Fees',
        titleCode: 'Allotment Application Fees',
        formData: []
    }
];

export const ENTERPRISE_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Category of Enterprise',
                titleCode: 'CATEGORY_OF_ENTERPRISE',
                field: 'enterpriseCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Sub-category',
                titleCode: 'SUB_CATEGORY',
                field: 'subCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                multiSelect: true,
                isRequired: true
            }
        ]
    }
];
export const PROJECT_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Whether applied under special project category',
                titleCode: 'APPLIED_UNDER_SPECIAL_PROJECT',
                field: 'specialProject',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true,
                isDisabled: true,
                renderCondition: (values: any) => _.get(values, `specialProject`) === true
            },
            {
                title: 'In-principle approval of Goa - IPB',
                titleCode: 'IN_PRINCIPLE_APPROVAL',
                field: 'inPrincipleApproval',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3',
                renderCondition: (values: any) => _.get(values, `specialProject`) === true,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.IN_PRINCIPAL_APPROVAL,
                    referenceType: DOCUMENT_TYPE.OTHER
                }
            },
            {
                title: 'Project Type',
                titleCode: 'PROJECT_TYPE',
                field: 'projectType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
            },
            {
                title: 'Is GST Applicable?',
                field: 'applicantGst',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                title: 'GST No',
                field: 'applicantGstno',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                condition1: (values: any) => _.get(values, `applicantGst`) === true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Please provide GST specific to the mentioned business activities or trade.</p>'
                }
            },
            {
                title: 'GST Document',
                titleCode: 'GST Document',
                field: 'applicantGstdocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, `applicantGst`) === true,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.GST,
                    referenceType: DOCUMENT_TYPE.ENTERPRISE
                },
                class: 'col-sm-6 me-3'
                // isDisabled: true
            },
            {
                title: 'GST Non-Applicability Undertaking',
                titleCode: 'GST_UNDERTAKING',
                field: 'entitygstUndertaking',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, `applicantGst`) === false,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.NON_GST,
                    referenceType: DOCUMENT_TYPE.ENTERPRISE
                },
                class: 'col-sm-6 me-3'
                // isDisabled: true
            },
            {
                title: 'Pollution category',
                titleCode: 'POLLUTION_CATEGORY',
                field: 'pollutionCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>As per Central Pollution Control Board guidelines</p>',
                    linkUrl: 'https://www.pollution.com',
                    linkText: 'Website Link'
                }
            },
            {
                title: 'Estimated water requirement per month (in cubic meter)',
                titleCode: 'ESTIMATED_WATER_REQUIREMENT',
                field: 'waterRequirement',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Estimated total power requirement per month(in KW/HP)',
                titleCode: 'ESTIMATED_POWER_REQUIREMENT',
                field: 'powerRequirement',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Estimated number of persons proposed to be employed ',
                titleCode: 'EMPLOYMENT_GENERATED',
                field: 'employement',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Estimated project cost (in INR)',
                titleCode: 'PROJECT_COST',
                field: 'projectCost',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                showInr: true
            },
            {
                title: 'Means of Finance',
                titleCode: 'MEANS_OF_FINANCE',
                field: 'meansOfFinance',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                multiSelect: true
            },
            {
                title: 'EMD Amount (in INR)',
                titleCode: 'EMD_AMOUNT',
                field: 'emdAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                showInr: true,
                renderCondition: (values: any) => _.get(values, `isAuction`) === true,
                class: 'col-sm-6 me-3'
            },
            {
                title: 'Supporting Document for EMD Amount',
                titleCode: 'EMD_DOCUMENT',
                field: 'emdAmountSupportingDocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.EMD_DOC,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                renderCondition: (values: any) => _.get(values, `isAuction`) === true,
                class: 'col-sm-6 me-3',
                hideTitle: true
            },
            {
                title: 'Detailed Project Report',
                titleCode: 'DETAILED_PROJECT_REPORT',
                field: 'detailedProjectReport',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                /* toolTipDetails: {
                    position: 'bottom',
                    content: '<p>In the case of Partnership, LLP, Private or Public Limited Company, any one of the Promoters may declare prior relevant experience, if any.</p>'
                }, */
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.DETAILED_PROJECT_REPORT,
                    referenceType: DOCUMENT_TYPE.OTHER
                }
            }
        ]
    }
];

export const BANK_DETAILS: FormDataDTO[] = [
    {
        titleCode: 'BANK_DETAILS',
        title: 'Bank Account Details',
        formData: [
            {
                title: 'Name of Account Holder',
                field: 'nameOfAccountHolder',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-12',
                isDisabled: true
            },
            {
                title: 'Account Number',
                field: 'accountNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'IFSC Code',
                field: 'ifscCode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Bank Name',
                field: 'bankName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Branch address',
                field: 'branchAddress',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const DECLARATION_DETAILS = [
    {
        formData: [
            {
                title: '  ',
                field: 'confirmation',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                class: 'col-xs-6 col-md-8',
                hideTitle: 'true'
            },
            {
                title: 'Confirmation from Department of Expenditure, Ministry of Finance, Government of India',
                titleCode: 'MOHA_CONFIRMATION',
                field: 'file3',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                class: 'col-xs-3 col-md-5',
                hideTitle: 'true',
                renderCondition: (values: any) => _.get(values, `confirmation`) === true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.MOHA_FDI_UNDERTAKING_APPROVAL,
                    referenceType: DOCUMENT_TYPE.OTHER
                }
            }
        ]
    }
];
export const SELF_DECLARATION_DETAILS: FormDataDTO[] = [
    {
        formData: []
    }
];

export const stepsDetails = [
    {
        titleCode: 'APPLICANT_DETAILS',
        name: "Applicant's Details",
        index: 1,
        isActive: true
    },
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'ENTERPRISE_DETAILS',
        name: 'Enterprise Details',
        index: 3,
        isActive: true
    },

    {
        titleCode: ' SELF DECLARATION',
        name: ' Self Declaration',
        index: 4,
        isActive: true
    },
    {
        titleCode: 'PAYMENT_DETAILS',
        name: 'Payment Details',
        index: 5,
        isActive: false
    }
];
export const PROPOSED_ACTIVITY_COLUMN: any = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '10%' },
        id: 1
    },
    {
        colName: 'Proposed Activity',
        fieldName: 'proposedActivity',
        cellstyle: { width: '40%' },
        id: 1,
        clsName: 'column-text-start'
    },
    {
        colName: 'National Industrial Classification (NIC) Code',
        fieldName: 'subClassAndsubClassDescription',
        cellstyle: { width: '60%' },
        id: 1,
        clsName: 'column-text-start'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg ',
        actions: ['edit', 'view', 'delete']
    }
];

export const advColumns = [
    {
        colName: 'Advertisement No.',
        fieldName: 'advNo',
        clsName: 'col-sm-3 column-text-start',
        isClickable: true
    },
    {
        colName: 'Date of Publication',
        fieldName: 'publicationDate',
        clsName: 'col-sm-3 column-text-start'
    },
    {
        colName: 'Submission Start Date',
        fieldName: 'submissionStartDate',
        clsName: 'col-sm-3 column-text-start'
    },
    {
        colName: 'Submission End Date',
        fieldName: 'submissionEndDate',
        clsName: 'col-sm-3 column-text-start'
    }
];
export const paymentColumns = [
    {
        colName: 'SN',
        fieldName: 'index',
        clsName: 'col-sm-1 my-2',
        isClickable: false
    },
    {
        colName: 'Description',
        fieldName: 'feeTypeDisplayName',
        clsName: 'col-sm-2 column-text-start my-2 '
    },
    {
        colName: 'Amount(in INR)',
        fieldName: 'amount',
        clsName: 'col-sm-2 my-2 column-text-end '
    },
    {
        colName: 'CGST(9%)',
        fieldName: 'cgstAmount',
        clsName: 'col-sm-2 my-2 column-text-end '
    },
    {
        colName: 'SGST(9%)',
        fieldName: 'sgstAmount',
        clsName: 'col-sm-2 my-2 column-text-end '
    },
    {
        colName: 'Total Amount (in INR)',
        fieldName: 'total',
        clsName: 'col-sm-3 my-2 mx-2 column-text-end'
    }
];
export const installmentColumns = [
    {
        colName: 'Installment No',
        fieldName: 'index',
        clsName: 'col-sm-2'
    },
    {
        colName: 'Due Date',
        fieldName: 'dueDate',
        clsName: 'col-sm-2'
    },
    {
        colName: 'Premium Due(INR)',
        fieldName: 'premiumDue',
        clsName: 'col-sm-2'
    },
    {
        colName: 'Interest (11% per annum)',
        fieldName: 'interest',
        clsName: 'col-sm-2'
    },
    {
        colName: 'Total and Premium (in INR)',
        fieldName: 'total',
        clsName: 'col-sm-3'
    }
];

export const paymentOptions = {
    HDFC: {
        img: hdfc,
        height: '90px'
    },
    PNB: {
        img: pnb,
        height: '60px'
    },
    ICICI: {
        img: icici,
        height: '75px'
    },
    AXIS: {
        img: axis,
        height: '75px'
    },
    FEDERAL: {
        img: federal,
        height: '75px'
    },
    SBI: {
        img: sbi,
        height: '75px'
    }
};

export const plotTableColumns = [
    {
        colName: '',
        fieldName: 'radio',
        clsName: 'col-sm-1'
    },
    {
        colName: 'Plot No.',
        fieldName: 'plotResponse.plotNo',
        clsName: 'col-sm-1',
        isClickable: true
    },
    {
        colName: 'Estate',
        fieldName: 'plotResponse.estateDetails.estateName',
        clsName: 'col-sm-2',
        isClickable: true
    },
    {
        colName: 'Phase',
        fieldName: 'plotResponse.phase',
        clsName: 'col-sm-2'
    },
    {
        colName: 'Plot Type',
        fieldName: 'plotResponse.plotType',
        clsName: 'col-sm-2'
    },
    {
        colName: 'Plot Area (Sq.m.)',
        fieldName: 'plotResponse.plotAreaSqm',
        clsName: 'col-sm-2'
    },
    {
        colName: 'Plot Rate (INR/Sq.m.)',
        fieldName: 'plotResponse.plotRate',
        clsName: 'col-sm-2 column-text-end',
        showInr: true,
        addDecimal: true
    }
];

export const PAYMENT_TYPE_DETAILS = [
    {
        formData: [
            {
                title: 'Payment Type',
                field: 'paymentType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },

            {
                title: 'Amount (in INR)',
                field: 'amount',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Down Payment',
                field: 'downPayment',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `paymentType`)?.code === 'installment'
            },
            {
                title: 'Number of installment',
                field: 'installments',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `paymentType`)?.code === 'installment'
            }
        ]
    }
];

export const paymentColumnsForTC = [
    {
        colName: 'SN',
        fieldName: 'index',
        clsName: 'col-sm-1 my-2 mx-2',
        isClickable: false
    },
    {
        colName: 'Description',
        fieldName: 'feeDescription',
        clsName: 'col-sm-2 text-start my-2 '
    },
    {
        colName: 'Amount(in INR)',
        fieldName: 'principalAmount',
        clsName: 'col-sm-2 my-2 column-text-end '
    },
    {
        colName: 'CGST(9%)',
        fieldName: 'cgstPrincipal',
        clsName: 'col-sm-2 my-2 column-text-end '
    },
    {
        colName: 'SGST(9%)',
        fieldName: 'sgstPrincipal',
        clsName: 'col-sm-2 my-2 column-text-end '
    },
    {
        colName: 'Total Amount (in INR)',
        fieldName: 'totalDueAmount',
        clsName: 'col-sm-3 my-2 column-text-end'
    }
];

export const APPLICANT_DETAILS_I = [
    {
        formData: [
            {
                title: 'Applicant Name',
                field: 'applicantName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Applicant Category',
                field: 'category',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Letter stating the name of the Proprietorship firm',
                field: 'entityProprietorshipFirm',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PROP_FIRM',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Identity of the proprietor',
                field: 'entityProprietorIdentity',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PROP_FIRM',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Trust Deed',
                field: 'trustDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => {
                    return _.get(value, `category`)?.code === 'TRUST';
                },
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Partnership Deed',
                field: 'partnershipDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PARTNERSHIP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Certificate of registration from the Registrar of Firms',
                field: 'certificateOfRegistrationFirms',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PARTNERSHIP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Certificate of incorporation from Registrar of Companies',
                field: 'certificateOfIncorporation',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'LIMITED_LIABILITY_PARTNERSHIP' || _.get(value, `category`)?.code === 'LTD_COMPANY',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Certificate of registration from the Registrar of Societies',
                field: 'certificateOfRegistrationSocieties',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'COOP_SOCIETY',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Copy of relevant notification',
                field: 'copyRelevantNotification',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'GOV_STAT_CORP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            }
        ]
    }
];

export const APPLICANT_DETAILS_II = [
    {
        formData: [
            {
                title: 'Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Phone No',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'PAN No',
                field: 'applicantPanNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                // conditionalClass: (values: any) => (_.get(values, `applicantGst`) === false ? 'me-3' : ''),
                isDisabled: true
            },
            {
                title: 'PAN Card',
                titleCode: 'PAN Card',
                field: 'applicantPancard',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                class: 'col-sm-6 d-block',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.PAN,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Address of the Applicant',
        title: 'Address Details',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-6'
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-6'
            },
            {
                title: 'State U/T',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },

            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Mailing/Correspondence address of the Applicant',
        title: 'Mailing/Correspondence address of the Entity',
        isSameAsAbove: true,
        isRequired: true,
        formData: [
            {
                title: 'Address Line 1',
                field: 'peraddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2 ',
                field: 'peraddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'perpincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-6'
            },
            {
                title: 'District',
                field: 'perdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },
            {
                title: 'State U/T',
                field: 'perstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },
            {
                title: 'Country',
                field: 'percountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Details of the Authorized Person',
        title: "Authorized Person's Details",
        toolTipDetails: {
            position: 'right',
            content: AUTHORIZED_PERSON_TITLE,
            variant: { height: '20px' }
        },
        formData: [
            {
                title: 'First Name',
                field: 'authorizedPersonFirstname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Last Name',
                field: 'authorizedPersonLastName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Primary Mobile Number',
                field: 'pmobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Alternative Mobile Number',
                field: 'alternateMobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false
            },

            {
                title: 'Email ID',
                field: 'authorizedEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
            },

            {
                title: 'The letter / resolution / power of attorney authorising the authorised person',
                titleCode: 'Please attach the letter/resolution/power of attorny ',
                field: 'letter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'authorizedPersonFirstname',
                    documentType: DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY,
                    referenceType: DOCUMENT_TYPE.AUTHUSER
                }
            }
        ]
    }
];

export const PROMOTER_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Udyam Registered Enterprise',
                titleCode: 'UDYAM_REGISTERED_ENTERPRISE',
                field: 'udhyam',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                title: 'Applicant has prior relevant experience',
                titleCode: 'PRIOR_EXPERIENCE',
                field: 'priorExperience',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>In the case of Partnership, LLP, Private or Public Limited Company, any one of the Promoters may declare prior relevant experience, if any.</p>'
                }
            },
            {
                title: 'Enter Udyam Registration Number',
                titleCode: 'UDYAM_REGISTRATION_NUMBER',
                field: 'udyamNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                renderCondition: (values: any) => _.get(values, `udhyam`) === true,
                isRequired: true
                // conditionalClass: value => (_.get(value, `udhyam`) === true && _.get(value, `isStartup`) === false ? 'mb-4' : '')
            },
            {
                title: '',
                field: '',
                type: 'none',
                renderCondition: (values: any) =>
                    (_.get(values, `priorExperience`) === true && _.get(values, `udhyam`) === false) || (_.get(values, `priorExperience`) === false && _.get(values, `udhyam`) === true)
                // class: 'col-sm-6 pb-5'
            },
            {
                title: 'Please select relevant experience',
                titleCode: 'SELECT_RELEVANT_EXPERIENCE',
                field: 'experienceCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                renderCondition: (values: any) => _.get(values, `priorExperience`) === true,
                isRequired: true
            },
            {
                title: 'Udyam Registration Certificate',
                titleCode: 'UDYAM_REGISTRATION_CERTIFICATE',
                field: 'RegCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                renderCondition: (values: any) => _.get(values, `udhyam`) === true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                class: 'pad-y-0 col-sm-6'
                // conditionalClass: value => (_.get(value, `udhyam`) === true ? 'mb-5' : '')
            },
            {
                title: '',
                field: '',
                type: 'none',
                renderCondition: (values: any) => _.get(values, `priorExperience`) === true && _.get(values, `udhyam`) === false
                // class: 'col-sm-6 pb-5'
            },
            {
                titleCode: 'RELEVANT_EXPERIENCE_CERTIFICATE',
                title: 'Prior Relevant Experience Document',
                field: 'experienceCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.OTHER_CATEGORY.EXPERIENCED,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                renderCondition: (values: any) => _.get(values, `priorExperience`) === true,
                class: 'pad-y-0 col-sm-6'
            },
            {
                title: 'Whether the enterprise is a start-up?',
                titleCode: 'IS_STARTUP',
                field: 'isStartup',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                class: 'col-sm-6 me-3'
            },
            {
                title: 'DPIIT/Government of Goa, Certificate of Recognition Certificate for Startup',
                titleCode: 'DPPIT_CERTIFICATE',
                field: 'DPIITCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                renderCondition: (values: any) => _.get(values, `isStartup`) === true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.OTHER_CATEGORY.DPIIT,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                class: 'col-sm-6'
            }
        ]
    }
];

export const PROFILE_APPLICANT_DETAILS_I = [
    {
        formData: [
            {
                title: 'Enterprise Name',
                field: 'applicantName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Enterprise Category',
                field: 'category',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            APPLICANT_DETAILS_I.slice(2)
        ]
    }
];

export const SUBLEASE_OTHER_DETAIL = [
    {
        formData: [
            {
                title: 'Udyam Registered Enterprise',
                field: 'udhyam',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                class: 'col-sm-6 me-3'
            },
            {
                title: 'Enter Udyam Registration Number',
                field: 'udyamNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                renderCondition: (values: any) => _.get(values, `udhyam`) === true,
                isRequired: true,
                // conditionalClass: value => (_.get(value, `udhyam`) === true && _.get(value, `isStartup`) === false ? 'mb-4' : ''),
                class: 'col-sm-6 me-3'
            },
            {
                title: 'Udyam Registration Certificate',
                titleCode: 'Please attach Udhyam Registration Certificate',
                field: 'RegCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                renderCondition: (values: any) => _.get(values, `udhyam`) === true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                class: 'pad-y-0 col-sm-6'
                // conditionalClass: value => (_.get(value, `udhyam`) === true ? 'mb-5' : '')
            }
        ]
    }
];

export const SUB_LESSEE_DETAILS_II = [
    {
        formData: [
            {
                title: 'Email ID',
                field: 'subLeaseEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Phone No',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'PAN No',
                field: 'applicantPanNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
                // conditionalClass: (values: any) => (_.get(values, `applicantGst`) === false ? 'me-3' : ''),
            },
            {
                title: 'PAN Card',
                titleCode: 'PAN Card',
                field: 'applicantPancard',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                class: 'col-sm-6 d-block',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.PAN,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            }
        ]
    },
    {
        titleCode: 'ADDRESS_SUB_LESSEE',
        title: 'Address of the Sub-Lessee',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-6'
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-6'
            },
            {
                title: 'State U/T',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },

            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'CORRESPONDENCE_SUB_LESSEE',
        title: 'Mailing/Correspondence address of the Sub-Lessee',
        isSameAsAbove: true,
        isRequired: true,
        formData: [
            {
                title: 'Address Line 1',
                field: 'peraddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2 ',
                field: 'peraddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'perpincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-6'
            },
            {
                title: 'District',
                field: 'perdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },
            {
                title: 'State U/T',
                field: 'perstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },
            {
                title: 'Country',
                field: 'percountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'AUTHORIZED_PERSON_DETAILS',
        title: "Authorized Person's Details",
        toolTipDetails: {
            position: 'right',
            content: AUTHORIZED_PERSON_TITLE,
            variant: { height: '20px' }
        },
        formData: [
            {
                title: 'First Name',
                field: 'authorizedPersonFirstname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Last Name',
                field: 'authorizedPersonLastName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Primary Mobile Number',
                field: 'pmobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Alternative Mobile Number',
                field: 'alternateMobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false
            },

            {
                title: 'Email ID',
                field: 'authorizedEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
            },

            {
                title: 'The letter / resolution / power of attorney authorising the authorised person',
                titleCode: 'Please attach the letter/resolution/power of attorny ',
                field: 'letter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'authorizedPersonFirstname',
                    documentType: DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY,
                    referenceType: DOCUMENT_TYPE.AUTHUSER
                }
            }
        ]
    }
];

export const OWNERSHIP_TABLE_COLUMNS: any = [
    {
        colName: 'Name',
        fieldName: 'ownerName',
        clsName: 'col-sm-3 column-text-start',
        isClickable: false
    },
    {
        colName: 'Ownership Interest(%)',
        fieldName: 'ownershipInterest',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Gender',
        fieldName: 'gender',
        clsName: 'col-sm-1 column-text-start'
    },
    {
        colName: 'Residential Address',
        fieldName: 'address',
        clsName: 'col-sm-4 column-text-start'
    },
    {
        colName: 'Contact No.',
        fieldName: 'mobileNumber',
        clsName: 'col-sm-2 column-text-start'
    }
];

export const CORRESPONDENCE_TABLE = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Uploaded By',
        fieldName: 'uploadedUserRole',
        clsName: 'col-sm-3 column-text-start',
        id: 2
    },
    {
        colName: ' Date & Time',
        fieldName: 'uploadedDate',
        clsName: 'col-sm-4 column-text-start',
        id: 3
    },
    {
        colName: ' Document',
        fieldName: 'docFileName',
        clsName: 'col-sm-4 column-text-start',
        isClickable: true,
        id: 4
    }
];

export const CHECKLIST_TABLE = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Condition',
        fieldName: 'checklistMessage',
        clsName: 'col-sm-8 column-text-start',
        id: 2
    },
    {
        colName: 'Status',
        fieldName: 'statusDisplayName',
        clsName: 'col-sm-3',
        id: 3
    }
];

export const CHECKLIST_TABLE_TC = [
    {
        colName: 'SN',
        fieldName: 'index',
        clsName: 'col-sm-2',
        id: 1
    },
    {
        colName: 'Condition',
        fieldName: 'checklistMessage',
        clsName: 'col-sm-8 column-text-start',
        id: 2
    },
    {
        colName: 'Status',
        fieldName: 'statusDisplayName',
        clsName: 'col-sm-2',
        id: 3
    }
];

export const ALLOTMENT_CORRESPONDENCE = [
    {
        title: 'A - Applicant Details',
        field: 'applicantDetails'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Enterprise Details',
        field: 'enterpriseDetails'
    },
    {
        title: 'D - Self Declaration',
        field: 'selfDeclaration'
    },
    {
        title: 'E - Payment Details',
        field: 'paymentDetails'
    }
];

export const PROVISIONAL_ALLOTMENT_STEPS = [
    {
        titleCode: 'PROVISIONAL_ALLOTMENT',
        name: 'Provisional Allotment',
        index: 1,
        isActive: true
    }
];
