import React, { useEffect, useMemo, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { Card } from 'react-bootstrap';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { t } from 'i18next';
import { ASSIGN_TRANSFEREE_SEARCH, TRANSFER_DETAILS_SEARCH, TRANSFER_FORM_STEPS, assignTransferreeColumns, transferDeclarationList, transferInitialValues } from './Transfer.const';
import { FormikProvider, useFormik } from 'formik';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { POST, POST2, endpoints, estateEndpoints, getProgressBarData, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import './Transfer.scss';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import GenericBackButton from '../../../../components/UI components/Buttons/GenericBackButton';
import { getFirstErrorKey } from '../../../../utils/utils';
import PaymentDetails from '../Allotment/PaymentDetails';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import Loading from '../../../../components/portalComponents/Loading.component';
import { fetchFeeData, fetchGatewayData, fetchLeaseDurationDoc, getNextActions, getOptions, getQueryParams, loadRazorpay, paymentCheck, paymentPayload } from '../EstateManagement.controller';
import { getEntityDocumentDetails } from '../../Migration/Migration.controller';
import { toast } from 'react-toastify';
import { check, cross } from '../../../../utils/Logo';
import { TRANSFER_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { PLOT_DETAILS_TABLE } from '../SubLease/SubLeaseConst';
import { RazorpayOptions } from '../../UserManagement/types';
import { APPLY_TRANSFER, EMPLOYEE_AUTHORIZED, KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../../../utils/constants';
import PaymentSubmissionStatus from '../../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../../components/moduleCommonFormik/ProgressBar';

const Transfer = () => {
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visited, setVisited] = useState(false);
    const [transferorDetails, setTransferorDetails] = useState<any>({});
    const [transferDetails, setTransferDetails] = useState<any>(null);
    const [selectedTransfereeDetails, setSelectedTransfereeDetails] = useState<any>({});
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [selectedOneTransferee, setSelectedOneTrasnferee] = useState<null | string>(null);
    const [searchTransfereeResponse, setSearchTransfereeResponse] = useState<any>([]);
    const [rowData, setRowData] = useState<any>([]);
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);

    const getUser = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = getUser?.type;
    let navigate = useNavigate();
    const stepsDetails = TRANSFER_FORM_STEPS;
    const [paymentData, setPaymentData] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);

    const [searchParams] = useSearchParams();
    const location: any = useLocation();
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);

    // const plotId = searchParams.get('plotId');
    // const currentIndex = searchParams.get('activeIndex');
    const applicationNumber = searchParams.get('applicationNumber');
    // const entityId = searchParams.get('entityId');

    const formik: any = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            ...transferInitialValues,
            isSpecialProject: false,
            esignOption: 'DSC'
        },
        onSubmit: value => {},
        validationSchema: TRANSFER_VALIDATION[`page${activeIndex}`]
    });
    const { values, setValues, errors, setFieldTouched } = formik;
    const [isChecked, setIsChecked] = useState([false, false, false, false, false]);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    console.log('Values', values);

    const handleStepClick = async (index: number) => {
        const stepperLength = stepsDetails.length;
        if (index > activeIndex) {
            try {
                await TRANSFER_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_FIELDS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const onChangeNextIndex = async () => {
        const stepperLength = stepsDetails.length;
        try {
            if (activeIndex < stepperLength) {
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            } else if (activeIndex === stepperLength) {
                // navigate('/goa-idc/dashboard');
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_FIELDS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const onAssignTransfereeSearch = async () => {
        console.log('user object', getUser);

        if (values.assignTransfereeName === getUser.firstName || values.assignTransfereeEmail === getUser.emailId || values.assignTransfereeMobileNo === getUser.mobileNumber) {
            setModalDetails({ show: true, title: 'Error!', para: t('SEARCH_DIFFERENT_ASSIGNEE', 'Please search for a different assignee'), img: cross, type: 'error', reUpload: 'Ok' });
            return;
        }

        const queryParams = new URLSearchParams();
        if (values.assignTransfereeName) queryParams.append('entityName', values.assignTransfereeName);
        if (values.assignTransfereeEmail) queryParams.append('email', values.assignTransfereeEmail);
        if (values.assignTransfereeMobileNo) queryParams.append('phoneNumber', values.assignTransfereeMobileNo);

        const { data } = await POST(endpoints.searchExistingUser + `?${queryParams.toString()}`, { RequestInfo: requestInfo() });
        if (data?.enterpriseEntity?.length) setSearchTransfereeResponse(data.enterpriseEntity);
        else {
            setModalDetails({ show: true, title: 'Error!', para: t('NO_RECORD_FOUND', 'No Record Found'), img: cross, type: 'error', reUpload: 'OK' });
            setSearchTransfereeResponse([]);
        }
    };

    const assignTransfereeData = useMemo(() => {
        return searchTransfereeResponse?.map((item: any) => {
            return {
                email: item.email,
                name: item.entityName,
                phoneNumber: item.phoneNumber,
                id: item.uuid,
                item
            };
        });
    }, [searchTransfereeResponse]);

    useEffect(() => {
        (async () => {
            if (activeIndex === 3 && values.plotUuid) {
                try {
                    const payCheck = await paymentCheck('PROCESSING_FEE', values.plotUuid, transferDetails?.applicationDetails.uuid);
                    setIsPaid(payCheck?.isPaid);
                    setPaymentTried(payCheck?.paymentTried);
                    setPaymentData(payCheck?.paymentData);
                    setTotalPaidAmount(payCheck?.totalPaidAmount);
                    setTransactionDetails(payCheck?.transactionDetails);
                    console.log('payCheckRes', payCheck);

                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);

                    const feeData = await fetchFeeData('TRANSFER', 'PROCESSING_FEE', values.plotUuid);
                    setPaymentData(feeData?.feeMasterDetails);
                    setTotalPaidAmount(feeData?.totalPaidAmount);
                    setPaymentIsLoading(false);
                    console.log('feeData', feeData);
                } catch (e) {
                    console.log(e);
                    setPaymentIsLoading(false);
                }
            }
        })();
    }, [activeIndex, values.plotUuid]);

    useEffect(() => {
        loadRazorpay();

        let applicationAccess;

        (async () => {
            if (applicationNumber) {
                try {
                    applicationAccess = await POST(endpoints?.applicationAccess + `?businessid=${applicationNumber}`, { RequestInfo: requestInfo() });
                    if (!applicationAccess?.data.isAuthorized) {
                        setModalDetails({
                            show: true,
                            title: t('ERROR', 'Error!'),
                            para: t('UNAUTHORIZED_ACCESS', EMPLOYEE_AUTHORIZED),
                            img: cross,
                            type: 'error',
                            reUpload: 'OK',
                            isUnauthorized: true
                        });
                    }
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }
        })();
    }, []);

    const lastSubmit = (isSaveOnly?: boolean, status?: string, comment?: string) => {
        if (activeIndex === 3 && !isPaid) onPay();
        else onSubmit(isSaveOnly);
    };

    const onSubmit = async (isSaveOnly?: boolean) => {
        if (activeIndex === 4 && !selectedTransfereeDetails?.item) {
            setModalDetails({ show: true, title: 'Error!', para: t('SELECT_ONE_ASSIGNEE', 'Please select at least one asignee'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 1 && !values.plotUuid) {
            setModalDetails({ show: true, title: 'Error!', para: t('SELECT_PLOT', 'Please select plot!'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 3 && isPaid) {
            setActiveIndex(4);
            return;
        }

        const payload: any = {
            RequestInfo: requestInfo(),
            isBuiltUpAreaUtilised: values.isBuiltUpAreaUtilised,
            isTransferProhibited: values.isTransferProhibited,
            isTransfereeDisqualified: values.isTransfereeDisqualified,
            isIndustrialCommercial: values.isIndustrialCommercial,
            plotIds: [values.plotUuid],
            isSpecialTransfer: false,
            transferorDetails,
            transfereeDetails: selectedTransfereeDetails?.item || null,
            nocSpecialProjectDocument: values.nocFromGoaIpb?.docReferenceId
        };

        if (transferDetails) {
            payload['uuid'] = transferDetails.uuid;
            payload['applicationDetails'] = transferDetails.applicationDetails;
            payload['status'] = transferDetails.status;
            payload['auditDetails'] = transferDetails.auditDetails;
        }

        if (activeIndex === 4 && selectedTransfereeDetails?.item) {
            payload['isTransferorFinalSubmit'] = true;
        }

        const apiEndPoint = transferDetails ? endpoints.transferUpdate : endpoints.transferCreate;

        try {
            await TRANSFER_VALIDATION[`page${activeIndex}`]?.validate(values, { abortEarly: false, context: values });
            if (activeIndex === 2) {
                if (isChecked[0] === false) {
                    setModalDetails({
                        show: true,
                        title: t('ERROR', 'Error!'),
                        para: t('BUILT_UP_AREA_UTILIZATION', 'Utilization of Built-Up area is less than 30% of Plot Area'),
                        img: cross,
                        type: 'error',
                        reUpload: 'Ok'
                    });
                    return;
                }
                if (isChecked[1] === false) {
                    setModalDetails({
                        show: true,
                        title: t('ERROR', 'Error!'),
                        para: t('AMOUNT_NOT_PAID', 'Entire amount of Land Premium has not been paid'),
                        img: cross,
                        type: 'error',
                        reUpload: 'Ok'
                    });
                    return;
                }
                if (!isChecked.every((e: boolean) => e)) {
                    setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('ACCEPT_ALL_TERMS', 'Please accept all the terms'), img: cross, type: 'error', reUpload: 'Ok' });
                    return;
                }
            }

            try {
                const { data } = await POST(apiEndPoint, payload);
                setTransferDetails(data?.transferDetails[0]);
                if (activeIndex === 1) {
                    toast(t('APPLICATION_CREATED', 'Application Created'), {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                } else if (activeIndex === 2) {
                    toast(t('DATA_SAVED_SUCCESSFULLY', 'Data saved successfully'), {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                }
                !isSaveOnly && onChangeNextIndex();
                if (activeIndex === 4) {
                    setModalDetails({
                        show: true,
                        title: t('SUCCESS', 'Success!'),
                        para: t('APP_ASSIGNED_SUCCESSFULLY', 'Application assigned successfully'),
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                    });
                }
            } catch (error: any) {
                let errMsg = error?.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            let errorField = getFirstErrorKey(errors);
            console.log(errors, e);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getTransactionUpdate = async (params: any, applicationId: any) => {
        try {
            if (params.plotId) {
                const paid: any = await paymentCheck('PROCESSING_FEE', params['plotId'], applicationId);
                if (!paid.isPaid) {
                    setIsLoading(true);
                    const payload = { RequestInfo: requestInfo() };
                    const { data } = await POST2(endpoints.transactionUpdate, payload, { eg_pg_txnid: params.eg_pg_txnid });
                    if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                        setTransactionDetails(data?.Transaction?.[0]);
                        if (data?.Transaction?.[0]?.txnId) {
                            try {
                                const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                                if (resp.data.status === 'SUCCESSFUL') {
                                    setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: t('PAYMENT_SUCCESSFULL', 'Payment successful'), img: check, type: 'success', reUpload: 'OK' });

                                    setIsPaid(true);
                                } else setIsPaid(false);
                                setIsLoading(false);
                                setPaymentIsLoading(false);
                            } catch (e: any) {
                                setIsPaid(false);
                                setIsLoading(false);
                                setPaymentIsLoading(false);
                            }
                        }
                    } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                        setModalDetails({
                            show: true,
                            title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                            para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                            img: cross,
                            type: 'error',
                            reUpload: 'OK'
                        });
                        // window.location.reload();
                    }
                    setIsLoading(false);
                    setPaymentIsLoading(false);
                }
            }
        } catch (e: any) {
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('TRANSACTION_FAILED', 'Transaction Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const queryParams = getQueryParams(searchParams);
            if (queryParams?.['applicationNumber']) {
                const nextActions = await getNextActions(queryParams?.['applicationNumber']);
                setHasValidAction(nextActions?.nextValidAction?.length > 0);

                if (nextActions?.nextValidAction?.length === 0) {
                    setIsChecked([true, true, true, true, true]);
                }

                queryParams?.['activeIndex'] && setActiveIndex(+queryParams?.['activeIndex']);
                const transferData: any = await fetchLeaseDurationDoc(queryParams?.['plotId'], queryParams?.['entityId']);
                const { data } = await POST(endpoints.transferSearch + `?applicationNos=${queryParams?.['applicationNumber']}`, { RequestInfo: requestInfo() });
                const nocIpb = data?.transferDetails[0]?.nocSpecialProjectDocument && (await getEntityDocumentDetails({ docReferenceId: data?.transferDetails[0]?.nocSpecialProjectDocument }));
                setTransferDetails(data?.transferDetails[0]);
                setValues({ ...values, ...transferData, nocFromGoaIpb: nocIpb || null, plotUuid: queryParams?.['plotId'] });
                setSelectedPlotDataId(queryParams?.['plotId']);
                if (queryParams?.['plotId']) {
                    setPaymentIsLoading(true);
                    getTransactionUpdate(queryParams, data?.transferDetails[0]?.applicationDetails.uuid);
                }
            }
            setIsLoading(false);
        })();
    }, [location.search]);

    console.log('isChecked', isChecked);

    const onPay = async () => {
        setIsLoading(true);
        let url =
            window.location.origin +
            `/goa-idc/estate/transfer?plotId=${values.plotUuid}&activeIndex=${activeIndex}&applicationNumber=${transferDetails?.applicationDetails?.applicationNo}&entityId=${transferorDetails.uuid}`;
        const periodTo = new Date(transferDetails?.auditDetails?.createdTime).setFullYear(new Date(transferDetails?.auditDetails?.createdTime).getFullYear() + 1);
        const payload = paymentPayload(
            transferDetails?.applicationDetails,
            values.plotUuid,
            totalPaidAmount,
            url,
            'Transfer Application Fees',
            selectedGateway,
            transferDetails.auditDetails.createdTime,
            periodTo,
            paymentData
        );

        try {
            if (!totalPaidAmount) {
                setIsLoading(false);
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('TOTAL_AMOUNT', 'Total amount must be greater than zero'), img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (!selectedGateway) {
                setIsLoading(false);
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('PAYMENT_GATEWAY', 'Please select a payment gateway'), img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (transferDetails?.applicationDetails?.applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data } = await POST(endpoints.paymentGateway, payload);

                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, getUser, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data.additionalDetails?.walletClientCode}&walletRequestMessage=${data.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
                setIsLoading(false);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setIsLoading(false);
            setModalDetails({ show: true, title: t('PAYMENT_FAILED', 'Payment Initiation Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onTransfereeSelect = (selectedTransferee: any) => {
        console.log('selectedTransferee', selectedTransferee);
        setSelectedOneTrasnferee(selectedTransferee.id);
        setSelectedTransfereeDetails(selectedTransferee);
    };

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const TransferDetails = () => {
        return (
            <div>
                <>
                    <Card className='card_border'>
                        <Card.Body>
                            {TRANSFER_DETAILS_SEARCH?.map(val => (
                                <GenericFormCard {...val} />
                            ))}

                            <div className='ps-2 py-2 d-flex justify-content-between'>
                                <div className='formTitle m-0'>
                                    {'Transferor Self Declaration'} <span className='text-danger'>*</span>
                                </div>
                            </div>

                            <div className='ps-2 d-flex flex-column fw-medium'>
                                <p className='mt-3 font-inter'>{t('AFFIRM_AND_DECLARE', 'The Transferor does hereby affirm and declare that:')}</p>
                                {transferDeclarationList.map((item, index) => (
                                    <label key={index} className='mb-3 d-flex gap-1'>
                                        <input
                                            type='checkbox'
                                            checked={isChecked[index]}
                                            onChange={() => handleCheckboxChange(index)}
                                            disabled={item.disabled}
                                            className={item.disabled ? 'default-checked' : ''}
                                        />
                                        <div className='flex-grow-0 font-inter'>{item.label}</div>
                                    </label>
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                </>
            </div>
        );
    };

    const AssignTransferee = () => {
        const assignColumns = [
            {
                colName: '',
                fieldName: 'radio',
                clsName: 'col-sm-1',
                onRadioClick: onTransfereeSelect,
                selectedValue: selectedOneTransferee
            },
            ...assignTransferreeColumns
        ];
        return (
            <div>
                <>
                    <Card className='card_border'>
                        <Card.Body>
                            {ASSIGN_TRANSFEREE_SEARCH?.map(val => (
                                <GenericFormCard {...val} />
                            ))}
                            <div className='d-flex justify-content-end mb-5'>
                                <GenericButtons
                                    text={'Reset'}
                                    variant='secondary'
                                    handleClick={() => {
                                        setValues({ ...values, assignTransfereeName: '', assignTransfereeMobileNo: '', assignTransfereeEmail: '' });
                                        setSearchTransfereeResponse([]);
                                        setSelectedOneTrasnferee(null);
                                    }}
                                />
                                <GenericButtons
                                    text={'Search'}
                                    variant='primary'
                                    disabled={!values.assignTransfereeName && !values.assignTransfereeEmail && !values.assignTransfereeMobileNo}
                                    handleClick={onAssignTransfereeSearch}
                                />
                            </div>

                            {assignTransfereeData?.length ? (
                                <Card.Body>
                                    <h3 className='Employee_search_result'>{t('SEARCH_RESULT', 'Search result')}</h3>
                                    <GenericActionTable hideExportBtn={true} columns={assignColumns} rowData={assignTransfereeData} hideSearchBar={true} />
                                </Card.Body>
                            ) : null}
                        </Card.Body>
                    </Card>
                </>
            </div>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            //Fetch Transfer Lessee Details
            try {
                const { data } = getUser.uuid && (await POST(endpoints.searchExistingUser + `?userUuid=${getUser.uuid}`, { RequestInfo: requestInfo() }));
                const transferor = data?.enterpriseEntity[0];
                // const allotmentDetails = await POST(endpoints.searchAllotment + `?applicationNos=${data?.transferDetails[0]?.applicationDetails?.applicationNo}`, { RequestInfo: requestInfo() });
                // setAllotmentDate(allotmentDetails.data.estateApplications?.[0].auditDetails.createdTime);
                if (transferor) {
                    setTransferorDetails(transferor);
                    const { entityName, phoneNumber, email } = transferor;
                    const postalAddress = transferor?.addresses?.find((address: any) => address.addressType === 'PERMANENT') || {};
                    const lesseeDataObj = { name: entityName, email, phoneNumber, ...postalAddress };
                    setValues({ ...values, ...lesseeDataObj });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setIsLoading(true);
                let appliedPlot: any = [];
                let plotDataArray: any = [];
                if (transferDetails?.plotDetails) {
                    appliedPlot = transferDetails?.plotDetails?.map((plot: any) => ({
                        estateName: plot?.estateDetails?.estateName,
                        plotType: plot?.plotType,
                        phase: plot?.phase,
                        plotNo: plot?.plotNo,
                        plotAreaSqm: plot?.plotAreaSqm,
                        uuid: plot?.uuid,
                        id: plot?.uuid
                    }));
                    onPlotSelect(appliedPlot[0]);
                }
                let { data: plotData } = await POST(endpoints.validApplication + `?type=${APPLY_TRANSFER}`, { RequestInfo: requestInfo() });
                if (plotData?.entityRefDetails?.length) {
                    let plotIds = plotData?.entityRefDetails?.map((item: any) => item.plotId);
                    plotIds = plotIds.join(',');

                    if (!plotIds) return;
                    let { data } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

                    plotDataArray = data?.plots?.map((plot: any) => ({
                        estateName: plot?.estateDetails?.estateName,
                        plotType: plot?.plotType,
                        phase: plot?.phase,
                        plotNo: plot?.plotNo,
                        plotAreaSqm: plot?.plotAreaSqm,
                        uuid: plot?.uuid,
                        id: plot?.uuid
                    }));
                }
                setRowData([...plotDataArray, ...appliedPlot]);
                console.log('plotDataArray', plotDataArray, 'appliedPlot', appliedPlot);
                // setIsLoading(false);
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                // setIsLoading(false);
                setModalDetails({ show: true, title: t('PAYMENT_FAILED', 'Payment Initiation Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (transferorDetails.uuid) fetchData();
    }, [transferorDetails, transferDetails]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const detailsData: any = await fetchLeaseDurationDoc(values.plotUuid, transferorDetails.uuid);
                setIsChecked([detailsData?.isBuiltUpAreaUtilised, detailsData?.transferOrLandPremium, false, false, false]);

                setValues((prevState: any) => ({
                    ...prevState,
                    ...detailsData
                }));
                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('PAYMENT_fAILED', 'Payment Initiation Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        values.plotUuid && transferorDetails && fetchData();
    }, [values.plotUuid, transferorDetails]);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleModalClose = (type: string, isUnauthorized: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success' && activeIndex === 4 && !isUnauthorized) {
            navigate('/goa-idc/enterprisedashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            } else if (userType === 'EMPLOYEE') {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    const onPlotSelect = (selectedPlot: any) => {
        console.log('selectedPlot', selectedPlot);
        setSelectedPlotDataId(selectedPlot.uuid);
        setValues({ ...values, plotUuid: selectedPlot.uuid });
    };

    useEffect(() => {
        if (!!applicationNumber)
            getProgressBarData(applicationNumber)
                .then(res => setProgressBarDetails(res.data))
                .catch(e => console.log(e));
    }, [applicationNumber, dropdownData]);

    console.log(formik);
    const status: any = progressBarDetails?.progressStatus.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: applicationNumber,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        // isRoleMD: isRoleMD,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };

    const plotDetailsTableColumns = PLOT_DETAILS_TABLE.map((val: any, index: number) => {
        if (index === 0) {
            return {
                ...val,
                onRadioClick: onPlotSelect,
                selectedValue: selectedPlotDataId
            };
        }
        return val;
    });

    const onClose = () => {
        navigate('/goa-idc/enterprisedashboard');
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
                    />
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={t('TRANSFER', 'Application for Transfer')}
                            bottomSubText={transferDetails ? `Type : Transfer, No : ${transferDetails?.applicationDetails?.applicationNo}` : ''}
                            additionalClass={transferDetails && 'sub-text-style'}
                        />
                        <div className='col-3 stickyCard-section font-inter'>
                            <div className='stickyCard'>
                                <Card className={`card_border hiddenNav ${isVisible ? '' : 'scrolledNav'}`}>
                                    <Card.Body>
                                        <div className='d-flex align-items-center'>
                                            <GenericBackButton />
                                            <div className='ps-1'>
                                                <Card.Text className='font-inter-med p-0 fs-5' style={{ marginBottom: -4 }}>
                                                    {t('TRANSFER', 'Application for Transfer')}
                                                </Card.Text>
                                                {transferDetails && (
                                                    <Card.Subtitle className='sub-text-style text-nav font-inter p-0 m-0'>{`Type : Transfer, No : ${transferDetails?.applicationDetails?.applicationNo}`}</Card.Subtitle>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column row-gap-3  '>
                                            <SideCard steps={stepsDetails} visited={visited} activeIndex={activeIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className='col-9 form-section'>
                            {!!applicationNumber && applicationData.status !== 'Draft' && (
                                <Card className='card_border my-2'>
                                    <Card.Body>
                                        <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                                    </Card.Body>
                                </Card>
                            )}
                            <FormikProvider value={formik}>
                                {/* {activeIndex === 1 && renderLesseDetails()} */}
                                {activeIndex === 2 && TransferDetails()}
                                {activeIndex === 1 && (
                                    <Card className='card_border'>
                                        <Card.Body>
                                            <div className='ps-2 py-2 d-flex justify-content-between'>
                                                <div className='formTitle m-0'>{'Plot Details'}</div>
                                            </div>
                                            <GenericActionTable rowData={rowData} columns={plotDetailsTableColumns} searchBarPlaceholder={'SEARCH_BY_PLOT'} />
                                            {/* <PlotDetails plotData={plotData}  /> */}
                                            {/* <AllotedPlotDetails plotData={plotData}  /> */}
                                        </Card.Body>
                                    </Card>
                                )}
                                {activeIndex === 3 &&
                                    (!paymentIsLoading ? (
                                        <PaymentDetails
                                            paymentData={paymentData}
                                            selectedGateway={selectedGateway}
                                            setSelectedGateway={setSelectedGateway}
                                            isPaid={isPaid}
                                            gatewayData={gatewayData}
                                            transactionDetails={transactionDetails}
                                            title={'Transfer Applictaion fees'}
                                        ></PaymentDetails>
                                    ) : (
                                        <Loading />
                                    ))}
                                {activeIndex === 4 && AssignTransferee()}
                            </FormikProvider>
                        </div>
                    </div>

                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                            </div>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {hasValidAction ? (
                                    <>
                                        {/* {activeIndex === 2 && (ipb || auction) && <GenericButtons text={'Reset'} handleClick={() => resetPlot()} ClassName='me-3' />} */}
                                        {activeIndex !== stepsDetails.length && activeIndex !== 3 && activeIndex !== 1 && (
                                            <GenericButtons text={t('SAVE', 'Save')} handleClick={() => onSubmit(true)} ClassName='me-3' />
                                        )}
                                        {activeIndex === 1 && <GenericButtons text={t('NEXT', 'Next')} variant='primary' handleClick={() => lastSubmit()} />}
                                        {activeIndex !== 1 && (
                                            <GenericButtons
                                                text={
                                                    activeIndex !== stepsDetails.length && activeIndex !== 3
                                                        ? `Save & Next`
                                                        : activeIndex !== stepsDetails.length
                                                        ? isPaid && activeIndex === 3
                                                            ? t('NEXT', 'Next')
                                                            : paymentTried
                                                            ? `Retry Payment`
                                                            : `Pay Now`
                                                        : t('ASSIGN', 'Assign')
                                                }
                                                variant='primary'
                                                handleClick={() => lastSubmit()}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <GenericButtons
                                            text={activeIndex === stepsDetails.length ? t('CLOSE', 'Close') : t('NEXT', 'Next')}
                                            variant='primary'
                                            handleClick={activeIndex === stepsDetails?.length ? onClose : onChangeNextIndex}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </footer>
                </>
            )}
        </>
    );
};

export default Transfer;
