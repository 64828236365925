import { ErrorMessage, Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { getEnterpriseOtp, getLocation, getSubLeaseOtp, validateOtp } from '../../services/modulesServices/userManagement.api';
import { EnterOtp } from '../../features/modules/UserManagement/EnterpriseModule/OwnershipDetails';
import GenericPopupModal from './GenericModalPopup.component';
import { useTranslation } from 'react-i18next';
import GenericButtons from '../UI components/Buttons/GenericButtons';
import _ from 'lodash';
import { setDataFromPrefix } from '../../utils/utils';
import ToolTipDetails from '../ToolTipFile';
import { convertToINR } from '../../features/modules/EstateManagement/EstateManagement.controller';
import { cross } from '../../utils/Logo';
import StatusAlertPopup from './StatusAlertPopup';

interface textFieldProps {
    titleCode?: any;
    field: string;
    title: string;
    type?: string;
    module?: string;
    isRequired?: boolean;
    index?: any;
    handleOnAssignmentChange?: (index: number, field: string, value: any) => void;
    additionalSection?: string;
    prefix?: string;
    isDisabled?: boolean;
    condition1?: (val: any, index: number) => boolean;
    disableCondition?: (val: any, index: number) => boolean;
    upperCase?: boolean;
    onChangeAction?: (e: any, field: string) => void;
    toolTipDetails?: any;
    showInr?: boolean;
    width?: any;
    isNoLogin?: boolean;
    subType?: any;
    hideOtpBtn?: any;
    validation?: any;
}

export default function GenericCommonTextField(this: any, props: textFieldProps) {
    const { field, additionalSection, toolTipDetails, index, handleOnAssignmentChange, prefix, module, isDisabled, condition1, disableCondition, upperCase, onChangeAction, showInr, hideOtpBtn } =
        props;
    const { t } = useTranslation();
    const { values, errors, touched, setFieldValue, setFieldTouched, handleChange, setValues, handleBlur } = useFormikContext<any>();
    const [btnText, setBtnText] = useState<string>('Send Otp');
    const [showVerfiyPopUp, setShowVerfiyPopUp] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [isRequired, setIsRequired] = useState(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const availabilityCheck = JSON.parse(localStorage.getItem('availabilityCheck') || '{}');

    const verficiationFields = [
        'entityemailId',
        'entityPhoneNo',
        'profilePhoneNo',
        'profileEmailId',
        'subLeaseEmailId',
        `${props?.isNoLogin ? 'phoneNo' : ''}`,
        `${props?.isNoLogin ? 'emailId' : ''}`
    ];
    const upperCaseFields = ['documentNo', 'entitypanNo', 'entityGstNo', 'authorizedPersonpanNo', 'ownershipPanNo', 'applicantPanNo', 'applicantGstno', 'authorizedPersonPanNo', 'udyamNo'];
    const appendSalutation = ['firstname', 'name', 'ownershipFirstname', 'firstName', 'authorizedPersonFirstname'];

    useEffect(() => {
        if (module === 'usermanagement') {
            if (
                values.hasOwnProperty(`${field}OtpReferenceId`) &&
                !errors.hasOwnProperty(field) &&
                (availabilityCheck?.isAvailable === true || field === 'entityPhoneNo' || field === 'phoneNo' || field === 'subLeaseEmailId')
            ) {
                setBtnText('Verified');
                setIsDisable(true);
            } else {
                setBtnText(t('SEND_OTP', 'Send OTP'));
                // setIsDisable(false);
            }

            if (!values.isUpdatable || ((verficiationFields.includes(field) || field === 'phoneNo' || field === 'emailId') && values.underProcess)) {
                setIsDisable(true);
            }
        }

        if (condition1 && condition1(values, index)) {
            setIsDisable(false);
            setIsRequired(true);
        } else if (condition1 && !condition1(values, index)) {
            setIsDisable(true);
            setIsRequired(false);
        }

        if (disableCondition && disableCondition(values, index)) {
            setIsDisable(false);
        } else if (disableCondition && !disableCondition(values, index)) {
            setIsDisable(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, errors, availabilityCheck]);

    const changeToINR = () => {
        let newValues = _.cloneDeep(values);
        let val = _.get(values, field) && convertToINR(_.get(values, field));
        _.set(newValues, field, val);
        setValues(newValues);
    };

    useEffect(() => {
        if (showInr) {
            changeToINR();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field, showInr]);

    useEffect(() => {
        if (module === 'usermanagement') {
            if (availabilityCheck.hasOwnProperty('isAvailable') && !availabilityCheck?.isAvailable && availabilityCheck.applicationStatus !== 'PENDING' && verficiationFields.includes(field)) {
                delete values[`${field}OtpReferenceId`];
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availabilityCheck]);

    const onVerifyBtnClick = () => {
        if (verficiationFields.includes(field) && field !== 'subLeaseEmailId') {
            getEnterpriseOtp({ data: _.get(values, field) })
                .then(res => setShowVerfiyPopUp(true))
                .catch(err => {
                    let errMsg = err.response?.data?.errorMessage || 'Something went wrong!';
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                });
        }

        if (field === 'subLeaseEmailId') {
            getSubLeaseOtp({ data: _.get(values, field) })
                .then(res => setShowVerfiyPopUp(true))
                .catch(err => {
                    let errMsg = err.response?.data?.errorMessage || 'Something went wrong!';
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                });
        }
    };

    const onValidateBtnClick = () => {
        localStorage.removeItem('availabilityCheck');
        validateOtp({ field: _.get(values, field), otp: values.otp })
            .then(res => {
                setFieldValue(`${field}OtpReferenceId`, res.data.otp.UUID);
                setShowVerfiyPopUp(false);
            })
            .catch(err => setModalDetails({ show: true, title: 'Error!', para: 'Invalid Otp', img: cross, type: 'error', reUpload: 'OK' }));
    };
    const handleModalClose = (type: string, msg?: string) => {
        setModalDetails({});
    };
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.type === 'number') {
            const numericValue = e.target.value.replace(/[^0-9.]/g, '');
            e.target.value = numericValue;
        }

        if (additionalSection) {
            handleOnAssignmentChange && handleOnAssignmentChange(index, props.field, e.target.value);
        } else if (upperCaseFields.includes(field) || upperCase) {
            setFieldValue(field, e.target.value.toUpperCase());
        } else if (field.toLowerCase().includes('perpincode')) {
            getPincodeLocation(e.target.value, true, field);
            handleChange(e);
        } else if (field.toLowerCase().includes('pincode')) {
            handleChange(e);
            getPincodeLocation(e.target.value, false, field);
        } else handleChange(e);
    };

    const getPincodeLocation = (pincode: string, isPermanant: boolean, field: string) => {
        if (String(pincode).length === 6) {
            getLocation(Number(pincode))
                .then(res => {
                    const { country, state, district } = res.data;
                    if (state === '' && country === '' && district === '') {
                        if (field === 'pincode') {
                            setFieldValue('isPincodeDetailsPresent', false);
                        } else if (field === 'perpincode') {
                            setFieldValue('isPermanentPincodeDetailsPresent', false);
                        } else if (field === 'ownershippincode') {
                            setFieldValue('isOwnershipPincodeDetailsPresent', false);
                        }
                    } else {
                        if (isPermanant) {
                            const propertiesToSet = { percountry: country, perdistrict: district, perpincode: pincode, perstate: state };
                            const newValues = setDataFromPrefix(propertiesToSet, prefix ?? '', values);
                            setValues({ ...newValues, isPermanentPincodeDetailsPresent: true });
                        } else {
                            const propertiesToSet = { country, district, pincode, state };
                            const newValues = setDataFromPrefix(propertiesToSet, prefix ?? '', values);
                            setValues({ ...newValues, isPincodeDetailsPresent: true });
                            if (field === 'ownershippincode') {
                                setFieldValue('isOwnershipPincodeDetailsPresent', true);
                            }
                        }
                    }
                })
                .catch(e => console.log(e));
        } else {
            if (isPermanant) {
                const propertiesToSet = { percountry: '', perdistrict: '', perpincode: pincode, perstate: '' };
                const newValues = setDataFromPrefix(propertiesToSet, prefix ?? '', values);
                setValues({ ...newValues, isPermanentPincodeDetailsPresent: false });
            } else {
                const propertiesToSet = { country: '', district: '', pincode, state: '' };
                const newValues = setDataFromPrefix(propertiesToSet, prefix ?? '', values);
                setValues({ ...newValues, isPincodeDetailsPresent: false });
                if (field === 'ownershippincode') {
                    setFieldValue('isOwnershipPincodeDetailsPresent', false);
                }
            }
        }
    };

    const styles = upperCaseFields.includes(field) || upperCase ? { textTransform: 'uppercase' } : {};

    return (
        <>
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.para)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.para)}
            />
            {!additionalSection ? (
                <div className='d-flex'>
                    {appendSalutation.includes(field) && (
                        <div className='d-none' style={{ width: '25%', marginRight: '10px' }}>
                            <label htmlFor={field} className='pb-2'>
                                {t('TITLE', 'Title')}
                            </label>
                            <Field
                                name={`${field}salutation`}
                                as='select'
                                isValid={false}
                                isInvalid={true}
                                className={`form-control  was-validated`}
                                disabled={!values.isUpdatable || isDisabled}
                                style={{ marginTop: '6.5px' }}
                            >
                                <option value='0' disabled selected hidden></option>
                                {values.individualOrOrganization !== 'Organization' ? (
                                    <>
                                        <option value='Mrs.'>Mrs</option>
                                        <option value='Mr.'>Mr</option>
                                        <option value='Ms.'>Ms</option>
                                    </>
                                ) : (
                                    <option value='MS.'>M/S.</option>
                                )}
                            </Field>
                        </div>
                    )}
                    <div style={{ width: props?.width === 'half' ? '50%' : '100%' }}>
                        <div className='d-flex  '>
                            <label htmlFor={field} className='pb-2 file-form-label' style={props.title.length > 50 ? { width: '70%' } : { width: 'fit-content' }} title={props.title}>
                                {props.titleCode ? t(props.titleCode, props.title) : props.title}
                            </label>
                            {(props.isRequired || isRequired) && <span className='mandatory-indicator'>*</span>}
                            {/* {prefix && <span className=''>&#8377;</span>} */}
                            {toolTipDetails && <ToolTipDetails toolTipDetails={toolTipDetails} />}
                        </div>
                        <Field
                            name={field}
                            type={props.subType || 'text'}
                            id={props?.field}
                            className={` form-control was-validated ${_.get(touched, field) && _.get(errors, field) && 'is-invalid'}  ${
                                _.get(values, field) && !_.get(errors, field) && (props.isRequired || isRequired) && values.isUpdatable && 'is-valid'
                            } `}
                            disabled={isDisable || props?.isDisabled || !values.isUpdatable}
                            style={styles}
                            onChange={(e: any) => {
                                handleOnChange(e);
                                onChangeAction && onChangeAction(e, field);
                            }}
                            onBlur={(e: any) => {
                                showInr && changeToINR();
                                handleBlur(e);
                            }}
                            value={_.get(values, field)}
                            // validate={(value: any) => {
                            //     const validation = props.validation || {};
                            //     if (validation.required && !value) {
                            //         return 'This field is required.';
                            //     }
                            //     if (validation.pattern && !validation.pattern.test(value)) {
                            //         return validation.customMessage || 'Invalid format.';
                            //     }
                            //     if (validation.minLength && value.length < validation.minLength) {
                            //         return `Minimum length is ${validation.minLength}.`;
                            //     }
                            //     if (validation.maxLength && value.length > validation.maxLength) {
                            //         return `Maximum length is ${validation.maxLength}.`;
                            //     }
                            //     if (validation.when && !validation.when(values)) {
                            //         return undefined; // Skip validation if condition fails
                            //     }
                            //     if (validation.required && !value) {
                            //         return validation.customMessage || 'This field is required.';
                            //     }
                            //     return undefined;
                            // }}
                        />
                        <ErrorMessage name={field}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                    </div>

                    {verficiationFields.includes(field) && !isDisabled && !hideOtpBtn && (
                        <>
                            <GenericButtons
                                text={btnText}
                                variant='primary'
                                ClassName='verify-btn-otp ms-2'
                                handleClick={onVerifyBtnClick}
                                disabled={isDisable || Object.keys(errors).includes(field)}
                            />
                            <GenericPopupModal
                                isVisible={showVerfiyPopUp}
                                primaryAction={() => onValidateBtnClick()}
                                secondaryAction={() => setShowVerfiyPopUp(false)}
                                primaryBtnTitle={t('VERIFY', 'Verify')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                title={t('ENTER_OTP', 'Enter OTP')}
                                subTitle={t('ENTER_SIX_DIGIT', 'Enter the 6 digit OTP.')}
                                children={<EnterOtp data={_.get(values, field)} onVerifyBtnClick={onVerifyBtnClick} />}
                            />
                        </>
                    )}
                </div>
            ) : (
                <>
                    <label htmlFor={field} className='pb-2 file-form-label' style={props.title.length > 50 ? { width: '70%' } : { width: 'fit-content' }}>
                        {props.titleCode ? t(props.titleCode) : props.title}
                    </label>
                    {props.isRequired && <span className='mandatory-indicator'>*</span>}
                    <Field
                        name={field}
                        type={props.type || 'text'}
                        id={props?.field}
                        max={props.field === 'dob' && new Date().toISOString().split('T')[0]}
                        className={`form-control was-validated ${
                            (touched as any)?.[additionalSection]?.[index]?.[field] && (errors as any)?.[additionalSection]?.[index]?.[field] ? 'is-invalid' : ''
                        } ${
                            (touched as any)?.[additionalSection]?.[index]?.[field] && props.isRequired && !(errors as any)?.[additionalSection]?.[index]?.[field] && values.isUpdatable
                                ? 'is-valid'
                                : ''
                        }`}
                        onChange={handleOnChange}
                        onBlur={() => setFieldTouched(`${additionalSection}[${index}].${props.field}`, true)}
                        value={values[additionalSection]?.[index]?.[field]}
                        disabled={isDisable || props?.isDisabled || !values.isUpdatable}
                    />
                    <ErrorMessage name={`${additionalSection}[${index}].${props.field}`}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                </>
            )}
        </>
    );
}
