import { CONSTANT_DATA_FIELD } from '../../../../../utils/constants';

export const EMPLOYEE_MAPPING_TABLE = [
    {
        colName: 'SN',
        titleCode: 'SERIAL_NO',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Industrial Estate',
        titleCode: 'INDUSTRIAL_ESTATE',
        fieldName: 'estateName',
        clsName: 'col-sm-4 column-text-start',
        id: 2
    },
    {
        colName: 'Assigned ARM Count',
        titleCode: 'ARM_COUNT',
        fieldName: 'areaManagerEmployeeCount',
        clsName: 'col-sm-3 column-text-end',
        id: 3
    },
    {
        colName: 'Assigned FM Count',
        titleCode: 'FM_COUNT',
        fieldName: 'fieldManagerEmployeeCount',
        clsName: 'col-sm-3 column-text-end',
        id: 4
    },
    {
        colName: 'Action',
        titleCode: 'ACTION',
        fieldName: 'action',
        clsName: 'col-sm-1',
        actions: ['edit', 'view'],
        id: 5
    }
];

export const MANAGER_TABLE = [
    {
        colName: 'SN',
        titleCode: 'SERIAL_NO',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Employee Name',
        titleCode: 'EMPLOYEE_NAME',
        fieldName: 'employeeName',
        clsName: 'col-sm-5 column-text-start',
        id: 2
    },
    {
        colName: 'Employee ID',
        titleCode: 'EMPLOYEE_ID',
        fieldName: 'employeeId',
        clsName: 'col-sm-5 column-text-end',
        id: 3
    },
    {
        colName: 'Action',
        titleCode: 'ACTION',
        fieldName: 'action',
        clsName: 'col-sm-1',
        actions: ['view', 'delete'],
        id: 4
    }
];
export const REASSIGN_FORM = [
    {
        formData: [
            {
                title: 'Designation',
                titleCode: 'DESIGNATION',
                field: 'Designation',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Department',
                titleCode: 'DEPARTMENT',
                field: 'Department',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Employee ID',
                titleCode: 'EMPLOYEE_ID',
                field: 'employeeId',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: false
            },
            {
                title: 'Employee Name',
                titleCode: 'EMPLOYEE_NAME',
                field: 'employeeName',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: false
            },
            {
                title: 'Notes',
                titleCode: 'Noting',
                field: 'notes',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                isRequired: true,
                class: 'col-sm-12'
            },
            {
                titleCode: 'REFERENCE_DOCUMENT',
                title: 'Reference Document',
                field: 'referenceDoc',
                type: CONSTANT_DATA_FIELD.FILE_FIELD
            }
        ]
    }
];
export const ASSIGN_FORM = [
    {
        formData: [
            {
                title: 'Employee ID',
                titleCode: 'EMPLOYEE_ID',
                field: 'employeeId',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Employee Name',
                titleCode: 'EMPLOYEE_NAME',
                field: 'employeeName',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            }
        ]
    }
];
