import { useTranslation } from 'react-i18next';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { INSTALLMENT_COL, PAID_PAYMENT_COL, PAYMENT_COL, WATER_CHARGES_PAYMENT_COL, getDateRangeForPayment, getFormatedPaymentData } from './EstatePayment.const';
import { useEffect, useState } from 'react';
import { POST, POST2, endpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { Card } from 'react-bootstrap';
import { changeDateFormatToIST, convertDateFormat, getEpoch, getNavigateTo } from '../../../../utils/utils';
import PaymentDetails from '../Allotment/PaymentDetails';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { convertToINR, fetchFeeData, fetchGatewayData, getOptions, getQueryParams, loadRazorpay, paymentCheck, paymentPayload, strToNum } from '../EstateManagement.controller';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { check, cross } from '../../../../utils/Logo';
import _ from 'lodash';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import ArrearBreakdownPopUp from './ArrearBreakdownPopUp';
import { RazorpayOptions } from '../../UserManagement/types';
import Loading from '../../../../components/portalComponents/Loading.component';
import { KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../../../utils/constants';

export const EstatePayment = () => {
    const { t } = useTranslation();
    const userId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    // const userId = '6b6d61ff-dafd-4472-99b1-61b3a8fa05ca';
    const [installmentRowData, setInstallmentRowData] = useState([]);
    const [paymentRowData, setPaymentRowData] = useState<any>([]);
    const [selectedInstallments, setSelectedInstallments] = useState<any>([]);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [arrearPopUp, setArrearPopUp] = useState<boolean>(false);
    const [arrearRowData, setArrearRowData] = useState({});
    const [initialScheduleResponse, setInitialScheduleResponse] = useState<any>([]);
    const [paymentInitialResponse, setPaymentInitialResponse] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [installmentSelectedItems, setInstallmentSelectedItems] = useState<any>([]);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [allInstallmentPaid, setAllInstallmentPaid] = useState<boolean>(false);
    const [footerButtons, setFooterButtons] = useState<any>(null);
    const [paidData, setPaidData] = useState<any>([]);
    // const [premiumFromDate, setPremiumFromDate] = useState<any>(null);

    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location: any = useLocation();

    const applicationNumber: any = searchParams.get('applicationNumber') || searchParams.get('applicationNo');
    const plotId: any = searchParams.get('plotId');
    const applicationId: any = searchParams.get('applicationId');
    const paymentProgress: any = searchParams.get('paymentProgress');
    const type: any = searchParams.get('type');
    const subleaseEntityId: any = searchParams.get('subleaseEntityId');

    const queryParams = getQueryParams(searchParams);
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        console.log('paymentProgress', paymentProgress);
        if (applicationNumber && paymentProgress) {
            setPaymentIsLoading(true);
            setIsPaid(true);
            setPaymentTried(true);

            let params = { ...queryParams };
            getTransactionUpdate(params);
        }
    }, [location.search]);

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                try {
                    const { data } = await POST(endpoints.allotmentActionSearch + `?allotmentId=${applicationNumber}`, { RequestInfo: requestInfo() });
                    setFooterButtons(data);
                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [applicationNumber]);

    useEffect(() => {
        if (!queryParams.eg_pg_txnid && paymentRowData) {
            const result = paymentRowData.filter((item: any) => selectedItems.includes(item.id)).reduce((acc: any, curr: any) => acc + +curr.totalDueAmount?.replace(/,/g, ''), 0);
            setTotalPaidAmount(+result.toFixed(2));
            console.log(paymentRowData, result);
        }
    }, [selectedItems, selectedInstallments, paymentRowData]);

    useEffect(() => {
        (async () => {
            try {
                if (!queryParams.eg_pg_txnid) {
                    let paymentRows: any = [];
                    if (type.includes('LAND_PREMIUM')) {
                        // get landpremium installment table data
                        const installmentData = await POST(
                            endpoints.scheduledPaymentDetailsSearch + `?paymentTypes=LAND_PREMIUM&plotIds=${plotId}&entityIds=${subleaseEntityId ? subleaseEntityId : userId}`,
                            {
                                RequestInfo: requestInfo()
                            }
                        );
                        setInitialScheduleResponse(installmentData.data.paymentDetailsResponse);
                        console.log('initialScedule', installmentData.data.paymentDetailsResponse);

                        const updatedData = installmentData.data.paymentDetailsResponse
                            .reverse()
                            .slice(installmentData.data.paymentDetailsResponse.length > 1 ? 1 : 0) // to hide downpayment record
                            .filter((item: any) => item.paymentType === 'LAND_PREMIUM')
                            .map((item: any, id: number) => ({
                                ...item,
                                id: item.uuid,
                                dueDate: changeDateFormatToIST(item.dueDate),
                                SN: id + 1,
                                isChecked: item.paidAmount ? true : false,
                                isDefaultChecked: item.paymentType === 'LAND_PREMIUM' && getDateRangeForPayment(item.dueDate) !== 'FUTURE_DATE',
                                totalDueAmount: convertToINR(item.totalDueAmount),
                                sgstPenalty: convertToINR(item.sgstPenalty),
                                cgstPenalty: convertToINR(item.sgstPenalty),
                                penaltyAmount: convertToINR(item.penaltyAmount),
                                interestAmount: convertToINR(item.interestAmount),
                                principalAmount: convertToINR(item.principalAmount)
                            }));

                        let defaultCheckedItems: any = [];
                        updatedData.map((item: any) => {
                            if (item.paymentType === 'LAND_PREMIUM' && getDateRangeForPayment(item.dueDate) !== 'FUTURE_DATE') {
                                defaultCheckedItems.push(item.uuid);
                            }
                        });

                        setInstallmentSelectedItems(defaultCheckedItems);
                        setInstallmentRowData(updatedData);
                        setSelectedInstallments(defaultCheckedItems);

                        const allPaid = updatedData?.length >= 1 && updatedData?.every((record: any) => record.isChecked);
                        console.log(updatedData, allPaid);
                        setAllInstallmentPaid(allPaid);

                        // const selectedItems = [...selectedInstallments];
                        // updatedData.map((item: any) => {
                        //     if (+item.sgstPenalty || +item.sgstPenalty) {
                        //         selectedItems.push(item.id);
                        //     }
                        // });
                        // setSelectedInstallments(selectedItems);
                        // console.log('selectedInstallments', selectedItems);

                        // for setting initial paidRowData
                        let landPremiumData: any = updatedData.filter((item: any) => defaultCheckedItems.includes(item.uuid) && !item.isChecked);
                        let landPremiumInstallment: any = landPremiumData.find((item: any) => getDateRangeForPayment(item.dueDate) !== 'PAST_ARREAR_DATE')?.interestAmount;
                        let landPremiumArrearInstallment: any = landPremiumData.reduce((acc: any, curr: any) => {
                            if (getDateRangeForPayment(curr.dueDate) === 'PAST_ARREAR_DATE') {
                                return acc + (curr?.interestAmount ? +curr?.interestAmount?.replace(/,/g, '') : 0);
                            }
                            return acc;
                        }, 0);

                        const result = landPremiumData.reduce(
                            (acc: any, record: any) => {
                                const principalAmount = getDateRangeForPayment(record.dueDate) !== 'PAST_ARREAR_DATE' ? +record.principalAmount?.replace(/,/g, '') || 0 : 0;
                                const penaltyAmount = record.penaltyAmount ? +record.penaltyAmount?.replace(/,/g, '') : 0;
                                const cgstPrincipal = record.cgstPrincipal ? +record.cgstPrincipal?.replace(/,/g, '') : 0;
                                const sgstPrincipal = record.sgstPrincipal ? +record.sgstPrincipal?.replace(/,/g, '') : 0;
                                const cgstPenalty = record.cgstPenalty ? +record.cgstPenalty?.replace(/,/g, '') : 0;
                                const sgstPenalty = record.sgstPenalty ? +record.sgstPenalty?.replace(/,/g, '') : 0;
                                const arrearPrincipalAmount = record.arrearPrincipalAmount ? +record.arrearPrincipalAmount?.replace(/,/g, '') : 0;

                                acc.arrearPrincipalAmountTotal =
                                    getDateRangeForPayment(record.dueDate) === 'PAST_ARREAR_DATE'
                                        ? parseFloat((acc.arrearPrincipalAmountTotal + arrearPrincipalAmount + +record.principalAmount?.replace(/,/g, '')).toFixed(2))
                                        : parseFloat((acc.arrearPrincipalAmountTotal + arrearPrincipalAmount).toFixed(2));
                                acc.sgstPrincipalTotal = parseFloat((acc.sgstPrincipalTotal + sgstPrincipal).toFixed(2));
                                acc.cgstPrincipalTotal = parseFloat((acc.cgstPrincipalTotal + cgstPrincipal).toFixed(2));
                                acc.sgstPenaltyTotal = parseFloat((acc.sgstPenaltyTotal + sgstPenalty).toFixed(2));
                                acc.cgstPenaltyTotal = parseFloat((acc.cgstPenaltyTotal + cgstPenalty).toFixed(2));
                                acc.penaltyAmountTotal = parseFloat((acc.penaltyAmountTotal + penaltyAmount).toFixed(2));
                                acc.principalAmountTotal = parseFloat((acc.principalAmountTotal + principalAmount).toFixed(2));
                                acc.uuid = record.uuid;
                                acc.id = record.id;
                                acc.paymentType = record.paymentType;
                                return acc;
                            },
                            {
                                arrearPrincipalAmountTotal: 0,
                                sgstPrincipalTotal: 0,
                                cgstPrincipalTotal: 0,
                                sgstPenaltyTotal: 0,
                                cgstPenaltyTotal: 0,
                                penaltyAmountTotal: 0,
                                principalAmountTotal: 0
                            }
                        );

                        const calculatedRow = {
                            SN: type === 'LAND_PREMIUM' ? 1 : 2,
                            uuid: 'LAND_PREMIUM_UUID',
                            id: 'LAND_PREMIUM_UUID',
                            paymentType: result?.paymentType,
                            paymentTypeDisplayName: 'Land Premium',
                            arrearPrincipalAmount: convertToINR(result?.arrearPrincipalAmountTotal),
                            sgstPrincipal: convertToINR(result?.sgstPrincipalTotal),
                            cgstPrincipal: convertToINR(result?.cgstPrincipalTotal),
                            sgstPenalty: convertToINR(result?.sgstPenaltyTotal),
                            cgstPenalty: convertToINR(result?.cgstPenaltyTotal),
                            penaltyAmount: convertToINR(result?.penaltyAmountTotal),
                            interestAmount: convertToINR(landPremiumInstallment ? +landPremiumInstallment?.replace(/,/g, '') : 0 + landPremiumArrearInstallment),
                            principalAmount: convertToINR(result?.principalAmountTotal),
                            totalDueAmount: convertToINR(
                                (
                                    result?.principalAmountTotal +
                                    result?.penaltyAmountTotal +
                                    result?.cgstPenaltyTotal +
                                    result?.sgstPenaltyTotal +
                                    result?.cgstPrincipalTotal +
                                    result?.sgstPrincipalTotal +
                                    result?.arrearPrincipalAmountTotal +
                                    (landPremiumInstallment ? +landPremiumInstallment?.replace(/,/g, '') : 0 + landPremiumArrearInstallment)
                                )?.toFixed(2)
                            )
                        };

                        // setPaymentRowData([calculatedRow]);
                        paymentRows = strToNum(calculatedRow.totalDueAmount) ? [calculatedRow] : [];

                        if (calculatedRow && !selectedItems.includes('LAND_PREMIUM_UUID')) {
                            setSelectedItems([...selectedItems, 'LAND_PREMIUM_UUID']);
                        }
                    }

                    // get Payment Breakdown table data
                    if (type !== 'LAND_PREMIUM') {
                        const paymentBreakdownData = await POST(
                            endpoints.schedulePaymentUnpaid + `?entityIds=${subleaseEntityId ? subleaseEntityId : userId}&plotIds=${plotId}${type ? `&paymentTypes=${type}` : ''}`,
                            {
                                RequestInfo: requestInfo()
                            }
                        );
                        setPaymentInitialResponse(paymentBreakdownData.data.paymentDetailsResponse);
                        const paymentUpdatedData = paymentBreakdownData.data.paymentDetailsResponse
                            .filter((item: any) => item.scheduledPaymentDetails.paymentType !== 'LAND_PREMIUM')
                            .map((item: any, id: number) => {
                                const data = getFormatedPaymentData(item, id);
                                return data;
                            });

                        const selectedRows = paymentUpdatedData.map((item: any) => item.id);
                        setSelectedItems(selectedRows);
                        // setPaymentRowData(paymentUpdatedData);
                        paymentRows = [...paymentRows, ...paymentUpdatedData];
                        console.log(paymentUpdatedData);

                        if (!paymentUpdatedData.length) {
                            const paymentSearchRes = await POST(endpoints.paymentDetailsSearch + `?paymentTypes=${type}&entityIds=${subleaseEntityId ? subleaseEntityId : userId}&plotIds=${plotId}`, {
                                RequestInfo: requestInfo()
                            });

                            const updatedData = paymentSearchRes.data.payments
                                .map((item: any, id: number) => ({
                                    ...item,
                                    id: item.uuid,
                                    dueDate: changeDateFormatToIST(item.dueDate),
                                    SN: id + 1,
                                    isChecked: item.paidAmount ? true : false,
                                    totalDueAmount: convertToINR(item.totalDueAmount),
                                    sgstPenalty: convertToINR(item.sgstPenalty),
                                    cgstPenalty: convertToINR(item.sgstPenalty),
                                    penaltyAmount: convertToINR(item.penaltyAmount),
                                    interestAmount: convertToINR(item.interestAmount),
                                    principalAmount: convertToINR(item.principalAmount)
                                }))
                                .filter((item: any) => item.paymentDate === paymentSearchRes.data.payments[0].paymentDate);

                            setPaidData(updatedData);

                            console.log('paid', updatedData);
                        }
                    }

                    setPaymentRowData(paymentRows);
                }
            } catch (err: any) {
                let errMsg = err.response?.data?.errorMessage || 'Something went wrong!';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                console.log(err);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (plotId) {
                try {
                    if (queryParams.eg_pg_txnid) {
                        const { data } = await POST(endpoints.paymentSearchViaTxnID + `?txnId=${queryParams.eg_pg_txnid}`, { RequestInfo: requestInfo() });
                        if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                            setTransactionDetails(data?.Transaction?.[0]);
                            if (data?.Transaction?.[0]?.txnId) {
                                try {
                                    setIsPaid(true);
                                    setIsLoading(false);
                                    setPaymentIsLoading(false);
                                } catch (e: any) {
                                    setIsPaid(false);
                                    setIsLoading(false);
                                    setPaymentIsLoading(false);
                                }
                            }
                            setIsLoading(false);
                            setPaymentIsLoading(false);
                        }
                        setIsLoading(false);
                        setPaymentIsLoading(false);
                        if (data?.Transaction?.[0]?.additionalDetails) {
                            const dat = data?.Transaction?.[0]?.additionalDetails;

                            const payData = dat?.payNowPaymentDetails.map((e: any, id: any) => {
                                const data = getFormatedPaymentData(e, id);
                                return data;
                            });
                            const totalAmt = payData.reduce((acc: any, curr: any) => acc + +curr.totalDueAmount?.replace(/,/g, ''), 0);
                            setPaymentRowData(payData || []);
                            setTotalPaidAmount(totalAmt);
                            console.log('payData', payData, totalAmt);
                        }
                    }

                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);
                } catch (e: any) {
                    console.log(e);
                    let errMsg = e.response?.data?.errorMessage || 'Something went wrong!';
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }
        })();
    }, [plotId]);

    useEffect(() => {
        try {
            loadRazorpay();
        } catch (e) {
            console.log(e);
        }
    }, []);

    const onCheckHandler = (checkedItems: any) => {
        let data = _.cloneDeep(paymentRowData);
        setSelectedInstallments(checkedItems);
        // const dateObject: any = installmentRowData?.find((val: any) => val?.uuid === checkedItems?.[0]);
        // const tempFromDate = dateObject ? getEpoch(dateObject?.dueDate) : null;
        // setPremiumFromDate(tempFromDate);

        let landPremiumData: any = installmentRowData.filter((item: any) => checkedItems.includes(item.uuid) && !item.isChecked);
        let leaseRentData = data?.filter((item: any) => item.paymentType !== 'LAND_PREMIUM');

        let landPremiumInstallment: any = landPremiumData.find((item: any) => getDateRangeForPayment(item.dueDate) !== 'PAST_ARREAR_DATE')?.interestAmount;
        let landPremiumArrearInstallment: any = landPremiumData.reduce((acc: any, curr: any) => {
            if (getDateRangeForPayment(curr.dueDate) === 'PAST_ARREAR_DATE') {
                return acc + (curr?.interestAmount ? +curr?.interestAmount?.replace(/,/g, '') : 0);
            }
            return acc;
        }, 0);

        if (!landPremiumData.length) {
            const resetChecked = leaseRentData.map((item: any) => item.uuid);
            setPaymentRowData(leaseRentData);
            setSelectedItems(resetChecked);
            return;
        }

        console.log('record', landPremiumData);

        const result = landPremiumData.reduce(
            (acc: any, record: any) => {
                const principalAmount = getDateRangeForPayment(record.dueDate) !== 'PAST_ARREAR_DATE' ? +record.principalAmount?.replace(/,/g, '') || 0 : 0;
                const penaltyAmount = record.penaltyAmount ? +record.penaltyAmount?.replace(/,/g, '') : 0;
                const cgstPrincipal = record.cgstPrincipal ? +record.cgstPrincipal?.replace(/,/g, '') : 0;
                const sgstPrincipal = record.sgstPrincipal ? +record.sgstPrincipal?.replace(/,/g, '') : 0;
                const cgstPenalty = record.cgstPenalty ? +record.cgstPenalty?.replace(/,/g, '') : 0;
                const sgstPenalty = record.sgstPenalty ? +record.sgstPenalty?.replace(/,/g, '') : 0;
                const arrearPrincipalAmount = record.arrearPrincipalAmount ? +record.arrearPrincipalAmount?.replace(/,/g, '') : 0;

                acc.arrearPrincipalAmountTotal =
                    getDateRangeForPayment(record.dueDate) === 'PAST_ARREAR_DATE'
                        ? parseFloat((acc.arrearPrincipalAmountTotal + arrearPrincipalAmount + +record.principalAmount?.replace(/,/g, '')).toFixed(2))
                        : parseFloat((acc.arrearPrincipalAmountTotal + arrearPrincipalAmount).toFixed(2));
                acc.sgstPrincipalTotal = parseFloat((acc.sgstPrincipalTotal + sgstPrincipal).toFixed(2));
                acc.cgstPrincipalTotal = parseFloat((acc.cgstPrincipalTotal + cgstPrincipal).toFixed(2));
                acc.sgstPenaltyTotal = parseFloat((acc.sgstPenaltyTotal + sgstPenalty).toFixed(2));
                acc.cgstPenaltyTotal = parseFloat((acc.cgstPenaltyTotal + cgstPenalty).toFixed(2));
                acc.penaltyAmountTotal = parseFloat((acc.penaltyAmountTotal + penaltyAmount).toFixed(2));
                acc.principalAmountTotal = parseFloat((acc.principalAmountTotal + principalAmount).toFixed(2));
                acc.uuid = record.uuid;
                acc.id = record.id;
                acc.paymentType = record.paymentType;
                return acc;
            },
            {
                arrearPrincipalAmountTotal: 0,
                sgstPrincipalTotal: 0,
                cgstPrincipalTotal: 0,
                sgstPenaltyTotal: 0,
                cgstPenaltyTotal: 0,
                penaltyAmountTotal: 0,
                principalAmountTotal: 0
            }
        );

        const calculatedRow = {
            SN: type === 'LAND_PREMIUM' ? 1 : 2,
            uuid: 'LAND_PREMIUM_UUID',
            id: 'LAND_PREMIUM_UUID',
            paymentType: result?.paymentType,
            paymentTypeDisplayName: 'Land Premium',
            arrearPrincipalAmount: convertToINR(result?.arrearPrincipalAmountTotal),
            sgstPrincipal: convertToINR(result?.sgstPrincipalTotal),
            cgstPrincipal: convertToINR(result?.cgstPrincipalTotal),
            sgstPenalty: convertToINR(result?.sgstPenaltyTotal),
            cgstPenalty: convertToINR(result?.cgstPenaltyTotal),
            penaltyAmount: convertToINR(result?.penaltyAmountTotal),
            interestAmount: convertToINR(landPremiumInstallment ? +landPremiumInstallment?.replace(/,/g, '') : 0 + landPremiumArrearInstallment),
            principalAmount: convertToINR(result?.principalAmountTotal),
            totalDueAmount: convertToINR(
                (
                    result?.principalAmountTotal +
                    result?.penaltyAmountTotal +
                    result?.cgstPenaltyTotal +
                    result?.sgstPenaltyTotal +
                    result?.cgstPrincipalTotal +
                    result?.sgstPrincipalTotal +
                    result?.arrearPrincipalAmountTotal +
                    (landPremiumInstallment ? +landPremiumInstallment?.replace(/,/g, '') : 0 + landPremiumArrearInstallment)
                )?.toFixed(2)
            )
        };

        setPaymentRowData([...leaseRentData, calculatedRow]);

        if (calculatedRow && !selectedItems.includes('LAND_PREMIUM_UUID')) {
            setSelectedItems([...selectedItems, 'LAND_PREMIUM_UUID']);
        }
    };

    const onPaymentCheckHandler = (checkedItems: any) => {
        setSelectedItems(checkedItems);
    };

    const handlePayNow = async (key: any) => {
        let leaseRentData = paymentInitialResponse.filter((item: any) => selectedItems.includes(item?.scheduledPaymentDetails.uuid) && item?.scheduledPaymentDetails?.paymentType !== 'LAND_PREMIUM');
        console.log(initialScheduleResponse, selectedInstallments);

        const dateObject: any = installmentRowData?.find((val: any) => val?.uuid === selectedInstallments?.[0]);
        const premiumFromDate = dateObject ? getEpoch(dateObject?.dueDate) : null;
        const premiumToDate = premiumFromDate && new Date(premiumFromDate).setFullYear(new Date(premiumFromDate).getFullYear() + 1);

        let landPremiumData = initialScheduleResponse
            .filter((item: any) => selectedInstallments?.includes(item.uuid) && !item.paidAmount)
            .map((item: any) => ({
                scheduledPaymentDetails: { ...item }
            }));

        if (!landPremiumData.length && !leaseRentData.length) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please select atleast 1 record', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        setIsLoading(true);
        let url = window.location.origin + `/goa-idc/estate/payment?plotId=${plotId}&applicationNo=${applicationNumber}&entityId=${userId}&paymentProgress=true&type=${type}`;
        const periodTo = leaseRentData[0]?.scheduledPaymentDetails?.dueDate
            ? new Date(leaseRentData[0]?.scheduledPaymentDetails?.dueDate).setFullYear(new Date(leaseRentData[0]?.scheduledPaymentDetails?.dueDate).getFullYear() + 1)
            : null;
        const periodFrom = leaseRentData[0]?.scheduledPaymentDetails?.dueDate ? new Date(leaseRentData[0]?.scheduledPaymentDetails?.dueDate).getTime() : null;
        // const periodTo = new Date().setFullYear(new Date().getFullYear() + 1);
        // const periodFrom = new Date().getTime();
        const payload = {
            RequestInfo: requestInfo(),
            applicationNumber: applicationNumber,
            plotId: plotId,
            totalPaidAmount: totalPaidAmount,
            callbackUrl: url,
            productInfo: 'info',
            gateway: selectedGateway,
            periodFrom: premiumFromDate ? premiumFromDate : periodFrom,
            periodTo: premiumToDate ? premiumToDate : periodTo,
            applicationId: applicationId,
            payNowPaymentDetails: [...leaseRentData, ...landPremiumData]
        };

        try {
            if (!totalPaidAmount) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            if (!selectedGateway) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (applicationNumber && selectedGateway && totalPaidAmount) {
                const { data } = await POST(endpoints.paymentGateway, payload);

                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, user, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data.additionalDetails?.walletClientCode}&walletRequestMessage=${data.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
                setIsLoading(false);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Payment Initiation Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string) => {
        setModalDetails({ show: false });
        if (type === 'success') {
            navigate('/goa-idc/enterprisedashboard');
        }
    };

    const onClickArrear = (rowData: any) => {
        setArrearRowData(rowData);
        console.log(rowData);
        setArrearPopUp(true);
    };

    const getTransactionUpdate = async (params: any) => {
        try {
            // const paymentData: any = await paymentCheck('LAND_PREMIUM,LEASE_RENT', plotId, applicationId, userId);
            // console.log('ispaid', paymentData.isPaid, paymentData.transactionDetails);
            const paymentCheck = await POST(endpoints.paymentSearchViaTxnID + `?txnId=${queryParams.eg_pg_txnid}`, { RequestInfo: requestInfo() });

            if (!isPaid) {
                setIsLoading(true);
                const payload = { RequestInfo: requestInfo() };
                const { data } = await POST2(endpoints.transactionUpdate, payload, { eg_pg_txnid: params.eg_pg_txnid });
                if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                    setTransactionDetails(data?.Transaction?.[0]);
                    if (data?.Transaction?.[0]?.txnId) {
                        try {
                            const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                            if (resp.data.status === 'SUCCESSFUL') {
                                setModalDetails({ show: true, title: 'Success!', para: 'Payment successful', img: check, type: 'sucess', reUpload: 'OK' });
                                setIsPaid(true);
                            } else setIsPaid(false);
                            setIsLoading(false);
                            setPaymentIsLoading(false);
                        } catch (e: any) {
                            setIsPaid(false);
                            setIsLoading(false);
                            setPaymentIsLoading(false);
                        }
                    }
                    setIsLoading(false);
                    setPaymentIsLoading(false);
                } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                    setModalDetails({
                        show: true,
                        title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                        para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    // window.location.reload();
                }
                setIsLoading(false);
                setPaymentIsLoading(false);
                if (data?.Transaction?.[0]?.additionalDetails) {
                    const dat = data?.Transaction?.[0]?.additionalDetails;

                    const payData = dat?.payNowPaymentDetails.map((e: any, id: any) => {
                        const data = getFormatedPaymentData(e, id);
                        return data;
                    });
                    const totalAmt = payData.reduce((acc: any, curr: any) => acc + +curr.totalDueAmount?.replace(/,/g, ''), 0);
                    setPaymentRowData(payData || []);
                    setTotalPaidAmount(totalAmt);
                }
            }
        } catch (e: any) {
            console.log(e);
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Transaction Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleOnCLose = () => {
        navigate('/goa-idc/enterprisedashboard');
    };

    const onBackButtonClick = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
        else navigate(-1);
    };

    console.log(PAID_PAYMENT_COL, 'PAID_PAYMENT_COL');

    return (
        <>
            <HeaderComponent />
            <div className='employee-creation-container bg-light m-auto px-5 py-4'>
                <GenericHeader text={t('PAYMENT', 'Payment')} additionalClass='sub-text-style' onActionClick={onBackButtonClick} />

                {type === 'LAND_PREMIUM' && !isPaid && (
                    <Card className='card_border font-inter'>
                        <Card.Body>
                            <div className='ps-2 py-2 d-flex justify-content-between'>
                                <div className='formTitle m-0'>{'Land Premium'}</div>
                            </div>
                            <GenericActionTable
                                columns={INSTALLMENT_COL}
                                rowData={installmentRowData}
                                hideSearchBar={true}
                                itemsPerPage={10}
                                highlightRow={true}
                                hideFooter={true}
                                onCheckHandler={onCheckHandler}
                                checkType={`multiCheck`}
                                serialCheckEnable={true}
                                selectedItems={installmentSelectedItems}
                            ></GenericActionTable>
                        </Card.Body>
                    </Card>
                )}

                {type === 'WATER_CHARGES' ? (
                    <div className='mb-5'>
                        {!paymentIsLoading ? (
                            paymentRowData?.length ? (
                                <PaymentDetails
                                    columns={WATER_CHARGES_PAYMENT_COL}
                                    paymentData={paymentRowData}
                                    selectedGateway={selectedGateway}
                                    setSelectedGateway={setSelectedGateway}
                                    isPaid={isPaid}
                                    gatewayData={gatewayData}
                                    transactionDetails={transactionDetails}
                                    title={'Payment Breakdown'}
                                    totalLabel={'totalDueAmount'}
                                    additionalMethod={onClickArrear}
                                    totalData={totalPaidAmount}
                                    selectedItems={selectedItems}
                                    onCheckHandler={onPaymentCheckHandler}
                                    checkType={`multiCheck`}
                                ></PaymentDetails>
                            ) : (
                                <GenericActionTable
                                    columns={PAID_PAYMENT_COL}
                                    rowData={paidData}
                                    hideSearchBar={true}
                                    itemsPerPage={10}
                                    highlightRow={true}
                                    hideFooter={true}
                                    // checkType={`multiCheck`}
                                    totalRowText={'Total Paid Amount (in INR)'}
                                    totalRow={'totalDueAmount'}
                                ></GenericActionTable>
                            )
                        ) : (
                            <Loading />
                        )}
                    </div>
                ) : (
                    <div className='mb-5'>
                        {!paymentIsLoading ? (
                            paymentRowData?.length ? (
                                <PaymentDetails
                                    columns={PAYMENT_COL}
                                    paymentData={paymentRowData}
                                    selectedGateway={selectedGateway}
                                    setSelectedGateway={setSelectedGateway}
                                    isPaid={isPaid}
                                    gatewayData={gatewayData}
                                    transactionDetails={transactionDetails}
                                    title={'Payment Breakdown'}
                                    totalLabel={'totalDueAmount'}
                                    additionalMethod={onClickArrear}
                                    totalData={totalPaidAmount}
                                    selectedItems={selectedItems}
                                    onCheckHandler={onPaymentCheckHandler}
                                    checkType={`multiCheck`}
                                ></PaymentDetails>
                            ) : (
                                <GenericActionTable
                                    columns={PAID_PAYMENT_COL}
                                    rowData={paidData}
                                    hideSearchBar={true}
                                    itemsPerPage={10}
                                    highlightRow={true}
                                    hideFooter={true}
                                    // checkType={`multiCheck`}
                                    totalRowText={'Total Paid Amount (in INR)'}
                                    totalRow={'totalDueAmount'}
                                ></GenericActionTable>
                            )
                        ) : (
                            <Loading />
                        )}
                    </div>
                )}
            </div>

            <div className='position-fixed bottom-0 py-3 bg-white w-100 border border-1 d-flex justify-content-end pe-5'>
                {allInstallmentPaid || paidData.length ? (
                    <GenericButtons text={t('CLOSE', 'Close')} variant='primary' ClassName='px-3 py-2' handleClick={handleOnCLose} />
                ) : (
                    !isPaid && <GenericButtons text={paymentTried ? `Retry Payment` : `Pay Now`} variant='primary' ClassName='px-3 py-2' handleClick={handlePayNow} />
                    // footerButtons?.validActions?.map((btn: string) => <>{activeIndex === 1 && <GenericButtons text={t(btn)} ClassName='px-3 py-2' handleClick={() => handleButtonClick(btn)} />}</>)
                )}
            </div>

            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />

            <GenericPopupModal
                title='Arrear Breakdown (in INR)'
                primaryBtnTitle='Close'
                isSecondaryBtn={false}
                secondaryAction={() => setArrearPopUp(false)}
                primaryAction={() => setArrearPopUp(false)}
                isVisible={arrearPopUp}
                children={<ArrearBreakdownPopUp rowData={arrearRowData} />}
            />
        </>
    );
};
