import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { Card, Form } from 'react-bootstrap';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { FormikProvider, useFormik } from 'formik';
import ScrollToTop from '../../../../components/ScrollToTop';
import { useTranslation } from 'react-i18next';
import { DECLARATION_LABEL, LESSE_APPLICANT_DETAILS_I, PLOT_DETAILS_TABLE, SUBLEASE_PAYLOAD, SUB_LEASE_READ_ONLY_ASTEPS, SUB_LEASE_STEPS, getSubleaseDocs, plotOtherField } from './SubLeaseConst';
import { toast } from 'react-toastify';
import { dropdownDataOptions, getFormatedOwnershipDetails } from '../../Migration/Migration.controller';
import { OWNERSHIP_VALIDATION, SUB_LEASE_LESSEE_VAALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import EnterpriseDetails from '../Allotment/EnterpriseDetails';
import { APPLICANT_DETAILS_II, DECLARATION_DETAILS, ENTERPRISE_DETAILS, PROJECT_DETAILS, PROMOTER_DETAILS, SUB_LESSEE_DETAILS_II, SUBLEASE_OTHER_DETAIL } from '../Allotment/Allotment.const';
import Declaration from '../Allotment/Declaration';
import PaymentDetails from '../Allotment/PaymentDetails';
import { POST, POST2, devUrl, endpoints, estateEndpoints, getProgressBarData, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { check, cross } from '../../../../utils/Logo';
import { getDiffBWDates, getFirstErrorKey } from '../../../../utils/utils';
import { getEnterpriseData, getEntityData, getFormatedNICDetails, setEnterpriseDocData, setEntityDocData } from '../Allotment/Allotment.controller';
import { APPLY_SUBLEASE, EMPLOYEE_AUTHORIZED, KEY_ID, TENANT_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../../../utils/constants';
import PopUps from '../../../../components/PopUps';
import ApplicantDetails from '../Allotment/ApplicantDetails';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import Loading from '../../../../components/portalComponents/Loading.component';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
    entityDocumentData,
    fetchFeeData,
    fetchGatewayData,
    fetchLeaseDurationDoc,
    getNextActions,
    getOptions,
    getQueryParams,
    loadRazorpay,
    paymentCheck,
    paymentPayload
} from '../EstateManagement.controller';
import GenericFileUploadField from '../../../../components/moduleCommonFormik/GenericFileUpload.component';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import LeaseDetails from './SubLesseeDetailsA1';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import PaymentSubmissionStatus from '../../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../../components/moduleCommonFormik/ProgressBar';
import { RazorpayOptions } from '../../UserManagement/types';
import axios from 'axios';
import { TemplateCard } from '../TemplatesCard';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { handleUpdates } from '../../UserManagement/UserMangement.controller';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { EsignModal } from '../../../commonPages/EsignModal';

const SubLeaseComponent = () => {
    const location: any = useLocation();
    const params: any = new URLSearchParams(location.search);
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const getEnterpriseDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const applicationNumber = params.get('applicationNumber');
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const subleaseInfo = searchParams.get('subleaseInfo') || false;
    const readOnly = params.get('readOnly');
    let userType = getEnterpriseDetails?.type;

    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [nicDetails, setNicDetails] = useState<any>([]);
    const [isChecked, setIsChecked] = useState([false, false, false, false, false]);
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [subLeaseResponse, setSubLeaseResponse] = useState<any>({});
    const [searchApplicationResponse, setSearchApplicationResponse] = useState<any>({});
    const [subLesseeEntityDetails, setSubLesseeEntityDetails] = useState<any>({});
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [calculated, setCalculated] = useState<boolean>(false);
    const [isPaid, setIsPaid] = useState<any>(true);
    const [paymentTried, setPaymentTried] = useState<any>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [applicationType, setApplicationType] = useState<string>('');

    const [dropdownData, setDropdownData] = useState<any>(null);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [transactionDetails, setTransactionDetails] = useState<any>(null);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [paymentData, setPaymentData] = useState<any>([]);
    const [eSignedDocumentData, setESignedDocumentData] = useState<any>(null);
    const [applicationNo, setApplicationNo] = useState<any>('');
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [rowData, setRowData] = useState<any>([]);
    const [subLeaseUuid, setSubLeaseUuid] = useState('');
    // const [applicationDetails, setApplicationDetails] = useState(null);
    const [showPopUp, setShowPopUp] = useState(false);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [isCheckedSubLeaseDetails, setIsCheckedSubLeaseDetails] = useState<any>([false, false, false, false]);
    const [existingUser, setExistingUser] = useState<any>(null);
    const [applicationDetails1, setApplicationDetails1] = useState<any>(LESSE_APPLICANT_DETAILS_I);
    const [applicationDetails2, setApplicationDetails2] = useState<any>(SUB_LESSEE_DETAILS_II);
    const [applicationDetails3, setApplicationDetails3] = useState<any>(SUBLEASE_OTHER_DETAIL);
    // const [applicationId, setApplicationId] = useState<any>(null);
    const [subLesseeEntityRefDetails, setSubLesseeEntityRefDetails] = useState<any>(null);
    const [enterpriseDetails, setEnterpriseDetails] = useState<any>(null);
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>(null);

    const queryParams = getQueryParams(searchParams);

    const user = JSON.parse(localStorage.getItem('user') || '{}');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            industrialToCommercial: false,
            disqualifiedEntity: false,
            applicantGst: false,
            udhyam: false,
            priorExperience: false,
            isStartup: false,
            subLeaseSpecialProject: false,
            confirmation: false,
            individualOrOrganization: 'Individual',
            esignOption: 'DSC'
        },
        onSubmit: (value: any) => {},
        validationSchema: !showOwnershipModal ? SUB_LEASE_LESSEE_VAALIDATION[`page${activeIndex}`] : OWNERSHIP_VALIDATION
    });

    const { values, setValues, setFieldTouched, errors, setFieldValue } = formik;

    useEffect(() => {
        if (userType !== 'ENTERPRISE') {
            window.location.href = '/goa-idc/access-restricted';
        }

        if (applicationNumber) {
            (async () => {
                let applicationAccess;
                try {
                    applicationAccess = await POST(endpoints?.applicationAccess + `?businessid=${applicationNumber}`, { RequestInfo: requestInfo() });
                    if (!applicationAccess?.data.isAuthorized) {
                        window.location.href = '/goa-idc/access-restricted';
                    }
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
        if (queryParams?.['applicationNumber']) {
            (async () => {
                try {
                    if (!readOnly) {
                        const nextActions = await getNextActions(queryParams?.['applicationNumber']);
                        setHasValidAction(nextActions?.nextValidAction?.length > 0);
                        setFieldValue('isUpdatable', nextActions?.nextValidAction?.length > 0);
                        // setValues({ ...values, isUpdatable: nextActions?.nextValidAction?.length > 0 });
                        if (nextActions?.nextValidAction?.length === 0) {
                            setIsChecked([true, true, true, true, true]);
                        }
                    } else {
                        setHasValidAction(!readOnly);
                        setFieldValue('isUpdatable', !readOnly);
                        setIsChecked([true, true, true, true, true]);
                    }
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }

        dropdownDataOptions()
            .then(dropdownOption => {
                setDropdownData(dropdownOption);
                setApplicantData(dropdownOption);
            })
            .catch(error => {});

        if (!!applicationNumber) {
            getProgressBarData(applicationNumber)
                .then(res => setProgressBarDetails(res.data))
                .catch(e => console.log(e));
        }

        loadRazorpay();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (getEnterpriseDetails.uuid) {
            fetchData();
        }
    }, [subLeaseResponse]);

    const subLeaseAutoPopulate = async () => {
        try {
            const entityData = await getEntityData(existingUser, dropdownData);

            const newOwnership = await getFormatedOwnershipDetails(existingUser?.owners, dropdownData);
            setOwnershipDetails(newOwnership);
            setControllingOwnershipResponse(existingUser?.owners);

            setValues((prev: any) => ({ ...prev, ...entityData }));
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        if (existingUser) {
            console.log('existing user', existingUser);
            const tempApplicationDetails1 = applicationDetails1.map((val: any) => ({
                ...val,
                formData: val.formData?.map((field: any) => {
                    return { ...field, isDisabled: true };
                })
            }));

            const tempApplicationDetails2 = SUB_LESSEE_DETAILS_II.map((val: any) => ({
                ...val,
                formData: val.formData?.map((field: any) => {
                    return { ...field, isDisabled: true };
                })
            }));

            const tempApplicationDetails3 = applicationDetails3.map((val: any) => ({
                ...val,
                formData: val.formData?.map((field: any) => {
                    return { ...field, isDisabled: true };
                })
            }));

            setApplicationDetails1(tempApplicationDetails1);
            setApplicationDetails2(tempApplicationDetails2);
            setApplicationDetails3(tempApplicationDetails3);

            subLeaseAutoPopulate();
        }
    }, [existingUser]);

    const fetchData = async () => {
        try {
            let appliedPlot: any = [];
            let plotDataArray = [];

            if (subLeaseResponse?.applicationDetails?.appliedPlotDetails) {
                const plot = subLeaseResponse?.applicationDetails?.appliedPlotDetails;
                appliedPlot = [
                    {
                        estateName: plot?.estateDetails?.estateName,
                        plotType: plot?.plotType,
                        phase: plot?.phase,
                        plotNo: plot?.plotNo,
                        plotAreaSqm: plot?.plotAreaSqm,
                        uuid: plot?.uuid,
                        id: plot?.uuid
                    }
                ];
            }
            if (!readOnly) {
                let { data: plotData } = await POST(endpoints.validApplication + `?type=${APPLY_SUBLEASE}`, { RequestInfo: requestInfo() });
                if (plotData?.entityRefDetails?.length) {
                    let plotIds = plotData?.entityRefDetails?.map((item: any) => item.plotId);
                    plotIds = plotIds.join(',');

                    if (!plotIds) return;
                    let { data } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

                    plotDataArray = data?.plots?.map((plot: any) => ({
                        estateName: plot?.estateDetails?.estateName,
                        plotType: plot?.plotType,
                        phase: plot?.phase,
                        plotNo: plot?.plotNo,
                        plotAreaSqm: plot?.plotAreaSqm,
                        uuid: plot?.uuid,
                        id: plot?.uuid
                    }));
                }
            }

            setRowData([...appliedPlot, ...plotDataArray]);
            console.log('[...appliedPlot, ...plotDataArray]', [...appliedPlot, ...plotDataArray], 'appliedPlot', appliedPlot, 'plotDataArray', plotDataArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (subLesseeEntityRefDetails && dropdownData) {
            (async () => {
                try {
                    const { data: existingEnterprisedetails } = await POST(endpoints.leaseSpecialProject + `?entityRefDetailsIds=${subLesseeEntityRefDetails?.uuid}`, { RequestInfo: requestInfo() });

                    if (existingEnterprisedetails?.enterpriseDetails[0]) {
                        setEnterpriseDetails(existingEnterprisedetails?.enterpriseDetails[0]);

                        const enterpriseDetails = existingEnterprisedetails?.enterpriseDetails[0] ? await getEnterpriseData(existingEnterprisedetails?.enterpriseDetails[0], dropdownData) : {};

                        const newProposed = await getFormatedNICDetails(existingEnterprisedetails?.enterpriseDetails[0].nicEnterpriseMappings || [], dropdownData);
                        setNicDetails(newProposed);

                        setValues((prev: any) => ({ ...prev, ...enterpriseDetails }));
                    }
                } catch (e: any) {}
            })();
        }
    }, [subLesseeEntityRefDetails, dropdownData]);

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const findExistingUser = async (email: any) => {
        try {
            const { data: existing } = await POST(endpoints.searchExistingUser + `?email=${email}`, { RequestInfo: requestInfo() });
            const { data: entityRefData } = existing && (await POST(endpoints.entityRefDetails + `?entityIds=${existing?.enterpriseEntity[0]?.uuid}`, { RequestInfo: requestInfo() }));

            // const checkCondition = entityRefData?.entityRefDetails?.map((val: any) => val.type).filter((val: any) => val !== 'SUB_LESSEE_APPLICANT');
            setExistingUser(existing?.enterpriseEntity[0]);
            setSubLesseeEntityDetails(existing?.enterpriseEntity[0]);
            // setSubLeaseUuid(data?.enterpriseEntity[0]?.uuid);
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        if (values.subLeaseEmailIdOtpReferenceId && values.subLeaseEmailId) {
            findExistingUser(values.subLeaseEmailId);
        }
    }, [values.subLeaseEmailIdOtpReferenceId]);

    useEffect(() => {
        (async () => {
            if (activeIndex > 4 && values.plotUuid) {
                try {
                    const payCheck = await paymentCheck('PROCESSING_FEE', values.plotUuid, subLeaseResponse?.applicationDetails.uuid);
                    setIsPaid(payCheck?.isPaid);
                    setPaymentTried(payCheck?.paymentTried);
                    setPaymentData(payCheck?.paymentData);
                    setTotalPaidAmount(payCheck?.totalPaidAmount);
                    setTransactionDetails(payCheck?.transactionDetails);

                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);

                    const feeData = await fetchFeeData('SUB_LEASE', 'PROCESSING_FEE', values.plotUuid);
                    setPaymentData(feeData.feeMasterDetails);
                    setTotalPaidAmount(feeData.totalPaidAmount);
                    setPaymentIsLoading(false);
                } catch (e) {
                    console.log(e);
                    setPaymentIsLoading(false);
                }
            }
        })();

        const fetchData = async () => {
            const detailsData: any = await fetchLeaseDurationDoc(values.plotUuid, user.entityId);

            setValues((prevState: any) => ({
                ...prevState,
                lesseeDeedOfExtension: detailsData?.leaseDeed,
                occupancyCertificate: detailsData?.occupancyCertificate,
                utilisedBuiltUpArea: detailsData?.isBuiltUpAreaUtilised,
                lesseeDuesPayable: detailsData?.transferOrLandPremium,
                subLeaseSpecialProject: detailsData?.isSpecialProject
            }));

            hasValidAction
                ? queryParams.applicationNumber && values?.subLeaseBuiltUpArea
                    ? setIsCheckedSubLeaseDetails([detailsData?.isBuiltUpAreaUtilised, detailsData?.transferOrLandPremium, true, true])
                    : setIsCheckedSubLeaseDetails([detailsData?.isBuiltUpAreaUtilised, detailsData?.transferOrLandPremium, false, false])
                : setIsCheckedSubLeaseDetails([true, true, true, true]);
        };

        values.plotUuid && fetchData();

        const fetchESignStatus = async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNo || applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                const { data } = await POST(endpoints.consolidatedDocSearch, payload);
                // data.filestoreId && setESignedDocumentData(data);
                const consolidDocSearch = await POST(endpoints.consolidatedDocSearch, payload);
                consolidDocSearch?.data.filestoreId && setESignedDocumentData(consolidDocSearch?.data);
                !data.isEsignRequired && setIsChecked([true, true, true, true, true]);
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };
        if (activeIndex === 5 && !signedFileStoreId) {
            fetchESignStatus();
        }
    }, [activeIndex, values.plotUuid]);

    useEffect(() => {
        (async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNo || applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                if (signedFileStoreId) {
                    const { data } = await POST(endpoints.consolidatedDocESign, payload);
                    setESignedDocumentData(data);
                    !data.isEsignRequired && setIsChecked([true, true, true, true, true]);
                }
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [signedFileStoreId]);

    useEffect(() => {
        console.log(queryParams);
        if (queryParams.applicationNumber) {
            (async () => {
                try {
                    setPaymentIsLoading(true);
                    queryParams?.['activeIndex'] && setActiveIndex(+queryParams?.['activeIndex']);
                    // const transferData = await fetchLeaseDurationDoc(queryParams?.['plotId'], queryParams?.['entityId']);

                    const { data } = await POST(endpoints.searchSubLease + `?applicationNumbers=${queryParams?.['applicationNumber']}`, { RequestInfo: requestInfo() });
                    let subleaseData: any;
                    if (data?.subLeaseDetails?.length) {
                        subleaseData = data?.subLeaseDetails[0];
                        setApplicationNo(subleaseData?.applicationDetails?.applicationNo);
                        setApplicationType(subleaseData?.applicationDetails?.applicationType);
                        setFileStoreId(data?.subLeaseDetails?.[0]?.applicationDetails?.signedConsolidatedDocRefId);
                    } else {
                        const { data } = await POST(endpoints.searchAllotment + `?applicationNos=${queryParams?.['applicationNumber']}`, { RequestInfo: requestInfo() });
                        subleaseData = data?.estateApplications[0]?.subLeaseDetails;
                        setApplicationNo(data?.estateApplications[0]?.applicationNo);
                        setApplicationType(data?.estateApplications[0]?.applicationType);
                        setFileStoreId(data?.estateApplications?.[0]?.signedConsolidatedDocRefId);
                    }
                    setSubLeaseResponse(subleaseData);
                    setSubLeaseUuid(subleaseData?.uuid);

                    // setApplicationId(data?.applicationId);

                    const { data: plotSearch } =
                        subleaseData?.subLesseeEntityRefDetails?.plotId &&
                        (await POST(estateEndpoints.plotSearch + `?plotUuids=${subleaseData?.subLesseeEntityRefDetails?.plotId}`, { RequestInfo: requestInfo() }));

                    subleaseData?.subLesseeEntityDetails?.email && findExistingUser(subleaseData?.subLesseeEntityDetails?.email);

                    let durationSubLease = getDiffBWDates(subleaseData?.subLeaseTermStartDate, subleaseData?.subLeaseTermEndDate);

                    setValues((prev: any) => ({
                        ...prev,
                        // ...transferData,
                        subLeaseEmailIdOtpReferenceId: true,
                        plotUuid: subleaseData?.subLesseeEntityRefDetails?.plotId,
                        plotType: { code: plotSearch?.plots[0]?.plotType, name: plotSearch?.plots[0]?.plotType },
                        subLeaseEndDate: subleaseData?.subLeaseTermEndDate,
                        subLeaseStartDate: subleaseData?.subLeaseTermStartDate,
                        subLeaseBuiltUpArea: subleaseData?.area,
                        durationSubLease
                    }));
                    if (queryParams?.['plotId'] && queryParams?.['eg_pg_txnid']) {
                        getTransactionUpdate(queryParams);
                    } else {
                        setPaymentIsLoading(false);
                    }
                } catch (e: any) {
                    setPaymentIsLoading(false);
                    let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [location.search]);

    useEffect(() => {
        if (dropdownData && subLeaseResponse && applicationNumber) {
            setSubleaseApplicationData(subLeaseResponse, applicationNumber, dropdownData);
        }
    }, [dropdownData, subLeaseResponse]);

    useEffect(() => {
        if (!existingUser) {
            const updatedDetails = handleUpdates(values, applicationDetails2, SUB_LESSEE_DETAILS_II, 'Address of the Sub-Lessee', 'Mailing/Correspondence address of the Sub-Lessee');
            setApplicationDetails2(updatedDetails);
        }
    }, [values?.isPermanentPincodeDetailsPresent, values?.isPincodeDetailsPresent, values?.copyOnCheck]);

    const setSubleaseApplicationData = async (res: any, appNo: any, dropdownData: any) => {
        try {
            setIsLoading(true);
            if (appNo && Object.values(res).length && dropdownData) {
                // const { data } = await POST(endpoints.searchSubLease + `?applicationNos=${appNo}`, { RequestInfo: requestInfo() });
                // let allotmentData = data.estateApplications?.[0];

                const res1 = await getEntityData(res?.applicationDetails?.enterpriseEntity, dropdownData);
                const res2 = res.subLesseeEnterpriseDetails ? await getEnterpriseData(res.subLesseeEnterpriseDetails, dropdownData) : {};
                const docs = await getSubleaseDocs(res);

                // const planForSubLeaseAreaDocRefId = res?.planForSubLeaseAreaDocRefId && (await getDocumentDetails(res?.planForSubLeaseAreaDocRefId, DOCUMENT_TYPE.AREA_OF_SUB_LEASE));
                // const nocForSpecialProjectDocRefId = res?.nocForSpecialProjectDocRefId && (await getDocumentDetails(res?.nocForSpecialProjectDocRefId, DOCUMENT_TYPE.NOC_SPECIAL_PROJECT));
                // res?.planForSubLeaseAreaDocRefId && (await entityDocumentData([{ docReferenceId: res?.planForSubLeaseAreaDocRefId, documentType: DOCUMENT_TYPE.AREA_OF_SUB_LEASE }]))[0];
                // setSearchApplicationResponse(allotmentData.enterpriseEntity || {});
                // console.log('planForSubLeaseAreaDocRefId', planForSubLeaseAreaDocRefId);
                // console.log('nocForSpecialProjectDocRefId', nocForSpecialProjectDocRefId);

                setValues((prev: any) => ({
                    ...prev,
                    ...docs,
                    ...res1,
                    ...res2,
                    // ...docs,
                    applicationId: res.applicationDetails.uuid
                    // subLeaseEmailId: res?.applicationDetails?.enterpriseEntity?.email,
                    // isUpdatable: hasValidAction
                    // areaOfSubLease: planForSubLeaseAreaDocRefId,
                    // nocDocument: nocForSpecialProjectDocRefId
                }));
                const newOwnership = await getFormatedOwnershipDetails(res.applicationDetails?.enterpriseEntity?.owners, dropdownData);
                setOwnershipDetails(newOwnership);
                setControllingOwnershipResponse(res.applicationDetails?.enterpriseEntity?.owners);
                const newProposed = await getFormatedNICDetails(res.applicationDetails?.enterpriseDetails?.nicEnterpriseMappings || [], dropdownData);
                setNicDetails(newProposed);
            }
            setIsLoading(false);
        } catch (e: any) {
            console.error('Error fetching data:', e);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Application for Sub Lease', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getTransactionUpdate = async (params: any) => {
        try {
            if (params.plotId) {
                const paid: any = await paymentCheck('PROCESSING_FEE', params.plotId, params.applicationId);
                if (!paid.isPaid) {
                    const payload = { RequestInfo: requestInfo() };
                    const { data } = await POST2(endpoints.transactionUpdate, payload, { eg_pg_txnid: params.eg_pg_txnid });
                    if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                        setTransactionDetails(data?.Transaction?.[0]);
                        if (data?.Transaction?.[0]?.txnId) {
                            try {
                                const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                                if (resp.data.status === 'SUCCESSFUL') {
                                    setModalDetails({ show: true, title: 'Success!', para: 'Payment successful', img: check, type: 'success', reUpload: 'OK' });
                                    setIsPaid(true);
                                } else setIsPaid(false);
                            } catch (e: any) {
                                setIsPaid(false);
                            }
                        }
                    } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                        setModalDetails({
                            show: true,
                            title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                            para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                            img: cross,
                            type: 'error',
                            reUpload: 'OK'
                        });
                        // window.location.reload();
                    } else setIsPaid(false);
                }
            }
            setPaymentIsLoading(false);
        } catch (e: any) {
            setIsPaid(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Transaction Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const setApplicantData = async (dropdownData: any) => {
        try {
            const userId = JSON.parse(localStorage.getItem('user') || '{}')?.uuid;
            const { data } = await POST(endpoints.searchExistingUser + `?tenantId=${TENANT_ID}&userUuid=${userId}`, { RequestInfo: requestInfo() });
            setSearchApplicationResponse(data.enterpriseEntity[0]);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const signDocument = async () => {
        let url =
            window.location.origin + `/goa-idc/estate/sublease?activeIndex=${activeIndex}&applicationNumber=${applicationNo}&entityId=${searchApplicationResponse.uuid}&plotId=${values.plotUuid}`;

        url = btoa(url);

        if (values.esignOption === 'EKYC') {
            const userRequestInfo = requestInfo();
            const eKYCInitiate =
                devUrl +
                endpoints.kycInitiate +
                `?fileStoreId=${eSignedDocumentData.filestoreId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }&callBackUrl=${url}`;

            window.open(eKYCInitiate, '_self');
        } else {
            navigate(`/goa-idc/emudra-esign?fileStoreId=${eSignedDocumentData.filestoreId}&callbackUrl=${url}`);
        }
    };

    const handleClickSignDocument = () => {
        if (activeIndex === 5 && !isChecked.every((e: boolean) => e)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setEsignModal(true);
    };

    const onPay = async (key?: any) => {
        setPaymentIsLoading(true);

        let url =
            window.location.origin +
            `/goa-idc/estate/sublease?plotId=${values.plotUuid}&activeIndex=${activeIndex}&applicationNumber=${subLeaseResponse?.applicationDetails?.applicationNo}&entityId=${searchApplicationResponse.uuid}&applicationId=${subLeaseResponse?.applicationDetails?.uuid}`;
        const periodTo = new Date(subLeaseResponse?.applicationDetails.auditDetails.createdTime).setFullYear(new Date(subLeaseResponse?.applicationDetails.auditDetails.createdTime).getFullYear() + 1);
        const payload = paymentPayload(
            subLeaseResponse?.applicationDetails,
            values.plotUuid,
            totalPaidAmount,
            url,
            'Sub-Lessee Application Fees',
            selectedGateway,
            subLeaseResponse?.applicationDetails.auditDetails.createdTime,
            periodTo,
            paymentData
        );
        try {
            if (!totalPaidAmount) {
                setPaymentIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (!selectedGateway) {
                setPaymentIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data } = await POST(endpoints.paymentGateway, payload);

                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, user, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data.additionalDetails?.walletClientCode}&walletRequestMessage=${data.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setPaymentIsLoading(false);
            setModalDetails({ show: true, title: 'Payment Initiation Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const lastSubmit = (key?: any) => {
        if (!isPaid) onPay(key);
        else onSubmit(key);
    };

    const onSubmit = async (key?: any) => {
        const isFinalSubmit = activeIndex === SUB_LEASE_STEPS?.length ? true : false;

        const payload = SUBLEASE_PAYLOAD(
            values,
            nicDetails,
            ownershipDetails,
            activeIndex,
            searchApplicationResponse,
            subLeaseResponse,
            isFinalSubmit,
            subLesseeEntityDetails,
            subLeaseUuid,
            applicationNo,
            existingUser,
            enterpriseDetails
        );
        let data: any;

        const currOwnership = ownershipDetails
            .filter((item: any) => !item.isDelete)
            .reduce((accumulator: number, subItem: any) => {
                return accumulator + subItem.ownershipInterest;
            }, 0);

        if (activeIndex === 1 && currOwnership != 100 && values?.category?.code !== 'LTD_COMPANY') {
            setModalDetails({ show: true, title: 'Error!', para: 'Your ownership interest does not add upto 100%', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 1 && !calculated) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please calculate controlling ownership', img: cross, type: 'error', reUpload: 'OK' });

            return;
        }

        if (activeIndex === 2 && !values?.plotUuid) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please select a plot for Sub-Lease', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 3) {
            if (isCheckedSubLeaseDetails[0] === false) {
                setModalDetails({
                    show: true,
                    title: t('ERROR', 'Error!'),
                    para: t('BUILT_UP_AREA_UTILIZATION', 'Utilization of Built-Up area is less than 30% of Plot Area'),
                    img: cross,
                    type: 'error',
                    reUpload: 'Ok'
                });
                return;
            }
            if (isCheckedSubLeaseDetails[1] === false) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('AMOUNT_NOT_PAID', 'Entire amount of Land Premium has not been paid'), img: cross, type: 'error', reUpload: 'Ok' });
                return;
            }
            if (!isCheckedSubLeaseDetails.every((e: boolean) => e)) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('ACCEPT_ALL_TERMS', 'Please accept all the terms'), img: cross, type: 'error', reUpload: 'Ok' });
                return;
            }
        }

        if (activeIndex === 4 && (!nicDetails || !nicDetails?.length)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please add atleast 1 proposed activity', img: cross, type: 'error', reUpload: 'OK' });

            return;
        }
        if (activeIndex === 5 && !isChecked.every((e: boolean) => e)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });

            return;
        }

        if (activeIndex === 5 && eSignedDocumentData?.isEsignRequired) {
            setModalDetails({ show: true, title: 'E-Sign Pending', para: 'Please sign the document', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            await SUB_LEASE_LESSEE_VAALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
            try {
                setIsLoading(true);
                if (activeIndex === 1 && !applicationNo) {
                    data = await POST(endpoints.createSubLease, payload);
                } else {
                    data = await POST(endpoints.updateSubLease, payload);
                }
                if (key === 1) onChangeNextIndex();
                setIsLoading(false);
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
            if (data && !isFinalSubmit) {
                setSubLeaseUuid(data?.data?.subLeaseDetails[0]?.uuid);
                setApplicationNo(data?.data?.subLeaseDetails[0]?.applicationDetails?.applicationNo || '');
                setApplicationType(data?.data?.subLeaseDetails[0]?.applicationDetails?.applicationType || '');
                setSubLesseeEntityRefDetails(data?.data?.subLeaseDetails[0]?.subLesseeEntityRefDetails || '');
                // setEnterpriseDetails(data?.data?.subLeaseBuiltUpArea[0]?.subLesseeEnterpriseDetails);

                setSubLesseeEntityDetails(data.data.subLeaseDetails?.[0].subLesseeEntityDetails || {});
                setSubLeaseResponse(data.data.subLeaseDetails?.[0] || {});

                const entityDocs = await setEntityDocData(data.data.subLeaseDetails?.[0]?.subLesseeEntityDetails?.documents || []);
                const enterpriseDocs = await setEnterpriseDocData(data.data.subLeaseDetails?.[0]?.cb?.documents || []);
                setValues((prev: any) => ({
                    ...prev,
                    entityUserUuid: data.data.subLeaseDetails?.[0].uuid,
                    ...entityDocs,
                    ...enterpriseDocs
                }));
            }

            if (isFinalSubmit) {
                setModalDetails({ show: true, title: 'Success!', para: 'Application for Sub Lease submitted successfully', finalSubmit: true, img: check, type: 'success', reUpload: 'OK' });
            } else toast('Action successful', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangeNextIndex = async () => {
        const stepperLength = SUB_LEASE_STEPS.length;
        try {
            if (activeIndex < stepperLength) {
                await SUB_LEASE_LESSEE_VAALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            }
        } catch (e) {
            toast(t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar',
                type: 'warning'
            });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = SUB_LEASE_STEPS.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                await SUB_LEASE_LESSEE_VAALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const handleScroll = () => {
        if (window.scrollY > 110) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const onPlotSelect = (selectedPlot: any) => {
        // setSelectedPlotDataId(selectedPlot.uuid);
        setValues((prev: any) => ({ ...prev, plotUuid: selectedPlot.uuid, plotType: { code: selectedPlot.plotType, name: selectedPlot.plotType } }));
    };

    const plotDetailsTableColumns = PLOT_DETAILS_TABLE.map((val: any, index: number) => {
        if (index === 0) {
            return {
                ...val,
                onRadioClick: onPlotSelect,
                selectedValue: values.plotUuid
            };
        }
        return val;
    });

    const handleModalClose = (type: string, final?: boolean, isUnauthorized?: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success' && final && !isUnauthorized) {
            navigate('/goa-idc/enterprisedashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            } else if (userType === 'EMPLOYEE') {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    const status: any = progressBarDetails?.progressStatus?.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: applicationNumber,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };

    const handleCheckboxChangeSubLeaseDetails = (i: any) => {
        let checks = isCheckedSubLeaseDetails;
        checks[i] = !isCheckedSubLeaseDetails[i];
        setIsCheckedSubLeaseDetails([...checks]);
    };

    const onClickNext = () => {
        if (activeIndex < (readOnly ? SUB_LEASE_READ_ONLY_ASTEPS : SUB_LEASE_STEPS).length) {
            setActiveIndex(activeIndex + 1);
        } else {
            navigate('/goa-idc/enterprisedashboard');
        }
    };

    console.log('valuesssss', values);
    console.log('applicationDetails2', applicationDetails2);

    return (
        <>
            {/* <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} /> */}
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={t('APPLICATION_FOR_SUB_LEASE', 'Application for Sub-Lease')}
                            bottomSubText={applicationNo ? `Type : ${t(applicationType)}, No : ${applicationNo}` : ``}
                            additionalClass='sub-text-style'
                        />
                        <div className='col-3 stickyCard-section'>
                            <div className='stickyCard' style={{ top: '110px' }}>
                                <GenericHeader
                                    text={t('APPLICATION_FOR_SUB_LEASE', 'Application for Sub-Lease')}
                                    sideHeader={true}
                                    additionalClass='sub-text-style'
                                    bottomSubText={applicationNo ? `Type : ${t(applicationType)}, No : ${applicationNo}` : ``}
                                />
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column  row-gap-3'>
                                            <SideCard
                                                steps={readOnly ? SUB_LEASE_READ_ONLY_ASTEPS : SUB_LEASE_STEPS}
                                                activeIndex={activeIndex}
                                                handleStepClick={handleStepClick}
                                                indexType={'custom'}
                                                indexArray={['A1', 'A2', 'A3', 'B', 'C', 'D']}
                                            />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                                <TemplateCard category={`ENTITY_REGISTRATION, ENTERPRISE_DETAILS`} />
                            </div>
                        </div>
                        <div className='col-9 form-section'>
                            {!!applicationNumber && applicationData.status !== 'Draft' && !subleaseInfo && (
                                <Card className='card_border my-2'>
                                    <Card.Body>
                                        <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                                    </Card.Body>
                                </Card>
                            )}
                            <FormikProvider value={formik}>
                                <Form>
                                    {activeIndex === 3 && (
                                        <Card className='card_border '>
                                            <Card.Body>
                                                <LeaseDetails
                                                    isCheckedSubLeaseDetails={isCheckedSubLeaseDetails}
                                                    setIsCheckedSubLeaseDetails={setIsCheckedSubLeaseDetails}
                                                    handleCheckboxChange={handleCheckboxChangeSubLeaseDetails}
                                                />
                                            </Card.Body>
                                        </Card>
                                    )}
                                    {activeIndex === 1 && (
                                        <Card className='card_border '>
                                            <Card.Body>
                                                <ApplicantDetails
                                                    dropdownOptions={dropdownData}
                                                    setOwnershipDetails={setOwnershipDetails}
                                                    controllingOwnershipResponse={controllingOwnershipResponse}
                                                    setControllingOwnershipResponse={setControllingOwnershipResponse}
                                                    ownershipDetails={ownershipDetails}
                                                    setCalculated={setCalculated}
                                                    showOwnershipModal={showOwnershipModal}
                                                    setShowOwnershipModal={setShowOwnershipModal}
                                                    applicantDetails1={applicationDetails1}
                                                    applicantDetails2={applicationDetails2}
                                                    promoterDetails={applicationDetails3}
                                                    hideBankDetails={true}
                                                    isDisabled={!!existingUser || !values.isUpdatable}
                                                    skipFullOwnershipPercent={values?.category?.code === 'LTD_COMPANY'}
                                                ></ApplicantDetails>
                                                {/* <SubLesseeDetailsFirst dropdownData={dropdownData} values={values} showOwnershipModal={showOwnershipModal} setShowOwnershipModal={setShowOwnershipModal} /> */}
                                            </Card.Body>
                                        </Card>
                                    )}

                                    {activeIndex === 2 && (
                                        // <PlotDetails dropdownData={dropdownDataPlot} setDropdownData={setDropdownDataPlot} isFieldDisable={true} OtherField={renderOtherFields()} plotOtherDetails={false} />
                                        <Card className='card_border '>
                                            <Card.Body>
                                                <GenericActionTable rowData={rowData} columns={plotDetailsTableColumns} searchBarPlaceholder={'SEARCH_BY_PLOT'} />
                                                {plotOtherField?.map((val: any) => (
                                                    <GenericFormCard {...val} />
                                                ))}
                                            </Card.Body>
                                        </Card>
                                    )}
                                    {activeIndex === 4 && (
                                        <Card className='card_border'>
                                            <Card.Body>
                                                <EnterpriseDetails
                                                    enterpriseDetails={ENTERPRISE_DETAILS}
                                                    enterpriseProjectDetails={PROJECT_DETAILS}
                                                    declarationDetails={DECLARATION_DETAILS}
                                                    dropdownOptions={dropdownData}
                                                    nicDetails={nicDetails}
                                                    setNicDetails={setNicDetails}
                                                    applicantName={'Sub-Lessee'}
                                                    showPopUp={showPopUp}
                                                    setShowPopUp={setShowPopUp}
                                                    isDisabled={!values?.isUpdatable}
                                                />
                                            </Card.Body>
                                        </Card>
                                    )}
                                    {activeIndex === 5 && (
                                        <Declaration
                                            isChecked={isChecked}
                                            declarationList={DECLARATION_LABEL}
                                            handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                                            signDocument={handleClickSignDocument}
                                            eSignedDocumentData={eSignedDocumentData}
                                            applicantName={'Sub-Lessee'}
                                            fileStoreId={fileStoreId}
                                        ></Declaration>
                                    )}

                                    {activeIndex === 6 &&
                                        (!paymentIsLoading ? (
                                            <PaymentDetails
                                                paymentData={paymentData}
                                                selectedGateway={selectedGateway}
                                                setSelectedGateway={setSelectedGateway}
                                                isPaid={isPaid}
                                                gatewayData={gatewayData}
                                                transactionDetails={transactionDetails}
                                                title={'Sub-Lessee Application Fees'}
                                            ></PaymentDetails>
                                        ) : (
                                            <Loading />
                                        ))}
                                </Form>
                                <GenericPopupModal
                                    title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                    primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                    secondaryBtnTitle={t('CLOSE', 'Close')}
                                    isSecondaryBtn={true}
                                    secondaryAction={() => setEsignModal(false)}
                                    primaryAction={signDocument}
                                    isVisible={esignModal}
                                    children={<EsignModal />}
                                />
                            </FormikProvider>
                        </div>
                    </div>
                    <ScrollToTop />
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                            </div>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {hasValidAction ? (
                                    <>
                                        {activeIndex !== (readOnly ? SUB_LEASE_READ_ONLY_ASTEPS : SUB_LEASE_STEPS).length && (
                                            <GenericButtons text={t('SAVE', 'Save')} handleClick={() => {}} ClassName='me-3' />
                                        )}
                                        <GenericButtons
                                            text={
                                                activeIndex !== (readOnly ? SUB_LEASE_READ_ONLY_ASTEPS : SUB_LEASE_STEPS).length
                                                    ? `Save & Next`
                                                    : isPaid
                                                    ? t('SUBMIT', 'Submit')
                                                    : paymentTried
                                                    ? `Retry Payment`
                                                    : `Pay Now`
                                            }
                                            variant='primary'
                                            handleClick={() => (activeIndex !== (readOnly ? SUB_LEASE_READ_ONLY_ASTEPS : SUB_LEASE_STEPS).length ? onSubmit(1) : lastSubmit && lastSubmit())}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <GenericButtons
                                            text={activeIndex === (readOnly ? SUB_LEASE_READ_ONLY_ASTEPS : SUB_LEASE_STEPS).length ? 'Close' : 'Next'}
                                            variant='primary'
                                            handleClick={onClickNext}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </footer>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails?.finalSubmit, modalDetails?.isUnauthorized)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.finalSubmit, modalDetails?.isUnauthorized)}
                    />
                </>
            )}
        </>
    );
};

export default SubLeaseComponent;
