import LoginPage from '../features/loginPage/LoginPage.component';
import MainPage from '../features/mainPage/MainPage.wrapper';
import TechnicalClearanceFormPage from '../features/technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.component';
import EmployeeCreationPage from '../features/modules/UserManagement/EmployeeModule/EmployeeCreationPage';
import EnterpriseCreationPage from '../features/modules/UserManagement/EnterpriseModule/EnterpriseCreationPage';
import UserManagementPage from '../features/modules/UserManagement/UserManagementPage';
import RedirectUrlScreen from './RedirectUrlScreen';
import OtpValidate from '../features/loginPage/OtpValidationPage.component';
import ArchitectCreationPage from '../features/modules/UserManagement/ArchitectModule/ArchitectCreationPage';
// import BpaHomePage from "../features/modules/UserManagement/BpaHomePage";
import ScrutinyFormPage from '../features/scrutinyFlowScreen/ScrutinyFormPage.component';
import ArchitectDashboard from '../features/modules/UserManagement/ArchitectModule/ArchitectDashboard';
import EnterpriseDashboard from '../features/modules/UserManagement/EnterpriseModule/EnterpriseDashboard';
import AboutUs from '../features/commonPages/AboutUs';
import ResetPassword from '../features/loginPage/ResetPasswordPage.component';
import FaqPage from '../features/commonPages/FAQPage';
import ContactUsPage from '../features/commonPages/ContactUs';
import TenderPage from '../features/commonPages/TenderPage';
import UnderConstruction from '../features/commonPages/UnderConstruction';
import WhoIsWho from '../features/commonPages/WhoIsWhoPage';
import Disclaimer from '../features/commonPages/Disclaimer';
import { PrivacyPolicy } from '../features/commonPages/PrivacyPolicy';
import { TermsandConditionPage } from '../features/commonPages/TermsCondition';
import PlotDetails from '../features/commonPages/PlotDetails';
import ApplicationSearchPage from '../features/modules/UserManagement/EmployeeModule/ApplicationSearchPage';
import Dashboard from '../features/modules/UserManagement/EmployeeModule/Dashboard';
import OrderCompletionFormPage from '../features/orderCompletionScreen/OrderCompletionFormPage.component';
import M_OrderCompletionFormPage from '../features/orderCompletionScreen/m_OrderCompletionFormPage.component';

import TechnicalSkipPayment from '../features/technicalFlowScreen/technicalClearanceForm/technicalSkipPayment/TechnicalSkipPayment.wrapper';
// import { ConstructionDashboard } from "../components/ConstructionLicense/ConstructionlicenseDashboard.component";
// import { ConstructionLicensePayment } from "../components/ConstructionLicense/PaymentStatus.component";
import ScrutinySharedPlans from '../features/scrutinyFlowScreen/scrutinyPopUp/ScrutinyDashboard';
import EstateListPage from '../features/modules/EstateManagement/EstateRegistry/EstateListPage';
import NotificationPage from '../components/NotificationComponent/NotificationPage';
import EmployeeSearch from '../features/modules/UserManagement/EmployeeModule/EmployeeSearch';
import { EmployeeDetailsPage } from '../features/modules/UserManagement/EmployeeModule/EmployeeDetailsPage';
import ConstructionLicenseMainPage from '../features/constructionLicense/ConstructionlicenseMainPage.component';
import NOC from '../features/modules/EstateManagement/NOC/NOC_CreationPage';
import ProfileComponent from '../components/Profile section/ProfileComponent';
import LeaseDeed from '../features/leaseDeed/LeaseDeed';
import { Payment } from '../features/modules/UserManagement/EmployeeModule/Payment';
import FieldInspectionMainPage from '../features/orderCompletionScreen/fieldInspection/FieldInspectionMainPage.component';
import HolidayList from '../features/commonPages/HolidayListPage';
import { PaymentStatus } from '../features/modules/UserManagement/EmployeeModule/PaymentStatus';
import MigrationForm from '../features/modules/Migration/MigrationForm';

import Transfer from '../features/modules/EstateManagement/Transfer/Transfer';
import CreateAllotment from '../features/modules/EstateManagement/Allotment/AllotmentPage';
import Transferee from '../features/modules/EstateManagement/Transferee/Transferee';
import ApplyForPlotAllotment from '../features/modules/EstateManagement/Allotment/ApplyForPlotAllotment';
import AllotmentWorkflow from '../features/modules/EstateManagement/Allotment/Workflow/AllotmentWorkflow';

import AdvertisementPage from '../features/modules/EstateManagement/Advertisement/CreateAdvertisement/AdvertisementPage';
import CreateAdvertisement from '../features/modules/EstateManagement/Advertisement/CreateAdvertisement/CreateAdvertisement';
import SPVApplication from '../features/modules/EstateManagement/SPV/SPVApplication';
import SPFWorkflow from '../features/modules/EstateManagement/SPV/Workflow/SPVWorkflow';

import M_TechnicalClearanceFormPage from '../features/technicalFlowScreen/technicalClearanceForm/m_TechnicalClearanceFormPage.component';
import M_ConstructionLicenseMainPage from '../features/constructionLicense/m_ConstructionlicenseMainPage.component';

import LeaseDeedApplication from '../features/modules/EstateManagement/LeaseDeed/LeaseDeedApplication';
import LeaseDeedWorkflow from '../features/modules/EstateManagement/LeaseDeed/Workflow/LeaseDeedWorkflow';
import Extension from '../features/modules/EstateManagement/Extension/Extension';
import MassAllotment from '../features/modules/EstateManagement/Allotment/MassAllotment';
import FindAdvertisement from '../features/modules/EstateManagement/Advertisement/Searchadvertisement/FindAdvertisement';
import ProvisionalAllotment from '../features/modules/EstateManagement/Allotment/Workflow/ProvisionalAllotment';
import PlotMaster from '../features/modules/EstateManagement/Plot Master/PlotMaster';
import EstateMaster from '../features/modules/EstateManagement/Estate_Master/EstateMaster';
import SpecialTransferApplication from '../features/modules/EstateManagement/SpecialTransfer/SpecialTransferApplication';
import ExtWorkflow from '../features/modules/EstateManagement/Extension/ExtWorkflow';
import { RegisterDeeds } from '../features/modules/EstateManagement/LeaseDeed/RegisterDeed';
import Cancellation from '../features/modules/EstateManagement/Cancellation/Cancellation';
import ExtensionInitiateByEmployee from '../features/modules/EstateManagement/Extension/EmployeeInitiate/InitiateExtension';
import SubLeaseComponent from '../features/modules/EstateManagement/SubLease/SubLeaseMain';
import SubLeaseWorkflow from '../features/modules/EstateManagement/SubLease/SubleaseWorkflow/SubLeaseWorkFlowMain';
import TransferWorkflow from '../features/modules/EstateManagement/Transfer/Workflow/TransferWorkflow';
import { TransferDetailsTable } from '../features/modules/EstateManagement/Transferee/TransferDetailsTable';
import SurrenderComponent from '../features/modules/EstateManagement/Surrender/Surrender.component';
import { EstatePayment } from '../features/modules/EstateManagement/Payment/EstatePayment';
import { EstateMasterWorkflow } from '../features/modules/EstateManagement/Estate_Master/EstateMasterWorkflow';
import { EstateApplication } from '../features/modules/EstateManagement/Estate_Master/EstateApplication';
import LeaseExtension from '../features/modules/EstateManagement/LeaseExtension/LeaseExtensionMainWrapper';
import DocumentDownloads from '../features/modules/UserManagement/EnterpriseModule/DocumentDownloads';
import WaterConnection from '../features/waterConnectionScreen/WaterConnectionFormPage.component';
import M_WaterConnection from '../features/waterConnectionScreen/m_WaterConnectionFormPage.component';
import M_BankNOC from '../features/WBankNOC/m_BankNOCFormPage.component';
import { NewPlot } from '../features/modules/EstateManagement/Plot Master/NewPlot';
import { PlotWorkflow } from '../features/modules/EstateManagement/Plot Master/Plot master Workflow/PlotWorkflow';
import { PlotEstateData } from '../features/modules/EstateManagement/Allotment/PlotEstateData';
import { components } from 'react-select';
import TransferMainPage from '../features/modules/EstateManagement/Transfer/TransferMainPage';
import MortgageComponent from '../features/modules/Mortgage Lessee/MortgageComponent';
import { SubleaseOrder } from '../features/modules/EstateManagement/SubLease/SubleaseOrder';
import ApplyForPlotScreen from '../components/new screens/ApplyForPlotScreen';
import WaterConnectionScreen from '../components/new screens/WaterConnectionScreen';
import UserManagScreen from '../components/new screens/UserManagScreen';
import SubleaseOrderWorkflow from '../features/modules/EstateManagement/SubLease/SubleaseWorkflow/SubleaseOrderWorkflow';
import NewDashboardScreen from '../features/modules/MenuScreensModule/NewDashboardScreen';
import RenewalOfConstructionLicense from '../features/renewalOfConstructionLicense/renewalOfConstructionLicense.component';
import M_RenewalOfConstructionLicense from '../features/renewalOfConstructionLicense/m_RenewalOfConstructionLicenseFormPage.component';
import MortgageWorkflow from '../features/modules/Mortgage Lessee/MortgageWorkflow/MortgageWorkflow';
import LeaseExtensionWorkflow from '../features/modules/EstateManagement/LeaseExtension/Workflow/LeaseExtensionWorkflow';
import MiscellaneousApplication from '../features/modules/EstateManagement/Miscellaneous/MiscellaneousApplication';
import SurrenderWorkFlow from '../features/modules/EstateManagement/Surrender/SurrenderWorkflow/SurrenderWorkFlow';
import { MiscellaneousWorkflow } from '../features/modules/EstateManagement/Miscellaneous/Workflow/MiscellaneousWorkflow';
import { CommercialOperation } from '../features/modules/EstateManagement/CommercialOperation/CommercialOperation';
import { CommercialOperationWorkflow } from '../features/modules/EstateManagement/CommercialOperation/WorkFlow/CommercialOperationWorkflow';
import WaterConnectionUpgradation from '../features/waterConnectionScreen/WaterConnectionUpgradationFormPage.component';
import M_WaterConnectionUpgradation from '../features/waterConnectionScreen/m_WaterConnectionUpgradationFormPage.component';
import WaterConnectionDisconnection from '../features/waterConnectionScreen/WaterConnectionDisconnectionFormPage.component';
import M_WaterConnectionDisconnection from '../features/waterConnectionScreen/m_WaterConnectionDisconnectionFormPage.component';
import { IllegalityApplication } from '../features/modules/EstateManagement/Illegality/IllegalityApplication';
import { EnterpriseProfileComponent } from '../components/Profile section/EnterpriseProfileComponent';
import QueryEntry from '../features/Query  Entry screen/QueryEntry';
import ArchitectWorkflowMain from '../features/modules/UserManagement/ArchitectModule/ArchitectWorkflow/ArchitectWorkflowMain';

import WaterConnectionPayment from '../features/waterConnectionScreen/WaterConnectionPayment';
import QueryEntryWorkflow from '../features/Query  Entry screen/Query entry workflow/QueryEntryWorkflow';
import M_WaterConnectionBillGeneration from '../features/waterConnectionScreen/m_WaterConnectionBillGeneration.component';
import { InspectionReport } from '../features/modules/EstateManagement/InspectionReport/InspectionReport';
import PaymentStatusForm from '../features/commonPages/ICICIDummyStatus';
import ICICIPaymentPage from '../features/commonPages/ICICIPaymentPage';
import DprWorkflow from '../features/modules/Workspace/DPR creation screen/DprWorkflow';
import EmployeeRegistration from '../features/modules/UserManagement/EmployeeModule/EmployeeWorkflow/EmployeeRegistrationWorkflow';
import ContentManagement from '../features/modules/UserManagement/EmployeeModule/ContentManagement';
import { MigrationSearchPage } from '../features/modules/Migration/MigrationSearchPage';
import { Component } from 'react';
import GsrScreen from '../features/modules/Workspace/GSR/GsrScreen';
import GsrMasterDetails from '../features/modules/Workspace/GSR/GsrSecondScreen';
import { EmployeeMapping } from '../features/modules/UserManagement/EmployeeModule/EmployeeMapping/EmployeeMapping';
import { EmployeeMappingEdit } from '../features/modules/UserManagement/EmployeeModule/EmployeeMapping/EmployeeMappingEdit';
import DprSearchScreen from '../features/modules/Workspace/NIT screens/DprSearchComponent';
import NitWorkflow from '../features/modules/Workspace/NIT screens/NitWorkflow';
import LoaSearchComponent from '../features/modules/Workspace/LOAScreens/LoaSearchComponent';
import LoaWorkflow from '../features/modules/Workspace/LOAScreens/LoaWorkflow';
import { EmudraPage } from '../features/commonPages/EmudraPage';
import { EmudraEsignPage } from '../features/commonPages/EmudraEsignPage';
import DscRegistration from '../features/modules/EstateManagement/DSCRegistration/DscRegistration';
import WorkOrderSearchComponent from '../features/modules/Workspace/WorkOrderScreens/WorkOrderSearchComponent';
import WorkOrderWorkflow from '../features/modules/Workspace/WorkOrderScreens/WorkOrderWorkflow';
import VacantPlotSearch from '../features/modules/EstateManagement/Plot Master/VacantPlotSearch';
import ContractCreationSearchComp from '../features/modules/Workspace/ContractCreationScreens/ContractCreationSearchComp';
import ContractCreationWorkflow from '../features/modules/Workspace/ContractCreationScreens/ContractCreationWorkflow';
import ContarctClosure from '../features/modules/Workspace/ContractClosure/ContractClosure';
import ContractClosureWorkflow from '../features/modules/Workspace/ContractClosure/Workflow/ContractClosureWorkflow';
import SeacrhContractScreen from '../features/modules/Workspace/RA Bill/SeacrhContractScreen';
import RunningAccountBillWorkflow from '../features/modules/Workspace/RA Bill/RunningAccountBillWorkflow';
import SearchContract from '../features/modules/Workspace/ExtensionClosure/SearchContract';
import ExtensionWorkflow from '../features/modules/Workspace/ExtensionClosure/ExtensionWorkflow';
import DprApplicationSearch from '../features/modules/Workspace/DPR creation screen/DprApplicationSearch';
import NitApplicationSearch from '../features/modules/Workspace/NIT screens/NitApplicationSearch';
import LoaApplicationSearch from '../features/modules/Workspace/LOAScreens/LoaApplicationSearch';
import WorkOrderApplicationSearch from '../features/modules/Workspace/WorkOrderScreens/WorkOrderApplicationSearch';
import ContractCreationApplicationSearch from '../features/modules/Workspace/ContractCreationScreens/ContractCreationApplicationSearch';
import RaBillApplicationSearch from '../features/modules/Workspace/RA Bill/RaBillApplicationSearch';
import EnterpriseManagement from '../features/modules/UserManagement/EnterpriseManagement/EnterpriseManagement';
import EnterpriseSearch from '../features/modules/UserManagement/EnterpriseManagement/EnterpriseSearch';
import EnterpriseDetailsUpdate from '../features/modules/UserManagement/EnterpriseManagement/EnterpriseDetailsUpdate';
import ReportDashboard from '../features/modules/UserManagement/EmployeeModule/ReportsDashboard';
import TaskReassignment from '../features/modules/UserManagement/TaskReassignment/TaskReassignment';
// import TaskReassignmentUpdate from '../features/modules/UserManagement/TaskReassignment/TaskReassignmentUpdate';
import TaskReassignmentSearch from '../features/modules/UserManagement/TaskReassignment/TaskReassignmentSearch';
import UnauthorizedPage from '../features/commonPages/UnauthorizedPage';
import MigrationPaymentUpdate from '../features/modules/Migration/MigrationPaymentUpdate';
import { MigrationPaymentSearch } from '../features/modules/UserManagement/EnterpriseManagement/MigrationPaymentSearch';

// import PlotScreen from '../features/modules/UserManagement/MenuScreensModule/PlotScreen';
// import PlotScreen from '../features/modules/UserManagement/'

export const routes = [
    // { path: '/goa-idc/plotScreen', component: PlotScreen },
    { path: '/goa-idc/dashboard', component: Dashboard },
    { path: '/goa-idc/usermanagement', component: UserManagementPage },
    { path: '/goa-idc/usermanagement/employeecreation', component: EmployeeRegistration },
    { path: '/goa-idc/usermanagement/searchapplication', component: ApplicationSearchPage },
    { path: '/goa-idc/emp/empPreview', component: EmployeeRegistration },
    { path: '/goa-idc/obpas/technicalClearance', component: TechnicalClearanceFormPage, exact: true },
    { path: '/goa-idc/obpas/e/technicalClearance', component: M_TechnicalClearanceFormPage },
    { path: '/goa-idc/login', component: LoginPage },
    { path: '/goa-idc/validate', component: OtpValidate },
    { path: '/goa-idc/usermanagement/enterprisecreation', component: EnterpriseCreationPage },
    { path: '/goa-idc/usermanagement/architectCreation', component: ArchitectCreationPage },
    { path: '/goa-idc/usermanagement/architect-creation-workflow', component: ArchitectWorkflowMain },

    { path: '/goa-idc/obpas/scrutinyTool', component: ScrutinyFormPage },
    { path: '/goa-idc/bpa/scrutinyFlowScreen', component: ScrutinyFormPage },
    { path: '/goa-idc/architectdashboard', component: ArchitectDashboard },
    { path: '/goa-idc/scrutinysharedplans', component: ScrutinySharedPlans },
    { path: '/goa-idc/enterprisedashboard', component: EnterpriseDashboard },
    { path: '/goa-idc/estate/estateRegistry', component: EstateListPage },
    { path: '/goa-idc/obpas/constructionLicense', component: ConstructionLicenseMainPage },

    { path: '/goa-idc/obpas/e/constructionLicense', component: M_ConstructionLicenseMainPage },

    { path: '/goa-idc/noc', component: NOC },
    { path: '/goa-idc/obpas/occupancyCertificate', component: OrderCompletionFormPage, exact: true },

    { path: '/goa-idc/obpas/e/occupancyCertificate', component: M_OrderCompletionFormPage },

    { path: '/goa-idc/obpas/fieldInspectionPage', component: FieldInspectionMainPage },
    { path: '/goa-idc/obpas/paymentSkip', component: TechnicalSkipPayment, exact: true },
    { path: '/goa-idc/viewNotification', component: NotificationPage, exact: true },
    { path: '/goa-idc/empsearch', component: EmployeeSearch },
    { path: '/goa-idc/empdetails', component: EmployeeDetailsPage },
    { path: '/goa-idc/profile', component: ProfileComponent },
    { path: '/goa-idc/apply-deed', component: LeaseDeed },
    { path: '/goa-idc/emp-profile', component: ProfileComponent },
    { path: '/goa-idc/enterprise-profile', component: EnterpriseProfileComponent },
    { path: '/goa-idc/estate/allotment', component: CreateAllotment },
    { path: '/goa-idc/estate/allotment/workflow', component: AllotmentWorkflow },
    { path: '/goa-idc/estate/apply-for-plot', component: ApplyForPlotAllotment },
    { path: '/goa-idc/estate/indenture/workflow', component: LeaseDeedWorkflow },
    { path: '/goa-idc/estate/order', component: ProvisionalAllotment },
    { path: '/goa-idc/estate/estate-registry', component: EstateMaster },
    { path: '/goa-idc/estate/new-estate-registry', component: EstateApplication },
    { path: '/goa-idc/estate/estate-registry-workflow', component: EstateMasterWorkflow },
    { path: '/goa-idc/estate/new-plot-registry', component: NewPlot },
    { path: '/goa-idc/estate/plot-registry-workflow', component: PlotWorkflow },
    { path: '/goa-idc/estate/special-transfer', component: SpecialTransferApplication },
    { path: '/goa-idc/estate/register-indenture', component: RegisterDeeds },
    { path: '/goa-idc/estate/cancellation-workflow', component: Cancellation },
    { path: '/goa-idc/estate/initiate-extension', component: ExtensionInitiateByEmployee },
    { path: '/goa-idc/sublease', component: SubLeaseComponent },
    { path: '/goa-idc/estate/sublease-order', component: SubleaseOrder },
    { path: '/goa-idc/estate/surrender', component: SurrenderComponent },
    { path: '/goa-idc/estate/payment', component: EstatePayment },
    { path: '/goa-idc/estate/lease-term-extension', component: LeaseExtension },
    { path: '/goa-idc/document-downloads', component: DocumentDownloads },
    { path: '/goa-idc/estate/apply-for-plot/plot-estate-data', component: PlotEstateData },
    { path: '/goa-idc/estate/mortgage', component: MortgageComponent },
    { path: '/goa-idc/estate/mortgage-workflow', component: MortgageWorkflow },
    { path: '/goa-idc/estate/lease-term-extension/workflow', component: LeaseExtensionWorkflow },
    { path: '/goa-idc/estate/surrender', component: SurrenderComponent },
    { path: '/goa-idc/estate/surrender-workflow', component: SurrenderWorkFlow },
    { path: '/goa-idc/estate/change-in-ownership-name-trade', component: MiscellaneousApplication },
    { path: '/goa-idc/estate/change-in-ownership-name-trade/workflow', component: MiscellaneousWorkflow },
    { path: '/goa-idc/estate/commercial-application/apply', component: CommercialOperation },
    { path: '/goa-idc/estate/commercial-application/workflow', component: CommercialOperationWorkflow },
    { path: '/goa-idc/estate/default-notice', component: IllegalityApplication },
    { path: '/goa-idc/query-entry', component: QueryEntry },
    { path: '/goa-idc/query-entry-workflow', component: QueryEntryWorkflow },
    { path: '/goa-idc/estate/inspection-report', component: InspectionReport },
    { path: '/goa-idc/dpr-creation', component: DprWorkflow },
    { path: '/goa-idc/content-management', component: ContentManagement },
    { path: '/goa-idc/migration/application-search', component: MigrationSearchPage },
    { path: '*', component: RedirectUrlScreen },
    { path: '/goa-idc/userDashboardNew', component: NewDashboardScreen },
    { path: '/goa-idc/gsr-master-details', component: GsrMasterDetails },
    { path: '/goa-idc/employee-mapping', component: EmployeeMapping },
    { path: '/goa-idc/employee-mapping/edit', component: EmployeeMappingEdit },
    { path: '/goa-idc/search-dpr', component: DprSearchScreen },
    { path: '/goa-idc/notice-inviting-tender', component: NitWorkflow },
    { path: '/goa-idc/search-nit', component: LoaSearchComponent },
    { path: '/goa-idc/letter-of-acceptance', component: LoaWorkflow },
    { path: '/goa-idc/search-loa', component: WorkOrderSearchComponent },
    { path: '/goa-idc/work-order', component: WorkOrderWorkflow },
    { path: '/goa-idc/esign', component: DscRegistration },
    { path: '/goa-idc/search-work-order', component: ContractCreationSearchComp },
    { path: '/goa-idc/contract', component: ContractCreationWorkflow },
    { path: '/goa-idc/emudra', component: EmudraPage },
    { path: '/goa-idc/emudra-esign', component: EmudraEsignPage },
    { path: '/goa-idc/contract-closure', component: ContarctClosure },
    { path: '/goa-idc/contract-closure/workflow', component: ContractClosureWorkflow },
    { path: '/goa-idc/search-work-contract', component: SeacrhContractScreen },
    { path: '/goa-idc/running-account-bill', component: RunningAccountBillWorkflow },
    { path: '/goa-idc/search-contract', component: SearchContract }, // for extension
    { path: '/goa-idc/extension-contract', component: ExtensionWorkflow }, // for extension
    { path: '/goa-idc/dpr/search', component: DprApplicationSearch },
    { path: '/goa-idc/nit/search', component: NitApplicationSearch },
    { path: '/goa-idc/loa/search', component: LoaApplicationSearch },
    { path: '/goa-idc/work-order/search', component: WorkOrderApplicationSearch },
    { path: '/goa-idc/contract/search', component: ContractCreationApplicationSearch },
    { path: '/goa-idc/mb-bill/search', component: RaBillApplicationSearch },
    { path: '/goa-idc/enterprise-management', component: EnterpriseManagement },
    { path: '/goa-idc/enterprise-management/search', component: EnterpriseSearch },
    { path: '/goa-idc/report-downloads', component: ReportDashboard },
    { path: '/goa-idc/enterprise-management/update', component: EnterpriseDetailsUpdate },
    { path: '/goa-idc/task-reassignment', component: TaskReassignment },
    { path: '/goa-idc/task-reassignment/search', component: TaskReassignmentSearch },
    { path: '/goa-idc/migration/payment-update', component: MigrationPaymentUpdate },
    { path: '/goa-idc/migration/payment-search', component: MigrationPaymentSearch }
];

export const publicRoutes = [
    { path: '/goa-idc/migration/migrationform', component: MigrationForm },
    { path: '/goa-idc/', component: MainPage },
    { path: '/goa-idc/login', component: LoginPage },
    { path: '/goa-idc/validate', component: OtpValidate },
    { path: '/goa-idc/resetpassword', component: ResetPassword },
    { path: '/goa-idc/usermanagement/enterprisecreation', component: EnterpriseCreationPage },
    { path: '/goa-idc/eu/euPreview', component: EnterpriseCreationPage },
    { path: '/goa-idc/aboutus', component: AboutUs },
    { path: '/goa-idc/faq', component: FaqPage },
    { path: '/goa-idc/contactus', component: ContactUsPage },
    { path: '/goa-idc/tender', component: TenderPage },
    { path: '/goa-idc/underconstruction', component: UnderConstruction },
    { path: '/goa-idc/whoweare', component: WhoIsWho },
    { path: '/goa-idc/disclaimer', component: Disclaimer },
    { path: '/goa-idc/privacypolicy', component: PrivacyPolicy },
    { path: '/goa-idc/terms&conditions', component: TermsandConditionPage },
    { path: '/goa-idc/plotdetails', component: PlotDetails },
    { path: '/goa-idc/dummy-payment', component: Payment },
    { path: '/goa-idc/holiday-calender', component: HolidayList },
    { path: '/goa-idc/dummy-payment-status', component: PaymentStatus },
    { path: '/goa-idc/estate/transfer', component: Transfer },
    { path: '/goa-idc/estate/transferee', component: Transferee },
    { path: '/goa-idc/createadvertisement', component: AdvertisementPage },
    { path: '/goa-idc/advertisement', component: CreateAdvertisement },
    { path: '/goa-idc/estate/indenture', component: LeaseDeedApplication },
    { path: '/goa-idc/search-advertisement', component: FindAdvertisement },
    { path: '/goa-idc/mass-allotment', component: MassAllotment },
    { path: '/goa-idc/estate/extension', component: Extension },
    { path: '/goa-idc/pa', component: ProvisionalAllotment },
    { path: '/goa-idc/mass-rejection', component: MassAllotment },
    { path: '/goa-idc/estate/extensionworkflow', component: ExtWorkflow },
    { path: '/goa-idc/estate/spv/application', component: SPVApplication },
    { path: '/goa-idc/estate/spv/workflow', component: SPFWorkflow },
    { path: '/goa-idc/estate/sublease', component: SubLeaseComponent },
    { path: '/goa-idc/estate/provisional-sublease-workflow', component: SubLeaseWorkflow },
    { path: '/goa-idc/estate/sublease-workflow', component: SubleaseOrderWorkflow },
    { path: '/goa-idc/estate/transfer/workflow', component: TransferWorkflow },
    { path: '/goa-idc/estate/transfer/details', component: TransferDetailsTable },
    { path: '/goa-idc/waterconnection', component: WaterConnection },
    { path: '/goa-idc/e/waterconnection', component: M_WaterConnection },
    { path: '/goa-idc/obpas/e/banknoc', component: M_BankNOC },
    { path: '/goa-idc/estate/transfer-application', component: TransferMainPage },
    { path: '/goa-idc/apply-plot', component: ApplyForPlotScreen },
    { path: '/goa-idc/water-connection', component: WaterConnectionScreen },
    { path: '/goa-idc/user-management', component: UserManagScreen },
    { path: '/goa-idc/compliance-dashboard', component: NewDashboardScreen },
    { path: '/goa-idc/obpas/renewal/constructionLicense', component: RenewalOfConstructionLicense },
    { path: '/goa-idc/obpas/e/renewal/constructionLicense', component: M_RenewalOfConstructionLicense },
    { path: '/goa-idc/waterconnection/upgradation', component: WaterConnectionUpgradation },
    { path: '/goa-idc/e/waterconnection/upgradation', component: M_WaterConnectionUpgradation },
    { path: '/goa-idc/waterconnection/disconnection', component: WaterConnectionDisconnection },
    { path: '/goa-idc/e/waterconnection/disconnection', component: M_WaterConnectionDisconnection },
    { path: '/goa-idc/waterconnection/payment', component: WaterConnectionPayment },
    { path: '/goa-idc/e/waterconnection/payment', component: M_WaterConnectionBillGeneration },
    { path: '/goa-idc/usermanagement/architectCreation', component: ArchitectCreationPage },
    { path: '/goa-idc/icici-payment', component: ICICIPaymentPage },
    { path: '/goa-idc/icici-dummy-status', component: PaymentStatusForm },
    { path: '/goa-idc/gsr-master', component: GsrScreen },
    { path: '/goa-idc/estate/plot-registry', component: PlotMaster },
    { path: '/goa-idc/vacant-plot-search', component: VacantPlotSearch },
    { path: '/goa-idc/access-restricted', component: UnauthorizedPage }
];
