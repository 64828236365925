import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { FieldArray, useFormikContext } from 'formik';
import { LOA_CORRESPONDENCE, BIDDER_FIELDS_I, BIDDER_FIELDS_II, LOA_DETAILS_FIELDS, DPR_DETAILS } from './Loa.const';
import Editor from '../../../../components/ContentEditor';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { BIDDER_DETAILS_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { getFirstErrorKey } from '../../../../utils/utils';
import { cross } from '../../../../utils/Logo';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';

const LoaCorrespondence = (props: any) => {
    const {
        setContent,
        content,
        readOnly,
        loaDetailsList,
        loaRowData,
        modalDetails,
        t,
        setModalDetails,
        setReminderPopUp,
        isViewOnly,
        onChangeAction,
        tableData,
        onRowClick,
        dropdownData,
        openSection,
        setOpenSection,
        resData
    } = props;
    const [bidderFields, setBidderFields] = useState<any>(BIDDER_FIELDS_II);

    const { values, errors, setFieldTouched, setTouched, touched, setValues, setFieldValue } = useFormikContext<any>();

    const onSectionChange = async (currentSection: string) => {
        try {
            if (!isViewOnly) {
                // if (openSection === 'dprDetails') {
                //     await BIDDER_DETAILS_VALIDATION[`page1`].validate(values, { abortEarly: false, context: values });
                // } else if (openSection === 'bidderDetails') {
                //     await BIDDER_DETAILS_VALIDATION[`page2`].validate(values, { abortEarly: false, context: values });
                // } else if (openSection === 'loaDetails') {
                //     await BIDDER_DETAILS_VALIDATION[`page3`].validate(values, { abortEarly: false, context: values });
                // }

                if (currentSection === 'loaNotes' && values.isUpdatable && Object.keys(touched).length) {
                    setReminderPopUp(true);
                }
                if (currentSection === 'loaDetails') {
                    const selectedObject = resData?.worksTenderDetails?.dprHeaderDetails?.find((val: any) => val.dprNo === values?.dprNumber?.code);
                    setFieldValue('estimatedTotalCost', +selectedObject?.estimatedTotalCost);
                }
                // setOpenSection(currentSection);
            }
            let previousSection = openSection;
            setOpenSection(previousSection === currentSection ? '' : currentSection);
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        let tempBidderField;
        if (values?.isPincodeDetailsPresent) {
            tempBidderField = BIDDER_FIELDS_II?.map((val: any) => ({
                ...val,
                formData: val?.formData?.map((item: any) =>
                    item?.field === 'district' || item?.field === 'state' || item?.field === 'country' ? { ...item, isDisabled: true, isRequired: false } : { ...item }
                )
            }));
        } else {
            tempBidderField = BIDDER_FIELDS_II?.map((val: any) => ({
                ...val,
                formData: val?.formData?.map((item: any) =>
                    item?.field === 'district' || item?.field === 'state' || item?.field === 'country' ? { ...item, isDisabled: false, isRequired: true } : { ...item }
                )
            }));
        }
        setBidderFields(tempBidderField);
    }, [values?.isPincodeDetailsPresent]);

    const renderBidderDetails = () => {
        const bidderFieldsI = !isViewOnly ? BIDDER_FIELDS_I : BIDDER_FIELDS_I.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));
        const bidderFieldsII = !isViewOnly ? bidderFields : bidderFields.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));
        return (
            <>
                <Card.Body>
                    {bidderFieldsI.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                    {bidderFieldsII.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                </Card.Body>
            </>
        );
    };

    const renderLoaNotes = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={setContent} content={content} readOnly={readOnly} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };
    const renderLoaDetails = () => {
        const loaDetailsFields = !isViewOnly ? LOA_DETAILS_FIELDS : LOA_DETAILS_FIELDS.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));
        return (
            <>
                {loaDetailsFields.map((val: any, id: number) => (
                    <GenericFormCard {...val} onChangeAction={onChangeAction} />
                ))}
            </>
        );
    };
    const renderDocTable = () => {
        return (
            <>
                <div className='px-4 pt-4'>
                    <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tableData} additionalMethod={onRowClick} />
                </div>
            </>
        );
    };

    const renderDprDetails = () => {
        const updatedDprFields = !isViewOnly
            ? DPR_DETAILS
            : DPR_DETAILS.map((val: any) => ({
                  ...val,
                  formData: val.formData.map((item: any) => {
                      if (item.field !== 'projectName') {
                          return { ...item, class: 'col-sm-4' };
                      } else {
                          return { ...item };
                      }
                  })
              }));
        return (
            <>
                <Card.Body>
                    {updatedDprFields?.map((val: any) => (
                        <GenericFormCard {...val} dropdownOptions={dropdownData} customHandleChange={onChangeAction} />
                    ))}
                </Card.Body>
            </>
        );
    };

    const LOA_CORRESPONDENCE_UPDATED = [
        ...LOA_CORRESPONDENCE,
        {
            title: `${isViewOnly ? '(D) Documents' : '(D) LOA Notes'}`,
            field: 'loaNotes'
        }
    ];
    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {LOA_CORRESPONDENCE_UPDATED.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === '(A) DPR Details' && openSection === 'dprDetails' && renderDprDetails()}
                                {val.title === '(B) Bidder Details' && openSection === 'bidderDetails' && renderBidderDetails()}
                                {val.title === '(C) LOA Details' && openSection === 'loaDetails' && renderLoaDetails()}
                                {val.title === '(D) LOA Notes' && openSection === 'loaNotes' && renderLoaNotes()}
                                {val.title === '(D) Documents' && openSection === 'loaNotes' && renderDocTable()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default LoaCorrespondence;
