import { Card, Form } from 'react-bootstrap';
import HeaderComponent from '../header/Header.component';
import GenericHeader from '../moduleCommonFormik/GenericHeader';
import Loading from '../portalComponents/Loading.component';
import { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import ScrollToTop from '../ScrollToTop';
import GenericButtons from '../UI components/Buttons/GenericButtons';
import StatusAlertPopup from '../moduleCommonFormik/StatusAlertPopup';
import { useNavigate } from 'react-router-dom';
import ApplicantDetails from '../../features/modules/EstateManagement/Allotment/ApplicantDetails';
import { dropdownDataOptions, getFormatedOwnershipDetails } from '../../features/modules/Migration/Migration.controller';
import { POST, endpoints, requestInfo, updatePassword } from '../../services/modulesServices/userManagement.api';
import { getAllotmentWorkflowInitialData, getEntityData } from '../../features/modules/EstateManagement/Allotment/Allotment.controller';
import { check, cross } from '../../utils/Logo';
import { TENANT_ID, USER_TYPE } from '../../utils/constants';
import { APPLICANT_DETAILS_I, APPLICANT_DETAILS_II, PROFILE_APPLICANT_DETAILS_I, PROMOTER_DETAILS } from '../../features/modules/EstateManagement/Allotment/Allotment.const';
import GenericBackButton from '../UI components/Buttons/GenericBackButton';
import SideCard from '../moduleCommonFormik/SideCardGenericComponent';
import { PROFILE_STEPS } from '../../features/modules/UserManagement/UserManagement.const';
import ForgotPassword from '../../features/loginPage/ForgotPassword';
import { EmudraPage } from '../../features/commonPages/EmudraPage';
import { KYCRegistration } from './KYCRegistration';

export const EnterpriseProfileComponent = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [username, setUsername] = useState<any>({});

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {}
        // validationSchema: activeIndex === 1 ? EMPLOYEE_PROFILE_VALIDATION : PasswordChangeSchema
    });
    const { values, setValues, setFieldValue } = formik;

    useEffect(() => {
        (async () => {
            try {
                const dropdownOption = await dropdownDataOptions();
                setDropdownData(dropdownOption);
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    useEffect(() => {
        const ApplicanAndPlotDetails = async () => {
            if (dropdownData) {
                try {
                    setIsLoading(true);
                    const userId = JSON.parse(localStorage.getItem('user') || '{}')?.uuid;
                    const { data } = await POST(endpoints.searchExistingUser + `?tenantId=${TENANT_ID}&userUuid=${userId}`, { RequestInfo: requestInfo() });
                    if (data.enterpriseEntity.length > 0) {
                        const res = await getEntityData(data.enterpriseEntity[0], dropdownData);
                        // setSearchApplicationResponse(data.enterpriseEntity[0]);
                        setValues((prev: any) => ({
                            ...prev,
                            ...res
                        }));
                        const newOwnership = await getFormatedOwnershipDetails(data?.enterpriseEntity[0].owners, dropdownData);
                        setOwnershipDetails(newOwnership);
                        setControllingOwnershipResponse(data?.enterpriseEntity[0].owners);
                        setUsername(data.enterpriseEntity[0].user.username);
                    }
                    setIsLoading(false);
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    setIsLoading(false);
                }
            }
        };
        ApplicanAndPlotDetails();
    }, [dropdownData]);

    const handleOnClose = () => {
        navigate('/goa-idc/enterprisedashboard');
    };

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                // let errMsg = t('FILL_REQUIRED_DETAILS', 'Fill in all the required details');
                setModalDetails({ show: true, title: 'Error!', para: e, img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const onSubmitChangePassword = async () => {
        console.log(username);
        if (values.oldPassword === values.newPassword) {
            let errMsg = t('OLD_PASSWORD_WARNING', 'For security reasons, please select a new password that is different from your previous one.');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            await updatePassword(username, values, USER_TYPE.ENTERPRISE);
            let msg = t('PASSWORD_UPDATED_SUCCESS', 'Password updated successfully');
            setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'success', reUpload: 'OK' });
        } catch (e) {
            let errMsg = t('PASSWORD_UPDATE_FAILED', 'Password update failed');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/enterprisedashboard');
    };

    const changePassword = () => {
        return (
            <>
                <Card className='card_border mb-4'>
                    <Card.Body>
                        <div className='container'>
                            <h3 className='change_password_heading mt-3'>Change Password</h3>
                            <ForgotPassword />
                        </div>
                        <div className='col-sm-12 ps-3 my-3'>
                            <h1 className='password_policy_heading'>Password Policy</h1>
                            <p className='col-sm-6 pe-2'>Password should be minimum 8 characters long, one lowercase, one uppercase, one digit, one special character.</p>
                        </div>
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderProfilePersonalDetails = () => {
        return (
            <Card className='card_border enterprise-profile'>
                <Card.Body>
                    <ApplicantDetails
                        controllingOwnershipResponse={controllingOwnershipResponse}
                        dropdownOptions={dropdownData}
                        setOwnershipDetails={setOwnershipDetails}
                        ownershipDetails={ownershipDetails}
                        isDisabled={true}
                        showOwnershipModal={showOwnershipModal}
                        setShowOwnershipModal={setShowOwnershipModal}
                        applicantDetails1={PROFILE_APPLICANT_DETAILS_I}
                        applicantDetails2={APPLICANT_DETAILS_II}
                        promoterDetails={PROMOTER_DETAILS}
                        // scrollNotRequired={true}
                    />
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />

            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader
                    text={t('MY_PROFILE', 'My Profile')}
                    // registrationNo={updateWorkflow ? regNo : ''}
                    bottomSubText={`${username}`}
                />
                <div className='col-3 stickyCard-section'>
                    <div className='stickyCard' style={{ top: '110px' }}>
                        <GenericHeader text={t('MY_PROFILE', 'My Profile')} bottomSubText={`${username}`} sideHeader={true} />

                        <Card className='card_border p-3'>
                            <Card.Body>
                                <Card.Text className=' d-flex flex-column  row-gap-3'>
                                    <SideCard steps={PROFILE_STEPS} activeIndex={activeIndex} handleStepClick={handleStepClick} />
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        {/* <Card className='card_border note'>
                            <Card.Body>
                                <Card.Text>
                                    <span>
                                        <i className='fa fa-exclamation-circle' aria-hidden='true'></i>
                                    </span>
                                    <b> {t('NOTE', 'Note :')} </b>
                                    {t('PLEASE_PROVIDE_ALL_DETAILS', 'Please provide all the details.')}
                                </Card.Text>
                            </Card.Body>
                        </Card> */}
                    </div>
                </div>
                <div className='col-9 form-section'>
                    <FormikProvider value={formik}>
                        <Form>
                            {activeIndex === 1 && renderProfilePersonalDetails()}
                            {activeIndex === 2 && changePassword()}
                            {activeIndex === 3 && <EmudraPage />}
                            {/* {activeIndex === 4 && <KYCRegistration dropdownData={dropdownData} />} */}
                        </Form>
                    </FormikProvider>
                </div>
            </div>
            <ScrollToTop />
            <div className='common-footer'>
                <GenericButtons
                    text={activeIndex === 1 ? t('CLOSE', 'Close') : t('SUBMIT', 'SUbmit')}
                    variant='primary'
                    ClassName='me-5'
                    handleClick={activeIndex === 1 ? handleOnClose : onSubmitChangePassword}
                />
                {/* <GenericButtons text={t('SUBMIT', 'Submit')} type='submit' variant='primary' ClassName='me-5' handleClick={activeIndex === 1 ? handleOnSubmit : onSubmitChangePassword} /> */}
            </div>
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
        </>
    );
};
